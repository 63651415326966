<div class="notification-container" [class.conf-mode]="isConfMode" [@fade]="isVisibleSubject | stream">
    <header class="notification-header">
        <i class="material-icons-round">videocam</i>
        <span class="title">Conference &#9679; Now</span>
    </header>

    <section class="notification-content">
        <ng-container
                *ngIf="profilesProvider.getProfileByUid(notification.payload.authorId) | stream as profile"
        >
            <app-user-avatar [profileData]="profile"></app-user-avatar>

            <div class="notification-content-data">
                <div class="title">
                    <span>{{ profile.displayName }} has requested to join the conference</span>
                </div>

                <section class="notification-actions">
                    <div class="action approve" (click)="approve()">Admit</div>
                    <div class="action decline" (click)="decline()">Decline</div>
                </section>
            </div>
        </ng-container>
    </section>

    <div class="processing-overlay" *ngIf="isProcessingStream | stream"></div>
</div>

