import {
    Component,
    TrackByFunction,
} from '@angular/core'

import {
    DockNotification,
    DockNotificationGroup,
    DockNotificationsManager,
    DockNotificationType,
} from '@undock/dock/meet/services/dock-notifications.manager'
import { ReactiveStream } from '@typeheim/fire-rx'
import { MeetingDockPageStateModel } from '@undock/dock/meet/ui/pages/meeting-dock/meeting-dock-page.state'


@Component({
    selector: 'app-meet-notifications',
    templateUrl: 'dock-notifications.component.html',
    styleUrls: ['dock-notifications.component.scss'],
})
export class DockNotificationsComponent {

    public readonly DockNotificationType = DockNotificationType

    public readonly groupsStream: ReactiveStream<DockNotificationGroup[]>

    public readonly trackByGroup: TrackByFunction<DockNotificationGroup> = (i, group) => group.groupName


    public constructor(
        public state: MeetingDockPageStateModel,
        private notificationsManager: DockNotificationsManager,
    ) {
        this.groupsStream = this.notificationsManager.groupsStream
    }

    public destroyNotification(notification: DockNotification) {
        this.notificationsManager.destroyNotification(notification)
    }
}
