import { Injectable } from '@angular/core'
import { FeaturePlansManager } from '@undock/feature-plans/services/feature-plans.manager'
import {
    AppFeature,
    AppFeatures,
} from '@undock/api/scopes/subscriptions/contracts/app-feature.interface'

export enum UserLimitType {
    PlansCount = 'PlansCount',
    CalendarsCount = 'CalendarsCount',
    SchedulesCount = 'SchedulesCount',
    PromptsCount = 'PromptsCount'
}

@Injectable({
    providedIn: 'root',
})
export class UserLimitsProvider {

    /**
     * The limitations for different features
     */
    private readonly appLimitsMap = {
        [UserLimitType.PlansCount]: {
            [AppFeatures.LimitedPlans]: 10,
            [AppFeatures.ExtendedPlans]: 30,
            [AppFeatures.UnlimitedPlans]: Infinity,
        },

        [UserLimitType.CalendarsCount]: {
            [AppFeatures.LimitedCalendars]: 4,
            [AppFeatures.UnlimitedCalendars]: Infinity,
        },

        [UserLimitType.SchedulesCount]: {
            [AppFeatures.LimitedSchedules]: 6,
            [AppFeatures.UnlimitedSchedules]: Infinity,
        },

        [UserLimitType.PromptsCount]: {
            [AppFeatures.LimitedPrompts]: 50,
            [AppFeatures.ExtendedPrompts]: 100,
            [AppFeatures.UnlimitedPrompts]: Infinity,
        },
    }

    public constructor(
        private featurePlansManager: FeaturePlansManager,
    ) {}

    public async getPlansLimit(): Promise<number> {
        return this.getUserLimitValue(
            UserLimitType.PlansCount,
            await this.featurePlansManager.state.currentPlanFeatures$,
            10,
        )
    }

    public async getCalendarsLimit(): Promise<number> {
        return this.getUserLimitValue(
            UserLimitType.CalendarsCount,
            await this.featurePlansManager.state.currentPlanFeatures$,
            4,
        )
    }

    public async getSchedulesLimit(): Promise<number> {
        return this.getUserLimitValue(
            UserLimitType.SchedulesCount,
            await this.featurePlansManager.state.currentPlanFeatures$,
            6,
        )
    }

    public async getPromptsLimit(): Promise<number> {
        return this.getUserLimitValue(
            UserLimitType.PromptsCount,
            await this.featurePlansManager.state.currentPlanFeatures$,
            50
        )
    }

    protected getUserLimitValue(
        type: UserLimitType, features: AppFeature[], defaultValue: number,
    ) {
        const featureTypes = features.map(feature => feature.type)

        /**
         * Searching for all limits allowed by current user plan
         */
        const limits = Object.entries(this.appLimitsMap[type])
                             .map(([feature, limit]) => {
                                 if (featureTypes.includes(feature as AppFeatures)) {
                                     return limit
                                 }
                             })
                             .filter(Boolean)

        /**
         * Searching for the biggest limit for current user
         */
        return limits.length > 0 ? Math.max(...limits) : defaultValue
    }
}
