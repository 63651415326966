export class BookingRequestSlotsNotAvailableException extends Error {

    public timestamps?: string[]

    public constructor(bookingCode?: string, unavailableTimestamps?: string[]) {
        super(
            `Attempted to create, add or confirm an unavailable slots for booking request #${bookingCode ?? ''}`
        )

        this.timestamps = unavailableTimestamps ?? null
    }
}
