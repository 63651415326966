import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { AuthManager } from '@undock/auth/services/auth.manager'

@Injectable()
export class EmbedWrapperGuard implements CanActivate {
    public constructor(
        private authManager: AuthManager,
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        if (!route.queryParams?.hasOwnProperty('uct')) {
            // regular routes should be bypassed right away
            return true
        }

        const authToken = route.queryParams['uct']
        if (!authToken || authToken?.length < 10) {
            return false
        }

        return this.authManager.signInWithMobileToken(authToken).pipe(map(credentials => {
            const isAuthorized = !!credentials.user
            if (isAuthorized) {
                // first access must refresh page due to internal dependencies
                window.location.replace(location.pathname)
            }
            return isAuthorized
        }))
    }
}
