import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-active-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.41663 9.99996C2.41663 5.8118 5.8118 2.41663 9.99996 2.41663C14.1881 2.41663 17.5833 5.8118 17.5833 9.99996C17.5833 14.1881 14.1881 17.5833 9.99996 17.5833C5.8118 17.5833 2.41663 14.1881 2.41663 9.99996ZM9.99996 0.916626C4.98337 0.916626 0.916626 4.98337 0.916626 9.99996C0.916626 15.0165 4.98337 19.0833 9.99996 19.0833C15.0165 19.0833 19.0833 15.0165 19.0833 9.99996C19.0833 4.98337 15.0165 0.916626 9.99996 0.916626ZM11.1068 11.4843C11.984 10.8995 12.4226 10.6071 12.5081 10.2107C12.538 10.0718 12.538 9.92812 12.5081 9.78921C12.4226 9.39283 11.984 9.10043 11.1068 8.51563C10.0445 7.80742 9.51333 7.45332 9.07796 7.56829C8.92696 7.60817 8.78739 7.68286 8.67045 7.78638C8.33329 8.08485 8.33329 8.72322 8.33329 9.99996C8.33329 11.2767 8.33329 11.9151 8.67045 12.2135C8.78739 12.3171 8.92696 12.3918 9.07796 12.4316C9.51333 12.5466 10.0445 12.1925 11.1068 11.4843Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class ActiveIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 20
    @Input() height: string | number  = 20
    @Input() color: string = '#807A8E'
}
