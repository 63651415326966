import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'

import { CoreModule } from '@undock/core/core.module'
import { UserProvider } from '@undock/user/user.provider'

import { InvitesProvider } from '@undock/invites/invites.provider'


/**
 * -----------------------------------------------------------------------
 *                            Providers
 * -----------------------------------------------------------------------
 */
import { ConnectionsFacade } from '@undock/people/services/facades/connections.facade'


@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        UserProvider,
        CoreModule,
        InvitesProvider,
    ],
    providers: [
        ConnectionsFacade,
    ],
})
export class PeopleProvider {}
