import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

import moment from 'moment'

import {
    ValueSubject,
    DestroyEvent,
    EmitOnDestroy,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'
import {
    takeUntil,
    shareReplay,
    distinctUntilChanged,
    filter,
} from 'rxjs/operators'
import { map } from 'rxjs/operators'
import { Observable, combineLatest } from 'rxjs'

import {
    Memoize,
    compareDeeplyBy,
} from '@undock/core'
import { EventFormScheduleComponent } from './event-form-schedule.component'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


@Component({
    selector: 'app-meet-event-form-schedule-lg',
    templateUrl: 'event-form-schedule-lg.component.html',
    styleUrls: [
        '_shared/event-form-schedule.scss',
        'event-form-schedule-lg.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormScheduleLgComponent extends EventFormScheduleComponent {

    /**
     * Large version of edit-schedule component with full-size slot selector
     */

    public readonly displayAvailability$ = this.availabilityViewModel.displayAvailabilityStream
    public readonly displayAvailabilityRangeStart$ = this.availabilityViewModel.displayAvailabilityRangeStartStream
    public readonly availabilityDaysCountToDisplay$ = this.availabilityViewModel.availabilityDaysCountToDisplayStream

    @CompleteOnDestroy()
    public readonly isAvailabilitySelectorHidden$ = new ValueSubject(false)

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    @Memoize()
    public get recurrenceLabel$(): Observable<string> {
        return this.recurringFrequencyStream.pipe(
            map(freq => {
                return freq
                    ? this.recurrenceOptions.find(o => o[0] === freq)[1]
                    : 'None'
            }),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isEventScheduleSelected$(): Observable<boolean> {
        return this.state.eventScheduleStream.pipe(
            map(schedule => {
                return Boolean(schedule?.start && schedule?.end)
            }),

            distinctUntilChanged(),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get selectedAvailableSlotStream$(): Observable<AvailabilitySlot> {
        return this.state.eventScheduleStream.pipe(
            map((schedule) => {
                return schedule?.start ? {
                    timeStamp: schedule.start.toISOString(),
                } as AvailabilitySlot : null
            }),

            distinctUntilChanged(
                compareDeeplyBy('timeStamp'),
            ),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get selectedAvailabilityDayIndex$(): Observable<number> {
        return combineLatest([
            this.availabilityViewModel.displayAvailabilityStream,
            this.availabilityViewModel.selectedAvailabilityDayStream,
        ]).pipe(
            map(([availability, selectedDay]) => {
                for (let set of availability) {
                    if (set.day.isSame(selectedDay, 'day')) {
                        return availability.indexOf(set)
                    }
                }
                return -1
            }),

            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isMeetingShouldBeRescheduled$(): Observable<boolean> {
        return combineLatest([
            this.state.isDraftModeStream,
            this.state.eventScheduleStream,
            this.state.originalEventDataStream,
        ]).pipe(
            // Skip cases when rescheduling isn't possible
            filter(([isDraft, schedule]) => {
                return Boolean(!isDraft && schedule)
            }),
            map(([, schedule, originalData]) => {
                return originalData.schedule.start
                    && originalData.schedule.end
                    && schedule.start && schedule.end
                    && !(
                        moment(schedule.end).isSame(originalData.schedule.end) &&
                        moment(schedule.start).isSame(originalData.schedule.start)
                    )
            }),

            distinctUntilChanged(),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    public displayPrevAvailabilityDays() {
        return super.displayPrevAvailabilityDays(3)
    }

    public displayNextAvailabilityDays() {
        return super.displayNextAvailabilityDays(3)
    }

    public async cancelReschedule() {
        const originalData = await this.state.originalEventDataStream
        this.eventFormStateModel.setEventSchedule(originalData.schedule)
    }

    public async onAvailabilityDayIndexSelected(index: number) {
        if (index >= 0) {
            const availability = await this.displayAvailability$
            await this.availabilityViewModel
                      .selectAvailabilityDay(availability[index]?.day, true)
        }
    }
}
