import { ApiContext } from '../../contracts/api.context'
import { SearchRoute } from '@undock/api/scopes/terminal/routes/search.route'
import { SettingsRoute } from '@undock/api/scopes/terminal/routes/settings.route'
import { EventsRoute } from '@undock/api/scopes/terminal/routes/events.route'
import { AvailabilityRoute } from '@undock/api/scopes/terminal/routes/availability.route'
import { FeaturePlansRoute } from '@undock/api/scopes/terminal/routes/feature-plan.route'

export class TerminalScope {
    public readonly search: SearchRoute
    public readonly events: EventsRoute
    public readonly settings: SettingsRoute
    public readonly featurePlan: FeaturePlansRoute
    public readonly availability: AvailabilityRoute

    public constructor(context: ApiContext) {
        this.search = new SearchRoute(context)
        this.events = new EventsRoute(context)
        this.settings = new SettingsRoute(context)
        this.featurePlan = new FeaturePlansRoute(context)
        this.availability = new AvailabilityRoute(context)
    }
}
