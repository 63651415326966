import { ApiRoute } from '@undock/api/services/api.route'


export class SettingsRoute extends ApiRoute {

    public init() {
        this.route = 'timeline/calendar/settings'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public getSettings() {
        return this.get<CalendarSettings>()
                   .endpoint(``)
    }

    public update(settings: CalendarSettings) {
        return this.post<void>()
                   .withData(settings)
                   .endpoint(`update`)
    }
}

interface BaseCalendarData {
    id: string
    name: string
    display: boolean
    icon: string
    color: string
    visible?: boolean
}

export interface SubCalendarSettingsData extends BaseCalendarData {
    enabled: boolean
}

export interface CalendarSettingsData extends BaseCalendarData {
    provider: string
    providerIcon?: string
    subCalendars: SubCalendarSettingsData[]
}

export interface CalendarSettings {
    showWeekends: boolean
    showReservedSlots: boolean
    calendars: CalendarSettingsData[]
}
