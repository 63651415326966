import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-audio-only-broadcast',
    templateUrl: 'audio-only-broadcast.component.html',
    styleUrls: ['audio-only-broadcast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioOnlyBroadcastIconComponent {
    @Input() height: number|string = '20'
    @Input() width: number|string = '20'
    @Input() color: string = '#807A8E' // $color-grayscale-dark
    @Input() backgroundColor: string = '#EDEAF2' // $color-grayscale-light-2
}
