import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-icon-in-person',
    templateUrl: 'in-person.component.html',
    styleUrls: ['in-person.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InPersonIconComponent extends IconTemplateComponent {}
