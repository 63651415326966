import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-command-prompt',
    templateUrl: 'command-prompt.component.html',
    styleUrls: ['command-prompt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandPromptIconComponent {
    @Input() height: number|string = '24'
    @Input() width: number|string = '24'
    @Input() color: string = '#8473B5'
    @Input() round: boolean = false
}
