import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-status-in-icon',
    templateUrl: 'status-in-icon.component.html',
    styleUrls: ['status-in-icon.component.scss'],
})
export class StatusInIconComponent extends IconTemplateComponent {}

