import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'

import {
    ForceAnonymousGuard,
    InstallIntegrationGuard,
    LoggedInGuard,
    NotAuthorizedUserGuard,
    RegularUserGuard,
} from '@undock/auth/guards'
import { HandleErrorStrategy } from '@undock/core'
import { AuthSession } from '@undock/auth/services/firebase/auth-session.service'
import { FirebaseAuthManager } from '@undock/auth/services/firebase/firebase-auth.manager'
import { AuthErrorsStrategy } from '@undock/auth/services/error-strategies/auth-errors.strategy'
import { ForceOnboardingGuard } from '@undock/auth/guards/force-onboarding.guard'
import { EmbedWrapperGuard } from '@undock/auth/guards/embed-wrapper-guard.service'
import { ExtensionRegularUserGuard } from '@undock/auth/guards/extension-regular-user.guard'


@NgModule()
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthSession,
                FirebaseAuthManager,

                LoggedInGuard,
                EmbedWrapperGuard,
                RegularUserGuard,
                ForceAnonymousGuard,
                ForceOnboardingGuard,
                NotAuthorizedUserGuard,
                InstallIntegrationGuard,
                ExtensionRegularUserGuard,

                /**
                 * The `AuthErrorsStrategy` will be automatically injected into the core ErrorsHandler
                 */
                { provide: HandleErrorStrategy, useClass: AuthErrorsStrategy, multi: true },
            ],
        }
    }
}
