import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'

import { AuthManager } from '@undock/auth/services/auth.manager'


@Injectable()
export class ForceAnonymousGuard implements CanActivate {

    /**
     * This Guard will force anonymous sign in for non-authorized users
     */

    public constructor(
        private authManager: AuthManager,
    ) {}

    public async canActivate(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot,
    ): Promise<boolean> {

        if (!await this.authManager.isLoggedInStream) {
            /**
             * Will force anonymous sign-in for some routes
             */
            await this.authManager.signInAnonymously()
        }

        return true
    }
}
