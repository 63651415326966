import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import { clone } from '@undock/core'
import { MeetingDurationOption } from '@undock/dock/meet'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'


@Component({
    selector: 'app-meet-edit-duration-select-v2',
    templateUrl: 'meet-duration-select-v2.component.html',
    styleUrls: ['meet-duration-select.component.scss'],
})
export class MeetDurationSelectV2Component {

    @Output() onDurationChanged = new EventEmitter<MeetingDuration>()

    /**
     * Input state
     */
    protected _selectedMeetingDuration: MeetingDuration
    protected _availableMeetingDurationOptions: MeetingDurationOption[] = []

    /**
     * Calculated state
     */
    protected _visibleDurationOptions: MeetingDurationOption[]


    public get selectedMeetingDuration(): MeetingDuration {
        return this._selectedMeetingDuration
    }

    public get availableMeetingDurationOptions(): MeetingDurationOption[] {
        return this._availableMeetingDurationOptions
    }


    @Input() set selectedMeetingDuration(value: MeetingDuration) {
        if (this._selectedMeetingDuration !== value) {
            this.resetCalculatedState()
        }
        this._selectedMeetingDuration = value
    }

    @Input() set availableMeetingDurationOptions(value: MeetingDurationOption[]) {
        if (this._availableMeetingDurationOptions !== value) {
            this.resetCalculatedState()
        }
        this._availableMeetingDurationOptions = value
    }


    public get allDurationOptionsStream(): MeetingDurationOption[] {
        if (this._visibleDurationOptions === undefined) {
            let options = this.availableMeetingDurationOptions ?? []
            if (options) {
                /**
                 * Pushing new option if custom duration is used here
                 */
                const isCustomDurationUsed = !options.some(option => {
                    return option.value - option.gap === this._selectedMeetingDuration
                })

                if (isCustomDurationUsed) {
                    /**
                     * If custom duration is used `selected` is custom value
                     */
                    const isOptionExists = options.some(
                        o => o.value === this.selectedMeetingDuration
                    )

                    if (!isOptionExists) {
                        /**
                         * Prevent mutating original array
                         */
                        options = clone(options)
                        options.push({ gap: 0, enabled: true, value: this.selectedMeetingDuration })
                        options.sort((a, b) => a.value - b.value)
                    }
                }
                this._visibleDurationOptions = options
            } else {
                return []
            }
        }
        return this._visibleDurationOptions
    }


    public onMeetingDurationChanged(value: MeetingDuration) {
        this.onDurationChanged.emit(value)
        this.selectedMeetingDuration = value
    }


    protected resetCalculatedState() {
        delete this._visibleDurationOptions
    }
}
