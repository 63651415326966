<!--
[attr.fill]="backgroundColor"
[attr.stroke]="borderColor"
[attr.width]="width"
[attr.height]="height"
-->
<ng-template #oldIcon>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
     xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.8335 2.49984V9.99984C5.8335 10.4582 6.2085 10.8332 6.66683 10.8332H8.3335V16.7915C8.3335 17.2165 8.89183 17.3665 9.1085 16.9998L13.4335 9.58317C13.7585 9.02484 13.3585 8.33317 12.7168 8.33317H10.8335L12.9085 2.7915C13.1168 2.24984 12.7168 1.6665 12.1335 1.6665H6.66683C6.2085 1.6665 5.8335 2.0415 5.8335 2.49984V2.49984Z"
        [attr.stroke]="borderColor"
        [attr.fill]="color"
    />
</svg>
</ng-template>

<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            [attr.stroke]="borderColor"
            [attr.fill]="color"
            d="M9.75 1.00006C9.75 0.669558 9.53366 0.378013 9.21734 0.282239C8.90102 0.186466 8.55929 0.30904 8.37596 0.584033L0.375963 12.584C0.222535 12.8142 0.208231 13.1101 0.338745 13.354C0.46926 13.5978 0.723405 13.7501 1 13.7501H5.25V21.0001C5.25 21.3306 5.46634 21.6221 5.78266 21.7179C6.09898 21.8137 6.44071 21.6911 6.62404 21.4161L14.624 9.41608C14.7775 9.18594 14.7918 8.89003 14.6613 8.64616C14.5307 8.4023 14.2766 8.25006 14 8.25006H9.75V1.00006Z"
    />
</svg>
