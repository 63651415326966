<ng-container *ngIf="{
        events: groupEventsStream | stream,
        attendees: attendeesStream | stream,
        quickMatchesByDay: quickMatchSlotsByDayStream | stream,
        groupEventsByDay: groupEventsByDayStream | stream,
        expandedSlot: expandedSlotStream | stream,
        areDescriptionsAvailable: areDescriptionsAvailableStream | stream
    } as view">

    <div class="group-schedule-container">

        <ng-container *ngIf="view.attendees?.length">

            <span class="section-label">Participants</span>

            <app-meet-edit-attendees
                [attendees]="view.attendees"
                [readonly]="true"
            ></app-meet-edit-attendees>

        </ng-container>

        <ng-container *ngIf="view.quickMatchesByDay?.length">

            <span class="section-label __quick">Quick Matches</span>

            <ng-container *ngFor="let group of view.quickMatchesByDay">
                <span class="quick-match">{{group.day | moment: 'dddd, MMMM Do'}} -
                    <ng-container *ngFor="let slot of group.slots">
                        {{slot | moment: 'h:mma'}}
                    </ng-container>
                </span>
            </ng-container>

        </ng-container>

        <span class="section-label __suggest">Suggestions
            <app-loading *ngIf="view.events?.length && !view.areDescriptionsAvailable" [width]="'50px'" [height]="'44px'"></app-loading>
        </span>

        <ng-container *ngIf="view.events?.length; else loadingTmpl">
            <section class="suggested-event-groups"
                     *ngFor="let group of view.groupEventsByDay; let groupIndex = index">

                <div class="group-header">{{ group.day | moment: "dddd, MMMM D, y" }}</div>

                <div class="event-items">
                    <ng-container *ngFor="let event of group.events; let eventIndex = index">
                        <div class="event-item"
                             [class.__expanded]="view.expandedSlot?.iCalUId === event.iCalUId"
                             [class.__descriptions-available]="view.areDescriptionsAvailable"
                            (click)="updateEventSelection(event); expandSlot(event);"
                        >
                            <div class="main-row">
                                <mat-radio-button (click)="$event.stopPropagation()"
                                                  [checked]="event.isSelected"
                                                  [name]="'event-group-select'"
                                                  (change)="updateEventSelection(event)"
                                ></mat-radio-button>

                                <span class="slot-time">
                                    <span class="date-time-box">{{ event.schedule.start | moment: "h:mm A" }}</span>
                                    -
                                    <span class="date-time-box">{{ event.schedule.end | moment: "h:mm A" }}</span>
                                </span>

                                <app-event-attendees-preview
                                    [participants]="event.freeAttendees"
                                    [maxDisplayCount]="10"
                                ></app-event-attendees-preview>

                                <ng-container *ngIf="event.attendees.length === event.freeAttendees.length; else rescheduleIcon">
                                    <app-icon-lightning class="icon"
                                        [color]="event.rank === 1 ? '#DFC051': '#CECADA'"
                                    ></app-icon-lightning>
                                </ng-container>

                                <ng-template #rescheduleIcon>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 10C1.75 5.44525 5.41752 1.75 10 1.75C12.7226 1.75 14.7616 2.88757 16.1051 4.00717C16.3408 4.20352 16.5557 4.39983 16.75 4.59017V2.91667C16.75 2.50245 17.0858 2.16667 17.5 2.16667C17.9142 2.16667 18.25 2.50245 18.25 2.91667V6.66667C18.25 7.08088 17.9142 7.41667 17.5 7.41667H15C14.5858 7.41667 14.25 7.08088 14.25 6.66667C14.25 6.25245 14.5858 5.91667 15 5.91667H15.9509C15.724 5.67684 15.4552 5.41812 15.1449 5.1595C13.9884 4.19577 12.2774 3.25 10 3.25C6.24915 3.25 3.25 6.27048 3.25 10C3.25 10.4142 2.91421 10.75 2.5 10.75C2.08579 10.75 1.75 10.4142 1.75 10ZM17.5 9.25C17.9142 9.25 18.25 9.58579 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C7.66872 18.25 5.82955 17.0784 4.6096 15.9723C4.42084 15.8012 4.24529 15.6302 4.08333 15.4633V17.0833C4.08333 17.4975 3.74755 17.8333 3.33333 17.8333C2.91912 17.8333 2.58333 17.4975 2.58333 17.0833V13.3333C2.58333 12.9191 2.91912 12.5833 3.33333 12.5833H5.88707C6.30128 12.5833 6.63707 12.9191 6.63707 13.3333C6.63707 13.7475 6.30128 14.0833 5.88707 14.0833H4.84826C5.06717 14.3295 5.32431 14.5955 5.61713 14.861C6.69498 15.8383 8.18914 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 9.58579 17.0858 9.25 17.5 9.25ZM10 5.91667C10.4142 5.91667 10.75 6.25245 10.75 6.66667V10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10V6.66667C9.25 6.25245 9.58579 5.91667 10 5.91667ZM10 13.3333C10.4602 13.3333 10.8333 12.9602 10.8333 12.5C10.8333 12.0398 10.4602 11.6667 10 11.6667C9.53976 11.6667 9.16667 12.0398 9.16667 12.5C9.16667 12.9602 9.53976 13.3333 10 13.3333Z" fill="#9A95AC"/>
                                    </svg>
                                </ng-template>
                            </div>

                            <span class="__description">{{event.description}}</span>

                        </div>
                    </ng-container>
                </div>
            </section>
        </ng-container>

        <ng-template #loadingTmpl>
            <!-- TODO: Move to a component -->
            <div class="__loading-section">
                <app-ai-loading [height]="'150px'" [backgroundColor]="'#FFFFFF'"></app-ai-loading>
                <div class="prompt-loader">
                    <span class="__label">{{viewModel.state.loadingStatusMessage$ | stream}}</span>
                    <span class="__sub-label">Give your calendar a few seconds to think</span>
                    <div class="__cancel-button">
                        <span class="__labels">Press</span>
                        <app-key-label [label]="'esc'"></app-key-label>
                        <span class="__labels">to cancel</span>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>

</ng-container>

