// Source: https://gist.github.com/maxrice/2776900

// export const states = [
//     { short: 'AL', full: 'ALABAMA' },
//     { short: 'AK', full: 'ALASKA' },
//     { short: 'AZ', full: 'ARIZONA' },
//     { short: 'AR', full: 'ARKANSAS' },
//     { short: 'CA', full: 'CALIFORNIA' },
//     { short: 'CO', full: 'COLORADO' },
//     { short: 'CT', full: 'CONNECTICUT' },
//     { short: 'DE', full: 'DELAWARE' },
//     { short: 'DC', full: 'DISTRICT OF COLUMBIA' },
//     { short: 'FL', full: 'FLORIDA' },
//     { short: 'GA', full: 'GEORGIA' },
//     { short: 'HI', full: 'HAWAII' },
//     { short: 'ID', full: 'IDAHO' },
//     { short: 'IL', full: 'ILLINOIS' },
//     { short: 'IN', full: 'INDIANA' },
//     { short: 'IA', full: 'IOWA' },
//     { short: 'KS', full: 'KANSAS' },
//     { short: 'KY', full: 'KENTUCKY' },
//     { short: 'LA', full: 'LOUISIANA' },
//     { short: 'ME', full: 'MAINE' },
//     { short: 'MD', full: 'MARYLAND' },
//     { short: 'MA', full: 'MASSACHUSETTS' },
//     { short: 'MI', full: 'MICHIGAN' },
//     { short: 'MN', full: 'MINNESOTA' },
//     { short: 'MS', full: 'MISSISSIPPI' },
//     { short: 'MO', full: 'MISSOURI' },
//     { short: 'MT', full: 'MONTANA' },
//     { short: 'NE', full: 'NEBRASKA' },
//     { short: 'NV', full: 'NEVADA' },
//     { short: 'NH', full: 'NEW HAMPSHIRE' },
//     { short: 'NJ', full: 'NEW JERSEY' },
//     { short: 'NM', full: 'NEW MEXICO' },
//     { short: 'NY', full: 'NEW YORK' },
//     { short: 'NC', full: 'NORTH CAROLINA' },
//     { short: 'ND', full: 'NORTH DAKOTA' },
//     { short: 'OH', full: 'OHIO' },
//     { short: 'OK', full: 'OKLAHOMA' },
//     { short: 'OR', full: 'OREGON' },
//     { short: 'PA', full: 'PENNSYLVANIA' },
//     { short: 'PR', full: 'PUERTO RICO' },
//     { short: 'RI', full: 'RHODE ISLAND' },
//     { short: 'SC', full: 'SOUTH CAROLINA' },
//     { short: 'SD', full: 'SOUTH DAKOTA' },
//     { short: 'TN', full: 'TENNESSEE' },
//     { short: 'TX', full: 'TEXAS' },
//     { short: 'UT', full: 'UTAH' },
//     { short: 'VT', full: 'VERMONT' },
//     { short: 'VA', full: 'VIRGINIA' },
//     { short: 'WA', full: 'WASHINGTON' },
//     { short: 'WV', full: 'WEST VIRGINIA' },
//     { short: 'WI', full: 'WISCONSIN' },
//     { short: 'WY', full: 'WYOMING' },
// ]

// Industries, with their group categorization and linkedin code
// source: https://developer.linkedin.com/docs/reference/industry-codes#

// export const industries = [
//     { code: 47, groups: ['corp', 'fin'], description: 'Accounting' },
//     { code: 94, groups: ['man', 'tech', 'tran'], description: 'Airlines/ Aviation' },
//     { code: 120, groups: ['leg', 'org'], description: 'Alternative Dispute Resolution' },
//     { code: 125, groups: ['hlth'], description: 'Alternative Medicine' },
//     { code: 127, groups: ['art', 'med'], description: 'Animation' },
//     { code: 19, groups: ['good'], description: 'Apparel & Fashion' },
//     { code: 50, groups: ['cons'], description: 'Architecture & Planning' },
//     { code: 111, groups: ['art', 'med', 'rec'], description: 'Arts and Crafts' },
//     { code: 53, groups: [' man'], description: 'Automotive' },
//     { code: 52, groups: ['gov', 'man'], description: 'Aviation & Aerospace' },
//     { code: 41, groups: ['fin'], description: 'Banking' },
//     { code: 12, groups: ['gov', 'hlth', 'tech'], description: 'Biotechnology' },
//     { code: 36, groups: ['med', 'rec'], description: 'Broadcast Media' },
//     { code: 49, groups: ['cons'], description: 'Building Materials' },
//     { code: 138, groups: ['corp', 'man'], description: 'Business Supplies and Equipment' },
//     { code: 129, groups: ['fin'], description: 'Capital Markets' },
//     { code: 54, groups: ['man'], description: 'Chemicals' },
//     { code: 90, groups: ['org', 'serv'], description: 'Civic & Social Organization' },
//     { code: 51, groups: ['cons', 'gov'], description: 'Civil Engineering' },
//     { code: 128, groups: ['cons', 'corp', 'fin'], description: 'Commercial Real Estate' },
//     { code: 118, groups: ['tech'], description: 'Computer & Network Security' },
//     { code: 109, groups: ['med', 'rec'], description: 'Computer Games' },
//     { code: 3, groups: ['tech'], description: 'Computer Hardware' },
//     { code: 5, groups: ['tech'], description: 'Computer Networking' },
//     { code: 4, groups: ['tech'], description: 'Computer Software' },
//     { code: 48, groups: ['cons'], description: 'Construction' },
//     { code: 24, groups: ['good', 'man'], description: 'Consumer Electronics' },
//     { code: 25, groups: ['good', 'man'], description: 'Consumer Goods' },
//     { code: 91, groups: ['org', 'serv'], description: 'Consumer Services' },
//     { code: 18, groups: ['good'], description: 'Cosmetics' },
//     { code: 65, groups: ['agr'], description: 'Dairy' },
//     { code: 1, groups: ['gov', 'tech'], description: 'Defense & Space' },
//     { code: 99, groups: ['art', 'med'], description: 'Design' },
//     { code: 69, groups: ['edu'], description: 'Education Management' },
//     { code: 132, groups: ['edu', 'org'], description: 'E-Learning' },
//     { code: 112, groups: ['good', 'man'], description: 'Electrical/ Electronic Manufacturing' },
//     { code: 28, groups: ['med', 'rec'], description: 'Entertainment' },
//     { code: 86, groups: ['org', 'serv'], description: 'Environmental Services' },
//     { code: 110, groups: ['corp', 'rec', 'serv'], description: 'Events Services' },
//     { code: 76, groups: ['gov'], description: 'Executive Office' },
//     { code: 122, groups: ['corp', 'serv'], description: 'Facilities Services' },
//     { code: 63, groups: ['agr'], description: 'Farming' },
//     { code: 43, groups: ['fin'], description: 'Financial Services' },
//     { code: 38, groups: ['art', 'med', 'rec'], description: 'Fine Art' },
//     { code: 66, groups: ['agr'], description: 'Fishery' },
//     { code: 34, groups: ['rec', 'serv'], description: 'Food & Beverages' },
//     { code: 23, groups: ['good', 'man', 'serv'], description: 'Food Production' },
//     { code: 101, groups: ['org'], description: 'Fund-Raising' },
//     { code: 26, groups: ['good', 'man'], description: 'Furniture' },
//     { code: 29, groups: ['rec'], description: 'Gambling & Casinos' },
//     { code: 145, groups: ['cons', 'man'], description: 'Glass, Ceramics & Concrete' },
//     { code: 75, groups: ['gov'], description: 'Government Administration' },
//     { code: 148, groups: ['gov'], description: 'Government Relations' },
//     { code: 140, groups: ['art', 'med'], description: 'Graphic Design' },
//     { code: 124, groups: ['hlth', 'rec'], description: 'Health, Wellness and Fitness' },
//     { code: 68, groups: ['edu'], description: 'Higher Education' },
//     { code: 14, groups: ['hlth'], description: 'Hospital & Health Care' },
//     { code: 31, groups: ['rec', 'serv', 'tran'], description: 'Hospitality' },
//     { code: 137, groups: ['corp'], description: 'Human Resources' },
//     { code: 134, groups: ['corp', 'good', 'tran'], description: 'Import and Export' },
//     { code: 88, groups: ['org', 'serv'], description: 'Individual & Family Services' },
//     { code: 147, groups: ['cons', 'man'], description: 'Industrial Automation' },
//     { code: 84, groups: ['med', 'serv'], description: 'Information Services' },
//     { code: 96, groups: ['tech'], description: 'Information Technology and Services' },
//     { code: 42, groups: ['fin'], description: 'Insurance' },
//     { code: 74, groups: ['gov'], description: 'International Affairs' },
//     { code: 141, groups: ['gov', 'org', 'tran'], description: 'International Trade and Development' },
//     { code: 6, groups: ['tech'], description: 'Internet' },
//     { code: 45, groups: ['fin'], description: 'Investment Banking' },
//     { code: 46, groups: ['fin'], description: 'Investment Management' },
//     { code: 73, groups: ['gov', 'leg'], description: 'Judiciary' },
//     { code: 77, groups: ['gov', 'leg'], description: 'Law Enforcement' },
//     { code: 9, groups: ['leg'], description: 'Law Practice' },
//     { code: 10, groups: ['leg'], description: 'Legal Services' },
//     { code: 72, groups: ['gov', 'leg'], description: 'Legislative Office' },
//     { code: 30, groups: ['rec', 'serv', 'tran'], description: 'Leisure, Travel & Tourism' },
//     { code: 85, groups: ['med', 'rec', 'serv'], description: 'Libraries' },
//     { code: 116, groups: ['corp', 'tran'], description: 'Logistics and Supply Chain' },
//     { code: 143, groups: ['good'], description: 'Luxury Goods & Jewelry' },
//     { code: 55, groups: ['man'], description: 'Machinery' },
//     { code: 11, groups: ['corp'], description: 'Management Consulting' },
//     { code: 95, groups: ['tran'], description: 'Maritime' },
//     { code: 97, groups: ['corp'], description: 'Market Research' },
//     { code: 80, groups: ['corp', 'med'], description: 'Marketing and Advertising' },
//     { code: 135, groups: ['cons', 'gov', 'man'], description: 'Mechanical or Industrial Engineering' },
//     { code: 126, groups: ['med', 'rec'], description: 'Media Production' },
//     { code: 17, groups: ['hlth'], description: 'Medical Devices' },
//     { code: 13, groups: ['hlth'], description: 'Medical Practice' },
//     { code: 139, groups: ['hlth'], description: 'Mental Health Care' },
//     { code: 71, groups: ['gov'], description: 'Military' },
//     { code: 56, groups: ['man'], description: 'Mining & Metals' },
//     { code: 35, groups: ['art', 'med', 'rec'], description: 'Motion Pictures and Film' },
//     { code: 37, groups: ['art', 'med', 'rec'], description: 'Museums and Institutions' },
//     { code: 115, groups: ['art', 'rec'], description: 'Music' },
//     { code: 114, groups: ['gov', 'man', 'tech'], description: 'Nanotechnology' },
//     { code: 81, groups: ['med', 'rec'], description: 'Newspapers' },
//     { code: 100, groups: ['org'], description: 'Non-Profit Organization Management' },
//     { code: 57, groups: ['man'], description: 'Oil & Energy' },
//     { code: 113, groups: ['med'], description: 'Online Media' },
//     { code: 123, groups: ['corp'], description: 'Outsourcing/ Offshoring' },
//     { code: 87, groups: ['serv', 'tran'], description: 'Package/ Freight Delivery' },
//     { code: 146, groups: ['good', 'man'], description: 'Packaging and Containers' },
//     { code: 61, groups: ['man'], description: 'Paper & Forest Products' },
//     { code: 39, groups: ['art', 'med', 'rec'], description: 'Performing Arts' },
//     { code: 15, groups: ['hlth', 'tech'], description: 'Pharmaceuticals' },
//     { code: 131, groups: ['org'], description: 'Philanthropy' },
//     { code: 136, groups: ['art', 'med', 'rec'], description: 'Photography' },
//     { code: 117, groups: ['man'], description: 'Plastics' },
//     { code: 107, groups: ['gov', 'org'], description: 'Political Organization' },
//     { code: 67, groups: ['edu'], description: 'Primary/ Secondary Education' },
//     { code: 83, groups: ['med', 'rec'], description: 'Printing' },
//     { code: 105, groups: ['corp'], description: 'Professional Training & Coaching' },
//     { code: 102, groups: ['corp', 'org'], description: 'Program Development' },
//     { code: 79, groups: ['gov'], description: 'Public Policy' },
//     { code: 98, groups: ['corp'], description: 'Public Relations and Communications' },
//     { code: 78, groups: ['gov'], description: 'Public Safety' },
//     { code: 82, groups: ['med', 'rec'], description: 'Publishing' },
//     { code: 62, groups: ['man'], description: 'Railroad Manufacture' },
//     { code: 64, groups: ['agr'], description: 'Ranching' },
//     { code: 44, groups: ['cons', 'fin', 'good'], description: 'Real Estate' },
//     { code: 40, groups: ['rec', 'serv'], description: 'Recreational Facilities and Services' },
//     { code: 89, groups: ['org', 'serv'], description: 'Religious Institutions' },
//     { code: 144, groups: ['gov', 'man', 'org'], description: 'Renewables & Environment' },
//     { code: 70, groups: ['edu', 'gov'], description: 'Research' },
//     { code: 32, groups: ['rec', 'serv	'], description: 'Restaurants' },
//     { code: 27, groups: ['good', 'man'], description: 'Retail' },
//     { code: 121, groups: ['corp', 'org', 'serv'], description: 'Security and Investigations' },
//     { code: 7, groups: ['tech'], description: 'Semiconductors' },
//     { code: 58, groups: ['man'], description: 'Shipbuilding' },
//     { code: 20, groups: ['good', 'rec'], description: 'Sporting Goods' },
//     { code: 33, groups: ['rec'], description: 'Sports' },
//     { code: 104, groups: ['corp'], description: 'Staffing and Recruiting' },
//     { code: 22, groups: ['good'], description: 'Supermarkets' },
//     { code: 8, groups: ['gov', 'tech'], description: 'Telecommunications' },
//     { code: 60, groups: ['man'], description: 'Textiles' },
//     { code: 130, groups: ['gov', 'org'], description: 'Think Tanks' },
//     { code: 21, groups: ['good'], description: 'Tobacco' },
//     { code: 108, groups: ['corp', 'gov', 'serv'], description: 'Translation and Localization' },
//     { code: 92, groups: ['tran'], description: 'Transportation/ Trucking / Railroad' },
//     { code: 59, groups: ['man'], description: 'Utilities' },
//     { code: 106, groups: ['fin', 'tech'], description: 'Venture Capital & Private Equity' },
//     { code: 16, groups: ['hlth'], description: 'Veterinary' },
//     { code: 93, groups: ['tran'], description: 'Warehousing' },
//     { code: 133, groups: ['good'], description: 'Wholesale' },
//     { code: 142, groups: ['good', 'man', 'rec'], description: 'Wine and Spirits' },
//     { code: 119, groups: ['tech'], description: 'Wireless' },
//     { code: 103, groups: ['art', 'med', 'rec'], description: 'Writing and Editing' },
// ]
//
// export const companySizes = ['1-2', '3-10', '11-50', '51-100', '101-1000', '1001+']
//
// export const contactPhoneTypes = ['Mobile', 'Office', 'Work', 'Personal']

/////////////////////////////
///// TIME ZONE MAPPINGS ////
/////////////////////////////

// export const timeZoneLabels = {
//     'Space': 'Space',
//     'Africa/Abidjan': '',
//     'Africa/Accra': '',
//     'Africa/Addis_Ababa': '',
//     'Africa/Algiers': '',
//     'Africa/Asmara': '',
//     'Africa/Asmera': '',
//     'Africa/Bamako': '',
//     'Africa/Bangui': '',
//     'Africa/Banjul': '',
//     'Africa/Bissau': '',
//     'Africa/Blantyre': '',
//     'Africa/Brazzaville': '',
//     'Africa/Bujumbura': '',
//     'Africa/Cairo': 'EG, CAIRO',
//     'Africa/Casablanca': '',
//     'Africa/Ceuta': '',
//     'Africa/Conakry': '',
//     'Africa/Dakar': '',
//     'Africa/Dar_es_Salaam': '',
//     'Africa/Djibouti': '',
//     'Africa/Douala': '',
//     'Africa/El_Aaiun': '',
//     'Africa/Freetown': '',
//     'Africa/Gaborone': '',
//     'Africa/Harare': '',
//     'Africa/Johannesburg': '',
//     'Africa/Juba': '',
//     'Africa/Kampala': '',
//     'Africa/Khartoum': '',
//     'Africa/Kigali': '',
//     'Africa/Kinshasa': '',
//     'Africa/Lagos': '',
//     'Africa/Libreville': '',
//     'Africa/Lome': '',
//     'Africa/Luanda': '',
//     'Africa/Lubumbashi': '',
//     'Africa/Lusaka': '',
//     'Africa/Malabo': '',
//     'Africa/Maputo': '',
//     'Africa/Maseru': '',
//     'Africa/Mbabane': '',
//     'Africa/Mogadishu': '',
//     'Africa/Monrovia': '',
//     'Africa/Nairobi': '',
//     'Africa/Ndjamena': '',
//     'Africa/Niamey': '',
//     'Africa/Nouakchott': '',
//     'Africa/Ouagadougou': '',
//     'Africa/Porto-Novo': '',
//     'Africa/Sao_Tome': '',
//     'Africa/Timbuktu': '',
//     'Africa/Tripoli': '',
//     'Africa/Tunis': '',
//     'Africa/Windhoek': '',
//     'America/Adak': '',
//     'America/Anchorage': 'AK',
//     'America/Anguilla': '',
//     'America/Antigua': '',
//     'America/Araguaina': '',
//     'America/Argentina/Buenos_Aires': 'AR',
//     'America/Argentina/Catamarca': 'AR',
//     'America/Argentina/ComodRivadavia': 'AR',
//     'America/Argentina/Cordoba': 'AR',
//     'America/Argentina/Jujuy': 'AR',
//     'America/Argentina/La_Rioja': 'AR',
//     'America/Argentina/Mendoza': 'AR',
//     'America/Argentina/Rio_Gallegos': 'AR',
//     'America/Argentina/Salta': 'AR',
//     'America/Argentina/San_Juan': 'AR',
//     'America/Argentina/San_Luis': 'AR',
//     'America/Argentina/Tucuman': 'AR',
//     'America/Argentina/Ushuaia': 'AR',
//     'America/Aruba': '',
//     'America/Asuncion': '',
//     'America/Atikokan': '',
//     'America/Atka': '',
//     'America/Bahia': '',
//     'America/Bahia_Banderas': '',
//     'America/Barbados': '',
//     'America/Belem': '',
//     'America/Belize': '',
//     'America/Blanc-Sablon': '',
//     'America/Boa_Vista': '',
//     'America/Bogota': '',
//     'America/Boise': '',
//     'America/Buenos_Aires': 'AR',
//     'America/Cambridge_Bay': '',
//     'America/Campo_Grande': '',
//     'America/Cancun': '',
//     'America/Caracas': '',
//     'America/Catamarca': '',
//     'America/Cayenne': '',
//     'America/Cayman': '',
//     'America/Chicago': 'USA, CHI',
//     'America/Chihuahua': '',
//     'America/Coral_Harbour': '',
//     'America/Cordoba': '',
//     'America/Costa_Rica': '',
//     'America/Creston': '',
//     'America/Cuiaba': '',
//     'America/Curacao': '',
//     'America/Danmarkshavn': '',
//     'America/Dawson': '',
//     'America/Dawson_Creek': '',
//     'America/Denver': 'USA, CO',
//     'America/Detroit': '',
//     'America/Dominica': '',
//     'America/Edmonton': '',
//     'America/Eirunepe': '',
//     'America/El_Salvador': '',
//     'America/Ensenada': '',
//     'America/Fort_Nelson': '',
//     'America/Fort_Wayne': '',
//     'America/Fortaleza': '',
//     'America/Glace_Bay': '',
//     'America/Godthab': '',
//     'America/Goose_Bay': '',
//     'America/Grand_Turk': '',
//     'America/Grenada': '',
//     'America/Guadeloupe': '',
//     'America/Guatemala': '',
//     'America/Guayaquil': '',
//     'America/Guyana': '',
//     'America/Halifax': '',
//     'America/Havana': '',
//     'America/Hermosillo': '',
//     'America/Indiana/Indianapolis': '',
//     'America/Indiana/Knox': '',
//     'America/Indiana/Marengo': '',
//     'America/Indiana/Petersburg': '',
//     'America/Indiana/Tell_City': '',
//     'America/Indiana/Vevay': '',
//     'America/Indiana/Vincennes': '',
//     'America/Indiana/Winamac': '',
//     'America/Indianapolis': '',
//     'America/Inuvik': '',
//     'America/Iqaluit': '',
//     'America/Jamaica': '',
//     'America/Jujuy': '',
//     'America/Juneau': '',
//     'America/Kentucky/Louisville': '',
//     'America/Kentucky/Monticello': '',
//     'America/Knox_IN': 'USA, IN',
//     'America/Kralendijk': '',
//     'America/La_Paz': '',
//     'America/Lima': '',
//     'America/Los_Angeles': 'USA, LA',
//     'America/Louisville': '',
//     'America/Lower_Princes': '',
//     'America/Maceio': '',
//     'America/Managua': '',
//     'America/Manaus': '',
//     'America/Marigot': '',
//     'America/Martinique': '',
//     'America/Matamoros': '',
//     'America/Mazatlan': '',
//     'America/Mendoza': '',
//     'America/Menominee': '',
//     'America/Merida': '',
//     'America/Metlakatla': '',
//     'America/Mexico_City': '',
//     'America/Miquelon': '',
//     'America/Moncton': '',
//     'America/Monterrey': '',
//     'America/Montevideo': '',
//     'America/Montreal': 'CA, MON',
//     'America/Montserrat': '',
//     'America/Nassau': '',
//     'America/New_York': 'USA, NYC',
//     'America/Nipigon': '',
//     'America/Nome': '',
//     'America/Noronha': '',
//     'America/North_Dakota/Beulah': '',
//     'America/North_Dakota/Center': '',
//     'America/North_Dakota/New_Salem': '',
//     'America/Ojinaga': '',
//     'America/Panama': '',
//     'America/Pangnirtung': '',
//     'America/Paramaribo': '',
//     'America/Phoenix': '',
//     'America/Port-au-Prince': '',
//     'America/Port_of_Spain': '',
//     'America/Porto_Acre': '',
//     'America/Porto_Velho': '',
//     'America/Puerto_Rico': 'PR',
//     'America/Punta_Arenas': '',
//     'America/Rainy_River': '',
//     'America/Rankin_Inlet': '',
//     'America/Recife': '',
//     'America/Regina': '',
//     'America/Resolute': '',
//     'America/Rio_Branco': '',
//     'America/Rosario': '',
//     'America/Santa_Isabel': '',
//     'America/Santarem': '',
//     'America/Santiago': '',
//     'America/Santo_Domingo': '',
//     'America/Sao_Paulo': '',
//     'America/Scoresbysund': '',
//     'America/Shiprock': '',
//     'America/Sitka': '',
//     'America/St_Barthelemy': '',
//     'America/St_Johns': '',
//     'America/St_Kitts': '',
//     'America/St_Lucia': '',
//     'America/St_Thomas': '',
//     'America/St_Vincent': '',
//     'America/Swift_Current': '',
//     'America/Tegucigalpa': '',
//     'America/Thule': '',
//     'America/Thunder_Bay': '',
//     'America/Tijuana': '',
//     'America/Toronto': 'CA, TOR',
//     'America/Tortola': '',
//     'America/Vancouver': 'CA, VC',
//     'America/Virgin': '',
//     'America/Whitehorse': '',
//     'America/Winnipeg': '',
//     'America/Yakutat': '',
//     'America/Yellowknife': '',
//     'Antarctica/Casey': 'AQ',
//     'Antarctica/Davis': 'AQ',
//     'Antarctica/DumontDUrville': 'AQ',
//     'Antarctica/Macquarie': 'AQ',
//     'Antarctica/Mawson': 'AQ',
//     'Antarctica/McMurdo': 'AQ',
//     'Antarctica/Palmer': 'AQ',
//     'Antarctica/Rothera': 'AQ',
//     'Antarctica/South_Pole': 'AQ',
//     'Antarctica/Syowa': 'AQ',
//     'Antarctica/Troll': 'AQ',
//     'Antarctica/Vostok': 'AQ',
//     'Arctic/Longyearbyen': 'AQ',
//     'Asia/Aden': '',
//     'Asia/Almaty': '',
//     'Asia/Amman': '',
//     'Asia/Anadyr': '',
//     'Asia/Aqtau': '',
//     'Asia/Aqtobe': '',
//     'Asia/Ashgabat': '',
//     'Asia/Ashkhabad': '',
//     'Asia/Atyrau': '',
//     'Asia/Baghdad': '',
//     'Asia/Bahrain': '',
//     'Asia/Baku': '',
//     'Asia/Bangkok': '',
//     'Asia/Barnaul': '',
//     'Asia/Beirut': '',
//     'Asia/Bishkek': '',
//     'Asia/Brunei': '',
//     'Asia/Calcutta': 'IN',
//     'Asia/Chita': '',
//     'Asia/Choibalsan': '',
//     'Asia/Chongqing': '',
//     'Asia/Chungking': '',
//     'Asia/Colombo': '',
//     'Asia/Dacca': '',
//     'Asia/Damascus': '',
//     'Asia/Dhaka': '',
//     'Asia/Dili': 'IN',
//     'Asia/Dubai': 'UAE, DUBAI',
//     'Asia/Dushanbe': '',
//     'Asia/Famagusta': '',
//     'Asia/Gaza': '',
//     'Asia/Harbin': '',
//     'Asia/Hebron': '',
//     'Asia/Ho_Chi_Minh': '',
//     'Asia/Hong_Kong': '',
//     'Asia/Hovd': '',
//     'Asia/Irkutsk': '',
//     'Asia/Istanbul': '',
//     'Asia/Jakarta': '',
//     'Asia/Jayapura': '',
//     'Asia/Jerusalem': '',
//     'Asia/Kabul': '',
//     'Asia/Kamchatka': '',
//     'Asia/Karachi': 'PAK',
//     'Asia/Kashgar': '',
//     'Asia/Kathmandu': '',
//     'Asia/Katmandu': '',
//     'Asia/Khandyga': '',
//     'Asia/Kolkata': 'IND',
//     'Asia/Krasnoyarsk': '',
//     'Asia/Kuala_Lumpur': '',
//     'Asia/Kuching': '',
//     'Asia/Kuwait': '',
//     'Asia/Macao': '',
//     'Asia/Macau': '',
//     'Asia/Magadan': '',
//     'Asia/Makassar': '',
//     'Asia/Manila': '',
//     'Asia/Muscat': '',
//     'Asia/Nicosia': '',
//     'Asia/Novokuznetsk': '',
//     'Asia/Novosibirsk': '',
//     'Asia/Omsk': '',
//     'Asia/Oral': '',
//     'Asia/Phnom_Penh': '',
//     'Asia/Pontianak': '',
//     'Asia/Pyongyang': '',
//     'Asia/Qatar': '',
//     'Asia/Qyzylorda': '',
//     'Asia/Rangoon': '',
//     'Asia/Riyadh': '',
//     'Asia/Saigon': '',
//     'Asia/Sakhalin': '',
//     'Asia/Samarkand': '',
//     'Asia/Seoul': '',
//     'Asia/Shanghai': '',
//     'Asia/Singapore': '',
//     'Asia/Srednekolymsk': '',
//     'Asia/Taipei': '',
//     'Asia/Tashkent': '',
//     'Asia/Tbilisi': '',
//     'Asia/Tehran': '',
//     'Asia/Tel_Aviv': '',
//     'Asia/Thimbu': '',
//     'Asia/Thimphu': '',
//     'Asia/Tokyo': 'JP, TOKYO',
//     'Asia/Tomsk': '',
//     'Asia/Ujung_Pandang': '',
//     'Asia/Ulaanbaatar': '',
//     'Asia/Ulan_Bator': '',
//     'Asia/Urumqi': '',
//     'Asia/Ust-Nera': '',
//     'Asia/Vientiane': '',
//     'Asia/Vladivostok': '',
//     'Asia/Yakutsk': '',
//     'Asia/Yangon': '',
//     'Asia/Yekaterinburg': '',
//     'Asia/Yerevan': '',
//     'Atlantic/Azores': '',
//     'Atlantic/Bermuda': '',
//     'Atlantic/Canary': '',
//     'Atlantic/Cape_Verde': '',
//     'Atlantic/Faeroe': '',
//     'Atlantic/Faroe': '',
//     'Atlantic/Jan_Mayen': '',
//     'Atlantic/Madeira': '',
//     'Atlantic/Reykjavik': '',
//     'Atlantic/South_Georgia': '',
//     'Atlantic/St_Helena': '',
//     'Atlantic/Stanley': '',
//     'Australia/ACT': 'AU',
//     'Australia/Adelaide': 'AU',
//     'Australia/Brisbane': 'AU',
//     'Australia/Broken_Hill': 'AU',
//     'Australia/Canberra': 'AU',
//     'Australia/Currie': 'AU',
//     'Australia/Darwin': 'AU',
//     'Australia/Eucla': 'AU',
//     'Australia/Hobart': 'AU',
//     'Australia/LHI': 'AU',
//     'Australia/Lindeman': 'AU',
//     'Australia/Lord_Howe': 'AU',
//     'Australia/Melbourne': 'AU',
//     'Australia/NSW': 'AU',
//     'Australia/North': 'AU',
//     'Australia/Perth': 'AU',
//     'Australia/Queensland': 'AU',
//     'Australia/South': 'AU',
//     'Australia/Sydney': 'AU',
//     'Australia/Tasmania': 'AU',
//     'Australia/Victoria': 'AU',
//     'Australia/West': 'AU',
//     'Australia/Yancowinna': 'AU',
//     'Brazil/Acre': 'BR',
//     'Brazil/DeNoronha': 'BR',
//     'Brazil/East': 'BR',
//     'Brazil/West': 'BR',
//     'CET': '',
//     'CST6CDT': '',
//     'Canada/Atlantic': 'CA',
//     'Canada/Central': 'CA',
//     'Canada/Eastern': 'CA',
//     'Canada/Mountain': 'CA',
//     'Canada/Newfoundland': 'CA',
//     'Canada/Pacific': 'CA',
//     'Canada/Saskatchewan': 'CA',
//     'Canada/Yukon': 'CA',
//     'Chile/Continental': '',
//     'Chile/EasterIsland': '',
//     'Cuba': '',
//     'EET': '',
//     'EST': '',
//     'EST5EDT': '',
//     'Egypt': '',
//     'Eire': '',
//     'Etc/GMT': '',
//     'Etc/GMT+0': '',
//     'Etc/GMT+1': '',
//     'Etc/GMT+10': '',
//     'Etc/GMT+11': '',
//     'Etc/GMT+12': 'IDL',
//     'Etc/GMT+2': '',
//     'Etc/GMT+3': '',
//     'Etc/GMT+4': '',
//     'Etc/GMT+5': '',
//     'Etc/GMT+6': '',
//     'Etc/GMT+7': '',
//     'Etc/GMT+8': '',
//     'Etc/GMT+9': '',
//     'Etc/GMT-0': '',
//     'Etc/GMT-1': '',
//     'Etc/GMT-10': '',
//     'Etc/GMT-11': '',
//     'Etc/GMT-12': '',
//     'Etc/GMT-13': '',
//     'Etc/GMT-14': '',
//     'Etc/GMT-2': '',
//     'Etc/GMT-3': '',
//     'Etc/GMT-4': '',
//     'Etc/GMT-5': '',
//     'Etc/GMT-6': '',
//     'Etc/GMT-7': '',
//     'Etc/GMT-8': '',
//     'Etc/GMT-9': '',
//     'Etc/GMT0': '',
//     'Etc/Greenwich': '',
//     'Etc/UCT': '',
//     'Etc/UTC': 'UTC',
//     'Etc/Universal': 'UTC',
//     'Etc/Zulu': 'ZULU',
//     'Europe/Amsterdam': '',
//     'Europe/Andorra': '',
//     'Europe/Astrakhan': '',
//     'Europe/Athens': 'GR, ATHENS',
//     'Europe/Belfast': '',
//     'Europe/Belgrade': '',
//     'Europe/Berlin': '',
//     'Europe/Bratislava': '',
//     'Europe/Brussels': '',
//     'Europe/Bucharest': '',
//     'Europe/Budapest': '',
//     'Europe/Busingen': '',
//     'Europe/Chisinau': '',
//     'Europe/Copenhagen': '',
//     'Europe/Dublin': 'IE, DUBLIN',
//     'Europe/Gibraltar': '',
//     'Europe/Guernsey': '',
//     'Europe/Helsinki': '',
//     'Europe/Isle_of_Man': '',
//     'Europe/Istanbul': '',
//     'Europe/Jersey': '',
//     'Europe/Kaliningrad': '',
//     'Europe/Kiev': 'RU',
//     'Europe/Kirov': 'RU',
//     'Europe/Lisbon': '',
//     'Europe/Ljubljana': '',
//     'Europe/London': 'UK, LON',
//     'Europe/Luxembourg': '',
//     'Europe/Madrid': '',
//     'Europe/Malta': '',
//     'Europe/Mariehamn': '',
//     'Europe/Minsk': '',
//     'Europe/Monaco': '',
//     'Europe/Moscow': 'RU, MOS',
//     'Europe/Nicosia': '',
//     'Europe/Oslo': '',
//     'Europe/Paris': 'FR, PARIS',
//     'Europe/Podgorica': '',
//     'Europe/Prague': '',
//     'Europe/Riga': '',
//     'Europe/Rome': 'IT, ROME',
//     'Europe/Samara': '',
//     'Europe/San_Marino': '',
//     'Europe/Sarajevo': '',
//     'Europe/Saratov': '',
//     'Europe/Simferopol': '',
//     'Europe/Skopje': '',
//     'Europe/Sofia': '',
//     'Europe/Stockholm': '',
//     'Europe/Tallinn': '',
//     'Europe/Tirane': '',
//     'Europe/Tiraspol': '',
//     'Europe/Ulyanovsk': '',
//     'Europe/Uzhgorod': '',
//     'Europe/Vaduz': '',
//     'Europe/Vatican': '',
//     'Europe/Vienna': '',
//     'Europe/Vilnius': '',
//     'Europe/Volgograd': '',
//     'Europe/Warsaw': 'PL, WAR',
//     'Europe/Zagreb': '',
//     'Europe/Zaporozhye': '',
//     'Europe/Zurich': '',
//     'GB': '',
//     'GB-Eire': '',
//     'GMT': '',
//     'GMT+0': '',
//     'GMT-0': '',
//     'GMT0': '',
//     'Greenwich': '',
//     'HST': '',
//     'Hongkong': '',
//     'Iceland': '',
//     'Indian/Antananarivo': '',
//     'Indian/Chagos': '',
//     'Indian/Christmas': '',
//     'Indian/Cocos': '',
//     'Indian/Comoro': '',
//     'Indian/Kerguelen': '',
//     'Indian/Mahe': '',
//     'Indian/Maldives': '',
//     'Indian/Mauritius': '',
//     'Indian/Mayotte': '',
//     'Indian/Reunion': '',
//     'Iran': '',
//     'Israel': '',
//     'Jamaica': '',
//     'Japan': 'JO',
//     'Kwajalein': '',
//     'Libya': '',
//     'MET': '',
//     'MST': '',
//     'MST7MDT': '',
//     'Mexico/BajaNorte': '',
//     'Mexico/BajaSur': '',
//     'Mexico/General': '',
//     'NZ': '',
//     'NZ-CHAT': '',
//     'Navajo': '',
//     'PRC': '',
//     'PST8PDT': '',
//     'Pacific/Apia': '',
//     'Pacific/Auckland': 'NZ, AUK',
//     'Pacific/Bougainville': '',
//     'Pacific/Chatham': '',
//     'Pacific/Chuuk': '',
//     'Pacific/Easter': '',
//     'Pacific/Efate': '',
//     'Pacific/Enderbury': '',
//     'Pacific/Fakaofo': '',
//     'Pacific/Fiji': 'FIJI',
//     'Pacific/Funafuti': '',
//     'Pacific/Galapagos': '',
//     'Pacific/Gambier': '',
//     'Pacific/Guadalcanal': '',
//     'Pacific/Guam': '',
//     'Pacific/Honolulu': 'USA, HON',
//     'Pacific/Johnston': '',
//     'Pacific/Kiritimati': '',
//     'Pacific/Kosrae': '',
//     'Pacific/Kwajalein': '',
//     'Pacific/Majuro': '',
//     'Pacific/Marquesas': '',
//     'Pacific/Midway': '',
//     'Pacific/Nauru': '',
//     'Pacific/Niue': '',
//     'Pacific/Norfolk': '',
//     'Pacific/Noumea': '',
//     'Pacific/Pago_Pago': '',
//     'Pacific/Palau': '',
//     'Pacific/Pitcairn': '',
//     'Pacific/Pohnpei': '',
//     'Pacific/Ponape': '',
//     'Pacific/Port_Moresby': '',
//     'Pacific/Rarotonga': '',
//     'Pacific/Saipan': '',
//     'Pacific/Samoa': '',
//     'Pacific/Tahiti': '',
//     'Pacific/Tarawa': '',
//     'Pacific/Tongatapu': '',
//     'Pacific/Truk': '',
//     'Pacific/Wake': '',
//     'Pacific/Wallis': '',
//     'Pacific/Yap': '',
//     'Poland': '',
//     'Portugal': '',
//     'ROC': '',
//     'ROK': '',
//     'Singapore': '',
//     'Turkey': '',
//     'UCT': '',
//     'US/Alaska': 'AK',
//     'US/Aleutian': '',
//     'US/Arizona': 'AZ',
//     'US/Central': 'CST',
//     'US/East-Indiana': '',
//     'US/Eastern': 'EST',
//     'US/Hawaii': 'HI',
//     'US/Indiana-Starke': '',
//     'US/Michigan': 'MI',
//     'US/Mountain': 'MST',
//     'US/Pacific': 'PST',
//     'US/Pacific-New': '',
//     'US/Samoa': '',
//     'UTC': 'UTC',
//     'Universal': 'UTC',
//     'W-SU': '',
//     'WET': '',
//     'Zulu': '',
// }

export const timeZoneMap = {
    'Space': 'Space',
    'Africa/Abidjan': 'Etc/Greenwich', // +0
    'Africa/Accra': 'Etc/Greenwich', // +0
    'Africa/Addis_Ababa': 'Africa/Nairobi', // +3
    'Africa/Algiers': 'Africa/Lagos', // +1
    'Africa/Asmara': 'Africa/Nairobi', // +3
    'Africa/Asmera': 'Africa/Nairobi', // +3
    'Africa/Bamako': 'Etc/Greenwich', // +0
    'Africa/Bangui': 'Africa/Lagos', // +1
    'Africa/Banjul': 'Africa/Lagos', // +1
    'Africa/Bissau': 'Etc/Greenwich', // +0
    'Africa/Blantyre': 'Africa/Harare', // +2
    'Africa/Brazzaville': 'Africa/Lagos', // +1
    'Africa/Bujumbura': 'Africa/Harare', // +2
    'Africa/Cairo': 'Africa/Cairo',
    'Africa/Casablanca': 'Africa/Casablanca',
    'Africa/Ceuta': 'Africa/Lagos', // +1
    'Africa/Conakry': 'Etc/Greenwich', // +0
    'Africa/Dakar': 'Etc/Greenwich', // +0
    'Africa/Dar_es_Salaam': 'Africa/Nairobi', // +3
    'Africa/Djibouti': 'Africa/Nairobi', // +3
    'Africa/Douala': 'Africa/Lagos', // +1
    'Africa/El_Aaiun': 'Africa/Lagos', // +1
    'Africa/Freetown': 'Etc/Greenwich', // +0
    'Africa/Gaborone': 'Africa/Harare', // +2
    'Africa/Harare': 'Africa/Harare', // +2
    'Africa/Johannesburg': 'Africa/Harare', // +2
    'Africa/Juba': 'Africa/Nairobi', // +3
    'Africa/Kampala': 'Africa/Nairobi', // +3
    'Africa/Khartoum': 'Africa/Windhoek', // +2
    'Africa/Kigali': 'Africa/Windhoek', // +2
    'Africa/Kinshasa': 'Africa/Lagos', // +1
    'Africa/Lagos': 'Africa/Lagos', // +1
    'Africa/Libreville': 'Africa/Lagos', // +1
    'Africa/Lome': 'Etc/Greenwich', // +0
    'Africa/Luanda': 'Africa/Lagos', // +1
    'Africa/Lubumbashi': 'Africa/Windhoek', // +2
    'Africa/Lusaka': 'Africa/Windhoek', // +2
    'Africa/Malabo': 'Africa/Lagos', // +1
    'Africa/Maputo': 'Africa/Windhoek', // +2
    'Africa/Maseru': 'Africa/Windhoek', // +2
    'Africa/Mbabane': 'Africa/Windhoek', // +2
    'Africa/Mogadishu': 'Africa/Nairobi', // +3
    'Africa/Monrovia': 'Etc/Greenwich', // +0
    'Africa/Nairobi': 'Africa/Nairobi', // +3
    'Africa/Ndjamena': 'Africa/Lagos', // +1
    'Africa/Niamey': 'Africa/Lagos', // +1
    'Africa/Nouakchott': 'Etc/Greenwich', // +0
    'Africa/Ouagadougou': 'Etc/Greenwich', // +0
    'Africa/Porto-Novo': 'Africa/Lagos', // +1
    'Africa/Sao_Tome': 'Africa/Lagos', // +1
    'Africa/Timbuktu': 'Etc/Greenwich', // +0
    'Africa/Tripoli': 'Africa/Cairo', // +2
    'Africa/Tunis': 'Africa/Lagos', // +1
    'Africa/Windhoek': 'Africa/Windhoek', // +2
    'America/Adak': 'Pacific/Honolulu', // -10 hawaii
    'America/Anchorage': 'US/Alaska',
    'America/Anguilla': 'Canada/Atlantic', // -4 atlantic
    'America/Antigua': 'Canada/Atlantic', // -4 atlantic
    'America/Araguaina': 'America/Sao_Paulo', // -3 brazil
    'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca': 'America/Argentina/Buenos_Aires',
    'America/Argentina/ComodRivadavia': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Jujuy': 'America/Argentina/Buenos_Aires',
    'America/Argentina/La_Rioja': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Mendoza': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Rio_Gallegos': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Salta': 'America/Argentina/Buenos_Aires',
    'America/Argentina/San_Juan': 'America/Argentina/Buenos_Aires',
    'America/Argentina/San_Luis': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Tucuman': 'America/Argentina/Buenos_Aires',
    'America/Argentina/Ushuaia': 'America/Argentina/Buenos_Aires',
    'America/Aruba': 'Canada/Atlantic', // -4 atlantic
    'America/Asuncion': 'America/Sao_Paulo',
    'America/Atikokan': 'US/Eastern',
    'America/Atka': 'US/Alaska',
    'America/Bahia': 'America/Sao_Paulo',
    'America/Bahia_Banderas': 'America/Sao_Paulo',
    'America/Barbados': 'Canada/Atlantic',
    'America/Belem': 'America/Sao_Paulo',
    'America/Belize': 'America/Managua', // -6 central am // NEXT
    'America/Blanc-Sablon': 'Canada/Atlantic',
    'America/Boa_Vista': 'America/Caracas', // -4 brazil
    'America/Bogota': 'America/Bogota',
    'America/Boise': 'US/Mountain', // mst
    'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
    'America/Cambridge_Bay': 'US/Mountain', // mst
    'America/Campo_Grande': 'America/Sao_Paulo',
    'America/Cancun': 'US/Eastern', // est
    'America/Caracas': 'America/Caracas',
    'America/Catamarca': 'America/Argentina/Buenos_Aires',
    'America/Cayenne': 'America/Sao_Paulo',
    'America/Cayman': 'US/Eastern', // est
    'America/Chicago': 'US/Central', // cst
    'America/Chihuahua': 'America/Chihuahua', // -7
    'America/Coral_Harbour': 'US/Eastern', // est
    'America/Cordoba': 'America/Argentina/Buenos_Aires',
    'America/Costa_Rica': 'America/Managua', // -6 central am
    'America/Creston': 'US/Mountain', // mst
    'America/Cuiaba': 'America/Sao_Paulo',
    'America/Curacao': 'Canada/Atlantic',
    'America/Danmarkshavn': 'Etc/Greenwich', // +0
    'America/Dawson': 'America/Los_Angeles', // pst
    'America/Dawson_Creek': 'US/Mountain', // mst
    'America/Denver': 'US/Mountain', // mst
    'America/Detroit': 'US/Eastern', // est
    'America/Dominica': 'Canada/Atlantic',
    'America/Edmonton': 'US/Mountain', // mst
    'America/Eirunepe': 'America/Bogota',
    'America/El_Salvador': 'America/Managua',
    'America/Ensenada': 'America/Tijuana',
    'America/Fort_Nelson': 'US/Mountain', // mst
    'America/Fort_Wayne': 'US/Eastern', // est
    'America/Fortaleza': 'America/Sao_Paulo',
    'America/Glace_Bay': 'Canada/Atlantic',
    'America/Godthab': 'America/Godthab', // -3 greenland
    'America/Goose_Bay': 'Canada/Atlantic',
    'America/Grand_Turk': 'US/Eastern', // est
    'America/Grenada': 'Canada/Atlantic', // -4 atlantic
    'America/Guadeloupe': 'Canada/Atlantic', // -4 atlantic
    'America/Guatemala': 'America/Managua', // central am
    'America/Guayaquil': 'America/Bogota', // -5
    'America/Guyana': 'America/Caracas',
    'America/Halifax': 'Canada/Atlantic', // -4 atlantic
    'America/Havana': 'America/Managua', // cst central am
    'America/Hermosillo': 'America/Chihuahua', // -7 mex
    'America/Indiana/Indianapolis': 'US/Eastern', // est
    'America/Indiana/Knox': 'US/Central', // cst
    'America/Indiana/Marengo': 'US/Eastern', // est
    'America/Indiana/Petersburg': 'US/Eastern', // est
    'America/Indiana/Tell_City': 'US/Central', // cst
    'America/Indiana/Vevay': 'US/Eastern', // est
    'America/Indiana/Vincennes': 'US/Eastern', // est
    'America/Indiana/Winamac': 'US/Eastern', // est
    'America/Indianapolis': 'US/Eastern', // est
    'America/Inuvik': 'US/Mountain', // mst
    'America/Iqaluit': 'US/Eastern', // est
    'America/Jamaica': 'US/Eastern', // est
    'America/Jujuy': 'America/Argentina/Buenos_Aires',
    'America/Juneau': 'US/Alaska',
    'America/Kentucky/Louisville': 'US/Eastern', // est
    'America/Kentucky/Monticello': 'US/Eastern', // est
    'America/Knox_IN': 'US/Central', // cst
    'America/Kralendijk': 'Canada/Atlantic', // -4 atlantic
    'America/La_Paz': 'America/Caracas',
    'America/Lima': 'America/Bogota',
    'America/Los_Angeles': 'US/Pacific', // pst
    'America/Louisville': 'US/Eastern', // est
    'America/Lower_Princes': 'Canada/Atlantic', // -4 atlantic
    'America/Maceio': 'America/Sao_Paulo',
    'America/Managua': 'America/Managua', // -4 central am
    'America/Manaus': 'America/Manaus',
    'America/Marigot': 'Canada/Atlantic', // -4 atlantic
    'America/Martinique': 'Canada/Atlantic', // -4 atlantic
    'America/Matamoros': 'America/Mexico_City', // -6 mex
    'America/Mazatlan': 'America/Chihuahua',
    'America/Mendoza': 'America/Argentina/Buenos_Aires',
    'America/Menominee': 'US/Central', // cst
    'America/Merida': 'America/Mexico_City',
    'America/Metlakatla': 'US/Pacific', // pst
    'America/Mexico_City': 'America/Mexico_City',
    'America/Miquelon': 'America/Godthab',
    'America/Moncton': 'Canada/Atlantic', // -4 atlantic
    'America/Monterrey': 'America/Mexico_City',
    'America/Montevideo': 'America/Montevideo',
    'America/Montreal': 'US/Eastern', // est
    'America/Montserrat': 'Canada/Atlantic', // -4 atlantic
    'America/Nassau': 'US/Eastern', // est
    'America/New_York': 'US/Eastern', // est
    'America/Nipigon': 'US/Eastern', // est
    'America/Nome': 'US/Alaska', // alaska
    'America/Noronha': 'America/Noronha', // mid atlantic
    'America/North_Dakota/Beulah': 'US/Central', // cst
    'America/North_Dakota/Center': 'US/Central', // cst
    'America/North_Dakota/New_Salem': 'US/Central', // cst
    'America/Ojinaga': 'America/Chihuahua', // -7 mex
    'America/Panama': 'America/Bogota',
    'America/Pangnirtung': 'US/Eastern', // est
    'America/Paramaribo': 'America/Sao_Paulo',
    'America/Phoenix': 'US/Mountain', // mst
    'America/Port-au-Prince': 'US/Eastern', // est
    'America/Port_of_Spain': 'Canada/Atlantic', // -4 atlantic
    'America/Porto_Acre': 'America/Bogota', // -5 brazil
    'America/Porto_Velho': 'America/Caracas', // -4 brazil
    'America/Puerto_Rico': 'Canada/Atlantic', // -4 atlantic
    'America/Punta_Arenas': 'America/Santiago',
    'America/Rainy_River': 'US/Central', // cst
    'America/Rankin_Inlet': 'US/Central', // cst
    'America/Recife': 'America/Sao_Paulo',
    'America/Regina': 'US/Central', // cst
    'America/Resolute': 'US/Central', // cst
    'America/Rio_Branco': 'America/Bogota',
    'America/Rosario': 'America/Argentina/Buenos_Aires',
    'America/Santa_Isabel': 'Canada/Atlantic', // -4 atlantic
    'America/Santarem': 'America/Sao_Paulo',
    'America/Santiago': 'America/Santiago',
    'America/Santo_Domingo': 'Canada/Atlantic', // -4 atlantic
    'America/Sao_Paulo': 'America/Sao_Paulo',
    'America/Scoresbysund': 'Atlantic/Azores',
    'America/Shiprock': 'US/Mountain', // mst
    'America/Sitka': 'US/Alaska', // alaska
    'America/St_Barthelemy': 'Canada/Atlantic', // -4 atlantic
    'America/St_Johns': 'Canada/Atlantic',
    'America/St_Kitts': 'Canada/Atlantic', // -4 atlan
    'America/St_Lucia': 'Canada/Atlantic',
    'America/St_Thomas': 'Canada/Atlantic',
    'America/St_Vincent': 'Canada/Atlantic',
    'America/Swift_Current': 'US/Central', // cst
    'America/Tegucigalpa': 'America/Managua', // central am
    'America/Thule': 'America/Godthab',
    'America/Thunder_Bay': 'US/Eastern', // est
    'America/Tijuana': 'America/Tijuana',
    'America/Toronto': 'US/Eastern', // est
    'America/Tortola': 'Canada/Atlantic', // -4 atlan
    'America/Vancouver': 'America/Los_Angeles', // pst
    'America/Virgin': 'Canada/Atlantic', // -4 atlan
    'America/Whitehorse': 'US/Pacific', // pst
    'America/Winnipeg': 'US/Central', // cst
    'America/Yakutat': 'US/Alaska', // alaska
    'America/Yellowknife': 'US/Mountain', // mst
    'Antarctica/Casey': 'Etc/Greenwich', //TODO: +11
    'Antarctica/Davis': 'Etc/Greenwich', //TODO: +7
    'Antarctica/DumontDUrville': 'Etc/Greenwich', //TODO: +10
    'Antarctica/Macquarie': 'Etc/Greenwich', // ??
    'Antarctica/Mawson': 'Etc/Greenwich', //TODO: +5
    'Antarctica/McMurdo': 'Etc/Greenwich', // ??
    'Antarctica/Palmer': 'Etc/Greenwich', // ??
    'Antarctica/Rothera': 'Etc/Greenwich', // ??
    'Antarctica/South_Pole': 'Etc/Greenwich', // ??
    'Antarctica/Syowa': 'Etc/Greenwich', // ??
    'Antarctica/Troll': 'Etc/Greenwich',
    'Antarctica/Vostok': 'Etc/Greenwich', //TODO: +6
    'Arctic/Longyearbyen': 'Europe/Amsterdam',
    'Asia/Aden': 'Asia/Kuwait', // +3
    'Asia/Almaty': 'Asia/Almaty', // +6
    'Asia/Amman': 'Asia/Amman', // +2
    'Asia/Anadyr': 'Pacific/Fiji', // +12 russia
    'Asia/Aqtau': 'Asia/Almaty',
    'Asia/Aqtobe': 'Asia/Yekaterinburg', // +5
    'Asia/Ashgabat': 'Asia/Yekaterinburg',
    'Asia/Ashkhabad': 'Asia/Yekaterinburg',
    'Asia/Atyrau': 'Asia/Yekaterinburg',
    'Asia/Baghdad': 'Asia/Kuwait',
    'Asia/Bahrain': 'Asia/Kuwait',
    'Asia/Baku': 'Asia/Yerevan',
    'Asia/Bangkok': 'Asia/Bangkok',
    'Asia/Barnaul': 'Asia/Krasnoyarsk',
    'Asia/Beirut': 'Asia/Amman',
    'Asia/Bishkek': 'Asia/Almaty',
    'Asia/Brunei': 'Asia/Kuala_Lumpur',
    'Asia/Calcutta': 'Asia/Calcutta',
    'Asia/Chita': 'Asia/Yakutsk',
    'Asia/Choibalsan': 'Asia/Hong_Kong',
    'Asia/Chongqing': 'Asia/Hong_Kong',
    'Asia/Chungking': 'Asia/Hong_Kong',
    'Asia/Colombo': 'Asia/Calcutta',
    'Asia/Dacca': 'Asia/Dhaka',
    'Asia/Damascus': 'Asia/Jerusalem',
    'Asia/Dhaka': 'Asia/Dhaka',
    'Asia/Dili': 'Asia/Calcutta',
    'Asia/Dubai': 'Asia/Muscat',
    'Asia/Dushanbe': 'Asia/Karachi',
    'Asia/Famagusta': 'Asia/Jerusalem',
    'Asia/Gaza': 'Asia/Jerusalem',
    'Asia/Harbin': 'Asia/Hong_Kong',
    'Asia/Hebron': 'Asia/Jerusalem',
    'Asia/Ho_Chi_Minh': 'Asia/Bangkok',
    'Asia/Hong_Kong': 'Asia/Hong_Kong',
    'Asia/Hovd': 'Asia/Krasnoyarsk',
    'Asia/Irkutsk': 'Asia/Irkutsk',
    'Asia/Istanbul': 'Asia/Kuwait',
    'Asia/Jakarta': 'Asia/Bangkok',
    'Asia/Jayapura': 'Asia/Tokyo',
    'Asia/Jerusalem': 'Asia/Jerusalem',
    'Asia/Kabul': 'Asia/Kabul',
    'Asia/Kamchatka': 'Pacific/Fiji',
    'Asia/Karachi': 'Asia/Karachi',
    'Asia/Kashgar': 'Asia/Hong_Kong',
    'Asia/Kathmandu': 'Asia/Katmandu',
    'Asia/Katmandu': 'Asia/Katmandu',
    'Asia/Khandyga': 'Asia/Yakutsk',
    'Asia/Kolkata': 'Asia/Calcutta',
    'Asia/Krasnoyarsk': 'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur': 'Asia/Kuala_Lumpur',
    'Asia/Kuching': 'Asia/Kuala_Lumpur',
    'Asia/Kuwait': 'Asia/Kuwait',
    'Asia/Macao': 'Asia/Hong_Kong',
    'Asia/Macau': 'Asia/Hong_Kong',
    'Asia/Magadan': 'Asia/Magadan',
    'Asia/Makassar': 'Asia/Kuala_Lumpur',
    'Asia/Manila': 'Asia/Kuala_Lumpur',
    'Asia/Muscat': 'Asia/Muscat',
    'Asia/Nicosia': 'Europe/Athens',
    'Asia/Novokuznetsk': 'Asia/Krasnoyarsk',
    'Asia/Novosibirsk': 'Asia/Krasnoyarsk',
    'Asia/Omsk': 'Asia/Almaty', // +6 russia
    'Asia/Oral': 'Asia/Yekaterinburg',
    'Asia/Phnom_Penh': 'Asia/Bangkok',
    'Asia/Pontianak': 'Asia/Bangkok',
    'Asia/Pyongyang': 'Asia/Seoul',
    'Asia/Qatar': 'Asia/Kuwait',
    'Asia/Qyzylorda': 'Asia/Almaty',
    'Asia/Rangoon': 'Asia/Rangoon',
    'Asia/Riyadh': 'Asia/Kuwait',
    'Asia/Saigon': 'Asia/Bangkok',
    'Asia/Sakhalin': 'Asia/Magadan',
    'Asia/Samarkand': 'Asia/Karachi',
    'Asia/Seoul': 'Asia/Seoul',
    'Asia/Shanghai': 'Asia/Hong_Kong',
    'Asia/Singapore': 'Asia/Kuala_Lumpur',
    'Asia/Srednekolymsk': 'Asia/Magadan',
    'Asia/Taipei': 'Asia/Taipei',
    'Asia/Tashkent': 'Asia/Almaty',
    'Asia/Tbilisi': 'Asia/Baku',
    'Asia/Tehran': 'Asia/Tehran',
    'Asia/Tel_Aviv': 'Asia/Jerusalem',
    'Asia/Thimbu': 'Asia/Dhaka',
    'Asia/Thimphu': 'Asia/Dhaka',
    'Asia/Tokyo': 'Asia/Tokyo',
    'Asia/Tomsk': 'Asia/Krasnoyarsk',
    'Asia/Ujung_Pandang': 'Asia/Kuala_Lumpur',
    'Asia/Ulaanbaatar': 'Asia/Irkutsk',
    'Asia/Ulan_Bator': 'Asia/Irkutsk',
    'Asia/Urumqi': 'Asia/Hong_Kong',
    'Asia/Ust-Nera': 'Asia/Vladivostok',
    'Asia/Vientiane': 'Asia/Bangkok',
    'Asia/Vladivostok': 'Asia/Vladivostok',
    'Asia/Yakutsk': 'Asia/Yakutsk',
    'Asia/Yangon': 'Asia/Rangoon',
    'Asia/Yekaterinburg': 'Asia/Yekaterinburg',
    'Asia/Yerevan': 'Asia/Yerevan',
    'Atlantic/Azores': 'Atlantic/Azores',
    'Atlantic/Bermuda': 'Canada/Atlantic', // -4 atlan
    'Atlantic/Canary': 'Etc/Greenwich', // +0
    'Atlantic/Cape_Verde': 'Atlantic/Cape_Verde',
    'Atlantic/Faeroe': 'Etc/Greenwich', // +0
    'Atlantic/Faroe': 'Etc/Greenwich', // +0
    'Atlantic/Jan_Mayen': 'Europe/Amsterdam',
    'Atlantic/Madeira': 'Etc/Greenwich', // +0
    'Atlantic/Reykjavik': 'Africa/Casablanca',
    'Atlantic/South_Georgia': 'America/Noronha', // -2 mid atlan
    'Atlantic/St_Helena': 'Etc/Greenwich', // +0
    'Atlantic/Stanley': 'America/Montevideo',
    'Australia/ACT': 'Australia/Sydney', // change from Asia/Magadan // review // +11 aus
    'Australia/Adelaide': 'Australia/Adelaide', // +10.5
    'Australia/Brisbane': 'Australia/Brisbane', // +10
    'Australia/Broken_Hill': 'Australia/Adelaide', // +10.5
    'Australia/Canberra': 'Australia/Canberra', // change from Asia/Magadan // review // +11
    'Australia/Currie': 'Australia/Currie', // change from Asia/Magadan // review // +11
    'Australia/Darwin': 'Australia/Darwin', // +9.5
    'Australia/Eucla': 'Australia/Perth', // +9
    'Australia/Hobart': 'Australia/Hobart', // +11
    'Australia/LHI': 'Australia/Sydney', // change from Asia/Magadan // review // +11
    'Australia/Lindeman': 'Australia/Brisbane', // +10
    'Australia/Lord_Howe': 'Australia/Lord_Howe', // change from Asia/Magadan // review // +11
    'Australia/Melbourne': 'Australia/Melbourne', // change from Asia/Magadan // review // +11
    'Australia/NSW': 'Australia/Sydney', // change from Asia/Magadan // review // +11
    'Australia/North': 'Australia/Darwin',
    'Australia/Perth': 'Australia/Perth',
    'Australia/Queensland': 'Australia/Brisbane',
    'Australia/South': 'Australia/Sydney', // change from Asia/Magadan // review
    'Australia/Sydney': 'Australia/Sydney', // change from Asia/Magadan // review
    'Australia/Tasmania': 'Australia/Sydney', // change from Asia/Magadan // review
    'Australia/Victoria': 'Australia/Victoria', // change from Asia/Magadan // review
    'Australia/West': 'Australia/Perth',
    'Australia/Yancowinna': 'Australia/Yancowinna', // change from Asia/Magadan // review
    'Brazil/Acre': 'America/Bogota',
    'Brazil/DeNoronha': 'America/Noronha',
    'Brazil/East': 'America/Sao_Paulo',
    'Brazil/West': 'America/Manaus',
    'CET': 'Europe/Amsterdam',
    'CST6CDT': 'US/Central',
    'Canada/Atlantic': 'Canada/Atlantic',
    'Canada/Central': 'US/Central',
    'Canada/Eastern': 'US/Eastern',
    'Canada/Mountain': 'US/Mountain',
    'Canada/Newfoundland': 'Canada/Newfoundland',
    'Canada/Pacific': 'America/Los_Angeles',
    'Canada/Saskatchewan': 'Canada/Saskatchewan',
    'Canada/Yukon': 'America/Los_Angeles',
    'Chile/Continental': 'America/Santiago',
    'Chile/EasterIsland': 'America/Bogota',
    'Cuba': 'US/Eastern',
    'EET': 'Europe/Minsk',
    'EST': 'US/Eastern',
    'EST5EDT': 'US/Eastern',
    'Egypt': 'Africa/Cairo',
    'Eire': 'Etc/Greenwich',
    'Etc/GMT': 'Etc/Greenwich',
    'Etc/GMT+0': 'Etc/Greenwich',
    'Etc/GMT+1': 'Europe/Amsterdam',
    'Etc/GMT+10': 'Australia/Sydney',
    'Etc/GMT+11': 'Asia/Magadan',
    'Etc/GMT+12': 'Pacific/Auckland',
    'Etc/GMT+2': 'Europe/Athens',
    'Etc/GMT+3': 'Europe/Moscow',
    'Etc/GMT+4': 'Asia/Muscat',
    'Etc/GMT+5': 'Asia/Karachi',
    'Etc/GMT+6': 'Asia/Dhaka',
    'Etc/GMT+7': 'Asia/Bangkok',
    'Etc/GMT+8': 'Asia/Hong_Kong',
    'Etc/GMT+9': 'Asia/Tokyo',
    'Etc/GMT-0': 'Etc/Greenwich',
    'Etc/GMT-1': 'Atlantic/Cape_Verde',
    'Etc/GMT-10': 'Pacific/Honolulu',
    'Etc/GMT-11': 'Pacific/Midway',
    'Etc/GMT-12': 'Etc/GMT+12',
    'Etc/GMT-13': 'Pacific/Midway',
    'Etc/GMT-14': 'Pacific/Midway',
    'Etc/GMT-2': 'America/Noronha',
    'Etc/GMT-3': 'America/Sao_Paulo',
    'Etc/GMT-4': 'Canada/Atlantic',
    'Etc/GMT-5': 'US/Eastern',
    'Etc/GMT-6': 'US/Central',
    'Etc/GMT-7': 'US/Mountain',
    'Etc/GMT-8': 'America/Los_Angeles',
    'Etc/GMT-9': 'US/Alaska',
    'Etc/GMT0': 'Etc/Greenwich',
    'Etc/Greenwich': 'Etc/Greenwich',
    'Etc/UCT': 'Etc/Greenwich',
    'Etc/UTC': 'Etc/Greenwich',
    'Etc/Universal': 'Etc/Greenwich',
    'Etc/Zulu': 'Etc/Greenwich',
    'Europe/Amsterdam': 'Europe/Amsterdam',
    'Europe/Andorra': 'Europe/Brussels',
    'Europe/Astrakhan': 'Asia/Tbilisi',
    'Europe/Athens': 'Europe/Athens',
    'Europe/Belfast': 'Etc/Greenwich',
    'Europe/Belgrade': 'Europe/Belgrade',
    'Europe/Berlin': 'Europe/Amsterdam',
    'Europe/Bratislava': 'Europe/Belgrade',
    'Europe/Brussels': 'Europe/Brussels',
    'Europe/Bucharest': 'Europe/Athens',
    'Europe/Budapest': 'Europe/Belgrade',
    'Europe/Busingen': 'Europe/Brussels',
    'Europe/Chisinau': 'Europe/Athens',
    'Europe/Copenhagen': 'Europe/Brussels',
    'Europe/Dublin': 'Etc/Greenwich',
    'Europe/Gibraltar': 'Africa/Casablanca',
    'Europe/Guernsey': 'Etc/Greenwich',
    'Europe/Helsinki': 'Europe/Helsinki',
    'Europe/Isle_of_Man': 'Etc/Greenwich',
    'Europe/Istanbul': 'Europe/Athens',
    'Europe/Jersey': 'Etc/Greenwich',
    'Europe/Kaliningrad': 'Europe/Helsinki',
    'Europe/Kiev': 'Europe/Athens',
    'Europe/Kirov': 'Europe/Moscow',
    'Europe/Lisbon': 'Etc/Greenwich',
    'Europe/Ljubljana': 'Europe/Belgrade',
    'Europe/London': 'Etc/Greenwich',
    'Europe/Luxembourg': 'Europe/Brussels',
    'Europe/Madrid': 'Europe/Brussels',
    'Europe/Malta': 'Europe/Sarajevo',
    'Europe/Mariehamn': 'Europe/Helsinki',
    'Europe/Minsk': 'Europe/Minsk',
    'Europe/Monaco': 'Europe/Brussels',
    'Europe/Moscow': 'Europe/Moscow',
    'Europe/Nicosia': 'Europe/Athens',
    'Europe/Oslo': 'Europe/Amsterdam',
    'Europe/Paris': 'Europe/Brussels',
    'Europe/Podgorica': 'Europe/Sarajevo',
    'Europe/Prague': 'Europe/Belgrade',
    'Europe/Riga': 'Europe/Helsinki',
    'Europe/Rome': 'Europe/Amsterdam',
    'Europe/Samara': 'Asia/Yerevan',
    'Europe/San_Marino': 'Europe/Amsterdam',
    'Europe/Sarajevo': 'Europe/Sarajevo',
    'Europe/Saratov': 'Asia/Yerevan',
    'Europe/Simferopol': 'Europe/Moscow',
    'Europe/Skopje': 'Europe/Sarajevo',
    'Europe/Sofia': 'Europe/Helsinki',
    'Europe/Stockholm': 'Europe/Amsterdam',
    'Europe/Tallinn': 'Europe/Helsinki',
    'Europe/Tirane': 'Europe/Sarajevo',
    'Europe/Tiraspol': 'Europe/Helsinki',
    'Europe/Ulyanovsk': 'Asia/Yerevan',
    'Europe/Uzhgorod': 'Europe/Helsinki',
    'Europe/Vaduz': 'Europe/Belgrade',
    'Europe/Vatican': 'Europe/Amsterdam',
    'Europe/Vienna': 'Europe/Amsterdam',
    'Europe/Vilnius': 'Europe/Helsinki',
    'Europe/Volgograd': 'Europe/Moscow',
    'Europe/Warsaw': 'Europe/Sarajevo',
    'Europe/Zagreb': 'Europe/Sarajevo',
    'Europe/Zaporozhye': 'Europe/Helsinki',
    'Europe/Zurich': 'Europe/Belgrade',
    'GB': 'Etc/Greenwich',
    'GB-Eire': 'Etc/Greenwich',
    'GMT': 'Etc/Greenwich',
    'GMT+0': 'Etc/Greenwich',
    'GMT-0': 'Etc/Greenwich',
    'GMT0': 'Etc/Greenwich',
    'Greenwich': 'Etc/Greenwich',
    'HST': 'Pacific/Honolulu',
    'Hongkong': 'Asia/Hong_Kong',
    'Iceland': 'Africa/Casablanca',
    'Indian/Antananarivo': 'Africa/Nairobi',
    'Indian/Chagos': 'Asia/Karachi',
    'Indian/Christmas': 'Pacific/Tongatapu', // TODO: +14
    'Indian/Cocos': 'Asia/Rangoon',
    'Indian/Comoro': 'Africa/Nairobi',
    'Indian/Kerguelen': 'Asia/Karachi',
    'Indian/Mahe': 'Africa/Nairobi',
    'Indian/Maldives': 'Asia/Karachi',
    'Indian/Mauritius': 'Asia/Muscat',
    'Indian/Mayotte': 'Africa/Nairobi',
    'Indian/Reunion': 'Asia/Muscat',
    'Iran': 'Asia/Tehran',
    'Israel': 'Asia/Jerusalem',
    'Jamaica': 'US/Eastern',
    'Japan': 'Asia/Tokyo',
    'Kwajalein': 'Pacific/Fiji',
    'Libya': 'Africa/Cairo',
    'MET': 'Europe/Sarajevo',
    'MST': 'US/Mountain',
    'MST7MDT': 'US/Mountain',
    'Mexico/BajaNorte': 'America/Tijuana',
    'Mexico/BajaSur': 'America/Tijuana',
    'Mexico/General': 'America/Mexico_City',
    'NZ': 'Pacific/Tongatapu',
    'NZ-CHAT': 'Pacific/Tongatapu',
    'Navajo': 'US/Mountain',
    'PRC': 'Asia/Hong_Kong',
    'PST8PDT': 'America/Los_Angeles',
    'Pacific/Apia': 'Pacific/Tongatapu', //TODO: +14
    'Pacific/Auckland': 'Pacific/Auckland',
    'Pacific/Bougainville': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Chatham': 'Pacific/Auckland',
    'Pacific/Chuuk': 'Pacific/Auckland',
    'Pacific/Easter': 'America/Bogota',
    'Pacific/Efate': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Enderbury': 'Pacific/Tongatapu',
    'Pacific/Fakaofo': 'Pacific/Tongatapu',
    'Pacific/Fiji': 'Pacific/Fiji',
    'Pacific/Funafuti': 'Pacific/Auckland',
    'Pacific/Galapagos': 'America/Managua',
    'Pacific/Gambier': 'US/Alaska',
    'Pacific/Guadalcanal': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Guam': 'Pacific/Guam',
    'Pacific/Honolulu': 'Pacific/Honolulu',
    'Pacific/Johnston': 'Pacific/Honolulu',
    'Pacific/Kiritimati': 'Pacific/Tongatapu', // TODO: +14
    'Pacific/Kosrae': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Kwajalein': 'Pacific/Fiji',
    'Pacific/Majuro': 'Pacific/Fiji',
    'Pacific/Marquesas': 'US/Alaska',
    'Pacific/Midway': 'Pacific/Midway',
    'Pacific/Nauru': 'Pacific/Auckland',
    'Pacific/Niue': 'Pacific/Midway',
    'Pacific/Norfolk': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Noumea': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Pago_Pago': 'Pacific/Midway',
    'Pacific/Palau': 'Australia/Adelaide',
    'Pacific/Pitcairn': 'America/Los_Angeles',
    'Pacific/Pohnpei': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Ponape': 'Australia/Sydney', // change from Asia/Magadan // review
    'Pacific/Port_Moresby': 'Pacific/Guam',
    'Pacific/Rarotonga': 'Pacific/Honolulu',
    'Pacific/Saipan': 'Pacific/Guam',
    'Pacific/Samoa': 'Pacific/Midway',
    'Pacific/Tahiti': 'Pacific/Honolulu',
    'Pacific/Tarawa': 'Pacific/Auckland',
    'Pacific/Tongatapu': 'Pacific/Tongatapu',
    'Pacific/Truk': 'Pacific/Guam',
    'Pacific/Wake': 'Pacific/Auckland',
    'Pacific/Wallis': 'Pacific/Auckland',
    'Pacific/Yap': 'Pacific/Guam',
    'Poland': 'Europe/Sarajevo',
    'Portugal': 'Etc/Greenwich',
    'ROC': 'Asia/Taipei',
    'ROK': 'Asia/Seoul',
    'Singapore': 'Asia/Kuala_Lumpur',
    'Turkey': 'Europe/Athens',
    'UCT': 'Etc/Greenwich',
    'US/Alaska': 'US/Alaska',
    'US/Aleutian': 'Pacific/Honolulu',
    'US/Arizona': 'US/Arizona',
    'US/Central': 'US/Central',
    'US/East-Indiana': 'US/East-Indiana',
    'US/Eastern': 'US/Eastern',
    'US/Hawaii': 'Pacific/Honolulu',
    'US/Indiana-Starke': 'US/East-Indiana',
    'US/Michigan': 'US/Eastern',
    'US/Mountain': 'US/Mountain',
    'US/Pacific': 'America/Los_Angeles',
    'US/Pacific-New': 'America/Los_Angeles',
    'US/Samoa': 'Pacific/Midway',
    'UTC': 'Etc/Greenwich',
    'Universal': 'Etc/Greenwich',
    'W-SU': 'America/Los_Angeles',
    'WET': 'Etc/Greenwich',
    'Zulu': 'Etc/Greenwich',
}

/**
 * @deprecated
 */
export const timeZoneSelections = [
    { 'label': '(GMT-XX:XX) Space', 'value': 'Space' },
    { 'label': '(GMT-12:00) International Date Line West', 'value': 'Etc/GMT+12' },
    { 'label': '(GMT-11:00) Midway Island, Samoa', 'value': 'Pacific/Midway' },
    { 'label': '(GMT-10:00) Hawaii', 'value': 'Pacific/Honolulu' },
    { 'label': '(GMT-09:00) Alaska', 'value': 'US/Alaska' },
    { 'label': '(GMT-08:00) Pacific Time (US & Canada)', 'value': 'America/Los_Angeles' },
    { 'label': '(GMT-08:00) Tijuana, Baja California', 'value': 'America/Tijuana' },
    { 'label': '(GMT-07:00) Arizona', 'value': 'US/Arizona' },
    { 'label': '(GMT-07:00) Chihuahua, La Paz, Mazatlan', 'value': 'America/Chihuahua' },
    { 'label': '(GMT-07:00) Mountain Time (US & Canada)', 'value': 'US/Mountain' },
    { 'label': '(GMT-06:00) Central America', 'value': 'America/Managua' },
    { 'label': '(GMT-06:00) Central Time (US & Canada)', 'value': 'US/Central' },
    { 'label': '(GMT-06:00) Guadalajara, Mexico City, Monterrey', 'value': 'America/Mexico_City' },
    { 'label': '(GMT-06:00) Saskatchewan', 'value': 'Canada/Saskatchewan' },
    { 'label': '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', 'value': 'America/Bogota' },
    { 'label': '(GMT-05:00) Eastern Time (US & Canada)', 'value': 'US/Eastern' },
    { 'label': '(GMT-05:00) Indiana (East)', 'value': 'America/Indiana/Indianapolis' },
    { 'label': '(GMT-04:00) Atlantic Time (Canada)', 'value': 'Canada/Atlantic' },
    { 'label': '(GMT-04:00) Caracas, La Paz', 'value': 'America/Caracas' },
    { 'label': '(GMT-04:00) Manaus', 'value': 'America/Manaus' },
    { 'label': '(GMT-03:30) Newfoundland', 'value': 'Canada/Newfoundland' },
    { 'label': '(GMT-03:00) Santiago', 'value': 'America/Santiago' },
    { 'label': '(GMT-03:00) Brasilia', 'value': 'America/Sao_Paulo' },
    { 'label': '(GMT-03:00) Buenos Aires, Georgetown', 'value': 'America/Argentina/Buenos_Aires' },
    { 'label': '(GMT-03:00) Greenland', 'value': 'America/Godthab' },
    { 'label': '(GMT-03:00) Montevideo', 'value': 'America/Montevideo' },
    { 'label': '(GMT-02:00) Mid-Atlantic', 'value': 'America/Noronha' },
    { 'label': '(GMT-01:00) Cape Verde Is.', 'value': 'Atlantic/Cape_Verde' },
    { 'label': '(GMT-01:00) Azores', 'value': 'Atlantic/Azores' },
    { 'label': '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', 'value': 'Etc/Greenwich' },
    { 'label': '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', 'value': 'Europe/Amsterdam' },
    { 'label': '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', 'value': 'Europe/Belgrade' },
    { 'label': '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', 'value': 'Europe/Brussels' },
    { 'label': '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', 'value': 'Europe/Sarajevo' },
    { 'label': '(GMT+01:00) Casablanca, Reykjavik', 'value': 'Africa/Casablanca' },
    { 'label': '(GMT+01:00) West Central Africa', 'value': 'Africa/Lagos' },
    { 'label': '(GMT+02:00) Amman', 'value': 'Asia/Amman' },
    { 'label': '(GMT+02:00) Athens, Bucharest, Istanbul', 'value': 'Europe/Athens' },
    { 'label': '(GMT+02:00) Beirut', 'value': 'Asia/Beirut' },
    { 'label': '(GMT+02:00) Cairo', 'value': 'Africa/Cairo' },
    { 'label': '(GMT+02:00) Harare, Pretoria', 'value': 'Africa/Harare' },
    { 'label': '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', 'value': 'Europe/Helsinki' },
    { 'label': '(GMT+02:00) Jerusalem', 'value': 'Asia/Jerusalem' },
    { 'label': '(GMT+02:00) Minsk', 'value': 'Europe/Minsk' },
    { 'label': '(GMT+02:00) Windhoek', 'value': 'Africa/Windhoek' },
    { 'label': '(GMT+03:00) Kuwait, Riyadh, Baghdad', 'value': 'Asia/Kuwait' },
    { 'label': '(GMT+03:00) Moscow, St. Petersburg, Volgograd', 'value': 'Europe/Moscow' },
    { 'label': '(GMT+03:00) Nairobi', 'value': 'Africa/Nairobi' },
    { 'label': '(GMT+03:30) Tehran', 'value': 'Asia/Tehran' },
    { 'label': '(GMT+04:00) Tbilisi', 'value': 'Asia/Tbilisi' },
    { 'label': '(GMT+04:00) Abu Dhabi, Muscat', 'value': 'Asia/Muscat' },
    { 'label': '(GMT+04:00) Baku', 'value': 'Asia/Baku' },
    { 'label': '(GMT+04:00) Yerevan', 'value': 'Asia/Yerevan' },
    { 'label': '(GMT+04:30) Kabul', 'value': 'Asia/Kabul' },
    { 'label': '(GMT+05:00) Yekaterinburg', 'value': 'Asia/Yekaterinburg' },
    { 'label': '(GMT+05:00) Islamabad, Karachi, Tashkent', 'value': 'Asia/Karachi' },
    { 'label': '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', 'value': 'Asia/Kolkata' },
    { 'label': '(GMT+05:30) Sri Jayawardenapura', 'value': 'Asia/Colombo' },
    { 'label': '(GMT+05:45) Kathmandu', 'value': 'Asia/Kathmandu' },
    { 'label': '(GMT+06:00) Almaty, Novosibirsk', 'value': 'Asia/Almaty' },
    { 'label': '(GMT+06:00) Astana, Dhaka', 'value': 'Asia/Dhaka' },
    { 'label': '(GMT+06:30) Yangon (Rangoon)', 'value': 'Asia/Rangoon' },
    { 'label': '(GMT+07:00) Bangkok, Hanoi, Jakarta', 'value': 'Asia/Bangkok' },
    { 'label': '(GMT+07:00) Krasnoyarsk', 'value': 'Asia/Krasnoyarsk' },
    { 'label': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', 'value': 'Asia/Hong_Kong' },
    { 'label': '(GMT+08:00) Kuala Lumpur, Singapore', 'value': 'Asia/Kuala_Lumpur' },
    { 'label': '(GMT+08:00) Irkutsk, Ulaan Bataar', 'value': 'Asia/Irkutsk' },
    { 'label': '(GMT+08:00) Perth', 'value': 'Australia/Perth' },
    { 'label': '(GMT+08:00) Taipei', 'value': 'Asia/Taipei' },
    { 'label': '(GMT+09:00) Osaka, Sapporo, Tokyo', 'value': 'Asia/Tokyo' },
    { 'label': '(GMT+09:00) Seoul', 'value': 'Asia/Seoul' },
    { 'label': '(GMT+09:00) Yakutsk', 'value': 'Asia/Yakutsk' },
    { 'label': '(GMT+09:30) Adelaide', 'value': 'Australia/Adelaide' },
    { 'label': '(GMT+09:30) Darwin', 'value': 'Australia/Darwin' },
    { 'label': '(GMT+10:00) Brisbane', 'value': 'Australia/Brisbane' },
    { 'label': '(GMT+10:00) Canberra, Melbourne, Sydney', 'value': 'Australia/Canberra' },
    { 'label': '(GMT+10:00) Hobart', 'value': 'Australia/Hobart' },
    { 'label': '(GMT+10:00) Guam, Port Moresby', 'value': 'Pacific/Guam' },
    { 'label': '(GMT+11:00) New Caledonia, Solomon Is.', 'value': 'Asia/Magadan' },
    { 'label': '(GMT+12:00) Auckland, Wellington', 'value': 'Pacific/Auckland' },
    { 'label': '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', 'value': 'Pacific/Fiji' },
    { 'label': '(GMT+13:00) Nuku\'alofa', 'value': 'Pacific/Nukualofa' },
]

/**
 * TODO: New complete list, do not use yet until time zone selections are restructured
 * (right now need a mapping from many->few which doesnt include many of these time zones yet)
 */
// export const timeZoneSelections2 = [
//     {"label": "(GMT+00:00) Coordinated Universal Time", "value": "UTC"},
//     {"label": "(GMT-11:00) Niue Time", "value": "Pacific/Niue"},
//     {"label": "(GMT-11:00) Samoa Standard Time", "value": "Pacific/Pago_Pago"},
//     {"label": "(GMT-10:00) Cook Islands Standard Time", "value": "Pacific/Rarotonga"},
//     {"label": "(GMT-10:00) Hawaii-Aleutian Standard Time", "value": "Pacific/Honolulu"},
//     {"label": "(GMT-10:00) Tahiti Time", "value": "Pacific/Tahiti"},
//     {"label": "(GMT-09:30) Marquesas Time", "value": "Pacific/Marquesas"},
//     {"label": "(GMT-09:00) Gambier Time", "value": "Pacific/Gambier"},
//     {"label": "(GMT-09:00) Hawaii-Aleutian Time (Adak)", "value": "America/Adak"},
//     {"label": "(GMT-08:00) Alaska Time - Anchorage", "value": "America/Anchorage"},
//     {"label": "(GMT-08:00) Alaska Time - Juneau", "value": "America/Juneau"},
//     {"label": "(GMT-08:00) Alaska Time - Metlakatla", "value": "America/Metlakatla"},
//     {"label": "(GMT-08:00) Alaska Time - Nome", "value": "America/Nome"},
//     {"label": "(GMT-08:00) Alaska Time - Sitka", "value": "America/Sitka"},
//     {"label": "(GMT-08:00) Alaska Time - Yakutat", "value": "America/Yakutat"},
//     {"label": "(GMT-08:00) Pitcairn Time", "value": "Pacific/Pitcairn"},
//     {"label": "(GMT-07:00) Mexican Pacific Standard Time", "value": "America/Mazatlan"},
//     {"label": "(GMT-07:00) Mexican Pacific Time", "value": "America/Chihuahua"},
//     {"label": "(GMT-07:00) Mountain Standard Time - Dawson Creek", "value": "America/Dawson_Creek"},
//     {"label": "(GMT-07:00) Mountain Standard Time - Fort Nelson", "value": "America/Fort_Nelson"},
//     {"label": "(GMT-07:00) Mountain Standard Time - Phoenix", "value": "America/Phoenix"},
//     {"label": "(GMT-07:00) Pacific Time - Los Angeles", "value": "America/Los_Angeles"},
//     {"label": "(GMT-07:00) Pacific Time - Tijuana", "value": "America/Tijuana"},
//     {"label": "(GMT-07:00) Pacific Time - Vancouver", "value": "America/Vancouver"},
//     {"label": "(GMT-07:00) Yukon Time - Dawson", "value": "America/Dawson"},
//     {"label": "(GMT-07:00) Yukon Time - Whitehorse", "value": "America/Whitehorse"},
//     {"label": "(GMT-06:00) Central Standard Time - Belize", "value": "America/Belize"},
//     {"label": "(GMT-06:00) Central Standard Time - Costa Rica", "value": "America/Costa_Rica"},
//     {"label": "(GMT-06:00) Central Standard Time - El Salvador", "value": "America/El_Salvador"},
//     {"label": "(GMT-06:00) Central Standard Time - Guatemala", "value": "America/Guatemala"},
//     {"label": "(GMT-06:00) Central Standard Time - Managua", "value": "America/Managua"},
//     {"label": "(GMT-06:00) Central Standard Time - Mexico City", "value": "America/Mexico_City"},
//     {"label": "(GMT-06:00) Central Standard Time - Regina", "value": "America/Regina"},
//     {"label": "(GMT-06:00) Central Standard Time - Swift Current", "value": "America/Swift_Current"},
//     {"label": "(GMT-06:00) Central Standard Time - Tegucigalpa", "value": "America/Tegucigalpa"},
//     {"label": "(GMT-06:00) Central Standard Time - Winnipeg", "value": "America/Winnipeg"},
//     {"label": "(GMT-06:00) Galapagos Time", "value": "Pacific/Galapagos"},
//     {"label": "(GMT-06:00) Mountain Time - Boise", "value": "America/Boise"},
//     {"label": "(GMT-06:00) Mountain Time - Cambridge Bay", "value": "America/Cambridge_Bay"},
//     {"label": "(GMT-06:00) Mountain Time - Denver", "value": "America/Denver"},
//     {"label": "(GMT-06:00) Mountain Time - Edmonton", "value": "America/Edmonton"},
//     {"label": "(GMT-06:00) Mountain Time - Inuvik", "value": "America/Inuvik"},
//     {"label": "(GMT-06:00) Mountain Time - Ojinaga", "value": "America/Ojinaga"},
//     {"label": "(GMT-06:00) Mountain Time - Yellowknife", "value": "America/Yellowknife"},
//     {"label": "(GMT-05:00) Central Time - Bahia Banderas", "value": "America/Bahia_Banderas"},
//     {"label": "(GMT-05:00) Central Time - Beulah", "value": "America/North_Dakota/Beulah"},
//     {"label": "(GMT-05:00) Central Time - Center", "value": "America/North_Dakota/Center"},
//     {"label": "(GMT-05:00) Central Time - Chicago", "value": "America/Chicago"},
//     {"label": "(GMT-05:00) Central Time - Knox", "value": "America/Indiana/Knox"},
//     {"label": "(GMT-05:00) Central Time - Matamoros", "value": "America/Matamoros"},
//     {"label": "(GMT-05:00) Central Time - Menominee", "value": "America/Menominee"},
//     {"label": "(GMT-05:00) Central Time - Merida", "value": "America/Merida"},
//     {"label": "(GMT-05:00) Central Time - Monterrey", "value": "America/Monterrey"},
//     {"label": "(GMT-05:00) Central Time - New Salem", "value": "America/North_Dakota/New_Salem"},
//     {"label": "(GMT-05:00) Central Time - Rainy River", "value": "America/Rainy_River"},
//     {"label": "(GMT-05:00) Central Time - Rankin Inlet", "value": "America/Rankin_Inlet"},
//     {"label": "(GMT-05:00) Central Time - Resolute", "value": "America/Resolute"},
//     {"label": "(GMT-05:00) Central Time - Tell City", "value": "America/Indiana/Tell_City"},
//     {"label": "(GMT-05:00) Central Time - Winnipeg", "value": "America/Winnipeg"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Atikokan", "value": "America/Atikokan"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Cancun", "value": "America/Cancun"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Jamaica", "value": "America/Jamaica"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Panama", "value": "America/Panama"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Lima", "value": "America/Lima"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Nassau", "value": "America/Nassau"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Port-au-Prince", "value": "America/Port-au-Prince"},
//     {"label": "(GMT-05:00) Eastern Standard Time - Swift Current", "value": "America/Swift_Current"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Barbados", "value": "America/Barbados"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Bermuda", "value": "Atlantic/Bermuda"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Blanc-Sablon", "value": "America/Blanc-Sablon"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Curacao", "value": "America/Curacao"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Dominica", "value": "America/Dominica"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Grenada", "value": "America/Grenada"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Guadeloupe", "value": "America/Guadeloupe"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Guyana", "value": "America/Guyana"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Martinique", "value": "America/Martinique"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Montserrat", "value": "America/Montserrat"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Porto Velho", "value": "America/Porto_Velho"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Puerto Rico", "value": "America/Puerto_Rico"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Santo Domingo", "value": "America/Santo_Domingo"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - St. Kitts", "value": "America/St_Kitts"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - St. Lucia", "value": "America/St_Lucia"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - St. Thomas", "value": "America/St_Thomas"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - St. Vincent", "value": "America/St_Vincent"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Thule", "value": "America/Thule"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Tortola", "value": "America/Tortola"},
//     {"label": "(GMT-04:00) Atlantic Standard Time - Trinidad", "value": "America/Trinidad"},
//     {"label": "(GMT-04:00) Eastern Time - Detroit", "value": "America/Detroit"},
//     {"label": "(GMT-04:00) Eastern Time - Indianapolis", "value": "America/Indiana/Indianapolis"},
//     {"label": "(GMT-04:00) Eastern Time - Marengo", "value": "America/Indiana/Marengo"},
//     {"label": "(GMT-04:00) Eastern Time - Monticello", "value": "America/Indiana/Monticello"},
//     {"label": "(GMT-04:00) Eastern Time - Petersburg", "value": "America/Indiana/Petersburg"},
//     {"label": "(GMT-04:00) Eastern Time - Vevay", "value": "America/Indiana/Vevay"},
//     {"label": "(GMT-04:00) Eastern Time - Vincennes", "value": "America/Indiana/Vincennes"},
//     {"label": "(GMT-04:00) Eastern Time - Winamac", "value": "America/Indiana/Winamac"},
//     {"label": "(GMT-04:00) Eastern Time - Iqaluit", "value": "America/Iqaluit"},
//     {"label": "(GMT-04:00) Eastern Time - Louisville", "value": "America/Kentucky/Louisville"},
//     {"label": "(GMT-04:00) Eastern Time - Monticello", "value": "America/Kentucky/Monticello"},
//     {"label": "(GMT-04:00) Eastern Time - Nassau", "value": "America/Nassau"},
//     {"label": "(GMT-04:00) Eastern Time - New York", "value": "America/New_York"},
//     {"label": "(GMT-04:00) Eastern Time - Nipigon", "value": "America/Nipigon"},
//     {"label": "(GMT-04:00) Eastern Time - Pangnirtung", "value": "America/Pangnirtung"},
//     {"label": "(GMT-04:00) Eastern Time - Thunder Bay", "value": "America/Thunder_Bay"},
//     {"label": "(GMT-04:00) Eastern Time - Toronto", "value": "America/Toronto"},
//     {"label": "(GMT-03:00) Amazon Time - Boa Vista", "value": "America/Boa_Vista"},
//     {"label": "(GMT-03:00) Amazon Time - Campo Grande", "value": "America/Campo_Grande"},
//     {"label": "(GMT-03:00) Amazon Time - Cuiaba", "value": "America/Cuiaba"},
//     {"label": "(GMT-03:00) Amazon Time - Eirunepe", "value": "America/Eirunepe"},
//     {"label": "(GMT-03:00) Amazon Time - Manaus", "value": "America/Manaus"},
//     {"label": "(GMT-03:00) Amazon Time - Porto Velho", "value": "America/Porto_Velho"},
//     {"label": "(GMT-03:00) Amazon Time - Rio Branco", "value": "America/Rio_Branco"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Buenos Aires", "value": "America/Argentina/Buenos_Aires"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Catamarca", "value": "America/Argentina/Catamarca"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Cordoba", "value": "America/Argentina/Cordoba"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Jujuy", "value": "America/Argentina/Jujuy"},
//     {"label": "(GMT-03:00) Argentina Standard Time - La Rioja", "value": "America/Argentina/La_Rioja"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Mendoza", "value": "America/Argentina/Mendoza"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Rio Gallegos", "value": "America/Argentina/Rio_Gallegos"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Salta", "value": "America/Argentina/Salta"},
//     {"label": "(GMT-03:00) Argentina Standard Time - San Juan", "value": "America/Argentina/San_Juan"},
//     {"label": "(GMT-03:00) Argentina Standard Time - San Luis", "value": "America/Argentina/San_Luis"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Tucuman", "value": "America/Argentina/Tucuman"},
//     {"label": "(GMT-03:00) Argentina Standard Time - Ushuaia", "value": "America/Argentina/Ushuaia"},
//     {"label": "(GMT-03:00) Atlantic Daylight Time - Bermuda", "value": "Atlantic/Bermuda"},
//     {"label": "(GMT-03:00) Central Daylight Time - Bahia Banderas", "value": "America/Bahia_Banderas"},
//     {"label": "(GMT-03:00) Central Daylight Time - Mexico City", "value": "America/Mexico_City"},
//     {"label": "(GMT-03:00) Central Daylight Time - Monterrey", "value": "America/Monterrey"},
//     {"label": "(GMT-03:00) Greenland Standard Time - Godthab", "value": "America/Godthab"},
//     {"label": "(GMT-03:00) Greenland Standard Time - Miquelon", "value": "America/Miquelon"},
//     {"label": "(GMT-03:00) Montevideo Standard Time - Montevideo", "value": "America/Montevideo"},
//     {"label": "(GMT-03:00) Newfoundland Daylight Time - St. John's", "value": "America/St_Johns"},
//     {"label": "(GMT-03:00) Paraguay Standard Time - Asuncion", "value": "America/Asuncion"},
//     {"label": "(GMT-03:00) Saint Pierre and Miquelon Standard Time - Miquelon", "value": "America/Miquelon"},
//     {"label": "(GMT-03:00) South Georgia Time - South Georgia", "value": "Atlantic/South_Georgia"},
//     {"label": "(GMT-02:00) Brasilia Standard Time - Sao Paulo", "value": "America/Sao_Paulo"},
//     {"label": "(GMT-02:00) Fernando de Noronha Standard Time - Noronha", "value": "America/Noronha"},
//     {"label": "(GMT-01:00) Cape Verde Standard Time - Praia", "value": "Atlantic/Cape_Verde"},
//     {"label": "(GMT-01:00) Eastern Greenland Time - Scoresbysund", "value": "America/Scoresbysund"},
//     {"label": "(GMT+00:00) Azores Standard Time - Azores", "value": "Atlantic/Azores"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - Abidjan", "value": "Africa/Abidjan"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - Accra", "value": "Africa/Accra"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - Bissau", "value": "Africa/Bissau"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - Casablanca", "value": "Africa/Casablanca"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - Danmarkshavn", "value": "America/Danmarkshavn"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - El Aaiun", "value": "Africa/El_Aaiun"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - Monrovia", "value": "Africa/Monrovia"},
//     {"label": "(GMT+00:00) Coordinated Universal Time - Reykjavik", "value": "Atlantic/Reykjavik"},
//     {"label": "(GMT+00:00) Greenwich Mean Time - Dublin", "value": "Europe/Dublin"},
//     {"label": "(GMT+00:00) Greenwich Mean Time - Edinburgh", "value": "Europe/Edinburgh"},
//     {"label": "(GMT+00:00) Greenwich Mean Time - Lisbon", "value": "Europe/Lisbon"},
//     {"label": "(GMT+00:00) Greenwich Mean Time - London", "value": "Europe/London"},
//     {"label": "(GMT+01:00) Central European Standard Time - Amsterdam", "value": "Europe/Amsterdam"},
//     {"label": "(GMT+01:00) Central European Standard Time - Andorra", "value": "Europe/Andorra"},
//     {"label": "(GMT+01:00) Central European Standard Time - Belgrade", "value": "Europe/Belgrade"},
//     {"label": "(GMT+01:00) Central European Standard Time - Berlin", "value": "Europe/Berlin"},
//     {"label": "(GMT+01:00) Central European Standard Time - Bratislava", "value": "Europe/Bratislava"},
//     {"label": "(GMT+01:00) Central European Standard Time - Brussels", "value": "Europe/Brussels"},
//     {"label": "(GMT+01:00) Central European Standard Time - Budapest", "value": "Europe/Budapest"},
//     {"label": "(GMT+01:00) Central European Standard Time - Copenhagen", "value": "Europe/Copenhagen"},
//     {"label": "(GMT+01:00) Central European Standard Time - Gibraltar", "value": "Europe/Gibraltar"},
//     {"label": "(GMT+01:00) Central European Standard Time - Ljubljana", "value": "Europe/Ljubljana"},
//     {"label": "(GMT+01:00) Central European Standard Time - Luxembourg", "value": "Europe/Luxembourg"},
//     {"label": "(GMT+01:00) Central European Standard Time - Madrid", "value": "Europe/Madrid"},
//     {"label": "(GMT+01:00) Central European Standard Time - Malta", "value": "Europe/Malta"},
//     {"label": "(GMT+01:00) Central European Standard Time - Monaco", "value": "Europe/Monaco"},
//     {"label": "(GMT+01:00) Central European Standard Time - Oslo", "value": "Europe/Oslo"},
//     {"label": "(GMT+01:00) Central European Standard Time - Paris", "value": "Europe/Paris"},
//     {"label": "(GMT+01:00) Central European Standard Time - Podgorica", "value": "Europe/Podgorica"},
//     {"label": "(GMT+01:00) Central European Standard Time - Prague", "value": "Europe/Prague"},
//     {"label": "(GMT+01:00) Central European Standard Time - Rome", "value": "Europe/Rome"},
//     {"label": "(GMT+01:00) Central European Standard Time - San Marino", "value": "Europe/San_Marino"},
//     {"label": "(GMT+01:00) Central European Standard Time - Sarajevo", "value": "Europe/Sarajevo"},
//     {"label": "(GMT+01:00) Central European Standard Time - Skopje", "value": "Europe/Skopje"},
//     {"label": "(GMT+01:00) Central European Standard Time - Stockholm", "value": "Europe/Stockholm"},
//     {"label": "(GMT+01:00) Central European Standard Time - Tirane", "value": "Europe/Tirane"},
//     {"label": "(GMT+01:00) Central European Standard Time - Vaduz", "value": "Europe/Vaduz"},
//     {"label": "(GMT+01:00) Central European Standard Time - Valletta", "value": "Europe/Valletta"},
//     {"label": "(GMT+01:00) Central European Standard Time - Vienna", "value": "Europe/Vienna"},
//     {"label": "(GMT+01:00) Central European Standard Time - Warsaw", "value": "Europe/Warsaw"},
//     {"label": "(GMT+01:00) Central European Standard Time - Zurich", "value": "Europe/Zurich"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Algiers", "value": "Africa/Algiers"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Bangui", "value": "Africa/Bangui"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Brazzaville", "value": "Africa/Brazzaville"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Douala", "value": "Africa/Douala"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Kinshasa", "value": "Africa/Kinshasa"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Lagos", "value": "Africa/Lagos"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Libreville", "value": "Africa/Libreville"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Luanda", "value": "Africa/Luanda"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Malabo", "value": "Africa/Malabo"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Ndjamena", "value": "Africa/Ndjamena"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Niamey", "value": "Africa/Niamey"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Porto-Novo", "value": "Africa/Porto-Novo"},
//     {"label": "(GMT+01:00) West Africa Standard Time - Tunis", "value": "Africa/Tunis"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Athens", "value": "Europe/Athens"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Bucharest", "value": "Europe/Bucharest"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Chisinau", "value": "Europe/Chisinau"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Helsinki", "value": "Europe/Helsinki"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Istanbul", "value": "Europe/Istanbul"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Kaliningrad", "value": "Europe/Kaliningrad"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Kiev", "value": "Europe/Kiev"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Nicosia", "value": "Europe/Nicosia"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Riga", "value": "Europe/Riga"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Sofia", "value": "Europe/Sofia"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Tallinn", "value": "Europe/Tallinn"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Vilnius", "value": "Europe/Vilnius"},
//     {"label": "(GMT+02:00) Eastern European Standard Time - Zaporozhye", "value": "Europe/Zaporozhye"},
//     {"label": "(GMT+02:00) Central Africa Time - Blantyre", "value": "Africa/Blantyre"},
//     {"label": "(GMT+02:00) Central Africa Time - Bujumbura", "value": "Africa/Bujumbura"},
//     {"label": "(GMT+02:00) Central Africa Time - Gaborone", "value": "Africa/Gaborone"},
//     {"label": "(GMT+02:00) Central Africa Time - Harare", "value": "Africa/Harare"},
//     {"label": "(GMT+02:00) Central Africa Time - Johannesburg", "value": "Africa/Johannesburg"},
//     {"label": "(GMT+02:00) Central Africa Time - Kigali", "value": "Africa/Kigali"},
//     {"label": "(GMT+02:00) Central Africa Time - Lubumbashi", "value": "Africa/Lubumbashi"},
//     {"label": "(GMT+02:00) Central Africa Time - Lusaka", "value": "Africa/Lusaka"},
//     {"label": "(GMT+02:00) Central Africa Time - Maputo", "value": "Africa/Maputo"},
//     {"label": "(GMT+02:00) Central Africa Time - Maseru", "value": "Africa/Maseru"},
//     {"label": "(GMT+02:00) Central Africa Time - Mbabane", "value": "Africa/Mbabane"},
//     {"label": "(GMT+02:00) Central Africa Time - Tripoli", "value": "Africa/Tripoli"},
//     {"label": "(GMT+02:00) Central Africa Time - Windhoek", "value": "Africa/Windhoek"},
//     {"label": "(GMT+03:00) East Africa Time - Addis Ababa", "value": "Africa/Addis_Ababa"},
//     {"label": "(GMT+03:00) East Africa Time - Asmara", "value": "Africa/Asmara"},
//     {"label": "(GMT+03:00) East Africa Time - Dar es Salaam", "value": "Africa/Dar_es_Salaam"},
//     {"label": "(GMT+03:00) East Africa Time - Djibouti", "value": "Africa/Djibouti"},
//     {"label": "(GMT+03:00) East Africa Time - Juba", "value": "Africa/Juba"},
//     {"label": "(GMT+03:00) East Africa Time - Kampala", "value": "Africa/Kampala"},
//     {"label": "(GMT+03:00) East Africa Time - Khartoum", "value": "Africa/Khartoum"},
//     {"label": "(GMT+03:00) East Africa Time - Mogadishu", "value": "Africa/Mogadishu"},
//     {"label": "(GMT+03:00) East Africa Time - Nairobi", "value": "Africa/Nairobi"},
//     {"label": "(GMT+03:00) East Africa Time - Antananarivo", "value": "Indian/Antananarivo"},
//     {"label": "(GMT+03:00) East Africa Time - Comoro", "value": "Indian/Comoro"},
//     {"label": "(GMT+03:00) East Africa Time - Mayotte", "value": "Indian/Mayotte"},
//     {"label": "(GMT+03:00) Moscow Standard Time - Minsk", "value": "Europe/Minsk"},
//     {"label": "(GMT+03:00) Moscow Standard Time - Moscow", "value": "Europe/Moscow"},
//     {"label": "(GMT+03:00) Moscow Standard Time - Simferopol", "value": "Europe/Simferopol"},
//     {"label": "(GMT+03:00) Moscow Standard Time - Volgograd", "value": "Europe/Volgograd"},
//     {"label": "(GMT+03:00) Arabian Standard Time - Bahrain", "value": "Asia/Bahrain"},
//     {"label": "(GMT+03:00) Arabian Standard Time - Kuwait", "value": "Asia/Kuwait"},
//     {"label": "(GMT+03:00) Arabian Standard Time - Qatar", "value": "Asia/Qatar"},
//     {"label": "(GMT+03:00) Arabian Standard Time - Riyadh", "value": "Asia/Riyadh"},
//     {"label": "(GMT+03:00) Turkish Standard Time - Istanbul", "value": "Europe/Istanbul"},
//     {"label": "(GMT+03:30) Iran Standard Time - Tehran", "value": "Asia/Tehran"},
//     {"label": "(GMT+04:00) Gulf Standard Time - Abu Dhabi", "value": "Asia/Abu_Dhabi"},
//     {"label": "(GMT+04:00) Gulf Standard Time - Dubai", "value": "Asia/Dubai"},
//     {"label": "(GMT+04:00) Gulf Standard Time - Muscat", "value": "Asia/Muscat"},
//     {"label": "(GMT+04:00) Azerbaijan Standard Time - Baku", "value": "Asia/Baku"},
//     {"label": "(GMT+04:00) Georgia Standard Time - Tbilisi", "value": "Asia/Tbilisi"},
//     {"label": "(GMT+04:00) Armenia Standard Time - Yerevan", "value": "Asia/Yerevan"},
//     {"label": "(GMT+04:00) Mauritius Standard Time - Port Louis", "value": "Indian/Mauritius"},
//     {"label": "(GMT+04:00) Réunion Time - Reunion", "value": "Indian/Reunion"},
//     {"label": "(GMT+04:30) Afghanistan Time - Kabul", "value": "Asia/Kabul"},
//     {"label": "(GMT+05:00) West Kazakhstan Time - Aqtau", "value": "Asia/Aqtau"},
//     {"label": "(GMT+05:00) West Kazakhstan Time - Aqtobe", "value": "Asia/Aqtobe"},
//     {"label": "(GMT+05:00) West Kazakhstan Time - Oral", "value": "Asia/Oral"},
//     {"label": "(GMT+05:00) Pakistan Standard Time - Karachi", "value": "Asia/Karachi"},
//     {"label": "(GMT+05:00) Pakistan Standard Time - Lahore", "value": "Asia/Lahore"},
//     {"label": "(GMT+05:00) Pakistan Standard Time - Islamabad", "value": "Asia/Islamabad"},
//     {"label": "(GMT+05:00) India Standard Time - Kolkata", "value": "Asia/Kolkata"},
//     {"label": "(GMT+05:00) India Standard Time - Mumbai", "value": "Asia/Mumbai"},
//     {"label": "(GMT+05:00) India Standard Time - New Delhi", "value": "Asia/New_Delhi"},
//     {"label": "(GMT+05:30) Sri Lanka Standard Time - Colombo", "value": "Asia/Colombo"},
//     {"label": "(GMT+05:45) Nepal Time - Kathmandu", "value": "Asia/Kathmandu"},
//     {"label": "(GMT+06:00) Bangladesh Standard Time - Dhaka", "value": "Asia/Dhaka"},
//     {"label": "(GMT+06:00) East Kazakhstan Time - Almaty", "value": "Asia/Almaty"},
//     {"label": "(GMT+06:00) East Kazakhstan Time - Qyzylorda", "value": "Asia/Qyzylorda"},
//     {"label": "(GMT+06:00) Bhutan Time - Thimphu", "value": "Asia/Thimphu"},
//     {"label": "(GMT+06:00) Omsk Standard Time - Omsk", "value": "Asia/Omsk"},
//     {"label": "(GMT+06:30) Myanmar Time - Yangon (Rangoon)", "value": "Asia/Yangon"},
//     {"label": "(GMT+06:30) Cocos Islands Time - Cocos", "value": "Indian/Cocos"},
//     {"label": "(GMT+07:00) Indochina Time - Bangkok", "value": "Asia/Bangkok"},
//     {"label": "(GMT+07:00) Indochina Time - Ho Chi Minh", "value": "Asia/Ho_Chi_Minh"},
//     {"label": "(GMT+07:00) Indochina Time - Jakarta", "value": "Asia/Jakarta"},
//     {"label": "(GMT+07:00) Indochina Time - Phnom Penh", "value": "Asia/Phnom_Penh"},
//     {"label": "(GMT+07:00) Indochina Time - Vientiane", "value": "Asia/Vientiane"},
//     {"label": "(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk", "value": "Asia/Krasnoyarsk"},
//     {"label": "(GMT+07:00) Krasnoyarsk Standard Time - Novosibirsk", "value": "Asia/Novosibirsk"},
//     {"label": "(GMT+07:00) Christmas Island Time - Christmas", "value": "Indian/Christmas"},
//     {"label": "(GMT+08:00) China Standard Time - Beijing", "value": "Asia/Shanghai"},
//     {"label": "(GMT+08:00) China Standard Time - Chongqing", "value": "Asia/Chongqing"},
//     {"label": "(GMT+08:00) China Standard Time - Hong Kong", "value": "Asia/Hong_Kong"},
//     {"label": "(GMT+08:00) China Standard Time - Urumqi", "value": "Asia/Urumqi"},
//     {"label": "(GMT+08:00) Singapore Standard Time - Singapore", "value": "Asia/Singapore"},
//     {"label": "(GMT+08:00) Taiwan Standard Time - Taipei", "value": "Asia/Taipei"},
//     {"label": "(GMT+08:00) Australian Western Standard Time - Perth", "value": "Australia/Perth"},
//     {"label": "(GMT+08:00) Irkutsk Standard Time - Irkutsk", "value": "Asia/Irkutsk"},
//     {"label": "(GMT+08:45) Australian Central Western Standard Time - Eucla", "value": "Australia/Eucla"},
//     {"label": "(GMT+09:00) Japan Standard Time - Tokyo", "value": "Asia/Tokyo"},
//     {"label": "(GMT+09:00) Korea Standard Time - Seoul", "value": "Asia/Seoul"},
//     {"label": "(GMT+09:00) Yakutsk Standard Time - Yakutsk", "value": "Asia/Yakutsk"},
//     {"label": "(GMT+09:00) Palau Time - Palau", "value": "Pacific/Palau"},
//     {"label": "(GMT+09:30) Australian Central Standard Time - Adelaide", "value": "Australia/Adelaide"},
//     {"label": "(GMT+09:30) Australian Central Standard Time - Darwin", "value": "Australia/Darwin"},
//     {"label": "(GMT+10:00) Australian Eastern Standard Time - Brisbane", "value": "Australia/Brisbane"},
//     {"label": "(GMT+10:00) Australian Eastern Standard Time - Sydney", "value": "Australia/Sydney"},
//     {"label": "(GMT+10:00) Australian Eastern Standard Time - Melbourne", "value": "Australia/Melbourne"},
//     {"label": "(GMT+10:00) Australian Eastern Standard Time - Hobart", "value": "Australia/Hobart"},
//     {"label": "(GMT+10:00) Vladivostok Standard Time - Vladivostok", "value": "Asia/Vladivostok"},
//     {"label": "(GMT+10:00) Chamorro Standard Time - Guam", "value": "Pacific/Guam"},
//     {"label": "(GMT+10:00) Papua New Guinea Time - Port Moresby", "value": "Pacific/Port_Moresby"},
//     {"label": "(GMT+10:30) Lord Howe Standard Time - Lord_Howe", "value": "Australia/Lord_Howe"},
//     {"label": "(GMT+11:00) Solomon Islands Time - Honiara", "value": "Pacific/Honiara"},
//     {"label": "(GMT+11:00) Norfolk Island Time - Norfolk", "value": "Pacific/Norfolk"},
//     {"label": "(GMT+11:00) Magadan Standard Time - Magadan", "value": "Asia/Magadan"},
//     {"label": "(GMT+11:00) Sakhalin Standard Time - Sakhalin", "value": "Asia/Sakhalin"},
//     {"label": "(GMT+11:00) Vanuatu Time - Efate", "value": "Pacific/Efate"},
//     {"label": "(GMT+11:30) New Zealand Standard Time - Chatham", "value": "Pacific/Chatham"},
//     {"label": "(GMT+12:00) Fiji Time - Fiji", "value": "Pacific/Fiji"},
//     {"label": "(GMT+12:00) Gilbert Islands Time - Tarawa", "value": "Pacific/Tarawa"},
//     {"label": "(GMT+12:00) New Zealand Standard Time - Auckland", "value": "Pacific/Auckland"},
//     {"label": "(GMT+12:00) Petropavlovsk-Kamchatski Standard Time - Kamchatka", "value": "Asia/Kamchatka"},
//     {"label": "(GMT+12:00) Marshall Islands Time - Majuro", "value": "Pacific/Majuro"},
//     {"label": "(GMT+12:00) Nauru Time - Nauru", "value": "Pacific/Nauru"},
//     {"label": "(GMT+12:00) Tuvalu Time - Funafuti", "value": "Pacific/Funafuti"},
//     {"label": "(GMT+12:00) Wake Island Time - Wake", "value": "Pacific/Wake"},
//     {"label": "(GMT+12:00) Wallis and Futuna Time - Wallis", "value": "Pacific/Wallis"},
//     {"label": "(GMT+12:45) New Zealand Daylight Time - Chatham", "value": "Pacific/Chatham"},
//     {"label": "(GMT+13:00) Phoenix Islands Time - Enderbury", "value": "Pacific/Enderbury"},
//     {"label": "(GMT+13:00) Tokelau Time - Fakaofo", "value": "Pacific/Fakaofo"},
//     {"label": "(GMT+13:00) Samoa Standard Time - Apia", "value": "Pacific/Apia"},
//     {"label": "(GMT+13:00) Tonga Time - Tongatapu", "value": "Pacific/Tongatapu"},
//     {"label": "(GMT+14:00) Line Islands Time - Kiritimati", "value": "Pacific/Kiritimati"}
// ]

/**
 * @deprecated
 */
export const timeZoneCities = {
    'Etc/GMT+12': ['Int\'l Date Line'],
    'Pacific/Midway': ['Midway Island', 'Samoa'],
    'Pacific/Honolulu': ['Honolulu', 'Maui', 'Tahiti', 'Adak', 'Aleutian', 'Johnston', 'Rarotonga'],
    'US/Alaska': ['Anchorage', 'Juneau', 'Alaska', 'Atka', 'Gambier', 'Marquesas', 'Nome', 'Sitka', 'Yakutat'],
    'America/Los_Angeles': ['Los Angeles', 'San Diego', 'San Francisco', 'Sacramento', 'Seattle', 'Portland'], // US Pacific Time
    'US/Pacific': ['Los Angeles', 'San Diego', 'San Francisco', 'Sacramento', 'Seattle', 'Portland'], // US Pacific Time
    'America/Tijuana': ['Tijuana', 'Baja'],
    'US/Arizona': ['Arizona'],
    'America/Chihuahua': ['Chihuahua', 'La Paz', 'Mazatlan'],
    'US/Mountain': ['Denver', 'Phoenix', 'Tucson', 'Fort Collins', 'Santa Fe', 'Albuquerque', 'Salt Lake City', 'Boise', 'Cheyenne', 'Helena'],
    'America/Managua': ['Managua'], // Central America
    'US/Central': ['Chicago', 'Minneapolis', 'Houston', 'Dallas', 'Oklahoma City'],
    'America/Mexico_City': ['Mexico City', 'Guadalajara', 'Monterrey'],
    'Canada/Saskatchewan': ['Saskatchewan'],
    'America/Bogota': ['Bogota', 'Lima', 'Quito', 'Rio Branco'],
    'US/Eastern': [
        'New York', 'Boston', 'Philadelphia', 'Detroit', 'Atlanta', 'Orlando', 'Miami', 'DC', 'Montreal', 'Toronto', 'Atikokan', 'Cancun', 'Cayman',
        'Coral Harbour', 'Cuba', 'Fort Wayne', 'Grand Turk', 'Indianapolis', 'Iqaluit', 'Jamaica', 'Louisville', 'Marengo', 'Monticello', 'Nassau', 'Nipigon',
        'Pangnirtung', 'Petersburg', 'Port-au-Prince', 'Thunder Bay', 'Vevay', 'Vincennes', 'Winamac',
    ],
    'US/East-Indiana': ['Indiana'],
    'Canada/Atlantic': [
        'Canada', 'Atlantic', 'Anguilla', 'Antigua', 'Aruba', 'Barbados', 'Bermuda', 'Blanc-Sablon', 'Curacao', 'Dominica',
        'Glace Bay', 'Goose Bay', 'Grenada', 'Guadeloupe', 'Halifax', 'Kralendijk', 'Lower Princes', 'Marigot', 'Martinique',
        'Moncton', 'Montserrat', 'Port of Spain', 'Puerto Rico', 'Santa Isabel', 'Santo Domingo', 'St. Barthelemy',
        'St. Johns', 'St. Kitts', 'St. Lucia', 'St. Thomas', 'St. Vincent', 'Tortola', 'Virgin',
    ],
    'America/Caracas': ['Caracas', 'La Paz'],
    'America/Manaus': ['Manaus'],
    'America/Santiago': ['Santiago'],
    'Canada/Newfoundland': ['Newfoundland', 'Canada'],
    'America/Sao_Paulo': [
        'Sao Paulo', 'Brasilia', 'Araguaina', 'Asuncion', 'Bahia', 'Bahia Banderas', 'Belem', 'Campo Grande',
        'Cayenne', 'Cuiaba', 'Fortaleza', 'Maceio', 'Paramaribo', 'Recife', 'Santarem',
    ],
    'America/Argentina/Buenos_Aires': [
        'Buenos Aires', 'Catamarca', 'ComodRivadavia', 'Cordoba', 'Georgetown', 'Jujuy',
        'La Rioja', 'Mendoza', 'Rio Gallegos', 'Rosario', 'Salta', 'San Juan', 'San Luis', 'Tucuman', 'Ushuaia',
    ],
    'America/Godthab': ['Godthab', 'Greenland'],
    'America/Montevideo': ['Montevideo'],
    'America/Noronha': ['Noronha', 'Mid-Atlantic'],
    'Atlantic/Cape_Verde': ['Cape Verde'],
    'Atlantic/Azores': ['Azores'],
    'Africa/Casablanca': ['Casablanca', 'Monrovia', 'Reykjavik', 'Gibraltar', 'Iceland'],
    'Etc/Greenwich': [
        'London', 'Dublin', 'Edinburgh', 'Lisbon', 'Belfast', 'Abidjan', 'Accra', 'Bissau', 'Canary', 'Conakry', 'Dakar', 'Danmarkshavn', 'Eire', 'Faeroe', 'Freetown',
        'Greenwich', 'Guernsey', 'Isle of Man', 'Jersey', 'Lome', 'Madeira', 'Monrovia', 'Nouakchott', 'Ouagadougou', 'St. Helena', 'Timbuktu', 'Troll',
    ],
    'Europe/Amsterdam': ['Amsterdam', 'Berlin', 'Bern', 'Rome', 'Stockholm', 'Vienna'],
    'Europe/Belgrade': ['Belgrade', 'Bratislava', 'Budapest', 'Ljubljana', 'Prague'],
    'Europe/Brussels': ['Paris', 'Brussels', 'Copenhagen', 'Madrid'],
    'Europe/Sarajevo': ['Sarajevo', 'Skopje', 'Warsaw', 'Zagreb'],
    'Africa/Lagos': [
        'Lagos', 'West Africa', 'Algiers', 'Bangui', 'Brazzaville', 'Ceuta', 'Douala', 'El Aaiun',
        'Kinshasa', 'Libreville', 'Luanda', 'Malabo', 'Ndjamena', 'Niamey', 'Porto-Novo', 'Sao Tome', 'Tunis',
    ],
    'Asia/Amman': ['Amman'],
    'Europe/Athens': ['Athens', 'Bucharest', 'Istanbul'],
    'Asia/Beirut': ['Beirut'],
    'Africa/Cairo': ['Cairo', 'Tripoli', 'Egypt', 'Libya'],
    'Africa/Harare': ['Harare', 'Johannesburg', 'Pretoria', 'Blantyre', 'Bujumbura', 'Gaborone'],
    'Europe/Helsinki': ['Helsinki', 'Kyiv', 'Riga', 'Sofia', 'Tallinn', 'Vilnius'],
    'Asia/Jerusalem': ['Jerusalem'],
    'Europe/Minsk': ['Minsk'],
    'Africa/Windhoek': ['Windhoek', 'Khartoum', 'Kigali', 'Lubumbashi', 'Lusaka', 'Maputo', 'Maseru', 'Mbabane'],
    'Asia/Kuwait': ['Kuwait', 'Riyadh', 'Baghdad'],
    'Europe/Moscow': ['Moscow', 'St.Petersburg', 'Volgograd'],
    'Africa/Nairobi': [
        'Nairobi', 'Addis Ababa', 'Antananarivo', 'Asmara', 'Comoro', 'Dar es Salaam', 'Djibouti', 'Juba', 'Kampala',
        'Mahe', 'Mayotte',
    ],
    'Asia/Tbilisi': ['Tbilisi'],
    'Asia/Tehran': ['Tehran'],
    'Asia/Muscat': ['Abu Dhabi', 'Muscat'],
    'Asia/Baku': ['Baku'],
    'Asia/Yerevan': ['Yerevan'],
    'Asia/Kabul': ['Kabul'],
    'Asia/Yekaterinburg': ['Yekaterinburg'],
    'Asia/Karachi': ['Islamabad', 'Karachi', 'Tashkent'],
    'Asia/Calcutta': ['Mumbai', 'Kolkata', 'Delhi', 'Bengaluru', 'Hyderabad', 'Pune', 'Chennai', 'Lucknow', 'Sri Jayawardenapura'],
    'Asia/Katmandu': ['Kathmandu'],
    'Asia/Almaty': ['Almaty', 'Novosibirsk'],
    'Asia/Dhaka': ['Astana', 'Dhaka'],
    'Asia/Rangoon': ['Yangon'],
    'Asia/Bangkok': ['Bangkok', 'Hanoi', 'Jakarta'],
    'Asia/Krasnoyarsk': ['Krasnoyarsk'],
    'Asia/Hong_Kong': ['Beijing', 'Chongqing', 'Hong Kong', 'Urumqi'],
    'Asia/Kuala_Lumpur': ['Kuala Lumpur', 'Singapore'],
    'Asia/Irkutsk': ['Irkutsk', 'Ulaan Bataar'],
    'Australia/Perth': ['Perth'],
    'Asia/Taipei': ['Taipei'],
    'Asia/Tokyo': ['Tokyo', 'Osaka', 'Sapporo'],
    'Asia/Seoul': ['Seoul'],
    'Asia/Yakutsk': ['Yakutsk'],
    'Australia/Adelaide': ['Adelaide'],
    'Australia/Darwin': ['Darwin'],
    'Australia/Brisbane': ['Brisbane'],
    'Australia/Sydney': ['Canberra', 'Melbourne', 'Sydney'],
    'Australia/Hobart': ['Hobart'],
    'Pacific/Guam': ['Guam', 'Port Moresby'],
    'Asia/Vladivostok': ['Vladivostok'],
    'Asia/Magadan': ['Solomon Is.', 'New Caledonia'], // 'Magadan'
    'Pacific/Auckland': ['Auckland', 'Wellington'],
    'Pacific/Fiji': ['Fiji', 'Kamchatka', 'Marshall Is.'],
    'Pacific/Tongatapu': ['Nuku\'alofa', 'Tongatapu'],
    'Space': ['Home Base', 'Mercury', 'Venus', 'Moon Base', 'Mars Base', 'Jupiter', 'Saturn', 'Uranus', 'Neptune', 'Pluto'],
}

// export const timeZoneOffsets = {
//     'Etc/GMT+12': 12,
//     'Pacific/Midway': -11,
//     'Pacific/Honolulu': -10,
//     'US/Alaska': -9,
//     'America/Los_Angeles': -8, // US Pacific Time
//     'America/Tijuana': -8,
//     'US/Arizona': -7,
//     'America/Chihuahua': -7,
//     'US/Mountain': -7,
//     'America/Managua': -6, // Central America
//     'US/Central': ['Chicago', 'Minneapolis', 'Houston', 'Dallas', 'Oklahoma City'],
//     'America/Mexico_City': -6,
//     'Canada/Saskatchewan': -6,
//     'America/Bogota': -5,
//     'US/Eastern': -5,
//     'US/East-Indiana': -5,
//     'Canada/Atlantic': -4,
//     'America/Caracas': -4,
//     'America/Manaus': -4,
//     'Canada/Newfoundland': -3.5,
//     'America/Santiago': -3,
//     'America/Sao_Paulo': -3,
//     'America/Argentina/Buenos_Aires': -3,
//     'America/Godthab': -3,
//     'America/Montevideo': -3,
//     'America/Noronha': -2,
//     'Atlantic/Cape_Verde': -1,
//     'Atlantic/Azores': -1,
//     'Etc/Greenwich': 0,
//     'Europe/Amsterdam': 1,
//     'Europe/Belgrade': 1,
//     'Europe/Brussels': 1,
//     'Europe/Sarajevo': 1,
//     'Africa/Casablanca': 1,
//     'Africa/Lagos': 1,
//     'Asia/Amman': 2,
//     'Europe/Athens': 2,
//     'Asia/Beirut': 2,
//     'Africa/Cairo': 2,
//     'Africa/Harare': 2,
//     'Europe/Helsinki': 2,
//     'Asia/Jerusalem': 2,
//     'Europe/Minsk': 2,
//     'Africa/Windhoek': 2,
//     'Asia/Kuwait': 3,
//     'Europe/Moscow': 3,
//     'Africa/Nairobi': 3,
//     'Asia/Tehran': 3.5,
//     'Asia/Tbilisi': 4,
//     'Asia/Muscat': 4,
//     'Asia/Baku': 4,
//     'Asia/Yerevan': 4,
//     'Asia/Kabul': 4.5,
//     'Asia/Yekaterinburg': 5,
//     'Asia/Karachi': 5,
//     'Asia/Calcutta': 5.5,
//     'Asia/Katmandu': 5.75,
//     'Asia/Almaty': 6,
//     'Asia/Dhaka': 6,
//     'Asia/Rangoon': 6.5,
//     'Asia/Bangkok': 7,
//     'Asia/Krasnoyarsk': 7,
//     'Asia/Hong_Kong': 8,
//     'Asia/Kuala_Lumpur': 8,
//     'Asia/Irkutsk': 8,
//     'Australia/Perth': 8,
//     'Asia/Taipei': 8,
//     'Asia/Tokyo': 9,
//     'Asia/Seoul': 9,
//     'Asia/Yakutsk': 9,
//     'Australia/Adelaide': 9.5,
//     'Australia/Darwin': 9.5,
//     'Australia/Brisbane': 10,
//     'Australia/Canberra': 10,
//     'Australia/Hobart': 10,
//     'Pacific/Guam': 10,
//     'Asia/Vladivostok': 10,
//     'Asia/Magadan': 11,
//     'Pacific/Auckland': 12,
//     'Pacific/Fiji': 12,
//     'Pacific/Tongatapu': 13,
//     'Space': 0,
// }


export const timeValueLabels = [
    { value: 0, label: '12:00 AM' }, { value: 30, label: '12:30 AM' }, { value: 100, label: '1:00 AM' }, { value: 130, label: '1:30 AM' },
    { value: 200, label: '2:00 AM' }, { value: 230, label: '2:30 AM' }, { value: 300, label: '3:00 AM' }, { value: 330, label: '3:30 AM' },
    { value: 400, label: '4:00 AM' }, { value: 330, label: '4:30 AM' }, { value: 500, label: '5:00 AM' }, { value: 530, label: '5:30 AM' },
    { value: 600, label: '6:00 AM' }, { value: 630, label: '6:30 AM' }, { value: 700, label: '7:00 AM' }, { value: 730, label: '7:30 AM' },
    { value: 800, label: '8:00 AM' }, { value: 830, label: '8:30 AM' }, { value: 900, label: '9:00 AM' }, { value: 930, label: '9:30 AM' },
    { value: 1000, label: '10:00 AM' }, { value: 1030, label: '10:30 AM' }, { value: 1100, label: '11:00 AM' }, { value: 1130, label: '11:30 AM' },
    { value: 1200, label: '12:00 PM' }, { value: 1230, label: '12:30 PM' }, { value: 1300, label: '1:00 PM' }, { value: 1330, label: '1:30 PM' },
    { value: 1400, label: '2:00 PM' }, { value: 1430, label: '2:30 PM' }, { value: 1500, label: '3:00 PM' }, { value: 1530, label: '3:30 PM' },
    { value: 1600, label: '4:00 PM' }, { value: 1630, label: '4:30 PM' }, { value: 1700, label: '5:00 PM' }, { value: 1730, label: '5:30 PM' },
    { value: 1800, label: '6:00 PM' }, { value: 1830, label: '6:30 PM' }, { value: 1900, label: '7:00 PM' }, { value: 1930, label: '7:30 PM' },
    { value: 2000, label: '8:00 PM' }, { value: 2030, label: '8:30 PM' }, { value: 2100, label: '9:00 PM' }, { value: 2130, label: '9:30 PM' },
    { value: 2200, label: '10:00 PM' }, { value: 2230, label: '10:30 PM' }, { value: 2300, label: '11:00 PM' }, { value: 2330, label: '11:30 PM' },
    { value: 2400, label: '12:00 AM' },
]


/////////////////////////////
// Reserved User Profile URLs
/////////////////////////////

// export const reservedUrls: string[] = [
//     'dan', 'daniel', 'danross', 'danielross', 'daniel-ross', 'dan-ross', 'dross', 'luk', 'lukman', 'nash', 'nashtheory', 'themgmt', 'themanagement', 'undock', 'undockhq',
//     'lukmanahmed', 'god', 'jesus', 'lord', 'lordalmighty', 'godalmighty', 'architect', 'thearchitect', 'sourceleven', 'sourceeleven',
//     'reena', 'reenarahi', 'rahi', 'drreenarahi', 'drreena', 'reenarahiphd', 'drrahi', 'dr-rahi', 'reena-rahi', 'merijaan',
//     'viper', 'viperbox', 'patched', 'patchedhq', 'patched-hq', 'randall', 'penelope', 'dboy', 'd-boy', 'danrossnyc',
//     'justdrumsounds', 'google', 'amazon', 'netflix', 'memet', 'rhea', 'phd', 'dismike', 'kingdomoftheblind', 'kotb',
//     'Anusha', 'Deepika', 'Kriti', 'Niddhi',
//     'Allison',
//     'Arthur',
//     'Ana',
//     'Alex',
//     'Arlene',
//     'Alberto',
//     'Barry',
//     'Bertha',
//     'Bill',
//     'Bonnie',
//     'Bret',
//     'Beryl',
//     'Chantal',
//     'Cristobal',
//     'Claudette',
//     'Charley',
//     'Cindy',
//     'Chris',
//     'Dean',
//     'Dolly',
//     'Danny',
//     'Danielle',
//     'Dennis',
//     'Debby',
//     'Erin',
//     'Edouard',
//     'Erika',
//     'Earl',
//     'Emily',
//     'Ernesto',
//     'Felix',
//     'Fay',
//     'Fabian',
//     'Frances',
//     'Franklin',
//     'Florence',
//     'Gabielle',
//     'Gustav',
//     'Grace',
//     'Gaston',
//     'Gert',
//     'Gordon',
//     'Humberto',
//     'Hanna',
//     'Henri',
//     'Hermine',
//     'Harvey',
//     'Helene',
//     'Iris',
//     'Isidore',
//     'Isabel',
//     'Ivan',
//     'Irene',
//     'Isaac',
//     'Jason',
//     'John',
//     'Jerry',
//     'Josephine',
//     'Juan',
//     'Jeanne',
//     'Jose',
//     'Joyce',
//     'Karen',
//     'Kyle',
//     'Kate',
//     'Karl',
//     'Katrina',
//     'Kirk',
//     'Lorenzo',
//     'Lili',
//     'Larry',
//     'Lisa',
//     'Lee',
//     'Leslie',
//     'Michelle',
//     'Marco',
//     'Mindy',
//     'Maria',
//     'Michael',
//     'Mike',
//     'Noel',
//     'Nana',
//     'Nicholas',
//     'Nicole',
//     'Nate',
//     'Nadine',
//     'Olga',
//     'Omar',
//     'Odette',
//     'Otto',
//     'Ophelia',
//     'Oscar',
//     'Pablo',
//     'Paloma',
//     'Peter',
//     'Paula',
//     'Philippe',
//     'Patty',
//     'Rebekah',
//     'Rene',
//     'Rose',
//     'Richard',
//     'Rita',
//     'Rafael',
//     'Sebastien',
//     'Sally',
//     'Sam',
//     'Shary',
//     'Stan',
//     'Sandy',
//     'Tanya',
//     'Teddy',
//     'Teresa',
//     'Tomas',
//     'Tammy',
//     'Tony',
//     'Van',
//     'Vicky',
//     'Victor',
//     'Virginie',
//     'Vince',
//     'Valerie',
//     'Wendy',
//     'Wilfred',
//     'Wanda',
//     'Walter',
//     'Wilma',
//     'William',
//     'Kumiko',
//     'Aki',
//     'Miharu',
//     'Chiaki',
//     'Michiyo',
//     'Itoe',
//     'Nanaho',
//     'Reina',
//     'Emi',
//     'Yumi',
//     'Ayumi',
//     'Kaori',
//     'Sayuri',
//     'Rie',
//     'Miyuki',
//     'Hitomi',
//     'Naoko',
//     'Miwa',
//     'Etsuko',
//     'Akane',
//     'Kazuko',
//     'Miyako',
//     'Youko',
//     'Sachiko',
//     'Mieko',
//     'Toshie',
//     'Junko',
// ]

// Source: https://github.com/mailcheck/mailcheck/wiki/List-of-Popular-Domains
//         https://email-verify.my-addr.com/list-of-most-popular-email-domains.php

// export const invalidOrgDomains = [
//     /* Default domains included */
//     'aol.com', 'aim.com', 'att.net', 'comcast.net', 'facebook.com', 'gmail.com', 'gmx.com', 'googlemail.com',
//     'google.com', 'hotmail.com', 'hotmail.co.uk', 'mac.com', 'me.com', 'mail.com', 'msn.com',
//     'live.com', 'sbcglobal.net', 'verizon.net', 'yahoo.com', 'yahoo.co.uk', 'centurytel.net',
//     'frontiernet.net',
//
//     /* Other global domains */
//     'email.com', 'fastmail.fm', 'games.com' /* AOL */, 'gmx.net', 'hush.com', 'hushmail.com', 'icloud.com',
//     'iname.com', 'inbox.com', 'lavabit.com', 'love.com' /* AOL */, 'outlook.com', 'pobox.com', 'protonmail.com',
//     'rocketmail.com' /* Yahoo */, 'safe-mail.net', 'wow.com' /* AOL */, 'ygm.com' /* AOL */,
//     'ymail.com' /* Yahoo */, 'zoho.com', 'yandex.com', 'bigpond.com', 'rediffmail.com', 'pm.me',
//
//     /* United States ISP domains */
//     'bellsouth.net', 'charter.net', 'cox.net', 'earthlink.net', 'juno.com', 'optonline.com', 'optonline.net',
//     'windstream.net', 'rr.com',
//
//     /* British ISP domains */
//     'btinternet.com', 'virginmedia.com', 'blueyonder.co.uk', 'freeserve.co.uk', 'live.co.uk',
//     'ntlworld.com', 'o2.co.uk', 'orange.net', 'sky.com', 'talktalk.co.uk', 'tiscali.co.uk',
//     'virgin.net', 'wanadoo.co.uk', 'bt.com',
//
//     /* Domains used in Asia */
//     'sina.com', 'sina.cn', 'qq.com', 'naver.com', 'hanmail.net', 'daum.net', 'nate.com', 'yahoo.co.jp', 'yahoo.co.kr',
//     'yahoo.co.id', 'yahoo.co.in', 'yahoo.com.sg', 'yahoo.com.ph', '163.com', '126.com', 'aliyun.com', 'foxmail.com',
//     'yahoo.in', 'in.com', 'gawab.com', 'gmx.in', 'lycos.com', 'lycos.in',
//
//     /* French ISP domains */
//     'hotmail.fr', 'live.fr', 'laposte.net', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'gmx.fr', 'sfr.fr', 'neuf.fr', 'free.fr',
//     'club-internet.fr', 'voila.fr', 'aliceadsl.fr',
//
//     /* German ISP domains */
//     'gmx.de', 'hotmail.de', 'live.de', 'online.de', 't-online.de' /* T-Mobile */, 'web.de', 'yahoo.de', 'freenet.de',
//
//     /* Italian ISP domains */
//     'libero.it', 'virgilio.it', 'hotmail.it', 'aol.it', 'tiscali.it', 'alice.it', 'live.it', 'yahoo.it', 'email.it', 'tin.it',
//     'poste.it', 'teletu.it',
//
//     /* Russian ISP domains */
//     'mail.ru', 'rambler.ru', 'yandex.ru', 'ya.ru', 'list.ru',
//
//     /* Belgian ISP domains */
//     'hotmail.be', 'live.be', 'skynet.be', 'voo.be', 'tvcablenet.be', 'telenet.be',
//
//     /* Argentinian ISP domains */
//     'hotmail.com.ar', 'live.com.ar', 'yahoo.com.ar', 'fibertel.com.ar', 'speedy.com.ar', 'arnet.com.ar',
//
//     /* Domains used in Mexico */
//     'yahoo.com.mx', 'live.com.mx', 'hotmail.es', 'hotmail.com.mx', 'prodigy.net.mx', 'yahoo.es',
//
//     /* Domains used in Brazil */
//     'yahoo.com.br', 'hotmail.com.br', 'outlook.com.br', 'uol.com.br', 'bol.com.br', 'terra.com.br', 'ig.com.br', 'itelefonica.com.br',
//     'r7.com', 'zipmail.com.br', 'globo.com', 'globomail.com', 'oi.com.br',
//
//     /* Other International Domains */
//     'bigpond.net.au', 'live.ca', 'chello.nl', 'sympatico.ca', 'bluewin.ch', 'optusnet.com.au', 'zonnet.nl', 'yahoo.com.sg', 'live.com.au',
//     'hetnet.nl', 'yahoo.co.id', 'arcor.de', 'planet.nl', 'home.nl', 'shaw.ca', 'live.nl',
//
//     /* Corporate or company wide domains and franchsed domains with many locations */
//     'allstate.com', 'walmart.com', 'hrblock.com', 'linkedin.com', 'google.com', 'microsoft.com', 'amazon.com', 'twitter.com', 'fastsigns.com',
//
//
// ]

