import {
    Directive,
    ElementRef,
    NgZone,
    Renderer2,
} from '@angular/core'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'


@Directive ({
    selector: '[udScrollBox]',
})
export class ScrollBoxDirective {

    public isDragScrolling: boolean = false
    public scrollPosition = { left: 0, x: 0 }

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    protected readonly THRESHOLD = 5

    constructor(
        private ngZone: NgZone,
        private renderer: Renderer2,
        private elementRef: ElementRef<HTMLElement>,
    ) {}

    public ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.initMouseDragScrolling()
            this.initHorizontalScrolling()
        })
    }

    protected initHorizontalScrolling() {
        const onMouseWheel = (event: WheelEvent) => {
            if (!event.shiftKey && this.elementRef.nativeElement) {
                this.renderer.setProperty(
                    this.elementRef.nativeElement,
                    'scrollLeft',
                    this.elementRef.nativeElement.scrollLeft + event.deltaY,
                )
            }
        }

        this.elementRef.nativeElement.addEventListener('wheel', onMouseWheel)
        this.destroyEvent.subscribe(() => {
            this.elementRef.nativeElement.removeEventListener('wheel', onMouseWheel)
        })
    }

    protected initMouseDragScrolling() {
        const mouseUpListener = (event: MouseEvent) => {
            if (this.isDragScrolling) {
                event.preventDefault()
                event.stopPropagation()
                this.isDragScrolling = false
            }
            document.body.removeEventListener('mouseup', mouseUpListener)
            document.body.removeEventListener('mousemove', mouseMoveListener)
            document.body.removeEventListener('mouseleave', mouseLeaveListener)
        }

        const mouseMoveListener = (event: MouseEvent) => {
            if (!this.isDragScrolling) {
                const dragOffset = Math.abs(event.clientX - this.scrollPosition.x)
                if (dragOffset > this.THRESHOLD) {
                    this.isDragScrolling = true
                }
            }
            if (this.isDragScrolling) {
                let dx = event.clientX - this.scrollPosition.x
                this.elementRef.nativeElement.scrollLeft = this.scrollPosition.left - dx;
                event.preventDefault()
            }
        }

        const mouseDownListener = (event: MouseEvent) => {
            this.scrollPosition.left = this.elementRef.nativeElement.scrollLeft
            this.scrollPosition.x = event.clientX
            event.preventDefault()

            document.body.addEventListener('mousemove', mouseMoveListener)
            document.body.addEventListener('mouseup', mouseUpListener, true)
            document.body.addEventListener('mouseleave', mouseLeaveListener)
        }

        const mouseLeaveListener = () => {
            this.isDragScrolling = false
            document.body.removeEventListener('mouseup', mouseUpListener)
            document.body.removeEventListener('mousemove', mouseMoveListener)
            document.body.removeEventListener('mouseleave', mouseLeaveListener)
        }

        this.elementRef.nativeElement.addEventListener('mousedown', mouseDownListener)

        this.destroyEvent.subscribe(() => {
            document.body.removeEventListener('mouseup', mouseUpListener)
            document.body.removeEventListener('mousemove', mouseMoveListener)
            document.body.removeEventListener('mouseleave', mouseLeaveListener)
            this.elementRef.nativeElement.removeEventListener('mousedown', mouseDownListener)
        })
    }
}
