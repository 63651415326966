import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import { map } from 'rxjs/operators'

import {
    clone,
    Memoize,
} from '@undock/core'
import { MeetingDurationOption } from '@undock/dock/meet'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { EditMeetingViewModel } from '@undock/dock/meet/ui/pages/edit-meeting/view-models/edit-meeting.view-model'


@Component({
    selector: 'app-meet-edit-duration-select',
    templateUrl: 'meet-duration-select.component.html',
    styleUrls: ['meet-duration-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetDurationSelectComponent {

    @Output() onDurationChanged = new EventEmitter<MeetingDuration>()

    public constructor(
        public readonly state: EditMeetingViewModel,
    ) {}

    @Memoize()
    public get allDurationOptionsStream(): Observable<MeetingDurationOption[]> {
        return combineLatest([
            this.state.selectedMeetingDurationStream,
            this.state.isCustomMeetingDurationUsedStream,
            this.state.availableMeetingDurationOptionsStream,
        ]).pipe(
            map(([selected, isCustomUsed, options]) => {

                if (isCustomUsed) {
                    /**
                     * If custom duration is used `selected` is custom value
                     */
                    const optionExists = options.find(o => o.value === selected)

                    if (!optionExists) {
                        /**
                         * Prevent mutating original array
                         */
                        options = clone(options)

                        options.push({ gap: 0, enabled: true, value: selected })
                        options.sort((a, b) => a.value - b.value)
                    }
                }

                return options
            }),
        )
    }

    public onMeetingDurationChanged(value: MeetingDuration) {
        this.onDurationChanged.emit(value)
        return this.state.selectMeetingDuration(value)
    }
}
