import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-logo-undock',
    templateUrl: 'logo-undock.component.html',
    styleUrls: ['logo-undock.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoUndockComponent extends IconTemplateComponent {
    @Input() width: string | number = 105
    @Input() height: string | number = 13
}
