<button class="event-more-button expand-button button" mat-button
        [matMenuTriggerFor]="slotEdit"
        (click)="$event.stopPropagation()"
>
    <span class="material-icons-outlined">more_vert</span>

    <mat-menu #slotEdit>

        <button mat-menu-item>
            <span class="material-menu-item-box">
                Reschedule
            </span>
        </button>




        <button mat-menu-item class="button">
            <a class="material-menu-item-box">Cancel</a>
        </button>
    </mat-menu>
</button>





