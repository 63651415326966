import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ViewChild,
    OnInit,
} from '@angular/core'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { UntypedFormControl } from '@angular/forms'
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select'
import { ReplaySubject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'app-select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ['./select-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterComponent<T> implements OnInit {

    public optionsFilterCtrl: UntypedFormControl = new UntypedFormControl()

    @ViewChild('select', { static: true }) matSelect: MatSelect

    @Input() readonly options: T[] = []
    public filteredOptions = new ReplaySubject(1)

    @Input() value: T

    @Input()
    public readonly compareFn = (a: T, b: T) => a === b

    @Input()
    public readonly labelFn = (a: T): string => 'string' === typeof a ? a : a.toString()

    @Input()
    public readonly selectedLabelFn: (a: T) => string = null

    @Input()
    public readonly filterFn: (a: T, s: string) => boolean = null

    @Input() panelClass: string

    @Output() onSelect = new EventEmitter<T>()

    @CompleteOnDestroy()
    public selectedOptionSubject = new ValueSubject<T>(null)

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    ngOnInit() {
        // init default value
        this.selectedOptionSubject.next(this.value)

        // load initial options list
        this.filteredOptions.next(this.options.slice())

        // listen for search field value changes
        this.optionsFilterCtrl.valueChanges
            .pipe(takeUntil(this.destroyedEvent))
            .subscribe(() => {
                this.filterOptions()
            })
    }

    private _selectedIsHidden = false

    private filterOptions() {
        let search = this.optionsFilterCtrl.value
        if (!search) {
            this.filteredOptions.next(this.options.slice())
            this._selectedIsHidden = false;
            return
        } else {
            search = search.toLowerCase()
        }

        const filteredOptions = this.options.filter(item => {
            const filterMatch = typeof this.filterFn === 'function'
                ? this.filterFn(item, search)
                : this.labelFn(item).toLowerCase().indexOf(search) > -1
            if( this.isSelected(item) ){
                this._selectedIsHidden = !filterMatch;
                return true;
            } else {
                return filterMatch
            }
        })

        this.filteredOptions.next(filteredOptions)
    }

    private isSelected(optionItem: T): boolean {
        return this.compareFn(optionItem, this.selectedOptionSubject.value)
    }

    public isHidden(optionItem: T): boolean {
        return this._selectedIsHidden && this.isSelected(optionItem)
    }

    public get ignoreOption(): T {
        const current = this.selectedOptionSubject.value;
        return current && this.isHidden(current) ? current : null;
    }
}
