import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    clone,
    compareDeeply,
    Memoize,
} from '@undock/core'

import {
    default as m,
    Moment,
} from 'moment'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


@Component({
    selector: 'app-meet-time-slot-selector',
    templateUrl: 'time-slot-selector.component.html',
    styleUrls: ['time-slot-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSlotSelectorComponent {

    @Output() readonly onSlotSelected = new EventEmitter<AvailabilitySlot>()

    public readonly step$ = new ValueSubject<number>(15)
    public readonly rangeEnd$ = new ValueSubject<number>(23)
    public readonly rangeStart$ = new ValueSubject<number>(0)
    public readonly forcedLabel$ = new ValueSubject<string>('')
    public readonly selectedSlot$ = new ValueSubject<AvailabilitySlot>(null)

    @Input() tabIndex: number
    @Input() disabled: boolean = false

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    @Input() set step(value: number) {
        (typeof value === 'number') ? this.step$.next(value) : null
    }

    @Input() set rangeEnd(value: number) {
        (typeof value === 'number') ? this.rangeEnd$.next(value) : null
    }

    @Input() set rangeStart(value: number) {
        (typeof value === 'number') ? this.rangeStart$.next(value) : null
    }

    @Input() labelFormat: string = 'h:mm A'

    /**
     * Any of `selectedSlot` or `selectedTime` should be passed
     */
    @Input() set selectedSlot(value: AvailabilitySlot) {
        this.selectedSlot$.next(value)
        this.forcedLabel$.next(value.label)
    }

    /**
     * Any of `selectedSlot` or `selectedTime` should be passed
     */
    @Input() set selectedTime(value: Moment | Date | string) {
        /**
         * Converting input to moment
         */
        if (!m.isMoment(value)) {
            value = m(value)
        }

        this.selectedSlot = {
            hour: value.get('hours'),
            minute: value.get('minutes'),
            label: value.format(this.labelFormat),
        } as AvailabilitySlot
    }

    @Memoize()
    public get selectedLabel(): Observable<string> {
        return combineLatest([
            this.forcedLabel$,
            this.selectedSlot$,
        ]).pipe(
            map(([forcedLabel, selectedSlot]) => forcedLabel || selectedSlot.label),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get selectedSlotIndexStream(): Observable<number> {
        return combineLatest([
            this.forcedLabel$,
            this.selectedSlot$,
            this.availableSlotsStream,
        ]).pipe(
            debounceTime(10),
            map(([forced, selected, slots]) => {
                if (selected) {
                    for (let slot of slots) {
                        if (this.areAvailabilitySlotsSame(selected, slot)) {
                            if (forced === slot.label) {
                                this.forcedLabel$.next('')
                            }
                            return slots.indexOf(slot)
                        }
                    }
                }

                if (forced) {
                    return -2
                }

                /**
                 * By default some of evening slots should be selected
                 */
                return Math.floor(slots.length / 4 * 3)
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get availableSlotsStream(): ReactiveStream<AvailabilitySlot[]> {
        return new ReactiveStream<AvailabilitySlot[]>(
            combineLatest([
                this.step$,
                this.rangeEnd$,
                this.rangeStart$,
            ]).pipe(
                distinctUntilChanged(
                    (prev, next) => compareDeeply(prev, next),
                ),
                map(([step, end, start]) => {
                    let slots: AvailabilitySlot[] = []
                    for (let i = start; i <= end; i++) {
                        for (let j = 0; j <= 60 - step; j += step) {
                            let label
                            if (this.labelFormat) {
                                label = m().startOf('day').add(i, 'hour').add(j, 'minute').format(this.labelFormat)
                            } else {
                                let mod = i % 12, isAM = i <= 11
                                label = (mod === 0 ? 12 : mod) + ':' + (j === 0 ? '00' : j) + ' ' + (isAM ? 'AM' : 'PM')
                            }
                            slots.push({
                                hour: i,
                                minute: j,
                                label,
                            } as AvailabilitySlot)
                        }
                    }
                    return slots
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    public async selectAvailabilitySlot(index: number) {
        const slots = await this.availableSlotsStream
        let selectedSlot = slots[index]
        if (selectedSlot) {
            this.selectedSlot$.next(selectedSlot)
            this.onSlotSelected.emit(selectedSlot)
        }
    }

    protected areAvailabilitySlotsSame(a: AvailabilitySlot, b: AvailabilitySlot): boolean {
        return a.hour === b.hour && a.minute === b.minute
    }
}
