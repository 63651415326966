<ng-container *ngIf="availableMeetingModeOptionsStream | stream as options">

    <ng-container *ngIf="{
        allowedModes: availableMeetingModeOptionsStream | stream
    } as context">
        <mat-button-toggle-group
            #group="matButtonToggleGroup"
            class="mat-button-toggle-group"
            [value]="selectedMeetingModeStream | stream"
            (change)="onMeetingModeChanged($event.value)"
        >
            <ng-container *ngIf="context.allowedModes?.includes(MeetingMode.Video)">
                <mat-button-toggle [value]="MeetingMode.Video" aria-label="Video" matTooltip="Video">
                    <app-icon-remote *ngIf="showIcons" [width]="20" [height]="20" [color]="group.value === MeetingMode.Video ? '#2B2733' : '#A59FB9'"></app-icon-remote>
                    <span class="__label">Remote</span>
                </mat-button-toggle>
            </ng-container>

            <ng-container *ngIf="showAudioOnly && context.allowedModes?.includes(MeetingMode.Audio)">
                <mat-button-toggle [value]="MeetingMode.Audio" aria-label="Audio" matTooltip="Audio">
                    <app-icon-audio-on *ngIf="showIcons" [width]="20" [height]="20" [color]="group.value === MeetingMode.Audio ? '#2B2733' : '#A59FB9'"></app-icon-audio-on>
                    <span class="__label">Remote Audio</span>
                </mat-button-toggle>
            </ng-container>

            <ng-container *ngIf="context.allowedModes?.includes(MeetingMode.InPerson)">
                <mat-button-toggle [value]="MeetingMode.InPerson" aria-label="In Person" matTooltip="In Person">
                    <app-icon-in-person *ngIf="showIcons" [width]="20" [height]="20" [color]="group.value === MeetingMode.InPerson ? '#2B2733' : '#A59FB9'"></app-icon-in-person>
                    <span class="__label">In person</span>
                </mat-button-toggle>
            </ng-container>
        </mat-button-toggle-group>
    </ng-container>

</ng-container>