import {
    Directive,
    NgModuleRef,
    OnInit,
    ViewContainerRef,
} from '@angular/core'

import { ParticipantSelectorService } from '../providers/participant-selector.service'


@Directive({
    selector: '[participant-selector-host]',
    providers: [ParticipantSelectorService],
})
export class ParticipantSelectorHostDirective implements OnInit {

    public constructor(
        private ngModuleRef: NgModuleRef<any>,
        private viewContainerRef: ViewContainerRef,
        private participantSelectorService: ParticipantSelectorService,
    ) {}

    public ngOnInit(): void {

        if (this.participantSelectorService.isHostAlreadyRegistered) {
            /**
             * If you see this error please check is participant-selector-host already defined above on the elements tree
             */
            throw new Error(
                `It seems that participant-selector-host already registered for this context`,
            )
        }

        this.participantSelectorService.registerHost(this.viewContainerRef, this.ngModuleRef)
    }
}
