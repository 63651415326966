import { ApiRoute } from '@undock/api/services/api.route'
import { ConnectionType } from '@undock/people'


export class ConnectionRoute extends ApiRoute {
    public init() {
        this.route = 'contacts/connection'
    }

    public async getConnections(): Promise<Connection[]> {
        return this.get<Connection[]>()
                   .endpoint(`list`)
    }

    public async getConnectionRequests(): Promise<ConnectionRequest[]> {
        return this.get<ConnectionRequest[]>()
                   .endpoint(`requests`)
    }

    public async getConnectedUsersIds(): Promise<string[]> {
        return this.get<string[]>()
                   .endpoint(`users`)
    }

    public async requestConnection(target: string): Promise<any> {
        return this.post()
                   .withData({ target })
                   .endpoint(`request`)
    }

    public async approveConnectionRequest(request: string): Promise<any> {
        return this.post()
                   .withData({ request })
                   .endpoint(`approve`)
    }

    public async denyConnectionRequest(request: string): Promise<any> {
        return this.post()
                   .withData({ request })
                   .endpoint(`deny`)
    }
}


export interface Connection {
    id: string
    connectionType: ConnectionType
    lastMetDate: Date
    meetingsCount: number
    connectedUserId: string
}

export interface ConnectionRequest {
    request: string
    requester: string
}
