import {
    Pipe,
    PipeTransform,
} from '@angular/core'

@Pipe({ name: 'repeatElement', pure: true })
export class RepeatElementPipe implements PipeTransform {

    /**
     * Repeats the element 'count' times in the HTML template
     */
    public transform(count: number, countOffset?: number): any {
        if (countOffset > 0) {

        }
        const iterable = <Iterable<any>>{}
        iterable[Symbol.iterator] = function* () {
            let n = 0
            while (n < count) {
                yield n++
            }
        }
        return iterable
    }
}
