import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { ArrowButtonComponent } from '@undock/common/ui-kit/ui/components/buttons/arrow-button/arrow-button.component'

@Component({
    selector: 'app-short-arrow-button',
    templateUrl: 'short-arrow-button.component.html',
    styleUrls: ['short-arrow-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortArrowButtonComponent extends ArrowButtonComponent {

}
