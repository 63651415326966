import { ApiRoute } from '@undock/api'
import { IChannel } from '@undock/api/scopes/organizations/contracts'

export class ChannelsRoute extends ApiRoute {

    public init() {
        this.route = 'organizations/channels'
    }

    public search(
        criteria: string,
        page: number = 0,
        pageSize: number = 50,
    ) {
        return this.get<IChannel[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                   })
                   .endpoint(`search/${criteria}`)
    }

    public list(
        organizationId: string,
    ) {
        return this.get<IChannel[]>()
                   .endpoint(`list/${organizationId}`)
    }

    public listOwn(
        organizationId: string,
    ) {
        return this.get<IChannel[]>()
                   .endpoint(`list-own/${organizationId}`)
    }

    public listOwnForAllOrganizations() {
        return this.get<IChannel[]>()
                   .endpoint(`list-all`)
    }

    public create(data: Partial<IChannel>) {
        return this.post<void>()
                   .withData(data)
                   .endpoint('create')
    }

    public update(
        channelId: string,
        data: Partial<IChannel>,
    ) {
        return this.post()
                   .withData(data)
                   .endpoint(`update/${channelId}`)
    }
}
