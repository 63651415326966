import {
    Pipe,
    PipeTransform,
} from '@angular/core'

import {
    default as moment,
    Moment,
} from 'moment'


@Pipe({ name: 'moment', pure: true })
export class MomentPipe implements PipeTransform {

    public transform(value: Date, format: string): string
    public transform(value: Object, format: string): string
    public transform(value: string, format: string): string
    public transform(value: Moment, format: string): string
    public transform(value: moment.Moment, format: string): string
    public transform(value: moment.Moment | Moment | Date | string, format: string): string {

        if (!value) {
            return ''
        }

        if (!moment.isMoment(value)) {
            /**
             * If the Firestore Timestamp format given
             */
            if (typeof value === 'object' && 'seconds' in value) {
                value = moment.unix((value as any).seconds)
            } else {
                value = moment(value)
            }
        }

        return value.format(format)
    }
}
