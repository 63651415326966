import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import {
    map,
    tap,
} from 'rxjs/operators'

import { AuthManager } from '@undock/auth/services/auth.manager'
import { AuthSession } from '@undock/auth'


@Injectable()
export class NotAuthorizedUserGuard implements CanActivate {
    public constructor(
        private router: Router,
        private authManager: AuthManager,
    ) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this.authManager.isRegularUserStream.pipe(
            tap(async (isRegularUser) => {
                if (!isRegularUser) return

                const redirectPath = next.queryParamMap.get('redirectPath')
                if (redirectPath && redirectPath?.length > 0) {
                    return this.router.navigate([this.filterRedirectUrl(redirectPath)])
                } else {
                    return this.router.navigate(['/'])
                }
            }),
        )
    }

    private filterRedirectUrl(redirectUrl: string) {
        // there should not be redirects to logout, login or signup page after log in
        if (redirectUrl && !redirectUrl?.startsWith('/logout') && !redirectUrl?.startsWith('logout') && !redirectUrl?.startsWith('/login') && !redirectUrl?.startsWith('login') && !redirectUrl?.startsWith('/signup') && !redirectUrl?.startsWith('signup')) {
            return redirectUrl
        }
        return '/timeline'
    }
}
