import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Inject,
    Input,
    OnInit,
} from '@angular/core'

import {
    combineLatest,
    fromEvent,
    Observable,
    of,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    take,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    SubscriptionsHub,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    BlurEvent,
    CKEditor5,
} from '@ckeditor/ckeditor5-angular'
import MarkdownEditor from '@ckeditor/ckeditor5-custom-build/build/ckeditor'

import {
    AttachmentsManager,
    Config,
    isEmptyString,
    Memoize,
} from '@undock/core'
import {
    ckEditorConfig,
    createUploadAdapterPlugin,
    Markdown2HtmlConverter,
} from '@undock/common/editor'
import { clone } from '@undock/core/utils/clone'
import { patchObject } from '@undock/core/utils/patch-object'
import {
    NOTES_ADAPTER,
    NotesAdapterInterface,
} from '@undock/dock/meet/contracts/ui-adapters/notes.adapter'


@Component({
    selector: 'app-meet-notes',
    templateUrl: 'notes.component.html',
    styleUrls: ['notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit {

    @HostBinding('class.__empty')
    private isEmpty: boolean = true

    @Input() disabled = false

    @Input() placeholder: string = 'Set the Agenda...'

    public readonly isEditModeStream: ReactiveStream<boolean>

    @CompleteOnDestroy()
    private editorTypeSubject = new ValueSubject(MarkdownEditor)

    @CompleteOnDestroy()
    private noteChangesSubject = new StatefulSubject<string>()

    @CompleteOnDestroy()
    private isFullscreenModeActiveSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private editorInstanceSubject = new StatefulSubject<CKEditor5.Editor>()

    @CompleteOnDestroy()
    private isEditorFocusedSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private editorClipboardPasteStream = new StatefulSubject<string>()


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    /**
     * Used for automatically saving changes
     */
    private readonly editorChangesDebounceTime: number = 300

    public constructor(
        private config: Config,
        @Inject(NOTES_ADAPTER)
        private notesAdapter: NotesAdapterInterface,
        private markdown2Html: Markdown2HtmlConverter,
        private attachmentsManager: AttachmentsManager,
    ) {
        this.isEditModeStream = this.notesAdapter.isEditModeStream

        /**
         * Using optional debounce time for changes
         */
        if (this.notesAdapter.editorChangesDebounceTime) {
            this.editorChangesDebounceTime = this.notesAdapter.editorChangesDebounceTime
        }

        /**
         * Initial editor settings
         */
        MarkdownEditor.markdownModeEnabled = false
        MarkdownEditor.balloonToolbarEnabled = !this.isFullscreenModeActiveSubject.value
    }

    @Memoize()
    public get editorDataStream(): Observable<{ type: any, config: any }> {
        return combineLatest([
            this.editorTypeSubject, this.editorConfigStream,
        ]).pipe(
            map(
                ([type, config]) => ({ type, config }),
            ),
        )
    }

    @Memoize()
    public get editorConfigStream(): Observable<Record<string, any>> {
        return combineLatest([
            of(clone(ckEditorConfig)),
            this.notesAdapter.attachmentSourceStream.pipe(
                distinctUntilChanged(
                    (prev, next) => prev.id === next.id,
                ),
            ),
        ]).pipe(
            map(sources => {
                const [config, dock] = sources
                /**
                 * Updates default config
                 */
                return patchObject(config, {
                    placeholder: this.placeholder,
                    toolbar: {
                        items: {
                            $unset: ['maximize'],
                        },
                    },
                    image: {
                        styles: [
                            'alignLeft', 'alignCenter', 'alignRight',
                        ],
                        resizeOptions: [
                            {
                                name: 'resizeImage:original',
                                label: 'Original',
                                value: null,
                            },
                            {
                                name: 'resizeImage:50',
                                label: '50%',
                                value: '50',
                            },
                            {
                                name: 'resizeImage:75',
                                label: '75%',
                                value: '75',
                            },
                        ],
                        toolbar: {
                            $add: [
                                'imageStyle:alignLeft',
                                'imageStyle:alignCenter',
                                'imageStyle:alignRight',
                                '|',
                                'resizeImage',
                            ],
                            $unset: [
                                'imageStyle:full',
                                'imageStyle:side',
                            ],
                        },
                    },
                    extraPlugins: [
                        createUploadAdapterPlugin(dock, this.attachmentsManager),
                    ],
                })
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isFullscreenModeActiveStream(): Observable<boolean> {
        return this.isFullscreenModeActiveSubject.asObservable()
    }


    public async ngOnInit() {
        this.initializeEditorContentUpdating()

        /**
         * Setting up initial notes value
         */
        combineLatest([
            this.editorInstanceSubject,
            this.notesAdapter.notesTextStream,
        ]).pipe(
            take(1),
        ).subscribe(sources => {
            const [editor, notes] = sources
            editor.setData(notes)
        })

        if (await this.isEditModeStream) {
            await Promise.all([
                this.initializeEditorEvents(),
                this.initializeNotesAutoSaving(),
                this.initializeEditorFullscreenMode(),
            ])
        }
    }


    public toggleFullscreenMode(): void {
        this.isFullscreenModeActiveSubject.next(
            !this.isFullscreenModeActiveSubject.value,
        )
    }

    public onEditorReady(editor) {
        this.editorInstanceSubject.next(editor)
    }

    public onEditorContentChanged(note: string) {
        this.noteChangesSubject.next(note)

    }

    protected async initializeEditorEvents() {
        const editorSubscriptionsHub = new SubscriptionsHub(this.destroyedEvent)
        this.editorInstanceSubject.subscribe(editor => {
            /**
             * Removing all subscriptions if editor instance been changed
             */
            editorSubscriptionsHub.unsubscribe()

            const editableElement = editor?.ui?.getEditableElement()
            if (editableElement) {
                editorSubscriptionsHub.add.apply(editorSubscriptionsHub, [
                    fromEvent<FocusEvent>(editor.ui.getEditableElement(), 'focus').pipe(
                        debounceTime(1000),
                    ).subscribe(() => {
                        this.isEditorFocusedSubject.next(true)
                    }),
                    fromEvent<BlurEvent>(editor.ui.getEditableElement(), 'blur').pipe(
                        debounceTime(1000),
                    ).subscribe(() => {
                        this.isEditorFocusedSubject.next(false)
                    }),
                    fromEvent<ClipboardEvent>(editor.ui.element, 'paste').pipe(
                        map($event => $event.clipboardData.getData('text/plain')),
                    ).subscribe((insertedContent: string) => {
                        this.editorClipboardPasteStream.next(insertedContent)
                    }),
                ])
            }
        })
    }

    private initializeEditorContentUpdating() {
        combineLatest([
            this.notesAdapter.notesTextStream,
            this.editorInstanceSubject,
            this.isEditorFocusedSubject.pipe(debounceTime(200)),
        ]).pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(sources => {
            let [data, editor, isFocused] = sources

            /**
             * Updating editor content only if editor is not focused
             *
             * After the focus been blurred all changes appear
             */
            if (!isFocused && data !== editor.getData()) {
                if (!MarkdownEditor.markdownModeEnabled) {
                    /**
                     * Checking what kind of data we got
                     */
                    if (!this.markdown2Html.isHTML(data)) {
                        /**
                         * Converting Markdown data into HTML
                         */
                        data = this.markdown2Html.toHtml(data)

                        /**
                         * Pushing changes to update data source
                         */
                        this.noteChangesSubject.next(data)
                    }
                }
                editor.setData(data)
            }
        })
    }

    protected async initializeEditorFullscreenMode() {
        fromEvent<Event>(
            document, 'fullscreenchange',
        ).pipe(
            takeUntil(this.destroyedEvent),
            map(() => !!document.fullscreenElement),
        ).subscribe(
            isFullscreenModeActive => this.isFullscreenModeActiveSubject.next(isFullscreenModeActive),
        )

        this.isFullscreenModeActiveStream.pipe(
            takeUntil(this.destroyedEvent),
            distinctUntilChanged(),
        ).subscribe(isFullscreenEnabled => {
            if (MarkdownEditor.balloonToolbarEnabled === isFullscreenEnabled) {
                MarkdownEditor.balloonToolbarEnabled = !isFullscreenEnabled

                /**
                 * We should render new editor to apply toolbar changes
                 */
                this.editorTypeSubject.next(null)

                /**
                 * Running this in after change detection been processed to render new editor
                 */
                setTimeout(() => {
                    this.editorTypeSubject.next(MarkdownEditor)
                    if (isFullscreenEnabled) {
                        this.tryEnterEditorFullscreenMode()
                    }
                }, 10)
            }
        })
    }

    protected async initializeNotesAutoSaving() {
        this.noteChangesSubject.pipe(
            takeUntil(this.destroyedEvent),
            debounceTime(this.editorChangesDebounceTime),
        ).subscribe(
            note => this.saveNoteChanges(note),
        )
    }

    protected async saveNoteChanges(note: string): Promise<void> {
        await this.notesAdapter.setNotes(note)
        this.isEmpty = isEmptyString(note)
    }

    protected tryEnterEditorFullscreenMode() {
        const subscription = this.editorInstanceSubject.pipe(
            /**
             * Stream can get already destroyed editor if the toolbar state been changed
             */
            filter(editor => editor.state === 'ready'),
        ).subscribe(editor => {
            try {
                const maximizeCommand = editor.commands.get('maximize')
                if (maximizeCommand) {
                    maximizeCommand._enterFullscreenMode()
                }
            } finally {
                subscription.unsubscribe()
            }
        })
    }
}
