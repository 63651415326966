<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1669_10427)">
        <g clip-path="url(#clip1_1669_10427)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4995 4.86871C23.6149 5.269 22.6659 5.54076 21.6702 5.66195C22.6874 5.03764 23.4681 4.04609 23.8334 2.86724C22.8807 3.44748 21.8278 3.86614 20.7068 4.09383C19.8079 3.11329 18.5293 2.5 17.1111 2.5C14.3928 2.5 12.1867 4.76221 12.1867 7.54958C12.1867 7.9462 12.2297 8.3318 12.3156 8.69904C8.22207 8.48972 4.59411 6.47723 2.16592 3.42178C1.74332 4.16728 1.49978 5.03397 1.49978 5.95942C1.49978 7.71116 2.37006 9.25725 3.6916 10.1607C2.88578 10.135 2.12653 9.90727 1.46039 9.52901C1.46039 9.55105 1.46039 9.56941 1.46039 9.59144C1.46039 12.0373 3.15797 14.0791 5.41067 14.5419C4.99881 14.6557 4.56188 14.7181 4.11421 14.7181C3.79546 14.7181 3.48746 14.6851 3.18662 14.6263C3.81337 16.6315 5.63272 18.0931 7.78514 18.1335C6.0983 19.4886 3.97811 20.2965 1.66811 20.2965C1.27057 20.2965 0.8802 20.2708 0.493408 20.2268C2.6709 21.659 5.26025 22.4963 8.043 22.4963C17.1004 22.4963 22.0534 14.8026 22.0534 8.12982C22.0534 7.90947 22.0499 7.6928 22.0391 7.47613C23.0025 6.76368 23.837 5.87495 24.496 4.86137L24.4995 4.86871Z" fill="#41ABE1"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_1669_10427">
            <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
        <clipPath id="clip1_1669_10427">
            <rect width="24.0061" height="19.9963" fill="white" transform="translate(0.493408 2.5)"/>
        </clipPath>
    </defs>
</svg>
