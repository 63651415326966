import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ViewChild,
    ElementRef,
    Inject,
} from '@angular/core'

import moment, { Moment } from 'moment'

import {
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { combineLatest } from 'rxjs'

import { Memoize } from '@undock/core'
import { CurrentUser } from '@undock/session'
import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'
import { DropdownDirective } from '@undock/common/ui-kit/ui/directives'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { EditScheduleComponent } from '@undock/dock/meet/ui/pages/edit-meeting'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'


@Component({
    selector: 'app-meet-event-form-schedule',
    templateUrl: 'event-form-schedule.component.html',
    styleUrls: [
        '_shared/event-form-schedule.scss',
        'event-form-schedule.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormScheduleComponent extends EditScheduleComponent {

    public readonly state = this.eventFormStateModel.state
    public readonly isAvailabilityLoadingStream: ReactiveStream<boolean>
    public readonly selectedAvailabilityDayStream: ReactiveStream<Moment>

    @Input() placeholder?: string = 'Choose the date and time'

    /**
     * Optional host for dropdown elem
     */
    @Input() dropdownElHost?: HTMLElement

    @ViewChild('dropdownRef')
    public readonly dropdownRef: DropdownDirective

    @CompleteOnDestroy()
    public readonly isSettingsOpened = new ValueSubject(false)

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        public readonly elementRef: ElementRef,
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,
        protected readonly currentUser: CurrentUser,
        protected readonly eventFormStateModel: EventFormStateModel,
        protected readonly availabilityProvider: AvailabilityProvider,
        protected readonly availabilityViewModel: AvailabilityViewModel,
    ) {
        super()

        this.isAvailabilityLoadingStream = this.availabilityViewModel.isAvailabilityLoadingStream
        this.selectedAvailabilityDayStream = this.availabilityViewModel.selectedAvailabilityDayStream
    }

    public get isDateSelected(): boolean {
        return Boolean(this.schedule?.start && this.schedule?.end)
    }

    public get dropdownHostRef(): HTMLElement {
        return this.dropdownElHost ?? this.elementRef.nativeElement
    }


    /**
     * TODO: Move to separate component
     */
    @Memoize()
    public get availabilityStream(): ReactiveStream<AvailabilitySet> {
        return new ReactiveStream(
            combineLatest([
                this.availabilityViewModel.displayAvailabilityStream,
                this.availabilityViewModel.selectedAvailabilityDayStream,
            ]).pipe(
                takeUntil(this.destroyEvent),
                map(([availability, day]) => {
                    return availability?.find(set => set.day.isSame(day, 'day'))
                }),
            ),
        )
    }

    public ngOnInit() {
        super.ngOnInit()
        this.selectedItem$.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(selected => {
            if (selected) {
                return this.selectAvailabilitySlot(selected)
            }
        })
    }

    public async useCustomTime() {
        const start = moment().startOf('hour')
                              .add(1, 'hour')

        this.onScheduleUpdated.next({
            ...this.schedule,
            start: start.toDate(),
            end: start.clone().add(1, 'hour').toDate(),
        })
    }

    public async displayPrevAvailabilityDays(days = 1) {
        await this.availabilityViewModel.selectAvailabilityDay(
            (await this.availabilityViewModel.selectedAvailabilityDayStream)
                .clone().subtract(days, 'day'),
        )
        setTimeout(() => {
            this.selectedItem$.next()
            this.activatePrevListItem()
        }, 250)
    }

    public async displayNextAvailabilityDays(days = 1) {
        await this.availabilityViewModel.selectAvailabilityDay(
            (await this.availabilityViewModel.selectedAvailabilityDayStream)
                .clone().add(days, 'day'),
        )
        setTimeout(() => {
            this.selectedItem$.next()
            this.activateNextListItem()
        }, 250)
    }

    public async selectAvailabilitySlot(slot: AvailabilitySlot) {
        this.onScheduleUpdated.next({
            ...this.schedule,
            start: new Date(slot.timeStamp),
            end: moment(slot.timeStamp).add(
                await this.eventFormStateModel.state.durationStream, 'minutes',
            ).toDate(),
        })

        //await this.availabilityViewModel.selectAvailabilityDay(moment(slot.timeStamp))
    }


    /**
     * Temporary functionality for key arrow selection
     * TODO: Rework this with some shared directive or service
     */

    @CompleteOnDestroy()
    public readonly selectedItem$ = new ValueSubject(null)

    public async applyActiveListItem() {
        if (this.selectedItem$.value) {
            await this.selectAvailabilitySlot(this.selectedItem$.value)
        }
    }

    public async activatePrevListItem() {
        const options = (await this.availabilityStream).slots
        if (!this.selectedItem$.value) {
            this.selectedItem$.next(
                options[options.findIndex(option => option.free)] ?? null,
            )
        } else {
            const currItemIndex = options.findIndex(
                option => option?.timeStamp === this.selectedItem$.value?.timeStamp
            )

            let prevItemIndex: number
            if (currItemIndex > 0) {
                for (let index = currItemIndex - 1; index >= 0; index--) {
                    if (options[index].free) {
                        prevItemIndex = index
                        break
                    }
                }
            }

            if (!prevItemIndex) {
                prevItemIndex = options.findIndex(option => option.free)
            }

            this.selectedItem$.next(options[prevItemIndex])
        }

        this.ensureFocusedItemVisible().catch(error => console.error(error))
    }

    public async activateNextListItem() {
        const options = (await this.availabilityStream).slots

        if (!this.selectedItem$.value) {
            this.selectedItem$.next(
                options[options.findIndex(option => option.free)] ?? null,
            )
        } else {
            const currItemIndex = options.findIndex(
                option => option?.timeStamp === this.selectedItem$.value?.timeStamp
            ) || 0

            let nextItemIndex: number
            if (currItemIndex < options.length) {
                for (let index = currItemIndex + 1; index < options.length; index++) {
                    if (options[index].free) {
                        nextItemIndex = index
                        break
                    }
                }
            }

            if (!nextItemIndex) {
                nextItemIndex = currItemIndex
            }

            this.selectedItem$.next(options[nextItemIndex < options.length ? nextItemIndex : options.length - 1])
        }

        this.ensureFocusedItemVisible().catch(error => console.error(error))
    }

    /**
     * This is a temporary solution
     * TODO: Rework with better approach using directive assigned to every list item
     */
    protected async ensureFocusedItemVisible() {
        /**
         * Searching for all list items in the target dropdown
         */
        const listItems = document.querySelectorAll<HTMLElement>(
            '.event-form-schedule-dropdown .available-slots-list .available-slot'
        )

        Array.from(listItems).forEach(listItem => {
            if (listItem.classList.contains('active')) {
                listItem.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        })
    }
}
