import {
    Pipe,
    PipeTransform,
} from '@angular/core'

@Pipe({
    name: 'removeLinkProtocol', pure: true,
})
export class RemoveLinkProtocolPipe implements PipeTransform {
    public transform(link: string): string {

        if (!link) return link

        return link.replace(/https?:\/\//, '')
    }
}