import { ApiRoute } from '@undock/api/services/api.route'
import { SyncedCalendar } from '@undock/integrations'


export class AuthRoute extends ApiRoute {
    public init() {
        this.route = 'integrations/auth'
    }

    public async getCalendarConnectionUrl(callback, provider: 'google' | 'microsoft' | 'zoom'): Promise<string> {
        return this.post<string>()
                   .withData({
                       callback, provider,
                   }).endpoint('build-redirect-url')
    }

    public async getIntegrationConnectionUrl(callback, provider: 'stripe' | 'gmail'): Promise<string> {
        return this.post<string>()
                   .withData({
                       callback, provider, ttl: 3600
                   }).endpoint('build-redirect-url')
    }

    public async getGuestState(): Promise<SyncedCalendar> {
        return this.post<SyncedCalendar>().endpoint('guest-state')
    }
}
