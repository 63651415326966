import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'


@Component({
    selector: 'app-user-icon',
    templateUrl: 'user-icon.component.html',
    styleUrls: ['user-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIconComponent {}
