import { Router } from '@angular/router'
import { Injectable } from '@angular/core'

import {
    CalendarEvent as CalGridEvent
} from 'angular-calendar'
import {
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import { StreamStore, StateModel } from '@undock/core/states'

import { Subject } from 'rxjs'
import { Api } from '@undock/api'
import { SessionStorage } from '@undock/core'
import { UiTimelineEvent } from '@undock/dashboard/contracts'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'


export class EventDetailsStore extends StreamStore {
    /**
     *
     */
    public readonly onClose = new Subject<void>()

    public readonly onUpdates = new StatefulSubject<Partial<EditMeetingData>>()

    /**
     *
     */
    public readonly calendarEvent = new StatefulSubject<CalGridEvent<{ payload: UiTimelineEvent }>>()

    /**
     * Flags
     */
    public readonly isEditMode = new ValueSubject<boolean>(false)
    public readonly isPreviewAllowed = new ValueSubject<boolean>(false)
    public readonly isEditingAllowed = new ValueSubject<boolean>(false)
    public readonly isDockInitialized = new ValueSubject<boolean>(false)
}

/**
 *
 */
@Injectable()
export class EventDetailsViewModel extends StateModel<EventDetailsStore> {

    protected readonly store = new EventDetailsStore()

    /**
     *
     */
    protected onBeforeClosedHook: () => Promise<boolean>

    protected currentEventFormStateModel: EventFormStateModel

    public constructor(
        protected readonly api: Api,
        protected readonly router: Router,
        protected readonly sessionStorage: SessionStorage,
        protected readonly snackbarManager: SnackbarManager,
    ) {
        super()
    }

    public async initViewModel(event: CalGridEvent<{ payload: UiTimelineEvent }>) {
        // Assign the event to store
        this.store.calendarEvent.next(event)

        if (event.meta.payload.isDraft) {
            this.store.isEditMode.next(true)
            this.store.isPreviewAllowed.next(false)
            this.store.isDockInitialized.next(true)
        } else {
            // Force edit mode by default for new events
            this.store.isPreviewAllowed.next(
                Boolean(event.meta.payload.id),
            )

            this.store.isEditingAllowed.next(
                event.meta.payload.isOwner
                  && event.meta.payload.editingAllowed
            )

            const isDockInitialized = Boolean(event.meta.payload.dockId)
            this.store.isDockInitialized.next(isDockInitialized)
            if (!isDockInitialized) {
                this.initializeLazyLoadDockForEvent().catch(error => {
                    this.store.onClose.next()
                    console.warn(`Cannot initialize dock`, error)
                    this.snackbarManager.error(`An error happened. Please try later`)
                })
            }
        }
    }

    public async updateEventData(updates: Partial<EditMeetingData>) {
        if (this.store.isEditingAllowed.value) {
            this.store.isEditMode.next(true)
        }
        this.store.onUpdates.next(updates)
    }

    public setOnBeforeClosedHook(callable: () => Promise<boolean>) {
        this.onBeforeClosedHook = callable
    }

    public setEventFormStateModel(eventFormStateModel: EventFormStateModel) {
        this.currentEventFormStateModel = eventFormStateModel
    }

    public async forceClose() {
        return this.store.onClose.next(), true
    }

    public async requestClose() {
        /**
         * Edit mode requires more checks before been closed
         */
        if (typeof this.onBeforeClosedHook === 'function') {
            if (await this.onBeforeClosedHook()) {
                return this.forceClose()
            }
        } else {
            /**
             * Close if no callback is registered
             */
            return this.forceClose()
        }
    }

    public async enterEditMode() {
        if (this.store.isEditingAllowed.value) {
            this.store.isEditMode.next(true)
        }
        return this.store.isEditingAllowed.value
    }

    public async openStandaloneEdit() {
        // We should save all draft changes before leaving the page
        if (this.store.isEditMode.value && this.currentEventFormStateModel) {
            const eventFormState = this.currentEventFormStateModel.state
                , originalEventData = await eventFormState.originalEventDataStream

            const updateEventData = await this.currentEventFormStateModel.getUpdatedMeetingData()
            if (await eventFormState.isDraftModeStream) {
                // Save all changes to the draft meeting
                await this.api.meet.meetings.updateDraftMeeting(originalEventData._id, updateEventData)
            } else {
                // Save updates data in the session store
                const key = `@undock[UpdateEventData][${originalEventData.dockKey}]`
                this.sessionStorage.setItem(key, JSON.stringify({
                    expiresAt: Date.now() + 5000,
                    updateEventData: updateEventData,
                    originalEventData: originalEventData,
                }))
            }

            return this.router.navigate(['meet', 'edit-event', originalEventData.dockKey])
        }
    }

    protected async initializeLazyLoadDockForEvent() {
        const event = await this.store.calendarEvent
        if (event && event.meta.payload.dockKey) {
            const dockId = await this.ensureDockExistAndGetId(event.meta.payload.dockKey)
            if (dockId) {
                event.meta.payload.dockId = dockId
                this.store.calendarEvent.next(event)
                this.store.isDockInitialized.next(true)
                return
            }
        }

        /**
         * Close the popup if an error occurred
         */
        await this.store.onClose.next()
        console.warn(`Cannot open popup for event`, event)
        this.snackbarManager.error(`Cannot open event ${event.title}`)
    }

    /**
     * @TODO: Replace all duplicates with single implementation
     */
    protected async ensureDockExistAndGetId(dockKey: string): Promise<string> {
        const dock = await this.api.meet.dock
                               .getBySharedAccessSecret(dockKey)
        return dock.id
    }
}
