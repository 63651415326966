import {
    OnInit,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    Router,
    ActivatedRoute,
} from '@angular/router'

import {
    timer,
    combineLatest,
} from 'rxjs'
import {
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'

import { Memoize } from '@undock/core'
import { Api } from '@undock/api/services/api.service'
import { AuthManager } from '@undock/auth/services/auth.manager'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'



@Component({
    selector: 'app-email-is-not-verified-page',
    templateUrl: 'email-is-not-verified-page.component.html',
    styleUrls: ['email-is-not-verified-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailIsNotVerifiedPageComponent implements OnInit {

    @CompleteOnDestroy()
    public targetEmailStream = new ValueSubject('')

    @CompleteOnDestroy()
    public isInitializedStream = new ValueSubject(false)

    /**
     * Timestamp ms when next confirmation email is available
     */
    @CompleteOnDestroy()
    protected nextConfirmationEmailAvailableAt = new ValueSubject(0)

    private readonly confirmationEmailInterval = 60 * 10**3 // 1 minute (ms)

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private api: Api,
        private router: Router,
        private authManager: AuthManager,
        private activatedRoute: ActivatedRoute,
        private snackbarManager: SnackbarManager,
    ) {}

    @Memoize()
    public get nextConfirmationEmailAllowedIn(): ReactiveStream<number> {
        return new ReactiveStream<number>(
            combineLatest([
                timer(0, 1000),
                this.nextConfirmationEmailAvailableAt,
            ]).pipe(
                map(([_, nextConfirmationEmailAvailableAt]) => {
                    const now = Date.now()
                    if (nextConfirmationEmailAvailableAt > now) {
                        return Math.floor((nextConfirmationEmailAvailableAt - now) / 1000)
                    }
                    return 0
                }),

                takeUntil(this.destroyEvent),
            )
        )
    }


    public async ngOnInit() {
        const authUser = await this.authManager.authUserStream
        const token = await authUser.getIdTokenResult(true)

        /**
         * Additional check to ensure verification is required
         */
        if (
            !authUser.emailVerified &&
            token.signInProvider === 'password' &&
            authUser.providerData.some(p => p.providerId === 'password')
        ) {
            /**
             * Searching for an email address to be verified
             */
            this.targetEmailStream.next(
                authUser.providerData.reduce((email, data) => {
                    return email ?? (data.providerId === 'password' ? data.email : null)
                }, null as string)
            )

            /**
             * A single confirmation email per minute is allowed
             */
            const confirmationEmailSentAt = token.claims.confirmationEmailSentAt || 0
                , nextConfirmationEmailAllowedAt = confirmationEmailSentAt + this.confirmationEmailInterval

            if (nextConfirmationEmailAllowedAt <= Date.now()) {
                /**
                 * Confirmation email is allowed to be sent
                 */
                this.nextConfirmationEmailAvailableAt.next(0)
            } else {
                this.nextConfirmationEmailAvailableAt.next(nextConfirmationEmailAllowedAt)
            }


            this.isInitializedStream.next(true)

            /**
             * Navigates to the application automatically
             */
            this.initializeEmailVerifiedStatusRefresh()
        } else {
            /**
             * Navigating the user back to the application
             */
            return this.navigateToTheApplication()
        }
    }

    public async login() {
        return this.authManager.login('/')
    }

    public async logout() {
        return this.authManager.logout()
    }

    public async sendVerificationEmail() {

        const user = await this.authManager.authUserStream

        /**
         * Reloading user data to be sure is up-to-date
         */
        await user.reload()

        if (!user.emailVerified) {
            const nextConfirmationEmailAllowedIn = await this.nextConfirmationEmailAllowedIn
            if (nextConfirmationEmailAllowedIn > 0) {
                return this.snackbarManager.error(`A single verification email per minute is allowed`)
            }

            try {
                /**
                 * Sending a confirmation email
                 */
                await this.api.auth.providers.sendConfirmationEmail()
                /**
                 * Displaying success message after notification is sent
                 */
                this.snackbarManager
                    .success(`Confirmation email sent to ${await this.targetEmailStream}`)

                /**
                 * Estimating a timestamp when next confirmation email is available
                 */
                this.nextConfirmationEmailAvailableAt.next(Date.now() + this.confirmationEmailInterval)
            } catch (error) {
                console.error(`Cannot send a confirmation email`, error)
                this.snackbarManager
                    .error(`Cannot send confirmation email. Please try later.`)
            }
        } else {
            this.snackbarManager.success(`Login ID is already confirmed`)
            return this.navigateToTheApplication()
        }
    }

    protected async navigateToTheApplication() {
        /**
         * Email verification isn't required for this user
         */
        const redirectPath = this.activatedRoute
                                 .snapshot
                                 .queryParamMap.get('redirectPath')
        /**
         * Navigating the user back to the application
         */
        return this.router.navigate([redirectPath || '/'])
    }

    /**
     * Checks `emailVerified` status every 2 seconds
     */
    protected initializeEmailVerifiedStatusRefresh() {
        combineLatest([
            this.authManager.authUserStream,
            timer(1000, 2000)
        ]).pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(async ([authUser, _]) => {
            /**
             * Refreshing auth user data
             */
            await authUser.reload()

            if (authUser.emailVerified) {
                return this.navigateToTheApplication()
            }
        })
    }
}
