import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-expand-arrow-icon',
    templateUrl: 'expand-panel-arrow.component.html',
    styleUrls: ['expand-panel-arrow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandArrowIconComponent {
    @Input() width: number|string = '20'
    @Input() height: number|string = '20'
    @Input() color: number|string = '#CECADA'
}
