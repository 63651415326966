import {
    Component,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    HostBinding,
} from '@angular/core'

import { CalendarEvent } from 'angular-calendar'

import { UiTimelineEvent } from '@undock/dashboard/contracts'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { EventDetailsViewModel } from '@undock/dashboard/view-models/event-details.view-model'
import * as m from 'moment/moment'


@Component({
    selector: 'app-dashboard-calendar-event-card',
    templateUrl: 'event-details-card.component.html',
    styleUrls: ['event-details-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventDetailsViewModel],
})
export class DashboardCalendarEventCardComponent {

    @Output() readonly onClose = new EventEmitter<void>()

    public timerText: string = ''

    @HostBinding('class.next')
    public isNext: boolean = false

    @HostBinding('class.current')
    public isCurrent: boolean = false

    public readonly state = this.eventDetailsViewModel.state

    public constructor(
        protected snackbarManager: SnackbarManager,
        protected changeDetectorRef: ChangeDetectorRef,
        protected eventDetailsViewModel: EventDetailsViewModel,
    ) {}

    public ngOnInit() {
        this.eventDetailsViewModel.state.onClose.subscribe(
            () => this.onClose.emit()
        )
    }

    public async initWithGridEvent(
        event: CalendarEvent<{ payload: UiTimelineEvent }>,
    ) {
        await this.eventDetailsViewModel.initViewModel(event)

        // Draft events have no state
        if (!event.meta.payload.isDraft) {
            // Copy event state
            this.isNext = event.meta.payload.state.isNext
            this.isCurrent = event.meta.payload.state.isCurrent
            await this.updateTimerText()
        }
    }


    public requestClose() {
        return this.eventDetailsViewModel.requestClose()
    }

    public enterEditMode() {
        return this.eventDetailsViewModel.enterEditMode()
    }

    public updateEventData(updates: Partial<EditMeetingData>) {
        return this.eventDetailsViewModel.updateEventData(updates)
    }

    public navigateToStandaloneEditPage() {
        return this.eventDetailsViewModel.openStandaloneEdit()
    }

    public onOptionsAction(action: string) {
        switch (action) {
            case 'delete':
            case 'rsvp::no':
                this.onClose.emit()
        }
    }

    /**
     * Schedules interval to refresh `timerText`
     */
    protected _startsInRefreshTimeout: number
    protected async updateTimerText() {
        const event = await this.eventDetailsViewModel.state.calendarEvent
        /**
         * Remove previous timeout if exist
         */
        if (this._startsInRefreshTimeout) {
            clearTimeout(this._startsInRefreshTimeout)
        }

        if (this.isNext || this.isCurrent) {
            this.timerText = m(event.start).fromNow()

            /**
             * Schedule new refresh timeout
             */
            // @ts-ignore
            this._startsInRefreshTimeout = setTimeout(
                () => this.updateTimerText(), 1000,
            )

            this.changeDetectorRef.detectChanges()
        } else {
            this.timerText = ''
        }
    }
}
