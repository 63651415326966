import { ApiRoute } from '@undock/api/services/api.route'
import {
    ProposalAggregate,
    UpdateProposalDTO,
    TimeProposalInterface,
    TimeProposalTimeSlot,
    SubmitProposalDTO,
} from '@undock/proposals/contracts/time-proposal.interface'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import { UserDataInterface } from '@undock/api/scopes/user/contracts/user.interface'
import { LegacyDock } from '@undock/dock/meet'


export class ProposalsRoute extends ApiRoute {

    public init() {
        this.route = 'meetings/proposal'
        this.baseURL = this.context.config.apiPlatformURL
    }

    /**
     * ---------------------------------------------------------------------------------
     *                           OWN PROPOSAL SECTION
     * ---------------------------------------------------------------------------------
     */

    public async listDrafts(): Promise<ProposalAggregate[]> {
        const data = await this.get<ProposalAggregate[]>()
                               .endpoint(`own/list-draft`)

        return data ?? []
    }

    public async listOutgoing(): Promise<ProposalAggregate[]> {
        const data = await this.get<ProposalAggregate[]>()
                               .endpoint(`own/list-outgoing`)

        return data ?? []
    }

    public async listIncoming(): Promise<ProposalAggregate[]> {
        const data = await this.get<ProposalAggregate[]>()
                               .endpoint(`own/list-incoming`)

        return data ?? []
    }

    public create(properties = {}): Promise<TimeProposalInterface> {
        return this.post<TimeProposalInterface>()
                   .withData({ ...properties })
                   .endpoint('own')
    }

    public confirm(proposalId: string, slot: TimeProposalTimeSlot): Promise<LegacyDock> {
        return this.post<LegacyDock>()
                   .withData({ slot })
                   .endpoint(`own/confirm/${proposalId}`)
    }

    public deleteOwn(proposalId: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(`own/${proposalId}`)
    }

    public submit(proposalId: string, properties: SubmitProposalDTO): Promise<TimeProposalInterface> {
        return this.post<TimeProposalInterface>()
                   .withData({ ...properties })
                   .endpoint(`own/submit/${proposalId}`)
    }

    public update(proposalId: string, properties: UpdateProposalDTO): Promise<TimeProposalInterface> {
        return this.patch<TimeProposalInterface>()
                   .withData({ ...properties })
                   .endpoint(`own/${proposalId}`)
    }

    public getOwnProposalById(id: string): Promise<TimeProposalInterface> {
        return this.get<TimeProposalInterface>()
                   .endpoint(`own/${id}`)
    }

    public getConfirmedEventForProposal(proposalId: string): Promise<LegacyDock> {
        return this.get<LegacyDock>()
                   .endpoint(`own/get-confirmed-event/${proposalId}`)
    }


    /**
     * ------------------------------------------------------------------------------------
     *                           PROPOSAL RESPONSE SECTION
     * ------------------------------------------------------------------------------------
     */

    public respond(proposalId: string, slotStatusMap: Record<string, RsvpStatus>): Promise<ProposalAggregate> {
        return this.post<ProposalAggregate>()
                   .withData({ slotStatusMap })
                   .endpoint(`respond/${proposalId}`)
    }

    public respondAsGuest(proposalId: string, data: {
        name?: string,
        email: string,
        secret?: string,
        slotStatusMap: Record<string, RsvpStatus>,
    }): Promise<ProposalAggregate> {
        return this.post<ProposalAggregate>()
                   .withData(data)
                   .endpoint(`respond/${proposalId}/guest`)
    }

    /**
     * Get proposal aggregate for response view
     */
    public async getResponseProposal(proposalId: string): Promise<ProposalAggregate> {
        const data = await this.get<ProposalAggregate>()
                               .endpoint(`respond/${proposalId}`)

        return data ?? null
    }

    /**
     * Get proposal aggregate for response view
     */
    public async getGuestResponseProposal(proposalId: string, email: string, secret: string): Promise<ProposalAggregate> {
        const data = await this.post<ProposalAggregate>()
                               .withData({ email, secret })
                               .endpoint(`respond/guest/get/${proposalId}`)

        return data ?? null
    }

    public getProposalPublicData(proposalId: string) {
        return this.get<Partial<ProposalAggregate>>()
                   .endpoint(`respond/public-data/${proposalId}`)
    }

    public isParticipantOfProposal(
        proposalId: string, overrideEmail?: string, guestAccessToken?: string,
    ): Promise<boolean> {
        return this.post<boolean>()
                   .withData({ overrideEmail, guestAccessToken })
                   .endpoint(`respond/is-participant/${proposalId}`)
    }

    public requestGuestAccess(proposalId: string, userData: UserDataInterface) {
        return this.post<any>()
                   .withData({ proposalId, userData })
                   .endpoint(`respond/request-guest-access`)
    }

    public requestUserAccess(proposalId: string) {
        return this.post<any>()
                   .withData({ proposalId })
                   .endpoint(`respond/request-user-access`)
    }
}
