import { ApiContext } from '../../contracts/api.context'

import { ApiRoute } from '@undock/api'
import { PersonalRoute } from '@undock/api/scopes/schedules/personal/personal.route'

export class SchedulesScope extends ApiRoute {

    public readonly personal: PersonalRoute

    public constructor(context: ApiContext) {
        super(context)
        this.personal = new PersonalRoute(context)
    }

    public init() {
        this.route = 'schedules'
    }

    public async ensureBuildInSchedulesCreatedForUser(userId: string) {
        return this.post<void>()
                   .withData({ userId })
                   .endpoint(`ensure-build-in-schedules-created`)
    }
}
