import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'

@Component({
    selector: 'app-on-off-select-button',
    templateUrl: 'on-off-select-button.component.html',
    styleUrls: ['on-off-select-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnOffSelectButtonComponent implements OnInit {

    @Input() alwaysOn: boolean = false

    @Input() label: string
    @Input() offLabel: string = 'Off'
    @Input() position: 'left' | 'right' | 'none' = 'left'

    @Input() offToggled: boolean = false

    @Input() height: string = '50px'
    @Input() width: string = '210px'
    @Input() fontSize: string = '2.3rem'
    @Input() fontWeight: string = 'bold'

    ///////////////
    // DROP DOWN //

    @Input() values: {
        value: any,
        label: string
    }[]
    @Input() currentValue: any

    @Output() currentValueChange = new EventEmitter<any>()
    @Output() turnOff = new EventEmitter<boolean>()

    public dropDownSelectedValue: string
    public dropDownValues: string
    public dropDownShowing: boolean = false

    constructor(
        public changeRef: ChangeDetectorRef,
        public el: ElementRef,
    ) { }

    ngOnInit() {

        this.dropDownShowing = false

        if (this.values && this.values.length > 0 && !this.currentValue) {

            this.currentValue = this.values[0].value
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes) {

            if (changes.currentValue) {

                if (this.values && this.values.length > 0) {

                    let index = this.values.findIndex(v => v.value === changes.currentValue.currentValue)

                    if (index !== -1) {
                        this.label = this.values[index].label
                    } else {
                        this.label = changes.currentValue.currentValue
                    }
                }
            }
        }
    }

    public toggleOff() {

        this.offToggled = true
        this.turnOff.emit(this.offToggled)
    }

    public showDropDown(event) {

        this.offToggled = false
        this.dropDownShowing = !this.dropDownShowing

        if (event) {
            event.stopPropagation()
        }
    }

    public selectValue(event, value: any) {

        this.currentValue = value
        this.currentValueChange.emit(value)

        if (event) {
            event.stopPropagation()
        }

        this.dropDownShowing = false
    }

    private scrollToValue(value: any) {

        if (value) {

            let element = this.el.nativeElement.querySelector('[data-value=\'' + value + '\']')

            if (element) {

                element.scrollIntoView()
            }
        }
    }

}
