import {
    Injectable,
} from '@angular/core'
import {
    Router,
    UrlTree,
    CanActivate,
} from '@angular/router'

import {
    AuthManager,
    AuthSession,
} from '@undock/auth'
import { LocalStorage } from '@undock/core'
import OnboardingSteps from '@undock/onboarding/install/data/steps'
import { CurrentUser } from '@undock/session'


@Injectable()
export class ForceOnboardingGuard implements CanActivate {

    public constructor(
        private router: Router,
        private storage: LocalStorage,
        private currentUser: CurrentUser,
        private authManager: AuthManager,
        private authSession: AuthSession,
    ) {}

    public async canActivate(): Promise<boolean | UrlTree> {
        if (await this.authManager.isRegularUserStream) {
            /**
             * THIS IS WHERE POST-LOGIN REDIRECT ACTUALLY HAPPENS
             */
            if (await this.authSession.isNewUserStream) {
                if (!this.isOnboardingComplete(await this.currentUser.id)) {
                    return this.router.createUrlTree(['install'])
                }
            } else {
                /** 
                 * Have to do this using the window object because the ActivatedRoute
                 * class is not mounted yet at this point in the lifecycle
                 */
                const redirectPath = this.router.parseUrl(`${window.location.pathname}${window.location.search}`).queryParamMap.get('redirectPath')
                if (
                    redirectPath 
                    && !redirectPath?.startsWith('/logout') 
                    && !redirectPath?.startsWith('logout') 
                    && !redirectPath?.startsWith('/login') 
                    && !redirectPath?.startsWith('login')
                ) {
                    return this.router.createUrlTree([redirectPath])
                }
                
                return this.router.createUrlTree(['/'])
            }
        }
        return true
    }

    protected isOnboardingComplete(userId: string) {
        const currentStep = this.storage.getItem('OnboardingCompletedStep' + userId)
        return currentStep && currentStep ===  OnboardingSteps.Complete
    }
}
