import { Injectable } from '@angular/core'
import { HasAttachments } from '@undock/core/contracts/model/has-attachments.interface'
import { PathResolveStrategy } from '@undock/core/contracts/path-resolve.strategy'

@Injectable()
export class DefaultPathResolveStrategy extends PathResolveStrategy {

    public async resolve(owner: HasAttachments, prefix: string): Promise<string> {
        return `${prefix}/${owner.id}`
    }
}
