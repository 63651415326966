<div class="availability-selector-header" [class.-unregistered-user]="isAnonymous$ | stream">
    <div class="meeting-settings">
        <div class="datepicker-box">
            <ng-container *ngIf="selectedAvailabilityDay">
                <mat-icon class="icon" [svgIcon]="'calendar'"
                          (click)="datePicker.show(); $event.stopPropagation();"
                ></mat-icon>

                <app-date-picker #datePicker
                                 class="__date-selector"
                                 [date]="selectedAvailabilityDay"
                                 [dateFormat]="'MMMM'"
                                 [position]="'bl'"
                                 [showInvalidDateLabels]="true"
                                 (onDateSelected)="onAvailabilityDaySelected.next($event)"
                ></app-date-picker>
            </ng-container>
        </div>

        <div class="duration-mode">
            <app-availability-duration-select class="settings-item -duration"
                                              [value]="meetingDuration"
                                              [options]="meetingDurationOptions"
                                              [schedule]="schedule"
                                              [paymentGroup]="paymentGroup"
                                              (onChange)="onMeetingDurationChanged.next($event)"
            ></app-availability-duration-select>

            <app-availability-meeting-mode-switch class="settings-item -event-type"
                                                  [value]="meetingMode"
                                                  [toggleMode]="true"
                                                  [allowedModes]="meetingModeOptions"
                                                  (onChange)="onMeetingModeChanged.next($event)"
            ></app-availability-meeting-mode-switch>
        </div>
    </div>
</div>

<app-time-availability-day-browser
    [multiMode]="true"
    [enableDatePicker]="true"

    [availability]="displayedAvailability"
    [isAvailabilityLoading]="isAvailabilityLoading"
    [availabilityRangeStart]="displayAvailabilityRangeStart"
    [selectedAvailabilityDayIndex]="selectedAvailabilityDayIndex"
    [availabilityDaysCountToDisplay]="availabilityDaysCountToDisplay"
    [selectedTimeZone]="selectedTimeZoneName"

    [themeColor]="themeColor"

    (onNextDaysClicked)="onGoToNextDaysClicked.emit()"
    (onPrevDaysClicked)="onGoToPrevDaysClicked.emit()"
    (onDaySelected)="onAvailabilityDaySelected.next($event.toDate())"
    (onDayIndexSelected)="onAvailabilityDayIndexSelected($event)"
></app-time-availability-day-browser>

<section class="availability-slot-selector" [class.-unregistered-user]="isAnonymous$ | stream">
    <main class="availability-slot-selector__slot-selector">
        <app-time-availability-multi-day-slot-selector
            [availabilitySet]="displayedAvailability"
            [suggestedAvailableSlot]="suggestedAvailableSlot"
            [meetingToReschedule]="alreadyBookedMeeting"
            [isAvailabilityLoading]="isAvailabilityLoading"
            [selectedTimezone]="selectedTimeZoneName"
            [availabilityDaysCountToDisplay]="availabilityDaysCountToDisplay"

            [themeColor]="themeColor"
            [accentColor]="accentColor"

            (onAvailableSlotSelected)="onAvailabilitySlotSelected.emit($event)"
        ></app-time-availability-multi-day-slot-selector>
    </main>

    <footer *ngIf="showExpandBtn">
        <button class="expand-button" (click)="onExpandBtnClicked.emit()">
            <app-icon-expand class="icon" [width]="20" [height]="20"></app-icon-expand>
            Expand calendar
        </button>
    </footer>
</section>
