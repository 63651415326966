import {
    Pipe,
    PipeTransform,
} from '@angular/core'


@Pipe({ name: 'splitOnCapital', pure: true })
export class SplitOnCapitalPipe implements PipeTransform {

    public transform(value: string): string {

        if (value === null || value === undefined) {
            return ''
        }

        return value.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
    }
}


