export class Validations {
    static readonly urlRE = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    static readonly emailRE = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    public static isNotEmptyString(value: string): boolean {
        return (typeof value === 'string') && value.replace(/^\s+/, '')
                                                   .replace(/\s+$/, '') !== ''
    }

    public static isValidUrl(value: string): boolean {
        return typeof value === 'string' && this.urlRE.test(value.trim())
    }

    public static isValidEmail(value: string): boolean {
        return typeof value === 'string' && this.emailRE.test(value.trim())
    }
}

