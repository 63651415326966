import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-users-icon',
    templateUrl: 'users-icon.component.html',
    styleUrls: ['users-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 32
    @Input() height: string | number  = 32
    @Input() color: string = '#807A8E'
}
