import { Component } from '@angular/core'
import { AsyncStream } from '@typeheim/fire-rx'
import { CurrentUser } from '@undock/session'

@Component({
    selector: 'app-not-found',
    templateUrl: 'not-found.component.html',
    styleUrls: ['not-found.component.scss'],
})
export class NotFoundComponent {

    public readonly isVisitorRegularUserStream: AsyncStream<boolean>

    constructor(
        private currentUser: CurrentUser,
    ) {
        this.isVisitorRegularUserStream = this.currentUser.isRegularUserStream
    }
}
