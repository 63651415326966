import { AbstractEvent } from '@undock/core'
import { StatusesListItemType } from '@undock/data-store/contracts/statuses/statuses-list-item-type'

/**
 * @API
 */
export class RefreshStatusesEvent extends AbstractEvent {
    public constructor(
        public readonly itemType: StatusesListItemType,
    ) {
        super()
    }
}
