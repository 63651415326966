import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core'

@Component({
    selector: 'app-meet-edit-title',
    templateUrl: 'edit-title.component.html',
    styleUrls: [
        '../../../../components/dock-title/dock-title.component.scss',
    ],
})
export class EditTitleComponent {

    @Input() value: string
    @Input() disabled: boolean
    @Input() placeholder: string = ''

    @Output() onTitleChanged = new EventEmitter<string>()

    @ViewChild('titleInput') titleInput: ElementRef<HTMLTextAreaElement>

    public insertText(text: string) {
        const el = this.titleInput.nativeElement

        /**
         * Inserting value between the selection
         */
        el.value = el.value.slice(0, el.selectionStart)
            + text + el.value.slice(el.selectionEnd, el.value.length)

        this.onTitleChanged.next(el.value)
    }
}
