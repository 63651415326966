import { default as m } from 'moment'

/**
 * When Firestore model objects are compared, it results in an infinite loop/stack overflow,
 * this prevents that
 */
const objectKeysToIgnore = [
    'toJSON',
    '__proto__',
    '__ormOnFire',
    '___docReference',
    'entityBuilder',
    'entityConstructor',
    'owner',
]

/**
 * Function proceed deep comparison of two values using recursion algo
 *
 * This algo don't recognize changes in the properties of object `b`
 *                      if object `a` doesn't have the same property
 *
 * Return true is objects are same
 *
 * @return boolean
 */
export function compareDeeply<T1, T2 extends any>(a: T1, b: T2, key?: keyof T1 | keyof T2) {

    /**
     * Starting a comparison from the single property if key is set
     */
    if (key) {
        a = a ? a[key as any] ?? null : null
        b = b ? b[key as any] ?? null : null
    }

    if (typeof a !== typeof b) {
        /**
         * For this case we shouldn't try to make further comparison
         */
        return false
    }

    /**
     * typeof null === 'object' .. weird thing
     */
    if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
        // @ts-ignore // If values have a simple type we can proceed with direct comparison
        return a === b
    }

    if (a instanceof Date && b instanceof Date) {
        /**
         * Compare Date objects as UNIX timestamps (integers)
         */
        return a.valueOf() === b.valueOf()
    }

    if (m.isMoment(a) && m.isMoment(b)) {
        /**
         * Compare moment values with build-in features
         */
        return a.isSame(b)
    }

    if (Array.isArray(a) && Array.isArray(b)) {
        let as = a.slice(), bs = b.slice()
        if (key) {
            function compare(a, b) {
                if (a && b && a[key] < b[key]) {
                    return -1
                }
                if (a && b && a[key] > b[key]) {
                    return 1
                }
                return 0
            }

            as = as.sort(compare)
            bs = bs.sort(compare)
        }
        return as.length === bs.length && as.every((value, index) => compareDeeply(value, bs[index], key))
    }


    if (a['toJSON'] instanceof Function) {
        // @ts-ignore
        a = a.toJSON()
    }

    if (b['toJSON'] instanceof Function) {
        // @ts-ignore
        b = b.toJSON()
    }

    const objAKeys = Object.keys(a)
        , objBKeys = Object.keys(b)

    /**
     * No reason to compare objects if the keys are different
     */
    if (objAKeys.length !== objBKeys.length) {
        return false
    }

    for (let key of objAKeys) {
        if (a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
            /**
             * Both objects should have the same properties
             */
            return false
        }

        if (!objectKeysToIgnore.includes(key)) {
            if (!compareDeeply(a[key], b[key])) {
                return false
            }
        }
    }

    return true
}

/**
 * Creates a callback function used by distinctUntilChanged comparison
 *
 * @param {String} key
 * @return Function
 */
export function compareDeeplyBy<T1, T2 extends any>(key: keyof T1 | keyof T2) {
    return (a: T1, b: T2) => compareDeeply(<any>a, <any>b, <any>key)
}
