import {
    Component,
    OnInit,
} from '@angular/core'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-not-found-screen',
    template: `<app-not-found></app-not-found>`,
})
export class NotFoundScreen implements OnInit {

    public constructor(
        private title: Title,
    ) {}

    public ngOnInit(): void {
        this.title.setTitle(`Undock | Not Found`)
    }
}
