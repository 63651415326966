import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-screen-share-on',
    templateUrl: 'screen-share-on.component.html',
    styleUrls: ['screen-share-on.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareOnIconComponent extends IconTemplateComponent {}
