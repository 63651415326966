import { ApiRoute } from '@undock/api/services/api.route'

export class OnboardingRoute extends ApiRoute {

    public init() {
        this.route = 'user/onboarding'
    }

    public sendExtensionInvitationEmail() {
        return this.get<number>()
                   .endpoint(`extension/invite`)
    }

}
