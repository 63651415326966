<ng-container *ngFor="let group of this.groupsStream | stream; trackBy: trackByGroup">
    <div class="notifications-group {{ group.groupName }}">
        <ng-container *ngFor="let notification of group.notificationStream | stream">
            <ng-container [ngSwitch]="notification.type">

                <!--
                 /**
                  * Chat::Message Notification
                  */
                 -->
                <ng-container *ngSwitchCase="DockNotificationType.ChatMessage">
                    <dock-notifications-chat-message
                            [notification]="notification"
                            [isConfMode]="state.state.isConfModeStream | stream | boolean"
                            (onDestroyed)="destroyNotification(notification)"
                    ></dock-notifications-chat-message>
                </ng-container>


                <!--
                  /**
                   * Conf::RequestToSpeak Notification
                   */
                 -->
                <ng-container *ngSwitchCase="DockNotificationType.AttendeeDidJoin">
                    <dock-notifications-attendee-did-join
                            [notification]="notification"
                            [isConfMode]="state.state.isConfModeStream | stream | boolean"
                            (onDestroyed)="destroyNotification(notification)"
                    ></dock-notifications-attendee-did-join>
                </ng-container>


                <!--
                 /**
                  * Conf::RequestToSpeak Notification
                  */
                -->
                <ng-container *ngSwitchCase="DockNotificationType.RequestedToJoin">
                    <dock-notifications-requested-to-join
                            [notification]="notification"
                            [isConfMode]="state.state.isConfModeStream | stream | boolean"
                            (onDestroyed)="destroyNotification(notification)"
                    ></dock-notifications-requested-to-join>
                </ng-container>


                <!--
                 /**
                  * Conf::RequestToSpeak Notification
                  */
                -->
                <ng-container *ngSwitchCase="DockNotificationType.RequestedToSpeak">
                    <dock-notifications-requested-to-speak
                            [notification]="notification"
                            [isConfMode]="state.state.isConfModeStream | stream | boolean"
                            (onDestroyed)="destroyNotification(notification)"
                    ></dock-notifications-requested-to-speak>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
