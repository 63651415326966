import {
    Router,
    UrlTree,
    CanActivate,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router'
import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { NavigationController } from '@web/app/services/navigation-controller.service'

@Injectable()
export class RoutingGuard implements CanActivate {
    public constructor(
        private router: Router,
        private location: Location,
        private controller: NavigationController,
    ) {}

    public async canActivate(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
        const targetRoute = await this.controller
                                      .getTargetRouteForPage(
                                          next.url.map(segment => segment.path)
                                      )

        // Use path from navigation controller if not empty
        if (targetRoute.path.length > 0) {
            await this.router.navigate(targetRoute.path, {
                queryParams: next.queryParams
            })

            // Replace URL state with an initial value
            if (targetRoute.keepUrl) {
                this.location.replaceState(state.url)
            }

            return true
        }

        /**
         * Will probably redirect to the 404 page
         */
        return this.router.createUrlTree(['404'])
    }
}
