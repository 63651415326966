/**
 * Converts given Base64 string to Blob object
 */
export function base64ToBlob(input: string): Blob {
    const contentType = input.slice(5, input.indexOf(';'))
    const base64Payload = input.slice(input.indexOf(',') + 1)

    const batchSize = 512
    const byteBatches = new Array<Uint8Array>()

    const base64BinaryData = atob(base64Payload)
    const base64BinaryDataLength = base64BinaryData.length

    for (let offset = 0; offset < base64BinaryDataLength; offset += batchSize) {
        let bytes = new Array<number>()
        for (let index = offset; index < offset + batchSize; index++) {
            bytes[index - offset] = base64BinaryData.charCodeAt(index)
        }
        byteBatches.push(new Uint8Array(bytes))
    }

    return new Blob(byteBatches, { type: contentType })
}
