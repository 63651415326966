export enum DockSharedAccessMode {
    /**
     * Anyone with the link can join
     *
     * @default
     */
    Link = 'Link',

    /**
     * Only connections could join
     *
     * @deprecated Is not implemented yet
     */
    Connections = 'Connections',

    /**
     * Only participants could join
     *
     * @deprecated Is not implemented yet
     */
    Participants = 'Participants',
}