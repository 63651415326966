<ng-container [ngSwitch]="isInitializedStream | stream">
    <ng-container *ngSwitchCase="true">
        <app-default-header></app-default-header>

        <div class="container email-is-not-verified-container">
            <app-auth-empty-state-icon></app-auth-empty-state-icon>

            <div class="title">Login ID Confirmed</div>

            <p>You can now login to your account with {{targetEmailStream | stream}}.</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <app-loading-overlay></app-loading-overlay>
    </ng-container>
</ng-container>
