import { ApiRoute } from '../../../services/api.route'

export class GuestAuthRoute extends ApiRoute {
    public init() {
        this.route = 'auth/guest'
    }

    public validateSecret(provider: string, secret: string) {
        return this.get<boolean>()
                   .endpoint(`${provider}/validate-secret/${secret}`)
    }

    public requestGuestAccess(provider: string, params: Record<string, any>) {
        return this.post<string>()
                   .withData(params)
                   .endpoint(`${provider}/request-guest-access`)
    }
}
