import { ApiRoute } from '@undock/api'


export class SessionRoute extends ApiRoute {
    public init() {
        this.route = 'auth/session'
    }

    public generateTokenForSession(idToken: string): Promise<CustomTokenResponse> {
        return this.get<CustomTokenResponse>().withHeaders({
            authorization: `Bearer ${idToken}`,
            'Content-type': 'application/json',
        }).endpoint(`generate-token-for-session`)
    }
}

export interface CustomTokenResponse {
    token: string
}
