import { ApiRoute } from '@undock/api'
import { IDelegate } from '@undock/api/scopes/delegate/contracts'

export class OwnGrantorsRoute extends ApiRoute {

    public init() {
        this.route = 'delegate/own/grantors'
    }

    public list() {
        return this.get<IDelegate[]>()
                   .endpoint(`list`)
    }

    public generateToken(delegateId: string) {
        return this.post<string>()
                   .withData({ delegateId })
                   .endpoint(`generate-token`)
    }
}
