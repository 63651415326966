import { Hotkey } from '@undock/hotkeys/contracts/keyboard'

export abstract class AppCommand {
    /**
     * Handle command execution
     */
    public abstract handle(event?: any)

    /**
     * Title to display in command menu
     */
    public abstract getTitle()

    /**
     * Key binding of command. Return false if command does not support bindings
     */
    public getHotkey(): Hotkey {
        return null
    }

    /**
     * Command icon to display in command menu
     */
    public getIcon() {
        return 'default'
    }

    /**
     * Identifies whether command can be executed under specified route.
     * By default all routes are allowed
     */
    public isAvailableOnRoute(route: string) {
        return true
    }
}

export abstract class GlobalAppCommand extends AppCommand {
    // Special type of commands to be available globally
}

export abstract class DomainAppCommand extends AppCommand {
    // Special type of commands to be available only inside of domain
}
