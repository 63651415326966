import {
    ChangeDetectionStrategy,
    Component,
    Inject,
} from '@angular/core'
import { Router } from '@angular/router'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations'
import { CurrentUser } from '@undock/session'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'
import { ReactiveStream } from '@typeheim/fire-rx'


@Component({
    selector: 'app-navigation-panel',
    templateUrl: 'navigation-panel.component.html',
    styleUrls: [`navigation-panel.component.scss`],
    animations: [
        trigger('sidebarView',[
            state('hide', style({
                //position: 'absolute',
                width: '0',
                opacity: '0'
            })),
            state('show', style({
                //position: 'relative',
                width: '105px',
                opacity: '1'
            })),
            transition('hide <=> show', [
                animate('0.1s linear')
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationPanelComponent {

    public constructor(
        public readonly state: SidebarState,
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,

        protected router: Router,
        protected currentUser: CurrentUser,
    ) {}

    async toggleSidebar() {
        this.state.toggleViewMode()
    }

    registerNavigationChange(location: string) {
        this.state.registerNavigationChange(location)
    }
}

