import { Router } from '@angular/router'
import {
    Component,
    Inject,
} from '@angular/core'
import { Config } from '@undock/core'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'
import { ReactiveStream } from '@typeheim/fire-rx'

@Component({
    selector: 'app-new-meeting-button',
    templateUrl: 'new-meeting-button.component.html',
    styleUrls: ['new-meeting-button.component.scss'],
})
export class NewMeetingButtonComponent {
    public readonly isProduction: boolean

    public constructor(
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,
        protected config: Config,
        protected router: Router,
    ) {
        this.isProduction = config.isProduction
    }
}
