import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

@Component({
    selector: 'app-icon-twitter',
    templateUrl: 'twitter-icon.component.html',
    styleUrls: ['twitter-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwitterIconComponent {}
