import { ApiRoute } from '@undock/api'
import {
    ProfileEmbedCreate,
    ProfileEmbedEntity,
} from '@undock/api/scopes/integrations/contracts/embed'

export class EmbedProfileRoute extends ApiRoute {
    public init() {
        this.route = 'integrations/embed/profile'
    }

    public create(profileEmbedCreate: ProfileEmbedCreate): Promise<ProfileEmbedEntity> {
        return this.post<ProfileEmbedEntity>()
                   .withData(profileEmbedCreate)
                   .endpoint('')
    }

    public getById(id: string): Promise<ProfileEmbedEntity> {
        return this.get<ProfileEmbedEntity>().endpoint(id)
    }

    public updateById(id: string, profileEmbedCreate: ProfileEmbedCreate): Promise<ProfileEmbedEntity> {
        return this.put<ProfileEmbedEntity>()
                   .withData(profileEmbedCreate)
                   .endpoint(id)
    }

    public findWidgetOrCreate(defaultParameters: ProfileEmbedCreate): Promise<ProfileEmbedEntity> {
        return this.post<ProfileEmbedEntity>().withData(defaultParameters).endpoint('findOrCreate')
    }

    public deleteById(id: string): Promise<{ id: string }> {
        return this.delete<{ id: string }>().endpoint(id)
    }
}
