import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Inject,
} from '@angular/core'

import {
    combineLatest,
    Observable,
    timer,
} from 'rxjs'
import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    CompleteOnDestroy,
    ValueSubject,
    ReactiveStream,
} from '@typeheim/fire-rx'

import { Memoize } from '@undock/core'
import {
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { EditMeetingViewModel } from '@undock/dock/meet/ui/pages/edit-meeting/view-models/edit-meeting.view-model'

import { default as m } from 'moment'
import { TimezoneData } from '@undock/time/availability'
import { RRule } from 'rrule'
import { CurrentUser } from '@undock/session'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'

/**
 * @deprecated
 */
@Component({
    selector: 'app-meet-edit-date',
    templateUrl: 'edit-date.component.html',
    styleUrls: ['edit-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMeetingDateComponent {
    public readonly state: EditMeetingViewModel
    public readonly timeSlotSelectorStep: number = 15

    protected readonly fullDate = 'dddd, MMMM D'
    protected readonly shortDate = 'ddd, MMM D'

    public readonly isOwnerModeStream = this.dock.isOwnerModeStream

    public readonly recurrenceOptions  = [[RRule.DAILY, 'Daily'], [RRule.WEEKLY, 'Weekly'], [RRule.MONTHLY, 'Monthly'], [RRule.YEARLY, 'Annually']]

    @CompleteOnDestroy()
    public readonly dateFormat = new ValueSubject<String>(this.fullDate)

    @CompleteOnDestroy()
    protected isAvailabilitySelectorHiddenSubject = new ValueSubject<boolean>(false)

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,
        protected dock: DockFacade,
        protected editMeeting: EditMeetingViewModel,
        protected currentUser: CurrentUser,
    ) {
        /**
         * @TODO: This is a first step of migration to state.
         *            Next step should split model and state.
         */
        this.state = editMeeting
        this.resetDisplayDaysCount()
    }

    public handleRecurrenceOptionChange(option) {
        // first element of first element is a value selected by "app-tags-selector"
        this.state.changeRecurringFrequency(option[0][0])
    }

    @Memoize()
    public get isPastDateUsedStream(): Observable<boolean> {
        return this.state.selectedMeetingSlotStream.pipe(
            map(
                slot => slot.start.isSameOrBefore(new Date()),
            ),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get customTimeRangeStartStream(): Observable<number> {
        return combineLatest([
            this.state.selectedMeetingSlotStream,
            timer(0, 5000),
        ]).pipe(
            map(([slot]) => {
                if (slot.start.isSame(new Date(), 'day')) {
                    /**
                     * Prevents selecting past time in the dates range
                     */
                    return m().get('hours') + 1
                }

                return 0
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get selectedAvailabilityDayIndexStream(): Observable<number> {
        return combineLatest([
            this.state.displayAvailabilityStream,
            this.state.selectedAvailabilityDayStream,
        ]).pipe(
            map(([availability, selectedDay]) => {
                for (let set of availability) {
                    if (set.day.isSame(selectedDay, 'day')) {
                        return availability.indexOf(set)
                    }
                }

                return -1
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isAvailabilitySelectorHiddenStream(): Observable<boolean> {
        return combineLatest([
            this.dock.isOwnerModeStream,
            this.isAvailabilitySelectorHiddenSubject,
        ]).pipe(
            map(([isOwner, isHidden]) => {
                if (!isOwner) {
                    /**
                     * Always hidden for non-owners
                     */
                    return true
                }

                return isHidden
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @HostListener('window:resize')
    public resetDisplayDaysCount() {
        const screenWidth = window?.innerWidth
        const displayDaysNumber = screenWidth > 1000 ? 3 : (screenWidth > 760 ? 2 : 1)
        this.dateFormat.next(screenWidth > 625 ? this.fullDate : this.shortDate)
        this.state.selectAvailabilityDaysCountToDisplay(displayDaysNumber)
    }

    public cancelReschedule() {
        return this.editMeeting.restoreInitialMeetingSlot()
    }

    public onGoToNextDaysClicked() {
        return this.editMeeting.onGoToNextDaysClicked()
    }

    public onGoToPrevDaysClicked() {
        return this.editMeeting.onGoToPrevDaysClicked()
    }

    public async onAvailabilityDayIndexSelected(index: number) {
        if (index >= 0) {
            let availability = await this.state.displayAvailabilityStream

            await this.editMeeting.selectAvailabilityDay(availability[index]?.day, true)
        }
    }

    public async onCustomMeetingDateSelected(value: Date) {
        const slot = await this.state.selectedAvailableSlotStream

        let newSlotDate = m(slot.timeStamp)
        newSlotDate.set('year', value.getFullYear())
        newSlotDate.set('month', value.getMonth())
        newSlotDate.set('date', value.getDate())

        if (newSlotDate.isSame(new Date(), 'day')) {
            /**
             * Force change hours if selected current day
             */
            newSlotDate.set('hours', m().get('hours') + 1)
        }

        return this.selectCustomAvailableSlot({
            ...slot,

            hour: newSlotDate.get('hour'),
            minute: newSlotDate.get('minute'),
            label: newSlotDate.format('h:mm A'),
            timeStamp: newSlotDate.toISOString(),
        } as AvailabilitySlot)
    }

    public async onCustomMeetingStartTimeSelected(value: AvailabilitySlot) {
        let [slot, duration] = await Promise.all([
            this.state.selectedAvailableSlotStream,
            this.state.selectedMeetingDurationStream,
        ])

        let newSlotDate = m(slot.timeStamp)
        newSlotDate.set('hour', value.hour)
        newSlotDate.set('minute', value.minute)

        let durationDifference = m(slot.timeStamp).diff(newSlotDate, 'minutes')
        if (duration + durationDifference > 1) {
            /**
             * We should set custom duration after start time was changed to keep end time
             *
             * `duration + durationDifference` will be less 0 if selected time is after end time
             */
            await this.editMeeting.selectCustomDuration(duration + durationDifference)
        } else {
            /**
             * If selected `start` date is after `end` date - will select default duration
             */
            await this.editMeeting.selectCustomDuration(this.timeSlotSelectorStep)
        }

        return this.selectCustomAvailableSlot({
            ...slot,
            hour: newSlotDate.get('hour'),
            minute: newSlotDate.get('minute'),
            label: newSlotDate.format('h:mm A'),
            timeStamp: newSlotDate.toISOString(),
        } as AvailabilitySlot)
    }

    public async onCustomMeetingEndTimeSelected(value: AvailabilitySlot) {
        let slot = await this.state.selectedAvailableSlotStream

        let newEndTime = m(slot.timeStamp)
        newEndTime.set('hour', value.hour)
        newEndTime.set('minute', value.minute)

        let newDuration = newEndTime.diff(slot.timeStamp, 'minutes')
        if (newDuration <= 0) {
            /**
             * Duration could not be negative
             */
            newDuration = this.timeSlotSelectorStep
        }

        return this.editMeeting.selectCustomDuration(newDuration)
    }


    public openAvailabilitySelector() {
        this.isAvailabilitySelectorHiddenSubject.next(false)
    }

    public closeAvailabilitySelector() {
        this.isAvailabilitySelectorHiddenSubject.next(true)
    }


    public selectAvailableSlot(slot: AvailabilitySlot) {
        if (m(slot.timeStamp).isAfter(new Date)) {
            /**
             * Past available slots cannot be selected
             */
            return this.editMeeting.selectAvailableSlot(slot)
        }
    }

    public selectCustomAvailableSlot(slot: AvailabilitySlot) {
        return this.editMeeting.selectAvailableSlot(slot, true)
    }

    public async switchToThePresentTimeMode() {
        await this.editMeeting.selectAvailabilityDay(m())
    }

    public onTimeZoneSelected(timeZone: TimezoneData) {
        this.editMeeting.selectTimeZone(timeZone)
    }
}
