import { Injectable } from '@angular/core'

import {
    PublicProfileData,
    User,
} from '@undock/user'
import { Api } from '@undock/api'
import { DateRange } from '@undock/time/availability'
import { AppEventsDispatcher } from '@undock/core'
import {
    MeetingMode,
    MeetingModeLegacy,
    Schedule,
    ScheduleMode,
} from '@undock/dock/meet'
import { LegacyDock } from '@undock/dock/meet/contracts/legacy-dock'
import { DockCreatedEvent } from '@undock/dock/meet/events/dock-created.event'
import { UserConferenceLinkType } from '@undock/api/scopes/user/contracts/user.interface'
import { generateMeetingTitle } from '@undock/dock/meet/utils/meeting-titles-generator'
import { FormResult } from '@undock/api/scopes/meet/contracts/form-result'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'


export interface CreateMeetingAdditionalOptions {
    /**
     * Is used only for InPerson meeting-mode
     */
    location?: string

    inPersonLocation?: string

    inPersonLocationUrl?: string

    /**
     * Applied schedule
     */
    schedule?: Schedule

    /**
     * This data will be added to the calendar event
     */
    contactInfo?: string

    /**
     * This code is used for booking by ChromeExt link
     */
    bookingCode?: string

    /**
     * Defines if meeting has been booked from the profile page
     */
    isProfileRequest?: boolean

    /**
     * Defines if meeting has been booked from a partner embed profile page
     */
    isPartnerModeRequest?: boolean

    /**
     * TODO: Rework when more settings are used
     * Custom options for partner mode meetings
     */
    partnerLogoUrl?: string

    /**
     * This string will be used for shared access link
     */
    sharedAccessSecret?: string

    autoAcceptRecipient?: boolean

    conferenceLinkType?: UserConferenceLinkType

    customConferenceLink?: string

    /**
     * A synced calendar ID which should receive the meeting
     */
    syncMeetingWithExternalCalendarId?: string
}


@Injectable()
export class MeetingsManager {

    public constructor(
        protected api: Api,
        protected appEventsDispatcher: AppEventsDispatcher,
    ) {}

    public async createMeeting(
        meetingMode: MeetingModeLegacy,
        requesterName: string,
        requesterEmail: string,
        recipientEmail: string,
        requesterTimeZone: string,
        meetingTitle: string,
        meetingNotes: string,
        additionalUsers: PublicProfileData[],
        requesterIsRegularUser: boolean,
        datesRange: DateRange,
        additionalOptions: CreateMeetingAdditionalOptions = {},
        formResult?: FormResult[]
    ): Promise<LegacyDock> {

        let dock = await this.api.meet.dock.create({
            requester: {
                email: requesterEmail.trim(),
                fullName: requesterName.trim(),
                timeZone: requesterTimeZone,
                /**
                 * @TODO: Do we need fill this parameter?
                 */
                isUndockUser: requesterIsRegularUser,
            },

            recipientUserEmail: recipientEmail.trim(),

            autoAcceptRecipient: additionalOptions.autoAcceptRecipient ?? false,

            additionalUserEmails: additionalUsers.reduce(
                (emails, user) => [...emails, user.email], [],
            ),

            meetingOptions: {
                mode: meetingMode,
                note: meetingNotes.trim(),
                title: meetingTitle.trim(),

                endDate: datesRange.end,
                startDate: datesRange.start,

                ...additionalOptions,
            },

            sendMeetingNotification: true,
            scheduleMeetingReminder: true,
            syncWithExternalCalendars: true,
            formResult
        })

        let recipient: User
        try {
            recipient = await this.api.user.profile.getByEmail(recipientEmail)
        } catch (error) {}

        /**
         * @TODO: Handle cases when the recipient isn't a regular user
         */
        await this.appEventsDispatcher.dispatch(new DockCreatedEvent(dock, recipient))

        return dock
    }


    public async createInstantMeeting(
        requesterUser: User,
        recipientUser?: User,
        meetingTitle?: string,
        meetingNotes?: string,
    ): Promise<LegacyDock> {
        return await this.api.meet.dock.createInstant({
            requesterUserId: requesterUser._id,
            recipientUserId: recipientUser?._id ?? null,

            meetingOptions: {
                meetingCode: null,

                note: meetingNotes ?? '',
                title: meetingTitle ?? generateMeetingTitle([{
                    displayName: requesterUser.displayName, email: requesterUser.email,
                } as PublicProfileData]),
            },
        })
    }


    public async createMeetingFromDraft(
        data: EditMeetingData,
        options?: {
            scheduleMode?: ScheduleMode,
        }
    ): Promise<EditMeetingData> {
        if (data.isDraft) {
            await this.api.meet.meetings
                      .updateDraftMeeting(data._id, data)
        }

        /**
         * Sending broadcast mode scheduling analytics tracking
         */
        if (data.mode === MeetingMode.Broadcast) {
            if (options?.scheduleMode === ScheduleMode.Schedule) {
                this.appEventsDispatcher.dispatch(
                    new TrackUserAnalyticsEvent(UserAnalyticsAction.BroadcastScheduled)
                ).catch(error => {
                    console.warn(`Cannot dispatch analytics BroadcastScheduled`, error)
                })
            }
        }

        return this.api.meet.meetings.createMeetingFromDraft({ draftMeetingId: data._id })
    }

    public async updateMeeting(dockKey: string, data: EditMeetingData) {
        return this.api.meet.meetings.updateMeeting(data.dockKey, data)
    }
}
