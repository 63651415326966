import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
} from '@angular/core'
import {
    ActivatedRoute,
} from '@angular/router'
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { Title } from '@angular/platform-browser'

import {
    DestroyEvent,
    EmitOnDestroy,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { takeUntil } from 'rxjs/operators'

import { Memoize } from '@undock/core'
import { AuthManager } from '@undock/auth/services/auth.manager'
import { FirebaseApp } from '@undock/session/contracts/firebase-app.token'


@Component({
    selector: 'app-login-page',
    templateUrl: 'login.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        'login.page.scss',
        '../shared-styles/login-signup.scss',
    ],
})
export class LoginPage {

    @CompleteOnDestroy()
    public readonly ssoAuthErrorStream = new ValueSubject<string>('')

    @CompleteOnDestroy()
    public readonly passwordAuthErrorStream = new ValueSubject<string>('')

    @CompleteOnDestroy()
    public readonly isPasswordHiddenStream = new ValueSubject<boolean>(true)

    @CompleteOnDestroy()
    public readonly servicePopupOpenedStream = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly isAuthFormSubmittedStream = new ValueSubject<boolean>(false)

    private isLoginProcessing = false

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private title: Title,
        private authManager: AuthManager,
        private activatedRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        @Inject(FirebaseApp) private firebaseApp,
    ) {
        this.title.setTitle(`Login | Undock`)
    }

    public async ngOnInit() {
        try {
            await this.authManager.getRedirectResult()
        } catch (error) {
            this.showAuthError(error)
        }

        /**
         * Will redirect if user logged in in the different tab
         */
        this.authManager.isRegularUserStream.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(state => state ? this.redirectAfterSuccessfulLogIn() : null)
    }

    @Memoize()
    public get loginForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            'email': new UntypedFormControl('', [
                Validators.email,
                Validators.required,
            ]),
            'password': new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(6),
            ]),
        })
    }

    public async loginWithGoogle() {
        if (!this.isLoginProcessing) {
            this.isLoginProcessing = true
            this.ssoAuthErrorStream.next('')
            this.passwordAuthErrorStream.next('')
            try {
                await this.authManager.signInWithGoogle()
            } catch (error) {
                this.showAuthError(error)
                this.changeDetector.detectChanges()
            } finally {
                this.isLoginProcessing = false
            }
        }
    }

    public async loginWithMicrosoft() {
        if (!this.isLoginProcessing) {
            this.isLoginProcessing = true
            this.ssoAuthErrorStream.next('')
            this.passwordAuthErrorStream.next('')
            try {
                await this.authManager.signInWithMicrosoft()
            } catch (error) {
                this.showAuthError(error)
                this.changeDetector.detectChanges()
            } finally {
                this.isLoginProcessing = false
            }
        }
    }

    public async loginWithEmailPassword() {
        if (!this.isLoginProcessing) {
            this.ssoAuthErrorStream.next('')
            this.passwordAuthErrorStream.next('')
            this.isAuthFormSubmittedStream.next(true)

            if (this.loginForm.invalid) {
                return this.loginForm.markAllAsTouched()
            }

            try {
                this.isLoginProcessing = true
                await this.authManager.signInWithEmailAndPassword(
                    this.formControl('email').value,
                    this.formControl('password').value,
                )
                await this.redirectAfterSuccessfulLogIn()
            } catch (error) {
                const authValidationErrorCodes = [
                    'auth/invalid-email',
                    'auth/user-disabled',
                    'auth/user-not-found',
                    'auth/wrong-password',
                ]
                let errorMessage = 'Something went wrong, please try again!'

                if (authValidationErrorCodes.includes(error?.code)) {
                    errorMessage = 'User does not exist or credentials are invalid!'
                }
                this.passwordAuthErrorStream.next(errorMessage)
                this.changeDetector.detectChanges()
            } finally {
                this.isLoginProcessing = false
            }
        }
    }

    protected showAuthError(error) {
        let errorMessage: string
        switch (error.code) {
            case 'auth/popup-blocked':
                errorMessage = 'Sign in popup is blocked!'
                break

            case 'auth/popup-closed-by-user':
            case 'auth/cancelled-popup-request':
                errorMessage = 'Sign in popup closed by user!'
                break

            case 'auth/web-storage-unsupported':
                errorMessage = 'Failed to finish authorization. This browser is not supported or cookies are disabled.'
                break

            case 'auth/account-exists-with-different-credential':
                errorMessage = 'Account already exist with the same email but different credentials. Please link accounts in settings.'
                break

            default:
                errorMessage = 'Something went wrong, please try again!'
        }
        this.ssoAuthErrorStream.next(errorMessage)
    }

    public formControl(formControlName: string): AbstractControl {
        return this.loginForm.get(formControlName) ?? null
    }

    public isFormControlInvalid(formControlName: string): boolean {
        const control = this.formControl(formControlName)

        return control.invalid
    }

    public isFormControlHasError(formControlName: string, errorName: string): boolean {
        const control = this.formControl(formControlName)

        return control.errors && control.errors[errorName]
    }

    public async loginWithCustomToken(token: string): Promise<void> {
        await this.firebaseApp.auth().signInWithCustomToken(
            token.replace(/[\n\r\s]/g, '')
        )
    }

    protected async redirectAfterSuccessfulLogIn() {
        const redirectPath = this.activatedRoute.snapshot.queryParamMap.get('redirectPath')
        if (!redirectPath) {
            window.location.href = window.location.origin + '/timeline'
        } else {
            window.location.href = window.location.origin + this.filterRedirectUrl(redirectPath)
        }
    }

    private filterRedirectUrl(redirectUrl: string) {
        // there should not be redirects to logout page and login page after log in
        if (redirectUrl && !redirectUrl?.startsWith('/logout') && !redirectUrl?.startsWith('logout') && !redirectUrl?.startsWith('/login') && !redirectUrl?.startsWith('login')) {
            return redirectUrl
        }

        return '/timeline'
    }
}
