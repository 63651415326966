import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core'
import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations'

import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts/availability'
import { AvailabilitySet } from '@undock/api/scopes/profile/contracts/availability'
import {
    ReactiveStream,
    CompleteOnDestroy,
    ValueSubject,
    EmitOnDestroy,
    DestroyEvent,
} from '@typeheim/fire-rx'
import { Memoize } from '@undock/core'
import { LegacyDock } from '@undock/dock/meet'
import { takeUntil } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router'
import {
    findBestSlotOnDay,
} from '@undock/core/utils/find-best-slot-on-day'
import { TimelineEvent } from '@undock/api/scopes/time/contracts/timeline-event'


@Component({
    selector: 'app-time-availability-multi-day-slot-selector',
    templateUrl: 'availability-multi-day-slot-selector.component.html',
    styleUrls: ['availability-multi-day-slot-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('insertSlotTrigger', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(10px)' }),
                animate('300ms', style({ opacity: 1, transform: 'translateX(0)' })),
            ]),
        ]),
    ],
})
export class AvailabilityMultiDaySlotSelectorComponent implements OnInit {

    @CompleteOnDestroy()
    private targetedSlotSubject = new ValueSubject<AvailabilitySlot>(null)

    @Output() readonly onAvailableSlotSelected = new EventEmitter<AvailabilitySlot>()

    @Input() availabilitySet: AvailabilitySet[]
    @Input() availabilityDaysCountToDisplay: number = 3
    @Input() suggestedAvailableSlot: AvailabilitySlot
    @Input() isAvailabilityLoading: boolean
    @Input() selectedAvailabilitySlot: AvailabilitySlot
    @Input() meetingToReschedule: TimelineEvent = null
    @Input() selectedTimezone: string

    @Input() themeColor: string = '#2B2733'
    @Input() accentColor: string = '#DFC051'
    @Input() accentTextColor: string = null

    @Memoize()
    public get targetedSlotStream(): ReactiveStream<AvailabilitySlot> {
        return this.targetedSlotSubject.asStream()
    }

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    @Input() embedMode = false

    textColor: string
    backgroundColor: string

    public readonly findBestSlotOnDay = findBestSlotOnDay

    constructor(private readonly activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.activatedRoute.queryParams.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(params => {
            this.textColor = params.textColor
            this.backgroundColor = params.backgroundColor
        })
    }

    public selectSlot(slot: AvailabilitySlot) {
        if (slot.type === 'slot') {
            this.onAvailableSlotSelected.next(slot as AvailabilitySlot)
        }
    }
}
