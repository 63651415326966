<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2126_56825)">
        <path d="M4.25781 15.7422C3.53906 15.0234 4.01562 13.5156 3.64844 12.6328C3.28125 11.75 1.875 10.9766 1.875 10C1.875 9.02344 3.26562 8.28125 3.64844 7.36719C4.03125 6.45313 3.53906 4.97656 4.25781 4.25781C4.97656 3.53906 6.48438 4.01562 7.36719 3.64844C8.25 3.28125 9.02344 1.875 10 1.875C10.9766 1.875 11.7188 3.26562 12.6328 3.64844C13.5469 4.03125 15.0234 3.53906 15.7422 4.25781C16.4609 4.97656 15.9844 6.48438 16.3516 7.36719C16.7188 8.25 18.125 9.02344 18.125 10C18.125 10.9766 16.7344 11.7188 16.3516 12.6328C15.9688 13.5469 16.4609 15.0234 15.7422 15.7422C15.0234 16.4609 13.5156 15.9844 12.6328 16.3516C11.75 16.7188 10.9766 18.125 10 18.125C9.02344 18.125 8.28125 16.7344 7.36719 16.3516C6.45313 15.9688 4.97656 16.4609 4.25781 15.7422Z" fill="#DFC051"/>
        <path d="M13.4375 8.125L8.85156 12.5L6.5625 10.3125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
        <clipPath id="clip0_2126_56825">
            <rect width="20" height="20" fill="white"/>
        </clipPath>
    </defs>
</svg>
