import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
} from '@angular/core'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { toNumbers } from '@angular/compiler-cli/src/version_helpers'


@Directive({
    selector: '[appTextareaAutoresize]',
})
export class TextareaAutoresizeDirective implements OnInit {

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    constructor(
        private elementRef: ElementRef,
    ) { }

    @Input() minimalSize: number

    @Input() set value(value: string) {
        this.resize()
    }

    @Input() set ngModel(value: string) {
        this.resize()
    }

    @HostListener('input')
    onInput() {
        this.resize()
    }

    ngOnInit() {
        if (this.elementRef.nativeElement.scrollHeight) {
            setTimeout(() => this.resize())
        }
    }

    resize() {
        /**
         * HotFix to handle initial value
         */
        setTimeout(() => {
            if (this.minimalSize < parseInt(getComputedStyle(this.elementRef.nativeElement).height)) {
                this.elementRef.nativeElement.style.height = '0'
            }
            this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px'
        }, 10)
    }
}
