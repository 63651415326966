import { ApiContext } from '@undock/api/contracts/api.context'

import { PrivacyRoute } from './routes/privacy.route'
import { AvailabilityRoute } from './routes/availability.route'


export class ProfileScope {

    public readonly privacy: PrivacyRoute
    public readonly availability: AvailabilityRoute

    public constructor(
        protected context: ApiContext,
    ) {
        this.privacy = new PrivacyRoute(context)
        this.availability = new AvailabilityRoute(context)
    }
}
