export interface ExtensionMessage {
    type: ExtensionMessageType,
    body: any,
    appId: string
}

export enum ExtensionMessageType {

    SignInWithCustomToken = 'signInWithCustomToken',
    CheckPermission = 'checkPermission',
    RequestPermission = 'requestPermission',
    RemovePermission = 'removePermission',
    SignOut = 'signOut',

    OpenSidebar = 'toggleSidebarMenu',
    CloseSidebar = 'closeSidebar',
    ShowTimeline = 'showTimeline',
    ShowNewEvent = 'showNewEvent',
    RequestPublicProposals = 'requestPublicProposals',
    UpdateProposalsCount = 'updateProposalsCount',
    UpdateExtensionOptions = 'updateExtensionOptions',
    UpdateDomainSpecificExtensionOptions = 'updateDomainSpecificExtensionOptions',
    EnableExtension = 'EnableExtension',
    DisableExtension = 'DisableExtension',

    /**
     * @deprecated
     */
    UpdateUser = 'updateUser',
    /**
     * @deprecated
     */
    RefreshUser = 'refreshUser',
    /**
     * @deprecated
     */
    CheckForChromeExtension = 'checkForChromeExtension',
}
