import { Component } from '@angular/core'
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { Memoize } from '../../../../core/decorators'
import { AuthManager } from '../../../../auth/services/auth.manager'


@Component({
    selector: 'reset-password-page',
    templateUrl: 'reset-password.page.html',
    styleUrls: ['reset-password.page.scss', '../shared-styles/login-signup.scss'],
})
export class ResetPasswordPage {

    protected _authenticationErrors: Array<string> = []

    protected _resetPasswordEmailBeenSent: boolean = false

    public constructor(
        private title: Title,
        private router: Router,
        private authManager: AuthManager,
    ) {
        title.setTitle(`Reset Password | Undock`)
    }

    @Memoize()
    public get sendEmailForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            'email': new UntypedFormControl('', [
                Validators.email,
                Validators.required,
            ]),
        })
    }

    public get authenticationErrors(): Array<string> {
        return this._authenticationErrors
    }

    public get resetPasswordEmailBeenSent(): boolean {
        return this._resetPasswordEmailBeenSent
    }

    public async sendVerificationEmail(): Promise<void> {
        this._authenticationErrors = []
        this.sendEmailForm.markAllAsTouched()

        if (this.sendEmailForm.invalid) {
            return null
        }

        try {
            await this.authManager.sendResetPasswordEmail(
                this.formControl('email').value,
            )

            this._resetPasswordEmailBeenSent = true
        } catch (error) {
            this._authenticationErrors.push(error.message)
        }
    }

    public formControl(formControlName: string): AbstractControl {
        return this.sendEmailForm.get(formControlName) || null
    }

    public isFormControlInvalid(formControlName: string): boolean {
        const control = this.formControl(formControlName)

        return (control.dirty || control.touched) && control.invalid
    }

    public isFormControlHasError(formControlName: string, errorName: string): boolean {
        const control = this.formControl(formControlName)

        return control.errors && control.errors[errorName]
    }
}
