<div class="participants-list participants-list__container">

<!--    <app-meet-add-participants *ngIf="isEditModeStream | stream"></app-meet-add-participants>-->

    <ng-container *ngFor="let participant of participantsStream | stream; trackBy: participantTrackByFn">
        <div class="participants-list__participant-item"
             [ngClass]="{
                'role-owner': participant.role === DockParticipantRole.Owner,
                'role-member': participant.role === DockParticipantRole.Member,
                'role-visitor': participant.role === DockParticipantRole.Visitor,

                'status-denied': participant.status === DockParticipantStatus.Denied,
                'status-pending': participant.status === DockParticipantStatus.Pending,
                'status-accepted': participant.status === DockParticipantStatus.Accepted
             }"
             [ngSwitch]="participant.isUndockUser"
        >
            <ng-container *ngSwitchCase="true">
                <ng-container
                        *ngIf="profilesProvider.getProfileById(participant.userId) | stream as profile else participantUserIsLoadingOrNotDefinedTmpl"
                >
                    <div class="participants-list__participant-info">
                        <img class="participants-list__participant-icon"
                             [matTooltip]="profile.displayName" [matTooltipPosition]="TooltipPosition.BOTTOM"
                             [alt]="profile.email" [src]="profile.imageUrl | imgThumbnail"
                             default="/assets/img/placeholders/user.png"
                        >
                        <p class="participants-list__participant-name">
                            {{ profile.displayName ? profile.displayName : profile.email }} {{ profile.removed ? '[Inactive]' : '' }}
                        </p>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container
                    *ngSwitchCase="false" [ngTemplateOutlet]="participantUserIsLoadingOrNotDefinedTmpl"
            ></ng-container>

            <ng-template #participantUserIsLoadingOrNotDefinedTmpl>
                <div class="participants-list__participant-info">
                    <img class="participants-list__participant-icon"
                         [matTooltip]="participant?.userData?.displayName || participant?.userData?.email"
                         [matTooltipPosition]="TooltipPosition.BOTTOM"
                         [src]="participant?.userData?.imageUrl || ''" [alt]="participant?.userData?.email"
                         default="/assets/img/placeholders/user.png"
                    >
                    <p class="participants-list__participant-name">
                        {{ participant?.userData?.displayName || participant?.email }}
                    </p>
                </div>
            </ng-template>
        </div>
    </ng-container>
</div>
