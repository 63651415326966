import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { TimeSlot } from '@undock/time/availability/contracts/time-slot.interface'
import { MeetingMode } from '@undock/dock/meet'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import moment, { default as m } from 'moment'
import { TimeZoneHelper } from '@undock/time/availability/services/timezone.helper'
import {
    RRuleSet,
    rrulestr,
} from 'rrule'
import { UserConferenceLinkType } from '@undock/api/scopes/user/contracts/user.interface'


export enum PlanType {
    TimeBlock = "TimeBlock",
    Meeting = "Meeting",
    Command = "Command",
    MarkBusy = "MarkBusy",
    MarkAvailable = "MarkAvailable",
    RescheduleCancel = "RescheduleCancel"
}

export enum PlanCategory {
    Focus = 'Focus',
    Breaks = 'Breaks',
    Productivity = 'Productivity',
    Work = 'Work',
    Meetings = 'Meetings',
    Mornings = 'Mornings',
    Fitness = 'Fitness',
    Mind = 'Mind',
    Personal = 'Personal',
    Entertainment = 'Entertainment',
    Family = 'Family',
    Tasks = 'Tasks',
    Nights = 'Nights',
    Routines = 'Routines'
}

export enum PlanTag {
    'Suggestion' = 'Suggestion',
    'Trending' = 'Trending',
    'Featured' = 'Featured'
}

export interface PlanParticipant {
    uid?: string
    email?: string
}

export interface PlanTimeFrameRange {
    id?: string
    startHour: number
    endHour: number
    isSelected: boolean
}

export interface PlanTimeFrame {
    morning: PlanTimeFrameRange
    afternoon: PlanTimeFrameRange
    evening: PlanTimeFrameRange
    custom: PlanTimeFrameRange[]
}

export enum PlanFrequency {
    Once = "Once",
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Annually = "Annually"
}

export enum PlanRecurrenceOrdinal {
    First = "First",
    Second = "Second",
    Third = "Third",
    Fourth = "Fourth",
    Last = "Last"
}

export interface PlanCustomDuration {
    hours: number
    minutes: number
    isSelected: boolean
}

export interface PlanLocationOptions {
    /**
     * Should be the same as user availability from the settings at default
     */
    conferenceLinkType: UserConferenceLinkType

    /**
     * Should be used only if selected conferenceLinkType === 'custom'
     */
    customConferenceLink?: string

    /**
     * Should be used only if the command's event template is "In person" mode
     */
    location?: string
}

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6 // Sun to Sat

export type MonthOfYear = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11

export type DayOfMonth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 |
    20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31

export interface PlanRecurrence {
    frequency: PlanFrequency
    frequencyCount: number
    ordinal?: PlanRecurrenceOrdinal,
    daysOfWeek?: DayOfWeek[] // Can be used for 'weekends only', weekly recurrence, and monthly day of week ordinals
    monthOfYear?: MonthOfYear // Used for annual recurrence month of the year
    dayOfMonth?: DayOfMonth // Used for annual recurrence day of the month
    isWeekdaysOnly: boolean
    startDate?: Date
    endDate?: Date
    maxOccurences?: number
    isEndDateEnabled: boolean
    rrule?: string
}

export enum PlanScheduleAheadFrequency {
    Days = "Days",
    Weeks = "Weeks",
    Months = "Months"
}

export interface PlanAutoPilotOptions {
    scheduleAheadFrequency: PlanScheduleAheadFrequency
    scheduleAheadFrequencyCount: number
    isAutoPilotEnabled: boolean
}

export enum PlanPriority {
    Low = "Low",
    Med = "Med",
    High = "High"
}

export interface PlanCreator {
    label: string
    name: string
    avatarUrl: string
}


export class Plan {

    private _rrule: RRuleSet

    _id?: string
    ownerId: string
    ownerUId: string
    createdAt?: Date
    updatedAt?: Date

    title: string
    description: string
    summary: string

    type: PlanType

    /**
     * Pre-filled dock data for meeting types
     */
    draftDockId?: string

    // Integration id to assign events
    calendarIntegrationId?: string

    // Id of the public command from which this command was created, if applicable
    publicId?: string

    // Participants that will be added to meeeting/event instances created by this Plan
    participants?: PlanParticipant[]

    // Time frames in which this Command will suggest times for meetings/time blocks/etc
    timeFrame: PlanTimeFrame

    // Duration of the meetings/time blocks/etc created/suggested by this Command
    duration: MeetingDuration
    customDuration: PlanCustomDuration = null
    isAllDay: boolean = false

    // Options determining which conference solution will be used for remote meetings created by a meeting type command
    locationOptions?: PlanLocationOptions

    // Recurrance rules and settings, how often this Command will execute (actually put Events on
    // the calendar) and rules related to that
    recurrence: PlanRecurrence

    // Options determining when the final version of an Event is created by a Command
    // i.e. if a Command is supposed to schedule "Lunch Time" every day, and if
    // scheduleAheadFrequency = 'Weeks' and scheduleAheadFrequencyCount = 1,
    // then the actual "Lunch Time" events will be created at most 1 week ahead and no sooner
    autoPilotOptions: PlanAutoPilotOptions

    // When multiple commands are conflicting, the free time will go towards the Command with the
    // highest priority. Also, Low and Med priority Commands are "bumpable", meaning if there is
    // a good enough reason for another Event to fill up the last available slot for those
    // Commands (such as a VIP, or important Connection scheduling on the User's profile at those
    // times), then the Command will be "bumped" and no event will be created for that spot
    priority: PlanPriority

    // See "priority"
    isBumpable: boolean = false

    // Is the Command actually running according to it's schedule/recurrence or is it paused
    isActive: boolean
    lastExecuted: Date = null
    nextExecution?: Date
    conflictDates?: Date[]

    timeZone?: string

    // Default Undock Commands and Commands shared by other Users are marked as public and can be
    // added to a User's personal Commands list
    isPublic: boolean = false

    isDisabled?: boolean

    category?: PlanCategory
    tag?: PlanTag
    imageUrl?: string = ''
    url?: string
    creator?: PlanCreator

    public get calculatedDuration(): number {
        if (this.isAllDay) {
            return 1440
        }
        else if (this.customDuration?.isSelected) {
            return this.customDuration.hours*60 + this.customDuration.minutes
        }
        else {
            return this.duration
        }
    }

    public get isRecurring(): boolean {
        return this.recurrence.frequency !== PlanFrequency.Once && !!this.recurrence.rrule
    }

    public get isConflict(): boolean {
        return this.conflictDates?.length && this.conflictDates.some(d => moment(TimeZoneHelper.fromUTCDate(d)).isSameOrAfter(TimeZoneHelper.toUTCDate(moment()), 'day'))
    }

    public get nextConflictDate(): Date {
        return this.conflictDates?.length ? this.conflictDates.find(d => moment(TimeZoneHelper.fromUTCDate(d)).isSameOrAfter(TimeZoneHelper.toUTCDate(moment()), 'day')) : null
    }

    public get nextOccurenceDate(): Date {
        if (!this._rrule && this.recurrence.rrule) {
            this._rrule = rrulestr(this.recurrence.rrule, { forceset: true }) as RRuleSet
        }
        return this._rrule ? TimeZoneHelper.fromUTCDate(
            this._rrule.after(TimeZoneHelper.toUTCDate(m()), true)
        ) : null
    }

    public get nextScheduledDate(): Date {
        if (!this._rrule && this.recurrence.rrule) {
            this._rrule = rrulestr(this.recurrence.rrule, { forceset: true }) as RRuleSet
        }
        let scheduledDates = this._rrule ? this._rrule.exdates().map(d => TimeZoneHelper.fromUTCDate(d)) : []
        return scheduledDates?.length ? scheduledDates[0] : null
    }

    public isConflictOnDate(date: moment.MomentInput): boolean {
        return this.conflictDates?.length && this.conflictDates.some(conflict => moment.utc(conflict).isSame(TimeZoneHelper.toUTCDate(date), 'day'))
    }


}

export interface PlanUpdateDTO {
    title?: string

    description?: string

    type?: PlanType

    draftDockId?: string

    calendarIntegrationId?: string

    participants? : PlanParticipant[]

    timeZone?: string

    timeFrame?: PlanTimeFrame

    duration?: MeetingDuration

    customDuration?: PlanCustomDuration

    isAllDay?: boolean

    locationOptions?: PlanLocationOptions

    recurrence?: PlanRecurrence

    priority?: PlanPriority

    autoPilotOptions?: PlanAutoPilotOptions

    isBumpable?: boolean
}

export interface PublicPlanUpdateDTO extends PlanUpdateDTO {
    summary?: string

    category?: PlanCategory

    url?: string

    imageUrl?: string

    isDisabled?: boolean

    tag?: PlanTag
}

export interface PlanUpdateRecurrenceDTO {
    recurrence?: PlanRecurrence
}
