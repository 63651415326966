import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { TimeCommandViewModel } from '@undock/time/prompt/states/time-command.view-model'
import {
    MeetingMode,
    UserSchedulesManager,
    Schedule,
} from '@undock/dock/meet'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { Memoize } from '@undock/core'
import {
    combineLatest,
    map,
    Observable,
    shareReplay,
    takeUntil,
} from 'rxjs'
import { TimeCommandAction } from '@undock/api/scopes/nlp/routes/commands.route'
import { Clipboard } from '@angular/cdk/clipboard'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { ProfileLinksManager } from '@undock/profile/shared/services/profile-links.manager'
import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'
import { Moment } from 'moment/moment'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { CurrentUser } from '@undock/session'
import {
    TimeProposalInterface,
} from '@undock/proposals/contracts'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'


@Component({
    selector: 'app-time-command-share-availability',
    templateUrl: './time-command-share-availability.component.html',
    styleUrls: ['./time-command-share-availability.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        UserSchedulesManager,
        AvailabilityProvider,
        AvailabilityViewModel,
        EventFormStateModel,
    ],
})
export class TimeCommandShareAvailabilityComponent {

    public readonly customSchedulesStream: Observable<Schedule[]>
    public readonly buildInSchedulesStream: Observable<Schedule[]>
    public readonly isAvailabilityLoadingStream: ReactiveStream<boolean>
    public readonly isMessageUpdateLoadingStream: ReactiveStream<boolean>
    public readonly selectedAvailabilityDayStream: ReactiveStream<Moment>

    @CompleteOnDestroy()
    protected currentActionSubject = new ValueSubject<TimeCommandAction>(null)

    @CompleteOnDestroy()
    protected isMessageUpdateLoadingSubject = new ValueSubject<boolean>(false)

    @Input() set action(value: TimeCommandAction) {
        this.currentActionSubject.next(value)
    }

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        public viewModel: TimeCommandViewModel,
        protected clipboard: Clipboard,
        protected currentUser: CurrentUser,
        protected snackbarManager: SnackbarManager,
        protected profileLinksManager: ProfileLinksManager,
        protected meetingTypesManager: UserSchedulesManager,
        protected readonly availabilityProvider: AvailabilityProvider,
        protected readonly availabilityViewModel: AvailabilityViewModel,
    ) {
        this.customSchedulesStream = this.meetingTypesManager.customSchedules$
        this.buildInSchedulesStream = this.meetingTypesManager.buildInSchedules$
        this.isAvailabilityLoadingStream = this.availabilityViewModel.isAvailabilityLoadingStream
        this.isMessageUpdateLoadingStream = this.isMessageUpdateLoadingSubject.asStream()
        this.selectedAvailabilityDayStream = this.availabilityViewModel.selectedAvailabilityDayStream
    }

    public async ngOnInit() {
        await this.initAvailability()
    }

    @Memoize()
    public get messageStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(this.currentActionSubject.pipe(
            map(action => !!action
                ? action.response
                : ''),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get proposalStream(): ReactiveStream<TimeProposalInterface> {
        return new ReactiveStream<TimeProposalInterface>(this.currentActionSubject.pipe(
            map(action => !!action
                ? action.proposal
                : null),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get selectedTimestampsStream(): ReactiveStream<string[]> {
        return new ReactiveStream<string[]>(this.proposalStream.pipe(
            map(proposal => !!proposal
                ? proposal.proposedSlots.map(slot => slot.timeStamp)
                : []),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    // @Memoize()
    // public get proposedDateRangesStream(): ReactiveStream<DateRange[]> {
    //     return new ReactiveStream<DateRange[]>(this.proposalStream.pipe(
    //         map(proposal => !!proposal
    //             ? proposal.proposedSlots.map(slot => {
    //                 return {
    //                     start: moment(slot.timeStamp).toDate(),
    //                     end: moment(slot.timeStamp).add(slot.duration, 'minutes')
    //                 }
    //             })
    //             : []),
    //         takeUntil(this.destroyedEvent),
    //         shareReplay({ bufferSize: 1, refCount: true }),
    //     ))
    // }

    @Memoize()
    public get availabilityStream(): ReactiveStream<AvailabilitySet> {
        return new ReactiveStream(
            combineLatest([
                this.availabilityViewModel.displayAvailabilityStream,
                this.availabilityViewModel.selectedAvailabilityDayStream,
            ]).pipe(
                takeUntil(this.destroyedEvent),
                map(([availability, day]) => {
                    return availability?.find(set => set.day.isSame(day, 'day'))
                }),
            ),
        )
    }

    public async addScheduleLink(schedule: Schedule, currentMessage?: string) {

        let [
            action,
            initialMessage,
            link
        ] = await Promise.all([
            await this.currentActionSubject,
            this.messageStream,
            this.profileLinksManager.generatePrivateAccessUrlForMeetingType(schedule.url)
        ])

        let message = typeof currentMessage === 'string' ? currentMessage : initialMessage

        if (!message.includes(link)) {
            let scheduleLink =
`If these times don't work, you can select another time here: ${link}`
            message += `${scheduleLink}
`

            return this.viewModel.updateActionResponseMessage(action.id, message)
        } else {
            this.snackbarManager.info("Link is already included", SnackbarPosition.BottomCenter)
        }
    }

    public async updateAvailabilitySlots(addedSlots: AvailabilitySlot[], removedSlots: AvailabilitySlot[], currentMessage?: string) {
        let action = await this.currentActionSubject
        if (action) {

            this.isMessageUpdateLoadingSubject.next(true)

            try {
                if (typeof currentMessage === 'string' && action.response !== currentMessage) {
                    await this.viewModel.updateActionResponseMessage(action.id, currentMessage)
                }
                await this.viewModel.updateTimeSlotsForAction(action.id, addedSlots.map(s => s.timeStamp), removedSlots.map(s => s.timeStamp))
            } catch (err) {
                console.log("Error adding time to message:", err)
                this.snackbarManager.error("Please try again", SnackbarPosition.BottomCenter)
            } finally {
                this.isMessageUpdateLoadingSubject.next(false)
            }
        }
    }

    public copyTextToClipboard(text: string) {
        this.clipboard.copy(text)
        this.snackbarManager.info("Copied to clipboard", SnackbarPosition.BottomCenter)
    }

    protected async initAvailability() {
        let [user, proposal] = await  Promise.all([
            this.currentUser.dataStream,
            this.proposalStream
        ])
        /**
         * Binds availability provider to the current context
         */
        await this.availabilityProvider.initialize({
            v2: true,
            emails: [user.email],
            timeZone: user.lastTimeZone,
            dateRange: this.availabilityViewModel.loadAvailabilityDatesRangeStream,
            meetingMode: MeetingMode.Video,
            meetingDuration: proposal.meetingDuration,
        })

        await this.availabilityViewModel.initViewModel()
    }

}
