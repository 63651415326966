import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router'


export abstract class AbstractGuard {
    protected getActivatedRouteSnapshot(state: RouterStateSnapshot): ActivatedRouteSnapshot {
        const findDeepestChildrenRouteState = (state: ActivatedRouteSnapshot): ActivatedRouteSnapshot => {
            return state.children.length > 0 ? findDeepestChildrenRouteState(state.firstChild) : state
        }

        return findDeepestChildrenRouteState(state.root)
    }
}
