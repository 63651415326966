<section class="command-menu" @slideInConfirmation>

        <div class="command-modal" (clickOutside)="closeCommandMenuIfOpen()">

            <app-time-prompt></app-time-prompt>

            <div class="__shortcuts-list __list-section">
                <span class="__heading">Shortcuts</span>

                <div>

                </div>
            </div>

            <div class="__command-footer">

            </div>

        </div>

</section>
