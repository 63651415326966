import { ApiRoute } from '@undock/api/services/api.route'

export class Auth0Route extends ApiRoute {
    public init() {
        this.route = 'auth'
    }

    /** @deprecated */
    public async sendVerificationEmail(auth0UserId: string) {
        return this.get<{ verificationEmailSentAt: string }>()
                   .endpoint(`auth0/send-verification-email/${auth0UserId}`)
    }
}
