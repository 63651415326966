<ng-container *ngIf="{
        isUnconfirmedChanges: isUnconfirmedChangesStream | stream
    } as view">
    <div class="event-form-slot-select-dropdown"
        [class.__remove-border-radius]="view.isUnconfirmedChanges"
    >
        <header>
            <app-arrow-button
                    [height]="20" [width]="20"
                    [direction]="'left'" color="var(--arrow-icon-color)"
                    (onClick)="displayPrevAvailabilityDays()"
            ></app-arrow-button>

            <span>
                    {{ selectedAvailabilityDayStream | stream | moment: 'MMM D, YYYY' }}
                </span>

            <app-arrow-button
                    [height]="20" [width]="20"
                    [direction]="'right'" color="var(--arrow-icon-color)"
                    (onClick)="displayNextAvailabilityDays()"
            ></app-arrow-button>
        </header>

        <main [ngSwitch]="isAvailabilityLoadingStream | stream | boolean">
            <div class="available-slots-list" *ngSwitchCase="true">
                <ngx-skeleton-loader
                        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]"
                        [theme]="{height: '40px', width: '100%', borderRadius: '8px', margin: '0 0 2px', backgroundColor: '#FBFBFD'}"
                ></ngx-skeleton-loader>
            </div>

            <ng-container *ngSwitchCase="false">
                <div class="available-slots-list"
                     *ngIf="availableSlotsStream | stream as availableSlots"
                >
                    <div>
                        <ng-container *ngFor="let slot of availableSlots">
                            <div class="available-slot clickable"
                                 [class.best]="slot.best"
                                 [class.event]="!slot.free"
                                 [class.targeted]="(targetedSlot$ | stream)?.timeStamp === slot.timeStamp"
                                 [class.selected]="slot.isSelected"
                                 (click)="selectAvailabilitySlot(slot)"
                            >
                                <span>{{ slot.label }}</span>

                                <app-icon-lightning
                                        *ngIf="slot.free"
                                        [height]="20" [width]="20"
                                        color="var(--lightning-icon-color)"
                                ></app-icon-lightning>

                                <app-remove-icon
                                        *ngIf="slot.isSelected"
                                        color="var(--remove-slot-icon-color)"
                                ></app-remove-icon>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="view.isUnconfirmedChanges">
                <section class="button-bar">
                    <div class="confirm-button" (click)="confirmChanges()">
                        Confirm
                    </div>
                </section>
            </ng-container>
        </main>

    </div>
</ng-container>

