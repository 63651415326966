import { ApiRoute } from '@undock/api/services/api.route'
import {
    EventSchedule,
    MeetingMode,
} from '@undock/dock/meet'
import { EventAttendee } from '@undock/api/scopes/time/contracts/timeline-event/event-attendee.interface'
import { DateRange } from '@undock/time/availability'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { TimeProposalInterface } from '@undock/proposals/contracts'
import { UserData } from '@undock/user'


export class CommandsRoute extends ApiRoute {

    public init() {
        this.route = 'nlp/time/command'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public async submitCommandPrompt(prompt: string, isTrainingMode: boolean = false): Promise<TimeCommandPromptResponse> {
        return this.post<TimeCommandPromptResponse>()
                   .withData({
                       prompt,
                       isTrainingMode
                   })
                   .endpoint(`submit`)
    }

    public async submitCommandPromptWithSse(
        prompt: string,
        context?: TimeCommandPromptContext,
        isTrainingMode: boolean = false
    ): Promise<string> {

        context = {
            participants: [],
            participantIds: [],
            organizationIds: [],
            ...(context ?? {})
        }

        return this.post<string>()
                   .withData({
                       prompt,
                       context,
                       isTrainingMode
                   })
                   .endpoint(`submit-sse`)
    }

    public async executeCommand(blueprint: TimeCommandBlueprint): Promise<void> {
        return this.post<void>()
                   .withData({
                       blueprint
                   })
                   .endpoint(`execute`)
    }

    public async trainCommand(blueprint: TimeCommandBlueprint): Promise<void> {
        return this.post<void>()
                   .withData({
                       blueprint
                   })
                   .endpoint(`train`)
    }

    public async addSlotToShareAvailabilityAction(blueprint: TimeCommandBlueprint, actionId: string, slot: string): Promise<TimeCommandAction> {
        return this.post<TimeCommandAction>()
                   .withData({
                       blueprint, actionId, slot
                   })
                   .endpoint(`add-availability-slot`)
    }

    public async updateSlotsForShareAvailabilityAction(blueprint: TimeCommandBlueprint, actionId: string, addedSlots: string[], removedSlots: string[]): Promise<TimeCommandAction> {
        return this.post<TimeCommandAction>()
                   .withData({
                       blueprint, actionId, addedSlots, removedSlots
                   })
                   .endpoint(`update-availability-slots`)
    }
}

export enum TimeCommandActions {
    Cancel = 'Cancel',
    Schedule = 'Schedule',
    GroupSchedule = 'Group Schedule',
    Reschedule = 'Reschedule',
    Modify = 'Modify',
    View = 'View',
    Block = 'Block',
    ShareAvailability = 'Share Availability',
    Answer = 'Answer',
    Other = 'View',
    CreateHolds = 'Create Holds',
    DeleteHolds = 'Delete Holds',
    ViewHolds = 'View Holds'
}

export interface TimeCommandBlueprintEvent {
    iCalUId: string
    title: string
    schedule: EventSchedule
    reschedule?: EventSchedule
    attendees: EventAttendee[]
    addedAttendees?: string[]
    removedattendees?: string[]
    location?: MeetingMode
    isRecurring?: boolean
    isAutoGenerated?: boolean
    isSelected?: boolean
    isSuggested?: boolean
    isOwnEvent?: boolean
    isEdited?: boolean
    meetingData?: EditMeetingData
}

export class TimeCommandAction {
    [x: string]: any
    id?: string
    action: TimeCommandActions
    timeframe: DateRange[]
    timeSlots?: string[]
    events?: TimeCommandBlueprintEvent[]
    holds?: TimeCommandBlueprintHold[]
    attendees?: EventAttendee[]
    description?: string
    proposal?: TimeProposalInterface
    topics?: string[]
    response?: string
    isConflict?: boolean
}

export class TimeCommandBlueprintHold {
    id?: string
    title?: string
    timeStamp?: string
    duration?: number
    isSelected?: boolean
    isSuggested?: boolean
}

export interface TimeCommandBlueprint {
    actions: TimeCommandAction[]
    originalQuery?: string
}

export interface TimeCommandPromptContext {
    participants?: Partial<UserData>[],
    participantIds?: string[],
    organizationIds?: string[]
}

export interface TimeCommandPromptResponse {
    status?: string,
    blueprint: TimeCommandBlueprint
}
