<button mat-button (click)="open()" class="add-people-button">
    <p class="button-content">
        <app-icon-add-people [width]="20" [height]="20" [color]="'#000000'"></app-icon-add-people>
        <span class="button-title">Invite via email</span>
    </p>
</button>

<ng-container *ngIf="isOpenedSubject | stream">
    <div class="add-participant-overlay" (click)="close()">
        <div class="add-participant-form" (click)="$event.stopPropagation()">
            <header>
                <div class="form-label">
                    <span>Invite People</span>
                </div>

                <div class="close-button">
                    <i class="material-icons-round" (click)="close()">close</i>
                </div>
            </header>

            <section class="pending-participants-list">
                <div class="participant-list-item"
                     *ngFor="let profile of pendingParticipantsStream | stream; trackBy: profileDataTrackByFn"
                >
                    <div class="profile-image-box">
                        <img [matTooltip]="profile?.displayName || profile.email"
                             [matTooltipPosition]="TooltipPosition.BOTTOM"
                             [src]="profile.imageUrl | imgThumbnail" [alt]="profile.displayName"
                             default="/assets/img/placeholders/user.png"
                        />
                    </div>

                    <div class="display-name-box">
                        <span>{{ profile.displayName ? profile.displayName : profile.email }}</span>
                    </div>

                    <div class="delete-participant-btn" (click)="removePendingParticipant(profile.email)">
                        <i class="material-icons">close</i>
                    </div>
                </div>

                <div class="search-participant-input">
                    <input name="email" autocomplete="email" placeholder="Enter email address to invite..."
                           type="text" autofocus
                           (keyup)="onSearchInputKeyUp($event)"
                           [class.searching]="isSearchingSubject | stream"
                           #searchInput [value]="searchCriteriaSubject | stream"
                    />
                </div>
            </section>

            <section class="send-invites" *ngIf="arePendingParticipantsAddedStream | stream">
                <ng-container *ngIf="pendingParticipantsCountStream | stream as count">
                    <button mat-button class="send-invites-button"
                            (click)="sendInvitesAndAddPendingParticipants()"
                    >Send {{ count }} {{ count === 1 ? 'invite' : 'invites' }}</button>
                </ng-container>
            </section>

            <div class="divider"></div>

            <ng-container *ngIf="{
                searchResultsUIds: searchResultUIdsStream | stream,
                suggestedParticipantUIds: suggestedParticipantUIdsStream | stream
            } as values" [ngSwitch]="values?.searchResultsUIds && values?.searchResultsUIds?.length > 0">
                <!--
                 /**
                  * Search results list
                  */
                 -->
                <ng-container *ngSwitchCase="true">
                    <span class="section-label">Search results</span>

                    <section class="suggested-participants-list">
                        <ng-container
                                *ngFor="let uId of values.searchResultsUIds"
                                [ngTemplateOutlet]="profileViewTmpl" [ngTemplateOutletContext]="{ uId: uId }"
                        ></ng-container>
                    </section>
                </ng-container>

                <!--
                /**
                 * Suggested participants list
                 */
                -->
                <ng-container *ngSwitchCase="false">
                    <span class="section-label">Suggested</span>

                    <section class="suggested-participants-list">
                        <ng-container
                                *ngFor="let uId of values.suggestedParticipantUIds"
                                [ngTemplateOutlet]="profileViewTmpl" [ngTemplateOutletContext]="{ uId: uId }"
                        ></ng-container>
                    </section>
                </ng-container>

                <!--
                 /**
                  * Profile template for both lists
                  */
                -->
                <ng-template #profileViewTmpl let-uId="uId">
                    <ng-container
                            *ngIf="profilesProvider.getProfileByUid(uId) | stream as profile else profileIsLoadingTmpl"
                    >
                        <div class="suggested-participant-item" (click)="onProfileSelected(profile)">
                            <div class="profile-image-box">
                                <img [matTooltip]="profile?.displayName || profile.email"
                                     [matTooltipPosition]="TooltipPosition.BOTTOM"
                                     [src]="profile.imageUrl | imgThumbnail" [alt]="profile.displayName"
                                     default="/assets/img/placeholders/user.png"
                                />
                            </div>

                            <div class="profile-info-box">
                                <div class="display-name">
                                    <span>{{ profile.displayName }}</span>
                                </div>

                                <div class="email">
                                    <span>{{ profile.email }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #profileIsLoadingTmpl>
                        <div class="suggested-participant-item">
                            <div class="profile-image-box">
                                <img src="/assets/img/placeholders/user.png" alt/>
                            </div>

                            <div class="profile-info-box">
                                <div class="display-name"><span></span></div>
                                <div class="email"><span></span></div>
                            </div>
                        </div>
                    </ng-template>
                </ng-template>
            </ng-container>

            <div class="processing-overlay" *ngIf="isProcessingSubject | stream"></div>
        </div>
    </div>
</ng-container>



