import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

@Component({
    selector: 'app-view-switch-button',
    templateUrl: 'view-switch.component.html',
    styleUrls: [`view-switch.component.scss`]
})
export class ViewSwitchComponent<T1, T2> {

    @Input() value: T1 | T2

    @Input() options: [T1, T2]

    @Output() mode = new EventEmitter<T1 | T2>()

    public changeState(state: T1 | T2) {
        this.value = state
        this.mode.emit(state)
    }
}
