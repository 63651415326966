import { ApiRoute } from '@undock/api/services/api.route'
import { TerminalTimelineEventDTO } from '@undock/api/scopes/terminal/contracts/terminal-timeline-event.dto'

export class EventsRoute extends ApiRoute {
    public init() {
        this.route = 'terminal/events'
    }

    public async getUserEventsByDateRange(uid: string, start: Date, end: Date): Promise<TerminalTimelineEventDTO[]> {
        return this.post<TerminalTimelineEventDTO[]>()
                   .withData({
                       uid,
                       start: start.toISOString(),
                       end: end.toISOString(),
                   }).endpoint('list')
    }

}
