import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core'

export class ObservablesContext {
    [key: string]: any;
}

@Directive({
    selector: '[observables]',
})
export class ObservablesDirective implements OnInit {

    /**
     * ------------------------------------------------------------------------------------------------------
     *         Directive used for handling multiple subscriptions from the component templates
     *
     * @see https://medium.com/angular-in-depth/having-fun-with-structural-directives-in-angular-69b4d229ad93
     * ------------------------------------------------------------------------------------------------------
     */

    private _context = new ObservablesContext()

    /**
     *  You can create a custom domain specific language with the pattern "[selector]Keyword",
     *  which maps to an Input.
     *  "observablesFrom" for example can then be used like *observables="let ... from { ... }
     */
    @Input() set observablesFrom(value: any) {
        Object.assign(this._context, value)
    }

    constructor(private template: TemplateRef<ObservablesContext>,
                private viewContainer: ViewContainerRef) {}

    ngOnInit() {
        this.viewContainer.createEmbeddedView(this.template, this._context)
    }
}
