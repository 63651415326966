import {
    Component,
    Input,
} from '@angular/core'
import {
    PlanType,
    Plan,
} from '@undock/time/plans/contracts/plan.interface'

@Component({
    selector: 'app-commands-menu-plan-item',
    templateUrl: './app-command-menu-plan-item.component.html',
    styleUrls: ['./app-command-menu-plan-item.component.scss'],
})
export class AppCommandMenuPlanItemComponent {

    public readonly iconColor = '#2B2733'

    public readonly PlanType = PlanType

    @Input() plan: Plan

    @Input() tags: string[] = []

}
