import { Attachment } from '@undock/core/models/attachment.model'
import { HasAttachments } from '@undock/core/contracts/model/has-attachments.interface'
import { PathResolveStrategy } from '@undock/core/contracts/path-resolve.strategy'

export interface AttachmentSource {
    name: string
    target: Blob | Uint8Array | ArrayBuffer
    options?: AttachmentOptions
}

export interface AttachmentOptions {
    tags?: string[]
}

export abstract class AbstractAttachmentsManager<T extends HasAttachments> {

    protected storagePathPrefix: string = '/'

    protected pathResolveStrategy: PathResolveStrategy


    public abstract addAttachments(owner: T, sources: AttachmentSource[]): Promise<Attachment[]>

    public abstract removeAttachment(attachment: Attachment): Promise<void>

    public abstract generateAttachmentUrl(attachment: Attachment): Promise<string>

    protected async getStoragePathForEntity(owner: T): Promise<string> {
        return this.pathResolveStrategy.resolve(owner, this.storagePathPrefix)
    }
}
