import { TemplateRef } from '@angular/core'

import {
    State,
    StreamStore,
} from '@undock/core/states'
import { StatefulSubject } from '@typeheim/fire-rx'
import { DashboardView } from '@undock/dashboard/contracts/dashboard-view'


export class DashboardStore extends StreamStore {
    public readonly dashboardView = new StatefulSubject<DashboardView>()
    public readonly headerControlsTmpl = new StatefulSubject<TemplateRef<any>>()
}

export declare type DashboardState = State<DashboardStore>
