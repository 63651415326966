import {
    Component,
    Directive,
    Input,
    ViewContainerRef,
} from '@angular/core'

import { ICONS } from '@undock/common/ui-kit/ui-kit.module'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-dev-design-system',
    template: `
        <mat-tab-group>
            
            <mat-tab label="Icons">
                <div class="container-icons">
                    <h1 class="header">Icon components</h1>
                    <div class="icons-list">
                        <ng-container *ngFor="let iconComponent of icons">
                            <div class="icon-row">
                                <ng-template [dynamicComponentHost]="iconComponent"></ng-template>
                                <span class="icon-name"> {{iconComponent.name}}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Buttons">
                <div class="buttons-list">
                    <h1 class="header">Buttons</h1>
                    <button class="ud-btn ud-btn-primary btn-large">
                        Button
                    </button>
                    <button class="ud-btn ud-btn-primary btn-medium" disabled="true">
                        Button
                    </button>
                    <button class="ud-btn ud-btn-primary btn-small">
                        Button
                    </button>

                    <button class="ud-btn btn-dark-primary btn-large">
                        Button
                    </button>
                    <button class="ud-btn btn-dark-primary btn-medium" disabled="true">
                        Button
                    </button>
                    <button class="ud-btn btn-dark-primary btn-small">
                        Button
                    </button>
                    
                    <button class="ud-btn ud-btn-secondary btn-large">
                        Button
                    </button>
                    <button class="ud-btn ud-btn-secondary btn-medium" disabled="true">
                        Button
                    </button>
                    <button class="ud-btn ud-btn-secondary btn-small">
                        Button
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Form inputs">
                <h1 class="header">Inputs</h1>
                <div class="container-inputs">
                    <div class="ud-form-input">
                        <input type="text" spellcheck="false" autocomplete="off" placeholder="class: ud-form-input">
                    </div>
    
                    <div class="ud-form-input with-text with-border">
                        <div class="content-container">
                            undock.com/
                        </div>
                        <input type="text" spellcheck="false" autocomplete="off" placeholder="class: ud-form-input with-text with-border">
                    </div>
    
                    <div class="ud-form-input with-icon with-border">
                        <div class="content-container">
                            <app-icon-phone [color]="'#807A8E'" [width]="24" [height]="24"></app-icon-phone>
                        </div>
                        <input type="text" spellcheck="false" autocomplete="off" placeholder="class: ud-form-input with-icon with-border">
                    </div>
    
                    <div class="ud-form-input with-icon">
                        <div class="content-container">
                            <app-icon-phone [color]="'#807A8E'" [width]="24" [height]="24"></app-icon-phone>
                        </div>
                        <input type="text" spellcheck="false" autocomplete="off" placeholder="class: ud-form-input with-icon">
                    </div>

                    <div class="ud-form-input with-icon">
                        <div class="content-container">
                            <app-icon-phone [color]="'#807A8E'" [width]="24" [height]="24"></app-icon-phone>
                        </div>
                        <input type="text" spellcheck="false" autocomplete="off" placeholder="class: ud-form-input with-icon">
                        <div class="cancel-button">
                            <app-icon-close [color]="'#807A8E'" [width]="20" [height]="20"></app-icon-close>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    `,
    styleUrls: ['dev-design-system.component.scss']
})
export class DevDesignSystemComponent {
    public readonly icons = ICONS

    constructor() {

    }
}

@Directive({
    selector: '[dynamicComponentHost]',
})
export class DynamicComponentHost {

    @Input('dynamicComponentHost') readonly componentCtr

    constructor(private viewContainerRef: ViewContainerRef) { }

    ngAfterViewInit() {
        if(this.componentCtr) {
            this.viewContainerRef.clear()
            let component = this.viewContainerRef.createComponent<IconTemplateComponent>(this.componentCtr)
            component.instance.color = 'black'
            component.instance.height = '48'
            component.instance.width = '48'
        }
    }
}
