import { Router } from '@angular/router'
import { Injectable } from '@angular/core'

import * as moment from 'moment'
import firebase from 'firebase/app'

import {
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
} from '@typeheim/fire-rx'

import {
    Api,
    HttpException,
} from '@undock/api'
import {
    Config,
    ExtConnector,
    Memoize,
    SessionStorage,
} from '@undock/core'
import { User } from '@undock/user'
import { AuthManager } from '@undock/auth'
import { injectCollection } from '@undock/session'
import { Account } from '@undock/user/models/account.model'
import { Profile } from '@undock/user/models/profile.model'
import { anonymousUserData } from './defaults/anonymous-user.data'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'


@Injectable()
export class UserSession {

    public readonly currentUser$: ReactiveStream<User>

    protected readonly AccountCollection = injectCollection(Account)
    protected readonly ProfileCollection = injectCollection(Profile)

    @CompleteOnDestroy()
    protected currentUserSubject = new StatefulSubject<User>()

    protected readonly USER_STORAGE_KEY = '@undock[StoredUser]'

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected router: Router,
        protected config: Config,
        protected auth: AuthManager,
        protected storage: SessionStorage,
        protected extConnector: ExtConnector,
        protected snackbarManager: SnackbarManager,
    ) {
        this.currentUser$ = this.currentUserSubject.asStream()

        this.initialize().catch(
            error => console.warn(`UserSession::initialize ERROR`, error),
        )
    }

    @Memoize()
    public get uidStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.auth.authUserStream.pipe(
                filter(Boolean),
                map(authUser => authUser.uid),

                distinctUntilChanged(),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get accountStream(): ReactiveStream<Account> {
        return new ReactiveStream<Account>(
            this.uidStream.pipe(
                switchMap(uid => {
                    return this.AccountCollection.one(uid)
                                            .stream()
                                            .emitUntil(this.destroyedEvent)
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get profileStream(): ReactiveStream<Profile> {
        return new ReactiveStream<Profile>(
            this.uidStream.pipe(
                switchMap((uid) => {
                    return this.ProfileCollection.one(uid)
                                            .stream()
                                            .emitUntil(this.destroyedEvent)
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isProfileInitializedStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.profileStream.pipe(
                /**
                 * Checks is profile generated for account
                 */
                map(value => Boolean(value)),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }


    public async initialize(): Promise<void> {
        this.auth.authUserStream.pipe(
            takeUntil(this.destroyedEvent),
            distinctUntilChanged(
                (prev, next) => prev?.uid === next?.uid,
            ),
        ).subscribe(authUser => {
            if (authUser) {
                if (!authUser.isAnonymous) {
                    return this.initializeAsRegularUser(authUser)
                } else {
                    return this.initializeAsAnonymousUser(authUser)
                }
            }

            /**
             * Should clean storage if logged out
             */
            return this.clearStoredData()
        })
    }

    public async refreshCurrentUser(updates?: Partial<User>): Promise<void> {
        if (updates) {
            const data = await this.currentUserSubject

            for (let key in updates) {
                if (updates.hasOwnProperty(key)) {
                    data[key] = updates[key]
                }
            }
            this.storedUserData = data
            this.currentUserSubject.next(data)
            this.extConnector.updateUserInExt(data)
        } else {
            await this.refreshCurrentUserData()
        }
    }


    protected async initializeAsRegularUser(authUser: firebase.User) {
        if (this.hasStoredData && this.storedUserData.firebaseId === authUser.uid) {

            /**
             * Using cached user data temporarily
             */
            this.currentUserSubject.next(this.storedUserData)

            /**
             * Fetching user profile from the cache and refreshing asynchronously
             */
            this.refreshCurrentUserData().catch(
                error => console.warn(`Unable refresh current user data`, error),
            )
        } else {
            this.clearStoredData()

            /**
             * We need to ensure all
             */
            const isAccountJustCreated = moment(authUser.metadata.creationTime)
                .add(5, 'minutes')
                .isAfter(moment())

            if (isAccountJustCreated) {
                /**
                 * Ensure account is generated
                 *
                 * @TODO: Create separate page with animation saying `Your account is building`
                 */
                const ensureAccountIsGenerated = async (attempt = 1) => {
                    let account

                    try {
                        account = await this.AccountCollection.one(authUser.uid).get()
                    } catch (e) {
                        await new Promise(resolve => {
                            setTimeout(async () => {
                                resolve(
                                    await ensureAccountIsGenerated(attempt + 1),
                                )
                            }, attempt * 10 ** 3)
                        })
                    }

                    if (account && account?.id) {
                        /**
                         * Account is generated successfully
                         */
                        return true
                    }

                    console.info(`Account didn't generated yet. Attempt ${attempt}`)

                    /**
                     * Wait until authentication hook will proceed (est. 55 seconds max)
                     */
                    if (attempt < 15) {
                        await new Promise(resolve => {
                            setTimeout(async () => {
                                resolve(
                                    await ensureAccountIsGenerated(attempt + 1),
                                )
                            }, attempt * 10 ** 3)
                        })
                    } else {
                        setTimeout(() => {
                            this.auth.logout()
                        }, 2500)

                        console.warn(`Cannot fetch initialized account`)
                        this.snackbarManager.error(`An error occurred during login process. Please try later`)
                    }
                }

                try {
                    await ensureAccountIsGenerated()
                } catch (error) {
                    console.error(`Unable ensure account is generated`, error)

                    return this.auth.logout()
                }
            }

            /**
             * Loading profile for the user
             */
            await this.refreshCurrentUserData()
        }
    }

    /**
     * @TODO: Merge initialization methods of Regular and Anonymous users
     */
    protected async initializeAsAnonymousUser(authUser: firebase.User) {
        try {
            this.currentUserSubject.next(
                await this.loadCurrentUserData(),
            )
        } catch (error) {
            this.currentUserSubject.next({
                _id: authUser.uid, firebaseId: authUser.uid, ...anonymousUserData,
            } as unknown as User)
        }
    }

    /**
     * Returns user data from storage
     */
    protected get storedUserData(): User {
        try {
            return JSON.parse(this.storage.getItem(this.USER_STORAGE_KEY)) as User
        } catch (error) {
            console.warn(`Could not get stored user data from the storage`, error)
            return null
        }
    }

    /**
     * Sets UserData object into the storage
     */
    protected set storedUserData(data: User) {
        this.storage.setItem(this.USER_STORAGE_KEY, JSON.stringify(data))
    }

    /**
     * Checks is any user data exists in the storage
     */
    protected get hasStoredData(): boolean {
        return !!this.storedUserData
    }

    /**
     * Deletes stored user from the Storage.
     */
    protected clearStoredData(): void {
        this.storage.removeItem(this.USER_STORAGE_KEY)
    }

    protected async refreshCurrentUserData(): Promise<void> {
        try {
            const data = await this.loadCurrentUserData()

            this.storedUserData = data
            this.currentUserSubject.next(data)
            this.extConnector.updateUserInExt(data)
        } catch (error) {
            console.warn(`UserSession::initializeAsRegularUser ERROR`, error)
            this.snackbarManager.error(`An error occurred during login process. Please try later`)
        }
    }

    protected async loadCurrentUserData(): Promise<User> {
        const user = await this.api.user.profile.getCurrentUserProfile()
        if (!user) {
            // Throws error on the frontend if the server did not return a user profile
            throw new HttpException(`User is not found`, 404)
        }
        return user
    }
}
