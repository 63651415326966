import {
    MeetingSession,
    VideoTile,
    VideoTileState,
} from 'amazon-chime-sdk-js'
import AudioVideoObserver from 'amazon-chime-sdk-js/build/audiovideoobserver/AudioVideoObserver'
import VolumeIndicatorCallback from 'amazon-chime-sdk-js/build/realtimecontroller/VolumeIndicatorCallback'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'

import { Api } from '@undock/api'
import { User } from '@undock/user'
import { CurrentUser } from '@undock/session'
import {
    ChimeAttendee,
    ChimeAttendeeType,
} from '@undock/dock/meet/contracts/conference/chime-attendee'


export class MeetingAttendee {
    /*
     * ------------------------------------------------
     *           MeetingAttendee data streams
     * ------------------------------------------------
     */
    public readonly userStream: ReactiveStream<User>
    public readonly isMutedStream: ReactiveStream<boolean>
    public readonly isVisibleStream: ReactiveStream<boolean>
    public readonly volumeLevelStream: ReactiveStream<number>
    public readonly videoTileStream: ReactiveStream<VideoTile>
    public readonly signalStrengthStream: ReactiveStream<number>
    public readonly isVideoStoppedStream: ReactiveStream<boolean>
    public readonly isContentShareStream: ReactiveStream<boolean>
    public readonly chimeAttendeeStream: ReactiveStream<ChimeAttendee>

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected undockUserSubject = new StatefulSubject<User>()

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected chimeAttendeeSubject = new StatefulSubject<ChimeAttendee>()

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected volumeLevelSubject = new StatefulSubject<number>()

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected videoTileSubject = new StatefulSubject<VideoTile>()

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected signalStrengthSubject = new StatefulSubject<number>()


    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected isMutedSubject = new StatefulSubject<boolean>()

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected isVisibleSubject = new StatefulSubject<boolean>()

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected isVideoStoppedSubject = new StatefulSubject<boolean>()

    @CompleteOnDestroy({ destroyHook: 'onDestroy' })
    protected isContentShareSubject = new StatefulSubject<boolean>()


    @EmitOnDestroy({ destroyHook: 'onDestroy' })
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private readonly _userId: string,
        private readonly _attendeeId: string,
        private readonly _isCurrentUser: boolean,
        protected api: Api,
        protected currentUser: CurrentUser,
        protected meetingSession: MeetingSession,
    ) {
        this.userStream = this.undockUserSubject.asStream()
        this.isMutedStream = this.isMutedSubject.asStream()
        this.videoTileStream = this.videoTileSubject.asStream()
        this.isVisibleStream = this.isVisibleSubject.asStream()
        this.volumeLevelStream = this.volumeLevelSubject.asStream()
        this.chimeAttendeeStream = this.chimeAttendeeSubject.asStream()
        this.signalStrengthStream = this.signalStrengthSubject.asStream()
        this.isVideoStoppedStream = this.isVideoStoppedSubject.asStream()
        this.isContentShareStream = this.isContentShareSubject.asStream()
    }


    public get userId(): string {
        return this._userId
    }

    public get attendeeId(): string {
        return this._attendeeId
    }

    public get isCurrentUser(): boolean {
        return this._isCurrentUser
    }

    public async initialize(): Promise<void> {
        await Promise.all([
            this.initObservers(),
            this.initVideoTile(),
            this.initAttendeeUser(),
            this.initChimeAttendee(),
        ])
    }

    protected async initObservers(): Promise<void> {
        /**
         * @TODO: Do we need this?
         */
        this.isContentShareSubject.next(false)

        const attendeePropertiesObserver: VolumeIndicatorCallback = (
            attendeeId: string, volume: number, muted: boolean, signalStrength: number,
        ) => {
            if (muted !== null) {
                this.isMutedSubject.next(muted)
            }

            if (volume !== null) {
                this.volumeLevelSubject.next(Math.round(volume * 100))
            }

            if (signalStrength !== null) {
                this.signalStrengthSubject.next(Math.round(signalStrength * 100))
            }
        }

        this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(this.attendeeId, attendeePropertiesObserver)

        const videoTilesObserver: AudioVideoObserver = {
            videoTileDidUpdate: async (tileState: VideoTileState) => {
                if (tileState.boundAttendeeId === this.attendeeId) {
                    this.videoTileSubject.next(
                        await this.meetingSession.audioVideo.getVideoTile(tileState.tileId),
                    )

                    this.isVideoStoppedSubject.next(!tileState.active)
                    this.isContentShareSubject.next(tileState.isContent)
                }
            },
            videoTileWasRemoved: async (tileId: number) => {
                let tile = await this.videoTileSubject

                if (!tile || !tile.id()) {
                    /**
                     * It seems that current tile was stopped.
                     * Works only for foreign tiles. Local tile is never removed.
                     */
                    this.isVideoStoppedSubject.next(true)
                }
            },
        }
        this.meetingSession.audioVideo.addObserver(videoTilesObserver)

        this.destroyedEvent.subscribe(() => {
            this.meetingSession.audioVideo.removeObserver(videoTilesObserver)
            this.meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(this.attendeeId)
        })
    }

    protected async initVideoTile(): Promise<void> {
        let tile

        if (this.isCurrentUser) {
            tile = this.meetingSession.audioVideo.getLocalVideoTile()
        } else {
            tile = this.meetingSession.audioVideo.getAllVideoTiles().find(
                tile => tile.state().boundAttendeeId === this.attendeeId,
            )
        }

        if (tile) {
            this.isVideoStoppedSubject.next(!tile.state().active)
        } else {
            this.isVideoStoppedSubject.next(true)
        }

        this.videoTileSubject.next(tile)
    }

    protected async initAttendeeUser(): Promise<void> {
        const attendee = await this.chimeAttendeeSubject

        switch (attendee.type) {
            case ChimeAttendeeType.User:
                this.isVisibleSubject.next(true)
                this.undockUserSubject.next(
                    await this.api.user.profile.getById(attendee.uid),
                )
                break

            case ChimeAttendeeType.Recorder:
                /**
                 * Recorder is a plain Guest user, but should not to be displayed
                 */
                this.isVisibleSubject.next(false)
                this.undockUserSubject.next({} as User)
                break

            case ChimeAttendeeType.GuestUser:
                this.isVisibleSubject.next(true)
                this.undockUserSubject.next(
                    await this.api.user.profile.getGuestById(attendee.uid),
                )
                break

            case ChimeAttendeeType.PSTNCaller:
                this.isVisibleSubject.next(true)
                this.undockUserSubject.next(
                    await this.getUserForPSTNCallerAttendee(),
                )
        }
    }

    protected async initChimeAttendee(): Promise<void> {
        this.chimeAttendeeSubject.next(
            await this.api.meet.conferenceRoom.getAttendeeById(this._attendeeId),
        )
    }

    protected async getUserForPSTNCallerAttendee(): Promise<User> {
        let attendee = await this.chimeAttendeeStream

        let displayName = attendee ?
            attendee.uid.replace('phone#', 'Caller: ') : 'Unknown caller'

        return { displayName } as User
    }

    public onDestroy(): void {
        /* For OnDestroy support */
    }
}
