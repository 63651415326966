import { DeviceInformation } from '@undock/core'

export enum ChimeAttendeeType {
    User = 'User',
    Recorder = 'Recorder',
    GuestUser = 'GuestUser',
    PSTNCaller = 'PSTNCaller'
}

export interface ChimeAttendee {
    uid: string

    dockId: string

    meetingId: string

    type: ChimeAttendeeType

    /**
     * Allows joining the conference from the different browsers or devices
     *
     * @pattern `${os.name}_${os.version}_${browser.name}_${browser.version}`
     */
    deviceToken?: string
    deviceInformation?: DeviceInformation

    JoinToken?: string
    AttendeeId?: string

    /**
     * @pattern `${this.uid}_${uuid()}`
     */
    ExternalUserId?: string
}
