import {
    ActivatedRoute,
    Router,
} from '@angular/router'
import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { Title } from '@angular/platform-browser'
import {
    Config,
    ExtConnector,
    openLinkBlank,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import { TopicsManager } from '@undock/dock/meet/services/topics.manager'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { MeetingsManager } from '@undock/dock/meet/services/meetings.manager'
import { EditMeetingViewModel } from '@undock/dock/meet/ui/pages/edit-meeting/view-models/edit-meeting.view-model'
import { DraftDockFacade } from '@undock/dock/meet/services/facade/draft-dock.facade'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { SettingsFacade } from '@undock/profile/settings/services/facade/settings.facade'
import { NOTES_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/notes.adapter'
import { DockFacadeNotesAdapter } from '@undock/dock/meet/services/adapters/dock-facade-notes.adapter'
import { TOPICS_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'
import { DockFacadeTopicsAdapter } from '@undock/dock/meet/services/adapters/dock-facade-topics.adapter'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { PARTICIPANTS_PROVIDER } from '@undock/dock/meet/contracts/ui-providers/participants.provider'
import { DockFacadeParticipantsProvider } from '@undock/dock/meet/services/data-providers/dock-facade-participants.provider'
import { EditMeetingPage } from '@undock/dock/meet/ui/pages/edit-meeting/edit-meeting.page'
import { default as m } from 'moment'
import { DockIsNotFoundException } from '@undock/dock/meet/exceptions/dock-is-not-found.exception'
import { SidebarViewModel } from '@undock/chrome-extension/modules/sidebar/services/sidebar.view-model'
import { SidebarEditMeetingDateComponent } from '@undock/chrome-extension/modules/sidebar/ui/pages/sidebar-edit-meeting/sidebar-edit-date/sidebar-edit-date.component'
import {
    DockType,
    MeetingMode,
    ScheduleMode,
} from '@undock/dock/meet'
import {
    AnalyticsAction,
    AnalyticsSource,
    AnalyticsTrackedComponent,
    AnalyticsTrackedFeature,
} from '@undock/api/scopes/analytics/analytics.scope'


@Component({
    selector: 'ext-meet-new-meeting',
    templateUrl: 'sidebar-edit-meeting.page.html',
    styleUrls: [
        'sidebar-edit-meeting.page.scss',
        '../../../../../../dock/meet/ui/pages/edit-meeting/edit-meeting.page.scss'
    ],
    providers: [
        TopicsManager,
        EditMeetingViewModel,
        DockParticipantsManager,
        AvailabilityProvider,
        AvailabilityViewModel,
        /**
         * Allows initialization of Draft meeting
         */
        { provide: DockFacade, useClass: DraftDockFacade },
        { provide: NOTES_ADAPTER, useClass: DockFacadeNotesAdapter },
        { provide: TOPICS_ADAPTER, useClass: DockFacadeTopicsAdapter },
        { provide: PARTICIPANTS_PROVIDER, useClass: DockFacadeParticipantsProvider },
    ],
})
export class SidebarEditMeetingPage extends EditMeetingPage implements OnInit {

    @ViewChild('editDate') editDate: SidebarEditMeetingDateComponent

    @Output() toggleChanged = new EventEmitter<boolean>()

    public constructor(
        protected api: Api,
        protected title: Title,
        protected router: Router,
        protected config: Config,
        protected user: CurrentUser,
        protected route: ActivatedRoute,
        protected settings: SettingsFacade,
        protected extConnector: ExtConnector,
        protected snackbarManager: SnackbarManager,
        @Inject(DockFacade)
        protected draftDockFacade: DraftDockFacade,
        protected meetingsManager: MeetingsManager,
        protected sidebarViewModel: SidebarViewModel,
        protected editMeetingVM: EditMeetingViewModel,
        protected confirmService: ConfirmPopupService,
        protected participantsManager: DockParticipantsManager,
        protected availabilityProvider: AvailabilityProvider,
    ) {
        super(
            api, title, router, config, user, route, settings,
            snackbarManager, draftDockFacade, meetingsManager,
            editMeetingVM, confirmService, participantsManager, availabilityProvider
        )
    }

    /**
     * @override
     */
    public async ngOnInit() {
        /**
         * Creating draft meeting
         */
        let dockId = await this.draftDockFacade.createDraftMeeting({
            dates: { start: this.initStartDate, end: this.initEndDate }
        })

        if (dockId) {
            try {
                await this.draftDockFacade.initializeWithDockId(dockId)

                if (this.initStartDate) {
                    this.editMeetingVM.selectAvailabilityDaysCountToDisplay(1)
                    await this.editMeetingVM.selectRangeStartForAvailabilityDisplaying(this.initStartDate, true)
                    if (this.initEndDate) {
                        let newDuration = m(this.initEndDate).diff( m(this.initStartDate), 'minutes')
                        if (newDuration > 0) {
                            await this.editMeetingVM.selectCustomDuration(newDuration)
                        }
                    }
                }
            } catch (error) {
                if (error instanceof DockIsNotFoundException) {
                    /**
                     * Will try to create a new meeting in full web app if not found
                     */
                    return openLinkBlank(`${this.config.clientDomain}meet/new`)
                }

                console.error(error)
                this.snackbarManager.error(`Something went wrong. Please try later.`)
                return this.navigateToTheTimeline()
            }
        }

        /**
         * Init view models
         */
        await Promise.all([
            this.editMeetingVM.initViewModel(),

            this.availabilityProvider.initialize({
                emails: this.participantsManager.participantsEmailStream,
                timeZone: this.editMeetingVM.selectedTimeZoneNameStream,
                dateRange: this.editMeetingVM.loadAvailabilityDatesRangeStream,
                meetingMode: this.editMeetingVM.selectedMeetingModeStream,
                meetingDuration: this.editMeetingVM.meetingDurationForAvailabilityStream,

                rescheduleMeetingId: this.editMeetingVM.currentNonDraftMeetingIdStream,
            }),
        ])

        await Promise.all([
            this.importMeetingModeFromUrl(),
            this.importParticipantsFromUrl(),
            this.syncMeetingTitleWithTabTitle(),
        ])

        const { defaultDuration } = await this.user.settings
        await this.state.selectMeetingDuration(defaultDuration)
    }

    /**
     * @override
     */
    public async onLeaveTheEditPageButtonClicked() {

        const dock = await this.draftDockFacade.currentDockStream

        if (dock.isDraftType) {
            const discardChanges = await this.confirmService.open({
                title: 'Are you sure you want to leave without saving your changes?',
                description: `This action could not be undone`,

                confirmButtonLabel: 'Discard changes',
                discardButtonLabel: 'Back to edit',
            })
            if (discardChanges) {
                /**
                 * Delete draft meeting asynchronously
                 */
                this.draftDockFacade.delete(dock).catch(
                    error => console.log(`Unable delete draft meeting`, error),
                )

                return this.sidebarViewModel.showTimeline()
            }
            return null
        }
    }

    /**
     * @override
     */
    public async navigateToTheTimeline() {
        await this.sidebarViewModel.showTimeline()
        return true
    }

    /**
     * @override
     * Handler for 'Save' button
     */
    public async onCompleteEditingButtonClicked() {
        this.isRequestProcessingSubject.next(true)
        let dock = await this.draftDockFacade.currentDockStream

        if (dock.isDraftType) {
            /**
             * Save Draft changes
             */
            dock = await this.editMeetingVM
                             .saveChangesToTheDraftMeeting(dock)

            await this.api.meet.dock.createFromDraft({
                draftMeetingId: dock.id, requestedMeetingType: DockType.Meeting,
            })

            /**
             * Sending analytics
             */
            try {
                /**
                 * Broadcast analytics
                 */
                if (dock.mode === MeetingMode.Broadcast) {
                    const scheduleMode = await this.state.selectedScheduleModeStream
                    await this.api.analytics.track({
                        event: AnalyticsAction.MeetingProposed,
                        source: AnalyticsSource.WebApp,
                        feature: AnalyticsTrackedFeature.OfficeHours,
                        component: scheduleMode === ScheduleMode.Schedule ?
                            AnalyticsTrackedComponent.Scheduled : AnalyticsTrackedComponent.Instant,
                    })
                } else {
                    /**
                     * Meeting / event analytics
                     */
                    await this.api.analytics.track({
                        event: AnalyticsAction.MeetingProposed,
                        source: AnalyticsSource.WebApp,
                        feature: AnalyticsTrackedFeature.NewEvent,
                        properties: { meetingMode: dock.mode }
                    })
                }
            } catch (error) {
                console.log(`Cannot track analytics`, error)
            }
        }

        if ((await this.state.selectedScheduleModeStream) === ScheduleMode.Instant) {
            /**
             * For instant meetings we should navigate straight to the conference room
             */
            openLinkBlank(`${this.config.clientDomain}meet/${dock.id}/room?skipLobbyPage=true`)

            return true
        }

        this.isRequestProcessingSubject.next(false)
        this.isMeetingCreatedSuccessfullySubject.next(true)

        return true
    }
}
