import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'


@Component({
    selector: 'app-locations-empty-state-icon',
    templateUrl: 'locations-empty-state-icon.component.html',
    styleUrls: ['locations-empty-state-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsEmptyStateIconComponent {}
