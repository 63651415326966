import { ApiContext } from '@undock/api/contracts/api.context'
import { SearchRoute } from '@undock/api/scopes/contacts/routes/search.route'
import { InvitesRoute } from '@undock/api/scopes/contacts/routes/invites.route'
import { ConnectionRoute } from '@undock/api/scopes/contacts/routes/connection.route'

export class ContactsScope {
    public readonly search: SearchRoute
    public readonly invites: InvitesRoute
    public readonly connection: ConnectionRoute

    public constructor(context: ApiContext) {
        this.search = new SearchRoute(context)
        this.invites = new InvitesRoute(context)
        this.connection = new ConnectionRoute(context)
    }
}
