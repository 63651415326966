import { ApiRoute } from '@undock/api/services/api.route'
import {
    MeetingMode,
    MeetingModeLegacy,
} from '@undock/dock/meet'
import { PublicProfileData } from '@undock/user'
import { UserDataInterface } from '@undock/api/scopes/user/contracts/user.interface'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


export class BookingRoute extends ApiRoute {

    public init() {
        this.route = 'meetings/booking-requests'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public createIncoming(request: CreateIncomingBookingRequest): Promise<BookingRequestCreateResult> {
        return this.post<BookingRequestCreateResult>()
                   .withData(request)
                   .endpoint(`incoming/create`)
    }

    public createOutgoingWithCode(request: CreateOutgoingBookingRequestWithCode): Promise<BookingRequestCreateResult> {
        return this.post<BookingRequestCreateResult>()
                   .withData(request)
                   .endpoint(`outgoing/create/code`)
    }

    public getRequestByCode(bookingCode: string): Promise<BookingRequest> {
        return this.get<BookingRequest>()
                   .endpoint(`${bookingCode}`)
    }

    public getRequestDetailsByCode(bookingCode: string): Promise<BookingRequestDetailsResponse> {
        return this.get<BookingRequestDetailsResponse>()
                   .endpoint(`details/${bookingCode}`)
    }

    public addSlot(bookingCode: string, slot: BookingRequestSlot): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .withData({
                       slot: slot
                   })
                   .endpoint(`slot/${bookingCode}`)
    }

    public addParticipants(bookingCode: string, participantEmails: string[]): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .withData({
                       participantEmails: participantEmails
                   })
                   .endpoint(`participants/${bookingCode}`)
    }

    public removeParticipants(bookingCode: string, participantEmails: string[]): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .withData({
                       participantEmails: participantEmails
                   })
                   .endpoint(`participants/remove/${bookingCode}`)
    }

    public confirmIncoming(bookingCode: string, request: ConfirmIncomingBookingRequestRequest): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .withData(request)
                   .endpoint(`incoming/confirm/${bookingCode}`)
    }

    public confirmOutgoing(bookingCode: string, request: ConfirmOutgoingBookingRequestRequest): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .withData(request)
                   .endpoint(`outgoing/confirm/${bookingCode}`)
    }

    public reschedule(bookingCode: string, request: RescheduleBookingRequestRequest): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .withData(request)
                   .endpoint(`reschedule/${bookingCode}`)
    }

    public confirmReschedule(bookingCode: string): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .endpoint(`reschedule/confirm/${bookingCode}`)
    }

    public cancel(bookingCode: string, request: CancelBookingRequestRequest): Promise<BookingRequest> {
        return this.put<BookingRequest>()
                   .withData(request)
                   .endpoint(`cancel/${bookingCode}`)
    }

    public deleteRequest(bookingCode: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(`${bookingCode}`)
    }

    public listPersonalIncomingRequests(): Promise<BookingRequestAggregate[]> {
        return this.get<BookingRequestAggregate[]>()
                   .endpoint(`own/list-incoming`)
    }

    public listPersonalOutgoingRequests(): Promise<BookingRequestAggregate[]> {
        return this.get<BookingRequestAggregate[]>()
                   .endpoint(`own/list-outgoing`)
    }
}

export interface BookingRequestSlot {
    timeStamp: string

    duration: number

    meetingMode?: MeetingMode
}

export interface BookingRequester {
    uid?: string
    email?: string
    displayName?: string
    timeZone?: string
}

export interface BookingRequestRescheduleData {
    rescheduledSlot?: BookingRequestSlot

    rescheduledByEmail?: string
}

export enum BookingRequestStatus {

    PendingConfirm = 'pendingConfirm',

    PendingReschedule = 'pendingReschedule',

    Confirmed = 'confirmed',

    Cancelled = 'cancelled'
}

export interface CreateIncomingBookingRequest {
    profileUrl: string,

    slots: BookingRequestSlot[]

    timeZone?: string

    scheduleId?: string

    participantEmails?: string[]
}

export interface CreateOutgoingBookingRequestWithCode extends CreateIncomingBookingRequest {
    bookingCode: string
}

export interface ConfirmIncomingBookingRequestRequest {
    meetingDate: string,

    requester?: {
        email?: string,
        displayName?: string,
        timeZone?: string
    }
}

export interface ConfirmOutgoingBookingRequestRequest {
    meetingDate: string,

    confirmedBy?: {
        email?: string,
        displayName?: string,
        timeZone?: string
    }
}

export interface RescheduleBookingRequestRequest {
    requester: {
        email: string,
        displayName?: string,
        timeZone?: string
    }
    slot: BookingRequestSlot
    message?: string
}

export interface CancelBookingRequestRequest {
    requester: {
        email: string,
        displayName?: string,
        timeZone?: string
    }
    message?: string
}

export interface BookingRequestCreateResult {
    bookingCode: string
    timeZone: string
    bookingSlots: BookingRequestSlot[]
    participantEmails: string[]
    bookingLink: string
}

export interface BookingRequest {
    userData?: PublicProfileData
    bookingCode: string
    participantEmails: string[]
    bookingSlots: BookingRequestSlot[]
    status?: BookingRequestStatus
    meetingDate?: string
    requester?: BookingRequester,
    confirmedBy?: BookingRequester,
    scheduleId?: string,
    rescheduleData?: BookingRequestRescheduleData
    // TODO: once we move to using different modes and durations of slots this won't work
    /** @deprecated **/ meetingDuration?: number
    /** @deprecated **/ meetingMode?: MeetingMode
    /** @deprecated **/ legacyMeetingMode?: MeetingModeLegacy
    // TODO: once we move to using booking slots as reserved slots this won't work
    /** @deprecated **/ reservedSlots?: AvailabilitySlot[]
}

export interface BookingRequestDetailsResponse {
    request: BookingRequest,
    reservedSlots: AvailabilitySlot[],
    userData?: PublicProfileData,
    participantEmails?: string[],
    bookingLinks?: string[],
    bookingLink?: string
}

export interface BookingRequestAggregate {
    ownerId: string
    bookingCode: string

    type: string
    slots: BookingRequestSlot[]
    status: BookingRequestStatus

    owner: UserDataInterface
    participants: UserDataInterface[]

    expiresAt: Date
    createdAt: Date
    updatedAt: Date
}
