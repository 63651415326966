import {
    Directive,
    ElementRef,
} from '@angular/core'

@Directive({
    selector: '[udButton]',
})
export class ButtonDirective {

    constructor(private elementRef: ElementRef) {
        this?.elementRef?.nativeElement?.classList?.add('ud-button')
    }
}
