/**
 * Function used for merging object including all nested objects
 *
 * @Examples:
 *
 * Supports $unset command for object keys:
 *   target = {
 *       firstProp: 'a',
 *       secondProp: ['b'],
 *       thirdProp: {'d': 1, 'e': 2},
 *   }
 *
 *   patch = {
 *       $unset: ['firstProp', 'secondProp'],
 *       thirdProp: {
 *           $unset: 'e'
 *       },
 *   }
 *
 *   result = {
 *       thirdProp: { 'd': 1 },
 *   }
 *
 * Supports commands for patching keys
 *     '$add': will add an element or elements array to the target array
 *     '$unset': will remove an element or elements array from the target array
 *
 *   target = {
 *       simpleProp: '1',
 *       arrayProp: ['a', 'b', 'c'],
 *   }
 *
 *   patch = {
 *       simpleProp: '2',
 *       arrayProp: {
 *           '$add': ['d', 'e'],
 *           '$unset': ['b']
 *       },
 *   }
 *
 *   result = {
 *       simpleProp: '2',
 *       arrayProp: ['a', 'c', 'd', 'e'],
 *   }
 *
 * @TODO: Write complex tests for this function
 */
export function patchObject<T extends Object, P extends Object>(target: T, patch: T & P): T & P {
    for (let key in patch) {
        if (patch.hasOwnProperty(key)) {

            if (key === '$unset') {
                let properties = patch[key]
                if (!Array.isArray(properties)) {
                    properties = [properties]
                }

                properties.forEach(property => {
                    if (target[property]) {
                        delete target[property]
                    }
                })

                continue
            }

            /**
             * Will merge if both items are objects
             */
            if (typeof target[key] === 'object' && typeof patch[key] === 'object') {
                /**
                 * Both objects are arrays
                 */
                if (Array.isArray(target[key]) && Array.isArray(patch[key])) {

                    target[key] = target[key].concat(patch[key])

                    continue
                }

                /**
                 * If patch for array is Object
                 */
                if (Array.isArray(target[key])) {
                    /**
                     *
                     */
                    for (let command in patch[key]) {
                        if (patch[key].hasOwnProperty(command)) {

                            const value = patch[key][command]

                            switch (command) {
                                case '$add':
                                    target[key] = target[key].concat(value)
                                    break

                                case '$unset':
                                    if (Array.isArray(value)) {
                                        target[key] = target[key].filter(
                                            i => !value.includes(i),
                                        )
                                    } else {
                                        target[key] = target[key].filter(i => i !== value)
                                    }
                            }
                        }
                    }

                    continue
                }

                /**
                 * Merge object properties
                 */
                patchObject(target[key], patch[key])

            } else {
                target[key] = patch[key]
            }
        }
    }

    return target as T & P
}
