import { Injectable } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { AuthManager } from '@undock/auth/services/auth.manager'


@Injectable()
export class LoggedInGuard implements CanActivate {

    public static readonly DATA_KEY = 'LoggedInGuard'
    public static readonly MODE_LOGIN = 'mode-login'
    public static readonly MODE_SIGNUP = 'mode-signup'

    public constructor(
        private authManager: AuthManager,
    ) {}

    public canActivate(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {

        const mode = next.data[LoggedInGuard.DATA_KEY]?.mode ?? LoggedInGuard.MODE_LOGIN

        return this.authManager.isLoggedInStream.pipe(
            tap(async (loggedIn) => {
                if (!loggedIn) {
                    switch (mode) {
                        case LoggedInGuard.MODE_LOGIN:
                            return this.authManager.login(state.url)

                        case LoggedInGuard.MODE_SIGNUP:
                            return this.authManager.signUp(state.url)
                    }
                }
            }),
        )
    }
}
