<div class="meeting-duration-select-button-container"
     (clickOutside)="closeValuesList()"
     [style.width]="width" [style.height]="height"
>
    <div class="meeting-duration-edit-icon-box" [class.values-toggled]="showValuesToggled"
         (click)="toggleValuesList($event)">
        <span class="material-icons">edit</span>
    </div>

    <div class="meeting-duration-selected-label" [class.values-toggled]="showValuesToggled"
         (click)="toggleValuesList($event)">
        <div [style.font-size]="fontSize" [style.font-weight]="fontWeight">
            {{label.split(' ')[0]}}
        </div>
        <div [style.font-weight]="fontWeight" style="font-size: 1.4rem;">
            <span *ngFor="let str of label.split(' '); index as i"><span *ngIf="i > 0">{{str}}</span>&nbsp;</span>
        </div>

        <div class="meeting-duration-values-container"
             *ngIf="options && options?.length > 0" [style.display]="dropDownShowing ? 'flex' : 'none'"
        >
            <div class="meeting-duration-values-list">
                <ng-container *ngFor="let option of options">
                    <div class="meeting-duration-values-item"

                         [attr.data-value]="option.value"
                         [class.item-disabled]="option.disabled"
                         [class.item-selected]="option.value === value"

                         (click)="selectValue($event, option.value)"
                    >
                        <span class="meeting-duration-values-item-label">{{option.label}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

