<div class="on-off-select-button-container" (clickOutside)="dropDownShowing = false"
     [style.width]="width" [style.height]="height">

    <div class="icon-button-icon-box" [ngClass]="{
        'icon-left': position === 'left',
        'icon-right': position === 'right'
      }" [class.off-toggled]="offToggled" (click)="toggleOff()">

        <span>{{ offLabel }}</span>

    </div>

    <div class="icon-button-label" [class.icon-left]="position === 'left'" [class.icon-right]="position === 'right'"
         [class.off-toggled]="offToggled" (click)="showDropDown($event)">
        <span [style.font-size]="fontSize" [style.font-weight]="fontWeight">{{label}}</span>
    </div>

    <!-- DROP DOWN -->
    <div *ngIf="values && values.length > 0" class="icon-button-dropdown-container"
         [style.display]="dropDownShowing ? 'flex' : 'none'">

        <div class="icon-button-dropdown-list">

            <div *ngFor="let value of values" class="icon-button-dropdown-item" [attr.data-value]="value.value"
                 [class.item-selected]="value.value === currentValue" (click)="selectValue($event, value.value)">
                <span class="icon-button-dropdown-item-label">
                    {{value.label}}
                </span>
            </div>

        </div>

    </div>

</div>

