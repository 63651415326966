import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'


@Component({
    selector: 'app-text-switch-toggle',
    templateUrl: 'text-switch-toggle.component.html',
    styleUrls: [`text-switch-toggle.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextSwitchToggleComponent {


    @Input() leftValue: any
    @Input() rightValue: any

    @Input() leftLabel: string
    @Input() rightLabel: string
    @Input() leftIcon: string
    @Input() rightIcon: string

    // @Input() fontSize: string = '16px'
    // @Input() iconSize: string = '4rem'

    @Input() height: string = '40px'

    @Input() value: any
    @Output() valueChange = new EventEmitter<any>()
    @Output() valueSelected = new EventEmitter<any>()

    public emitValue(option: 'left' | 'right') {
        let value = option === 'left' ? this.leftValue : this.rightValue
        this.valueChange.emit(value)
        this.valueSelected.emit(value)
    }
}
