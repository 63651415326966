import {
    Directive,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core'

import { CurrentUser } from '@undock/session'


@Directive({
    selector: '[restrictGuest]',
})
export class RestrictGuestDirective implements OnInit {
    public constructor(
        private currentUser: CurrentUser,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    public ngOnInit() {
        this.viewContainer.createEmbeddedView(this.templateRef)

        this.currentUser.isAnonymous.then(isGuest => {
            if (isGuest) {
                this.viewContainer.clear()
            }
        })
    }
}
