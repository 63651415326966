import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { Config } from '@undock/core'
import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

@Component({
    selector: 'app-changelog-widget',
    templateUrl: 'changelog-widget.component.html',
    styleUrls: ['changelog-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogWidgetComponent implements AfterViewInit {

    @CompleteOnDestroy()
    public displayWidgetStream = new ValueSubject(true)

    public constructor(
        private config: Config,
    ) { }

    ngAfterViewInit() {
        this.initializeChangelogWidget()
    }

    public initializeChangelogWidget() {
        if (this.canny && (this.config.enableCannySDK)) {
            this.canny('initChangelog', {
                appID: this.config.cannyAppId,
                position: 'top',
                align: 'left',
            })
        } else {
            this.displayWidgetStream.next(false)
        }
    }

    protected get canny(): any {
        return window['Canny'] = window['Canny'] || []
    }
}
