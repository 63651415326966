/**
 * Used only for Broadcast feature
 */
export enum DockVisibility {
    /**
     * Means the event is visible for all contacts
     */
    Connections = 'Connections',

    /**
     * Default visibility status
     */
    Participants = 'Participants',
}
