import { Injectable } from '@angular/core'
import { ReactiveStream } from '@typeheim/fire-rx'

import {
    Model,
    HasAttachments,
} from '@undock/core'
import { TopicsAdapterInterface } from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'


@Injectable()
export class EditEventTopicsAdapter implements TopicsAdapterInterface {

    public readonly isEditModeStream: ReactiveStream<boolean>
    public readonly ownerModelStream: ReactiveStream<Model & HasAttachments>

    public constructor(
        private readonly eventFormStateModel: EventFormStateModel,
    ) {
        this.isEditModeStream = this.eventFormStateModel.state.isEditModeStream
        this.ownerModelStream = this.eventFormStateModel.state.relatedDockStream
    }
}
