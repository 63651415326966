import {
    Input,
    Output,
    Component,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core'


@Component({
    selector: 'app-time-slider',
    templateUrl: 'time-slider.component.html',
    styleUrls: ['time-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSliderComponent {

    @Input() readonly showGrid: boolean
    @Input() readonly workdayEnd: number
    @Input() readonly workdayStart: number

    @Output() readonly onWorkdayEndChanged = new EventEmitter<number>()
    @Output() readonly onWorkdayStartChanged = new EventEmitter<number>()

    get selectedTimesClass(): string {
        return `start-${
            Math.floor(this.workdayStart)
        }-hours-${
            Math.floor(this.workdayEnd - this.workdayStart)
        }`
    }

    readonly computeDragRenderPosition = async (pos: any, dragRef: any): Promise<void> => {
        const rootEl = dragRef._rootElement
        const parentEl = rootEl.parentElement
        const parentRect = parentEl.getBoundingClientRect()

        const hours = ((pos.x - parentRect.x) / parentEl.offsetWidth) * 24
        const hoursFloor = Math.floor(hours)
        const hoursFraction = hours - hoursFloor

        let hour = hoursFloor
        if (hoursFraction >= 0.66) {
            hour = hoursFloor + 1
        } else if (hoursFraction >= 0.33) {
            hour = hoursFloor + 0.5
        }

        if (rootEl.classList.contains('start') && (hour < this.workdayEnd && hour >= 0)) {
            this.onWorkdayStartChanged.emit(hour)
        } else if (rootEl.classList.contains('end') && (hour > this.workdayStart && hour <= 24)) {
            this.onWorkdayEndChanged.emit(hour)
        }
    }

    public renderTime(time: number): string {
        const rawHour = Math.floor(time)
        const hour = rawHour > 0
            ? rawHour > 12
                ? rawHour - 12
                : rawHour
            : rawHour + 12
        const minute = time - rawHour == 0 ? '00' : '30'
        const ampm = rawHour >= 12 && rawHour < 24
            ? 'pm'
            : 'am'
        return `${hour}:${minute}${ampm}`
    }
}
