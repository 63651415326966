import {
    Component,
    Input,
} from '@angular/core'
import { UiConnectionData } from '@undock/people/services/facades/connections.facade'

@Component({
    selector: 'app-time-search-people-result-item',
    templateUrl: './people-search-result-item.component.html',
    styleUrls: ['./people-search-result-item.component.scss'],
})
export class PeopleSearchResultItemComponent {

    public readonly iconColor = '#2B2733'

    @Input() connection: UiConnectionData

}
