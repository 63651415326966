<svg class="logo-button" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
     viewBox="0 0 105 13" fill="none">
    <path d="M1.90819 0.18545H0V7.58488C0 10.7375 1.8715 13 5.48605 13C9.1373 13 11.0271 10.4964 11.0271 7.58488V0.18545H9.11895V7.43652C9.11895 9.60628 7.96303 11.2011 5.48605 11.2011C3.04577 11.2011 1.90819 9.71755 1.90819 7.45506V0.18545Z"
          [attr.fill]="color"/>
    <path d="M28.796 12.796H30.4657V0.18545H28.5575V9.75464L20.6312 0.18545H18.9432V12.796H20.8514V3.26391L28.796 12.796Z"
          [attr.fill]="color"/>
    <path d="M38.5633 12.796H43.6457C47.3337 12.796 50.0859 10.5706 50.0859 6.50927C50.0859 2.41084 47.3337 0.203995 43.6457 0.18545H38.5633V12.796ZM43.5723 1.87304C46.2328 1.89158 48.1226 3.50499 48.1226 6.50927C48.1226 9.47646 46.2328 11.1084 43.5723 11.1084H40.4715V1.87304H43.5723Z"
          [attr.fill]="color"/>
    <path d="M56.6241 6.50927C56.6241 10.1997 59.3763 12.9815 63.0275 12.9815C66.7155 12.9815 69.4677 10.1997 69.4677 6.50927C69.4677 2.78174 66.7155 0 63.0275 0C59.3763 0 56.6241 2.78174 56.6241 6.50927ZM58.4956 6.50927C58.4956 3.82026 60.4038 1.83595 63.0275 1.83595C65.688 1.83595 67.5778 3.82026 67.5778 6.50927C67.5778 9.1612 65.688 11.1641 63.0275 11.1641C60.4038 11.1641 58.4956 9.1612 58.4956 6.50927Z"
          [attr.fill]="color"/>
    <path d="M77.9024 6.49073C77.9024 3.8388 79.7923 1.79886 82.4344 1.79886C84.1591 1.79886 85.4802 2.44793 86.3976 3.301L87.315 1.92867C86.0673 0.667618 84.3793 0 82.4344 0C78.7281 0 75.9392 2.80029 75.9392 6.49073C75.9392 10.1812 78.7281 12.9815 82.4344 12.9815C84.3793 12.9815 86.0673 12.3138 87.315 11.0528L86.3976 9.68046C85.4802 10.5335 84.1591 11.1826 82.4344 11.1826C79.7923 11.1826 77.9024 9.14265 77.9024 6.49073Z"
          [attr.fill]="color"/>
    <path d="M94.4316 12.796H96.3397V7.43652H97.881L102.725 12.796H105V12.5178L99.5323 6.58345L104.486 0.482169V0.18545H102.505L97.8993 5.73039H96.3397V0.18545H94.4316V12.796Z"
          [attr.fill]="color"/>
</svg>
