import { Injectable } from '@angular/core'

import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import { from } from 'rxjs'

import {
    Config,
    Memoize,
} from '@undock/core'
import { ValuesSelectorOption } from '@undock/common/ui-kit'
import { MeetingMode } from '@undock/dock/meet'

export enum MeetingModeExtended {
    Video = 'Video',
    Audio = 'Audio',
    InPerson = 'InPerson',
    Broadcast = 'Broadcast',
}

@Injectable()
export class MeetingModeOptionsProvider {

    protected meetingModeLabelsMap = {
        [MeetingModeExtended.Video]: 'Video',
        [MeetingModeExtended.Audio]: 'Audio Only',
        [MeetingModeExtended.InPerson]: 'In Person',
        [MeetingModeExtended.Broadcast]: 'Broadcast',
    }

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private config: Config,
    ) {}

    public get availableMeetingModeOptions(): ValuesSelectorOption<MeetingMode>[] {
        return [
            { label: this.meetingModeLabelsMap[MeetingMode.Video], value: MeetingMode.Video, disabled: false },
            { label: this.meetingModeLabelsMap[MeetingMode.InPerson], value: MeetingMode.InPerson, disabled: false },
        ]
    }

    public get availableExtendedMeetingModeOptions(): ValuesSelectorOption<MeetingModeExtended>[] {
        return [
            { label: this.meetingModeLabelsMap[MeetingModeExtended.Video], value: MeetingModeExtended.Video, disabled: false },
            { label: this.meetingModeLabelsMap[MeetingModeExtended.Audio], value: MeetingModeExtended.Audio, disabled: false },
            { label: this.meetingModeLabelsMap[MeetingModeExtended.InPerson], value: MeetingModeExtended.InPerson, disabled: false },
            { label: 'Room', value: MeetingModeExtended.Broadcast, disabled: false },
        ]
    }

    @Memoize()
    public get availableMeetingModeOptionsStream(): ReactiveStream<ValuesSelectorOption<MeetingModeExtended>[]> {
        return new ReactiveStream<ValuesSelectorOption<MeetingModeExtended>[]>(
            from(Promise.resolve()).pipe(
                takeUntil(this.destroyedEvent),
                map(() => this.availableMeetingModeOptions),
            ),
        )
    }

    @Memoize()
    public get availableExtendedMeetingModeOptionsStream(): ReactiveStream<ValuesSelectorOption<MeetingModeExtended>[]> {
        return new ReactiveStream<ValuesSelectorOption<MeetingModeExtended>[]>(
            from(Promise.resolve()).pipe(
                takeUntil(this.destroyedEvent),
                map(() => this.availableExtendedMeetingModeOptions),
            ),
        )
    }
}
