import { Component } from '@angular/core'
import { AsyncStream } from '@typeheim/fire-rx'
import { CurrentUser } from '@undock/session'
import { fadeAnimation } from '@undock/dock/meet/ui/components/dock-notifications/shared-animations/fade.animation'
import { Title } from '@angular/platform-browser'


@Component({
    selector: 'app-page-not-found',
    templateUrl: 'page-not-found.component.html',
    styleUrls: ['page-not-found.component.scss'],
    animations: [fadeAnimation],
})
export class PageNotFoundComponent {

    public readonly isLoggedInStream: AsyncStream<boolean>

    constructor(
        private title: Title,
        private currentUser: CurrentUser,
    ) {
        this.isLoggedInStream = this.currentUser.isLoggedInStream
    }

    public ngOnInit(): void {
        this.title.setTitle(`Undock | Not Found`)
    }
}
