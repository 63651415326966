import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'

/**
 * Undock modules
 */
import { CoreModule } from '@undock/core/core.module'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'

/**
 * ------------------------------------------------------------
 *                      Components
 * ------------------------------------------------------------
 */
import { AvatarComponent } from '@undock/user/components/avatar/avatar.component'
import {
    ContactsSearchAdapter,
    ContactsSearchComponent,
    UserSearchAdapter,
} from '@undock/user/components/contacts-search/contacts-search.component'

/**
 * ------------------------------------------------------------
 *                      Providers
 * ------------------------------------------------------------
 */
import { PrivacyManager } from '@undock/user/services/privacy.manager'
import { SettingsManager } from '@undock/user/services/settings.manager'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'

/**
 * ------------------------------------------------------------
 *                       Guards
 * ------------------------------------------------------------
 */
import { AccountInitializedGuard } from '@undock/user/routing/guards/account-initialized.guard'


@NgModule({
    imports: [
        CoreModule,
        UiKitModule,
    ],
    exports: [
        AvatarComponent,
        ContactsSearchComponent,
    ],
    providers: [
        { provide: UserSearchAdapter, useClass: ContactsSearchAdapter },
    ],
    declarations: [
        AvatarComponent,
        ContactsSearchComponent,
    ],
})
export class UserProvider {
    // Bootstrapping singletons
    constructor(private usersProvider: ProfilesProvider) {}

    static forRoot(): ModuleWithProviders<UserProvider> {
        return {
            ngModule: UserProvider,
            providers: [
                PrivacyManager,
                SettingsManager,

                /**
                 * Guards
                 */
                AccountInitializedGuard,
            ],
        }
    }
}
