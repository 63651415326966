import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-hashtag-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4122 2.08283C11.458 1.67115 11.1613 1.30033 10.7497 1.25459C10.338 1.20885 9.96716 1.5055 9.92142 1.91718L9.5511 5.25004H7.06033L7.41224 2.08283C7.45798 1.67115 7.16133 1.30033 6.74965 1.25459C6.33797 1.20885 5.96716 1.5055 5.92142 1.91718L5.5511 5.25004L2.00016 5.25004C1.58595 5.25004 1.25016 5.58583 1.25016 6.00004C1.25016 6.41426 1.58595 6.75004 2.00016 6.75004L5.38443 6.75004L5.10665 9.25004H1.3335C0.919283 9.25004 0.583496 9.58583 0.583496 10C0.583496 10.4143 0.919282 10.75 1.3335 10.75H4.93999L4.58808 13.9172C4.54234 14.3289 4.83899 14.6997 5.25067 14.7454C5.66235 14.7912 6.03317 14.4945 6.07891 14.0828L6.44922 10.75L8.93999 10.75L8.58808 13.9172C8.54234 14.3289 8.83899 14.6997 9.25067 14.7454C9.66235 14.7912 10.0332 14.4945 10.0789 14.0828L10.4492 10.75H13.3335C13.7477 10.75 14.0835 10.4143 14.0835 10C14.0835 9.58583 13.7477 9.25004 13.3335 9.25004H10.6159L10.8937 6.75004L14.0002 6.75004C14.4144 6.75004 14.7502 6.41426 14.7502 6.00004C14.7502 5.58583 14.4144 5.25004 14.0002 5.25004H11.0603L11.4122 2.08283ZM9.10665 9.25004L9.38443 6.75004H6.89366L6.61588 9.25004L9.10665 9.25004Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class HashtagIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 22
    @Input() height: string | number  = 22
    @Input() color: string = '#2B2733'
}
