import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core'


@Directive({
    selector: '[clickOutside]',
})
export class ClickOutsideDirective implements OnChanges {
    @Input('clickOutsideEnabled')
    public clickOutsideEnabled: boolean = true

    @Input('clickOutsideListenerDelay')
    public clickOutsideListenerDelay: number = 0

    @Output() clickOutside = new EventEmitter()
    protected lastExecution = null

    public constructor(
        private elementRef: ElementRef,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.clickOutsideEnabled && changes?.clickOutsideEnabled.currentValue === false) {
            this.lastExecution = null
        }
    }

    checkOneContainsAnother(a, b) {
        return !(
            b.left < a.left ||
            b.top < a.top ||
            b.right > a.right ||
            b.bottom > a.bottom
        )
    }

    checkOneTouchesAnother(a, b) {
        return (
            !(a.left >= b.right || b.left >= a.right) &&
            !(a.top >= b.bottom || b.top >= a.bottom)
        )
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        if (!this.clickOutsideEnabled || this.elementRef.nativeElement.contains(targetElement)) {
            // stop execution if still inside of container or directive disabled
            return
        }
        // @todo - need to polish this approach
        // else if (this.checkOneContainsAnother(this.elementRef.nativeElement.getBoundingClientRect(), targetElement.getBoundingClientRect())) {
        //     // stop execution if target container visually on top of parent
        //     return
        // }

        if (this.clickOutsideListenerDelay) {
            if (!this.lastExecution) {
                this.lastExecution = new Date()
            } else {
                let now = new Date()
                let seconds = (now.getTime() - this.lastExecution.getTime()) / 1000
                if (seconds >= this.clickOutsideListenerDelay) {
                    this.clickOutside.emit(null)
                } else {
                    this.lastExecution = new Date()
                }
            }
        } else {
            this.clickOutside.emit(null)
        }
    }
}
