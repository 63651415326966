import { ApiContext } from '@undock/api/contracts/api.context'
import { RsvpRoute } from '@undock/api/scopes/calendar/routes/rsvp.route'
import { EventsRoute } from '@undock/api/scopes/calendar/routes/events.route'
import { TimelineRoute } from '@undock/api/scopes/calendar/routes/timeline.route'
import { SettingsRoute } from '@undock/api/scopes/calendar/routes/settings.route'
import { ProfileRoute } from '@undock/api/scopes/calendar/routes/profile.route'


export class CalendarScope {

    public readonly rsvp: RsvpRoute
    public readonly events: EventsRoute
    public readonly profile: ProfileRoute
    public readonly timeline: TimelineRoute
    public readonly settings: SettingsRoute

    public constructor(context: ApiContext) {
        this.rsvp = new RsvpRoute(context)
        this.events = new EventsRoute(context)
        this.profile = new ProfileRoute(context)
        this.timeline = new TimelineRoute(context)
        this.settings = new SettingsRoute(context)
    }
}
