import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'

@Component({
    selector: 'app-icon-meeting-cancelled',
    templateUrl: 'meeting-cancelled.component.html',
    styleUrls: ['meeting-cancelled.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingCancelledIconComponent {}
