import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-element-status-checked',
    templateUrl: 'element-status-checked-icon.component.html',
    styleUrls: ['element-status-checked-icon.component.scss'],
    host : {
        '[style.border-radius]' : 'borderRadius'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementStatusCheckedIconComponent {
    @Input() height: number|string = '18'
    @Input() width: number|string = '18'
    @Input() color: string = '#FFFFFF'
    @Input() background: string = '#2B2733'
    @Input() borderColor: string = ''
    @Input() borderRadius: string = '50%' // not working here, used as future reference of self-binding
}
