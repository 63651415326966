import { PublicProfileData } from '@undock/user'
import { MeetingMode } from '@undock/dock/meet/contracts/dock/meeting-mode'
import { DockParticipantStatus } from '@undock/dock/meet/models/dock/dock-participant.model'
import { EventAttendee } from '@undock/api/scopes/time/contracts/timeline-event/event-attendee.interface'

export enum TimelineDirection {
    History = -1,
    Future = 1
}

export interface TimelineEventConfig {
    display?: boolean
    color?: string
    icon?: string
}

export interface TimelineEvent {
    /**
     * Id of CalendarEvent
     */
    id: string

    dockId: string
    /** @deprecated */
    uid?: string // Deprecated alias for `dockId`

    type: MeetingMode
    title: string
    agenda: string

    location: string

    /**
     * @TODO: Rework timeline to use it instead of `participants`
     */
    attendees: EventAttendee[]

    /**
     * This property will be removed soon
     *
     * @deprecated
     */
    participants: TimelineEventParticipant[]

    end: string
    start: string
    allDay: boolean

    iCalUId: string
    calendar: string
    provider: string
    providerId?: string

    calendarId?: string
    calendarEmail?: string
    calendarTitle?: string

    /**
     * @TODO: Remove from this place
     */
    calendarConfig?: TimelineEventConfig

    isOwner?: boolean
    isOrganizer?: boolean
    isNonBlocking?: boolean
    isAccessRestricted?: boolean

    /**
     * Contains a handle of dockLink
     */
    dockKey: string
    /**
     * Contains a link to the Undock conference
     */
    dockLink: string
    /**
     * Will contain a direct link to the Undock agenda
     */
    agendaLink?: string
    /**
     * Contains a link to preferred conference solution
     */
    conferenceLink: string

    /**
     * Contains all information about available conference solutions
     */
    conferenceDetails: TimelineEventConferenceDetails


    editingAllowed?: boolean

    /**
     * For Plans
     */
    generatedById?: string
    isAutoGenerated?: boolean

    createdAt?: Date


    /**
     * In Person locations
     */

    inPersonLocation?: string
    inPersonLocationUrl?: string


    /** @deprecated */
    topics?: TimelineEventTopic[]

    /** @deprecated */
    privateNotes?: string
}

export enum ConferenceProviders {
    Undock = 'undock',
    Zoom = 'zoom',
    Skype = 'skype',
    Teams = 'teams',
    GMeet = 'gmeet',
    Custom = 'custom',
}

export interface TimelineEventConferenceDetails {
    preferred: ConferenceProviders
    undock: { location: string }
    zoom?: { location: string }
    skype?: { location: string }
    teams?: { location: string }
    gmeet?: { location: string }
    custom?: { location: string }
}

export interface TimelineEventParticipant {
    status: DockParticipantStatus
    userData: PublicProfileData

    /** @deprecated */ fullname?: string
    /** @deprecated */ firstname?: string
    /** @deprecated */ emailAddress: string

    addedBy: string
    isOwner: boolean
    timeZone: string
    isUndockUser: boolean

    /** @deprecated */ denied?: boolean
    /** @deprecated */ deleted?: boolean
    /** @deprecated */ pending?: boolean
    /** @deprecated */ accepted?: boolean
    /** @deprecated */ tentative?: boolean
}

/**
 * @deprecated
 */
export interface TimelineEventTopic {
    id: string
    text: string
    assignedUserId: string
    authorId: string
    complete: string
    position: number
    removed: boolean
}

export interface TimelineItem {
    /**
     * @deprecated
     */
    uid?: string
    id: string
    date: string
    blankDay?: boolean
    isToday?: boolean
    isOver?: boolean
    startOfDay?: boolean
    endOfDay?: boolean
    eventsCount?: number
    timelineEvent?: TimelineEvent
    allDayEvents?: TimelineItem[]
}

export interface ScrollItem {
    id: number
    timelineItem?: TimelineItem
    actual?: boolean
    first?: boolean
    last?: boolean
    next?: boolean
    firstOnDate?: boolean
    lastOnDate?: boolean
    empty?: boolean
    hidden?: boolean
    date?: string
}

export interface TimelineItemMap {
    [key: string]: TimelineItem[]
}

export interface FocusedTimelineItem {
    /**
     * @deprecated
     */
    uid?: string
    id: string
    tabIndex: number
}

export interface TimelineItemStateDataSource {
    nextEvent: TimelineEvent
    currentEvents: TimelineEvent[]
    lastCurrentEvent: TimelineEvent
    focusedTimelineItem: FocusedTimelineItem
}
