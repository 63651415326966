import { ApiContext } from '@undock/api/contracts/api.context'
import { ChannelsRoute } from '@undock/api/scopes/organizations/routes/channels.route'
import { FormsRoute } from '@undock/api/scopes/organizations/routes/forms.route'
import { IndexRoute } from '@undock/api/scopes/organizations/routes/index.route'
import { InvitesRoute } from '@undock/api/scopes/organizations/routes/invites.route'
import { MembersRoute } from '@undock/api/scopes/organizations/routes/members.route'
import { OwnRoute } from '@undock/api/scopes/organizations/routes/own.route'
import { PublicRoute } from '@undock/api/scopes/organizations/routes/public.route'
import { SchedulesRoute } from '@undock/api/scopes/organizations/routes/schedules.route'

export class OrganizationsScope extends IndexRoute {
    public readonly channels: ChannelsRoute
    public readonly forms: FormsRoute
    public readonly invites: InvitesRoute
    public readonly members: MembersRoute
    public readonly own: OwnRoute
    public readonly public: PublicRoute
    public readonly schedules: SchedulesRoute

    public constructor(
        context: ApiContext,
    ) {
        super(context)
        this.channels = new ChannelsRoute(context)
        this.forms = new FormsRoute(context)
        this.invites = new InvitesRoute(context)
        this.members = new MembersRoute(context)
        this.own = new OwnRoute(context)
        this.public = new PublicRoute(context)
        this.schedules = new SchedulesRoute(context)
    }
}
