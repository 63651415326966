<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70 124C99.8225 124 124 99.8701 124 70C124 40.1299 99.716 16 70 16C40.1775 16 16 40.1299 16 70C16 99.8701 40.1775 124 70 124Z" fill="#F3F3F9"/>
    <rect x="19.5" y="35.5" width="97" height="67" rx="7.5" fill="url(#paint0_linear_5417_64394)"/>
    <rect x="19" y="35" width="98" height="12" fill="#EDEAF2"/>
    <path d="M25.1731 42.1643C26.0869 42.1643 26.8276 41.4197 26.8276 40.5011C26.8276 39.5825 26.0869 38.8379 25.1731 38.8379C24.2593 38.8379 23.5186 39.5825 23.5186 40.5011C23.5186 41.4197 24.2593 42.1643 25.1731 42.1643Z" fill="#9A95AC"/>
    <path d="M30.5823 42.1643C31.4961 42.1643 32.2368 41.4197 32.2368 40.5011C32.2368 39.5825 31.4961 38.8379 30.5823 38.8379C29.6685 38.8379 28.9277 39.5825 28.9277 40.5011C28.9277 41.4197 29.6685 42.1643 30.5823 42.1643Z" fill="#9A95AC"/>
    <path d="M35.9592 42.1643C36.873 42.1643 37.6138 41.4197 37.6138 40.5011C37.6138 39.5825 36.873 38.8379 35.9592 38.8379C35.0455 38.8379 34.3047 39.5825 34.3047 40.5011C34.3047 41.4197 35.0455 42.1643 35.9592 42.1643Z" fill="#9A95AC"/>
    <path d="M68.6254 82.125L63.7817 86.9688C63.4333 87.3183 63.0193 87.5957 62.5635 87.785C62.1077 87.9743 61.619 88.0717 61.1254 88.0717C60.6319 88.0717 60.1432 87.9743 59.6873 87.785C59.2315 87.5957 58.8176 87.3183 58.4692 86.9688L55.0317 83.5312C54.6821 83.1829 54.4047 82.7689 54.2154 82.3131C54.0261 81.8573 53.9287 81.3686 53.9287 80.875C53.9287 80.3814 54.0261 79.8927 54.2154 79.4369C54.4047 78.9811 54.6821 78.5671 55.0317 78.2188L59.8754 73.375" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M56.75 85.25L51.75 90.25" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M84.25 57.75L79.25 62.75" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M70.5 76.5L66.75 80.25" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M65.5 71.5L61.75 75.25" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M76.125 74.625L80.9688 69.7812C81.3183 69.4329 81.5957 69.0189 81.785 68.5631C81.9743 68.1072 82.0717 67.6185 82.0717 67.125C82.0717 66.6314 81.9743 66.1427 81.785 65.6869C81.5957 65.2311 81.3183 64.8171 80.9688 64.4687L77.5312 61.0312C77.1829 60.6816 76.7689 60.4043 76.3131 60.215C75.8573 60.0257 75.3686 59.9283 74.875 59.9283C74.3814 59.9283 73.8927 60.0257 73.4369 60.215C72.9811 60.4043 72.5671 60.6816 72.2188 61.0312L67.375 65.875" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M66.125 64.625L77.375 75.875" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M58.625 72.125L69.875 83.375" stroke="#9A95AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="19.5" y="35.5" width="97" height="67" rx="7.5" stroke="#EDEAF2"/>
    <defs>
        <linearGradient id="paint0_linear_5417_64394" x1="39.6862" y1="40.7736" x2="56.1529" y2="107.575" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.9964" stop-color="white"/>
        </linearGradient>
    </defs>
</svg>
