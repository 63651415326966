import {
    Component,
    Input,
} from '@angular/core'
import { Api } from '@undock/api'
import { Memoize, Model } from '@undock/core';
import { TimeCommandViewModel } from '@undock/time/prompt/states/time-command.view-model';
import { TopicsManager } from '@undock/dock/meet/services/topics.manager';
import { NOTES_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/notes.adapter';
import { TOPICS_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/topics.adapter';
import { PARTICIPANTS_PROVIDER } from '@undock/dock/meet/contracts/ui-providers/participants.provider';
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    TimeCommandAction,
    TimeCommandBlueprintEvent,
} from '@undock/api/scopes/nlp/routes/commands.route'
import { map, share, shareReplay, takeUntil } from 'rxjs';
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { EditEventNotesAdapter } from '@undock/dock/meet/services/adapters/edit-event-notes.adapter'
import { EditEventTopicsAdapter } from '@undock/dock/meet/services/adapters/edit-event-topics.adapter'
import { EditEventParticipantsProvider } from '@undock/dock/meet/services/data-providers/edit-event-participants.provider'

@Component({
    selector: 'app-time-command-answer',
    templateUrl: 'time-command-answer.component.html',
    styleUrls: ['time-command-answer.component.scss'],
    providers: [
        EventFormStateModel,
        TopicsManager,

        { provide: NOTES_ADAPTER, useClass: EditEventNotesAdapter },
        { provide: TOPICS_ADAPTER, useClass: EditEventTopicsAdapter },
        { provide: PARTICIPANTS_PROVIDER, useClass: EditEventParticipantsProvider },
    ]
})
export class TimeCommandAnswerComponent {

    @CompleteOnDestroy()
    protected currentActionSubject = new ValueSubject<TimeCommandAction>(null)

    @Input() set action(value: TimeCommandAction) {
        this.currentActionSubject.next(value)
    }

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor (
        public commandViewModel: TimeCommandViewModel,

        protected api: Api,
        protected eventFormStateModel: EventFormStateModel,
        protected topicsManager: TopicsManager,

    ) {

    }

    async ngOnInit() {
        await this.initFormStateModel()

        // Note: For V1, will just display GPT response on Agenda portion of template. 
        // For V2, will make use of topics list below after regularizing format of returned GPT topics:
    }

    @Memoize()
    public get answer$(): ReactiveStream<string> {
        return new ReactiveStream<string>(this.currentActionSubject.pipe(
            map(action => {
                if (action) {
                    let response = action.response ?? ''
                      , topics = action.topics?.length
                                    ? action.topics.map(topic => `${topic}\n`)
                                    : ''
                    return `${response}${topics}`
                }
                return ''
            }),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    protected async initFormStateModel() {
        let meetingData = await this.api.meet.meetings.createDraftMeeting({
            createDraftDock: false,
            initialProperties: {
                title: 'Answer',
                notes: await this.answer$,
            },
        })

        /**
         * Converting serialized dates to native Date object
         */
        if (meetingData.schedule) {
            if (typeof meetingData.schedule.end === 'string') {
                meetingData.schedule.end = new Date(meetingData.schedule.end)
            }
            if (typeof meetingData.schedule.start === 'string') {
                meetingData.schedule.start = new Date(meetingData.schedule.start)
            }
        }

        try {
            await this.eventFormStateModel.initViewModel(meetingData)
        } catch (error) {
            console.error(`Cannot initialize EventFormStateModel`, error)
        }

        /**
         * TODO: Do we need to subscribe to this? not yet.. but maybe when we turn the completion into an event we will
         */
        // this.eventFormStateModel.state.onSubmit.subscribe(
        //     data => this.handleEventFormSubmit(data)
        // )
    }



    /*
    *   Substream to extract GPT action items (topics) from blueprint
    */
    @Memoize()
    public get topicsStream(): ReactiveStream<string[]> {
        return new ReactiveStream<string[]>(this.currentActionSubject.pipe(
            map(action => action?.topics),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),

        ))
    }

}
