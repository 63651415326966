import { Injectable } from '@angular/core'

import {
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators'
import { combineLatest, debounceTime } from 'rxjs'
import {
    AsyncStream,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import {
    Config,
    AppEventsDispatcher,
    Memoize,
    ExtConnector,
} from '@undock/core'
import {
    FirestoreUser,
    PublicProfileData,
    User,
    UserSettings,
} from '@undock/user'
import { Account } from '@undock/user/models/account.model'
import { AuthManager } from '@undock/auth/services/auth.manager'
import { UserSession } from '@undock/session/models/user-session'
import { TrackUserAnalyticsEvent, UserAnalyticsAction } from '@undock/integrations/events'
import { injectCollection } from '@undock/session'


@Injectable()
export class CurrentUser {
    public readonly uidStream: ReactiveStream<string>
    public readonly dataStream: ReactiveStream<User>
    public readonly accountStream: ReactiveStream<Account>
    public readonly isLoggedInStream: AsyncStream<boolean>
    public readonly isProfileInitializedStream: ReactiveStream<boolean>
    public readonly isRegularUserStream: AsyncStream<boolean>
    public readonly isAnonymousUserStream: AsyncStream<boolean>

    // TODO: Use public state only
    public readonly state = {
        isRegular$:  this.authManager.isRegularUserStream,
        isLoggedIn$: this.authManager.isLoggedInStream,
        isAnonymous$: this.authManager.isAnonymousUserStream,
    }

    protected readonly FirestoreUserCollection = injectCollection(FirestoreUser)

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected config: Config,
        protected authManager: AuthManager,
        protected userSession: UserSession,
        protected extConnector: ExtConnector,
        protected eventsManager: AppEventsDispatcher,
    ) {
        this.uidStream = this.userSession.uidStream
        this.dataStream = this.userSession.currentUser$
        this.accountStream = this.userSession.accountStream
        this.isLoggedInStream = this.authManager.isLoggedInStream
        this.isProfileInitializedStream = this.userSession.isProfileInitializedStream

        this.isRegularUserStream = this.authManager.isRegularUserStream
        this.isAnonymousUserStream = this.authManager.isAnonymousUserStream

        if (this.config.enableAnalytics && !this.extConnector.isExt()) {
            let subscription = this.isLoggedInStream.pipe(
                debounceTime(100),
                takeUntil(this.destroyEvent),
            ).subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    this.eventsManager.dispatch(
                        new TrackUserAnalyticsEvent(UserAnalyticsAction.UserSignIn)
                    )
                    return subscription.unsubscribe()
                }
            })
        }

        // Provide usetiful data
        this.dataStream.pipe(
            filter(Boolean),
            take(1),
            takeUntil(this.destroyEvent),
        ).subscribe(user => {
            // @ts-ignore
            window.usetifulTags = { userId: user._id }
        })
    }

    @Memoize()
    public get settingsStream(): ReactiveStream<UserSettings> {
        return new ReactiveStream<UserSettings>(
            this.userSession.currentUser$.pipe(
                map(user => user.settings),
            ),
        )
    }

    @Memoize()
    public get publicProfileDataStream(): ReactiveStream<PublicProfileData> {
        return new ReactiveStream<PublicProfileData>(
            combineLatest([
                this.dataStream,
                this.isRegularUserStream,
            ]).pipe(
                map(sources => {
                    const [profile, isRegularUser] = sources

                    return {
                        isGuest: !isRegularUser,
                        isUndockUser: isRegularUser,
                        isRegularUser: isRegularUser,

                        email: profile.email ?? '',
                        imageUrl: profile.imageUrl ?? '',

                        lastName: profile.lastname,
                        firstName: profile.firstname,
                        displayName: profile.displayName,
                    } as PublicProfileData
                }),
            ),
        )
    }


    public get id(): Promise<string> {
        return (async () => (await this.data)._id)()
    }

    public get firebaseId(): Promise<string> {
        return (async () => (await this.data).firebaseId)()
    }

    public get isAnonymous(): Promise<boolean> {
        return (async () => await this.isAnonymousUserStream)()
    }

    public get isRegularUser(): Promise<boolean> {
        return (async () => (await this.isRegularUserStream))()
    }

    public get lastName(): Promise<string> {
        return (async () => (await this.data).lastname)()
    }

    public get firstName(): Promise<string> {
        return (async () => (await this.data).firstname)()
    }

    public get displayName(): Promise<string> {
        return (async () => (await this.data).displayName)()
    }

    public get email(): Promise<string> {
        return (async () => (await this.data).email)()
    }

    public get imageUrl(): Promise<string> {
        return (async () => (await this.data).imageUrl)()
    }

    public get profileUrl(): Promise<string> {
        return (async () => (await this.data).profileUrl)()
    }

    public get chargeAccountId(): Promise<string> {
        return (async () => (await this.data).chargeAccountId)()
    }

    public get subscriptionAccountId(): Promise<string> {
        return (async () => (await this.data).subscriptionAccountId)()
    }

    public get settings() {
        return (async () => (await this.data).settings)()
    }

    public get data(): Promise<User> {
        return (async () => this.userSession.currentUser$)()
    }

    public get publicData(): Promise<PublicProfileData> {
        return (async () => await this.publicProfileDataStream)()
    }

    public get firestoreProfile(): Promise<FirestoreUser> {
        return (async () => await this.firestoreProfileStream)()
    }

    @Memoize()
    public get firestoreProfileStream(): ReactiveStream<FirestoreUser> {
        return new ReactiveStream<FirestoreUser>(
            this.dataStream.pipe(
                distinctUntilChanged(
                    (prev, next) => prev._id === next._id,
                ),
                switchMap(data => this.FirestoreUserCollection.one(data._id).stream()),
            ),
        )
    }

    /**
     * @TODO: Implement firestore User and then remove this method.
     *
     * @deprecated
     */
    public async refreshProperties(updates?: Partial<User>): Promise<void> {
        return this.userSession.refreshCurrentUser(updates)
    }

    /**
     * @TODO: Implement firestore User and then remove this method.
     *
     * @deprecated
     */
    public async refresh(): Promise<void> {
        return this.userSession.refreshCurrentUser()
    }
}
