import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-close-circle-icon',
    template: `
        <svg class="close-btn" [attr.width]="width" [attr.height]="width" viewBox="0 0 48 48"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="46" height="46" rx="23" stroke="#E4DFF2" stroke-width="2"/>
            <path d="M30.3 17.7102C29.91 17.3202 29.28 17.3202 28.89 17.7102L24 22.5902L19.11 17.7002C18.72 17.3102 18.09 17.3102 17.7 17.7002C17.31 18.0902 17.31 18.7202 17.7 19.1102L22.59 24.0002L17.7 28.8902C17.31 29.2802 17.31 29.9102 17.7 30.3002C18.09 30.6902 18.72 30.6902 19.11 30.3002L24 25.4102L28.89 30.3002C29.28 30.6902 29.91 30.6902 30.3 30.3002C30.69 29.9102 30.69 29.2802 30.3 28.8902L25.41 24.0002L30.3 19.1102C30.68 18.7302 30.68 18.0902 30.3 17.7102Z"
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
         :host {
             display: flex;
             justify-content: center;
             align-items: center;
         }
    `]
})
export class CloseCircleComponent extends IconTemplateComponent {
    @Input() width: string | number = 48
    @Input() height: string | number  = 48
    @Input() color: string = 'currentColor'
}
