import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { Overlay } from '@angular/cdk/overlay'

import {
    DestroyEvent,
    EmitOnDestroy,
    StatefulSubject,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Observable } from 'rxjs'

import { Memoize } from '@undock/core'
import { ImageViewerService } from '@undock/common/ui-kit/services/image-viewer.service'


@Component({
    selector: 'app-image-viewer-host',
    templateUrl: 'image-viewer-host.component.html',
    styleUrls: ['image-viewer-host.component.scss'],
})
export class ImageViewerHostComponent {

    @Input() key: string = 'default'

    @CompleteOnDestroy()
    private currentImageSubject = new StatefulSubject<string>()

    @CompleteOnDestroy()
    private isOpenedStateSubject = new ValueSubject<boolean>(false)

    @ViewChild('imageViewerTmpl')
    private imageViewerTemplateRef: TemplateRef<HTMLElement>


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private imageViewerService: ImageViewerService,
    ) {
        this.imageViewerService.registerHost(this, this.key)
    }


    @Memoize()
    public get currentImageStream(): Observable<string> {
        return this.currentImageSubject.asObservable()
    }

    @Memoize()
    public get isOpenedStateStream(): Observable<boolean> {
        return this.isOpenedStateSubject.asObservable()
    }


    public displayImage(url: string) {
        this.currentImageSubject.next(url)
        this.isOpenedStateSubject.next(true)
    }

    public open() {
        this.isOpenedStateSubject.next(true)
    }

    public close() {
        this.isOpenedStateSubject.next(false)
    }
}
