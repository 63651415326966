import {
    Inject,
    Injectable,
} from '@angular/core'

import { Config } from '@undock/core'
import { CurrentUser } from '@undock/session'
import { AnalyticsTracker } from '@undock/integrations/contracts'
import { UserAnalyticsAction } from '@undock/integrations/events'


@Injectable({
    providedIn: 'root',
})
export class AnalyticsManager {

    public constructor(
        private config: Config,
        private currentUser: CurrentUser,
        @Inject(AnalyticsTracker)
        private readonly trackers: AnalyticsTracker[],
    ) {
        this.initialize()
    }

    protected initialize(): void {
        if (this.config.enableAnalytics) {
            for (let tracker of this.trackers) {
                tracker.initialize()
            }
        }
    }

    public async track(action: UserAnalyticsAction, data: any = {}): Promise<void> {
        if (this.config.enableAnalytics) {
            let user = await this.currentUser.dataStream
            for (let tracker of this.trackers) {
                tracker.track(action, user, data)
            }
        }
    }
}
