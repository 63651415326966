import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-warning-calendar-icon',
    templateUrl: 'warning-calendar-icon.component.html',
    styleUrls: ['warning-calendar-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningCalendarIconComponent {
    @Input() width: number | string = '140'
    @Input() height: number | string = '140'
}
