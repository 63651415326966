<div class="duration-select clickable"
     #selectedDurationBoxRef
     #dropdownRef=udDropdownRef

     udDropdown
     [dropdownHostEl]="dropdownElHost ?? selectedDurationBoxRef"
     [overlayContentRef]="durationOverlayRef"

     (click)="dropdownRef.open(); hiddenInput.focus()"
>
    {{ (selectedMeetingDuration | meetingDuration) || placeholder }}
</div>

<!-- This input is used to handle focus by Tab -->
<input #hiddenInput
       class="hidden" tabindex="0"
       (focus)="dropdownRef.open()"
       (keydown.escape)="dropdownRef.close(); hiddenInput.blur()"
       (keydown.enter)="applyActiveListItem(); dropdownRef.close()"
       (keydown.arrowUp)="activatePrevListItem()"
       (keydown.arrowDown)="activateNextListItem()"
/>

<ng-template #durationOverlayRef>
    <div class="event-form-duration-dropdown">
        <ng-container *ngFor="let option of availableMeetingDurationOptions">
            <div class="duration-option"
                 [class.active]="option === (selectedItem$ | stream)"
                 (click)="onMeetingDurationChanged(option.value - option.gap); dropdownRef.close()"
            >
                <span>{{ option.value - option.gap | meetingDuration }}</span>
                <ng-container *ngIf="selectedMeetingDuration === option.value - option.gap">
                    <!-- TODO: Replace with correct icon -->
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="9" fill="#2B2733"/>
                        <path d="M9 12L11 14L16 9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>
