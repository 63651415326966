import { Injectable } from '@angular/core'
import { RemoveUnavailableSlotsFilter } from './remove-unavailable-slots.filter';
import { GeneratePriorityModeSlotsFilter } from './generate-priority-mode-slots.filter';
import { RemoveDuplicateSlotsFilter } from './remove-duplicate-slots.filter'

@Injectable({
    providedIn: 'root',
})
export class AvailabilityFiltersAggregator {

    constructor(
        public readonly removeUnavailableSlots: RemoveUnavailableSlotsFilter,
        public readonly generatePriorityModeSlots: GeneratePriorityModeSlotsFilter,
        public readonly removeDuplicateSlotsFilter: RemoveDuplicateSlotsFilter
    ) {}
}
