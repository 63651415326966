<div class="list-icon-box"
    [style.width]="size + 'px'"
    [style.height]="size + 'px'"
    [style.background]="backgroundColor"
    [style.border]="border">

    <ng-container *ngIf="!command?.category; else hasCategoryTmpl">
        <app-icon-hourglass
                [color]="color"
        ></app-icon-hourglass>
    </ng-container>

    <ng-template #hasCategoryTmpl>
        <plan-category-icon
            [category]="command.category"
            [color]="color"
        ></plan-category-icon>
    </ng-template>

</div>
