export function generateFileName(extension: string): string {
    const date = new Date(),
        Y = date.getFullYear(),
        M = date.getMonth(),
        d = date.getDate(),
        h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds()

    return `${Y}-${M}-${d}_${h}-${m}-${s}.${extension}`
}
