import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import { EventAttendee } from '@undock/api/scopes/time/contracts/timeline-event/event-attendee.interface'


@Component({
    selector: 'app-event-attendees-preview',
    templateUrl: 'event-attendees-preview.component.html',
    styleUrls: ['event-attendees-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventAttendeesPreviewComponent implements OnInit {

    /* Match both values with size variable in scss */
    private readonly iconSize = 28
    private readonly iconShift = 10
    public readonly TooltipPosition = TooltipPosition
    public visibleCount: number

    @Input() public direction: string
    @Input() public maxDisplayCount: number = 3
    @Input() public preserveWidth: boolean = false
    @Input() public dynamicWidth: boolean = false
    @Input() public participants: EventAttendee[]

    @HostBinding('class.expand-on-hover') @Input() expandOnHover = false

    @HostBinding('class.right-to-left') get rightDirection() { return this.direction !== 'left' }

    @HostBinding('class.left-to-right') get leftDirection() { return this.direction === 'left' }

    @HostBinding('style.min-width.px') public minWidth: number = 28
    public maxWidth: number = 0

    public ngOnInit() {
        this.updateLayout()
    }

    private updateLayout() {
        if (this.participants) {
            const totalCount = this.participants.length
            this.visibleCount = this.maxDisplayCount
            let displayCount = this.visibleCount
            if (totalCount > this.visibleCount) {
                this.visibleCount--
            }

            if (this.dynamicWidth) {
                // @todo element width check and change displayCount
            }

            const itemsToShow = displayCount <= totalCount ? displayCount : totalCount
            const calcWidth = itemsToShow * (this.iconSize - this.iconShift) + this.iconShift
            const realSize = this.iconSize >= calcWidth ? this.iconSize : calcWidth

            if (this.preserveWidth) {
                this.minWidth = realSize
            }

            this.maxWidth = realSize
        }
    }
}
