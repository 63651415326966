import {
    OnInit,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    Router,
    ActivatedRoute,
} from '@angular/router'

import { AnimationOptions } from 'ngx-lottie'

import {
    CompleteOnDestroy,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import { Api } from '@undock/api'
import { User } from '@undock/user'
import { getQueryParam } from '@undock/core'
import { AuthManager } from '@undock/auth/services/auth.manager'


@Component({
    selector: 'app-invite-sign-up-page',
    templateUrl: 'invite-sign-up.page.html',
    styleUrls: ['invite-sign-up.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteSignUpPage implements OnInit {

    @CompleteOnDestroy()
    public targetProfileStream: ValueSubject<User> = new ValueSubject(null)

    @CompleteOnDestroy()
    public animation$: StatefulSubject<AnimationOptions> = new StatefulSubject()

    @CompleteOnDestroy()
    protected isProfileLoadingSubject = new ValueSubject<boolean>(false)

    protected readonly INVITEE_USER_PROFILE_PARAM = 'i'

    public constructor(
        protected api: Api,
        protected router: Router,
        protected authManager: AuthManager,
        protected activatedRoute: ActivatedRoute
    ) {
        // Lazy load booking confirmation animation
        import('@undock/profile/public/ui/animations/booking-confirmation')
            .then(m => this.animation$.next(m.BOOKING_CONFIRMATION_ANIMATION))
    }

    public async ngOnInit() {
        this.isProfileLoadingSubject.next(true)

        try {
            const authUser = await this.authManager.authUserStream
            if (authUser.isAnonymous) {
                let userProfile = getQueryParam(this.activatedRoute, this.INVITEE_USER_PROFILE_PARAM)
                if (userProfile) {
                    this.targetProfileStream.next(
                        await this.loadUserByProfileUrl(userProfile),
                    )
                }
                else {
                    return this.navigateToSignup()
                }
            } else {
                return this.navigateToSignup()
            }
        } catch (e) {
            console.warn(`Unable to load profile`, e)
            return this.navigateToSignup()
        } finally {
            this.isProfileLoadingSubject.next(false)
        }
    }

    public async navigateToSignup(updateInviteCount: boolean = false) {
        const viewedProfile = await this.targetProfileStream
        const queryParams = viewedProfile ? {
            queryParams: { utm_source: 'user_referral', utm_medium: 'invite', utm_campaign: viewedProfile.profileUrl }
        } : {}

        if (viewedProfile && updateInviteCount) {
            await this.api.user.analytics.updateSentInvitesCountForUserProfile(viewedProfile.profileUrl)
        }

        return this.router.navigate(['signup'], queryParams)
    }

    protected async loadUserByProfileUrl(profileUrl: string): Promise<User> {
        let user: User = await this.api.user.profile.getByProfileUrl(profileUrl)

        if (!user) {
            throw new Error(`User ${profileUrl} is not found`)
        }

        return user
    }
}
