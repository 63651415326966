import { Injectable } from '@angular/core'

import {
    UserAvailableSlotDay,
    UserAvailableSlotPreferences,
    UserSettings,
} from '@undock/user'
import { Api } from '@undock/api'
import { clone } from '@undock/core'
import { CurrentUser } from '@undock/session'


@Injectable()
export class SettingsManager {

    protected readonly enabledAvailabilityIntervals = ['15', '30', '60', '120']

    /**
     * @TODO: Refactor this class
     */
    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
    ) {}

    /**
     * At least one available meeting length should be enabled
     */
    public canRemoveAvailableMeetingLength(settings: UserSettings): boolean {
        let enabledIntervalsCount = 0

        for (let interval in settings.availabilityIntervals) {
            if (interval !== '_id' && settings.availabilityIntervals[interval] && this.enabledAvailabilityIntervals.includes(interval)) {
                enabledIntervalsCount++
            }
        }

        return enabledIntervalsCount > 1
    }

    public async toggleTimeProfileSlotSetting(
        settings: UserSettings, slot: any, day: string, settingName: 'allowInPerson',
    ) {
        if (slot.settings[settingName]) {
            this.disableAvailabilitySlotSetting(day, slot.hour, slot.minute, settingName, settings)
            slot.settings[settingName] = false
        } else {
            this.enableAvailabilitySlotSetting(day, slot.hour, slot.minute, settingName, settings)
            slot.settings[settingName] = true
        }

        return this.massUpdateUserProperties({
            'settings.availableSlots': settings.availableSlots,
        })
    }

    public async toggleTimeProfileSlotAvailability(settings: UserSettings, slot: any, day: string) {
        if (slot.enabled) {
            this.disableAvailabilitySlot(day, slot.hour, slot.minute, settings)
            slot.enabled = false

            if (slot.hour <= settings.minSlotHour) {
                /**
                 * If the slot being turned off is lower than or equal to the current min slot hour (and therefore could have been the min slot),
                 * find the new min slot hour
                 */
                let min = this.findMinAvailableSlotHour(settings)
                if (min !== -1) {
                    settings.minSlotHour = min
                }
            } else if (slot.hour >= settings.maxSlotHour - 1) {
                /**
                 * If the slot being turned off is higher than or equal to the current max slot hour (and therefore could be the max slot), find the new max
                 */
                let max = this.findMaxAvailableSlotHour(settings)
                if (max !== -1) {
                    settings.maxSlotHour = max + 1
                }
            }
        } else {
            this.enableAvailabilitySlot(day, slot.hour, slot.minute, settings)
            slot.enabled = true

            if ((!settings.minSlotHour && settings.minSlotHour !== 0) || slot.hour < settings.minSlotHour) {
                settings.minSlotHour = slot.hour
            } else if (!settings.maxSlotHour || slot.hour + 1 > settings.maxSlotHour) {

                settings.maxSlotHour = slot.hour + 1
            }
        }

        return this.massUpdateUserProperties({ settings })
    }


    public async updateWorkingHours(
        settings: UserSettings, newHourMinuteValue: number, bound: 'start' | 'end',
    ): Promise<void> {
        // Save a copy of the previous working hours
        let previousStart = settings.availabilityStart
        let previousStartMins = settings.availabilityStartMins
        let previousEnd = settings.availabilityEnd
        let previousEndMins = settings.availabilityEndMins

        let hour = Math.trunc(newHourMinuteValue / 100)
        let mins = newHourMinuteValue % 100

        if (bound === 'start') {
            if (hour >= settings.businessHoursEnd) {
                if (hour + 1 < 24) {
                    settings.availabilityStart = settings.businessHoursStart = hour
                    settings.availabilityStartMins = settings.businessHoursStartMins = mins
                    settings.availabilityEnd = settings.businessHoursEnd = hour + 1
                    settings.availabilityEndMins = settings.businessHoursEndMins = mins
                } else {
                    settings.availabilityStart = settings.businessHoursStart = settings.businessHoursEnd - 1
                    settings.availabilityStartMins = settings.businessHoursStartMins = 0
                }
            } else {
                settings.availabilityStart = settings.businessHoursStart = hour
                settings.availabilityStartMins = settings.businessHoursStartMins = mins
            }

            // If the chosen start time is now the min overall slot hour for the whole week, update it here
            if ((!settings.minSlotHour && settings.minSlotHour !== 0) || settings.availabilityStart <= settings.minSlotHour) {
                settings.minSlotHour = settings.availabilityStart
            } else if (settings.minSlotHour === previousStart) {
                settings.minSlotHour = settings.availabilityStart
            }
        } else if (bound === 'end') {
            if (hour <= settings.businessHoursStart) {
                if (hour - 1 >= 0) {
                    settings.availabilityEnd = settings.businessHoursEnd = hour
                    settings.availabilityEndMins = settings.businessHoursEndMins = mins
                    settings.availabilityStart = settings.businessHoursStart = hour - 1
                    settings.availabilityStartMins = settings.businessHoursStartMins = mins
                } else {
                    settings.availabilityEnd = settings.businessHoursEnd = settings.businessHoursStart + 1
                    settings.availabilityEndMins = settings.businessHoursEndMins = 0
                }
            } else {
                settings.availabilityEnd = settings.businessHoursEnd = hour
                settings.availabilityEndMins = settings.businessHoursEndMins = mins
            }

            // If the chosen end time is the max overall slot hour for the whole week, update it here
            if ((!settings.maxSlotHour && settings.minSlotHour !== 0) || settings.availabilityEnd >= settings.maxSlotHour) {
                settings.maxSlotHour = settings.availabilityEnd
            } else if (settings.maxSlotHour === previousEnd) {
                settings.maxSlotHour = settings.availabilityEnd
            }
        }

        /**
         * Reset all of the availability slots based on the selected start
         *          and end times and enabled/non-enabled days of the week
         */
        this.updateTimeProfileAvailability(settings.availableSlots, {
            previousEnd: previousEnd, previousEndMins: previousEndMins,
            previousStart: previousStart, previousStartMins: previousStartMins,
        }, settings)

        let updates = {
            'settings.minSlotHour': settings.minSlotHour,
            'settings.maxSlotHour': settings.maxSlotHour,

            'settings.availableSlots': settings.availableSlots,

            'settings.businessHoursStart': settings.businessHoursStart,
            'settings.businessHoursStartMins': settings.businessHoursStartMins,
            'settings.businessHoursEnd': settings.businessHoursEnd,
            'settings.businessHoursEndMins': settings.businessHoursEndMins,

            'settings.availabilityStart': settings.availabilityStart,
            'settings.availabilityStartMins': settings.availabilityStartMins,

            'settings.availabilityEnd': settings.availabilityEnd,
            'settings.availabilityEndMins': settings.availabilityEndMins,
        }

        return this.massUpdateUserProperties(updates)
    }

    /**
     * @TODO: Update this method to update StandardSchedule
     */
    private updateTimeProfileAvailability(previousSlots: any, previousTimes: any, settings: UserSettings) {
        let previous: { [dayOfWeek: string]: UserAvailableSlotDay } = clone(previousSlots)

        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

        // Get the union time range of the previous availability hours and the updated ones
        let start = previousTimes.previousStart <= settings.availabilityStart ? previousTimes.previousStart : settings.availabilityStart
        let end = previousTimes.previousEnd >= settings.availabilityEnd ? previousTimes.previousEnd : settings.availabilityEnd

        for (let day of days) {
            if (settings.availableSlots[day]) {
                if (settings.availableSlots[day].enabled) { // TODO: Setting to allow changing the enabled days, right now only Mon-Fri
                    for (let i = start; i < end; i++) {
                        // 1) If the previous availability had this hour and the new availability does not, mark this hour as unavailable
                        if (previous[day]?.map?.hasOwnProperty(i) && !(settings.availabilityStart <= i && i < settings.availabilityEnd)) {
                            if (previous[day].map[i][0].available) {
                                settings.availableSlots[day].count--
                            }

                            if (previous[day].map[i][1].available) {
                                settings.availableSlots[day].count--
                            }

                            delete settings.availableSlots[day].map[i]
                        }

                            // 2) If the previous availability had this hour and the new availability also has it, differ to the previous values
                        // 3) If the previous availability does not have this hour and the new availability does, mark this hour as available
                        else if (!previous[day]?.map?.hasOwnProperty(i) && (settings.availabilityStart <= i && i < settings.availabilityEnd)) {

                            if (!(previousTimes.previousStart <= i && i < previousTimes.previousEnd)) {

                                settings.availableSlots[day].map[i] = [{ available: true, allowInPerson: true }, {
                                    available: true,
                                    allowInPerson: true,
                                }]
                                settings.availableSlots[day].count += 2
                            }
                        }
                    }

                    if (previousTimes.previousStartMins === 0 && settings.availabilityStartMins === 30) {
                        if (settings.availableSlots[day].map[settings.availabilityStart][0].available) {
                            this.disableAvailabilitySlot(day, settings.availabilityStart, 0, settings)
                        }
                        if (!settings.availableSlots[day].map[settings.availabilityStart][1].available) {
                            this.enableAvailabilitySlot(day, settings.availabilityStart, 1, settings)
                        }
                    } else if (previousTimes.previousStartMins === 30 && settings.availabilityStartMins === 0) {
                        if (previousTimes.previousStart === settings.availabilityStart && !settings.availableSlots[day].map[settings.availabilityStart][0].available) {
                            this.enableAvailabilitySlot(day, settings.availabilityStart, 0, settings)
                        }
                        if (previousTimes.previousStart > settings.availabilityStart && !settings.availableSlots[day].map[previousTimes.previousStart][0].available) {
                            this.enableAvailabilitySlot(day, previousTimes.previousStart, 0, settings)
                        }
                    } else if (previousTimes.previousStartMins === 30 && settings.availabilityStartMins === 30) {
                        if (settings.availableSlots[day].map[settings.availabilityStart] && settings.availableSlots[day].map[settings.availabilityStart][0].available) {
                            this.disableAvailabilitySlot(day, settings.availabilityStart, 0, settings)
                        }
                        if (previousTimes.previousStart > settings.availabilityStart && !settings.availableSlots[day].map[previousTimes.previousStart][0].available) {
                            this.enableAvailabilitySlot(day, previousTimes.previousStart, 0, settings)
                        }
                    }

                    if (previousTimes.previousEndMins !== 30 && settings.availabilityEndMins === 30) {
                        this.enableAvailabilitySlot(day, settings.availabilityEnd, 0, settings)
                    } else if (previousTimes.previousEndMins === 30 && settings.availabilityEndMins === 0) {
                        if (previousTimes.previousEnd === settings.availabilityEnd || previousTimes.previousEnd > settings.availabilityEnd) {

                            if (settings.availableSlots[day].map[previousTimes.previousEnd][0].available) {
                                settings.availableSlots[day].count--
                            }
                            if (settings.availableSlots[day].map[previousTimes.previousEnd][1].available) {
                                settings.availableSlots[day].count--
                            }

                            delete settings.availableSlots[day].map[previousTimes.previousEnd]
                        } else if (previousTimes.previousEnd < settings.availabilityEnd) {
                            if (!settings.availableSlots[day].map[previousTimes.previousEnd][0].available) {
                                this.enableAvailabilitySlot(day, previousTimes.previousEnd, 0, settings)
                            }
                            if (!settings.availableSlots[day].map[previousTimes.previousEnd][1].available) {
                                this.enableAvailabilitySlot(day, previousTimes.previousEnd, 1, settings)
                            }
                        }
                    } else if (previousTimes.previousEndMins === 30 && settings.availabilityEndMins === 30 && previousTimes.previousEnd < settings.availabilityEnd) {
                        this.enableAvailabilitySlot(day, settings.availabilityEnd, 0, settings)

                        if (!settings.availableSlots[day].map[previousTimes.previousEnd][0].available) {
                            this.enableAvailabilitySlot(day, previousTimes.previousEnd, 0, settings)
                        }
                        if (!settings.availableSlots[day].map[previousTimes.previousEnd][1].available) {
                            this.enableAvailabilitySlot(day, previousTimes.previousEnd, 1, settings)
                        }
                    } else if (previousTimes.previousEndMins === 30 && settings.availabilityEndMins === 30 && previousTimes.previousEnd > settings.availabilityEnd) {
                        this.enableAvailabilitySlot(day, settings.availabilityEnd, 0, settings)

                        if (settings.availableSlots[day].map[previousTimes.previousEnd][0].available) {
                            settings.availableSlots[day].count--
                        }
                        if (settings.availableSlots[day].map[previousTimes.previousEnd][1].available) {
                            settings.availableSlots[day].count--
                        }

                        delete settings.availableSlots[day].map[previousTimes.previousEnd]
                    }
                }
            }
        }
    }

    private enableAvailabilitySlot(day: string, hour: number, minute: 0 | 1, settings: UserSettings) {
        if (!settings.availableSlots[day].map) {
            settings.availableSlots[day].map = {}
        }

        if (settings.availableSlots[day].map[hour]) {
            settings.availableSlots[day].map[hour][minute].available = true
        } else {
            settings.availableSlots[day].map[hour] = [{ available: false, allowInPerson: true }, { available: false, allowInPerson: true }]
            settings.availableSlots[day].map[hour][minute].available = true
        }

        settings.availableSlots[day].count++
    }

    private disableAvailabilitySlot(day: string, hour: number, minute: number, settings: UserSettings) {
        if (settings.availableSlots[day].map[hour]) {
            settings.availableSlots[day].map[hour][minute].available = false

            if (settings.availableSlots[day].map[hour][0].available === false && settings.availableSlots[day].map[hour][1].available === false) {
                delete settings.availableSlots[day].map[hour]
            }
        }

        settings.availableSlots[day].count--
    }

    private findMinAvailableSlotHour(settings: UserSettings): number {
        let min = -1

        for (let day in settings.availableSlots) {
            if (settings.availableSlots.hasOwnProperty(day) && settings.availableSlots[day].count > 0 && settings.availableSlots[day].map) {
                for (let hour in settings.availableSlots[day].map) {
                    if (settings.availableSlots[day].map.hasOwnProperty(hour)) {
                        let h = parseInt(hour)

                        if (min === -1) {
                            min = h
                        } else if (h < min) {
                            min = h
                        }
                    }
                }
            }
        }

        return min
    }

    private findMaxAvailableSlotHour(settings: UserSettings): number {
        let max = -1

        for (let day in settings.availableSlots) {
            if (settings.availableSlots.hasOwnProperty(day) && settings.availableSlots[day].count > 0 && settings.availableSlots[day].map) {
                for (let hour in settings.availableSlots[day].map) {
                    if (settings.availableSlots[day].map.hasOwnProperty(hour)) {
                        let h = parseInt(hour)

                        if (max === -1) {
                            max = h
                        } else if (h > max) {
                            max = h
                        }
                    }
                }
            }
        }

        return max
    }

    private enableAvailabilitySlotSetting(
        day: string, hour: number, minute: 0 | 1, setting: string, settings: UserSettings,
    ) {
        if (!settings.availableSlots[day].map) {
            settings.availableSlots[day].map = {}
        }

        if (settings.availableSlots[day].map[hour]) {
            settings.availableSlots[day].map[hour][minute][setting] = true
        }
    }

    private disableAvailabilitySlotSetting(
        day: string, hour: number, minute: number, setting: string, settings: UserSettings,
    ) {
        if (settings.availableSlots[day].map[hour]) {
            settings.availableSlots[day].map[hour][minute][setting] = false
        }
    }

    protected massUpdateUserProperties(updates: Record<string, any>) {
        return this.api.user.settings.massUpdateUserProperties(updates)
    }
}
