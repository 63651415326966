import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
} from '@angular/core'

import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { EventDetailsViewModel } from '@undock/dashboard/view-models/event-details.view-model'
import { DashboardCalendarEventCardComponent } from './event-details-card.component'

@Component({
    selector: 'app-dashboard-calendar-event-popup',
    templateUrl: 'event-details-popup.component.html',
    styleUrls: ['event-details-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventDetailsViewModel]
})
export class DashboardCalendarEventPopupComponent extends DashboardCalendarEventCardComponent {

    public constructor(
        protected elementRef: ElementRef,
        protected snackbarManager: SnackbarManager,
        protected changeDetectorRef: ChangeDetectorRef,
        protected eventDetailsViewModel: EventDetailsViewModel,
    ) {
        super(snackbarManager, changeDetectorRef, eventDetailsViewModel)
    }

    public ngAfterViewInit() {
        const overlayEl = this.elementRef
                              .nativeElement
                              .parentElement as HTMLDivElement
        if (overlayEl) {
            /**
             * Reset previous drag changes
             */
            overlayEl.style.transform = ''
        }
    }
}
