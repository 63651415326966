import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { TagMouseEvent } from '@undock/common/mentions/contracts/tag-mouse-event'
import { ChoiceWithIndices } from '@undock/common/mentions/contracts/choice-with-indices'
import { ConnectionsFacade } from '@undock/people/services/facades/connections.facade'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { FirestoreUser } from '@undock/user'
import {
    compareDeeply,
    Memoize,
} from '@undock/core'
import {
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
    map,
    combineLatest,
    tap,
    merge,
    filter,
    take,
    Observable,
} from 'rxjs'
import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations'
import { KeyboardEventsListener } from '@undock/hotkeys/services/keyboard-events.listener'
import { MentionsComponent } from '@undock/common/mentions/components/mentions.component'
import {
    IChannel,
    IOrganization,
} from '@undock/api/scopes/organizations/contracts'
import { Api } from '@undock/api'
import { OrganizationsStorage } from '@undock/organizations/services/organizations.storage'


export interface UiGroupEntity {
    id: string,
    name: string,
    logoUrl: string,
    description?: string,
    organization?: IOrganization,
    channel?: IChannel
}

@Component({
    selector: 'app-contacts-mentions',
    templateUrl: './contact-mentions.component.html',
    styleUrls: ['./contact-mentions.component.scss'],
    animations: [
        trigger('slideIn', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(10px)' }),
                animate('150ms', style({ opacity: 1, transform: 'translateX(0)' })),
            ]),
        ]),
    ],
})
export class ContactMentionsComponent {

    @ViewChild('profileMentions') profileMentions: MentionsComponent

    @ViewChild('profileResultsList') profileResultsList: ElementRef

    @ViewChild('groupMentions') groupMentions: MentionsComponent

    @ViewChild('groupResultsList') groupResultsList: ElementRef

    @CompleteOnDestroy()
    public readonly isLoading$ = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly isProfileMenuShowing$ = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly profileSearchResults$ = new ValueSubject<FirestoreUser[]>([])

    @CompleteOnDestroy()
    public readonly targetedProfileIndex$ = new ValueSubject<number>(0)

    @CompleteOnDestroy()
    public readonly isGroupMenuShowing$ = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly channels$ = new ValueSubject<IChannel[]>([])

    @CompleteOnDestroy()
    public readonly groups$ = new ValueSubject<UiGroupEntity[]>([])

    @CompleteOnDestroy()
    public readonly groupSearchResults$ = new ValueSubject<UiGroupEntity[]>([])

    @CompleteOnDestroy()
    public readonly organizationlSearchResults$ = new ValueSubject<IOrganization[]>([])

    @CompleteOnDestroy()
    public readonly channelSearchResults$ = new ValueSubject<IChannel[]>([])

    @CompleteOnDestroy()
    public readonly targetedGroupIndex$ = new ValueSubject<number>(0)

    @Input() textInputElement: HTMLTextAreaElement | HTMLInputElement

    @Input() openDirection: 'up' | 'down' = 'down'

    @Input() closeMenuOnBlur = true

    @Output() profileSelected = new EventEmitter<ChoiceWithIndices<FirestoreUser>>()

    @Output() profileRemoved = new EventEmitter<ChoiceWithIndices<FirestoreUser>>()

    @Output() selectedProfilesChange = new EventEmitter<FirestoreUser[]>()

    @Output() groupSelected = new EventEmitter<ChoiceWithIndices<UiGroupEntity>>()

    @Output() groupRemoved = new EventEmitter<ChoiceWithIndices<UiGroupEntity>>()

    @Output() selectedGroupsChange = new EventEmitter<UiGroupEntity[]>()

    @Output() tagClick = new EventEmitter<TagMouseEvent>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        protected api: Api,
        protected connectionsFacade: ConnectionsFacade,
        protected keyEventsListener: KeyboardEventsListener,
        protected organizationsProvider: OrganizationsStorage,
    ) {
        this.isProfileKeyListenerRegisteredStream.subscribe()
        this.isGroupKeyListenerRegisteredStream.subscribe()
    }

    async ngOnInit() {
        this.isLoading$.next(true)
        this.channels$.next(
            await this.api.organizations.channels.listOwnForAllOrganizations()
        )
        this.isLoading$.next(false)
    }

    @Memoize()
    public get connectionProfilesStream(): ReactiveStream<FirestoreUser[]> {
        return new ReactiveStream<FirestoreUser[]>(
            this.connectionsFacade.uiConnections$.pipe(
                map(connections => connections?.length
                    ? connections.map(c => c.profile)
                    : []),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get profileSearchResultsStream(): ReactiveStream<FirestoreUser[]> {
        return this.profileSearchResults$.asStream()
    }

    @Memoize()
    public get targetedProfileStream(): ReactiveStream<FirestoreUser> {
        return new ReactiveStream<FirestoreUser>(
            combineLatest([
                this.profileSearchResultsStream,
                this.targetedProfileIndex$
            ]).pipe(
                map(([profiles, targetedIndex]) => profiles?.length
                    ? profiles[targetedIndex]
                    : null),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get groupsStream(): ReactiveStream<UiGroupEntity[]> {
        return new ReactiveStream<UiGroupEntity[]>(
            combineLatest([
                this.organizationsProvider.own$,
                this.channels$
            ]).pipe(
                map(([organizations, channels]) => {
                    return [
                        ...(organizations.map(o => {
                            return {
                                id: o._id,
                                name: o.name,
                                logoUrl: o.logoUrl,
                                organization: o
                            }
                        })),
                        ...(channels.map(c => {
                            return {
                                id: c._id,
                                name: c.name,
                                logoUrl: organizations.find(o => o._id === c.organizationId)?.logoUrl ?? null,
                                description: organizations.find(o => o._id === c.organizationId)?.name ?? null,
                                channel: c
                            }
                        }))
                    ]
                }),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get groupSearchResultsStream(): ReactiveStream<UiGroupEntity[]> {
        return this.groupSearchResults$.asStream()
    }

    @Memoize()
    public get targetedGroupStream(): ReactiveStream<UiGroupEntity> {
        return new ReactiveStream<UiGroupEntity>(
            combineLatest([
                this.groupSearchResultsStream,
                this.targetedGroupIndex$
            ]).pipe(
                map(([groups, targetedIndex]) => groups?.length
                    ? groups[targetedIndex]
                    : null),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get isAllowProfileKeyListenerStream(): ReactiveStream<boolean> {
        return this.isProfileMenuShowing$.asStream()
    }

    @Memoize()
    protected get isProfileKeyListenerRegisteredStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.isAllowProfileKeyListenerStream.pipe(
            distinctUntilChanged(
                (prev, next) => compareDeeply(prev, next),
            ),
            tap(isAllowed => {
                if (isAllowed) {
                    this.keyEventsListener.subscribe({
                            'Tab': this.onSelectProfileKey,
                            'Enter': this.onSelectProfileKey,
                            'Up': this.onPreviousProfileKey,
                            'Down': this.onNextProfileKey,
                            'Escape': this.onCloseSearchResultsKey
                        },
                        {
                            priority: 200,
                            allowInputs: true,
                            terminal: true,
                            preventDefault: true,
                            takeUntil: merge(
                                this.destroyedEvent,
                                this.isAllowProfileKeyListenerStream.pipe(
                                    filter(isAllowed => !isAllowed),
                                    take(1),
                                ),
                            ),
                        })
                }
            }),
            takeUntil(this.destroyedEvent),
            shareReplay(1),
        ))
    }

    @Memoize()
    public get isAllowGroupKeyListenerStream(): ReactiveStream<boolean> {
        return this.isGroupMenuShowing$.asStream()
    }

    @Memoize()
    protected get isGroupKeyListenerRegisteredStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.isAllowGroupKeyListenerStream.pipe(
            distinctUntilChanged(
                (prev, next) => compareDeeply(prev, next),
            ),
            tap(isAllowed => {
                if (isAllowed) {
                    this.keyEventsListener.subscribe({
                            'Tab': this.onSelectGroupKey,
                            'Enter': this.onSelectGroupKey,
                            'Up': this.onPreviousGroupKey,
                            'Down': this.onNextGroupKey,
                            'Escape': this.onCloseSearchResultsKey
                        },
                        {
                            priority: 200,
                            allowInputs: true,
                            terminal: true,
                            preventDefault: true,
                            takeUntil: merge(
                                this.destroyedEvent,
                                this.isAllowGroupKeyListenerStream.pipe(
                                    filter(isAllowed => !isAllowed),
                                    take(1),
                                ),
                            ),
                        })
                }
            }),
            takeUntil(this.destroyedEvent),
            shareReplay(1),
        ))
    }

    public onSelectProfileKey = async (): Promise<boolean> => {
        let targetedProfile = await this.targetedProfileStream
        if (this.profileMentions && targetedProfile) {
            this.profileMentions.selectChoice(targetedProfile)
            return true
        }
        return false
    }

    protected onPreviousProfileKey = async () => {
        const [
            profileResults,
            targetedIndex
        ] = await Promise.all([
            this.profileSearchResultsStream,
            this.targetedProfileIndex$
        ])
        if (profileResults?.length) {
            if (targetedIndex === 0) {
                this.targetedProfileIndex$.next(profileResults.length-1)
            } else {
                this.targetedProfileIndex$.next(targetedIndex-1)
            }
            this.scrollToTargetedProfile(
                profileResults[
                    this.targetedProfileIndex$.getValue()
                ]
            )
            return true
        }
        return false
    }

    protected onNextProfileKey = async () => {
        const [
            profileResults,
            targetedIndex
        ] = await Promise.all([
            this.profileSearchResultsStream,
            this.targetedProfileIndex$
        ])
        if (profileResults?.length) {
            if (targetedIndex === profileResults.length-1) {
                this.targetedProfileIndex$.next(0)
            } else {
                this.targetedProfileIndex$.next(targetedIndex+1)
            }
            this.scrollToTargetedProfile(
                profileResults[
                    this.targetedProfileIndex$.getValue()
                ]
            )
            return true
        }
        return false
    }

    public onSelectGroupKey = async (): Promise<boolean> => {
        let targetedGroup = await this.targetedGroupStream
        if (this.groupMentions && targetedGroup) {
            this.groupMentions.selectChoice(targetedGroup)
            return true
        }
        return false
    }

    protected onPreviousGroupKey = async () => {
        const [
            groupResults,
            targetedIndex
        ] = await Promise.all([
            this.groupSearchResultsStream,
            this.targetedGroupIndex$
        ])
        if (groupResults?.length) {
            if (targetedIndex === 0) {
                this.targetedGroupIndex$.next(groupResults.length-1)
            } else {
                this.targetedGroupIndex$.next(targetedIndex-1)
            }
            this.scrollToTargetedGroup(
                groupResults[
                    this.targetedGroupIndex$.getValue()
                ]
            )
            return true
        }
        return false
    }

    protected onNextGroupKey = async () => {
        const [
            groupResults,
            targetedIndex
        ] = await Promise.all([
            this.groupSearchResultsStream,
            this.targetedGroupIndex$
        ])
        if (groupResults?.length) {
            if (targetedIndex === groupResults.length-1) {
                this.targetedGroupIndex$.next(0)
            } else {
                this.targetedGroupIndex$.next(targetedIndex+1)
            }
            this.scrollToTargetedGroup(
                groupResults[
                    this.targetedGroupIndex$.getValue()
                ]
            )
            return true
        }
        return false
    }

    protected onCloseSearchResultsKey = () => {

    }

    public async loadContactChoices(searchTerm: string): Promise<void> {
        const [
            allProfiles,
            profileSearchResults
        ] = await Promise.all([
            this.connectionProfilesStream,
            this.profileSearchResults$
        ])

        this.profileSearchResults$.next(
            allProfiles.filter(profile => {
                const alreadyExists = false // profileSearchResults.some(p => p.email === profile.email)
                return !alreadyExists && [
                    profile.email, profile.profileUrl, profile.firstName, profile.lastName
                ]
                    .toString()
                    .replace(',', '')
                    .toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            })
        )

        setTimeout(async () => {
            let results = await this.profileSearchResults$
            if (results?.length) {
                this.targetedProfileIndex$.next(0)
            }
        })
    }

    public async loadGroupChoices(searchTerm: string): Promise<void> {
        const groups = await this.groupsStream

        this.groupSearchResults$.next(
            groups.filter(group => {
                const alreadyExists = false
                return !alreadyExists && group.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            }).sort((a, b) => a.name <= b.name ? 1 : -1)
        )

        setTimeout(async () => {
            let results = await this.groupSearchResults$
            if (results?.length) {
                this.targetedGroupIndex$.next(0)
            }
        })
    }

    public onSelectedProfilesChange(profileChoices: ChoiceWithIndices<FirestoreUser>[]): void {
        this.selectedProfilesChange.emit(profileChoices.map(c => c.choice))
    }

    public onSelectedChannelsChange(channelChoices: ChoiceWithIndices<UiGroupEntity>[]): void {
        this.selectedGroupsChange.emit(channelChoices.map(c => c.choice))
    }

    public getProfileLabel = (profile: FirestoreUser): string => {
        return `${profile.displayName ?? profile.email}`;
    }

    public getChannelLabel = (channel: IOrganization): string => {
        return `#${channel.name}`
    }

    public async onProfileMenuShow(): Promise<void> {
        this.isProfileMenuShowing$.next(true)
    }

    public onProfileMenuHide(): void {
        this.isProfileMenuShowing$.next(false)
        this.profileSearchResults$.next([])
    }

    protected scrollToTargetedProfile(profile: FirestoreUser) {
        let items = (this.profileResultsList?.nativeElement as HTMLElement).children
        if (items?.length) {
            for (let item of Array.from(items)) {
                if ((item as HTMLElement).dataset['profileid'] === profile.id) {
                    item.scrollIntoView({
                        behavior: 'auto', block: 'center'
                    })
                    break
                }
            }
        }
    }

    public async onGroupMenuShow(): Promise<void> {
        this.isGroupMenuShowing$.next(true)
    }

    public onGroupMenuHide(): void {
        this.isGroupMenuShowing$.next(false)
        this.groupSearchResults$.next([])
    }

    protected scrollToTargetedGroup(group: UiGroupEntity) {
        let items = (this.groupResultsList?.nativeElement as HTMLElement).children
        if (items?.length) {
            for (let item of Array.from(items)) {
                if ((item as HTMLElement).dataset['groupid'] === group.id) {
                    item.scrollIntoView({
                        behavior: 'auto', block: 'center'
                    })
                    break
                }
            }
        }
    }

    public clear() {
        if (this.profileMentions) {
            this.profileMentions.clearHighlights()
        }
        if (this.groupMentions) {
            this.groupMentions.clearHighlights()
        }
    }
}
