<main [ngSwitch]="isDateSelected"
      udDropdown
      #dropdownRef=udDropdownRef
      [dropdownHostEl]="dropdownHostRef"
      [dropdownAutoOpen]="!isDateSelected"
      [overlayContentRef]="scheduleDropdownTmpl"
>
    <ng-container
        *ngSwitchCase="true" [ngTemplateOutlet]="selectedDateTmpl"
    ></ng-container>

    <div class="placeholder clickable"
         *ngSwitchCase="false" (click)="hiddenInput.focus()"
    >
        {{ placeholder }}
    </div>

    <!-- This input is used to handle focus by Tab -->
    <input #hiddenInput class="hidden" tabindex="0"
           (focus)="dropdownRef.open()"
           (keydown.enter)="applyActiveListItem(); dropdownRef.close(); hiddenInput.blur()"
           (keydown.arrowUp)="activatePrevListItem()"
           (keydown.arrowDown)="activateNextListItem()"
           (keydown.arrowLeft)="displayPrevAvailabilityDays()"
           (keydown.arrowRight)="displayNextAvailabilityDays()"
    />

    <div class="actions-box">
        <div class="button popup"
             [class.active]="dropdownRef.isOpened$ | stream"
             (click)="dropdownRef.isOpened ? dropdownRef.close() : hiddenInput.focus()"
        >
            <app-icon-lightning
                    [height]="20" [width]="20"
            ></app-icon-lightning>
        </div>

        <div class="button settings"
             [class.active]="isSettingsOpened | stream"
             (click)="isSettingsOpened.next(!isSettingsOpened.value)"
        >
            <app-icon-gear
                [height]="20" [width]="20" color="var(--settings-icon-color)"
            ></app-icon-gear>
        </div>
    </div>
</main>

<section class="additional-settings" [class.opened]="isSettingsOpened | stream">
    <app-time-time-zone-selector-2
            class="timezone-selector-new"
            [defaultSelect]="true"
            [selectedTimeZone]="timeZone"
            (onSelected)="handleSelectedTimeZoneChange($event)"
    ></app-time-time-zone-selector-2>

    <div class="recurring-settings" *ngIf="state.isDraftModeStream | stream">
        <h5>Recurring settings</h5>

        <mat-select udInput
                    [value]="recurringFrequencyStream | stream"
                    [placeholder]="recurrenceOptions[0][1]"
                    (selectionChange)="changeRecurringFrequency($event.value)"
        >
            <mat-option *ngFor="let option of recurrenceOptions" [value]="option[0]"
            >{{ option[1] }}</mat-option>
        </mat-select>

        <mat-slide-toggle class="limit-occurrences-number-input"
                          [disabled]="!(isRecurrentEventStream | stream)"
                          [checked]="limitRecurrentEventSeriesStream | stream"
                          (toggleChange)="toggleRecurrenceLimit()"
        >Limit number of occurrences</mat-slide-toggle>

<!--        <div class="input-row">-->
<!--            <input class="events-end-date" udInput type="number" spellcheck="false"-->
<!--                   *ngIf="limitRecurrentEventSeriesStream | stream"-->
<!--                   [ngModel]="numberOfEventOccurrencesStream | stream"-->
<!--                   (ngModelChange)="changeNumberOfEventOccurrences($event)"-->
<!--            >-->
<!--        </div>-->

        <div class="input-row" *ngIf="limitRecurrentEventSeriesStream | stream">
            <span class="label">Number of occurences</span>
            <input class="events-limit-number" udInput type="number" spellcheck="false"
                   [ngModel]="numberOfEventOccurrencesStream | stream"
                   (ngModelChange)="changeNumberOfEventOccurrences($event)"
            />
        </div>
    </div>
</section>

<ng-template #selectedDateTmpl>
    <div class="selected-meeting-date-box">
        <div class="date-text date">
            <app-date-picker
                    [tabIndex]="-1"
                    [showAsModal]="true"
                    [date]="schedule.start"
                    [dateFormat]="dateFormat"
                    [minDate]="'1900-01-01T00:00:00Z'"
                    (onDateSelected)="handleDateSelected($event)"
            ></app-date-picker>
        </div>

        <div class="date-text time">
            <app-meet-time-slot-selector
                    [step]="15"
                    [tabIndex]="-1"
                    [labelFormat]="timeFormat"
                    [selectedTime]="schedule.start"
                    (onSlotSelected)="handleStartTimeChange($event)"
            ></app-meet-time-slot-selector>
        </div>

        <span class="separator">–</span>

        <div class="date-text time">
            <app-meet-time-slot-selector
                    [step]="15"
                    [tabIndex]="-1"
                    [labelFormat]="timeFormat"
                    [selectedTime]="schedule.end"
                    (onSlotSelected)="handleEndTimeChange($event)"
            ></app-meet-time-slot-selector>
        </div>
    </div>
</ng-template><!-- selectedDateTmpl -->

<ng-template #scheduleDropdownTmpl>
    <app-meet-event-form-available-slot-selector
        (onSlotSelected)="selectAvailabilitySlot($event); dropdownRef.close()"
    ></app-meet-event-form-available-slot-selector>
<!--    <div class="event-form-schedule-dropdown">-->
<!--        <header>-->
<!--            <app-arrow-button-->
<!--                    [height]="20" [width]="20"-->
<!--                    [direction]="'left'" color="var(&#45;&#45;arrow-icon-color)"-->
<!--                    (onClick)="displayPrevAvailabilityDays()"-->
<!--            ></app-arrow-button>-->

<!--            <span>-->
<!--                {{ selectedAvailabilityDayStream | stream | moment: 'MMM D, YYYY' }}-->
<!--            </span>-->

<!--            <app-arrow-button-->
<!--                    [height]="20" [width]="20"-->
<!--                    [direction]="'right'" color="var(&#45;&#45;arrow-icon-color)"-->
<!--                    (onClick)="displayNextAvailabilityDays()"-->
<!--            ></app-arrow-button>-->
<!--        </header>-->

<!--        <main [ngSwitch]="isAvailabilityLoadingStream | stream | boolean">-->
<!--            <div class="available-slots-list" *ngSwitchCase="true">-->
<!--                <ngx-skeleton-loader-->
<!--                    *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]"-->
<!--                    [theme]="{height: '40px', width: '100%', borderRadius: '8px', margin: '0 0 2px', backgroundColor: '#FBFBFD'}"-->
<!--                ></ngx-skeleton-loader>-->
<!--            </div>-->

<!--            <ng-container *ngSwitchCase="false">-->
<!--                <div class="available-slots-list"-->
<!--                     *ngIf="availabilityStream | stream as availability"-->
<!--                >-->
<!--                    <div>-->
<!--                        <ng-container *ngFor="let slot of availability.slots">-->
<!--                            <div class="available-slot clickable"-->
<!--                                 [class.best]="slot.best"-->
<!--                                 [class.event]="!slot.free"-->
<!--                                 [class.active]="(selectedItem$ | stream) === slot"-->
<!--                                 (click)="selectAvailabilitySlot(slot); dropdownRef.close()"-->
<!--                            >-->
<!--                                <span>{{ slot.label }}</span>-->

<!--                                <app-icon-lightning-->
<!--                                        *ngIf="slot.free"-->
<!--                                        [height]="20" [width]="20"-->
<!--                                        color="var(&#45;&#45;lightning-icon-color)"-->
<!--                                ></app-icon-lightning>-->
<!--                            </div>-->
<!--                        </ng-container>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-container>-->

<!--            <div class="custom-time-btn clickable"-->
<!--                 *ngIf="!isDateSelected" (click)="useCustomTime(); dropdownRef.close()"-->
<!--            >-->
<!--                Custom time-->

<!--                <span class="hot-key">-->
<!--                    <app-icon-special-key></app-icon-special-key> + ⌥-->
<!--                </span>-->
<!--            </div>-->
<!--        </main>-->
<!--    </div>-->
</ng-template><!-- scheduleDropdownTmpl -->

