import {
    Collection,
    Entity,
    Field,
    ID,
    SearchField,
} from '@typeheim/orm-on-fire'

import { SoftDelete } from '@undock/core'
import { PublicProfileData } from '@undock/user/contracts/public-profile-data.interface'


export const USER_COLLECTION = 'user'

@Entity({
    collection: USER_COLLECTION,
})
export class FirestoreUser implements SoftDelete, PublicProfileData {
    @ID() id: string

    /**
     * @TODO: We should refactor all short id usage places
     * @deprecated
     */
    @Field() shortId: string
    @Field() firebaseId: string

    @Field() username: string
    @Field() lastName: string
    @Field() firstName: string
    @Field() displayName: string

    @Field() isGuest: boolean = false
    @Field() isVerified: boolean = false
    @Field() isRegularUser: boolean = true

    @SearchField() email: string
    @Field() timeZone: string
    @Field() phoneNumberFull: string

    @Field() imageUrl: string
    @Field() profileUrl: string

    /**
     * For SoftDelete implementation
     */
    @Field() removed: boolean = false

    /**
     * @TODO: maybe we should move this fields
     */
    @Field() onboardingNextStep: string
    @Field() onboardingComplete: boolean

    @Field() signUpComplete: boolean
    @Field() calendarInitialized: boolean

    @Field() createdAt: Date
    @Field() updatedAt: Date

    public toPublicProfileData(): PublicProfileData {
        return {
            id: this.id,
            uid: this.firebaseId,

            isGuest: this.isGuest,
            isVerified: this.isVerified,
            isUndockUser: !this.isGuest,
            isRegularUser: !this.isGuest,

            email: this.email,
            imageUrl: this.imageUrl,
            lastName: this.firstName,
            firstName: this.firstName,
            displayName: this.displayName,
        }
    }
}
