<mat-select class="customArrow" udInput
            [value]="value"
            (selectionChange)="onMeetingDurationValueChange($event.value)"
>
    <ng-container
        *ngIf="{
            isPaymentEnabled: schedule?.paymentSettings?.requirePayment,
            rates: schedule?.paymentSettings?.rates
        } as schedule"
    >
        <ng-container *ngFor="let option of options">
            <mat-option class="__duration-select"
                        *ngIf="option.enabled"
                        [value]="option.value"
                        [disabled]="!option.enabled"
            >
                <ng-container *ngIf=" schedule.isPaymentEnabled && schedule.rates[option.value] > 0">
                    {{ schedule.rates[option.value] | currency: paymentGroup?.currency ?? 'USD' : 'symbol': '1.0-0' }} /
                </ng-container>

                {{ option.value - option.gap | meetingDuration }}
            </mat-option>
        </ng-container>
    </ng-container>
</mat-select>
