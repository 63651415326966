import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

import { Validations } from '@undock/core'
import { PublicProfileData } from '@undock/user'


@Component({
    selector: 'app-user-avatar',
    templateUrl: 'avatar.component.html',
    styleUrls: ['avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {

    @Input() profileData: PublicProfileData

    @Input() useThumbnail: boolean = true

    @Input() placeholder = '/assets/img/placeholders/user.png'

    public get imageUrl(): string {
        return this.profileData?.imageUrl ?? ''
    }

    public get isDisplayed(): boolean {
        return Boolean(this.profileData)
    }

    public get displayName(): string {
        if (this.profileData.displayName) {
            return this.profileData.displayName
        }

        if (this.profileData.firstName || this.profileData.lastName) {
            return `${this.profileData.firstName} ${this.profileData.lastName}`.trim()
        }

        return Validations.isNotEmptyString(this.profileData.email) ? this.profileData.email : 'Anonymous'
    }
}
