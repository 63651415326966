import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-calendar-requested',
    templateUrl: 'calendar-requested.component.html',
    styleUrls: ['calendar-requested.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarRequestedIconComponent extends IconTemplateComponent {}
