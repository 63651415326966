<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 161 153" fill="none">
    <path d="M78.4078 124.488C112.869 124.488 140.816 96.7509 140.816 62.5002C140.816 28.2495 112.869 0.512695 78.4078 0.512695C43.9469 0.512695 16 28.2495 16 62.5002C16 96.7509 43.9469 124.488 78.4078 124.488Z"
          fill="url(#paint0_linear_1891:22505)"/>
    <path d="M135.906 47.1534V104.035C135.906 104.27 135.906 104.387 135.906 104.622C135.788 107.906 134.264 110.721 131.918 112.714C129.924 114.474 127.227 115.646 124.295 115.646H32.4624C30.4686 115.646 28.592 115.177 26.9501 114.239C26.2464 113.887 25.5427 113.418 24.9563 112.832C23.6662 111.776 22.4933 110.251 21.9069 108.609C21.4378 107.437 21.0859 106.147 21.0859 104.856V47.1534C21.0859 47.1534 25.8945 43.0485 32.8142 37.4189C39.9684 31.4375 49.3511 23.6968 57.5608 16.8944C62.2521 12.9068 61.783 13.376 69.0545 7.27727C74.5668 2.58596 82.6593 2.58596 88.1716 7.27727C89.579 8.4501 91.1037 9.85749 92.5111 11.0303C95.5604 13.4932 98.8443 16.308 102.363 19.2401C109.752 25.3388 117.844 32.1412 124.177 37.4189C130.628 42.6966 135.319 46.6842 135.906 47.1534Z"
          fill="#BBB4D5"/>
    <path d="M124.176 37.3017V57.7089L104.121 69.789L98.1391 73.4248L85.4726 81.0481L79.4912 84.6839L76.911 86.2086L74.448 84.6839L68.8185 81.0481L56.7383 73.4248L51.1088 69.789L32.5781 58.178V37.419C39.7324 31.4376 49.115 23.6969 57.3248 16.8945L102.244 19.1229C109.75 25.3389 117.843 32.024 124.176 37.3017Z"
          fill="#A89FC7"/>
    <g filter="url(#filter0_d_1891:22505)">
        <path d="M120.189 21.1166V57.0051L101.893 67.9124L96.4977 71.1963L84.8868 77.9987L79.4918 81.2826L77.1461 82.5727L74.9177 81.2826L69.7573 77.9987L58.7327 71.1963L53.5723 67.9124L36.6836 57.3569V21.1166C36.6836 17.3635 39.7329 14.3142 43.6033 14.3142H113.269C117.022 14.1969 120.189 17.3635 120.189 21.1166Z"
              fill="url(#paint1_linear_1891:22505)"/>
    </g>
    <path opacity="0.3"
          d="M21.5547 46.9189L76.6775 50.5547L135.084 46.9189L132.856 57.3571C132.856 57.3571 77.9677 85.9741 78.3195 85.9741C78.6713 85.9741 21.672 52.0794 21.672 52.0794L21.5547 46.9189Z"
          fill="url(#paint2_linear_1891:22505)"/>
    <g clip-path="url(#clip0_1891:22505)">
        <path d="M90.2438 37.1379L81.0281 31.823C79.4719 30.9255 77.5382 30.9256 75.9819 31.823L66.7662 37.1379C65.2069 38.0371 64.2383 39.714 64.2383 41.5141V52.1356C64.2383 53.9358 65.2069 55.6127 66.7661 56.5119L75.9819 61.8267C77.5384 62.7244 79.4721 62.7241 81.0281 61.8267L90.2438 56.5119C91.8031 55.6127 92.7717 53.9358 92.7717 52.1356V41.5141C92.7717 39.714 91.8031 38.0371 90.2438 37.1379ZM90.3225 52.1356C90.3225 53.0629 89.8236 53.9267 89.0203 54.3898L79.8046 59.7047C79.0029 60.1671 78.007 60.1671 77.2053 59.7047L67.9896 54.3898C67.1864 53.9266 66.6875 53.0629 66.6875 52.1356V41.5141C66.6875 40.5869 67.1864 39.7232 67.9896 39.26L77.2053 33.9451C78.0071 33.4827 79.0033 33.4829 79.8047 33.9451L89.0204 39.2599C89.8236 39.7232 90.3225 40.5869 90.3225 41.5141V52.1356Z"
              fill="#BDB5D5"/>
        <path d="M77.3413 45.89L72.793 42.0408C72.2768 41.6039 71.504 41.6682 71.0671 42.1847C70.6302 42.7011 70.6947 43.4739 71.211 43.9108L74.6544 46.825L71.211 49.7391C70.6947 50.1761 70.6303 50.9489 71.0671 51.4653C71.505 51.9828 72.278 52.045 72.793 51.6091L77.3413 47.7599C77.9199 47.2703 77.9183 46.3783 77.3413 45.89Z"
              fill="#BDB5D5"/>
        <path d="M85.0085 49.4492H79.498C78.8217 49.4492 78.2734 49.9975 78.2734 50.674C78.2734 51.3505 78.8217 51.8988 79.498 51.8988H85.0085C85.6848 51.8988 86.2331 51.3505 86.2331 50.674C86.2331 49.9975 85.6848 49.4492 85.0085 49.4492Z"
              fill="#BDB5D5"/>
    </g>
    <path d="M135.906 47.1533V104.622C135.788 107.906 134.264 110.721 131.918 112.714C129.924 114.474 127.227 115.646 124.295 115.646H32.4624C30.4686 115.646 28.592 115.177 26.9501 114.239C26.2464 113.887 25.5427 113.418 24.9563 112.832C23.6662 111.776 22.4933 110.251 21.9069 108.609C21.4378 107.437 21.0859 106.146 21.0859 104.856V104.739V47.1533L72.4557 79.2888L76.9125 82.1036L78.4372 81.1653L79.8445 80.3443L135.906 47.1533Z"
          fill="#738DFF"/>
    <path d="M132.035 112.714C130.042 114.474 127.344 115.646 124.412 115.646H32.4624C30.4686 115.646 28.592 115.177 26.9501 114.239C26.2464 113.887 25.5427 113.418 24.9563 112.832C23.6662 111.776 22.4933 110.251 21.9069 108.609C21.4378 107.437 21.0859 106.146 21.0859 104.856V104.739V47.1533L27.0674 50.7891L78.4372 81.1653L79.8446 81.9863L79.9618 82.1036L126.054 109.313L132.035 112.714Z"
          fill="url(#paint3_linear_1891:22505)"/>
    <path d="M79.8439 80.2273V84.2149L26.9494 114.239C26.2457 113.887 25.542 113.418 24.9556 112.832C23.6655 111.776 22.4927 110.252 21.9062 108.61L73.3933 78.1162L77.8501 80.931L78.4365 81.1656L79.8439 80.2273Z"
          fill="url(#paint4_linear_1891:22505)"/>
    <path d="M21.0859 47.1533L78.4372 81.1653" stroke="white" stroke-width="0.7125" stroke-miterlimit="10"/>
    <g filter="url(#filter1_d_1891:22505)">
        <path d="M135.906 47.1533V104.622C135.789 107.906 134.264 110.721 131.919 112.714C129.925 114.474 127.228 115.646 124.295 115.646H32.4631C30.4693 115.646 28.5928 115.177 26.9508 114.239C26.2471 113.887 25.5434 113.418 24.957 112.832L30.8212 109.313L76.9132 82.1036L78.4379 81.1653L79.8453 80.3443L129.808 50.6718L135.906 47.1533Z"
              fill="url(#paint5_linear_1891:22505)"/>
    </g>
    <path d="M135.906 47.1533L79.8453 80.227L78.4379 81.1653L76.9132 82.1036L24.957 112.714" stroke="white"
          stroke-width="0.7125" stroke-miterlimit="10"/>
    <path d="M122.069 87.3815H91.5753C90.8716 87.3815 90.2852 86.7951 90.2852 86.0914C90.2852 85.3877 90.8716 84.8013 91.5753 84.8013H122.069C122.772 84.8013 123.359 85.3877 123.359 86.0914C123.476 86.7951 122.89 87.3815 122.069 87.3815Z"
          fill="#F3F0FB"/>
    <path d="M122.069 96.1774H91.5753C90.8716 96.1774 90.2852 95.591 90.2852 94.8873C90.2852 94.1836 90.8716 93.5972 91.5753 93.5972H122.069C122.772 93.5972 123.359 94.1836 123.359 94.8873C123.476 95.591 122.89 96.1774 122.069 96.1774Z"
          fill="#F3F0FB"/>
    <defs>
        <filter id="filter0_d_1891:22505" x="12.2921" y="2.11531" width="132.287" height="117.045"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dy="12.1957"/>
            <feGaussianBlur stdDeviation="12.1957"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.803589 0 0 0 0 0.776597 0 0 0 0 0.879167 0 0 0 0.6 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1891:22505"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1891:22505" result="shape"/>
        </filter>
        <filter id="filter1_d_1891:22505" x="0.565573" y="34.9576" width="159.732" height="117.276"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dy="12.1957"/>
            <feGaussianBlur stdDeviation="12.1957"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.803589 0 0 0 0 0.776597 0 0 0 0 0.879167 0 0 0 0.4 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1891:22505"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1891:22505" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_1891:22505" x1="78.4078" y1="0.512695" x2="78.4078" y2="124.488"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="#EDE9F7"/>
            <stop offset="1" stop-color="#E2DCF1"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1891:22505" x1="54.3102" y1="20.1068" x2="73.3609" y2="85.7073"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.9964" stop-color="#F1EEFA"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1891:22505" x1="78.2691" y1="98.3559" x2="78.2691" y2="49.5508"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="#BCB5D4"/>
            <stop offset="1" stop-color="#BCB5D4" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1891:22505" x1="44.5055" y1="52.9688" x2="59.4404" y2="121.067"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.9964" stop-color="#F1EEFA"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1891:22505" x1="54.1835" y1="99.4858" x2="49.6863" y2="92.9036"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="#BDB6D5" stop-opacity="0.41"/>
            <stop offset="1" stop-color="#FDFCFE" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint5_linear_1891:22505" x1="48.3766" y1="52.9688" x2="64.9456" y2="119.123"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.9964" stop-color="#FAF8FF"/>
        </linearGradient>
        <clipPath id="clip0_1891:22505">
            <rect width="31.35" height="31.35" fill="white" transform="translate(62.8281 31.1499)"/>
        </clipPath>
    </defs>
</svg>
