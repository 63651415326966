import { ApiRoute } from '../../../services/api.route'
import { DockExportResult } from '../contracts/export-result'

export class ExportRoute extends ApiRoute {
    public init() {
        this.route = 'meetings/export'
    }

    public async toPDF(dockId: string): Promise<DockExportResult> {
        return this.get<DockExportResult>().endpoint(`/to-pdf/${dockId}`)
    }
}
