import { ApiContext } from '@undock/api/contracts/api.context'
import { OwnPlansRoute } from '@undock/api/scopes/plans/routes/own-plans.route'
import { PublicPlansRoute } from '@undock/api/scopes/plans/routes/public-plans.route'
import { SharedPlansRoute } from '@undock/api/scopes/plans/routes/shared-plans.route'


export class PlansScope {
    public readonly own: OwnPlansRoute
    public readonly public: PublicPlansRoute
    public readonly shared: SharedPlansRoute

    public constructor(
        protected context: ApiContext,
    ) {
        this.own = new OwnPlansRoute(context)
        this.public = new PublicPlansRoute(context)
        this.shared = new SharedPlansRoute(context)
    }
}
