import { UserData } from '@undock/user'

export const anonymousUserData = {
    isGuest: true,
    isRemoved: false,
    isUndockUser: false,
    isRegularUser: false,

    email: null,
    lastname: '',
    lastName: '',
    firstName: 'Anonymous',
    firstname: 'Anonymous',
    displayName: 'Anonymous',
    imageUrl: '/assets/img/placeholders/user.png',
} as UserData
