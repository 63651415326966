import { ApiRoute } from '@undock/api'
import {
    IForm,
    IFormReply,
} from '@undock/common/form-creator/types'
import { UserDataInterface } from '@undock/api/scopes/user/contracts/user.interface'


export class FormsScope extends ApiRoute {

    public init() {
        this.route = 'forms'
    }

    public listOwn(
        page: number = 0,
        pageSize: number = 50,
    ) {
        return this.get<IForm[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                   })
                   .endpoint('list-own')
    }

    public getById(id: string) {
        return this.get<IForm>().endpoint(`id/${id}`)
    }

    public getByUrl(url: string) {
        return this.get<IForm>().endpoint(`url/${url}`)
    }

    public create(data: Record<string, any>) {
        return this.post<IForm>().withData(data).endpoint('')
    }

    public updateById(id: string, updates: Record<string, any>) {
        return this.patch<IForm>().withData(updates).endpoint(`${id}`)
    }

    public deleteById(id: string) {
        return this.delete().endpoint(`${id}`)
    }

    public reply(data: CreateReplyData) {
        return this.post()
                   .withData(data)
                   .endpoint(`reply`)
    }

    public listReplies(
        formId: string,
        page: number = 0,
        pageSize: number = 50,
    ) {
        return this.get<IFormReply[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                   })
                   .endpoint(`reply/list/${formId}`)
    }

    public deleteReplyById(replyId: string) {
        return this.delete<void>()
                   .endpoint(`reply/${replyId}`)
    }
}


export interface CreateReplyData {
    formId: string
    status?: string
    payload: Array<any>
    userData: UserDataInterface
}
