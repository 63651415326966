import {
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay'
import {
    EventEmitter,
    Injectable,
    Injector,
    ViewContainerRef,
} from '@angular/core'
import { ComponentPortal } from '@angular/cdk/portal'

import { CalendarEvent } from 'angular-calendar'

import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'

import { UiTimelineEvent } from '@undock/dashboard/contracts'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import {
    DashboardCalendarEventCardComponent,
    DashboardCalendarEventPopupComponent,
} from '@undock/dashboard/ui/pages/calendar/components/event-details'
import { take } from 'rxjs/operators'


@Injectable()
export class DashboardCalendarDetailsManager {

    public readonly onDetailsClosed = new EventEmitter<void>()

    /**
     * For popup mode
     */
    protected overlayRef: OverlayRef

    /**
     * For inline mode
     */
    protected hostContainerRef: ViewContainerRef

    protected currentComponent: DashboardCalendarEventCardComponent

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        private overlay: Overlay,
        private injector: Injector,
    ) {}

    public initialize() {
        /**
         * Remove previous overlay ref
         */
        if (this.overlayRef) {
            this.overlayRef.dispose()
            this.overlayRef = undefined
        }

        const positionStrategy = this.overlay
                                     .position()
                                     .global()
                                     .centerVertically()
                                     .centerHorizontally()

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            hasBackdrop: false,
        })
    }

    public forceClose(): void {
        this.overlayRef?.detach()
        this.hostContainerRef?.clear()
        delete this.currentComponent
        this.onDetailsClosed.emit(null)
    }

    public async requestClose(): Promise<boolean> {
        if (this.currentComponent) {
            return this.currentComponent.requestClose()
        }
        return true
    }

    public async open(
        event: CalendarEvent<{ payload: UiTimelineEvent }>,
        updates?: Partial<EditMeetingData>,
        mode: 'popup' | 'inline' = 'popup',
    ) {
        /**
         * Inline mode for Calendar Day View
         */
        if (mode === 'inline') {
            return this.openInViewContainer(
                this.hostContainerRef, event, updates,
            )
        }

        return this.openInPopup(event, updates)
    }

    public updateEditPopupData(updates: Partial<EditMeetingData>) {
        return this.currentComponent?.updateEventData(updates)
    }

    public setHostViewContainer(viewContainerRef: ViewContainerRef) {
        this.hostContainerRef = viewContainerRef
    }

    protected async openInPopup(
        event: CalendarEvent<{ payload: UiTimelineEvent }>,
        updates?: Partial<EditMeetingData>,
    ) {
        if (this.overlayRef) {
            this.overlayRef.detach()

            /**
             * Initializing popup window
             */
            const componentRef = this.overlayRef.attach(
                new ComponentPortal(
                    DashboardCalendarEventPopupComponent,
                    undefined,
                    this.injector,
                ),
            )

            /**
             * Destroying popup when the component is closed
             */
            componentRef.instance.onClose
                        .subscribe(() => {
                            this.overlayRef.detach()
                            this.onDetailsClosed.emit()
                            this.hostContainerRef.clear()
                        })

            /**
             * Destroying popup when the component is destroyed
             */
            this.destroyEvent.subscribe(() => componentRef.destroy())

            /**
             * Initialize the component with event data
             */
            await componentRef.instance.initWithGridEvent(event)

            if (updates) {
                await componentRef.instance.updateEventData(updates)
            }

            /**
             * Saving current component
             */
            this.currentComponent = componentRef.instance
        } else {
            throw new Error(`Overlay isn't initialized yet.`)
        }
    }

    protected async openInViewContainer(
        viewContainerRef: ViewContainerRef,
        event: CalendarEvent<{ payload: UiTimelineEvent }>,
        updates?: Partial<EditMeetingData>,
    ) {
        if (!viewContainerRef) {
            throw new Error(`View Container isn't initialized yet.`)
        }

        const componentRef = viewContainerRef.createComponent(
            DashboardCalendarEventCardComponent
        )

        componentRef.instance.onClose.pipe(
            take(1),
        ).subscribe(() => {
            viewContainerRef.clear()
            this.onDetailsClosed?.emit()
        })

        /**
         * Destroying popup when the component is destroyed
         */
        this.destroyEvent.subscribe(() => componentRef.destroy())

        /**
         * Initialize the component with event data
         */
        await componentRef.instance.initWithGridEvent(event)

        if (updates) {
            await componentRef.instance.updateEventData(updates)
        }

        /**
         * Saving current component
         */
        this.currentComponent = componentRef.instance
    }
}
