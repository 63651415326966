<ng-container *ngIf="{
    isLoggedIn: isLoggedInStream | stream
} as view">
    <div class="container-fluid not-found-container" style="display: flex">

        <div class="top-header">
            <app-logo-undock></app-logo-undock>

            <ng-container *ngIf="view.isLoggedIn === false">
                <div class="__buttons" [@fade]="view.isLoggedIn === false">
                    <div class="__button __sign-in">
                        <a [routerLink]="['/', 'login']">Sign In</a>
                    </div>
                    <div class="__button __sign-up">
                        <a [routerLink]="'/signup'">Get started – It’s Free</a>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="not-found-image">
            <app-icon-not-found [width]="140" [height]="140"></app-icon-not-found>

            <h2 class="no-found-header">Page not found</h2>

            <span class="no-found-sub-title">The page you are looking for has moved or no longer exists</span>

            <a mat-button class="navigation-link" [routerLink]="['/', 'dashboard']">Open home page</a>

            <ng-container *ngIf="view.isLoggedIn === false">
                <div class="call-to-action" [@fade]="view.isLoggedIn === false">

                    <div class="__image-box">
                        <img class="guest-image" src="/assets/img/c-to-a.png" alt="hero">
                    </div>

                    <div class="__info-box">

                        <h3>
                            <span>
                                Undock helps you find time to meet with anyone.
                            </span>
                        </h3>

                        <p class="subtitle">
                            Undock helps you find time to meet with anyone. See suggestions for when you and Ronald are both free — wherever you type!
                        </p>

                        <div class="profile-confirm-join-undock-button">
                            <a [routerLink]="'/signup'">
                                Join Undock – It’s Free
                            </a>
                        </div>
                    </div>

                </div>
            </ng-container>

        </div>
    </div>
</ng-container>

