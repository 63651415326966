import { ApiRoute } from '@undock/api/services/api.route'
import { InviteSuggestion } from '@undock/invites/contracts/invite-suggestion.interface'


export class InvitesRoute extends ApiRoute {
    public init() {
        this.route = 'user/invites'
    }

    public async sendInviteViaEmail(email: string): Promise<void> {
        return this.post<void>()
                   .withData({ email  })
                   .endpoint(`send-by-email`)
    }

    public async sendInviteViaEmails(emails: string[]): Promise<void> {
        return this.post<void>()
                   .withData({ emails  })
                   .endpoint(`send-by-emails`)
    }

    public async sendInviteToSuggestion(uid: string): Promise<InviteSuggestion> {
        return this.put<InviteSuggestion>()
                   .withData({ uid })
                   .endpoint(`send`)
    }

    public async dismissInviteSuggestion(uid: string): Promise<InviteSuggestion> {
        return this.put<InviteSuggestion>()
                   .endpoint(`dismiss/${uid}`)
    }

    public async getInviteSuggestions(count: number = 4, offset: number = 0): Promise<InviteSuggestion[]> {
        return this.get<InviteSuggestion[]>()
                   .endpoint(`suggestions/${count}/${offset}`)
    }
}
