import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'


@Component({
    selector: 'app-icon-select-button',
    templateUrl: 'icon-select-button.component.html',
    styleUrls: ['icon-select-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSelectButtonComponent implements OnInit {

    private _toggled: boolean = false

    @Input() label: string
    @Input() image: string
    @Input() description: string
    @Input() position: 'left' | 'right' | 'none' = 'left'

    @Input() mode: 'action' | 'toggle' | 'dropdown' = 'action'

    @Input() disabled: boolean = false
    @Input() setLabelManually: boolean = false

    @Input() labelContent: string = 'center'
    @Input() width: string = ''
    @Input() height: string = ''
    @Input() labelPadding: string = '0'
    @Input() zIndex: string = 'unset'
    @Input() fontSize: string = '3rem'
    @Input() fontWeight: string = '400'
    @Input() borderRadius: string = '8px'
    @Input() borderWidth: string = ''
    @Input() border: string = ''
    @Input() borderColor: string = '#E4DFF2'
    @Input() backgroundColor: string = '#FBFBFD'
    @Input() labelBackground: string = '#FBFBFD'
    @Input() listFontSize: string = '14px'

    @Input() labelBadge?: string

    @Input() largeSize: boolean = false
    @Input() expandedMode: boolean = false

    ///////////////
    // DROP DOWN //

    @Input() values: {
        value: any,
        label: string
    }[]
    @Input() currentValue: any

    @Output() currentValueChange = new EventEmitter<any>()
    @Output() toggleChanged = new EventEmitter<boolean>()
    @Output() action = new EventEmitter<any>()

    public dropDownSelectedValue: string
    public dropDownValues: string
    public dropDownShowing: boolean = false

    constructor(
        public changeRef: ChangeDetectorRef,
        public el: ElementRef,
    ) { }

    @Input()
    public set toggled(value: boolean) {
        this._toggled = value
        this.changeRef.detectChanges()
    }

    public get toggled(): boolean {
        return this._toggled
    }

    ngOnInit() {
        this.dropDownShowing = false

        if (this.values && this.values.length > 0 && !this.currentValue) {
            this.currentValue = this.values[0].value
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes && !this.setLabelManually) {
            if (changes.currentValue) {
                if (this.values && this.values.length > 0) {
                    let index = this.values.findIndex(v => v.value === changes.currentValue.currentValue)

                    if (index !== -1) {
                        this.label = this.values[index].label
                    } else {
                        this.label = changes.currentValue.currentValue
                    }
                }
            }
        }
    }

    public clickButton() {
        if (this.mode === 'toggle') {
            this.toggleChanged.emit(!this._toggled)
        } else if (this.mode === 'action') {
            this.action.emit(true)
        }
    }

    public showDropDown(event) {
        if (this.mode === 'dropdown' && !this.disabled) {
            this.dropDownShowing = !this.dropDownShowing
            if (event) {
                event.stopPropagation()
            }
        }
    }

    public selectValue(event, value: any) {
        this.currentValue = value
        this.currentValueChange.emit(value)

        if (event) {
            event.stopPropagation()
        }

        this.dropDownShowing = false
    }

    private scrollToValue(value: any) {
        if (value) {
            let element = this.el.nativeElement.querySelector('[data-value=\'' + value + '\']')
            if (element) {

                element.scrollIntoView()
            }
        }
    }
}
