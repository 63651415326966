import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-forum-broadcast',
    templateUrl: 'forum-broadcast.component.html',
    styleUrls: ['forum-broadcast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForumBroadcastIconComponent {
    @Input() height: number|string = '20'
    @Input() width: number|string = '20'
    @Input() color: string = '#2B2733'
}
