import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-key-label',
    templateUrl: 'key-label.component.html',
    styleUrls: ['key-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyLabelComponent {

    @Input() label: string = null

}
