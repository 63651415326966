import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'
import { AuthModule } from '@undock/auth/auth.module'
import { CoreModule } from '@undock/core/core.module'

import { Api } from '@undock/api/services/api.service'
import { UndockClient } from './services/undock.client'
import { AuthHeaders } from '@undock/api/models/auth-headers'

@NgModule({
    imports: [
        CoreModule,
        AuthModule,
    ],
})
export class ApiModule {
    static forRoot(): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                Api,
                UndockClient,
                AuthHeaders,
            ],
        }
    }
}
