import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-compare-calendar',
    templateUrl: 'compare-calendar.component.html',
    styleUrls: ['compare-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareCalendarIconComponent extends IconTemplateComponent {}
