<div class="availability-slot-warning-icon-container"
     [style.width]="width"
     [style.height]="height"
     [style.background]="backgroundColor"
>
    <svg xmlns="http://www.w3.org/2000/svg"
         [attr.viewBox]="viewBox"
         [style.fill]="'transparent'"
         [style.width]="width"
         [style.height]="height">

        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00013 3.12213C5.51419 3.73274 4.96899 4.67045 4.18677 6.02157L3.79122 6.70477C3.0062 8.06072 2.46237 9.00407 2.17377 9.73207C1.88764 10.4539 1.93715 10.7582 2.05206 10.9575C2.16697 11.1568 2.40555 11.3521 3.17356 11.4661C3.94819 11.5811 5.03706 11.5831 6.60386 11.5831H7.39495C8.96175 11.5831 10.0506 11.5811 10.8253 11.4661C11.5933 11.3521 11.8318 11.1568 11.9467 10.9575C12.0617 10.7582 12.1112 10.4539 11.825 9.73207C11.5364 9.00406 10.9926 8.06072 10.2076 6.70477L9.81204 6.02156C9.02982 4.67045 8.48462 3.73274 7.99868 3.12213C7.51708 2.51698 7.22927 2.4082 6.9994 2.4082C6.76954 2.4082 6.48172 2.51698 6.00013 3.12213ZM4.82644 2.18807C5.40266 1.46403 6.07616 0.908203 6.9994 0.908203C7.92264 0.908203 8.59615 1.46402 9.17236 2.18807C9.73597 2.89628 10.3357 3.9323 11.0775 5.21352L11.1102 5.27001L11.5057 5.95322L11.5385 6.00976C12.283 7.29575 12.8849 8.33535 13.2195 9.17929C13.5614 10.0418 13.7085 10.905 13.2462 11.7067C12.784 12.5085 11.9633 12.8136 11.0455 12.9499C10.1475 13.0832 8.94627 13.0832 7.46032 13.0831H7.4603H7.39495H6.60386H6.53851H6.53849C5.05254 13.0832 3.85128 13.0832 2.95329 12.9499C2.03555 12.8136 1.21482 12.5085 0.752573 11.7067C0.29033 10.905 0.437429 10.0418 0.779342 9.17929C1.1139 8.33535 1.7158 7.29575 2.46035 6.00976L2.49308 5.95322L2.88863 5.27001L2.92133 5.21352L2.92133 5.21352C3.66306 3.93229 4.26283 2.89628 4.82644 2.18807ZM6.9994 4.24981C7.41361 4.24981 7.7494 4.58559 7.7494 4.99981V7.66647C7.7494 8.08069 7.41361 8.41647 6.9994 8.41647C6.58518 8.41647 6.2494 8.08069 6.2494 7.66647V4.99981C6.2494 4.58559 6.58518 4.24981 6.9994 4.24981ZM6.9994 10.3331C7.36759 10.3331 7.66607 10.0347 7.66607 9.66647C7.66607 9.29828 7.36759 8.99981 6.9994 8.99981C6.63122 8.99981 6.33274 9.29828 6.33274 9.66647C6.33274 10.0347 6.63122 10.3331 6.9994 10.3331Z" [attr.fill]="color"/>
    </svg>
</div>

