import {
    Observable,
    Subject,
} from 'rxjs'

import {
    DestroyEvent,
    ValueSubject,
} from '@typeheim/fire-rx'

import { AbstractStorage } from '../contracts/storage/abstract-storage.interface'

export abstract class ReactiveStorage<T extends AbstractStorage> {

    protected values: Record<string, any> = {}

    protected defaults: Record<string, any> = {}

    protected valueStreams: Record<string, ValueSubject<any>> = {}

    protected abstract STORAGE_KEY: string

    protected readonly destroyEvent = new DestroyEvent()

    protected constructor(
        protected storage: T,
    ) {}

    protected loadSettings() {
        let data = Object.assign({}, this.defaults,
            JSON.parse(this.storage.getItem(this.STORAGE_KEY)) ?? {},
        )

        Object.keys(data).forEach(key => {
            if (!(this.valueStreams[key] instanceof Subject)) {
                this.valueStreams[key] = new ValueSubject<any>(data[key]).emitUntil(this.destroyEvent)
            }
        })

        this.values = data
    }

    protected getSettingValueStream(key: string): Observable<any> {
        if (!this.valueStreams[key]) {
            this.valueStreams[key] = new ValueSubject<any>(this.defaults[key]).emitUntil(this.destroyEvent)
        }

        return this.valueStreams[key].asObservable()
    }

    protected getSettingValue(key: string): any {
        return this.values[key] ?? null
    }

    protected setSettingValue(key: string, value: any): void {
        if (!this.valueStreams[key]) {
            this.valueStreams[key] = new ValueSubject<any>(value).emitUntil(this.destroyEvent)
        }

        this.values[key] = value
        this.valueStreams[key].next(value)

        this.saveSettings()
    }

    protected saveSettings() {
        this.storage.setItem(this.STORAGE_KEY, JSON.stringify(this.values))
    }

    // @todo - add fire-rx decorators
    public onDestroy() {
        this.destroyEvent.emit()
    }
}
