import { ApiRoute } from '@undock/api/services/api.route'

export interface AutocompleteResult {
    description: string
    placeId: string
}
export class LocationsRoute extends ApiRoute {
    public init() {
        this.route = 'locations'
    }

    public search(input: string): Promise<AutocompleteResult[]> {
        return this.post<AutocompleteResult[]>().withData({ input }).endpoint('search')
    }

    public getUrl(placeId: string): Promise<string> {
        return this.get<{ url: string }>().endpoint(`place/${placeId}`).then(data => data.url)
    }
}
