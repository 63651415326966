import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-open-link',
    templateUrl: 'open-link.component.html',
    styleUrls: ['open-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenLinkIconComponent extends IconTemplateComponent {
    @Input() color: string = '#2B2733'
}
