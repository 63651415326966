import { ApiRoute } from '@undock/api/services/api.route'
import { TimelineEvent } from '@undock/api/scopes/time/contracts/timeline-event'


export class ProfileRoute extends ApiRoute {
    public init() {
        this.route = 'timeline/profile'
    }

    public async getEventToRescheduleByBookingCode(bookingCode: string, ownerId: string): Promise<TimelineEvent> {
        return this.post<TimelineEvent>()
                   .withData({ bookingCode, ownerId })
                   .endpoint(`event/booking-code`)
    }
}
