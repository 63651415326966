import {
    AfterViewInit,
    Directive,
    HostListener,
    Optional,
} from '@angular/core'

import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete'


@Directive({
    selector: '[matAutocompleteForceSelectOptionsByTabKey]',
})
export class MatAutocompleteTabDirective implements AfterViewInit {


    public constructor(
        @Optional()
        protected readonly matAutocompleteTrigger: MatAutocompleteTrigger,
    ) {}

    public ngAfterViewInit() {
        if (!this.matAutocompleteTrigger) {
            throw new MatAutocompleteExpectedException()
        }
    }

    @HostListener('keydown.tab', ['$event'])
    public onTabKeyPressed(event: KeyboardEvent) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        if (this.matAutocompleteTrigger.activeOption) {
            this.matAutocompleteTrigger.autocomplete.optionSelected.next({
                source: this.matAutocompleteTrigger.autocomplete,
                option: this.matAutocompleteTrigger.activeOption,
            })
        }
    }
}

export class MatAutocompleteExpectedException extends Error {}