import {
    Component,
    ChangeDetectionStrategy,
    Input,
    HostBinding,
    ViewChild,
} from '@angular/core'

import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { take } from 'rxjs/operators'

import { Api } from '@undock/api'
import { MeetingMode } from '@undock/dock/meet'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import { TopicsManager } from '@undock/dock/meet/services/topics.manager'
import { NOTES_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/notes.adapter'
import { TOPICS_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { EditEventNotesAdapter } from '@undock/dock/meet/services/adapters/edit-event-notes.adapter'
import { EditEventTopicsAdapter } from '@undock/dock/meet/services/adapters/edit-event-topics.adapter'
import { PARTICIPANTS_PROVIDER } from '@undock/dock/meet/contracts/ui-providers/participants.provider'
import { EditEventParticipantsProvider } from '@undock/dock/meet/services/data-providers/edit-event-participants.provider'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'
import { PopupTriggerDirective } from '@undock/common/ui-kit/ui/directives/popup-trigger/popup-trigger.directive'

@UseKeyboardShortcuts({
    takeUntilPropertyKey: 'destroyedEvent',
    allowInputs: true
})
@Component({
    selector: 'app-meet-event-form',
    templateUrl: 'event-form.component.html',
    styleUrls: ['event-form.component.scss'],
    providers: [
        TopicsManager,
        { provide: NOTES_ADAPTER, useClass: EditEventNotesAdapter },
        { provide: TOPICS_ADAPTER, useClass: EditEventTopicsAdapter },
        { provide: PARTICIPANTS_PROVIDER, useClass: EditEventParticipantsProvider },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormComponent {

    public readonly MeetingMode = MeetingMode

    public readonly state = this.eventFormStateModel.state

    @Input()
    @HostBinding('class.full-size')
    public readonly fullSizeMode: boolean = false

    @Input('submitButtonLabel') customSubmitButtonLabel: string = null

    @ViewChild('recurrenceOptionsPopup')
    private readonly recurrenceOptionsPopup: PopupTriggerDirective

    @CompleteOnDestroy()
    public readonly isPlanModeStream = new ValueSubject(false)

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        public eventFormStateModel: EventFormStateModel,

        protected api: Api,
        protected snackbarManager: SnackbarManager,
        protected confirmPopupService: ConfirmPopupService,
    ) {}

    public async ngOnInit() {
        // Switch to plan mode when plan is selected
        this.eventFormStateModel.state.planIdStream.pipe(
            take(1),
        ).subscribe((planId) => {
            this.isPlanModeStream.next(Boolean(planId))
        })
    }

    @KeyboardShortcut('OS.Enter')
    public async submit(
        // Used only for recurrent events
        updateRecurrenceMode?: 'current' | 'following' | 'all',
    ) {
        // Display options popup for recurrent event edit
        const schedule = await this.state.eventScheduleStream
        const isDraftMode = await this.state.isDraftModeStream
        if (!isDraftMode && schedule.rRule && !updateRecurrenceMode) {
            return this.recurrenceOptionsPopup.open()
        }

        return this.eventFormStateModel.submitForm({
            updateRecurrenceMode: updateRecurrenceMode,
        })
    }

    @KeyboardShortcut('OS.A')
    public async addAgenda() {
        try {
            /**
             * Assign new draft dock for hosted agenda
             */
            await this.eventFormStateModel.assignNewDraftDock()
        } catch (error) {
            console.error(error)
            this.snackbarManager.error(`Cannot add agenda. Please try later`)
        }
    }

    public async switchToPlanMode() {
        try {
            /**
             * An id of assigned draft dock (for agenda)
             */
            if (await this.state.dockIdStream) {
                const confirmed = await this.confirmPopupService.open({
                    title: 'Replace existing agenda?',
                    description: 'Adding a plan will reset the entered Agenda',
                    confirmButtonLabel: 'Yes, replace',
                    discardButtonLabel: 'Cancel'
                })

                if (!confirmed) {
                    return false
                }
            }

            this.isPlanModeStream.next(true)
            await this.eventFormStateModel.removeAgendaAndDraftDock()
        } catch (error) {
            console.error(error)
            this.snackbarManager.error(`Cannot add plan. Please try later.`)
        }
    }
}
