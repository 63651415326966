import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core'
import { Router } from '@angular/router'
import { AppEventsDispatcher } from '@undock/core'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'


@Component({
    selector: 'app-feature-plan-upgrade-banner',
    templateUrl: 'upgrade-plan-banner.component.html',
    styleUrls: ['upgrade-plan-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradePlanBannerComponent {
    @Input() planName: string
    @Input() description: string
    @Input() buttonText: string

    @Input()
    @HostBinding('class.small')
    public small: boolean = false

    @Input()
    @HostBinding('class.large')
    public large: boolean = false

    @Input() iconSize: number = 71

    public constructor(
        private router: Router,
        private eventsManager: AppEventsDispatcher,
    ) {}

    public navigateToTheMembershipPage() {

        this.eventsManager.dispatch(
            new TrackUserAnalyticsEvent(
                UserAnalyticsAction.UpgradeSubscriptionClickedBanner,
            ),
        )

        return this.router.navigate(
            ['/', 'settings', 'membership'],
            { queryParams: { upgrade: true } },
        )
    }
}
