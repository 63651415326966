import { ApiContext } from '@undock/api/contracts/api.context'

import { DockRoute } from './routes/dock.route'
import { ExportRoute } from './routes/export.route'
import { BookingRoute } from './routes/booking.route'
import { RequestsRoute } from './routes/requests.route'
import { ProposalsRoute } from './routes/proposals.route'
import { ParticipantsRoute } from './routes/participants.route'
import { ConferenceRoomRoute } from './routes/conference-room.route'
import { MeetingsRoute } from '@undock/api/scopes/meet/routes/meetings.route'


export class MeetingsScope {

    public readonly dock: DockRoute
    public readonly export: ExportRoute
    public readonly booking: BookingRoute
    public readonly requests: RequestsRoute
    public readonly meetings: MeetingsRoute
    public readonly proposal: ProposalsRoute
    public readonly participants: ParticipantsRoute
    public readonly conferenceRoom: ConferenceRoomRoute

    public constructor(context: ApiContext) {
        this.dock = new DockRoute(context)
        this.export = new ExportRoute(context)
        this.booking = new BookingRoute(context)
        this.requests = new RequestsRoute(context)
        this.meetings = new MeetingsRoute(context)
        this.proposal = new ProposalsRoute(context)
        this.participants = new ParticipantsRoute(context)
        this.conferenceRoom = new ConferenceRoomRoute(context)
    }
}
