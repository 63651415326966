import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-eye-close-icon',
    templateUrl: 'eye-close.component.html',
    styleUrls: ['eye-close.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EyeCloseIconComponent {
    @Input() width: number|string = '20'
    @Input() height: number|string = '20'
    @Input() color: number|string = '#CECADA'
}
