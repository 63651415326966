import {
    Pipe,
    PipeTransform,
} from '@angular/core'

import { default as moment  } from 'moment'


@Pipe({ name: 'monthOfYear', pure: true })
export class MonthOfYearPipe implements PipeTransform {

    public transform(value: number, format: 'MMM' | 'MMMM' = 'MMM'): string {

        if (value === null || value === undefined || isNaN(value) || value < 0 || value > 23) {
            return ''
        }

        return moment().month(value).format(format)
    }
}
