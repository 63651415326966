<ng-container
    *ngIf="{
        allPlans: allPlansStream | stream,
        selectedPlan: selectedPlanStream | stream,
        searchCriteria: searchCommandsState.searchCriteriaStream | stream,
        searchResults: searchCommandsState.plansSearchResultsStream | stream
    } as view"
    [ngSwitch]="view.selectedPlan | boolean"
>
    <ng-container *ngSwitchCase="true">
        <div class="chips-list">
            <div class="chip-item">
                <span>{{ view.selectedPlan.title }}</span>

                <app-icon-close class="clickable"
                                [height]="20" [width]="20"
                                color="var(--close-icon-color)"
                                (click)="resetSelectedPlan()"
                ></app-icon-close>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <input tabindex="0"
               #inputElRef
               #dropdownRef=udDropdownRef

               [ngModel]="view.searchCriteria"
               [placeholder]="'Add plan'"

               udInput
               udDropdown
               [dropdownHostEl]="dropdownElHost ?? inputElRef"
               [overlayContentRef]="eventFormPlanTmpl"

               (focus)="dropdownRef.open()"
               (keyup.escape)="dropdownRef.close()"
               (ngModelChange)="setSearchCriteria($event)"
        />

        <ng-template #eventFormPlanTmpl>
            <div class="event-form-plan-dropdown"
                 [ngSwitch]="view.searchCriteria?.trim() | boolean"
            >
                <ng-container *ngSwitchCase="true"
                              [ngSwitch]="view.searchResults?.length > 0"
                >
                    <div class="vertical-items-list" *ngSwitchCase="true">
                        <span class="sub-title">Results</span>
                        <div class="list-item clickable"
                             *ngFor="let plan of view.searchResults"
                             (click)="selectPlan(plan); dropdownRef.close()"
                        >
                            <div class="image-box">
                                <app-icon-hourglass></app-icon-hourglass>
                            </div>

                            <div class="content-box">
                                <div class="label">{{ plan.title }}</div>
                                <div class="description">{{ plan.description }}</div>
                            </div>
                        </div>
                    </div>

                    <span *ngSwitchCase="false">No plans found</span>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <div class="vertical-items-list">
                        <span class="sub-title">All plans</span>

                        <div class="list-item clickable"
                             *ngFor="let plan of view.allPlans"
                             (click)="selectPlan(plan); dropdownRef.close()"
                        >
                            <div class="image-box">
                                <app-icon-hourglass></app-icon-hourglass>
                            </div>

                            <div class="content-box">
                                <div class="label">{{ plan.title }}</div>
                                <div class="description">{{ plan.description }}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </ng-container>
</ng-container>



