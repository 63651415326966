import { Injectable } from '@angular/core'

import { Config } from '@undock/core'
import { AnalyticsTracker } from '@undock/integrations'
import { User } from '@undock/user'
import { UserAnalyticsAction } from '@undock/integrations/events'


@Injectable()
export class GoogleTagManagerService implements AnalyticsTracker {

    public constructor(
        private config: Config,
    ) {}

    public initialize(): void {
        if (this.config.enableGoogleTagManager) {
            console.log('[ Google Tag Manager initialized ]')

            gtag('js', new Date())
            gtag('config', this.config.googleTagManagerTrackingId)
        }
    }

    public identify(userData: User) {
        // @TODO: Finish this method
    }

    public track(action: UserAnalyticsAction, userData?: User, data?: any) {
        // @TODO: Finish this method
    }
}
