import { ApiRoute } from '@undock/api'
import {
    IOrganization,
    IOrganizationMember,
    IOrganizationAggregate,
} from '@undock/api/scopes/organizations/contracts'


export class OwnRoute extends ApiRoute {

    public init() {
        this.route = 'organizations/own'
    }

    public getById(id: string) {
        return this.get<IOrganizationAggregate>()
                   .endpoint(`id/${id}`)
    }

    public getByHandle(handle: string) {
        return this.get<IOrganizationAggregate>()
                   .endpoint(`handle/${handle}`)
    }

    public list(page: number = 0, pageSize: number = 50) {
        return this.get<IOrganizationAggregate[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                   })
                   .endpoint(`list`)
    }

    public create(data: IOrganization) {
        return this.post<IOrganization>()
                   .withData(data)
                   .endpoint(`create`)
    }

    public update(organizationId: string, data: Partial<IOrganization>) {
        return this.post<IOrganization>()
                   .withData(data)
                   .endpoint(`update/${organizationId}`)
    }

    public updateMember(memberId: string, data: Partial<IOrganizationMember>) {
        return this.post<IOrganizationMember>()
                   .withData(data)
                   .endpoint(`member/update/${memberId}`)
    }

    public leave(organizationId: string) {
        return this.post<void>()
                   .withData({})
                   .endpoint(`leave/${organizationId}`)
    }

    public remove(organizationId: string) {
        return this.post<void>()
                   .withData({})
                   .endpoint(`delete/${organizationId}`)
    }

}
