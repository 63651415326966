import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: `app-text-icon`,
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 1.75H8.44513H8.44511C7.07753 1.74998 5.97521 1.74996 5.10825 1.86652C4.20814 1.98754 3.45027 2.24643 2.84835 2.84835C2.24643 3.45027 1.98754 4.20814 1.86652 5.10825C1.74996 5.97521 1.74998 7.07753 1.75 8.44511V8.44513V8.5V11.5V11.5549V11.5549C1.74998 12.9225 1.74996 14.0248 1.86652 14.8918C1.98754 15.7919 2.24643 16.5497 2.84835 17.1517C3.45027 17.7536 4.20814 18.0125 5.10825 18.1335C5.97522 18.25 7.07754 18.25 8.44513 18.25H8.5H11.5H11.5549C12.9225 18.25 14.0248 18.25 14.8918 18.1335C15.7919 18.0125 16.5497 17.7536 17.1517 17.1517C17.7536 16.5497 18.0125 15.7919 18.1335 14.8918C18.25 14.0248 18.25 12.9225 18.25 11.5549V11.5V8.5V8.44513C18.25 7.07754 18.25 5.97522 18.1335 5.10825C18.0125 4.20814 17.7536 3.45027 17.1517 2.84835C16.5497 2.24643 15.7919 1.98754 14.8918 1.86652C14.0248 1.74996 12.9225 1.74998 11.5549 1.75H11.5549H11.5H8.5ZM3.90901 3.90901C4.18577 3.63225 4.57435 3.4518 5.30812 3.35315C6.06347 3.25159 7.06459 3.25 8.5 3.25H11.5C12.9354 3.25 13.9365 3.25159 14.6919 3.35315C15.4257 3.4518 15.8142 3.63225 16.091 3.90901C16.3678 4.18577 16.5482 4.57435 16.6469 5.30812C16.7484 6.06347 16.75 7.06459 16.75 8.5V11.5C16.75 12.9354 16.7484 13.9365 16.6469 14.6919C16.5482 15.4257 16.3678 15.8142 16.091 16.091C15.8142 16.3678 15.4257 16.5482 14.6919 16.6469C13.9365 16.7484 12.9354 16.75 11.5 16.75H8.5C7.06459 16.75 6.06347 16.7484 5.30812 16.6469C4.57435 16.5482 4.18577 16.3678 3.90901 16.091C3.63225 15.8142 3.4518 15.4257 3.35315 14.6919C3.25159 13.9365 3.25 12.9354 3.25 11.5V8.5C3.25 7.06459 3.25159 6.06347 3.35315 5.30812C3.4518 4.57435 3.63225 4.18577 3.90901 3.90901ZM6.66667 5.91667C6.25245 5.91667 5.91667 6.25245 5.91667 6.66667C5.91667 7.08088 6.25245 7.41667 6.66667 7.41667H9.25V13.3333C9.25 13.7475 9.58579 14.0833 10 14.0833C10.4142 14.0833 10.75 13.7475 10.75 13.3333V7.41667H13.3333C13.7475 7.41667 14.0833 7.08088 14.0833 6.66667C14.0833 6.25245 13.7475 5.91667 13.3333 5.91667H10H6.66667Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class TextIconComponent extends IconTemplateComponent{
    @Input() width: string | number = 20
    @Input() height: string | number  = 20
    @Input() color: string = '#807A8E'
}
