import { ApiRoute } from '@undock/api/services/api.route'
import {
    Plan,
    PlanUpdateDTO,
    PublicPlanUpdateDTO,
} from '@undock/time/plans/contracts/plan.interface'


export class PublicPlansRoute extends ApiRoute {

    public init() {
        this.route = 'plans/public'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public create(properties = {}): Promise<Plan> {
        return this.post<Plan>()
                   .withData({ ...properties })
                   .endpoint('')
    }

    public async listAll(): Promise<Plan[]> {
        const data = await this.get<Plan[]>()
                               .endpoint(``)

        return data ?? []
    }

    public getById(planId: string): Promise<Plan> {
        return this.get<Plan>()
                   .endpoint(`${planId}`)
    }

    public getByToken(token: string): Promise<Plan> {
        return this.get<Plan>()
                   .endpoint(`token/${token}`)
    }

    public update(planId: string, properties: PublicPlanUpdateDTO = {}): Promise<Plan> {
        return this.patch<Plan>()
                   .withData({ ...properties })
                   .endpoint(`${planId}`)
    }

    public async saveToOwnList(planId: string, updates: PlanUpdateDTO): Promise<Plan> {
        return await this.post<Plan>()
                         .withData({ ...updates })
                         .endpoint(`save/${planId}`)
    }

    public getOwnPublicByPublicId(id: string): Promise<Plan> {
        return this.get<Plan>()
                   .endpoint(`saved/${id}`)
    }

    public deleteById(planId: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(`${planId}`)
    }

    /**
     * TODO: DO NOT USE for anything but onboarding for now
     */
    public async saveOnboardingPlansToOwnListAndRun(plans: string[], updates: Pick<PlanUpdateDTO, 'timeZone'> ): Promise<Plan> {
        return await this.post<Plan>()
                         .withData({
                             plans: plans,
                             updatePlanData: updates
                         })
                         .endpoint(`run`)
    }
}
