import { NgModule } from '@angular/core';

import { TextInputHighlightComponent } from '@undock/common/mentions/components/text-input-highlight/text-input-highlight.component'
import { MentionsComponent } from '@undock/common/mentions/components/mentions.component'
import { TextInputAutocompleteComponent } from '@undock/common/mentions/components/text-input-autocomplete/text-input-autocomplete.component'
import { TextInputAutocompleteMenuComponent } from '@undock/common/mentions/components/text-input-autocomplete-menu/text-input-autocomplete-menu.component'
import { CommonModule } from '@angular/common'


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        MentionsComponent,
        TextInputHighlightComponent,
        TextInputAutocompleteComponent,
        TextInputAutocompleteMenuComponent
    ],
    exports: [
        MentionsComponent,
        TextInputHighlightComponent,
        TextInputAutocompleteComponent,
        TextInputAutocompleteMenuComponent
    ],
})
export class MentionsModule {}
