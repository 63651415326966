import {
    Directive,
    ElementRef,
} from '@angular/core'

@Directive({
    selector: '[udSelect]',
})
export class SelectDirective {

    constructor(private elementRef: ElementRef) {
        this?.elementRef?.nativeElement?.classList?.add('ud-select')
    }
}
