import {
    CreatedDateField,
    DocRef,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import {
    SoftDelete,
} from '@undock/core'
import { FirestoreUser } from '@undock/user'
import { Dock } from '@undock/dock/meet/models/dock.model'
import { SafeReference } from '@undock/session/models/orm-on-fire.context'

export const ACTION_ITEMS_COLLECTION_NAME = 'actionItem'

@Entity({
    collection: ACTION_ITEMS_COLLECTION_NAME,
})
export class ActionItem implements SoftDelete {
    @ID() id: string

    @Field() text: string

    @Field() weight: number
    @Field() position: number

    @Field() complete: boolean

    /**
     * For soft-delete implementation
     */
    @Field() removed: boolean

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date


    @Field() dockId: string
    @DocRef(Dock) dock: SafeReference<Dock>

    @Field() authorId: string
    @DocRef(FirestoreUser) author: SafeReference<FirestoreUser>

    @Field() completedById: string
    @DocRef(FirestoreUser) completedBy: SafeReference<FirestoreUser>

    @Field() assignedUserId: string
    @DocRef(FirestoreUser) assignedUser: SafeReference<FirestoreUser>

    /**
     * @description `isInitialized` is value which prevents
     *           getting updates from uncompleted documents
     */
    @Field() isInitialized: boolean
}
