<div class="container login-page">
    <nav class="login-page__top-navigation mobile">
        <a class="login-page__logo" target="_blank" href="https://undock.com/l/?ref=null">
            <app-logo-undock [color]="'#FFFFFF'"></app-logo-undock>
        </a>
    </nav>

    <div class="login-page__carousel-wrapper"><!--    left side    -->
        <div class="login-page__carousel-box">
            <div class="login-page__carousel-slide">
                <div class="login-page__carousel-slide-image"></div>

                <div class="login-page__carousel-slide-title">
                    <p>Undock puts me in complete control of my day. Life changing.</p>
                    <p>Harold Hughes</p>
                </div>
            </div>
        </div>
    </div>

    <div class="login-page__login-wrapper"><!--    right side    -->
        <div class="login-page__login-box"><!-- wrapper for login fields -->
            <div class="login-page__title">
                <h1>Discover what tomorrow brings</h1>
                <p>It’s time to take control of your day</p>
            </div>

            <button type="button" class="form-container__signup-button link-button" (click)="loginWithGoogle()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path d="M23.9996 12.2755C23.9996 11.4598 23.9321 10.6397 23.7881 9.83716H12.2402V14.4582H18.8532C18.5788 15.9485 17.697 17.2669 16.4059 18.1047V21.103H20.3512C22.668 19.013 23.9996 15.9265 23.9996 12.2755Z"
                              fill="#4285F4"/>
                        <path d="M12.2404 24.0001C15.5424 24.0001 18.327 22.9375 20.3559 21.1032L16.4106 18.1048C15.3129 18.8368 13.8959 19.2513 12.2449 19.2513C9.05088 19.2513 6.34273 17.1392 5.37103 14.2996H1.2998V17.3905C3.37816 21.4427 7.61133 24.0001 12.2404 24.0001Z"
                              fill="#34A853"/>
                        <path d="M5.36712 14.2995C4.85428 12.8091 4.85428 11.1953 5.36712 9.70497V6.61401H1.30039C-0.436067 10.0048 -0.436067 13.9997 1.30039 17.3905L5.36712 14.2995Z"
                              fill="#FBBC04"/>
                        <path d="M12.2404 4.74895C13.9858 4.72249 15.6728 5.36625 16.9369 6.54796L20.4323 3.1219C18.219 1.08478 15.2814 -0.0351985 12.2404 7.6247e-05C7.61133 7.6247e-05 3.37815 2.5575 1.2998 6.6141L5.36653 9.70505C6.33373 6.86102 9.04638 4.74895 12.2404 4.74895Z"
                              fill="#EA4335"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="24" height="24.0001" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <span>Continue with Google</span>
            </button>

            <button type="button" class="form-container__signup-button link-button" (click)="loginWithMicrosoft()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12.75 11.25H24V0.75C24 0.336 23.664 0 23.25 0H12.75V11.25Z" fill="#4CAF50"/>
                    <path d="M11.25 11.25V0H0.75C0.336 0 0 0.336 0 0.75V11.25H11.25Z" fill="#F44336"/>
                    <path d="M11.25 12.75H0V23.25C0 23.664 0.336 24 0.75 24H11.25V12.75Z" fill="#2196F3"/>
                    <path d="M12.75 12.75V24H23.25C23.664 24 24 23.664 24 23.25V12.75H12.75Z" fill="#FFC107"/>
                </svg>
                <span>Continue with Microsoft</span>
            </button>

            <p class="login-page__divider">or</p>

            <a [routerLink]="'/signup/email'" queryParamsHandling="preserve" class="signup-button link-button -no-border">
                <app-icon-message></app-icon-message>
                Sign Up with Email</a>

            <p class="account-info link-button">
                Already have account? <a [routerLink]="'/signin'" queryParamsHandling="preserve">Log In</a>
            </p>
        </div>
    </div>

    <div class="service-login-menu-trigger"
         (click)="servicePopupOpenedStream.next(true)"
    ></div>

    <div class="service-login-menu-overlay"
         [class.opened]="servicePopupOpenedStream | stream"
         (click)="servicePopupOpenedStream.next(false)"
    >
        <div class="service-login-menu-popup"
             (click)="$event.stopPropagation()"
        >
            <div class="close-button"
                 [matTooltip]="'close'"
                 (click)="servicePopupOpenedStream.next(false)"
            >
                <i class="material-icons-round">close</i>
            </div>

            <h1>Service menu</h1>
            <span class="warning">
                Please close this window if you aren't a developer
            </span>

            <div udInputBlock class="textarea-box">
                <textarea udInput #serviceLoginText rows="7"
                          placeholder="Put your service code here"
                ></textarea>
            </div>

            <button mat-button type="button" class="ud-btn ud-btn-primary btn-large"
                    (click)="loginWithCustomToken(serviceLoginText.value)"
            >Login with custom token</button>
        </div>
    </div>
</div>
