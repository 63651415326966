import { Injectable } from '@angular/core'
import { Memoize } from '@undock/core/decorators/memoize'

export interface DeviceInformation {
    osName?: string
    osVersion?: string
    browserName?: string
    browserVersion?: string
}

export interface DeviceData {
    name: string
    value?: string
    version: string | number
}

@Injectable({
    providedIn: 'root',
})
export class DeviceUtil {

    private readonly deviceInfoProviders: string[] = [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window['opera'],
    ]

    private readonly osData: DeviceData[] = [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
    ]

    private readonly browsersData: DeviceData[] = [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
    ]

    @Memoize()
    public get deviceData(): { os: DeviceData, browser: DeviceData } {
        let agent = this.deviceInfoProviders.join(' '),
            os = DeviceUtil.matchItem(agent, this.osData),
            browser = DeviceUtil.matchItem(agent, this.browsersData)

        return { os: os, browser: browser }
    }

    @Memoize()
    public get deviceInformation(): DeviceInformation {
        return {
            osName: this.deviceData.os.name,
            osVersion: this.deviceData.os.version as string,
            browserName: this.deviceData.browser.name,
            browserVersion: this.deviceData.browser.version as string,
        }
    }

    /**
     * Device token is used to allow user join meeting from the different devices at the same time
     */
    @Memoize()
    public get deviceToken(): string {
        let info = this.deviceData

        return `${info.os.name}_${info.os.version}_${info.browser.name}_${info.browser.version}`
    }

    @Memoize()
    public get isMobileDevice(): boolean {
        return 'ontouchstart' in document.documentElement
    }

    public get isFirefox(): boolean {
        return this.deviceInformation.browserName === 'Firefox'
    }

    public get isSafariMobile(): boolean {
        const info = this.deviceInformation

        return info.browserName === 'Safari' && ['iPhone', 'iPad'].includes(info.osName)
    }

    public get isAppleDevice(): boolean {
        const info = this.deviceInformation

        return ['iPhone', 'iPad', 'Macintosh'].includes(info.osName)
    }

    private static matchItem(string, data) {
        let i = 0, j = 0,
            html = '',
            regex,
            regexv,
            match,
            matches,
            version

        for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i')
            match = regex.test(string)
            if (match) {
                regexv = new RegExp(data[i].version + '[- /:]([\\d._]+)', 'i')
                matches = string.match(regexv)
                version = ''
                if (matches) { if (matches[1]) { matches = matches[1] } }
                if (matches) {
                    matches = matches.split(/[._]+/)
                    for (j = 0; j < matches.length; j += 1) {
                        if (j === 0) {
                            version += matches[j] + '.'
                        } else {
                            version += matches[j]
                        }
                    }
                } else {
                    version = '0'
                }
                return {
                    name: data[i].name,
                    version: parseFloat(version),
                }
            }
        }
        return { name: 'unknown', version: 0 }
    }
}
