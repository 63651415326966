<ng-container [ngSwitch]="priority">

    <ng-container *ngSwitchCase="'Low'">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9" y="9" width="38" height="38" rx="9" stroke-width="2"
                [attr.stroke]="borderColor"
            />
            <rect x="18" y="30" width="4" height="7" rx="2"
                [attr.fill]="color"
            />
            <rect x="26" y="25" width="4" height="12" rx="2" fill="#CECADA"/>
            <rect x="34" y="19" width="4" height="18" rx="2" fill="#CECADA"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'Med'">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9" y="9" width="38" height="38" rx="9" stroke-width="2"
                  [attr.stroke]="borderColor"
            />
            <rect x="18" y="30" width="4" height="7" rx="2"
                  [attr.fill]="color"
            />
            <rect x="26" y="25" width="4" height="12" rx="2"
                  [attr.fill]="color"
            />
            <rect x="34" y="19" width="4" height="18" rx="2" fill="#CECADA"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'High'">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9" y="9" width="38" height="38" rx="9" stroke-width="2"
                  [attr.stroke]="borderColor"
            />
            <rect x="18" y="30" width="4" height="7" rx="2"
                  [attr.fill]="color"
            />
            <rect x="26" y="25" width="4" height="12" rx="2"
                  [attr.fill]="color"
            />
            <rect x="34" y="19" width="4" height="18" rx="2"
                  [attr.fill]="color"
            />
        </svg>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9" y="9" width="38" height="38" rx="9" stroke-width="2"
                  [attr.stroke]="borderColor"
            />
            <rect x="18" y="30" width="4" height="7" rx="2"
                  [attr.fill]="color"
            />
            <rect x="26" y="25" width="4" height="12" rx="2" fill="#CECADA"/>
            <rect x="34" y="19" width="4" height="18" rx="2" fill="#CECADA"/>
        </svg>
    </ng-container>

</ng-container>