import {
    CreatedDateField,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

export const LOCATIONS_COLLECTION_NAME = 'locations'

@Entity({
    collection: LOCATIONS_COLLECTION_NAME,
})
export class SavedLocation {

    @ID() readonly id: string

    @Field() url: string
    @Field() name: string
    @Field() address: string
    @Field() visibleForGuests: boolean = true

    @CreatedDateField() public createdAt: Date
    @UpdatedDateField() public updatedAt: Date

    public constructor(
        name: string,
        address: string,
        url: string,
        visibleForGuests: boolean = true,
    ) {
        this.url = url
        this.name = name
        this.address = address
        this.visibleForGuests = visibleForGuests
    }
}
