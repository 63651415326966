import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import { initializeLogRocket } from '@web/initializers/log-rocket.initializer'
import {
    initializeGoogleApis,
    initializeGoogleMapsApi,
} from '@web/initializers/google-api.initializer'
import { unregisterServiceWorkers } from '@web/initializers/unregister-service-workers'

unregisterServiceWorkers()

if (environment.production) {
    enableProdMode()
}

try {
    initializeGoogleApis()
    initializeGoogleMapsApi()
} catch (error) {
    console.warn(`Cannot initialize Google API`, error)
}

if (environment.gistAppId && typeof window['__initGist'] === 'function') {
    try {
        window['__initGist'](environment.gistAppId)
    } catch (error) {
        console.warn(`Cannot initialize Gist`, error)
    }
}

if (environment.crispWebsiteId && typeof window['__initCrisp'] === 'function') {
    try {
        window['__initCrisp'](environment.crispWebsiteId)
    } catch (error) {
        console.warn(`Cannot initialize Crisp`, error)
    }
}

if (environment.usetifulToken && typeof window['__initUsetiful'] === 'function') {
    try {
        window['__initUsetiful'](environment.usetifulToken)
    } catch (error) {
        console.warn(`Cannot initialize Usetiful`, error)
    }
}

// LogRocket is disabled
// const url = new URL(location.href)
// if (environment.enableErrorTracking || url.searchParams.get('enableLogRocket')) {
//     initializeLogRocket()
// }

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(error => {
        console.error(`Unable bootstrap application`, error)
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            return window.location.reload()
        }
        document.querySelector('.fatal-error-overlay').classList.remove('hidden')
    })
