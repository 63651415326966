/**
 * Checks if element is visible on the window viewport
 */
export function isElementVisible(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect(),
        vWidth = window.innerWidth || document.documentElement.clientWidth,
        vHeight = window.innerHeight || document.documentElement.clientHeight

    const efp = (x, y) => document.elementFromPoint(x, y)

    /**
     *  Return false if it's not in the viewport
     */
    if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
        return false
    }

    /**
     * Return true if any of its four corners are visible
     */
    return (
        element.contains(efp(rect.left, rect.top))
        || element.contains(efp(rect.right, rect.top))
        || element.contains(efp(rect.right, rect.bottom))
        || element.contains(efp(rect.left, rect.bottom))
    )
}

/**
 * Checks if element is fully visible on the window viewport
 */
export function isElementFullyVisible(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect(),
        vWidth = window.innerWidth || document.documentElement.clientWidth,
        vHeight = window.innerHeight || document.documentElement.clientHeight

    const efp = (x, y) => document.elementFromPoint(x, y)

    /**
     *  Return false if it's not in the viewport
     */
    if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) {
        return false
    }

    /**
     * Return true if all of its four corners are visible
     */
    return (
        element.contains(efp(rect.left, rect.top))
        && element.contains(efp(rect.right-1, rect.top))
        && element.contains(efp(rect.right-1, rect.bottom-1))
        && element.contains(efp(rect.left, rect.bottom-1))
    )
}
