import { ApiRoute } from '@undock/api/services/api.route'


export class OAuthRoute extends ApiRoute {
    public init() {
        this.route = 'partners/oauth'
    }

    /**
     * Returns an authorization code
     */
    public authorizeClient(
        clientId: string, scopes: string[], redirectUrl: string,
    ): Promise<string> {
        return this.post<string>()
                   .withData({
                       clientId, scopes, redirectUrl,
                   })
                   .endpoint(`authorize-client`)
    }

    public revokeClientAuthorization(clientId: string) {
        // @TODO:
    }
}
