import {
    Directive,
} from '@angular/core'
import { Router } from '@angular/router'

import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { takeUntil } from 'rxjs/operators'

import { AuthManager } from '@undock/auth'


@Directive({
    selector: '[appRequireAuth]',
})
export class RequireAuthDirective {

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        private router: Router,
        private authManager: AuthManager,
    ) {}

    public ngOnInit () {
        this.authManager.isRegularUserStream.pipe(
            takeUntil(this.destroyedEvent)
        ).subscribe(
            isRegularUser => {
                if (!isRegularUser) {
                    window.location.href = this.router.createUrlTree(['login'], {
                        queryParams: { redirectPath: this.router.url }
                    }).toString()
                }
            }
        )
    }
}
