<ng-container *ngIf="bold; else standardTmpl">
    <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1405_28366)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C8.55229 0 9 0.447715 9 1V2.00008L9.29182 2.00004H9.29183L9.33342 2.00004H15V1C15 0.447715 15.4477 0 16 0C16.5523 0 17 0.447715 17 1V2.00163C17.1884 2.00399 17.345 2.00982 17.4902 2.02412C19.8641 2.25792 21.7422 4.13607 21.976 6.50995C22.0002 6.75533 22.0001 7.03357 22.0001 7.44221L22.0001 7.50004V10.5C22.0001 11.0523 21.5524 11.5 21.0001 11.5C20.4478 11.5 20.0001 11.0523 20.0001 10.5V7.50004C20.0001 7.01191 19.9989 6.84034 19.9856 6.70599C19.8454 5.28166 18.7185 4.15477 17.2941 4.01449C17.2216 4.00734 17.1382 4.0037 17 4.00186C16.999 4.55329 16.5517 5 16 5C15.4477 5 15 4.55231 15 4.00004H9.33342L9 4.00012C8.99994 4.55235 8.55225 5 8 5C7.46052 5 7.02081 4.5728 7.00072 4.03823C6.95227 4.04138 6.90491 4.04478 6.85857 4.04846C6.26967 4.09526 5.9305 4.18255 5.67322 4.30942C5.08092 4.60151 4.60155 5.08088 4.30946 5.67317C4.18259 5.93046 4.0953 6.26962 4.0485 6.85853C4.00082 7.45862 4.00008 8.22868 4.00008 9.33337V15.5C4.00008 16.9684 4.01094 17.4808 4.12926 17.8709C4.42011 18.8297 5.17042 19.58 6.12923 19.8709C6.51929 19.9892 7.03177 20 8.50008 20H10.0001C10.5524 20 11.0001 20.4478 11.0001 21C11.0001 21.5523 10.5524 22 10.0001 22H8.50008L8.32634 22.0001C7.09908 22.0005 6.26106 22.0008 5.54866 21.7847C3.95065 21.3 2.70013 20.0495 2.21538 18.4515C1.99928 17.7391 1.99959 16.901 2.00004 15.6738L2.00008 15.5V9.33337L2.00008 9.29179C2.00007 8.2384 2.00007 7.38875 2.05479 6.7001C2.11112 5.99121 2.23009 5.3678 2.51572 4.7886C3.00253 3.80144 3.80148 3.00249 4.78864 2.51568C5.36785 2.23004 5.99125 2.11108 6.70014 2.05475C6.79688 2.04706 6.89679 2.04045 7 2.03477V1C7 0.447715 7.44772 0 8 0Z"
                  [attr.fill]="borderColor"/>
            <path d="M15.9583 20.2917C18.3516 20.2917 20.2917 18.3516 20.2917 15.9583C20.2917 13.5651 18.3516 11.625 15.9583 11.625C13.5651 11.625 11.625 13.5651 11.625 15.9583C11.625 18.3516 13.5651 20.2917 15.9583 20.2917Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  [attr.stroke]="color"/>
            <path d="M21.3758 21.3748L19.0195 19.0186" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  [attr.stroke]="color"/>
        </g>
        <defs>
            <clipPath id="clip0_1405_28366">
                <rect [attr.width]="width" [attr.height]="height" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</ng-container>

<ng-template #standardTmpl>
    <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 4.99994C19.5523 4.99994 20 5.44765 20 5.99994V8.00004L20.549 7.99994H28C31.1592 7.99994 33.7892 7.99994 36 8.11014V5.99994C36 5.44765 36.4477 4.99994 37 4.99994C37.5523 4.99994 38 5.44765 38 5.99994V8.25367C41.2503 8.57338 43.4381 9.29592 45.0711 10.9289C48 13.8578 48 18.5718 48 27.9999C48 37.428 48 42.1421 45.0711 45.071C42.1421 47.9999 37.4281 47.9999 28 47.9999C18.5719 47.9999 13.8579 47.9999 10.9289 45.071C8 42.1421 8 37.428 8 27.9999V20.549C8 18.18 8 16.9956 8.19973 16.0113C8.99849 12.0749 12.075 8.99843 16.0114 8.19967C16.5569 8.08897 17.1639 8.03963 18 8.01763V5.99994C18 5.44765 18.4477 4.99994 19 4.99994ZM36 10.1124V11.9999C36 12.5522 36.4477 12.9999 37 12.9999C37.5523 12.9999 38 12.5522 38 11.9999V10.2639C38.2422 10.2894 38.4775 10.3175 38.7062 10.3482C41.2076 10.6845 42.6259 11.3122 43.6569 12.3431C44.6878 13.374 45.3154 14.7924 45.6517 17.2937C45.9958 19.8526 46 23.2294 46 27.9999C46 32.7705 45.9958 36.1473 45.6517 38.7062C45.3154 41.2075 44.6878 42.6259 43.6569 43.6568C42.6259 44.6877 41.2076 45.3154 38.7062 45.6517C36.1473 45.9957 32.7706 45.9999 28 45.9999C23.2294 45.9999 19.8527 45.9957 17.2938 45.6517C14.7924 45.3154 13.3741 44.6877 12.3431 43.6568C11.3122 42.6259 10.6846 41.2075 10.3483 38.7062C10.0042 36.1473 10 32.7705 10 27.9999V20.549C10 18.0823 10.0101 17.1468 10.1598 16.409C10.7988 13.2599 13.26 10.7987 16.4091 10.1597C16.8098 10.0784 17.2689 10.0383 18 10.0186V11.9999C18 12.5522 18.4477 12.9999 19 12.9999C19.5523 12.9999 20 12.5522 20 11.9999V10.0002C20.1741 10 20.3569 9.99994 20.549 9.99994H28C31.2543 9.99994 33.8601 10.0019 36 10.1124Z"
              [attr.fill]="borderColor"
        />
        <path d="M27.1667 33.8333C30.8486 33.8333 33.8333 30.8486 33.8333 27.1667C33.8333 23.4848 30.8486 20.5 27.1667 20.5C23.4848 20.5 20.5 23.4848 20.5 27.1667C20.5 30.8486 23.4848 33.8333 27.1667 33.8333Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              [attr.stroke]="color"
        />
        <path d="M35.5 35.5L31.875 31.875" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              [attr.stroke]="color"
        />
    </svg>
</ng-template>


