<div class="event-item">
    <section class="description-wrapper">
        <div class="left">
            <ngx-skeleton-loader [theme]="{height: '24px', width: '60%', minWidth: '100px', maxWidth: '230px',margin: '0 0 0 0', backgroundColor: '#FBFBFD'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{height: '16px', width: '80%', minWidth: '115px', maxWidth: '280px', margin: '7px 0 0 0', backgroundColor: '#FBFBFD' }"></ngx-skeleton-loader>
        </div>
        <div class="right">
            <div class="participants">
                <ngx-skeleton-loader appearance="circle" [theme]="{height: '32px',width: '32px', margin: '0 0 0 -4px', border: '2px solid #fff', backgroundColor: '#FBFBFD'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{height: '32px',width: '32px', margin: '0 0 0 -4px', border: '2px solid #fff', backgroundColor: '#FBFBFD'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{height: '32px',width: '32px', margin: '0 0 0 -4px', border: '2px solid #fff', backgroundColor: '#FBFBFD'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{height: '32px',width: '32px', margin: '0 0 0 -4px', border: '2px solid #fff', backgroundColor: '#FBFBFD'}"></ngx-skeleton-loader>
            </div>
            <ngx-skeleton-loader class="action-btn" [theme]="{height: '40px', width: '140px', minWidth: '50px', maxWidth: '140px', margin: '0 0 0 20px', backgroundColor: '#FBFBFD'}"></ngx-skeleton-loader>
        </div>
    </section>
    <section class="event-details" *ngIf="tabs">
        <ngx-skeleton-loader [theme]="{height: '20px',width: '100px', maxWidth: '30%',margin: '0 12px 0 0', backgroundColor: '#FBFBFD'}" [count]="3"></ngx-skeleton-loader>
    </section>
</div>
