import {
    inject,
    Injectable,
} from '@angular/core'

import {
    CurrentUser,
    injectCollection,
} from '@undock/session'
import { AbstractFactory } from '@undock/core/models/factories/abstract.factory'
import { Dock } from '@undock/dock/meet/models/dock.model'
import { ActionItem } from '@undock/dock/meet/models/action-item.model'
import { FirestoreUser } from '@undock/user'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'

@Injectable()
export class ActionItemFactory extends AbstractFactory<ActionItem> {

    protected readonly ActionItemsCollection = injectCollection(ActionItem)
    protected readonly FirestoreUserCollection = injectCollection(FirestoreUser)
    protected readonly ormOnFireContext = inject(OrmOnFireContext)

    protected defaultProperties: Partial<ActionItem> = {
        text: '',
        weight: 1,
        complete: false,
        position: 99999,
        removed: false,
    }

    public constructor(
        private currentUser: CurrentUser,
    ) { super() }

    public async create(
        dock: Dock, properties: Partial<ActionItem> = {},
    ): Promise<ActionItem> {
        const [entity, currentUser] = await Promise.all([
            this.ActionItemsCollection.new(),
            this.FirestoreUserCollection.one(await this.currentUser.id).get(),
        ])

        this.assignDefaultData(entity, properties)

        entity.dockId = dock.id
        await this.ormOnFireContext.linkReference(entity.dock, dock)

        entity.authorId = currentUser.id
        await this.ormOnFireContext.linkReference(entity.author, currentUser)

        entity.isInitialized = true

        return entity
    }
}
