import {
    Component,
    ChangeDetectionStrategy,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-event-label-audio-icon',
    templateUrl: 'audio-icon.component.html',
    styleUrls: ['audio-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventLabelAudioIconComponent extends IconTemplateComponent {

    // 16px - is default size for icon
    @Input() width: number = 20
    @Input() height: number = 20

    @Input() fill: string = '#F3F3F9'
    @Input() color: string = '#9A95AC'
}
