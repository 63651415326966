import { NgModule } from '@angular/core'
import { TimePromptComponent } from '@undock/time/prompt/ui/time-prompt.component'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'
import { TimePromptInputComponent } from '@undock/time/prompt/ui/components/time-prompt-input/time-prompt-input.component'
import { PlanSearchResultItemComponent } from '@undock/time/prompt/ui/components/plan-search-result-item/plan-search-result-item.component'
import { PeopleSearchResultItemComponent } from '@undock/time/prompt/ui/components/people-search-result-item/people-search-result-item.component'
import { CoreModule } from '@undock/core/core.module'
import { MeetProvider } from '@undock/dock/meet/meet.provider'
import { TimeSearchNewEventComponent } from '@undock/time/prompt/ui/components'
import { TimePromptServiceProvider } from '@undock/time/prompt/time-prompt.service-provider'
import { EditTimeCommandBlueprintComponent } from '@undock/time/prompt/ui/components/edit-time-command-blueprint/edit-time-command-blueprint.component'
import { TimeCommandBlueprintEventItemComponent } from '@undock/time/prompt/ui/components/time-command-blueprint-event-item/time-command-blueprint-event-item.component'
import { TimeCommandExecutedConfirmationComponent } from '@undock/time/prompt/ui/components/time-command-executed-confirmation/time-command-executed-confirmation.component'
import { TimeCommandPendingBlueprintEventItemComponent } from '@undock/time/prompt/ui/components/time-command-blueprint-event-item/pending-event/time-command-pending-blueprint-event-item.component'
import { EditTimeCommandNewEventComponent } from '@undock/time/prompt/ui/components/edit-time-command-new-event/edit-time-command-new-event.component'
import { TimeCommandRescheduleBlueprintEventItemComponent } from '@undock/time/prompt/ui/components/time-command-blueprint-event-item/reschedule-event/time-command-reschedule-blueprint-event-item.component'
import { TimeCommandShareAvailabilityComponent } from '@undock/time/prompt/ui/components/time-command-share-availability/time-command-share-availability.component'
import { TimeCommandAnswerComponent } from '@undock/time/prompt/ui/components/time-command-answer/time-command-answer.component'
import { PromptLimitCounterComponent } from '@undock/time/prompt/ui/components/time-prompt-input/prompt-limit-counter/prompt-limit-counter.component'
import { FeaturePlansProvider } from '@undock/feature-plans/feature-plans.provider'
import { TimeCommandBlueprintHoldComponent } from './ui/components/time-command-blueprint-hold/time-command-blueprint-hold.component'
import { PeopleUiProvider } from '@undock/people/people-ui.provider'
import { TimeCommandGroupScheduleComponent } from '@undock/time/prompt/ui/components/time-command-group-schedule/time-command-group-schedule.component'
import { EventAttendeesPreviewComponent } from '@undock/time/prompt/ui/components/time-command-group-schedule/event-attendees-preview/event-attendees-preview.component'
import { GridDataSource } from '@undock/common/calendar-grid/contracts/grid-data-source'
import {
    CalendarGridDataSource,
} from '@undock/dashboard/view-models'


@NgModule({
    imports: [
        CoreModule,
        UiKitModule,
        CommonModule,
        RouterModule,
        MeetProvider,
        PeopleUiProvider,
        FeaturePlansProvider,
        TimePromptServiceProvider
    ],
    declarations: [
        TimePromptComponent,
        TimePromptInputComponent,
        PromptLimitCounterComponent,
        TimeSearchNewEventComponent,
        EditTimeCommandNewEventComponent,
        EditTimeCommandBlueprintComponent,
        TimeCommandExecutedConfirmationComponent,
        PlanSearchResultItemComponent,
        TimeCommandBlueprintEventItemComponent,
        TimeCommandBlueprintHoldComponent,
        TimeCommandPendingBlueprintEventItemComponent,
        TimeCommandGroupScheduleComponent,
        TimeCommandRescheduleBlueprintEventItemComponent,
        TimeCommandShareAvailabilityComponent,
        TimeCommandAnswerComponent,
        PeopleSearchResultItemComponent,
        EventAttendeesPreviewComponent,
    ],
    exports: [
        TimePromptComponent
    ],
    providers: [
        { provide: GridDataSource, useClass: CalendarGridDataSource },
    ],
})
export class TimePromptModule {}
