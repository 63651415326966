<div class="track">
    <div class="markers">
        <div class="marker" [class.with-grid]="showGrid"
             [class.active]="i >= workdayStart && i <= workdayEnd"
             *ngFor="let item of [].constructor(25); let i = index"
        >
            <div class="label" *ngIf="i % 3 === 0 || i === 0">
                {{ i > 0 ? (i > 12 ? i - 12 : i) : i + 12 }}{{ i >= 12 && i < 24 ? "pm" : "am" }}
            </div>
        </div>
    </div>

    <div class="selected-times {{selectedTimesClass}}">
        {{ renderTime(workdayStart) }}-{{ renderTime(workdayEnd) }}
    </div>

    <div class="active-track hours-{{ workdayEnd * 2 - workdayStart * 2 }} start-{{ workdayStart * 2 }}"></div>

    <div cdkDrag
         cdkDragLockAxis="x"
         class="slider start hour-{{workdayStart * 2}}"
         [cdkDragConstrainPosition]="$any(computeDragRenderPosition)"
    ></div>

    <div cdkDrag
         cdkDragLockAxis="x"
         class="slider end hour-{{workdayEnd * 2}}"
         [cdkDragConstrainPosition]="$any(computeDragRenderPosition)"
    ></div>
</div>
