import { ApiRoute } from '@undock/api'
import { IOrganizationPublicData } from '@undock/api/scopes/organizations/contracts'

export class PublicRoute extends ApiRoute {
    public init() {
        this.route = 'organizations/public'
    }

    public getById(id: string): Promise<IOrganizationPublicData> {
        return this.get<IOrganizationPublicData>().endpoint(`id/${id}`)
    }

    public isMemberById(id: string): Promise<boolean> {
        return this.get<boolean>().endpoint(`handle/${id}/is-member`)
    }

    public getByHandle(handle: string): Promise<IOrganizationPublicData> {
        return this.get<IOrganizationPublicData>().endpoint(`handle/${handle}`)
    }

    public isMemberByHandle(handle: string): Promise<boolean> {
        return this.get<boolean>().endpoint(`handle/${handle}/is-member`)
    }
}
