<ng-container *ngIf="state.state.isPrivateNotesDisplayedStream | stream">
    <div class="private-notes-overlay" (click)="state.togglePrivateNotes()"></div>

    <section class="meet-notes-container meet-notes" [class.dock-mode-notes]="!(state.state.isConfModeStream | stream)">
        <div class="meet-notes__private-notes-field dock-mode-notes-field" *restrictGuest>

            <div class="meet-notes__private-notes-header dock-mode-notes-header">

                <p>Private notes</p>

                <button (click)="state.togglePrivateNotes()" mat-button>
                    <app-icon-close [color]="'#FFFFFF'"></app-icon-close>
                </button>
            </div>

            <app-meet-private-notes-editor></app-meet-private-notes-editor>
            <!--<div class="ck-content" data-private>
                <ckeditor [config]="editorConfig" [editor]="Editor" [ngModel]="''"
                          (ready)="onEditorReady($event)" (ngModelChange)="onEditorContentChanged($event)"
                ></ckeditor>
            </div>-->
        </div>

        <div class="meet-notes__private-notes-field" *onlyGuest>
            <p class="guest-notice">Private notes available only for regular users.</p>
        </div>
    </section>
</ng-container>
