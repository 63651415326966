import {
    Pipe,
    PipeTransform,
} from '@angular/core'

import {
    default as moment,
    Moment,
} from 'moment'


@Pipe({ name: 'hourOfDay', pure: true })
export class HourOfDayPipe implements PipeTransform {

    public transform(value: number, format: 'hA' | 'ha' | 'h A' | 'h a' = 'hA'): string {

        if (value === null || value === undefined || isNaN(value) || value < 0 || value > 24) {
            return ''
        } else if (value === 24) {
            return '12AM'
        }

        return moment().hour(value).format(format)
    }
}
