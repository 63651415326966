import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import RRule from 'rrule'
import moment from 'moment'
import { Weekday } from 'rrule/dist/esm/src/weekday'
import { Frequency, Options } from 'rrule/dist/esm/src/types'

import {
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { combineLatest } from 'rxjs'

import {
    compareDeeply,
    Memoize,
} from '@undock/core'
import {
    applyEmulatedTimeZone,
    revertEmulatedTimeZone,
} from '@undock/dock/meet/helpers/emulate-tz'
import { TimezoneData } from '@undock/time/availability'
import { EventSchedule } from '@undock/api/scopes/time/contracts/timeline-event/event-schedule.interface'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


@Component({
    selector: 'app-meet-edit-schedule',
    templateUrl: 'edit-schedule.component.html',
    styleUrls: ['edit-schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditScheduleComponent {

    @CompleteOnDestroy()
    public readonly recurringFrequencyStream: ValueSubject<Frequency> = new ValueSubject(null)

    @CompleteOnDestroy()
    public readonly numberOfEventOccurrencesStream: ValueSubject<number> = new ValueSubject(20)

    @CompleteOnDestroy()
    public readonly limitRecurrentEventSeriesStream: ValueSubject<boolean> = new ValueSubject(false)

    public readonly recurrenceOptions: Array<[
        Frequency | null, string,
    ]> = [
        [null, 'Does not repeat'],
        [RRule.DAILY, 'Daily'],
        [RRule.WEEKLY, 'Weekly'],
        [RRule.MONTHLY, 'Monthly'],
        [RRule.YEARLY, 'Annually'],
    ]

    @Output() onScheduleUpdated = new EventEmitter<EventSchedule>()
    @Output() onTimeZoneSelected = new EventEmitter<TimezoneData>()

    @Input() dateFormat: string = 'ddd, MMM D'
    @Input() timeFormat: string = 'h:mmA'
    @Input() timeInputStep: number = 15

    @Input() browserTimeZone: TimezoneData

    protected _timeZone: TimezoneData
    protected _schedule: EventSchedule // original data
    protected _loadedSchedule: EventSchedule // processed data

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    @Input() set timeZone(value: TimezoneData) {
        this._timeZone = value
        // Reload schedule with new timezone
        this._schedule ? this.loadSchedule(this._schedule) : null
    }

    @Input() set schedule(value: EventSchedule) {
        this.loadSchedule(value)
    }

    public get timeZone(): TimezoneData {
        return this._timeZone
    }

    public get schedule(): EventSchedule {
        return this._loadedSchedule
    }

    @Memoize()
    public get isRecurrentEventStream(): ReactiveStream<boolean> {
        return new ReactiveStream(
            this.recurringFrequencyStream.pipe(
                map(Boolean),
                takeUntil(this.destroyEvent),
            ),
        )
    }

    public ngOnInit() {
        this.subscribeForRecurrenceRuleChanges()
    }

    public handleDateSelected(value: Date) {
        let end = moment(value).set('hours', this.schedule.end.getHours())
                               .set('minutes', this.schedule.end.getMinutes())
                               .set('seconds', 0).set('milliseconds', 0)

        let start = moment(value).set('hours', this.schedule.start.getHours())
                                 .set('minutes', this.schedule.start.getMinutes())
                                 .set('seconds', 0).set('milliseconds', 0)

        if (this.timeZone && this.timeZone.zone !== this.browserTimeZone?.zone) {
            end = revertEmulatedTimeZone(end, this.timeZone.zone)
            start = revertEmulatedTimeZone(start, this.timeZone.zone)
        }

        this.loadSchedule({
            ...this._schedule,
            end: end.toDate(),
            start: start.toDate(),
        })
        this.onScheduleUpdated.next(this._schedule)
    }

    public handleStartTimeChange(value: AvailabilitySlot) {
        let adjustedStartTime = moment(this.schedule.start)
                                 .set('hours', value.hour)
                                 .set('minutes', value.minute)
                                 .set('seconds', 0).set('milliseconds', 0)

        // Reverting emulated timezone
        if (this.timeZone && this.timeZone?.zone !== this.browserTimeZone?.zone) {
            adjustedStartTime = revertEmulatedTimeZone(adjustedStartTime, this.timeZone.zone)
        }

        // Update _schedule and _loadedSchedule
        const durationMs = this.schedule.end.valueOf() - this.schedule.start.valueOf()
        this.loadSchedule({
            ...this._schedule,
            start: adjustedStartTime.toDate(),
            end: adjustedStartTime.clone().add(durationMs, 'milliseconds',).toDate(),
        })

        // Emit updated schedule
        this.onScheduleUpdated.next(this._schedule)
    }

    public handleEndTimeChange(value: AvailabilitySlot) {
        const adjustedEnd = moment(this.schedule.end)
                                .set('hours', value.hour)
                                .set('minutes', value.minute)
                                .set('seconds', 0).set('milliseconds', 0)

        const adjustedDuration = Math.round(
            (adjustedEnd.valueOf() - this.schedule.start.valueOf()) / 1000 / 60,
        )

        let updatedSchedule = { ...this.schedule }
        if (adjustedDuration >= this.timeInputStep) {
            // It seems we can use new end time
            updatedSchedule.end = adjustedEnd.toDate()
        } else {
            /**
             * Should decrease start time
             */
            const initialDurationMs = Math.round(
                this.schedule.end.valueOf() - this.schedule.start.valueOf()
            )

            updatedSchedule.end = adjustedEnd.toDate()
            updatedSchedule.start = adjustedEnd.clone()
                                               .subtract(initialDurationMs, 'milliseconds')
                                               .toDate()
        }

        // Revert emulated timezone
        if (this.timeZone && this.timeZone.zone !== this.browserTimeZone?.zone) {
            updatedSchedule.end = revertEmulatedTimeZone(
                updatedSchedule.end, this.timeZone.zone,
            ).toDate()
            updatedSchedule.start = revertEmulatedTimeZone(
                updatedSchedule.start, this.timeZone.zone,
            ).toDate()
        }

        this.loadSchedule(updatedSchedule)
        this.onScheduleUpdated.next(this._schedule)
    }

    public handleSelectedTimeZoneChange(value: TimezoneData) {
        this.timeZone = value
        this.onTimeZoneSelected.next(value)
    }

    public toggleRecurrenceLimit() {
        let nextValue = true
        if (this.limitRecurrentEventSeriesStream.value) {
            nextValue = false
            this.numberOfEventOccurrencesStream.next(20)
        }

        this.limitRecurrentEventSeriesStream.next(nextValue)
    }

    public changeRecurringFrequency(frequency: Frequency) {
        this.recurringFrequencyStream.next(frequency)
    }

    public changeNumberOfEventOccurrences(occurrences: number) {
        this.numberOfEventOccurrencesStream.next(occurrences)
    }

    protected loadSchedule(schedule: EventSchedule) {
        this._schedule = schedule

        if ( // Apply emulated timezone
            this.timeZone &&
            this.timeZone?.zone !== this.browserTimeZone?.zone
        ) {
            this._loadedSchedule = {
                ...schedule,
                end: applyEmulatedTimeZone(schedule.end, this.timeZone.zone).toDate(),
                start: applyEmulatedTimeZone(schedule.start, this.timeZone.zone).toDate(),
            }
        } else {
            this._loadedSchedule = { ...schedule }
        }

        this.loadRecurrenceRule(schedule)
    }

    protected loadRecurrenceRule(schedule: EventSchedule) {
        if (schedule?.rRule && schedule?.rRule?.length > 0) {
            const rRule = RRule.fromString(schedule.rRule)
            this.recurringFrequencyStream.next(rRule.options.freq)
            if (rRule.options.count) {
                this.numberOfEventOccurrencesStream.next(rRule.options.count)
                this.limitRecurrentEventSeriesStream.next(true)
            }
        } else {
            this.recurringFrequencyStream.next(null)
        }
    }

    // TODO: Support end date, update start date
    protected compileRecurrenceRule(
        schedule: EventSchedule,
        data: {
            frequency: Frequency
            numberOfOccurrences?: number
            limitRecurrentEventSeries?: boolean
        },
    ) {
        let rRule = ''
        if (data.frequency) {
            let options: Partial<Options> = {
                freq: data.frequency,
            }

            if (data.frequency == RRule.WEEKLY) {
                options.byweekday = new Weekday(schedule.start.getDay())
            }

            if (data.limitRecurrentEventSeries) {
                options.count = data.numberOfOccurrences
            }

            rRule = (new RRule(options)).toString()
        }

        return rRule
    }

    protected subscribeForRecurrenceRuleChanges() {
        combineLatest([
            this.recurringFrequencyStream,
            this.numberOfEventOccurrencesStream,
            this.limitRecurrentEventSeriesStream,
        ]).pipe(
            takeUntil(this.destroyEvent),
            distinctUntilChanged(compareDeeply),
        ).subscribe(([
            frequency,
            numberOfOccurrences,
            limitRecurrentEventSeries,
        ]) => {
            const rRule = this.compileRecurrenceRule(this._schedule, {
                frequency,
                numberOfOccurrences,
                limitRecurrentEventSeries,
            })
            if (rRule !== this._schedule.rRule) {
                this._schedule = { ...this._schedule, rRule }
                this.onScheduleUpdated.next(this._schedule)
            }
        })
    }
}
