import { NgModule } from '@angular/core'

import { CoreModule } from '@undock/core/core.module'
import { Markdown2HtmlConverter } from '@undock/common/editor/services/markdown-2-html.converter'


@NgModule({
    imports: [
        CoreModule,
    ],
    exports: [],
    providers: [
        Markdown2HtmlConverter,
    ],
    declarations: [],
})
export class EditorModule {}
