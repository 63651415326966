import {
    Injectable,
} from '@angular/core'

import { Config } from '@undock/core'
import { CurrentUser } from '@undock/session'
import {
    UserAnalyticsAttributes,
} from '@undock/integrations/contracts'
import { SegmentAnalyticsService } from '@undock/integrations'


@Injectable({
    providedIn: 'root',
})
export class UserAnalyticsAttributesManager {

    public constructor(
        private config: Config,
        private currentUser: CurrentUser,
        private segmentClient: SegmentAnalyticsService
    ) {
        this.initialize()
    }

    protected initialize(): void {
        if (this.config.enableAnalytics) {
            this.segmentClient.initialize()
        }
    }

    public async identify(userAttributes: UserAnalyticsAttributes): Promise<void> {
        if (this.config.enableAnalytics) {
            let user = await this.currentUser.dataStream
            if (user) {
                return this.segmentClient.identify(user, userAttributes)
            }
        }
    }
}
