import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core'

import { CurrentUser } from '@undock/session'
import { AclManager } from '@undock/acl/services/acl.manager'


@Directive({
    selector: '[restrictByPermission]',
})
export class RestrictByPermissionDirective implements OnInit {

    @Input('restrictByPermission')
    public config: Record<string, string>

    public constructor(
        private aclManager: AclManager,
        private currentUser: CurrentUser,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    public async ngOnInit() {
        this.viewContainer.createEmbeddedView(this.templateRef)

        const allowed = await this.aclManager.checkPermission(
            this.config.resource, this.config.id, this.config.permission,
        )

        console.log(allowed)

        if (!allowed) {
            this.viewContainer.clear()
        }
    }
}
