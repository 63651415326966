import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-duration-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 2.5C9.25 2.08579 9.58579 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10C1.75 7.72203 2.67423 5.6585 4.16637 4.16637C4.45926 3.87348 4.93414 3.87348 5.22703 4.16637C5.51992 4.45926 5.51992 4.93414 5.22703 5.22703C4.0047 6.44936 3.25 8.13583 3.25 10C3.25 13.7279 6.27208 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 6.52558 14.125 3.66428 10.75 3.2912V5C10.75 5.41421 10.4142 5.75 10 5.75C9.58579 5.75 9.25 5.41421 9.25 5V2.5ZM6.96967 6.96967C7.26256 6.67678 7.73744 6.67678 8.03033 6.96967L10.5303 9.46967C10.8232 9.76256 10.8232 10.2374 10.5303 10.5303C10.2374 10.8232 9.76256 10.8232 9.46967 10.5303L6.96967 8.03033C6.67678 7.73744 6.67678 7.26256 6.96967 6.96967Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class DurationIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 20
    @Input() height: string | number  = 20
    @Input() color: string = '#807A8E'
}
