import {
    Pipe,
    PipeTransform,
} from '@angular/core'

@Pipe({ name: 'trim', pure: true })
export class TextTrimPipe implements PipeTransform {
    public transform(value: string, trimInnerSpacing = false) {
        if (!value) {
            return ''
        }
        if (trimInnerSpacing) {
            value = value.replace(/[\s]+/, ' ')
        }
        return value.trim()
    }
}
