import {
    Pipe,
    PipeTransform,
} from '@angular/core'
import { rrulestr } from 'rrule'


@Pipe({ name: 'rrule', pure: true })
export class RRuleToStringPipe implements PipeTransform {

    public transform(value: string): string {

        if (value === null || value === undefined) {
            return ''
        }

        let rruleSet = rrulestr(value, { forceset: true })
        let rrule = typeof rruleSet['rrules'] === 'function'
            ? rruleSet['rrules']()?.length
                ? rruleSet['rrules']()[0]
                : null
            : rruleSet

        if (rrule) {
            let rruleString = rrule.toText() ?? ''
            return rruleString?.length
                ? rruleString.charAt(0).toUpperCase() + rruleString.slice(1)
                : rruleString
        }

        return ''
    }
}


