import {
    Component,
    ViewEncapsulation,
} from '@angular/core'
import { DeviceUtil } from '@undock/core'

@Component({
    selector: 'app-icon-special-key',
    templateUrl: 'special-key-icon.component.html',
    styleUrls: ['special-key-icon.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SpecialKeyIconComponent {
    public constructor(
        public readonly deviceUtil: DeviceUtil,
    ) {}
}