import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Injectable } from '@angular/core'

import {
    map,
    takeUntil,
} from 'rxjs/operators'
import { Observable, combineLatest } from 'rxjs'

import { AuthManager } from '@undock/auth/services/auth.manager'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'


@Injectable()
export class ExtensionRegularUserGuard implements CanActivate {

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private router: Router,
        private authManager: AuthManager,
    ) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return combineLatest([
            this.authManager.idTokenStream,
            this.authManager.authUserStream,
            this.authManager.isRegularUserStream,
        ]).pipe(
            map(sources => {
                const [ token, authUser, isRegular ] = sources

                if (isRegular) {
                    /**
                     * TODO: V3 - Open this somewhere else
                     */
                    /**
                     * Email confirmation
                     * User should be authenticated via email-password
                     * User should have active email-password auth provider
                     */
                    // if (
                    //     !authUser.emailVerified &&
                    //     token.signInProvider === 'password' &&
                    //     authUser.providerData.some(p => p.providerId === 'password')
                    // ) {
                    //     return this.router.createUrlTree(['email-is-not-verified'], {
                    //         queryParams: { redirectPath: state.url },
                    //     })
                    // }

                    /**
                     * Allow full regular-authUser access in this case
                     */
                    return true
                }

                /**
                 * Navigating to the login page
                 */
                return this.router.createUrlTree(['/chrome/signin'], {
                    queryParams: { redirectPath: state.url },
                })
            }),

            takeUntil(this.destroyEvent),
        )
    }
}
