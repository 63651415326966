import {
    Component,
    EventEmitter,
} from '@angular/core'
import {
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms'


export interface ConfirmPopupConfig {
    title: string,
    description?: string,
    confirmButtonLabel?: string,
    discardButtonLabel?: string,
    highlightConfirmButton?: boolean,
    dockStyleMode?: string,
    hideDiscardButton?: boolean,
    confirmByText?: string,
    confirmationLabel?: string,
    discardButtonStyle?: 'button' | 'link'
    actionButtonsStyle?: 'horizontal' | 'vertical'
}

export class AbstractConfirmPopup {

    public form: UntypedFormGroup

    public onClosed = new EventEmitter<boolean>()

    public config: ConfirmPopupConfig = {
        title: '',
        description: '',
        confirmButtonLabel: 'Confirm',
        discardButtonLabel: 'Cancel',
        highlightConfirmButton: true,
        dockStyleMode: '',
        discardButtonStyle: 'button',
        actionButtonsStyle: 'horizontal',
    }

    public confirm() {
        this.onClosed.emit(true)
    }

    public discard() {
        this.onClosed.emit(false)
    }

    public applyConfig(config: Partial<ConfirmPopupConfig>) {
        Object.assign(this.config, config)
    }
}

@Component({
    selector: 'app-confirm-popup',
    templateUrl: 'confirm-popup.component.html',
    styleUrls: ['confirm-popup.component.scss'],
})
export class ConfirmPopupComponent extends AbstractConfirmPopup {

    public ngOnInit() {
        if (!this.config.confirmByText) {
           this.config.confirmByText = ''
        }
        this.form = new UntypedFormGroup({
            confirmationMessage: new UntypedFormControl('', []),
        })
    }

    public isConfirmButtonDisabled() {
        return this.config.confirmByText !== this.form.value.confirmationMessage
    }
}

