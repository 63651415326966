import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-copy-times-icon',
    templateUrl: 'copy-times-icon.component.html',
    styleUrls: ['copy-times-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyTimesIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 22
    @Input() height: string | number  = 22
    @Input() color: string = '#2B2733' // should be same as $color-primary in theme.scss
    @Input() rotated: boolean = false
}
