import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from "@angular/core";
import { Clipboard } from '@angular/cdk/clipboard'
import {
    EmitOnDestroy,
    DestroyEvent,
    ValueSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'
import { Api } from "@undock/api";
import { SnackbarManager, SnackbarPosition } from "@undock/common/ui-kit/services/snackbar.manager";
import { NOTES_ADAPTER, NotesAdapterInterface } from "@undock/dock/meet/contracts/ui-adapters/notes.adapter";
import { TOPICS_ADAPTER, TopicsAdapterInterface } from "@undock/dock/meet/contracts/ui-adapters/topics.adapter";
import { Topic } from "@undock/dock/meet/models/topic.model";
import { TopicsManager } from "@undock/dock/meet/services/topics.manager";
import { isEmptyString } from '@undock/core'

@Component({
    selector: 'agenda-generator',
    templateUrl: 'agenda-generator.component.html',
    styleUrls: ['agenda-generator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        
    ],
})
export class AgendaGeneratorComponent {

    @CompleteOnDestroy()
    public readonly isResponseLoading$ = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public currentAgendaResponse$ = new ValueSubject<string>(null)

    @CompleteOnDestroy()
    public currentTopicsResponse$ = new ValueSubject<string[]>([])

    @Output() closePrompt = new EventEmitter<void>()

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        @Inject(NOTES_ADAPTER)
        protected notesAdapter: NotesAdapterInterface,
        @Inject(TOPICS_ADAPTER)
        protected topicsAdapter: TopicsAdapterInterface,
        protected clipboard: Clipboard,
        protected topicsManager: TopicsManager,
        protected snackbarManager: SnackbarManager,
    ) {}

    async ngOnInit() {}

    public async submitPrompt(prompt: string, event?: KeyboardEvent) {
        if (!this.isResponseLoading$.getValue()) {
            if (!event?.shiftKey) {
                event.stopPropagation()
                event.preventDefault()

                if (!isEmptyString(prompt)) {
                    await this.generateAgenda(prompt)
                }
            }
        }
    }

    public async submitRefinedPrompt(prompt: string, event?: KeyboardEvent) {
        if (!this.isResponseLoading$.getValue()) {
            if (!event?.shiftKey) {
                event.stopPropagation()
                event.preventDefault()

                if (!isEmptyString(prompt)) {
                    await this.generateAgenda(prompt)
                }
            }
        }
    }

    /* 
    * Generates a new agenda based on the CURRENT Agenda and Topics, if any.
    */
    public async generateAgenda(currentInput: string) {

        this.isResponseLoading$.next(true)

        let currentAgenda = this.currentAgendaResponse$.getValue() ?? await this.notesAdapter.notesTextStream
        let currentTopics = []

        for (let topic of await this.topicsManager.topicsStream) {
            currentTopics.push(topic.text)
        }
        this.currentTopicsResponse$.next(currentTopics)

        let response = await this.api.nlp.agenda.generateAgenda({
            agenda: currentAgenda.length ? currentAgenda : '',
            latestPrompt: currentInput,
            topics: currentTopics.length ? currentTopics : []
        })

        this.currentAgendaResponse$.next(response.agenda)
        this.currentTopicsResponse$.next(response.topics)

        this.isResponseLoading$.next(false)
    }

    public async insertAgenda() {
        
        this.notesAdapter.setNotes(await this.currentAgendaResponse$)

        let model = await this.topicsAdapter.ownerModelStream
        this.topicsManager.initializeForModel(model)

        for(let topic of await this.currentTopicsResponse$) {
            // Construct Topic Partial from GPT action items to then add to topics list
            let currentTopic: Partial<Topic> = {
                text: topic
            }
            await this.topicsManager.new(model, currentTopic)
        }

        // After setting notes and topic, let parent component know we're done??
        this.closePrompt.emit()
    }

    public copyAgendaText(agenda: string) {
        this.clipboard.copy(agenda)
        this.snackbarManager.info("Agenda copied to clipboard", SnackbarPosition.BottomCenter)
    }
}
