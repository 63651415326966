<div class="icon-button-container" (clickOutside)="dropDownShowing = false" (click)="clickButton()"
     [class.button-toggled]="toggled"
     [style.width]="width" [style.height]="height" [style.border]="border" [style.border-radius]="borderRadius"
     [style.background]="backgroundColor" [style.border-width]="borderWidth" [style.border-color]="borderColor"
     [style.overflow]="mode === 'dropdown' ? 'visible' : 'hidden'" [class.mode-dropdown]="mode === 'dropdown'"
     [class.broadcast-button]="expandedMode" [class.-large-size]="largeSize"
>
    <ng-container [ngSwitch]="expandedMode">
        <ng-container *ngSwitchCase="false">
            <div *ngIf="position !== 'none'" class="icon-button-icon-box" [ngClass]="{
              'icon-left': position === 'left',
              'icon-right': position === 'right'
            }" [class.button-toggled]="toggled">
                <img *ngIf="image" [src]="'/assets/img/' + image + '.svg'" default="/assets/img/placeholders/user.png"/>
            </div>

            <div class="icon-button-label" [class.icon-left]="position === 'left'"
                 [style.justify-content]="labelContent"
                 [style.padding]="labelPadding"
                 [style.z-index]="zIndex"
                 [class.icon-right]="position === 'right'"
                 [class.mode-dropdown]="mode === 'dropdown'"
                 [class.button-toggled]="toggled"
                 [class.button-disabled]="disabled"

                 (click)="showDropDown($event)">
                <span [style.font-size]="fontSize" [style.font-weight]="fontWeight">{{label}}</span>
            </div>

            <!-- DROP DOWN -->
            <div *ngIf="values && values?.length > 0" class="icon-button-dropdown-container"
                 [style.display]="dropDownShowing ? 'flex' : 'none'"
            >
                <div class="icon-button-dropdown-list">
                    <div *ngFor="let value of values" class="icon-button-dropdown-item" [attr.data-value]="value.value"
                         [class.item-selected]="value.value === currentValue"
                         (click)="selectValue($event, value.value)"><span class="icon-button-dropdown-item-label" [style.font-size]="listFontSize">{{value.label}}</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="true">
            <div class="broadcast-style">
                <div class="broadcast-icon">
                    <ng-content></ng-content>
                </div>

                <div class="broadcast-button-label">
                    <div class="label">
                        <h4>{{label}}</h4>
                        <span *ngIf="labelBadge" class="label-badge">{{labelBadge}}</span>
                    </div>

                    <p>{{description}}</p>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

