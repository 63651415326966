import { TimezoneData } from '@undock/time/availability'

export const supportedTimeZones = new Map<string, Partial<TimezoneData>>([
    ['Pacific/Niue', { 'generalName': 'Niue Time', 'cities': ['Alofi'] }],
    ['Pacific/Pago_Pago', { 'generalName': 'Samoa Standard Time', 'cities': ['Pago Pago', 'Apia'] }],
    ['Pacific/Rarotonga', { 'generalName': 'Cook Islands Standard Time', 'cities': ['Avarua'] }],
    ['Pacific/Honolulu', { 'generalName': 'Hawaii-Aleutian Standard Time', 'cities': ['Honolulu', 'Hilo', 'Kailua-Kona', 'Kahului', 'Lihue', 'Pearl City', 'Waipahu', 'Kaneohe', 'Mililani Town', 'Kahului'] }],
    ['Pacific/Tahiti', { 'generalName': 'Tahiti Time', 'cities': ['Papeete', 'Faaa', 'Punaauia', 'Pirae', 'Mahina', 'Paea', 'Arue', 'Papara', 'Teva I Uta', 'Faaone'] }],
    ['Pacific/Marquesas', { 'generalName': 'Marquesas Time', 'cities': ['Taiohae'] }],
    ['Pacific/Gambier', { 'generalName': 'Gambier Time', 'cities': ['Rikitea'] }],
    ['America/Adak', { 'generalName': 'Hawaii-Aleutian Time (Adak)', 'cities': ['Adak'] }],
    ['America/Anchorage', { 'generalName': 'Alaska Time - Anchorage', 'cities': ['Anchorage', 'Wasilla', 'Palmer', 'Eagle River', 'Knik-Fairview', 'Big Lake', 'Chugiak', 'Peters Creek', 'Butte', 'Girdwood'] }],
    ['America/Juneau', { 'generalName': 'Alaska Time - Juneau', 'cities': ['Juneau', 'Douglas', 'Auke Bay', 'Gustavus', 'Hoonah', 'Angoon', 'Tenakee Springs', 'Elfin Cove', 'Pelican', 'Excursion Inlet'] }],
    ['America/Metlakatla', { 'generalName': 'Alaska Time - Metlakatla', 'cities': ['Metlakatla', 'Saxman', 'Ketchikan', 'Klawock', 'Craig', 'Thorne Bay', 'Wrangell', 'Petersburg', 'Coffman Cove', 'Port Protection'] }],
    ['America/Nome', { 'generalName': 'Alaska Time - Nome', 'cities': ['Nome', 'Unalakleet', 'Shaktoolik', 'Brevig Mission', 'Wales', 'Shishmaref', 'Diomede', 'Savoonga', 'Gambell', 'White Mountain'] }],
    ['America/Sitka', { 'generalName': 'Alaska Time - Sitka', 'cities': ['Sitka', 'Port Alexander', 'Kake', 'Edna Bay', 'Hollis', 'Whale Pass', 'Point Baker', 'Port Protection', 'Kupreanof', 'Portage Creek'] }],
    ['America/Yakutat', { 'generalName': 'Alaska Time - Yakutat', 'cities': ["Yakutat","Icy Bay","Dry Bay","Alsek River","Dang…River","Yakutat Forelands","Manby Shore","Situk"] }],
    ['Pacific/Pitcairn', { 'generalName': 'Pitcairn Time', 'cities': ["Adamstown"] }],
    ['America/Hermosillo', { 'generalName': 'Mexican Pacific Standard Time - Hermosillo', 'cities': ["Mazatlán","Culiacán","Los Mochis","Guasave","Guamúchil","Navolato","El Rosario","Escuinapa","Sinaloa de Leyva","Angostura"] }],
    ['America/Mazatlan', { 'generalName': 'Mexican Pacific Standard Time - Mazatlan', 'cities': ["Mazatlán","Culiacán","Los Mochis","Guasave","Guamúchil","Navolato","El Rosario","Escuinapa","Sinaloa de Leyva","Angostura"] }],
    ['America/Dawson_Creek', { 'generalName': 'Mountain Standard Time - Dawson Creek', 'cities': ["Dawson Creek","Fort St. John","Chetwynd","Taylor","Tumbler Ridge","Pouce Coupe","Hudson's Hope","Farmington","Tomslake","Progress"] }],
    ['America/Fort_Nelson', { 'generalName': 'Mountain Standard Time - Fort Nelson', 'cities': ["Fort Nelson","Toad River","Steamboat","Muncho Lake","Fireside","Liard River","Lower Post","Coal River","Upper Halfway","Prophet River"] }],
    ['America/Phoenix', { 'generalName': 'Mountain Standard Time - Phoenix', 'cities': ["Phoenix","Tucson","Mesa","Chandler","Glendale","Scottsdale","Gilbert","Tempe","Peoria","Surprise"] }],
    ['America/Los_Angeles', { 'generalName': 'Pacific Time - Los Angeles', 'cities': ["Los Angeles","San Diego","San Jose","San Francisco","Fresno","Sacramento","Long Beach","Oakland","Bakersfield","Anaheim"] }],
    ['America/Tijuana', { 'generalName': 'Pacific Time - Tijuana', 'cities': ["Tijuana","Rosarito","Tecate","Ensenada","Playas de Rosarito","La Joya","El Descanso","San Antonio del Mar","Baja Malibu","Real del Mar"] }],
    ['America/Vancouver', { 'generalName': 'Pacific Time - Vancouver', 'cities': ["Vancouver","Victoria","Kelowna","Abbotsford","Kamloops","Nanaimo","Prince George","Chilliwack","Vernon","Courtenay"] }],
    ['America/Dawson', { 'generalName': 'Yukon Time - Dawson', 'cities': ["Dawson City","Mayo","Pelly Crossing","Carmacks","Faro","Ross River","Stewart Crossing","Champagne","Minto","Keno City"] }],
    ['America/Whitehorse', { 'generalName': 'Yukon Time - Whitehorse', 'cities': ["Whitehorse","Haines Junction","Marsh Lake","Tagish","Carcross","Teslin","Mount Lorne","Ibex Valley","Destruction Bay"] }],
    ['America/Bahia_Banderas', { 'generalName': 'Central Standard Time - Bahía de Banderas', 'cities': ["Puerto Vallarta", "Nuevo Vallarta", "Bucerías", "La Cruz de Huanacaxtle", "Punta de Mita"] }],
    ['America/Belize', { 'generalName': 'Central Standard Time - Belize', 'cities': ["Belize City","San Ignacio","Orange Walk","Belmopan","Dangriga","Corozal","San Pedro","Benque Viejo del Carmen","Punta Gorda","Placencia"] }],
    ['America/Chihuahua', { 'generalName': 'Central Standard Time - Chihuahua', 'cities': ["Chihuahua", "Ciudad Juárez", "Delicias", "Hidalgo del Parral", "Cuauhtémoc"] }],
    ['America/Costa_Rica', { 'generalName': 'Central Standard Time - Costa Rica', 'cities': ["San José","Limón","Alajuela","Heredia","Puntarenas","Cartago","Liberia","Guápiles","San Ramón","San Isidro de El General"] }],
    ['America/El_Salvador', { 'generalName': 'Central Standard Time - El Salvador', 'cities': ["San Salvador","Soyapango","Santa Ana","San Miguel","Mejicanos","Santa Tecla","Apopa","Delgado","Sonsonate","San Marcos"] }],
    ['America/Guatemala', { 'generalName': 'Central Standard Time - Guatemala', 'cities': ["Guatemala City","Mixco","Villa Nueva","Quetzaltenango","San Miguel Petapa","Escuintla","San Juan Sacatepéquez","Villa Canales","Chinautla","Chimaltenango"] }],
    ['America/Managua', { 'generalName': 'Central Standard Time - Managua', 'cities': ["Managua","León","Masaya","Chinandega","Granada","Estelí","Tipitapa","Jinotepe","Matagalpa","Bluefields"] }],
    ['America/Merida', { 'generalName': 'Central Standard Time - Mérida', 'cities': ["Mérida", "Progreso", "Tizimín", "Valladolid", "Izamal"] }],
    ['America/Mexico_City', { 'generalName': 'Central Standard Time - Mexico City', 'cities': ["Mexico City","Puebla","Guadalajara","Monterrey","Toluca","Tijuana","León","Ciudad Juárez","Torreón","San Luis Potosí"] }],
    ['America/Monterrey', { 'generalName': 'Central Standard Time - Monterrey', 'cities': ["Monterrey", "San Nicolás de los Garza", "Guadalupe", "San Pedro Garza García", "Santa Catarina"] }],
    ['America/Regina', { 'generalName': 'Central Standard Time - Regina', 'cities': ["Regina", "Saskatoon", "Moose Jaw", "Prince Albert", "Yorkton"] }],
    ['America/Swift_Current', { 'generalName': 'Central Standard Time - Swift Current', 'cities': ["Swift Current","Gull Lake","Shaunavon","Maple Creek","Herbert","Kyle","Cabri","Waldeck","Hodgeville","Tompkins"] }],
    ['America/Tegucigalpa', { 'generalName': 'Central Standard Time - Tegucigalpa', 'cities': ["Tegucigalpa", "San Pedro Sula", "La Ceiba", "Choloma", "El Progreso"] }],
    ['Pacific/Easter', { 'generalName': 'Easter Island Time', 'cities': ["Hanga Roa"] }],
    ['Pacific/Galapagos', { 'generalName': 'Galapagos Time', 'cities': ["Puerto Ayora", "Puerto Baquerizo Moreno", "Puerto Villamil"] }],
    ['America/Boise', { 'generalName': 'Mountain Time - Boise', 'cities': ["Boise", "Idaho Falls", "Pocatello", "Caldwell", "Meridian"] }],
    ['America/Cambridge_Bay', { 'generalName': 'Mountain Time - Cambridge Bay', 'cities': ["Cambridge Bay", "Gjoa Haven", "Kugaaruk", "Taloyoak", "Kugluktuk"] }],
    ['America/Ciudad_Juarez', { 'generalName': 'Mountain Time - Ciudad Juárez', 'cities': ["Ciudad Juárez", "Chihuahua", "Piedras Negras", "Nuevo Casas Grandes", "Ascensión"] }],
    ['America/Denver', { 'generalName': 'Mountain Time - Denver', 'cities': ["Denver", "Longmont", "Colorado Springs", "Boulder", "Aspen", "Aurora", "Fort Collins", "Lakewood"] }],
    ['America/Edmonton', { 'generalName': 'Mountain Time - Edmonton', 'cities': ["Edmonton", "Calgary", "Red Deer", "Lethbridge", "St. Albert"] }],
    ['America/Inuvik', { 'generalName': 'Mountain Time - Inuvik', 'cities': ["Inuvik", "Tuktoyaktuk", "Aklavik", "Ulukhaktok", "Sachs Harbour"] }],
    ['America/Yellowknife', { 'generalName': 'Mountain Time - Yellowknife', 'cities': ["Yellowknife", "Hay River", "Fort Smith", "Inuvik", "Norman Wells"] }],
    ['America/Eirunepe', { 'generalName': 'Acre Standard Time - Eirunepe', 'cities': ["Eirunepé", "Envira", "Ipixuna", "Guajará", "Feijó"] }],
    ['America/Rio_Branco', { 'generalName': 'Acre Standard Time - Rio Branco', 'cities': ["Rio Branco", "Cruzeiro do Sul", "Sena Madureira", "Tarauacá", "Brasiléia"] }],
    ['America/North_Dakota/Beulah', { 'generalName': 'Central Time - Beulah, North Dakota', 'cities': ["Beulah", "Hazen", "Center", "Stanton", "Crosby"] }],
    ['America/North_Dakota/Center', { 'generalName': 'Central Time - Center, North Dakota', 'cities': ["Center", "Beulah", "Hazen", "Stanton", "Crosby"] }],
    ['America/Chicago', { 'generalName': 'Central Time - Chicago', 'cities': ["Chicago", "Houston", "Dallas", "San Antonio", "Austin"] }],
    ['America/Indiana/Knox', { 'generalName': 'Central Time - Knox, Indiana', 'cities': ["Knox", "Plymouth", "Rochester", "Winamac", "Monticello"] }],
    ['America/Matamoros', { 'generalName': 'Central Time - Matamoros', 'cities': ["Matamoros", "Reynosa", "Nuevo Laredo", "San Fernando", "Valle Hermoso"] }],
    ['America/Menominee', { 'generalName': 'Central Time - Menominee', 'cities': ["Menominee", "Marinette", "Escanaba", "Iron Mountain", "Sturgeon Bay"] }],
    ['America/North_Dakota/New_Salem', { 'generalName': 'Central Time - New Salem, North Dakota', 'cities': ["New Salem", "Bismarck", "Mandan", "Dickinson", "Williston"] }],
    ['America/Ojinaga', { 'generalName': 'Central Time - Ojinaga', 'cities': ["Ojinaga", "Presidio", "Manuel Benavides", "Coyame del Sotol", "La Mula"] }],
    ['America/Rainy_River', { 'generalName': 'Central Time - Rainy River', 'cities': ["Rainy River", "Fort Frances", "Atikokan", "Sioux Lookout", "Dryden"] }],
    ['America/Rankin_Inlet', { 'generalName': 'Central Time - Rankin Inlet', 'cities': ["Rankin Inlet", "Chesterfield Inlet", "Coral Harbour", "Baker Lake", "Arviat"] }],
    ['America/Resolute', { 'generalName': 'Central Time - Resolute', 'cities': ["Resolute", "Grise Fiord", "Pond Inlet", "Arctic Bay", "Igloolik"] }],
    ['America/Indiana/Tell_City', { 'generalName': 'Central Time - Tell City, Indiana', 'cities': ["Tell City", "Cannelton", "Hawesville", "Cloverport", "Hardinsburg"] }],
    ['America/Winnipeg', { 'generalName': 'Central Time - Winnipeg', 'cities': ["Winnipeg", "Brandon", "Portage la Prairie", "Steinbach", "Thompson"] }],
    ['America/Bogota', { 'generalName': 'Colombia Standard Time', 'cities': ["Bogotá", "Medellín", "Cali", "Barranquilla", "Cartagena"] }],
    ['America/Cancun', { 'generalName': 'Eastern Standard Time - Cancún', 'cities': ["Cancún", "Playa del Carmen", "Tulum", "Cozumel", "Isla Mujeres"] }],
    ['America/Jamaica', { 'generalName': 'Eastern Standard Time - Jamaica', 'cities': ["Kingston", "Montego Bay", "Ocho Rios", "Negril", "Spanish Town"] }],
    ['America/Panama', { 'generalName': 'Eastern Standard Time - Panama', 'cities': ["Panama City", "San Miguelito", "David", "Colón", "La Chorrera"] }],
    ['America/Guayaquil', { 'generalName': 'Ecuador Time', 'cities': ["Guayaquil", "Cuenca", "Machala", "Santo Domingo", "Manta"] }],
    ['America/Lima', { 'generalName': 'Peru Standard Time', 'cities': ["Lima", "Arequipa", "Trujillo", "Chiclayo", "Piura"] }],
    ['America/Boa_Vista', { 'generalName': 'Amazon Standard Time - Boa Vista', 'cities': ["Boa Vista", "Bonfim", "Canta", "Caracaraí", "Mucajaí"] }],
    ['America/Campo_Grande', { 'generalName': 'Amazon Standard Time - Campo Grande', 'cities': ["Campo Grande", "Dourados", "Corumbá", "Três Lagoas", "Ponta Porã"] }],
    ['America/Cuiaba', { 'generalName': 'Amazon Standard Time - Cuiaba', 'cities': ["Cuiabá", "Várzea Grande", "Rondonópolis", "Sinop", "Tangará da Serra"] }],
    ['America/Manaus', { 'generalName': 'Amazon Standard Time - Manaus', 'cities': ["Manaus", "Parintins", "Itacoatiara", "Manacapuru", "Coari"] }],
    ['America/Porto_Velho', { 'generalName': 'Amazon Standard Time - Porto Velho', 'cities': ["Porto Velho", "Ariquemes", "Vilhena", "Ji-Paraná", "Cacoal"] }],
    ['America/Barbados', { 'generalName': 'Atlantic Standard Time - Barbados', 'cities': ["Bridgetown", "Speightstown", "Oistins", "Bathsheba", "Holetown"] }],
    ['America/Martinique', { 'generalName': 'Atlantic Standard Time - Martinique', 'cities': ["Fort-de-France", "Le Lamentin", "Le Robert", "Sainte-Marie", "Le François"] }],
    ['America/Puerto_Rico', { 'generalName': 'Atlantic Standard Time - Puerto Rico', 'cities': ["San Juan", "Ponce", "Caguas", "Mayagüez", "Bayamón"] }],
    ['America/Santo_Domingo', { 'generalName': 'Atlantic Standard Time - Santo Domingo', 'cities': ["Santo Domingo", "Santiago de los Caballeros", "La Romana", "San Cristóbal", "Punta Cana"] }],
    ['America/La_Paz', { 'generalName': 'Bolivia Time', 'cities': ["La Paz", "Santa Cruz de la Sierra", "Cochabamba", "Sucre", "Oruro"] }],
    ['America/Santiago', { 'generalName': 'Chile Time', 'cities': ["Santiago", "Valparaíso", "Concepción", "La Serena", "Antofagasta"] }],
    ['America/Havana', { 'generalName': 'Cuba Time', 'cities': ["Havana", "Santiago de Cuba", "Camagüey", "Holguín", "Santa Clara"] }],
    ['America/Detroit', { 'generalName': 'Eastern Time - Detroit', 'cities': ["Detroit", "Grand Rapids", "Warren", "Sterling Heights", "Ann Arbor"] }],
    ['America/Grand_Turk', { 'generalName': 'Eastern Time - Grand Turk', 'cities': ["Cockburn Town", "Balfour Town", "Bambarra", "Conch Bar", "Kew"] }],
    ['America/Indiana/Indianapolis', { 'generalName': 'Eastern Time - Indianapolis', 'cities': ["Indianapolis", "Fort Wayne", "Evansville", "South Bend", "Carmel"] }],
    ['America/Iqaluit', { 'generalName': 'Eastern Time - Iqaluit', 'cities': ["Iqaluit", "Clyde River", "Pangnirtung", "Qikiqtarjuaq", "Cape Dorset"] }],
    ['America/Kentucky/Louisville', { 'generalName': 'Eastern Time - Louisville', 'cities': ["Louisville", "Lexington", "Bowling Green", "Owensboro", "Covington"] }],
    ['America/Indiana/Marengo', { 'generalName': 'Eastern Time - Marengo, Indiana', 'cities': ["Marengo"] }],
    ['America/Kentucky/Monticello', { 'generalName': 'Eastern Time - Monticello, Kentucky', 'cities': ["Monticello"] }],
    ['America/New_York', { 'generalName': 'Eastern Time - New York', 'cities': ["New York", "Boston", "Washington D.C.", "Baltimore", "Miami", "Atlanta", "Orlando", "Trenton", "Philadelphia", "Pittsburgh", "Cleveland", "Cincinnati", "Buffalo", "Rochester","Charlotte", "Raleigh", "Richmond", "Jacksonville", "Detroit", "Nashville"] }],
    ['America/Nipigon', { 'generalName': 'Eastern Time - Nipigon', 'cities': ["Nipigon"] }],
    ['America/Pangnirtung', { 'generalName': 'Eastern Time - Pangnirtung', 'cities': ["Pangnirtung"] }],
    ['America/Indiana/Petersburg', { 'generalName': 'Eastern Time - Petersburg, Indiana', 'cities': ["Petersburg"] }],
    ['America/Port-au-Prince', { 'generalName': 'Eastern Time - Port-au-Prince', 'cities': ["Port-au-Prince","Cap-Haitien","Delmas","Petionville","Croix-des-Bouquets","Jacmel","Leogane","Gonaives","Les Cayes","Petit-Goave"] }],
    ['America/Thunder_Bay', { 'generalName': 'Eastern Time - Thunder Bay', 'cities': ["Thunder Bay"] }],
    ['America/Toronto', { 'generalName': 'Eastern Time - Toronto', 'cities': ["Toronto","Montreal","Ottawa","Mississauga","Winnipeg","Vancouver","Calgary","Edmonton","Brampton","Hamilton"] }],
    ['America/Indiana/Vevay', { 'generalName': 'Eastern Time - Vevay, Indiana', 'cities': ["Vevay"] }],
    ['America/Indiana/Vincennes', { 'generalName': 'Eastern Time - Vincennes, Indiana', 'cities': ["Vincennes"] }],
    ['America/Indiana/Winamac', { 'generalName': 'Eastern Time - Winamac, Indiana', 'cities': ["Winamac"] }],
    ['America/Guyana', { 'generalName': 'Guyana Time', 'cities': ["George Town","West Bay","Bodden Town","East End","North Side","West End","Old Man Bay","Newlands","Gun Bay","Prospect Park"] }],
    ['America/Asuncion', { 'generalName': 'Paraguay Time', 'cities': ["Asunción","Ciudad del Este","San Lorenzo","Luque","Capiatá","Lambare","Fernando de la Mora","Limpio","Nemby","Encarnación"] }],
    ['America/Caracas', { 'generalName': 'Venezuela Time', 'cities': ["Caracas","Maracaibo","Valencia","Barquisimeto","Maracay","Ciudad Guayana","Barcelona","Maturín","Cabimas","Coro"] }],
    ['America/Argentina/Buenos_Aires', { 'generalName': 'Argentina Standard Time - Buenos Aires', 'cities': ["Buenos Aires","La Plata","Mar del Plata","Bahía Blanca","San Nicolás de los Arroyos","Chacabuco","Junín","San Pedro","Necochea","Tandil"] }],
    ['America/Argentina/Catamarca', { 'generalName': 'Argentina Standard Time - Catamarca', 'cities': ["San Fernando del Valle de Catamarca","Santa María","Tinogasta","Belén","Recreo","Andalgalá","Fiambalá","La Puerta","San José","Villa Vil"] }],
    ['America/Argentina/Cordoba', { 'generalName': 'Argentina Standard Time - Cordoba', 'cities': ["Cordoba","Rio Cuarto","Villa Carlos Paz","San Francisco","Villa Maria","Rio Tercero","Bell Ville","Jesús María","Arroyito","Villa Dolores"] }],
    ['America/Argentina/Jujuy', { 'generalName': 'Argentina Standard Time - Jujuy', 'cities': ["San Salvador de Jujuy","Palpalá","Libertador General San Martín","San Pedro","Humahuaca","La Quiaca","Abra Pampa","El Carmen","Tilcara","Perico"] }],
    ['America/Argentina/La_Rioja', { 'generalName': 'Argentina Standard Time - La Rioja', 'cities': ["La Rioja","Chilecito","Chamical","Aimogasta","Villa Unión","Chepes","Olta","Patquia","Anillaco","Villa Castelli"] }],
    ['America/Argentina/Mendoza', { 'generalName': 'Argentina Standard Time - Mendoza', 'cities': ["Mendoza","San Rafael","Godoy Cruz","Luján de Cuyo","San Martín","Tunuyán","Rivadavia","Malargüe","General Alvear","Maipú"] }],
    ['America/Argentina/Rio_Gallegos', { 'generalName': 'Argentina Standard Time - Rio Gallegos', 'cities': ["Río Gallegos","El Calafate","Puerto Deseado","Caleta Olivia","Pico Truncado","Las Heras","Comandante Luis Piedrabuena","Gobernador Gregores","Perito Moreno","28 de Noviembre"] }],
    ['America/Argentina/Salta', { 'generalName': 'Argentina Standard Time - Salta', 'cities': ["Salta","San Ramón de la Nueva Orán","Tartagal","Cafayate","Metán","General Güemes","Joaquín V. González","Cerrillos","Rosario de la Frontera","La Merced"] }],
    ['America/Argentina/San_Juan', { 'generalName': 'Argentina Standard Time - San Juan', 'cities': ["San Juan","Rivadavia","Chimbas","Santa Lucía","Caucete","Pocito","Rawson","Albardón","Villa Paula de Sarmiento","9 de Julio"] }],
    ['America/Argentina/San_Luis', { 'generalName': 'Argentina Standard Time - San Luis', 'cities': ["San Luis","Villa Mercedes","Villa de Merlo","La Toma","Buena Esperanza","Justo Daract","Naschel","Tilisarao","Concarán","Santa Rosa del Conlara"] }],
    ['America/Argentina/Tucuman', { 'generalName': 'Argentina Standard Time - Tucuman', 'cities': ["San Miguel de Tucumán","Concepción","Monteros","Aguilares","Tafí Viejo","Famaillá","Simoca","Yerba Buena","Banda del Río Salí","Alderetes"] }],
    ['America/Argentina/Ushuaia', { 'generalName': 'Argentina Standard Time - Ushuaia', 'cities': ["Ushuaia","Río Grande","Tolhuin","Porvenir","Punta Arenas","Puerto Natales","Puerto Williams","Villa Tehuelches","Cerro Sombrero","Timaukel"] }],
    ['Atlantic/Bermuda', { 'generalName': 'Atlantic Time - Bermuda', 'cities': ["Hamilton","Saint George","Flatts Village","Somerset Village","Tucker's Town","Ireland Island","St. David's Island","Bailey's Bay","Spanish Point","Warwick Camp"] }],
    ['America/Glace_Bay', { 'generalName': 'Atlantic Time - Glace Bay', 'cities': ["Glace Bay","Sydney","New Waterford","Dominion","Reserve Mines","Louisbourg","North Sydney","Sydney Mines","Whitney Pier","Birch Grove"] }],
    ['America/Goose_Bay', { 'generalName': 'Atlantic Time - Goose Bay', 'cities': ["Happy Valley-Goose Bay","North West River","Sheshatshiu","Mud Lake","Churchill Falls","Cartwright","Rigolet","Makkovik","Hopedale","Postville"] }],
    ['America/Halifax', { 'generalName': 'Atlantic Time - Halifax', 'cities': ["Halifax","Dartmouth","Sydney","Truro","New Glasgow","Glace Bay","Sydney Mines","Amherst","Bridgewater","Yarmouth"] }],
    ['America/Moncton', { 'generalName': 'Atlantic Time - Moncton', 'cities': ["Moncton","Saint John","Fredericton","Dieppe","Riverview","Quispamsis","Rothesay","Oromocto","Sackville","Bathurst"] }],
    ['America/Thule', { 'generalName': 'Atlantic Time - Thule', 'cities': ["Thule (Pituffik)","Qaanaaq"] }],
    ['America/Araguaina', { 'generalName': 'Brasilia Standard Time - Araguaina', 'cities': ["Araguaina","Palmas","Gurupi","Porto Nacional","Miracema do Tocantins","Paraíso do Tocantins","Guaraí","Colinas do Tocantins","Tocantinópolis","Taguatinga"] }],
    ['America/Bahia', { 'generalName': 'Brasilia Standard Time - Bahia', 'cities': ["Salvador", "Feira de Santana", "Vitória da Conquista", "Camaçari", "Itabuna"] }],
    ['America/Belem', { 'generalName': 'Brasilia Standard Time - Belem', 'cities': ["Belém","Ananindeua","Santarém","Marabá","Castanhal","Abaetetuba","Cametá","Bragança","Altamira","Tucuruí"] }],
    ['America/Fortaleza', { 'generalName': 'Brasilia Standard Time - Fortaleza', 'cities': ["Fortaleza", "Juazeiro do Norte", "Sobral", "Crato", "Iguatu"] }],
    ['America/Maceio', { 'generalName': 'Brasilia Standard Time - Maceio', 'cities': ["Maceió", "Arapiraca", "Rio Largo", "Palmeira dos Índios", "União dos Palmares"] }],
    ['America/Recife', { 'generalName': 'Brasilia Standard Time - Recife', 'cities': ["Recife", "Olinda", "Jaboatão dos Guararapes", "Caruaru", "Paulista"] }],
    ['America/Santarem', { 'generalName': 'Brasilia Standard Time - Santarem', 'cities': ["Santarem"] }],
    ['America/Sao_Paulo', { 'generalName': 'Brasilia Standard Time - Sao Paulo', 'cities': ["São Paulo","Guarulhos","Campinas","São Bernardo do Campo","Santo André","Osasco","São José dos Campos","Sorocaba","Ribeirão Preto","São Carlos"] }],
    ['Atlantic/Stanley', { 'generalName': 'Falkland Islands Standard Time', 'cities': ["Stanley"] }],
    ['America/Cayenne', { 'generalName': 'French Guiana Time', 'cities': ["Cayenne","Matoury","Saint-Laurent-du-Maroni","Kourou","Remire-Montjoly","Macouria","Mana","Apatou","Iracoubo","Grand-Santi"] }],
    ['Antarctica/Palmer', { 'generalName': 'Palmer Time', 'cities': ["Palmer"] }],
    ['America/Punta_Arenas', { 'generalName': 'Punta Arenas Time', 'cities': ["Punta Arenas", "Puerto Natales", "Porvenir", "Puerto Williams", "Cerro Sombrero"] }],
    ['Antarctica/Rothera', { 'generalName': 'Rothera Time', 'cities': ["Rothera"] }],
    ['America/Paramaribo', { 'generalName': 'Suriname Time', 'cities': ["Paramaribo","Lelydorp","Nieuw Nickerie","Moengo","Meerzorg","Albina","Wageningen","Groningen","Brownsweg","Onverwacht"] }],
    ['America/Montevideo', { 'generalName': 'Uruguay Standard Time', 'cities': ["Montevideo","Salto","Ciudad de la Costa","Paysandú","Las Piedras","Rivera","Maldonado","Tacuarembó","Melo","Mercedes"] }],
    ['America/St_Johns', { 'generalName': 'Newfoundland Time', 'cities': ["St. John's", "Mount Pearl", "Conception Bay South", "Paradise", "Torbay"] }],
    ['America/Noronha', { 'generalName': 'Fernando de Noronha Standard Time', 'cities': ["Fernando de Noronha","Vila dos Remédios","Sueste","Cacimba do Padre","Boldró","Conceição","Praia do Americano","Porto de Santo Antônio","Atalaia","Praia do Leão"] }],
    ['Atlantic/South_Georgia', { 'generalName': 'South Georgia Time', 'cities': ["King Edward Point","Grytviken","Godthul","Stromness","Husvik","Leith Harbour","Prince Olav Harbour","Ocean Harbour","Elsehul","Drygalski Fjord"] }],
    ['America/Miquelon', { 'generalName': 'St. Pierre & Miquelon Time', 'cities': ["Saint-Pierre","Miquelon","Langlade","Saint-Louis","Miquelon-Langlade","Saint-Pierre-Langlade","Île aux Marins","Pointe Plate"] }],
    ['America/Nuuk', { 'generalName': 'West Greenland Time', 'cities': ["Nuuk","Sisimiut","Ilulissat","Qaqortoq","Aasiaat","Maniitsoq","Tasiilaq","Paamiut","Narsaq","Upernavik"] }],
    ['Atlantic/Cape_Verde', { 'generalName': 'Cape Verde Standard Time', 'cities': ["Praia","Mindelo","Santa Maria","Cova Figueira","Porto Novo","Sal Rei","Tarrafal","Vila da Ribeira Brava","Pombas","São Filipe"] }],
    ['Atlantic/Azores', { 'generalName': 'Azores Time', 'cities': ["Ponta Delgada","Lagoa","Ribeira Grande","Praia da Vitória","Angra do Heroísmo","Horta","Vila Franca do Campo","Santa Cruz da Graciosa","Velas","Corvo"] }],
    ['UTC', { 'generalName': 'Coordinated Universal Time', 'cities': [] }],
    ['America/Scoresbysund', { 'generalName': 'East Greenland Time', 'cities': ["Scoresbysund"] }],
    ['Etc/GMT', { 'generalName': 'Greenwich Mean Time', 'cities': [] }],
    ['Africa/Abidjan', { 'generalName': 'Greenwich Mean Time - Abidjan', 'cities': ["Abidjan","Yamoussoukro","Bouaké","Daloa","San-Pédro","Korhogo","Abengourou","Man","Gagnoa","Bondoukou"] }],
    ['Africa/Bissau', { 'generalName': 'Greenwich Mean Time - Bissau', 'cities': ["Bissau","Bafatá","Gabú","Cacheu","Bissorã","Bolama","Mansôa","Catió","Canchungo","Farim"] }],
    ['America/Danmarkshavn', { 'generalName': 'Greenwich Mean Time - Danmarkshavn', 'cities': ["Danmarkshavn"] }],
    ['Africa/Monrovia', { 'generalName': 'Greenwich Mean Time - Monrovia', 'cities': ["Monrovia","Gbarnga","Buchanan","Kakata","Bensonville","Harper","Voinjama","Zwedru","Robertsport","Tubmanburg"] }],
    ['Atlantic/Reykjavik', { 'generalName': 'Greenwich Mean Time - Reykjavik', 'cities': ["Reykjavik","Kopavogur","Hafnarfjörður","Akureyri","Kópavogur","Garðabær","Mosfellsbær","Akranes","Selfoss","Seltjarnarnes"] }],
    ['Africa/Sao_Tome', { 'generalName': 'Greenwich Mean Time - São Tomé', 'cities': ["São Tomé"] }],
    ['Africa/Algiers', { 'generalName': 'Central European Standard Time - Algiers', 'cities': ["Algiers","Oran","Constantine","Annaba","Blida","Batna","Djelfa","Sétif","Sidi Bel Abbès","Biskra"] }],
    ['Africa/Tunis', { 'generalName': 'Central European Standard Time - Tunis', 'cities': ["Tunis","Sfax","Sousse","Kairouan","Bizerte","Gabès","Ariana","Gafsa","Monastir","La Marsa"] }],
    ['Europe/Dublin', { 'generalName': 'Ireland Time', 'cities': ["Dublin","Cork","Limerick","Galway","Waterford","Drogheda","Dundalk","Swords","Bray","Navan"] }],
    ['Africa/Casablanca', { 'generalName': 'Morocco Time', 'cities': ["Casablanca","Rabat","Fes","Marrakech","Tangier","Agadir","Meknes","Oujda","Kenitra","Tetouan"] }],
    ['Europe/London', { 'generalName': 'United Kingdom Time', 'cities': ["London","Birmingham","Manchester","Liverpool","Leeds","Sheffield","Bristol","Leicester","Coventry","Kingston upon Hull"] }],
    ['Africa/Lagos', { 'generalName': 'West Africa Standard Time - Lagos', 'cities': ["Lagos","Kano","Ibadan","Kaduna","Port Harcourt","Benin City","Maiduguri","Zaria","Aba","Jos"] }],
    ['Africa/Ndjamena', { 'generalName': 'West Africa Standard Time - Ndjamena', 'cities': ["Ndjamena","Moundou","Sarh","Abeche","Kelo","Koumra","Pala","Am Timan","Bongor","Mongo"] }],
    ['Atlantic/Canary', { 'generalName': 'Western European Time - Canary', 'cities': ["Las Palmas de Gran Canaria", "Santa Cruz de Tenerife", "San Cristóbal de La Laguna", "Arrecife", "Puerto del Rosario"] }],
    ['Atlantic/Faroe', { 'generalName': 'Western European Time - Faroe', 'cities': ["Tórshavn", "Klaksvík", "Runavík", "Vágur", "Tvøroyri"] }],
    ['Europe/Lisbon', { 'generalName': 'Western European Time - Lisbon', 'cities': ["Lisbon","Porto","Amadora","Braga","Setúbal","Coimbra","Queluz","Funchal","Cacém","Vila Nova de Gaia"] }],
    ['Atlantic/Madeira', { 'generalName': 'Western European Time - Madeira', 'cities': ["Funchal", "Câmara de Lobos", "Machico", "Ribeira Brava", "Ponta do Sol"] }],
    ['Africa/El_Aaiun', { 'generalName': 'Western Sahara Time', 'cities': ["El Aaiún","Smara","Boujdour","Dakhla","Laâyoune Plage","Tarfaya","Laayoune-Boujdour-Sakia El Hamra","Auserd","Tah","Agouinit"] }],
    ['Africa/Juba', { 'generalName': 'Central Africa Time - Juba', 'cities': ["Juba"] }],
    ['Africa/Khartoum', { 'generalName': 'Central Africa Time - Khartoum', 'cities': ["Khartoum"] }],
    ['Africa/Maputo', { 'generalName': 'Central Africa Time - Maputo', 'cities': ["Maputo","Matola","Beira","Nampula","Chimoio","Nacala","Quelimane","Tete","Xai-Xai","Maxixe","Inhambane","Lichinga","Pemba","Cuamba","Montepuez","Dondo","Chokwé","Manjacaze","Macia","Mocuba"] }],
    ['Africa/Windhoek', { 'generalName': 'Central Africa Time - Windhoek', 'cities': ["Windhoek","Rundu","Walvis Bay","Oshakati","Swakopmund","Katima Mulilo","Grootfontein","Rehoboth","Otjiwarongo","Okahandja","Gobabis","Keetmanshoop","Mariental","Outjo","Usakos","Karibib","Opuwo","Eenhana","Omaruru","Omuthiya"] }],
    ['Europe/Amsterdam', { 'generalName': 'Central European Time - Amsterdam', 'cities': ["Amsterdam","Rotterdam","The Hague","Utrecht","Eindhoven","Tilburg","Groningen","Almere Stad","Breda","Nijmegen"] }],
    ['Europe/Andorra', { 'generalName': 'Central European Time - Andorra', 'cities': ["Andorra la Vella","Escaldes-Engordany","Encamp","Sant Julià de Lòria","La Massana","Canillo","Ordino"] }],
    ['Europe/Belgrade', { 'generalName': 'Central European Time - Belgrade', 'cities': ["Belgrade","Novi Sad","Niš","Kragujevac","Subotica","Zrenjanin","Pančevo","Čačak","Kruševac","Smederevo"] }],
    ['Europe/Berlin', { 'generalName': 'Central European Time - Berlin', 'cities': ["Berlin","Hamburg","Munich","Cologne","Frankfurt am Main","Stuttgart","Düsseldorf","Dortmund","Essen","Leipzig"] }],
    ['Europe/Brussels', { 'generalName': 'Central European Time - Brussels', 'cities': ["Brussels","Antwerp","Ghent","Charleroi","Liège","Bruges","Namur","Leuven,"] }],
    ['Europe/Budapest', { 'generalName': 'Central European Time - Budapest', 'cities': ["Budapest","Debrecen","Szeged","Miskolc","Pécs","Győr","Nyíregyháza","Kecskemét","Székesfehérvár","Szombathely"] }],
    ['Africa/Ceuta', { 'generalName': 'Central European Time - Ceuta', 'cities': ["Ceuta"] }],
    ['Europe/Copenhagen', { 'generalName': 'Central European Time - Copenhagen', 'cities': ["Copenhagen","Aarhus","Odense","Aalborg","Frederiksberg","Esbjerg","Randers","Kolding","Vejle","Horsens"] }],
    ['Europe/Gibraltar', { 'generalName': 'Central European Time - Gibraltar', 'cities': ["Gibraltar"] }],
    ['Europe/Luxembourg', { 'generalName': 'Central European Time - Luxembourg', 'cities': ["Luxembourg City","Esch-sur-Alzette","Dudelange","Schifflange","Bettembourg","Pétange","Ettelbruck","Diekirch","Strassen","Bertrange"] }],
    ['Europe/Madrid', { 'generalName': 'Central European Time - Madrid', 'cities': ["Madrid","Barcelona","Valencia","Seville","Zaragoza","Málaga","Murcia","Palma","Las Palmas de Gran Canaria","Bilbao"] }],
    ['Europe/Malta', { 'generalName': 'Central European Time - Malta', 'cities': ["Valletta","Birkirkara","Mosta","Qormi","Zabbar","Sliema","Żejtun","San Ġwann","Fgura","Rabat"] }],
    ['Europe/Monaco', { 'generalName': 'Central European Time - Monaco', 'cities': ["Monaco","Monte-Carlo","La Condamine","Fontvieille","Moneghetti","Saint-Roman","Jardin Exotique","Les Révoires","La Colle","Les Moneghetti"] }],
    ['Europe/Oslo', { 'generalName': 'Central European Time - Oslo', 'cities': ["Oslo","Bergen","Trondheim","Drammen","Fredrikstad","Porsgrunn","Sarpsborg","Skien","Ålesund","Sandefjord"] }],
    ['Europe/Paris', { 'generalName': 'Central European Time - Paris', 'cities': ["Paris","Marseille","Lyon","Toulouse","Nice","Nantes","Strasbourg","Montpellier","Bordeaux","Lille"] }],
    ['Europe/Prague', { 'generalName': 'Central European Time - Prague', 'cities': ["Prague","Brno","Ostrava","Pilsen","Olomouc","Liberec","České Budějovice","Hradec Králové","Ústí nad Labem","Pardubice"] }],
    ['Europe/Rome', { 'generalName': 'Central European Time - Rome', 'cities': ["Rome","Milan","Naples","Turin","Palermo","Genoa","Bologna","Florence","Bari","Catania"] }],
    ['Europe/Stockholm', { 'generalName': 'Central European Time - Stockholm', 'cities': ["Stockholm","Gothenburg","Malmö","Uppsala","Sollentuna","Västerås","Örebro","Linköping","Helsingborg","Jönköping"] }],
    ['Europe/Tirane', { 'generalName': 'Central European Time - Tirane', 'cities': ["Tirana","Durrës","Vlorë","Elbasan","Shkodër","Fier","Korçë","Berat","Lushnjë","Kavajë"] }],
    ['Europe/Vienna', { 'generalName': 'Central European Time - Vienna', 'cities': ["Vienna","Graz","Linz","Salzburg","Innsbruck","Klagenfurt","Villach","Wels","Sankt Pölten","Dornbirn"] }],
    ['Europe/Warsaw', { 'generalName': 'Central European Time - Warsaw', 'cities': ["Warsaw","Kraków","Łódź","Wrocław","Poznań","Gdańsk","Szczecin","Bydgoszcz","Lublin","Katowice"] }],
    ['Europe/Zurich', { 'generalName': 'Central European Time - Zurich', 'cities': ["Zurich","Geneva","Basel","Lausanne"] }],
    ['Europe/Kaliningrad', { 'generalName': 'Eastern European Standard Time - Kaliningrad', 'cities': ["Kaliningrad","Vilnius","Gdansk","Szczecin","Klaipeda","Olsztyn","Elblag","Gdynia","Sopot","Kalisz","Grudziadz","Torun","Koszalin","Gostynin","Slupsk","Leszno","Tczew","Starogard Gdanski","Swinoujscie","Walcz"] }],
    ['Africa/Tripoli', { 'generalName': 'Eastern European Standard Time - Tripoli', 'cities': ["Beirut","Tripoli","Sidon","Tyre","Nabatiye et Tahta","Zahle","Jounieh","Batroun","Aley","Byblos","Bcharre","Ras Baalbek","Anjar","Baalbek","Jbail","Ar Ramlah","Baabda","Ghazir","Chtaura","Rmeilan"] }],
    ['Africa/Johannesburg', { 'generalName': 'South Africa Standard Time', 'cities': ["Johannesburg","Soweto","Pretoria","Durban","Cape Town","Pietermaritzburg","Benoni","Tembisa","East London","Vereeniging","Bloemfontein","Newcastle","Krugersdorp","Botshabelo","Brakpan","Witbank","Richards Bay","Vanderbijlpark","Boksburg","Welkom"] }],
    ['Antarctica/Troll', { 'generalName': 'Troll Time', 'cities': ["Troll"] }],
    ['Asia/Baghdad', { 'generalName': 'Arabian Standard Time - Baghdad', 'cities': ["Baghdad","Mosul","Basrah","Arbil","Sulaymaniyah","Najaf","Karbala","Kirkuk","Ramadi","Tikrit","Samarra","Fallujah","Diwaniyah","Balad","Haditha","Al Hillah","Al Kufah","Al Kut","Al Qaim","Kifri"] }],
    ['Asia/Qatar', { 'generalName': 'Arabian Standard Time - Qatar', 'cities': ["Doha", "Al Rayyan", "Al Wakrah", "Al Khor", "Madinat ash Shamal"] }],
    ['Asia/Riyadh', { 'generalName': 'Arabian Standard Time - Riyadh', 'cities': ["Riyadh","Jeddah","Mecca","Medina","Sulaimaniyah","Dammam","Taif","Tabuk","Khamis Mushait","Buraidah","Al Qatif","Abha","Al Jubayl","Najran","Al Khobar","Yanbu al Bahr","Hafar Al-Batin","Ha'il","Sakaka","Arar"] }],
    ['Africa/Nairobi', { 'generalName': 'East Africa Time', 'cities': ["Nairobi","Mombasa","Kisumu","Nakuru","Eldoret","Nyeri","Machakos","Thika","Meru","Kakamega","Kitale","Malindi","Lamu","Garissa","Isiolo","Lodwar","Kapenguria","Homa Bay","Busia","Wajir"] }],
    ['Europe/Athens', { 'generalName': 'Eastern European Time - Athens', 'cities': ["Athens","Thessaloniki","Patras","Piraeus","Larissa","Heraklion","Peristeri","Kallithea","Acharnes","Kalamaria","Nikaia","Glyfada","Volos","Ilioúpoli","Keratsini","Nea Smyrni","Marousi","Agios Dimitrios","Zografos","Egaleo"] }],
    ['Asia/Beirut', { 'generalName': 'Eastern European Time - Beirut', 'cities': ["Beirut","Tripoli","Sidon","Tyre","Nabatiye et Tahta","Zahle","Jounieh","Batroun","Aley","Byblos","Bcharre","Ras Baalbek","Anjar","Baalbek","Jbail","Ar Ramlah","Baabda","Ghazir","Chtaura","Rmeilan"] }],
    ['Europe/Bucharest', { 'generalName': 'Eastern European Time - Bucharest', 'cities': ["Bucharest","Cluj-Napoca","Timisoara","Iasi","Constanta","Craiova","Galati","Brasov","Ploiesti","Braila","Oradea","Bacau","Arad","Pitesti","Sibiu","Targu Mures","Baia Mare","Buzau","Satu Mare","Drobeta-Turnu Severin"] }],
    ['Africa/Cairo', { 'generalName': 'Eastern European Time - Cairo', 'cities': ["Cairo","Alexandria","Giza","Shubra El-Kheima","Port Said","Suez","Luxor","Mansoura","Tanta","Asyut","Fayyum","Zagazig","Ismailia","Damietta","Minya","Beni Suef","Qena","Sohag","Hurghada"] }],
    ['Europe/Chisinau', { 'generalName': 'Eastern European Time - Chisinau', 'cities': ["Chisinau","Tiraspol","Balti","Bender","Ribnita","Cahul","Ungheni","Soroca","Orhei","Dubasari","Comrat","Edinet","Causeni","Straseni","Drochia","Riscani","Hincesti","Nisporeni","Floresti","Briceni"] }],
    ['Asia/Gaza', { 'generalName': 'Eastern European Time - Gaza', 'cities': ["Gaza","Khan Yunis","Rafah","Jabalia","Deir al-Balah","Beit Lahia","Al-Bireh","Beit Hanoun","Qalqilya","Tulkarm","Nablus","Jericho","Ramallah","Hebron","Jenin","Bethlehem","Yatta","Bayt Jala","Halhul","Dura"] }],
    ['Asia/Hebron', { 'generalName': 'Eastern European Time - Hebron', 'cities': ["Hebron"] }],
    ['Europe/Helsinki', { 'generalName': 'Eastern European Time - Helsinki', 'cities': ["Helsinki","Espoo","Tampere","Vantaa","Turku","Oulu","Lahti","Kuopio","Jyvaskyla","Pori","Lappeenranta","Vaasa","Kotka","Joensuu","Hameenlinna","Porvoo","Mikkeli","Hyvinkaa","Nokia","Kajaani"] }],
    ['Europe/Kiev', { 'generalName': 'Eastern European Time - Kyiv', 'cities': ["Kiev","Kharkiv","Dnipro","Odessa","Donetsk","Zaporizhzhya","Lviv","Kryvyi Rih","Mykolaiv","Mariupol","Luhansk","Makiivka","Vinnytsia","Simferopol","Kherson","Poltava","Chernihiv","Cherkasy","Sumy","Rivne"] }],
    ['Asia/Nicosia', { 'generalName': 'Eastern European Time - Nicosia', 'cities': ["Nicosia","Limassol","Larnaca","Paphos","Famagusta","Kyrenia","Protaras","Paralimni","Ayia Napa","Latchi","Polis","Xylofagou","Dherynia","Sotira","Tala","Chloraka","Kapparis","Kiti","Perivolia","Peyia"] }],
    ['Europe/Riga', { 'generalName': 'Eastern European Time - Riga', 'cities': ["Riga","Daugavpils","Liepaja","Jelgava","Jurmala","Ventspils","Rezekne","Valmiera","Ogre","Cesis","Tukums","Salaspils","Kuldiga","Sigulda","Aizkraukle","Balvi","Dobele","Gulbene","Ludza","Saldus"] }],
    ['Europe/Sofia', { 'generalName': 'Eastern European Time - Sofia', 'cities': ["Sofia","Plovdiv","Varna","Burgas","Rousse","Stara Zagora","Pleven","Sliven","Dobrich","Shumen","Pernik","Yambol","Haskovo","Blagoevgrad","Veliko Tarnovo","Gabrovo","Asenovgrad","Kazanlak","Vidin","Kyustendil"] }],
    ['Europe/Tallinn', { 'generalName': 'Eastern European Time - Tallinn', 'cities': ["Tallinn","Tartu","Narva","Parnu","Kohtla-Jarve","Viljandi","Rakvere","Sillamae","Maardu","Kuressaare","Valga","Haapsalu","Võru","Jõhvi","Paide","Keila","Kiviõli","Tapa","Polva","Jogeva"] }],
    ['Europe/Uzhgorod', { 'generalName': 'Eastern European Time - Uzhhorod', 'cities': ["Uzhgorod", "Mukachevo", "Khust", "Berehove", "Vynohradiv"] }],
    ['Europe/Vilnius', { 'generalName': 'Eastern European Time - Vilnius', 'cities': ["Vilnius","Kaunas","Klaipeda","Šiauliai","Panevezys","Alytus","Marijampole","Mazeikiai","Jonava","Utena","Kedainiai","Taurage","Ukmerge","Visaginas","Kretinga","Silute","Plunge","Radviliskis","Palanga","Gargzdai"] }],
    ['Europe/Zaporozhye', { 'generalName': 'Eastern European Time - Zaporozhye', 'cities': ["Zaporizhzhya","Melitopol","Berdyansk","Enerhodar","Tokmak","Prym"] }],
    ['Asia/Famagusta', { 'generalName': 'Famagusta Time', 'cities': ["Famagusta"] }],
    ['Asia/Jerusalem', { 'generalName': 'Israel Time', 'cities': ["Jerusalem","Tel Aviv","Haifa","Ashdod","Rishon LeZion","Petah Tikva","Beersheba","Netanya","Holon","Bnei Brak"] }],
    ['Asia/Amman', { 'generalName': 'Jordan Time', 'cities': ["Amman","Zarqa","Irbid","Russeifa","Wadi as-Ser","Ajloun","Aqaba","Madaba","Jerash","Karak City","Mafraq","Tafilah","Ma'an","Safi","Anjarah","Al-Karak","Al-Mazraa","Sahab","Qir Moav","Al-Quwaysimah"] }],
    ['Europe/Kirov', { 'generalName': 'Kirov Time', 'cities': ["Kirov", "Kotelnich", "Slobodskoy", "Kirs", "Yaransk"] }],
    ['Europe/Minsk', { 'generalName': 'Moscow Standard Time - Minsk', 'cities': ["Minsk","Gomel","Mogilev","Vitebsk","Hrodna","Brest","Babruysk","Baranovichi","Pinsk","Orsha"] }],
    ['Europe/Moscow', { 'generalName': 'Moscow Standard Time - Moscow', 'cities': ["Moscow", "Saint Petersburg", "Kazan", "Nizhny Novgorod", "Samara", "Omsk", "Yekaterinburg", "Novosibirsk", "Ufa", "Rostov-on-Don"] }],
    ['Europe/Simferopol', { 'generalName': 'Moscow Standard Time - Simferopol', 'cities': ["Simferopol"] }],
    ['Asia/Damascus', { 'generalName': 'Syria Time', 'cities': ["Damascus","Aleppo","Homs","Latakia","Hama","Deir ez-Zor","Raqqa","Tartus","As-Suwayda","Darayya"] }],
    ['Europe/Istanbul', { 'generalName': 'Türkiye Time', 'cities': ["Istanbul","Ankara","Izmir","Bursa","Adana","Gaziantep","Konya","Antalya","Diyarbakir","Kayseri"] }],
    ['Europe/Volgograd', { 'generalName': 'Volgograd Standard Time', 'cities': ["Volgograd", "Astrakhan", "Saratov", "Ulyanovsk", "Penza"] }],
    ['Asia/Tehran', { 'generalName': 'Iran Standard Time', 'cities': ["Tehran", "Mashhad", "Isfahan", "Karaj", "Tabriz", "Shiraz", "Qom", "Ahvaz"] }],
    ['Asia/Yerevan', { 'generalName': 'Armenia Standard Time', 'cities': ["Yerevan","Gyumri","Vanadzor","Ejmiatsin","Hrazdan","Abovyan","Armavir","Artashat","Goris","Ashtarak","Kapan","Sevan"] }],
    ['Europe/Astrakhan', { 'generalName': 'Astrakhan Time', 'cities': ["Astrakhan", "Akhtubinsk", "Znamensk", "Kamyzyak", "Narimanov"] }],
    ['Asia/Baku', { 'generalName': 'Azerbaijan Standard Time', 'cities': ["Baku","Sumqayit","Ganja","Mingachevir","Lankaran","Qaraçuxur","Şirvan","Nakhchivan","Bakıxanov","Sabirabad","Imishli","Saatlı","Hacıqabul","Qusar","Biləsuvar","Yevlax","Barda","Zaqatala","Göyçay"] }],
    ['Asia/Tbilisi', { 'generalName': 'Georgia Standard Time', 'cities': ["Tbilisi","Kutaisi","Batumi","Rustavi","Zugdidi","Poti","Gori","Samtredia","Khashuri","Senaki","Ozurgeti","Marneuli","Telavi","Akhaltsikhe","Tsqaltubo","Kobuleti","Lentekhi","Dmanisi","Tkibuli"] }],
    ['Asia/Dubai', { 'generalName': 'Gulf Standard Time', 'cities': ["Dubai","Abu Dhabi","Sharjah","Al Ain","Ajman","Ras al-Khaimah","Umm al-Quwain","Fujairah","Khor Fakkan","Kalba","Jebel Ali","Dibba Al-Hisn","Hatta","Dhaid","Madinat Zayed","Ruwais","Liwa Oasis","Al Hamriyah","Al Khawaneej","Ghayathi"] }],
    ['Indian/Mauritius', { 'generalName': 'Mauritius Standard Time', 'cities': ["Port Louis","Beau Bassin-Rose Hill","Vacoas-Phoenix","Curepipe","Quatre Bornes","Triolet","Goodlands","Centre de Flacq","Bel Air Rivière Sèche","Mahebourg","Saint Pierre"] }],
    ['Indian/Reunion', { 'generalName': 'Réunion Time', 'cities': ["Saint-Denis","Saint-Paul","Saint-Pierre","Le Tampon","Saint-André","Saint-Louis","Saint-Joseph","Le Port","Saint-Benoît","Sainte-Marie","La Possession","Bras-Panon","Petite-Île","Les Avirons","Trois-Bassins","Entre-Deux","Cilaos","Salazie","Sainte-Suzanne","La Plaine-des-Palmistes"] }],
    ['Europe/Samara', { 'generalName': 'Samara Standard Time', 'cities': ["Samara","Tolyatti","Syzran","Novokuibyshevsk","Zhigulevsk","Kinel","Oktyabrsk","Chapaevsk","Kuybyshev","Bezenchuk","Krasnoyarskoye","Volsk","Pokhvistnevo","Shigony","Pohvistnevo","Kinel-Cherkassy","Alekseyevka","Krasnoarmeysk"] }],
    ['Europe/Saratov', { 'generalName': 'Saratov Time', 'cities': ["Saratov", "Engels", "Balakovo", "Balashov", "Volsk"] }],
    ['Indian/Mahe', { 'generalName': 'Seychelles Time', 'cities': ["Victoria","Anse Boileau","Bel Ombre","Beau Vallon","Cascade","La Digue","Grand Anse","Takamaka","Anse Royale","Anse aux Pins","Port Glaud"] }],
    ['Europe/Ulyanovsk', { 'generalName': 'Ulyanovsk Time', 'cities': ["Ulyanovsk", "Dimitrovgrad", "Barysh", "Sengiley", "Novoulyanovsk"] }],
    ['Asia/Kabul', { 'generalName': 'Afghanistan Time', 'cities': ["Kabul"] }],
    ['Indian/Kerguelen', { 'generalName': 'French Southern & Antarctic Time', 'cities': ["Port-aux-Français"] }],
    ['Indian/Maldives', { 'generalName': 'Maldives Time', 'cities': ["Male"] }],
    ['Antarctica/Mawson', { 'generalName': 'Mawson Time', 'cities': ["Mawson Station"] }],
    ['Asia/Karachi', { 'generalName': 'Pakistan Standard Time', 'cities': ["Lahore","Karachi","Faisalabad","Wah Cantt", "Rawalpindi","Multan","Gujranwala","Peshawar","Quetta","Bahawalpur","Sargodha","Sialkot","Sukkur","Larkana","Sheikhupura","Jhang","Rahim Yar Khan","Dera Ghazi Khan","Mardan","Mirpur Khas"] }],
    ['Asia/Dushanbe', { 'generalName': 'Tajikistan Time', 'cities': ["Dushanbe"] }],
    ['Asia/Ashgabat', { 'generalName': 'Turkmenistan Standard Time', 'cities': ["Ashgabat"] }],
    ['Asia/Samarkand', { 'generalName': 'Uzbekistan Standard Time - Samarkand', 'cities': ["Samarkand","Bukhara","Nukus","Urgench","Karshi"] }],
    ['Asia/Tashkent', { 'generalName': 'Uzbekistan Standard Time - Tashkent', 'cities': ["Tashkent","Andijan","Namangan","Samarkand","Bukhara","Nukus","Urgench","Chirchiq","Fergana","Jizzax","Qarshi","Navoiy","Olmaliq","Margilan","Angren","Bekobod","Chust","Yangiyul"] }],
    ['Asia/Aqtau', { 'generalName': 'West Kazakhstan Time - Aqtau', 'cities': ["Aqtau"] }],
    ['Asia/Aqtobe', { 'generalName': 'West Kazakhstan Time - Aqtobe', 'cities': ["Aqtobe"] }],
    ['Asia/Atyrau', { 'generalName': 'West Kazakhstan Time - Atyrau', 'cities': ["Atyrau"] }],
    ['Asia/Oral', { 'generalName': 'West Kazakhstan Time - Oral', 'cities': ["Oral"] }],
    ['Asia/Qyzylorda', { 'generalName': 'West Kazakhstan Time - Qyzylorda', 'cities': ["Qyzylorda","Baikonur","Aral","Kazaly"] }],
    ['Asia/Yekaterinburg', { 'generalName': 'Yekaterinburg Standard Time', 'cities': ["Yekaterinburg","Chelyabinsk","Ufa","Perm","Orenburg","Magnitogorsk","Izhevsk","Kurgan","Tyumen","Surgut","Tobolsk","Salair","Serov","Nizhny Tagil","Zlatoust","Kamensk-Uralsky","Polevskoy","Novouralsk","Verkhnyaya Pyshma","Alapayevsk"] }],
    ['Asia/Colombo', { 'generalName': 'India Standard Time - Colombo', 'cities': ["Colombo","Dehiwala-Mount Lavinia","Moratuwa","Jaffna","Negombo","Pita Kotte","Sri Jayawardenepura Kotte","Kandy","Trincomalee","Kalmunai","Galle","Point Pedro","Batticaloa","Katunayake","Valvedditturai","Matara","Ratnapura","Kurunegala","Gampola"] }],
    ['Asia/Kolkata', { 'generalName': 'India Standard Time - Kolkata', 'cities': ["Kolkata","Mumbai","Delhi","Bengaluru","Chennai","Hyderabad","Ahmedabad","Pune","Surat","Jaipur","Lucknow","Kanpur","Nagpur","Visakhapatnam","Bhopal","Patna","Ludhiana","Agra","Nashik"] }],
    ['Asia/Kathmandu', { 'generalName': 'Nepal Time', 'cities': ["Kathmandu","Pokhara","Lalitpur","Birgunj","Bharatpur","Biratnagar","Janakpur","Hetauda","Bhimdatta","Gulariya","Damak","Siddharthanagar","Butwal","Kirtipur","Tansen","Rajbiraj","Lahan","Bhadrapur","Dhankuta"] }],
    ['Asia/Dhaka', { 'generalName': 'Bangladesh Standard Time', 'cities': ["Dhaka","Chittagong","Narayanganj","Khulna","Rajshahi","Comilla","Sylhet","Rangpur","Tongi","Jessore","Cox's Bazar","Mymensingh","Bogra","Barisal","Narsingdi","Saidpur","Gazipur","Narail","Pabna","Kushtia"] }],
    ['Asia/Thimphu', { 'generalName': 'Bhutan Time', 'cities': ["Thimphu","Phuntsholing","Punakha","Samdrup Jongkhar","Geylegphug","Paro","Trongsa","Jakar","Ha","Mongar","Trashigang","Tsirang","Damphu","Gasa","Samtse","Bajo","Dagana","Lhuentse","Zhemgang","Panbang"] }],
    ['Asia/Almaty', { 'generalName': 'East Kazakhstan Time - Almaty', 'cities': ["Almaty","Karagandy","Shymkent","Taraz","Nur-Sultan","Ust-Kamenogorsk","Pavlodar","Kyzylorda","Semey","Aktobe","Temirtau","Oral","Kokshetau","Taldykorgan","Ekibastuz","Rudny","Zhanaozen","Balqash","Kentau"] }],
    ['Asia/Qostanay', { 'generalName': 'East Kazakhstan Time - Kostanay', 'cities': ["Qostanay", "Rudny", "Lisakovsk", "Arkalyk", "Komsomolets"] }],
    ['Indian/Chagos', { 'generalName': 'Indian Ocean Time', 'cities': ["Chagos"] }],
    ['Asia/Bishkek', { 'generalName': 'Kyrgyzstan Time', 'cities': ["Bishkek","Osh","Jalal-Abad","Karakol","Tokmok","Uzgen","Balykchy","Kara-Balta","Naryn","Talas","Kyzyl-Suu","Batken","At-Bashi","Toktogul","Isfana","Kant","Kara-Suu","Suluktu","Cholpon-Ata","Tash-Kumyr"] }],
    ['Asia/Omsk', { 'generalName': 'Omsk Standard Time', 'cities': ["Omsk","Novosibirsk","Barnaul","Tomsk","Biysk","Kemerovo","Rubtsovsk","Novokuznetsk","Prokop'yevsk","Berdsk","Anzhero-Sudzhensk","Iskitim","Gorno-Altaysk","Yurga","Kansk","Mezhdurechensk"] }],
    ['Asia/Urumqi', { 'generalName': 'Urumqi Time', 'cities': ["Urumqi", "Kashgar", "Aksu", "Korla", "Hotan"] }],
    ['Antarctica/Vostok', { 'generalName': 'Vostok Time', 'cities': ["Vostok"] }],
    ['Indian/Cocos', { 'generalName': 'Cocos Islands Time', 'cities': ["West Island"] }],
    ['Asia/Yangon', { 'generalName': 'Myanmar Time', 'cities': ["Yangon","Mandalay","Naypyidaw","Mawlamyine","Bago","Pathein","Monywa","Akyab","Meiktila","Myingyan","Taunggyi","Pyay","Letpandan","Sittwe","Henzada","Magway","Pakokku","Chauk","Myeik"] }],
    ['Asia/Barnaul', { 'generalName': 'Barnaul Time', 'cities': ["Barnaul"] }],
    ['Indian/Christmas', { 'generalName': 'Christmas Island Time', 'cities': ["The Settlement"] }],
    ['Antarctica/Davis', { 'generalName': 'Davis Time', 'cities': ["Davis"] }],
    ['Asia/Hovd', { 'generalName': 'Hovd Standard Time', 'cities': ["Hovd"] }],
    ['Asia/Bangkok', { 'generalName': 'Indochina Time - Bangkok', 'cities': ["Bangkok","Nonthaburi","Pak Kret","Hat Yai","Si Racha","Phra Pradaeng","Rayong","Chon Buri","Nakhon Ratchasima","Udon Thani","Chiang Mai","Surat Thani","Khon Kaen","Ubon Ratchathani","Nakhon Si Thammarat","Nakhon Pathom","Chanthaburi","Phuket","Songkhla","Samut Prakan"] }],
    ['Asia/Ho_Chi_Minh', { 'generalName': 'Indochina Time - Ho Chi Minh City', 'cities': ["Ho Chi Minh City","Bien Hoa","Vung Tau","Tân An","Thủ Dầu Một","Cái Bè","Bình Long","Sa Đéc","Long Khánh","Cao Lãnh","Phan Thiết","Mỹ Tho","Đồng Xoài","Thành phố Hạ Long","Buôn Ma Thuột","Quy Nhơn","Tuy Hòa","Rạch Giá","Thanh Hóa"] }],
    ['Asia/Krasnoyarsk', { 'generalName': 'Krasnoyarsk Standard Time - Krasnoyarsk', 'cities': ["Krasnoyarsk","Achinsk","Kansk","Minusinsk","Abakan","Zheleznogorsk","Zelenogorsk","Lesosibirsk","Sharypovo","Kyzyl","Yeniseysk","Bogotol","Norilsk","Kodinsk","Uyar","Kuytun","Sayanogorsk","Dudinka","Berezovka"] }],
    ['Asia/Novokuznetsk', { 'generalName': 'Krasnoyarsk Standard Time - Novokuznetsk', 'cities': ["Novokuznetsk","Kemerovo","Prokopyevsk","Yurga","Anzhero-Sudzhensk","Mezhdurechensk","Leninsk-Kuznetsky","Kiselyovsk","Mariinsk","Beryozovsky","Tashtagol","Osinniki","Shira","Polysayevo,"] }],
    ['Asia/Novosibirsk', { 'generalName': 'Novosibirsk Standard Time', 'cities': ["Novosibirsk","Barnaul","Berdsk","Iskitim","Akademgorodok","Ob","Barabinsk","Biysk","Toguchin","Kupino","Cherepanovo","Suzun","Kolyvan","Krasnoobsk","Linevo","Moshkovo","Bolotnoye","Chistyye Prudy","Karasuk"] }],
    ['Asia/Tomsk', { 'generalName': 'Tomsk Time', 'cities': ["Tomsk","Seversk","Strezhevoy","Asino","Kolpashevo","Kargasok","Kedrovy","Parabel","Pervomayskoye","Podgornoye","Krivosheino","Bakchar","Molchanovo","Bogashevo","Zonalnoye","Verkh-Tomskiy","Beryozovka","Chistyye Klyuchi","Dzerzhinskiy","Dubinino"] }],
    ['Asia/Jakarta', { 'generalName': 'Western Indonesia Time - Jakarta', 'cities': ["Jakarta","Bekasi","Tangerang","Depok","Bogor","South Tangerang","Cimahi","Cileungsi","Cikarang","Purwakarta","Karawang","Bandung","Sukabumi","Serang","Cibinong","Banjar","Cikupa","Cirebon","Probolinggo","Pemalang"] }],
    ['Asia/Pontianak', { 'generalName': 'Western Indonesia Time - Pontianak', 'cities': ["Pontianak", "Singkawang", "Sintang", "Ketapang", "Putussibau"] }],
    ['Australia/Perth', { 'generalName': 'Australian Western Standard Time', 'cities': ["Perth"] }],
    ['Asia/Brunei', { 'generalName': 'Brunei Darussalam Time', 'cities': ["Bandar Seri Begawan"] }],
    ['Asia/Makassar', { 'generalName': 'Central Indonesia Time', 'cities': ["Makassar"] }],
    ['Asia/Macau', { 'generalName': 'China Standard Time - Macao', 'cities': ["Macau"] }],
    ['Asia/Shanghai', { 'generalName': 'China Standard Time - Shanghai', 'cities': ["Shanghai","Beijing","Tianjin","Chengdu","Xi'an","Shenzhen","Wuhan","Chongqing","Nanjing","Hangzhou","Dongguan","Shenyang","Harbin","Changchun","Zhengzhou","Suzhou","Jinan","Ningbo","Foshan","Wuxi"] }],
    ['Asia/Hong_Kong', { 'generalName': 'Hong Kong Standard Time', 'cities': ["Hong Kong"] }],
    ['Asia/Irkutsk', { 'generalName': 'Irkutsk Standard Time', 'cities': ["Irkutsk"] }],
    ['Asia/Kuala_Lumpur', { 'generalName': 'Malaysia Time - Kuala Lumpur', 'cities': ["Kuala Lumpur","Johor Bahru","Ipoh","George Town","Kota Kinabalu","Kuching","Petaling Jaya","Shah Alam","Melaka","Kuala Terengganu","Alor Setar","Seremban","Miri","Sungai Petani","Kulim","Kluang","Sandakan","Taiping","Tawau","Bintulu"] }],
    ['Asia/Kuching', { 'generalName': 'Malaysia Time - Kuching', 'cities': ["Kuching", "Miri", "Sibu", "Bintulu", "Sri Aman"] }],
    ['Asia/Manila', { 'generalName': 'Philippine Standard Time', 'cities': ["Manila","Quezon City","Caloocan","Davao City","Cebu City","Antipolo","Zamboanga City","Bacoor","Lapu-Lapu City","Imus","General Santos City","Valenzuela","Las Piñas","Makati City","San Jose del Monte","Bacolod City","Muntinlupa","Parañaque","Marikina","Pasig"] }],
    ['Asia/Singapore', { 'generalName': 'Singapore Standard Time', 'cities': ["Singapore"] }],
    ['Asia/Taipei', { 'generalName': 'Taipei Standard Time', 'cities': ["Taipei"] }],
    ['Asia/Choibalsan', { 'generalName': 'Ulaanbaatar Standard Time - Choibalsan', 'cities': ["Choibalsan"] }],
    ['Asia/Ulaanbaatar', { 'generalName': 'Ulaanbaatar Standard Time - Ulaanbaatar', 'cities': ["Ulaanbaatar"] }],
    ['Australia/Eucla', { 'generalName': 'Australian Central Western Standard Time', 'cities': ["Eucla"] }],
    ['Asia/Dili', { 'generalName': 'East Timor Time', 'cities': ["Dili","Baucau","Liquica","Maliana","Aileu","Gleno","Same","Suai","Maubara","Bobonaro","Viqueque","Lospalos","Tutuala","Balibo","Atabae","Baguia","Ainaro","Betano","Manatuto","Baucau"] }],
    ['Asia/Jayapura', { 'generalName': 'Eastern Indonesia Time', 'cities': ["Jayapura","Abepura","Sentani"] }],
    ['Asia/Tokyo', { 'generalName': 'Japan Standard Time', 'cities': ["Tokyo","Yokohama","Osaka","Nagoya","Sapporo","Kobe","Kyoto","Fukuoka","Kawasaki","Hiroshima","Kitakyushu","Sendai","Chiba","Sakai","Niigata","Hamamatsu","Kumamoto","Sagamihara","Okayama","Shizuoka"] }],
    ['Asia/Pyongyang', { 'generalName': 'Korean Standard Time - Pyongyang', 'cities': ["Pyongyang","Hamhung","Chongjin","Nampo","Sariwon","Sinuiju","Kaesong","Haeju","Wonsan","Kanggye","Pyongsong","Kimchaek","Songnim","Anju","Hoeryong","Manpo","Hyesan","Kangdong","Rajin","Kusong"] }],
    ['Asia/Seoul', { 'generalName': 'Korean Standard Time - Seoul', 'cities': ["Seoul","Busan","Incheon","Daegu","Daejeon","Gwangju","Ulsan","Suwon-si","Changwon","Seongnam-si","Bucheon-si","Jeonju","Ansan-si","Cheongju-si","Anyang-si","Pohang","Uijeongbu-si","Goyang-si","Gimhae-si","Tongyeong"] }],
    ['Pacific/Palau', { 'generalName': 'Palau Time', 'cities': ["Melekeok","Koror","Airai","Kloulklubed","Meyungs","Ngaraard","Angaur","Ngerulmud","Peleliu","Ngardmau","Aimeliik","Ngchesar","Ngiwal","Ngatpang","Sonsorol","Hatohobei","Kayangel","Ngarchelong","Imeong","Mecherchar"] }],
    ['Asia/Chita', { 'generalName': 'Yakutsk Standard Time - Chita', 'cities': ["Chita","Krasnoyarsk","Abakan","Kyzyl","Ulan-Ude","Irkutsk","Novosibirsk","Kemerovo","Tomsk","Omsk","Novokuznetsk","Barnaul","Biysk","Rubtsovsk","Gorno-Altaysk","Anzhero-Sudzhensk","Prokopyevsk","Leninsk-Kuznetsky","Achinsk","Novokuybyshevsk"] }],
    ['Asia/Khandyga', { 'generalName': 'Yakutsk Standard Time - Khandyga', 'cities': ["Khandyga","Yakutsk","Blagoveshchensk","Khabarovsk","Ulan-Ude","Irkutsk","Chita","Bratsk","Angarsk","Usolye-Sibirskoye","Ust-Ilimsk","Tulun","Neryungri","Severobaykalsk","Zhigalovo","Olekminsk","Bodaybo","Zeya","Lensk","Magdagachi"] }],
    ['Asia/Yakutsk', { 'generalName': 'Yakutsk Standard Time - Yakutsk', 'cities': ["Khandyga","Yakutsk","Blagoveshchensk","Khabarovsk","Ulan-Ude","Irkutsk","Chita","Bratsk","Angarsk","Usolye-Sibirskoye","Ust-Ilimsk","Tulun","Neryungri","Severobaykalsk","Zhigalovo","Olekminsk","Bodaybo","Zeya","Lensk","Magdagachi"] }],
    ['Australia/Darwin', { 'generalName': 'Australian Central Standard Time', 'cities': ["Darwin"] }],
    ['Australia/Adelaide', { 'generalName': 'Central Australia Time - Adelaide', 'cities': ["Adelaide","Alice Springs","Darwin"] }],
    ['Australia/Broken_Hill', { 'generalName': 'Central Australia Time - Broken Hill', 'cities': ["Broken Hill"] }],
    ['Australia/Brisbane', { 'generalName': 'Australian Eastern Standard Time - Brisbane', 'cities': ["Brisbane","Gold Coast","Sunshine Coast","Cairns","Townsville","Toowoomba","Mackay","Rockhampton","Bundaberg","Hervey Bay","Gladstone","Maryborough","Gympie","Mount Isa","Warwick","Stanthorpe","Charleville","Longreach","Charters Towers","Bowen"] }],
    ['Australia/Lindeman', { 'generalName': 'Australian Eastern Standard Time - Lindeman', 'cities': ["Mackay", "Airlie Beach", "Proserpine", "Bowen", "Cannonvale"] }],
    ['Pacific/Guam', { 'generalName': 'Chamorro Standard Time', 'cities': ["Dededo Village","Yigo Village","Tamuning-Tumon-Harmon Village","Mangilao Village","Hagatna","Agana Heights Village","Barrigada Village","Chalan Pago-Ordot Village","Sinajana Village","Mongmong-Toto-Maite Village","Santa Rita Village","Asan-Maina Village","Piti Village","Inarajan Village","Umatac Village","Agat Village","Merizo Village"] }],
    ['Pacific/Chuuk', { 'generalName': 'Chuuk Time', 'cities': ["Weno"] }],
    ['Australia/Hobart', { 'generalName': 'Eastern Australia Time - Hobart', 'cities': ["Hobart","Launceston","Devonport","Burnie","Kingston","Ulverstone","New Norfolk","Wynyard","George Town","Smithton","Bridgewater","Scottsdale","Somerset","Penguin","Queenstown","Westbury","Triabunna","Sheffield","Zeehan","Deloraine"] }],
    ['Antarctica/Macquarie', { 'generalName': 'Eastern Australia Time - Macquarie', 'cities': ["Macquarie"] }],
    ['Australia/Melbourne', { 'generalName': 'Eastern Australia Time - Melbourne', 'cities': ["Melbourne","Geelong","Ballarat","Bendigo","Frankston","Melton","Sunbury","Warrnambool","Wodonga","Warragul","Wangaratta","Traralgon","Horsham","Bairnsdale","Sale","Portland","Seymour","Colac","Hamilton","Castlemaine"] }],
    ['Australia/Sydney', { 'generalName': 'Eastern Australia Time - Sydney', 'cities': ["Sydney","Newcastle","Wollongong","Central Coast","Port Macquarie","Coffs Harbour","Albury","Dubbo","Orange","Bathurst","Nowra","Tamworth","Armidale","Goulburn","Broken Hill","Griffith","Parkes","Mudgee","Taree","Muswellbrook"] }],
    ['Pacific/Port_Moresby', { 'generalName': 'Papua New Guinea Time', 'cities': ["Port Moresby","Lae","Arawa","Mount Hagen","Popondetta","Madang","Kokopo","Mendi","Kimbe","Goroka","Wewak","Vanimo","Alotau","Kiunga","Bulolo","Kavieng","Lorengau","Kundiawa","Daru","Manus"] }],
    ['Asia/Ust-Nera', { 'generalName': 'Vladivostok Standard Time - Ust-Nera', 'cities': ["Ust-Nera","Vladivostok","Nakhodka","Ussuriysk","Artem","Fokino","Partizansk","Spassk-Dalniy","Dalnegorsk","Lesozavodsk","Bolshoy Kamen"] }],
    ['Asia/Vladivostok', { 'generalName': 'Vladivostok Standard Time - Vladivostok', 'cities': ["Vladivostok","Nakhodka","Ussuriysk","Artem","Fokino","Partizansk","Spassk-Dalniy","Dalnegorsk","Lesozavodsk","Bolshoy Kamen"] }],
    ['Australia/Lord_Howe', { 'generalName': 'Lord Howe Time', 'cities': ["Lord Howe Island"] }],
    ['Pacific/Bougainville', { 'generalName': 'Bougainville Time', 'cities': ["Arawa"] }],
    ['Antarctica/Casey', { 'generalName': 'Casey Time', 'cities': ["Casey"] }],
    ['Pacific/Kosrae', { 'generalName': 'Kosrae Time', 'cities': ["Kosrae"] }],
    ['Asia/Magadan', { 'generalName': 'Magadan Standard Time', 'cities': ["Magadan","Yagodnoye","Susuman","Ust-Omchug","Omsukchan","Seymchan","Omolon","Kadykchan","Palatka","Yelizovo","Gornyak","Kamenskoye","Chersky","Beringovskiy","Snezhnogorsk","Korf","Nikolayevsk-on-Amur","Zyryanka","Lavrentiya","Anadyr"] }],
    ['Pacific/Noumea', { 'generalName': 'New Caledonia Standard Time', 'cities': ["Noumea","Mont-Dore","Dumbéa","Paita","Bourail","Yaté","Païta","Bouloupari","Pouembout","Moindou","Koné","Voh","Thio","La Foa","Koumac","Farino","Hienghène","Ouégoa","Lifou","Canala"] }],
    ['Pacific/Norfolk', { 'generalName': 'Norfolk Island Time', 'cities': ["Kingston", "Burnt Pine"] }],
    ['Pacific/Pohnpei', { 'generalName': 'Ponape Time', 'cities': ["Palikir", "Kolonia", "U", "Nanpil", "Nett"] }],
    ['Asia/Sakhalin', { 'generalName': 'Sakhalin Standard Time', 'cities': ["Yuzhno-Sakhalinsk","Kholmsk","Korsakov","Nevelsk","Poronaysk","Dolinsk","Klyuchi","Krasnogorsk","Makarov","Tomari","Alexandrovsk-Sakhalinsky","Okha","Uglegorsk","Nogliki","Aniva","Kurilsk","Smirnykh","Kholmskiy","Gastello"] }],
    ['Pacific/Guadalcanal', { 'generalName': 'Solomon Islands Time', 'cities': ["Honiara", "Auki", "Gizo", "Kirakira", "Tulagi"] }],
    ['Asia/Srednekolymsk', { 'generalName': 'Srednekolymsk Time', 'cities': ["Srednekolymsk"] }],
    ['Pacific/Efate', { 'generalName': 'Vanuatu Standard Time', 'cities': ["Port-Vila","Luganville","Sola","Isangel","Lakatoro","Saratamata","Port Olry","Longana","Lenakel","Lamap","Buala","Port Sandwich","Mont-Dore"] }],
    ['Asia/Anadyr', { 'generalName': 'Anadyr Standard Time', 'cities': ["Anadyr","Egvekinot","Bilibino","Provideniya","Lavrentiya","Pevek","Uelen","Lorino","Novoye Chaplino","Yanrakynnot"] }],
    ['Pacific/Fiji', { 'generalName': 'Fiji Standard Time', 'cities': ["Suva","Lautoka","Nadi","Labasa","Ba","Levuka","Sigatoka","Rakiraki","Tavua","Savusavu"] }],
    ['Pacific/Tarawa', { 'generalName': 'Gilbert Islands Time', 'cities': ["Tarawa","Betio Village","Bairiki Village","Teaoraereke","Bikenibeu Village","Eita Village","Butaritari","Buariki Village","Tabiauea","Nanikai Village"] }],
    ['Pacific/Kwajalein', { 'generalName': 'Marshall Islands Time - Kwajalein', 'cities': ["Majuro","Delap-Uliga-Darrit","Wotje","Jaluit","Namdrik","Aur Atoll","Mili Atoll","Ebon Atoll","Kwajalein","Arno Atoll"] }],
    ['Pacific/Majuro', { 'generalName': 'Marshall Islands Time - Majuro', 'cities': ["Majuro","Delap-Uliga-Darrit","Wotje","Jaluit","Namdrik","Aur Atoll","Mili Atoll","Ebon Atoll","Kwajalein","Arno Atoll"] }],
    ['Pacific/Nauru', { 'generalName': 'Nauru Time', 'cities': ["Yaren District"] }],
    ['Pacific/Auckland', { 'generalName': 'New Zealand Time', 'cities': ["Auckland","Wellington","Christchurch","Manukau City","Waitakere","North Shore","Hamilton","Dunedin","Tauranga","Lower Hutt"] }],
    ['Asia/Kamchatka', { 'generalName': 'Petropavlovsk-Kamchatski Standard Time', 'cities': ["Petropavlovsk-Kamchatsky","Yelizovo","Vilyuchinsk","Milkovo","Ust-Kamchatsk","Klyuchi","Esso","Korf","Kamen-Rybolov","Ust-Bolsheretsk"] }],
    ['Pacific/Funafuti', { 'generalName': 'Tuvalu Time', 'cities': ["Funafuti","Alapi Village","Vaiaku Village","Senala Village","Teava Village","Toga Village","Savave Village","Teone Village","Funafala Village","Fakaifou Village"] }],
    ['Pacific/Wake', { 'generalName': 'Wake Island Time', 'cities': ["Wake Island"] }],
    ['Pacific/Wallis', { 'generalName': 'Wallis & Futuna Time', 'cities': ["Mata-Utu"] }],
    ['Pacific/Chatham', { 'generalName': 'Chatham Time', 'cities': ["Waitangi","Te One","Kaingaroa","Owenga","Pitt Island","Owenga Road","Te Whanga","Te One Road","Tuku","Port Hutt"] }],
    ['Pacific/Apia', { 'generalName': 'Apia Standard Time', 'cities': ["Apia"] }],
    ['Pacific/Kanton', { 'generalName': 'Phoenix Islands Time', 'cities': ["Kanton"] }],
    ['Pacific/Fakaofo', { 'generalName': 'Tokelau Time', 'cities': ["Fakaofo"] }],
    ['Pacific/Tongatapu', { 'generalName': 'Tonga Standard Time', 'cities': ["Nukualofa"] }],
    ['Pacific/Kiritimati', { 'generalName': 'Line Islands Time', 'cities': ["Kiritimati"] }],
])

/**
 * @deprecated
 */
export const preferredTimeZones = new Set<string>([
    'Etc/UTC',
    'Europe/Kiev',
    'Europe/London',
    'Europe/Paris',
    'Etc/GMT+9',
    'US/Alaska',
    'America/New_York',
    'America/Buenos_Aires',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'Etc/GMT+2',
    'Canada/Newfoundland',
    'Asia/Hong_Kong',
    'Asia/Bangkok',
    'Australia/Sydney',
    'Pacific/Norfolk',
    'Asia/Karachi',
    'Pacific/Auckland',
    'Asia/Dhaka',
    'Asia/Dubai',
    'Asia/Kolkata',
    'Asia/Tokyo',
    'Asia/Tehran',
    'Asia/Kathmandu',
    'Asia/Rangoon',
    'Etc/GMT+1',
    'Australia/Adelaide',
    'Australia/Eucla',
    'Australia/Lord_Howe',
    'Pacific/Honolulu',
    'Pacific/Samoa',
    'Etc/GMT+12',
    'Pacific/Tongatapu',
    'Pacific/Kiritimati',
    'Pacific/Chatham',
    'Pacific/Marquesas',
    'Australia/Darwin',
    'Asia/Kabul',
])

/**
 * @deprecated
 */
export const timeZoneToCityMap = {
    'Africa/Algiers': 'Algiers',
    'Africa/Cairo': 'Cairo',
    'Africa/Casablanca': 'Casablanca',
    'Africa/Dakar': 'Dakar',
    'Africa/Dar_es_Salaam': 'Dar es Salaam',
    'Africa/Johannesburg': 'Johannesburg',
    'Africa/Nairobi': 'Nairobi',
    'Africa/Tripoli': 'Tripoli',
    'Africa/Tunis': 'Tunis',
    'America/Anchorage': 'Anchorage',
    'America/Argentina/Buenos_Aires': 'Buenos Aires',
    'America/Argentina/Cordoba': 'Cordoba',
    'America/Argentina/Mendoza': 'Mendoza',
    'America/Argentina/Ushuaia': 'Ushuaia',
    'America/Aruba': 'Aruba',
    'America/Barbados': 'Barbados',
    'America/Belize': 'Belize',
    'America/Bogota': 'Bogota',
    'America/Boise': '',
    'America/Buenos_Aires': 'Buenos Aires',
    'America/Caracas': 'Caracas',
    'America/Chicago': 'Chicago',
    'America/Costa_Rica': 'Costa Rica',
    'America/Curacao': 'Curacao',
    'America/Denver': 'Denver',
    'America/Detroit': 'Detroit',
    'America/El_Salvador': 'El Salvador',
    'America/Guadeloupe': 'Guadeloupe',
    'America/Guatemala': 'Guatemala',
    'America/Halifax': 'Halifax',
    'America/Havana': 'Havana',
    'America/Indianapolis': 'Indianapolis',
    'America/Jamaica': 'Jamaica',
    'America/Juneau': 'Juneau',
    'America/Kentucky/Louisville': 'Louisville',
    'America/Knox_IN': 'Knox',
    'America/La_Paz': 'La Paz',
    'America/Lima': 'Lima, Peru',
    'America/Los_Angeles': 'Los Angeles',
    'America/Louisville': 'Louisville',
    'America/Mendoza': 'Mendoza',
    'America/Mexico_City': 'Mexico City',
    'America/Montevideo': 'Montevideo',
    'America/Montreal': 'Montreal',
    'America/Nassau': 'Nassau',
    'America/New_York': 'New York',
    'America/Panama': 'Panama',
    'America/Phoenix': 'Phoenix',
    'America/Port-au-Prince': 'Port-au-Prince',
    'America/Puerto_Rico': 'Puerto Rico',
    'America/Santiago': 'Santiago, Chile',
    'America/Santo_Domingo': 'Santo Domingo',
    'America/Sao_Paulo': 'Sao Paulo',
    'America/Tijuana': 'Tijuana',
    'America/Toronto': 'Toronto',
    'America/Vancouver': 'Vancouver',
    'Asia/Baghdad': 'Baghdad',
    'Asia/Bahrain': 'Bahrain',
    'Asia/Bangkok': 'Bangkok',
    'Asia/Beirut': 'Beirut',
    'Asia/Calcutta': 'Kolkata',
    'Asia/Chongqing': 'Chongqing',
    'Asia/Chungking': 'Chungking',
    'Asia/Damascus': 'Damascus',
    'Asia/Dhaka': 'Dhaka',
    'Asia/Dili': 'Dili',
    'Asia/Dubai': 'Dubai',
    'Asia/Gaza': 'Gaza',
    'Asia/Ho_Chi_Minh': 'Ho Chi Minh',
    'Asia/Hong_Kong': 'Hong Kong',
    'Asia/Istanbul': 'Istanbul',
    'Asia/Jakarta': 'Jakarta',
    'Asia/Jerusalem': 'Jerusalem',
    'Asia/Kabul': 'Kabul',
    'Asia/Kamchatka': 'Kamchatka',
    'Asia/Karachi': 'Karachi',
    'Asia/Kathmandu': 'Kathmandu',
    'Asia/Katmandu': 'Kathmandu',
    'Asia/Kolkata': 'Kolkata',
    'Asia/Kuala_Lumpur': 'Kuala Lumpur',
    'Asia/Kuwait': 'Kuwait',
    'Asia/Manila': 'Manila',
    'Asia/Muscat': 'Muscat',
    'Asia/Phnom_Penh': 'Phnom Penh',
    'Asia/Pyongyang': 'Pyongyang',
    'Asia/Qatar': 'Qatar',
    'Asia/Rangoon': 'Rangoon',
    'Asia/Saigon': 'Saigon',
    'Asia/Samarkand': 'Samarkand',
    'Asia/Seoul': 'Seoul',
    'Asia/Shanghai': 'Shanghai',
    'Asia/Singapore': 'Singapore',
    'Asia/Taipei': 'Taipei',
    'Asia/Tehran': 'Tehran',
    'Asia/Tel_Aviv': 'Tel Aviv',
    'Asia/Tokyo': 'Tokyo',
    'Atlantic/Bermuda': 'Bermuda',
    'Atlantic/Reykjavik': 'Reykjavik',
    'Australia/Adelaide': 'Adelaide',
    'Australia/Brisbane': 'Brisbane',
    'Australia/Darwin': 'Darwin',
    'Australia/Eucla': 'Eucla',
    'Australia/Lord_Howe': 'Lord Howe',
    'Australia/Melbourne': 'Melbourne',
    'Australia/Perth': 'Perth',
    'Australia/Queensland': 'Queensland',
    'Australia/Sydney': 'Sydney',
    'Canada/Atlantic': 'Canada/Atlantic',
    'Canada/Central': 'Canada/Central',
    'Canada/Eastern': 'Canada/Eastern',
    'Canada/Mountain': 'Canada/Mountain',
    'Canada/Newfoundland': 'Newfoundland',
    'Canada/Pacific': 'Canada/Pacific',
    'Canada/Saskatchewan': 'Saskatchewan',
    'Canada/Yukon': 'Yukon, CA',
    'Chile/Continental': 'Chile/Continental',
    'Chile/EasterIsland': 'Easter Island',
    'Cuba': 'Havana',
    'EST': 'New York',
    'EST5EDT': 'New York',
    'Egypt': 'Cairo',
    'Eire': 'Eire',
    'Etc/GMT': 'UTC',
    'Etc/GMT+0': 'UTC',
    'Etc/GMT+1': 'UTC-1',
    'Etc/GMT+10': 'UTC-10',
    'Etc/GMT+11': 'UTC-11',
    'Etc/GMT+12': 'UTC-12',
    'Etc/GMT+2': 'UTC-2',
    'Etc/GMT+3': 'UTC-3',
    'Etc/GMT+4': 'UTC-4',
    'Etc/GMT+5': 'UTC-5',
    'Etc/GMT+6': 'UTC-6',
    'Etc/GMT+7': 'UTC-7',
    'Etc/GMT+8': 'UTC-8',
    'Etc/GMT+9': 'UTC-9',
    'Etc/GMT-0': 'UTC',
    'Etc/GMT-1': 'UTC+1',
    'Etc/GMT-10': 'UTC+10',
    'Etc/GMT-11': 'UTC+11',
    'Etc/GMT-12': 'UTC+12',
    'Etc/GMT-13': 'UTC+13',
    'Etc/GMT-14': 'UTC+14',
    'Etc/GMT-2': 'UTC+2',
    'Etc/GMT-3': 'UTC+3',
    'Etc/GMT-4': 'UTC+4',
    'Etc/GMT-5': 'UTC+5',
    'Etc/GMT-6': 'UTC+6',
    'Etc/GMT-7': 'UTC+7',
    'Etc/GMT-8': 'UTC+8',
    'Etc/GMT-9': 'UTC+9',
    'Etc/GMT0': 'UTC',
    'Etc/Greenwich': 'UTC',
    'Etc/UCT': 'UTC',
    'Etc/UTC': 'UTC',
    'Etc/Universal': 'UTC',
    'Etc/Zulu': 'UTC',
    'Europe/Amsterdam': 'Amsterdam',
    'Europe/Athens': 'Athens',
    'Europe/Belfast': 'Belfast',
    'Europe/Berlin': 'Berlin',
    'Europe/Brussels': 'Brussels',
    'Europe/Bucharest': 'Bucharest',
    'Europe/Budapest': 'Budapest',
    'Europe/Copenhagen': 'Copenhagen',
    'Europe/Dublin': 'Dublin',
    'Europe/Gibraltar': 'Gibraltar',
    'Europe/Helsinki': 'Helsinki',
    'Europe/Istanbul': 'Istanbul',
    'Europe/Jersey': 'Jersey',
    'Europe/Kaliningrad': 'Kaliningrad',
    'Europe/Kiev': 'Kyiv',
    'Europe/Kirov': 'Kirov',
    'Europe/Lisbon': 'Lisbon',
    'Europe/London': 'London',
    'Europe/Luxembourg': 'Luxembourg',
    'Europe/Madrid': 'Madrid',
    'Europe/Minsk': 'Minsk',
    'Europe/Monaco': 'Monaco',
    'Europe/Moscow': 'Moscow',
    'Europe/Oslo': 'Oslo',
    'Europe/Paris': 'Paris',
    'Europe/Prague': 'Prague',
    'Europe/Rome': 'Rome',
    'Europe/Sarajevo': 'Sarajevo',
    'Europe/Stockholm': 'Stockholm',
    'Europe/Vienna': 'Vienna',
    'Europe/Warsaw': 'Warsaw',
    'Europe/Zurich': 'Zurich',
    'GMT': 'UTC',
    'GMT+0': 'UTC',
    'GMT-0': 'UTC',
    'GMT0': 'UTC',
    'Greenwich': 'UTC',
    'HST': 'Honolulu',
    'Hongkong': 'Hong Kong',
    'Israel': 'Israel',
    'Jamaica': 'Jamaica',
    'Libya': 'Libya',
    'Pacific/Auckland': 'Auckland',
    'Pacific/Chatham': 'Chatham',
    'Pacific/Easter': 'Easter Island',
    'Pacific/Fiji': 'Fiji',
    'Pacific/Galapagos': 'Galapagos',
    'Pacific/Guam': 'Guam',
    'Pacific/Honolulu': 'Honolulu',
    'Pacific/Johnston': 'Johnston',
    'Pacific/Kiritimati': 'Kiritimati',
    'Pacific/Kosrae': 'Kosrae',
    'Pacific/Kwajalein': 'Kwajalein',
    'Pacific/Majuro': 'Majuro',
    'Pacific/Marquesas': 'Marquesas',
    'Pacific/Midway': 'Midway',
    'Pacific/Nauru': 'Nauru',
    'Pacific/Niue': 'Niue',
    'Pacific/Norfolk': 'Norfolk',
    'Pacific/Noumea': 'Noumea',
    'Pacific/Pago_Pago': 'Pago Pago',
    'Pacific/Palau': 'Palau',
    'Pacific/Pitcairn': 'Pitcairn',
    'Pacific/Pohnpei': 'Pohnpei',
    'Pacific/Ponape': 'Ponape',
    'Pacific/Port_Moresby': 'Port Moresby',
    'Pacific/Rarotonga': 'Rarotonga',
    'Pacific/Saipan': 'Saipan',
    'Pacific/Samoa': 'Samoa',
    'Pacific/Tahiti': 'Tahiti',
    'Pacific/Tarawa': 'Tarawa',
    'Pacific/Tongatapu': 'Tongatapu',
    'Pacific/Truk': 'Truk',
    'Pacific/Wake': 'Wake',
    'Pacific/Wallis': 'Wallis',
    'Pacific/Yap': 'Yap',
    'UCT': 'UTC',
    'US/Alaska': 'Alaska',
    'US/Arizona': 'US/Mountain',
    'US/Central': 'US/Central',
    'US/Eastern': 'US/Eastern',
    'US/Hawaii': 'Honolulu',
    'US/Mountain': 'US/Mountain',
    'US/Pacific': 'US/Pacific',
    'US/Pacific-New': 'US/Pacific',
    'US/Samoa': 'Samoa',
    'UTC': 'UTC',
    'Universal': 'UTC',
    'Zulu': 'UTC',
}

/**
 * Used to fill out timezone abbreviations for the ones that are not found in the moment timezone library
 */
export const timeZoneToAbbreviationMap = {
    'Pacific/Marquesas': 'MART',
    'America/Argentina/Buenos_Aires': 'ART',
    'America/Buenos_Aires': 'ART',
    'Asia/Tehran': 'IRST',
    'Asia/Dubai': 'GST',
    'Asia/Kabul': 'AFT',
    'Asia/Karachi': 'PKT',
    'Asia/Kathmandu': 'NPT',
    'Asia/Katmandu': 'NPT',
    'Asia/Dhaka': 'BST',
    'Asia/Rangoon': 'MMT',
    'Asia/Bangkok': 'ICT',
    'Australia/Eucla': 'ACWST',
    'Pacific/Norfolk': 'NFT',
    'Pacific/Chatham': 'CHAST',
    'Pacific/Kiritimati': 'LINT',
    // 'Australia/Lord_Howe': 'UTC+10.5',
    // 'Pacific/Tongatapu': 'UTC+13',
    'Etc/GMT': 'UTC',
    'Etc/GMT+0': 'UTC',
    'Etc/GMT+1': 'CVT',
    // 'Etc/GMT+10': 'UTC-10',
    // 'Etc/GMT+11': 'UTC-11',
    'Etc/GMT+12': 'IDLW',
    'Etc/GMT+2': 'BRST',
    // 'Etc/GMT+3': 'UTC-3',
    // 'Etc/GMT+4': 'UTC-4',
    // 'Etc/GMT+5': 'UTC-5',
    // 'Etc/GMT+6': 'UTC-6',
    // 'Etc/GMT+7': 'UTC-7',
    // 'Etc/GMT+8': 'UTC-8',
    // 'Etc/GMT+9': 'UTC-9',
    // 'Etc/GMT-0': 'UTC',
    // 'Etc/GMT-1': 'UTC+1',
    // 'Etc/GMT-10': 'UTC+10',
    // 'Etc/GMT-11': 'UTC+11',
    // 'Etc/GMT-12': 'UTC+12',
    // 'Etc/GMT-13': 'UTC+13',
    // 'Etc/GMT-14': 'UTC+14',
    // 'Etc/GMT-2': 'UTC+2',
    // 'Etc/GMT-3': 'UTC+3',
    // 'Etc/GMT-4': 'UTC+4',
    // 'Etc/GMT-5': 'UTC+5',
    // 'Etc/GMT-6': 'UTC+6',
    // 'Etc/GMT-7': 'UTC+7',
    // 'Etc/GMT-8': 'UTC+8',
    // 'Etc/GMT-9': 'UTC+9',
    // 'Etc/GMT0': 'UTC',
    // 'GMT': 'UTC',
    // 'GMT+0': 'UTC',
    // 'GMT-0': 'UTC',
    // 'GMT0': 'UTC',
}
