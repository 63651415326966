import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core'

export interface TagOption {
    index: number
    label: string | any
    value: any
    checked: boolean
}

@Component({
    selector: 'app-tags-selector',
    templateUrl: 'tags.component.html',
    styleUrls: ['tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: Move to the ui-kit module
export class TagsSelectorComponent<T, InputOption = [T, string]> implements OnInit {

    @Output() onTagRemoved = new EventEmitter<number>()
    @Output() onAddTagClicked = new EventEmitter<void>()

    /**
     * @TODO: Rework this component
     */

    public tagOptions: TagOption[] = []
    private _options: InputOption[] = []

    public _selectedOptions: T[] = []

    @Input() iconSize = 18
    @Input() multiple = true
    @Input() required = false
    @Input() disabled = false

    @Input() addTagText: string
    @Input() showAddTagBtn: boolean = false
    @Input() showDeleteIcon: boolean = false

    @Input() size: 'md' | 'lg' = 'lg'

    @Input() set options(list: InputOption[]) {
        this._options = list
        this.tagOptions = list.map((item, index) => {
            let label
            let value
            if ('string' === typeof item) {
                label = value = item
            } else if (Array.isArray(item) && item.length === 2) {
                value = item[0]
                label = item[1]
            } else if ('object' === typeof item) {
                // @ts-ignore
                let { label: itemLabel, value: itemValue } = item

                if (!itemLabel && itemValue && 'string' === typeof itemValue) {
                    itemLabel = itemValue
                }
                if (itemLabel && !itemValue) {
                    itemValue = itemLabel
                }
                label = itemLabel
                value = itemValue
            }
            return {
                index,
                label,
                value,
                checked: this._selectedOptions.includes(value),
            }
        })
    }

    @Input() set selected(value: T | T[]) {
        this._selectedOptions = Array.isArray(value) ? value : [value]
        this.updateChecked()
    }

    @Output() readonly selectedChange = new EventEmitter<T | T[]>()

    @Output() readonly onChange = new EventEmitter<T | T[]>()

    @Output() readonly tagClick = new EventEmitter<[T,boolean]>()

    ngOnInit() {
        this.updateChecked()
    }

    private updateChecked() {
        this.tagOptions.forEach((option, index) => {
            option.checked = this._selectedOptions.includes(option.value)
        })
    }

    public toggleTag(option: TagOption) {
        /**
         * Skip any actions when disabled
         */
        if (this.disabled) {
            return null
        }

        const checked = !option.checked

        if (this.required && !checked && 1 === this._selectedOptions.length ) {
            return false
        }

        if (!this.multiple && option.checked) {
            this.tagOptions.forEach(option => option.checked = false)
        }

        option.checked = checked

        this.tagClick.emit([this._options[option.index] as any, checked]);

        if (this.multiple) {
            const selectedOptions = []
            const selectedValues = []
            this.tagOptions.forEach((option, index) => {
                if (option.checked) {
                    selectedValues.push(option.value)
                    selectedOptions.push(this._options[index])
                }
            })
            this._selectedOptions = selectedValues
            this.selectedChange.emit(selectedValues)
            this.onChange.emit(selectedOptions)
        } else {
            this._selectedOptions = option.checked ? [option.value] : []
            this.selectedChange.emit(this._selectedOptions[0])
            this.onChange.emit(this._options[option.index] as any)
        }
    }

}
