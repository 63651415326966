import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-all-day-event-clock',
    templateUrl: 'all-day-event-clock-icon.component.html',
    styleUrls: ['all-day-event-clock-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllDayEventClockIconComponent {
    @Input() height: number|string = '18'
    @Input() width: number|string = '18'
    @Input() color: string = '#2B2733'
    @Input() borderColor: string = ''
}
