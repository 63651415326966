import { ApiContext } from '@undock/api/contracts/api.context'
import { FeaturePlansRoute } from '@undock/api/scopes/subscriptions/routes/feature-plans.route'
import { SubscriptionsRoute } from '@undock/api/scopes/subscriptions/routes/subscriptions.route'
import { GroupSubscriptionsRoute } from '@undock/api/scopes/subscriptions/routes/group-subscriptions.route'

export class SubscriptionsScope {
    public readonly featurePlans: FeaturePlansRoute
    public readonly subscriptions: SubscriptionsRoute
    public readonly groupSubscriptions: GroupSubscriptionsRoute

    constructor(protected context: ApiContext) {
        this.featurePlans = new FeaturePlansRoute(context)
        this.subscriptions = new SubscriptionsRoute(context)
        this.groupSubscriptions = new GroupSubscriptionsRoute(context)
    }
}
