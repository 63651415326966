import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core'

import {
    fromEvent,
    Observable,
} from 'rxjs'
import {
    debounceTime,
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { Memoize } from '@undock/core'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'


@Component({
    selector: 'app-meet-title',
    templateUrl: 'dock-title.component.html',
    styleUrls: ['dock-title.component.scss'],
})
export class DockTitleComponent implements AfterViewInit {

    public readonly isEditModeStream: ReactiveStream<boolean>

    @Input() disabled: boolean

    /**
     * Used for automatically saving changes
     */
    @Input() debounceTime: number = 2000

    @ViewChild('titleInput')
    private titleInputRef: ElementRef<HTMLInputElement>

    @CompleteOnDestroy()
    private placeholderSubject = new ValueSubject<string>('')

    private readonly defaultPlaceholder = 'Enter title...'

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private currentDock: DockFacade,
    ) {
        this.isEditModeStream = this.currentDock.isEditModeStream
    }

    @Input() set placeholder(value: string) {
        this.placeholderSubject.next(value)
    }

    @Memoize()
    public get titleStream(): Observable<string> {
        return this.currentDock.currentDockStream.pipe(
            takeUntil(this.destroyedEvent),
            map(dock => dock.title ?? ''),
        )
    }

    @Memoize()
    public get placeholderStream(): Observable<string> {
        return this.placeholderSubject.pipe(
            map(placeholder => placeholder ?? this.defaultPlaceholder),
        )
    }

    public async ngAfterViewInit(): Promise<void> {
        const attachTitleHandler = (source: Observable<any>, dTime: number) => {
            return source.pipe(
                takeUntil(this.destroyedEvent), debounceTime(dTime),
                map(() => this.titleInputRef.nativeElement.value),
            ).subscribe(
                title => this.currentDock.setTitle(title),
            )
        }

        if (await this.isEditModeStream && this.titleInputRef?.nativeElement) {
            attachTitleHandler(fromEvent<KeyboardEvent>(this.titleInputRef.nativeElement, 'blur'), 20)
            attachTitleHandler(fromEvent<KeyboardEvent>(this.titleInputRef.nativeElement, 'paste'), this.debounceTime)
            attachTitleHandler(fromEvent<KeyboardEvent>(this.titleInputRef.nativeElement, 'keyup'), this.debounceTime)
        }
    }
}
