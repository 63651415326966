<header>
    <div class="button back clickable" (click)="close()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69732 4.46967C9.99022 4.76256 9.99022 5.23744 9.69732 5.53033L5.97765 9.25H15.8337C16.2479 9.25 16.5837 9.58579 16.5837 10C16.5837 10.4142 16.2479 10.75 15.8337 10.75H5.97765L9.69732 14.4697C9.99022 14.7626 9.99022 15.2374 9.69732 15.5303C9.40443 15.8232 8.92956 15.8232 8.63666 15.5303L3.63666 10.5303C3.34377 10.2374 3.34377 9.76256 3.63666 9.46967L8.63666 4.46967C8.92956 4.17678 9.40443 4.17678 9.69732 4.46967Z" fill="#807A8E"/>
        </svg>
    </div>

    <div class="form-title">
        {{relevantActionTypeStream | stream}}
    </div>

    <div class="button close clickable" (click)="close()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.46967 4.46967C4.76256 4.17678 5.23744 4.17678 5.53033 4.46967L10 8.93934L14.4697 4.46967C14.7626 4.17678 15.2374 4.17678 15.5303 4.46967C15.8232 4.76256 15.8232 5.23744 15.5303 5.53033L11.0607 10L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7626 15.8232 14.4697 15.5303L10 11.0607L5.53033 15.5303C5.23744 15.8232 4.76256 15.8232 4.46967 15.5303C4.17678 15.2374 4.17678 14.7626 4.46967 14.4697L8.93934 10L4.46967 5.53033C4.17678 5.23744 4.17678 4.76256 4.46967 4.46967Z" fill="#807A8E"/>
        </svg>
    </div>
</header>

<app-meet-event-form
    [submitButtonLabel]="'Save Changes'"
></app-meet-event-form>

<footer>
    <span class="clickable">
        Next field
        <span class="hot-key bordered">Tab</span>
    </span>

    <span class="clickable" (click)="close()">
        Close the form
        <span class="hot-key bordered">Esc</span>
    </span>
</footer>
