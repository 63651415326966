<ng-container [ngSwitch]="command.type">
    <app-command-time-block class="__icon" *ngSwitchCase="CommandType.TimeBlock"
                            [height]="size" [width]="size" [color]="color" [borderColor]="borderColor"
    ></app-command-time-block>
    <app-command-meeting class="__icon" *ngSwitchCase="CommandType.Meeting"
                         [height]="size" [width]="size" [color]="color" [borderColor]="borderColor"
    ></app-command-meeting>
    <app-command-mark-busy class="__icon" *ngSwitchCase="CommandType.MarkBusy"
                           [height]="size" [width]="size" [color]="color" [borderColor]="borderColor"
    ></app-command-mark-busy>
    <app-command-mark-available class="__icon" *ngSwitchCase="CommandType.MarkAvailable"
                                [height]="size" [width]="size" [color]="color" [borderColor]="borderColor"
    ></app-command-mark-available>
    <app-command-reschedule class="__icon" *ngSwitchCase="CommandType.RescheduleCancel"
                            [height]="size" [width]="size" [color]="color" [borderColor]="borderColor"
    ></app-command-reschedule>
    <app-command-time-block class="__icon" *ngSwitchDefault
                            [height]="size" [width]="size" [color]="color" [borderColor]="borderColor"
    ></app-command-time-block>
</ng-container>