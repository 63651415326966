import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core'

import {
    combineLatest,
    fromEvent,
    Observable,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    compareDeeply,
    Memoize,
    Validations,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import { MeetingMode } from '@undock/dock/meet'
import { IntegrationsManager } from '@undock/integrations'
import { LocationsManager } from '@undock/locations/services/locations.manager'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { UserConferenceLinkType } from '@undock/api/scopes/user/contracts/user.interface'
import { AutocompleteService } from '@undock/locations/services/autocomplete.service'


/**
 *
 */
declare type UiUserConferenceLinkType = UserConferenceLinkType | 'undock-audio'

@Component({
    selector: 'app-meet-event-form-location',
    templateUrl: 'event-form-location.component.html',
    styleUrls: ['event-form-location.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormLocationComponent {

    public readonly eventFormState = this.eventFormStateModel.state

    @CompleteOnDestroy()
    public readonly locationInputTextStream = new ValueSubject('')

    /**
     * Optional host for dropdown elem
     */
    @Input() dropdownElHost?: HTMLElement

    @ViewChild('inputElRef', { read: ElementRef })
    private readonly inputElRef: ElementRef<HTMLInputElement>

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    /**
     * Build-in conferencing options
     */
    private readonly remoteOptions: UiLocationListItem[] = [
        {
            label: 'Undock',
            mode: MeetingMode.Video,
            remoteType: 'undock',
        },
        {
            label: 'Zoom',
            mode: MeetingMode.Video,
            remoteType: 'zoom',
        },
        {
            label: 'Undock Audio-Only',
            mode: MeetingMode.Audio,
            remoteType: 'undock-audio',
        },
        {
            label: 'Connected Calendar Conferencing',
            mode: MeetingMode.Video,
            remoteType: 'external',
        },
    ]

    public constructor(
        private readonly currentUser: CurrentUser,
        private readonly locationsManager: LocationsManager,
        private readonly eventFormStateModel: EventFormStateModel,
        private readonly integrationsManager: IntegrationsManager,
        private readonly autocompleteService: AutocompleteService,
    ) {}

    @Memoize()
    public get selectedLocationOption$(): Observable<{
        mode?: MeetingMode
        value?: string // custom or in-person option
        label?: string // label for readonly options
        readonly?: boolean // conference generated later
    }> {
        return combineLatest([
            this.eventFormStateModel.state.locationStream,
            this.eventFormStateModel.state.isDraftModeStream,
            this.eventFormStateModel.state.meetingModeStream,
            this.eventFormStateModel.state.conferenceLinkTypeStream,
        ]).pipe(
            distinctUntilChanged(compareDeeply),
            map(sources => {
                const [ location, isDraft, mode, confProvider ] = sources
                if (isDraft) {
                    // For external conference providers
                    if (mode !== MeetingMode.InPerson) {
                        switch (confProvider) {
                            case 'zoom':
                                return {
                                    label: 'Zoom', readonly: true,
                                }
                            case 'undock':
                                return {
                                    readonly: true,
                                    label: mode === MeetingMode.Video ? 'Undock' : 'Undock Audio',
                                }
                            case 'external':
                                return {
                                    readonly: true,
                                    label: 'Connected Calendar Conferencing',
                                }
                        }
                    }
                }
                // For InPerson location and existing meetings
                return { mode, value: location, readonly: false }
            })
        )
    }

    @Memoize()
    public get locationListItemGroups$(): ReactiveStream<{
        remote: UiLocationListItem[]
        locations: UiLocationListItem[]
        suggestions: UiLocationListItem[]
    }> {
        return new ReactiveStream(
            combineLatest([
                this.eventFormState.isDraftModeStream,
                this.locationsManager.savedLocationsStream,
                this.locationInputTextStream,
                this.integrationsManager.zoomConnectionStream,
                this.autocompleteService.results$,
            ]).pipe(
                /**
                 * TODO: Add filtering based on a location input text
                 */
                map(([
                    isDraft,
                    savedLocations,
                    inputText,
                    zoomConnection,
                    placesSearchResults,
                ]) => {
                    const regexp = new RegExp(inputText, 'gi')

                    // Disable remote options in edit mode
                    const remoteOptions = isDraft ? this.remoteOptions : []
                    return {
                        remote: (
                            zoomConnection // Remove zoom option if connection doesn't exist
                                ? remoteOptions
                                : remoteOptions.filter(option => option.remoteType !== 'zoom')
                        ).filter(option => {
                            return regexp.test(option.label)
                                || regexp.test(option.description)
                        }),

                        locations: savedLocations
                            .filter(location => {
                                return regexp.test(location.name)
                                    || regexp.test(location.address)
                            })
                            .slice(0, 4)
                            .map(location => {
                                return {
                                    label: location.name,
                                    description: location.address,
                                    mode: MeetingMode.InPerson,
                                    url: location.url,
                                    address: location.address,
                                }
                            }),

                        suggestions: placesSearchResults.slice(0, 4).map(item => {
                            return {
                                label: item.description,
                                mode: MeetingMode.InPerson,
                                url: '',
                                address: item.description,
                            }
                        }),
                    }
                }),
            )
        )
    }

    public ngAfterViewInit() {
        fromEvent<KeyboardEvent>(
            this.inputElRef.nativeElement, 'keydown',
        ).pipe(
            debounceTime(200),
            takeUntil(this.destroyEvent),
        ).subscribe(() => this.locationInputTextStream.next(
            this.inputElRef.nativeElement.value ?? '',
        ))

        this.locationInputTextStream.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe((value) => {
            if (value && value.trim()) {
                this.autocompleteService.search(value)
            }
        })
    }

    public selectOption(option: UiLocationListItem) {
        this.eventFormStateModel.setMeetingMode(option.mode)
        if (option.mode === MeetingMode.InPerson) {
            this.eventFormStateModel.setLocation(option.address)
            this.eventFormStateModel.setInPersonLocationUrl(option.url)
            this.eventFormStateModel.setConferenceLinkType(null)
        } else {
            this.eventFormStateModel.setConferenceLinkType(
                option.remoteType === 'undock-audio' ? 'undock' : option.remoteType
            )
            this.eventFormStateModel.setLocation(null)
            this.eventFormStateModel.setInPersonLocationUrl(null)
        }
    }

    public async applyLocationInputText(value: string) {
        const location = await this.eventFormState.locationStream
        if (location.trim() !== value.trim()) {
            // Detecting meeting mode used
            const isUrl = Validations.isValidUrl(value)
            if (isUrl) {
                const isGoogleMapsLink = value.includes('goo.gl/maps')
                    || value.includes('google.com/maps')
                if (isGoogleMapsLink) {
                    this.eventFormStateModel.setMeetingMode(MeetingMode.InPerson)
                    this.eventFormStateModel.setInPersonLocationUrl(value)
                } else {
                    this.eventFormStateModel.setMeetingMode(MeetingMode.Video)
                }
            } else {
                this.eventFormStateModel.setMeetingMode(MeetingMode.InPerson)
            }
            this.eventFormStateModel.setLocation(value)
        }
    }

    public async resetSelectedRemote() {
        if (await this.eventFormState.isDraftModeStream) {
            this.eventFormStateModel.setLocation(null)
            this.eventFormStateModel.setConferenceLinkType(null)
            this.eventFormStateModel.setInPersonLocationUrl(null)
        }
    }


    /**
     * Temporary functionality for key arrow selection
     * TODO: Rework this with some shared directive or service
     */

    @CompleteOnDestroy()
    public readonly selectedItem$ = new ValueSubject<UiLocationListItem>(null)

    public applyActiveListItem() {
        if (this.selectedItem$.value) {
            this.selectOption(this.selectedItem$.value)
        }
    }

    public async activatePrevListItem() {
        const groups = await this.locationListItemGroups$
            , options = [].concat(groups.remote, groups.locations, groups.suggestions)
        if (!this.selectedItem$.value) {
            this.selectedItem$.next(options[0])
        } else {
            const nextItemIndex = options.findIndex(option => {
                return option.label === this.selectedItem$.value?.label
            }) - 1
            this.selectedItem$.next(options[nextItemIndex >= 0 ? nextItemIndex : 0])
        }
    }

    public async activateNextListItem() {
        const groups = await this.locationListItemGroups$
            , options = [].concat(groups.remote, groups.locations, groups.suggestions)
        if (!this.selectedItem$.value) {
            this.selectedItem$.next(options[0])
        } else {
            const nextItemIndex = options.findIndex(option => {
                return option.label === this.selectedItem$.value?.label
            }) + 1
            this.selectedItem$.next(options[nextItemIndex < options.length ? nextItemIndex : options.length - 1])
        }
    }
}


interface UiLocationListItem {
    mode: MeetingMode
    label: string
    description?: string

    // For `MeetingMode.InPerson`
    url?: string
    address?: string

    // For `MeetingMode.(Video|Audio)`
    remoteType?: UiUserConferenceLinkType
}
