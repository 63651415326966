import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { AnimationOptions } from 'ngx-lottie'

export enum OverlayStyle {
    Dark = 'Dark',
    Light = 'Light',
    Transparent = 'Transparent',
}

@Component({
    selector: 'app-loading-overlay',
    templateUrl: 'loading-overlay.component.html',
    styleUrls: ['loading-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent {

    public readonly OverlayStyle = OverlayStyle

    @Input() style: OverlayStyle = OverlayStyle.Light

    @Input() backgroundColor: string = '#FFFFFF'

    @Input() overrideLogoUrl: string

    public get options(): AnimationOptions {
        switch (this.style) {
            case OverlayStyle.Dark:
                return this.darkOptions
            case OverlayStyle.Light:
                return this.lightOptions
            case OverlayStyle.Transparent:
                return this.lightOptions
        }
    }

    private readonly darkOptions: AnimationOptions = {
        rendererSettings: {
            progressiveLoad: true,
        },
        animationData: window['udLightAnimationConfig'],
    }

    private readonly lightOptions: AnimationOptions = {
        rendererSettings: {
            progressiveLoad: true,
        },
        animationData: window['udLightAnimationConfig'],
    }
}
