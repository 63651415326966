import { ApiRoute } from '@undock/api/services/api.route'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'


export class RsvpRoute extends ApiRoute {

    public init() {
        this.route = 'calendar/events/rsvp'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public yes(eventId: string, attendeeEmail?: string) {
        return this.post<void>()
                   .endpoint(`yes/${eventId}${attendeeEmail ? '/' + attendeeEmail : ''}`)
    }

    public no(eventId: string, attendeeEmail?: string) {
        return this.post<void>()
                   .endpoint(`no/${eventId}`)
    }

    public maybe(eventId: string, attendeeEmail?: string) {
        return this.post<void>()
                   .endpoint(`maybe/${eventId}${attendeeEmail ? '/' + attendeeEmail : ''}`)
    }

    public setStatus(
        eventId: string, status: RsvpStatus, attendeeEmail?: string
    ) {
        return this.post<void>()
                   .endpoint(`set/${eventId}/${status}${attendeeEmail ? '/' + attendeeEmail : ''}`)
    }
}
