import { Injectable } from '@angular/core'
import { Api } from '@undock/api'
import { ValueSubject } from '@typeheim/fire-rx'
import { AutocompleteResult } from '@undock/api/scopes/locations/locations.route'

@Injectable()
export class AutocompleteService {

    public readonly results$ = new ValueSubject<AutocompleteResult[]>([])

    public constructor(private readonly api: Api) {}

    search(input: string): void {
        this.api.locations.locations.search(input).then(data => {
            this.results$.next(data)
        })
    }

    getUrl(placeId: string): Promise<string> {
        return this.api.locations.locations.getUrl(placeId)
    }
}
