import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core'


@Component({
    selector: 'app-time-available-time-slot-score-icon',
    templateUrl: 'available-time-slot-score-icon.component.html',
    styleUrls: ['available-time-slot-score-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableTimeSlotScoreIconComponent {

    protected readonly defaultWidth = 13
    protected readonly defaultHeight = 10

    @Input() score: number

    @Input() disabled: boolean = false

    @Input() width: number = this.defaultWidth
    @Input() height: number = this.defaultHeight
    @Input() barHeight: number = 10

    @HostBinding('style.--off-color')
    @Input() offColor: string = '#F3F3F9'

    @HostBinding('style.--on-color')
    @Input() onColor: string = '#2B2733'

    @Input() backgroundColor: string = '#FFFFFF'

    get viewBox(): string {
        return `0 0 ${this.width} ${this.height}`
    }

}
