import {
    Injectable,
} from '@angular/core'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import { UserAnalyticsObject } from '@undock/user/models/analytics/user-analytics-object.model'
import { UserAnalyticsProvider } from '@undock/user/services/analytics/user-analytics.provider'
import { injectCollection } from '@undock/session'


@Injectable({
    providedIn: 'root',
})
export class OnboardingAnalyticsRegistry {

    protected userAnalyticsStream: ReactiveStream<UserAnalyticsObject>

    protected readonly UserAnalyticsObjectCollection = injectCollection(UserAnalyticsObject)

    public constructor(
        private userAnalyticsProvider: UserAnalyticsProvider
    ) {
        this.userAnalyticsStream = this.userAnalyticsProvider.userAnalyticsStream
    }

    public async onOnboardingStarted() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject) {
            await this.prepareOnboardingTracking(analyticsObject)

            if (!analyticsObject.onboardingTracking.onboarding._state.started) {
                analyticsObject.onboardingTracking.onboarding._state = {
                    started: new Date(Date.now()).toISOString(),
                    isComplete: false
                }
                analyticsObject.onboardingTracking.onboarding.trainCalendar._state = {
                    started: new Date(Date.now()).toISOString(),
                    isComplete: false
                }
                analyticsObject.onboardingTracking.onboarding.trainCalendar.setWorkingHours = {
                    started: new Date(Date.now()).toISOString(),
                    isComplete: false
                }
            }

            await this.save(analyticsObject)
        }
    }

    public async onOnboardingComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding._state.isComplete) {
            analyticsObject.onboardingTracking.onboarding._state = {
                ...analyticsObject.onboardingTracking.onboarding._state,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingSetWorkingHoursComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.trainCalendar.setWorkingHours.isComplete) {
            analyticsObject.onboardingTracking.onboarding.trainCalendar.setWorkingHours = {
                ...analyticsObject.onboardingTracking.onboarding.trainCalendar.setWorkingHours,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingSetMeetingIntervalsStarted() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.trainCalendar.setMeetingIntervals.started) {
            analyticsObject.onboardingTracking.onboarding.trainCalendar.setMeetingIntervals = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingSetMeetingIntervalsComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.trainCalendar.setMeetingIntervals.isComplete) {
            analyticsObject.onboardingTracking.onboarding.trainCalendar.setMeetingIntervals = {
                ...analyticsObject.onboardingTracking.onboarding.trainCalendar.setMeetingIntervals,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingSetCalendarPlansStarted() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.trainCalendar.plans.started) {
            analyticsObject.onboardingTracking.onboarding.trainCalendar.plans = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingSetCalendarPlansComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.trainCalendar.plans.isComplete) {
            analyticsObject.onboardingTracking.onboarding.trainCalendar.plans = {
                ...analyticsObject.onboardingTracking.onboarding.trainCalendar.plans,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
            analyticsObject.onboardingTracking.onboarding.trainCalendar._state = {
                ...analyticsObject.onboardingTracking.onboarding.trainCalendar._state,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingConnectCalendarStarted() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.connectCalendar.started) {
            analyticsObject.onboardingTracking.onboarding.connectCalendar = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingConnectCalendarComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.connectCalendar.isComplete) {
            analyticsObject.onboardingTracking.onboarding.connectCalendar = {
                ...analyticsObject.onboardingTracking.onboarding.connectCalendar,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingClaimNameStarted() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.claimName.started) {
            analyticsObject.onboardingTracking.onboarding.claimName = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onOnboardingClaimNameComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.onboarding.claimName.isComplete) {
            analyticsObject.onboardingTracking.onboarding.claimName = {
                ...analyticsObject.onboardingTracking.onboarding.claimName,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourStarted() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour._state.started) {
            analyticsObject.onboardingTracking.productTour._state = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
            analyticsObject.onboardingTracking.productTour.profile = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour._state.isComplete) {
            analyticsObject.onboardingTracking.productTour._state = {
                ...analyticsObject.onboardingTracking.productTour._state,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourProfileComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject) {

            if (!analyticsObject.onboardingTracking) {
                await this.prepareOnboardingTracking(analyticsObject)
            }

            if (!analyticsObject.onboardingTracking.productTour.profile.isComplete) {
                analyticsObject.onboardingTracking.productTour.profile = {
                    ...analyticsObject.onboardingTracking.productTour.profile,
                    completed: new Date(Date.now()).toISOString(),
                    isComplete: true
                }
            }

            await this.save(analyticsObject)
        }
    }

    public async onProductTourDemoStarted() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject) {

            if (!analyticsObject.onboardingTracking) {
                await this.prepareOnboardingTracking(analyticsObject)
            }

            if (!analyticsObject.onboardingTracking.productTour.demo._state.started) {
                analyticsObject.onboardingTracking.productTour.demo._state = {
                    started: new Date(Date.now()).toISOString(),
                    isComplete: false
                }
                analyticsObject.onboardingTracking.productTour.demo.slashKey = {
                    started: new Date(Date.now()).toISOString(),
                    isComplete: false
                }
                analyticsObject.onboardingTracking.productTour.demo.installButton = {
                    started: new Date(Date.now()).toISOString(),
                    isComplete: false
                }
            }

            await this.save(analyticsObject)
        }
    }

    public async onProductTourDemoComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo._state.isComplete) {
            analyticsObject.onboardingTracking.productTour.demo._state = {
                ...analyticsObject.onboardingTracking.productTour.demo._state,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourDemoInteractionSlashKeyComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.slashKey.isComplete) {
            analyticsObject.onboardingTracking.productTour.demo.slashKey = {
                ...analyticsObject.onboardingTracking.productTour.demo.slashKey,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.arrowKeys.started) {
            analyticsObject.onboardingTracking.productTour.demo.arrowKeys = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourDemoInteractionArrowKeyComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.arrowKeys.isComplete) {
            analyticsObject.onboardingTracking.productTour.demo.arrowKeys = {
                ...analyticsObject.onboardingTracking.productTour.demo.arrowKeys,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.tabKey.started) {
            analyticsObject.onboardingTracking.productTour.demo.tabKey = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourDemoInteractionTabKeyComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.tabKey.isComplete) {
            analyticsObject.onboardingTracking.productTour.demo.tabKey = {
                ...analyticsObject.onboardingTracking.productTour.demo.tabKey,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.enterKey.started) {
            analyticsObject.onboardingTracking.productTour.demo.enterKey = {
                started: new Date(Date.now()).toISOString(),
                isComplete: false
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourDemoInteractionEnterKeyComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.enterKey.isComplete) {
            analyticsObject.onboardingTracking.productTour.demo.enterKey = {
                ...analyticsObject.onboardingTracking.productTour.demo.enterKey,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async onProductTourDemoInteractionInstallButtonComplete() {
        let analyticsObject = await this.userAnalyticsStream

        if (analyticsObject && !analyticsObject.onboardingTracking.productTour.demo.installButton.isComplete) {
            analyticsObject.onboardingTracking.productTour.demo.installButton = {
                ...analyticsObject.onboardingTracking.productTour.demo.installButton,
                completed: new Date(Date.now()).toISOString(),
                isComplete: true
            }
        }

        await this.save(analyticsObject)
    }

    public async prepareOnboardingTracking(analyticsObject: UserAnalyticsObject) {
        analyticsObject.onboardingTracking = {
            onboarding: analyticsObject.onboardingTracking?.onboarding ?? {
                _state: { isComplete: false },
                trainCalendar: {
                    _state: { isComplete: false },
                    setWorkingHours: { isComplete: false },
                    setMeetingIntervals: { isComplete: false },
                    plans: { isComplete: false },
                },
                connectCalendar: { isComplete: false },
                claimName: { isComplete: false },
            },
            productTour: analyticsObject.onboardingTracking?.productTour ?? {
                _state: { isComplete: false },
                profile: { isComplete: false },
                demo: {
                    _state: { isComplete: false },
                    slashKey: { isComplete: false },
                    arrowKeys: { isComplete: false },
                    tabKey: { isComplete: false },
                    enterKey: { isComplete: false },
                    installButton: { isComplete: false }
                }
            }
        }
    }

    protected async save(analyticsObject: UserAnalyticsObject) {
        if (analyticsObject) {
            return this.UserAnalyticsObjectCollection.save(analyticsObject)
        }
        return null
    }
}
