<section class="image-upload-preview-container">
    <div class="image-preview-box">
        <img alt
             [src]="uploadedImageUrl"
             [default]="placeholderImageUrl"
             [class.has-placeholder-image]="placeholderImageUrl"
        />
    </div>

    <div class="controls-box">
        <p class="description">{{ description }}</p>
        <div class="actions-box">
            <app-image-upload
                [buttonText]="uploadedImageUrl ? 'Upload new picture' : 'Upload picture'"
                (onUploaded)="onImageUploaded.next($event)"
            ></app-image-upload>

            <button class="ud-btn ud-btn-secondary btn-medium" mat-button
                    *ngIf="showDeleteButton" (click)="onImageUploaded.next(null)"
            >Delete</button>
        </div>
    </div>
</section>
