<div class="container upload-view__container">
    <ng-container *ngIf="uploadStream | async as upload">
        <div class="upload-view__info-row">
            <div class="name-box">
                <span>{{ upload.fileName }}</span>
            </div>

            <div class="actions-box">
                <ng-container [ngSwitch]="upload.uploadingStatusStream | async">
                    <ng-container *ngSwitchCase="UploadingFileStatus.Paused">
                        <div class="action-item start" (click)="upload.start()"
                             [matTooltip]="'Start'" [matTooltipPosition]="TooltipPosition.TOP"
                        >
                            <i class="material-icons-round">play_arrow</i>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="UploadingFileStatus.Running">
                        <div class="action-item start" (click)="upload.pause()"
                             [matTooltip]="'Pause'" [matTooltipPosition]="TooltipPosition.TOP"
                        >
                            <i class="material-icons-round">pause</i>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="action-item cancel" (click)="upload.cancel()"
                     [matTooltip]="'Cancel'" [matTooltipPosition]="TooltipPosition.TOP"
                >
                    <i class="material-icons-round">close</i>
                </div>
            </div>
        </div>

        <div class="upload-view__progress-bar">
            <progress max="100" [value]="upload.percentLoadedStream | async"></progress>
        </div>
    </ng-container>
</div>
