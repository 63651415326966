import { ApiRoute } from '@undock/api/services/api.route'
import {
    TimelineDirection,
    TimelineEvent,
} from '@undock/api/scopes/time/contracts/timeline-event'

/**
 * @deprecated
 */
export class EventsRoute extends ApiRoute {

    public init() {
        this.route = 'calendar'
    }

    /**
     * @deprecated
     */
    public async getTimelineEventsByDateRange(start: Date, end: Date): Promise<TimelineEvent[]> {
        return this.post<TimelineEvent[]>()
                   .withData({
                       start: start.toISOString(),
                       end: end.toISOString(),
                   })
                   .endpoint(`events/timeline`)
    }

    /**
     * @deprecated
     */
    public async getTimelineEventsChunkFromDate(date: Date, direction: TimelineDirection, pageSize = 20, page = 0): Promise<TimelineEvent[]> {
        return this.post<TimelineEvent[]>()
                   .withData({
                       start : date.toISOString(),
                       page,
                       pageSize,
                       order: direction
                   })
                   .endpoint(`events/timeline`)
    }
}
