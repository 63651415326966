import { Injectable } from '@angular/core'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    compareDeeplyBy,
    Memoize,
} from '@undock/core'
import {
    combineLatestWith,
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
    map
} from 'rxjs/operators'
import { IDelegate } from '@undock/api/scopes/delegate/contracts'
import { Observable } from 'rxjs'

interface UIDelegateData extends IDelegate {
    isActive: boolean
    calendarsCount: number
}

@Injectable({
    providedIn: 'root',
})
export class DelegateStorage {

    @CompleteOnDestroy()
    public readonly areGrantorsLoading$ = new ValueSubject(false)

    @CompleteOnDestroy()
    public readonly triggerGrantorsRefresh$ = new ValueSubject<void>(null)

    @CompleteOnDestroy()
    public readonly assistants$ = new ValueSubject<IDelegate[]>([])

    @CompleteOnDestroy()
    public readonly areAssistantsLoading$ = new ValueSubject<boolean>(true)

    @CompleteOnDestroy()
    public readonly triggerAssistantsRefresh$ = new ValueSubject<void>(null)

    @EmitOnDestroy()
    private destroyEvent = new DestroyEvent()

    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
    ) {
        this.ngOnInit()
    }

    @Memoize()
    public get grantorsCount$(): Observable<number> {
        return this.ownGrantorsData$.pipe(
            map(delegate => {
                return delegate.length
            })
        )
    }

    @Memoize()
    public get ownGrantorsData$(): ReactiveStream<UIDelegateData[]> {
        return new ReactiveStream(
            this.currentUser.dataStream.pipe(
                takeUntil(this.destroyEvent),
                distinctUntilChanged(compareDeeplyBy('_id')),
                // Reload
                combineLatestWith(this.triggerGrantorsRefresh$),
                tap(() => {
                    this.areGrantorsLoading$.next(true)
                }),
                switchMap(() => {
                    return this.api.delegate.ownGrantors.list()
                }),
                map(delegates => {
                    return this.delegateToUIDelegateData(delegates)
                }),
                tap((delegate) => {
                    this.areGrantorsLoading$.next(false)
                }),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    @Memoize()
    public get assistantsData$(): Observable<UIDelegateData[]> {
        return this.assistants$.pipe(
            map(assistants => this.delegateToUIDelegateData(assistants)),
        )
    }

    @Memoize()
    public get assistantUserUIds$(): Observable<string[]> {
        return this.assistants$.pipe(
            map(assistants => {
                return assistants.map(item => item.granteeUId)
            }),
        )
    }

    public async ngOnInit() {
        this.assistants$.next(
            await this.api.delegate.ownGrantees.list(),
        )
        this.areAssistantsLoading$.next(false)
    }

    protected delegateToUIDelegateData(delegates: IDelegate[]): Array<UIDelegateData> {
        return delegates.map(data => {
            let calendarsCount = 0
            if (data?.permissions?.calendars) {
                for (let calendarId in data.permissions.calendars) {
                    if (data.permissions.calendars[calendarId]?.isAccessGranted) {
                        calendarsCount++
                    }
                }
            }

            return {
                ...data,
                isActive: !!calendarsCount,
                calendarsCount: calendarsCount,
            }
        })
    }
}
