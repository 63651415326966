import { ApiRoute } from '@undock/api/services/api.route'
import { TimeSlot } from '@undock/time/availability/contracts/time-slot.interface'
import {
    GroupAvailabilityRequest,
    PlanAvailabilityRequest,
} from '../requests/group-availability.request'
import { AvailabilityResponse } from '@undock/api/scopes/profile/contracts/availability'
import {
    AvailabilityV2Request,
    PlansAvailabilityV2Request,
} from '@undock/api/scopes/profile/contracts/availability-v2.request'
import { AvailabilityV2Response } from '@undock/api/scopes/profile/contracts/availability-v2.response'


export class AvailabilityRoute extends ApiRoute {

    public init() {
        this.route = 'availability'
    }

    public v2(data: AvailabilityV2Request) {
        return this.post<AvailabilityV2Response>()
                   .withData(data)
                   .endpoint(`v2`)
    }

    public plansV2(data: PlansAvailabilityV2Request) {
        return this.post<AvailabilityV2Response>()
                   .withData(data)
                   .endpoint(`plans/v2`)
    }

    /**
     * A temporary solution to prevent double bookings
     */
    public isDateRangeAvailable(
        rangeStart: Date, rangeEnd: Date, emails: string[],
    ): Promise<boolean> {
        return this.post<boolean>()
                   .withData({
                       emails: emails,
                       rangeEnd: rangeEnd.toISOString(),
                       rangeStart: rangeStart.toISOString(),
                   })
                   .endpoint(`is-date-range-available`)
    }

    public getGroupAvailability(
        request: GroupAvailabilityRequest,
    ): Promise<AvailabilityResponse> {
        return this.post<AvailabilityResponse>()
                   .withData({
                       ...request,
                   })
                   .endpoint(`group`)
    }

    /**
     * Will restore availability for provided meeting
     */
    public getRescheduleAvailability(
        meetingId: string, request: GroupAvailabilityRequest,
    ): Promise<AvailabilityResponse> {
        return this.post<AvailabilityResponse>()
                   .withData({
                       ...request, meetingId,
                   })
                   .endpoint(`reschedule`)
    }

    /**
     * Get a user's own availability which takes into account the settings of the command it's being called for and
     * all of the user's other actively running commands
     */
    public getPlanAvailability(
        request: PlanAvailabilityRequest,
    ): Promise<AvailabilityResponse> {
        return this.post<AvailabilityResponse>()
                   .withData({
                       ...request,
                   })
                   .endpoint(`plans`)
    }

    public getPlanAllDayAvailability(
        request: PlanAvailabilityRequest,
    ): Promise<AvailabilityResponse> {
        return this.post<AvailabilityResponse>()
                   .withData({
                       ...request,
                   })
                   .endpoint(`plans/all-day`)
    }


    public getRanksForTimeSlots(request: RankSlotsRequest) {
        return this.post<Record<string, Record<string, any>>>()
                   .withData(request)
                   .endpoint(`calculate-slot-ranks`)
    }
}


export interface RankSlotsRequest {
    slots: TimeSlot[],
    participants: string[],
    timeZone?: string,
    guestCalendars?: any[],
    meetingType?: any,
    bookingCode?: string,
}
