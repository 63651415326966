<div class="selector" cdkOverlayOrigin #trigger="cdkOverlayOrigin" #toggleButton (click)="toggleMenu()">
    <div class="selected-value">
        <ng-container *ngIf="value"><mat-icon class="icon" [svgIcon]="value"></mat-icon></ng-container>
        <ng-container *ngIf="!value"><div class="icon no-icon"></div></ng-container>
    </div>
    <mat-select-trigger class="customArrow"></mat-select-trigger>
</div>

<ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isMenuOpen"
        (overlayOutsideClick)="toggleMenu()"
>
    <div class="modal" #menu>
        <div class="search-block">
            <app-icon-search class="icon"></app-icon-search>
            <input class="search-field"
                   type="text"
                   name="searchText"
                   placeholder="Search..."
                   autocomplete="off"
                   appInputAutoresize
                   [formControl]="searchText"
                   [(ngModel)]="searchTextValue"
                   (ngModelChange)="filterIcons($event)"
                   [class.field-filled]="this.searchTextValue?.length > 0"
                   #SearchInput
            />
        </div>
        <div class="icons-block">
            <div class="icons-title">Icons</div>
            <div class="icons-container">
                <mat-icon class="icon"
                          [ngClass]="{selected: value === icon}"
                          *ngFor="let icon of icons | paginate: { itemsPerPage: 27, currentPage: paginationPage }"
                          (click)="selectIcon(icon)"
                          [svgIcon]="icon"
                ></mat-icon>
            </div>
            <div class="pagination">
                <pagination-controls
                        [autoHide]="false"
                        [directionLinks]="false"
                        [maxSize]="7"
                        (pageChange)="paginationPage = $event"
                ></pagination-controls>
            </div>
        </div>

        <div class="footer" (click)="selectIcon('')">
            <div class="icon no-icon" style="margin-right: 12px;"></div>
            No icon
        </div>
    </div>
</ng-template>

