import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'
import { LayoutStateModel } from '@undock/common/layout/states/layout.state'


@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [],
})
export class LayoutProvider {
    static forRoot(): ModuleWithProviders<LayoutProvider> {
        return {
            ngModule: LayoutProvider,
            providers: [
                SidebarState,
                LayoutStateModel,
            ],
        }
    }
}
