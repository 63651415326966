<ng-container *ngIf="{ slots: displayedSlotsForCurrentDayStream | stream } as currentDay">

    <div class="availability-column-container"
         *ngIf="isAnySlotAvailableForBooking(currentDay.slots) else noItemsOnDayTmpl"
    >
        <ng-container *ngFor="let slot of currentDay.slots">
            <ng-container [ngSwitch]="slot.type">
                <ng-container *ngSwitchCase="'slot'">

                    <ng-container *ngIf="!embedMode">
                        <app-time-available-time-slot-view *ngIf="!($any(slot).isSoftBooked) || isPriorityMode"
                                                           class="__item"
                                                           @insertSlotTrigger
                                                           [slot]="$any(slot)"
                                                           [selected]="isAvailabilitySlotSelected(slot)"
                                                           [targeted]="isAvailabilitySlotTargeted(slot)"
                                                           [suggested]="isAvailabilitySlotSuggested(slot)"
                                                           [best]="isAvailabilitySlotBest(slot)"
                                                           [editable]="isEditorMode"
                                                           [accentColor]="accentColor"
                                                           [accentTextColor]="accentTextColor"
                                                           [themeColor]="themeColor"
                                                           (click)="selectSlot(slot)"
                        ></app-time-available-time-slot-view>
                    </ng-container>

                </ng-container>

                <ng-container *ngSwitchCase="'event'">
                    <app-time-upcoming-event-slot-view
                            class="__item"
                            @insertSlotTrigger
                            [slot]="$any(slot)"
                            [timezone]="selectedTimezone"
                            [selected]="isAvailabilitySlotSelected(slot)"
                            [editable]="isEditorMode"
                    ></app-time-upcoming-event-slot-view>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <ng-template #noItemsOnDayTmpl>
        <div class="availability-column-container __empty" @insertSlotTrigger>
            <app-icon-large-calendar-no-time [width]="'140'" [height]="'140'"></app-icon-large-calendar-no-time>
            <ng-container *ngIf="isLoggedIn | stream; else compareCalendar">
                <span>No time available</span>
            </ng-container>
            <ng-template #compareCalendar>
                <span class="compare-calendar">
                    See when you’re both available<br />
                    by <a (click)="onCompareCalendarClick()">comparing your calendar</a></span>
            </ng-template>
        </div>
    </ng-template>

</ng-container>

