import {
    Directive,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core'
import { environment } from '@web/environments/environment'

@Directive({
    selector: '[ifProduction]'
})
export class ProductionModeDirective implements OnInit {
    isShow = environment.production
    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }
    ngOnInit() {
        if (this.isShow) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector: '[ifStage]'
})
export class StageModeDirective extends ProductionModeDirective {
    isShow = !environment.production
}


