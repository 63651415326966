import { ApiRoute } from '@undock/api/services/api.route'
import {
    Plan,
    PlanUpdateDTO,
    PlanUpdateRecurrenceDTO,
} from '@undock/time/plans/contracts/plan.interface'
import { TimeSlot } from '@undock/time/availability/contracts/time-slot.interface'


export class OwnPlansRoute extends ApiRoute {

    public init() {
        this.route = 'plans/own'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public async listAll(): Promise<Plan[]> {
        const data = await this.get<Plan[]>()
                               .endpoint(``)

        return data ?? []
    }

    public create(properties = {}): Promise<Plan> {
        return this.post<Plan>()
                   .withData({ ...properties })
                   .endpoint('')
    }

    public getById(planId: string): Promise<Plan> {
        return this.get<Plan>()
                   .endpoint(`${planId}`)
    }

    public getInstanceDatesByPlanId(planId: string): Promise<Date[]> {
        return this.get<Date[]>()
                   .endpoint(`instances/${planId}`)
    }

    public update(planId: string, properties: PlanUpdateDTO = {}): Promise<Plan> {
        return this.patch<Plan>()
                   .withData({ ...properties })
                   .endpoint(`${planId}`)
    }

    public updateRecurrence(planId: string, properties: PlanUpdateRecurrenceDTO): Promise<Plan> {
        return this.patch<Plan>()
                   .withData({ ...properties })
                   .endpoint(`recurrence/${planId}`)
    }

    public updateRecurrenceCount(planId: string, properties: PlanUpdateRecurrenceDTO): Promise<Plan> {
        return this.patch<Plan>()
                   .withData({ ...properties })
                   .endpoint(`recurrence-count/${planId}`)
    }

    public deleteById(planId: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(`${planId}`)
    }

    public run(planId: string, slots: TimeSlot[], startDate?: Date, excludeToday: boolean = false, runAsSimulation = false): Promise<Plan> {
        return this.post<Plan>()
                   .withData({ slots, startDate, excludeToday, isSimulation: runAsSimulation })
                   .endpoint(`run/${planId}`)
    }

    public stop(planId: string):Promise<Plan> {
        return this.post<Plan>()
                   .endpoint(`stop/${planId}`)
    }

    public pause(planId: string, dates: string[]):Promise<Plan> {
        return this.post<Plan>()
                   .withData({ dates })
                   .endpoint(`pause/${planId}`)
    }

}
