import { Injectable } from '@angular/core'

import { Api } from '@undock/api'


@Injectable({
    providedIn: 'root',
})
export class PrivacyManager {

    public static readonly PRIVACY_UNLOCK_URL_PARAM = 'p'

    public constructor(
        private api: Api,
    ) {}

    /**
     * Generates a privacy unlock code for current user
     *
     * @warning This method will return empty string for anonymous
     */
    public async createPrivacyUnlockCode(): Promise<string> {
        return this.api.profile.privacy.getPrivateProfileAccessCode()
    }

    /**
     * Invalidates privacy unlock code
     *
     * @warning Method will be removed after users ACL implementation
     */
    public async destroyPrivacyUnlockCode(code: string): Promise<void> {
        return this.api.profile.privacy.destroyPrivacyUnlockCode(code)
    }

    /**
     * Returns - is given privacy unlock `code` is valid for the user with provided `email`
     */
    public async isPrivacyUnlockCodeValid(code: string, email: string): Promise<boolean> {
        return this.api.profile.privacy.isProfileAccessCodeValid(code, email)
    }
}

