import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-lock-medieval-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.94133 2.45021L7.99995 2.45023L8.05857 2.45021C8.3727 2.45009 8.61489 2.44999 8.83015 2.48408C10.0069 2.67045 10.9297 3.59333 11.1161 4.77003C11.1502 4.98529 11.1501 5.22748 11.15 5.54161L11.15 5.60023V5.84954C12.1231 6.29076 12.8 7.2707 12.8 8.40874V9.60022C12.8 12.2512 10.6509 14.4002 7.99995 14.4002C5.34898 14.4002 3.19995 12.2512 3.19995 9.60022V8.40874C3.19995 7.2707 3.87683 6.29076 4.84995 5.84955V5.60023L4.84994 5.54161V5.54161C4.84981 5.22748 4.84971 4.98529 4.88381 4.77003C5.07018 3.59333 5.99305 2.67045 7.16976 2.48408C7.38502 2.44999 7.6272 2.45009 7.94133 2.45021ZM9.63456 5.00468C9.64762 5.08715 9.64995 5.19996 9.64995 5.60023L6.34995 5.60022C6.34995 5.19996 6.35228 5.08715 6.36534 5.00468C6.45006 4.46982 6.86954 4.05033 7.40441 3.96562C7.48688 3.95255 7.59969 3.95023 7.99995 3.95023C8.40021 3.95023 8.51302 3.95255 8.59549 3.96562C9.13036 4.05033 9.54985 4.46982 9.63456 5.00468ZM8.74995 9.33356C8.74995 8.91935 8.41416 8.58356 7.99995 8.58356C7.58574 8.58356 7.24995 8.91935 7.24995 9.33356V10.6669C7.24995 11.0811 7.58574 11.4169 7.99995 11.4169C8.41416 11.4169 8.74995 11.0811 8.74995 10.6669V9.33356Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class LockMedievalIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 16
    @Input() height: string | number  = 16
    @Input() color: string = '#BD970F'
}
