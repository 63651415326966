<ng-container *ngIf="widget">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <rect width="40" height="40" rx="10" fill="#FBFBFD"/>
        <g opacity="0.5" filter="url(#filter0_f_1736_38017)">
            <rect x="13" y="19" width="14" height="4" fill="#2B2733"/>
        </g>
        <rect x="10" y="9" width="20" height="14" rx="2" fill="#DFC051"/>
        <circle cx="12.5" cy="28.5" r="2.5" fill="#2B2733"/>
        <defs>
            <filter id="filter0_f_1736_38017" x="6" y="12" width="28" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur_1736_38017"/>
            </filter>
        </defs>
    </svg>
</ng-container>

<ng-container *ngIf="modal">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <rect width="40" height="40" rx="10" fill="#FBFBFD"/>
        <g opacity="0.5" filter="url(#filter0_f_4363_24586)">
            <rect x="13" y="25" width="14" height="4" fill="#2B2733"/>
        </g>
        <rect x="9" y="16" width="22" height="13" rx="2" fill="#DFC051"/>
        <rect x="18" y="10" width="13" height="3" rx="1.5" fill="#2B2733"/>
        <rect x="9" y="10" width="6" height="3" rx="1.5" fill="#2B2733"/>
        <defs>
            <filter id="filter0_f_4363_24586" x="6" y="18" width="28" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur_4363_24586"/>
            </filter>
        </defs>
    </svg>
</ng-container>

<ng-container *ngIf="inline">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <rect width="40" height="40" rx="10" fill="#FBFBFD"/>
        <g opacity="0.5" filter="url(#filter0_f_4363_24600)">
            <rect x="13" y="23" width="14" height="4" fill="#2B2733"/>
        </g>
        <rect x="9" y="13" width="22" height="14" rx="2" fill="#DFC051"/>
        <rect x="31" y="10" width="3" height="3" rx="1.5" fill="#2B2733"/>
        <defs>
            <filter id="filter0_f_4363_24600" x="6" y="16" width="28" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur_4363_24600"/>
            </filter>
        </defs>
    </svg>
</ng-container>
