<section class="scroll-wrapper">
    <h2 class="participant-popup-title">participants</h2>

    <div *ngFor="let participant of participants" class="participant-popup-item">
        <div class="participant-popup-item__body">
            <ng-container
                    *ngIf="participant.isUndockUser; else profileDoesNotExistsTmpl"
            >
                <div class="participant-popup-item__icon">
                    <img default="/assets/img/placeholders/user.png"
                         [src]="participant.userData.imageUrl" [alt]="participant.userData.email"
                    />

                    <ng-container *ngIf="isParticipantDenied(participant)">
                        <div class="participant-status denied">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                 fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M2.46967 2.46967C2.76256 2.17678 3.23744 2.17678 3.53033 2.46967L6 4.93934L8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967C9.82322 2.76256 9.82322 3.23744 9.53033 3.53033L7.06066 6L9.53033 8.46967C9.82322 8.76256 9.82322 9.23744 9.53033 9.53033C9.23744 9.82322 8.76256 9.82322 8.46967 9.53033L6 7.06066L3.53033 9.53033C3.23744 9.82322 2.76256 9.82322 2.46967 9.53033C2.17678 9.23744 2.17678 8.76256 2.46967 8.46967L4.93934 6L2.46967 3.53033C2.17678 3.23744 2.17678 2.76256 2.46967 2.46967Z"
                                      fill="white"/>
                            </svg>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isParticipantAccepted(participant)">
                        <div class="participant-status accepted">
                            <span class="material-icons">check</span>
                        </div>
                    </ng-container>
                </div>

                <div class="participant-popup-item__user-info">
                    <span>{{ participant.fullname }}</span>
                    <span class="user-email">{{ participant.emailAddress || participant.email }}</span>
                </div>
            </ng-container>

            <ng-template #profileDoesNotExistsTmpl>
                <div class="participant-popup-item__icon">
                    <img src="/assets/img/placeholders/user.png"
                         [alt]="participant.emailAddress || participant.email"
                    />

                    <ng-container *ngIf="isParticipantDenied(participant)">
                        <div class="participant-status denied">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                 fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M2.46967 2.46967C2.76256 2.17678 3.23744 2.17678 3.53033 2.46967L6 4.93934L8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967C9.82322 2.76256 9.82322 3.23744 9.53033 3.53033L7.06066 6L9.53033 8.46967C9.82322 8.76256 9.82322 9.23744 9.53033 9.53033C9.23744 9.82322 8.76256 9.82322 8.46967 9.53033L6 7.06066L3.53033 9.53033C3.23744 9.82322 2.76256 9.82322 2.46967 9.53033C2.17678 9.23744 2.17678 8.76256 2.46967 8.46967L4.93934 6L2.46967 3.53033C2.17678 3.23744 2.17678 2.76256 2.46967 2.46967Z"
                                      fill="white"/>
                            </svg>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isParticipantAccepted(participant)">
                        <div class="participant-status accepted">
                            <span class="material-icons">check</span>
                        </div>
                    </ng-container>
                </div>

                <div class="participant-popup-item__user-info">
                    <span>{{ participant.emailAddress || participant.email }}</span>
                </div>
            </ng-template>

            <button mat-button class="participant-popup-item__more-button">
                <span class="material-icons">more_horiz</span>
            </button>
        </div>
    </div>
</section>
