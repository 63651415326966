import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core'

@Component({
  selector: 'app-icon-settings-gear',
  templateUrl: 'gear-icon.component.html',
  styleUrls: ['gear-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsGearIconComponent extends IconTemplateComponent {}
