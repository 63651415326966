import {
    Injectable,
    Optional,
    Type,
} from '@angular/core'

import * as m from 'moment'

import {
    AbstractEvent,
    AbstractEventListener,
    AppEventsDispatcher,
    ExtConnector,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import { DockCreatedEvent } from '@undock/dock/meet/events'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'


@Injectable()
export class MeetDockCreatedListener extends AbstractEventListener {

    public readonly sortOrder = 1

    public constructor(
        protected api: Api,
        protected currentUser: CurrentUser,
        protected extConnector: ExtConnector,
        protected eventsManager: AppEventsDispatcher,
        @Optional()
        protected calendarEventsStorage: CalendarEventsStorage,
    ) {
        super(eventsManager, MeetDockCreatedListener)
    }

    public getEventClass(): Type<AbstractEvent> {
        return DockCreatedEvent
    }

    public async handle(event: DockCreatedEvent): Promise<void> {
        await Promise.all([
            this.removeReservedSlots(event),
            this.refreshCalendarEventsStorage(event),
        ])
    }

    protected async removeReservedSlots(event: DockCreatedEvent) {
        try {
            if (event.dock?.meetingCode) {
                /**
                 * This timestamp is used to remove the reserved slots for this time after the meeting being created
                 */
                let timestamp = event.dock?.date.start ? (
                    event.dock?.date.start instanceof Date ? event.dock?.date.start.toISOString() : event.dock?.date.start as string
                ) : null

                /**
                 * Cleanup reserved slots after the meeting been confirmed
                 */
                await this.api.time.reservedSlots
                          .removeReservedSlotsByMeetingCode(event.dock.meetingCode, timestamp)

                /**
                 * Update User/reserved slot list in extension
                 */
                this.extConnector.refreshUserInExt((await this.currentUser.data)?.email)
            }
        } catch (error) {
            console.error(error)
        }
    }

    protected async refreshCalendarEventsStorage(event: DockCreatedEvent) {
        try {
            if (event.dock.date) {
                if (this.calendarEventsStorage) {
                    await this.calendarEventsStorage.getEventsForDateRange({
                        start: m(event.dock.date.start).startOf('day').toDate(),
                        end: m(event.dock.date.end).endOf('day').toDate(),
                    })
                }
            }
        } catch (error) {
            console.error(error)
        }
    }
}

