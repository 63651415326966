import {
    Directive,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core'

import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { ProfilesProvider } from '@undock/user'
import { fadeOutTimeout } from '../shared-animations/fade.animation'
import { DockNotification } from '@undock/dock/meet/services/dock-notifications.manager'


@Directive()
export class AbstractNotificationComponent<T = any> implements OnInit {

    @Input() isConfMode: boolean = false
    @Input() notification: DockNotification<T>

    @Output() readonly onDestroyed = new EventEmitter<void>()

    @CompleteOnDestroy()
    public readonly isVisibleSubject = new ValueSubject(true)

    public constructor(
        public profilesProvider: ProfilesProvider,
    ) {}

    public ngOnInit() {
        setTimeout(() => {
            this.isVisibleSubject.next(false)
        }, this.notification.notificationTtl - fadeOutTimeout)
    }

    protected destroy() {
        this.isVisibleSubject.next(false)

        setTimeout(() => {
            this.onDestroyed.emit()
        }, fadeOutTimeout)
    }
}
