<header
    *ngIf="{
       event: state.calendarEvent | stream,
       isEditMode: state.isEditMode | stream,
       isEditingAllowed: state.isEditingAllowed | stream
    } as view"
>
    <div class="header-title" [ngSwitch]="view.isEditMode | boolean">
        <ng-container *ngSwitchCase="true">
            <span class="event-title">
                {{ view.event.meta.payload.isDraft ? 'New Event' : 'Edit Event' }}
            </span>
        </ng-container>

        <ng-container *ngSwitchCase="false">
            <ng-container *ngIf="isNext">
                <span class="event-title">Starts {{timerText}}</span>
            </ng-container>

            <ng-container *ngIf="isCurrent">
                <span class="event-title">Started {{timerText}}</span>
            </ng-container>
        </ng-container>
    </div>

    <div class="header-actions-box">
        <ng-container *ngIf="!view.event.meta.payload.isDraft">
            <div class="event-options-button">
                <app-dashboard-plain-event-options
                        [event]="view.event.meta.payload"
                        [hideRsvp]="true"
                        [hideReschedule]="true"
                        [iconTemplate]="optionsButtonTmpl"
                        (onAction)="onOptionsAction($event)"
                ></app-dashboard-plain-event-options>

                <ng-template #optionsButtonTmpl>
                    <span class="ud-icon icon-more-vert"></span>
                </ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="view.isEditMode">
            <span class="clickable" (click)="navigateToStandaloneEditPage()">
                <app-expand-element [color]="'var(--icon-color)'"></app-expand-element>
            </span>
        </ng-container>

        <ng-container *ngIf="!view.isEditMode">
            <span class="ud-icon icon-edit" [class.disabled]="!view.isEditingAllowed"
                  (click)="enterEditMode()"
            ></span>
        </ng-container>

        <div class="close-button">
            <span class="ud-icon icon-close" (click)="requestClose()"></span>
        </div>
    </div>
</header>

<app-dashboard-calendar-event-details
        [isFullSizeMode]="true"
></app-dashboard-calendar-event-details>
