import {
    Input,
    Component,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


@Component({
    selector: 'app-event-slot-edit-button',
    templateUrl: 'event-slot-edit-button.component.html',
    styleUrls: ['event-slot-edit-button.component.scss'],
})
export class EventSlotEditButtonComponent {

    @CompleteOnDestroy()
    private readonly slotSubject = new StatefulSubject<AvailabilitySlot>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    @Input()
    public set slot(value: AvailabilitySlot) {
        if (value) {
            this.slotSubject.next(value)
        }
    }
}
