import 'firebase/auth'
import 'firebase/firestore'
import firebase from 'firebase/app'

import { Config } from '@undock/core'

let app: firebase.app.App = null

const FORCE_USE_LONG_POLLING = true
const ENABLE_FIREBASE_PERSISTENCE = true
const ENABLE_FIREBASE_LONG_POLLING = false

export function FirebaseAppFactory(config: Config) {
    if (!app) {
        app = firebase.initializeApp(config.firebaseConfig)

        try {
            app.firestore().settings({
                merge: true,
                // useFetchStreams: false,
                experimentalForceLongPolling: FORCE_USE_LONG_POLLING,
                // experimentalAutoDetectLongPolling: ENABLE_FIREBASE_LONG_POLLING,
            })

            if (ENABLE_FIREBASE_PERSISTENCE) {
                app.firestore().enablePersistence({ synchronizeTabs: true }).catch(function(err) {
                    if (err.code == 'failed-precondition') {
                        // Multiple tabs open, persistence can only be enabled
                        // in one tab at a a time.
                        // ...
                        console.log(err.code)
                    } else if (err.code == 'unimplemented') {
                        // The current browser does not support all of the
                        // features required to enable persistence
                        // ...
                        console.log(err.code)
                    }
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return app
}
