import {
    Pipe,
    PipeTransform
} from '@angular/core'

import { timeZoneToAbbreviationMap } from '@undock/core/utils/timezone-data-model';

import { default as moment, Moment } from 'moment'
import { default as timezone } from 'moment-timezone';


@Pipe({ name: 'momentTimeZone', pure: true })
export class MomentTimeZonePipe implements PipeTransform {

    private readonly dayDisplayFormats = {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd, MMM D',
        lastDay: '[Yesterday]',
        sameElse: 'ddd, MMM D'
    }

    public transform(date: moment.MomentInput, targetTimeZone: string, format?: string, keepLocalTime: boolean = false): string {

        if (date) {
            let convertedDate = timezone(date).tz(targetTimeZone, keepLocalTime);
            if (convertedDate) {
                if (format) {
                    if (format === 'z') {
                        let label = convertedDate.format(format);
                        if (!isNaN(parseInt(label))) {
                            return timeZoneToAbbreviationMap[targetTimeZone];
                        }
                        else {
                            return label;
                        }
                    }
                    else {
                        return convertedDate.format(format);
                    }
                }
                else {
                    return convertedDate.calendar(null, this.dayDisplayFormats);
                }
            }
        }
        return '';
    }
}
