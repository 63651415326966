import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-individual-broadcast',
    templateUrl: 'individual-broadcast.component.html',
    styleUrls: ['individual-broadcast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualBroadcastIconComponent {
    @Input() height: number|string = '20'
    @Input() width: number|string = '20'
    @Input() color: string = '#2B2733'
}
