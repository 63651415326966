import { ApiRoute } from '@undock/api/services/api.route'


export class RequestsRoute extends ApiRoute {

    public init() {
        this.route = 'meetings/requests'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public acceptFromEvent(
        eventId: string, targetAttendeeEmail?: string,
    ) {
        return this.post<void>()
                   .endpoint(
                       targetAttendeeEmail ?
                           `accept-from-event/${eventId}/${targetAttendeeEmail}`
                           : `accept-from-event/${eventId}`
                   )
    }

    public declineFromEvent(
        eventId: string, targetAttendeeEmail?: string,
    ) {
        return this.post<void>()
                   .endpoint(
                       targetAttendeeEmail ?
                           `decline-from-event/${eventId}/${targetAttendeeEmail}`
                           : `decline-from-event/${eventId}`
                   )
    }
}

export interface RespondToExternalMeetingData {
    meetingId: string
    provider?: string
    externalCalendarEmail?: string
    participants: any[]
}
