import {
  Component,
  Input,
  TemplateRef,
} from '@angular/core'
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface TemplateModel {
  contentTemplate: TemplateRef<any>,
  onClose?: any
}

@Component({
  selector: 'app-parent-dialog-modal',
  templateUrl: './parent-dialog-modal.component.html',
  styleUrls: ['./parent-dialog-modal.component.scss']
})
export class ParentDialogModalComponent extends SimpleModalComponent<TemplateModel, null> implements TemplateModel {
  contentTemplate: TemplateRef<any>
}
