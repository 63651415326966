import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'


@Component({
    selector: 'app-auth-empty-state-icon',
    templateUrl: 'auth-empty-state-icon.component.html',
    styleUrls: ['auth-empty-state-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthEmptyStateIconComponent {}
