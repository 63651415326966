import { ApiRoute } from '@undock/api/services/api.route'


export class GuestRoute extends ApiRoute {
    public init() {
        this.route = 'user/guest'
    }

    /**
     * Works only for anonymous users
     */
    public async createGuestAccount(data: CreateGuestAccountRequest): Promise<boolean> {
        /**
         * Parsing firstName and lastName if displayName been provided
         */
        if (data.displayName) {
            const regExp = /(\w+)\s+(\w+)/
            if (data.displayName.search(regExp) >= 0) {
                const match = data.displayName.match(regExp)
                data.lastName = match[2] || ''
                data.firstName = match[1] || ''
            } else {
                /**
                 * Ensure firstName is filled okay
                 */
                data.firstName = data.firstName || data.displayName
            }
        }

        return this.post<boolean>()
                   .withData(data)
                   .endpoint(`create-guest-profile`)
    }
}

export interface CreateGuestAccountRequest {
    lastName?: string
    firstName?: string
    displayName?: string
    imageUrl?: string
    email?: string
}
