import {
    NgModule,
    ModuleWithProviders,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'
import { UpgradePlanBannerComponent } from '@undock/feature-plans/ui/components'
import { FeaturePlansManager } from '@undock/feature-plans/services/feature-plans.manager'


/**
 * ----------------------- Providers --------------------------
 */

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        UiKitModule,
    ],
    exports: [
        UpgradePlanBannerComponent,
    ],
    declarations: [
        UpgradePlanBannerComponent,
    ],
})
export class FeaturePlansProvider {

    static forRoot(): ModuleWithProviders<FeaturePlansProvider> {
        return {
            ngModule: FeaturePlansProvider,
            providers: [],
        }
    }

    public constructor(
        /**
         * Early initialization of provider
         */
        private readonly featurePlansManager: FeaturePlansManager,
    ) {}
}
