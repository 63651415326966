<div class="container topics-container">
    <ng-container *ngIf="showTitle">
        <span class="topics-container__description-title">topics</span>
    </ng-container>

    <div class="topics-list" cdkDropList (cdkDropListDropped)="onCdkDropEvent($event)">
        <app-meet-topic-view
                *ngFor="let topic of topicsStream | stream; trackBy: topicsTrackByFn" [topic]="topic"
                cdkDrag [cdkDragDisabled]="!(isEditModeStream | stream)" cdkDragBoundary=".topics-list"
        >
        </app-meet-topic-view>
    </div>

    <div class="topics-container__create-topic-wrapper" *ngIf="isRegularUserStream | stream">
        <app-meet-topic-form
                [showCheck]="showFormCheckbox" (onTopicAdded)="createTopic($event)"
        ></app-meet-topic-form>
    </div>
</div>
