import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
} from '@angular/core'
import {
    NavigationEnd,
    NavigationStart,
    Router,
} from '@angular/router'

import {
    DestroyEvent,
    EmitOnDestroy,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { takeUntil } from 'rxjs/operators'
import { OverlayStyle } from '@undock/common/ui-kit/ui/components'


@Component({
    selector: 'app-navigation-loader-overlay',
    templateUrl: 'navigation-loader-overlay.component.html',
    styleUrls: [`navigation-loader-overlay.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationLoaderOverlayComponent implements OnInit {

    public readonly OverlayStyle = OverlayStyle

    @CompleteOnDestroy()
    public readonly isDisplayedSubject = new ValueSubject(false)

    @EmitOnDestroy()
    private destroyEvent = new DestroyEvent()

    public constructor(
        private router: Router,
    ) {}

    public ngOnInit() {
        let subscription = this.router.events.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe((event) => {
            switch (event.constructor) {
                case NavigationEnd:
                    this.hide()
                    /**
                     * Preventing getting further events
                     */
                    return subscription.unsubscribe()

                case NavigationStart:
                    return this.show()
            }
        })
    }

    public show() {
        return this.isDisplayedSubject.next(true)
    }

    public hide() {
        return this.isDisplayedSubject.next(false)
    }
}
