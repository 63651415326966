import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-feature',
    templateUrl: 'feature.component.html',
    styleUrls: ['feature.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureIconComponent extends IconTemplateComponent {}
