import {
    inject,
    Injectable,
} from '@angular/core'

import { Model } from '@undock/core'
import { Topic } from '@undock/dock/meet/models/topic.model'
import { CurrentUser } from '@undock/session'
import { AbstractFactory } from '@undock/core/models/factories/abstract.factory'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'

@Injectable()
export class TopicFactory extends AbstractFactory<Topic> {

    protected readonly ormOnFireContext = inject(OrmOnFireContext)

    protected defaultProperties: Partial<Topic> = {
        text: '',
        removed: false,
        position: 99999,
        complete: false,
    }

    public constructor(
        private currentUser: CurrentUser,
    ) { super() }

    public async create(model: Model, properties: Partial<Topic>): Promise<Topic> {
        let topic = new Topic()

        this.assignDefaultData(topic, properties)

        topic.authorData = await this.currentUser.publicData

        if (!this.currentUser.isAnonymous) {
            const author = await this.currentUser.firestoreProfile
            topic.authorId = author.id
            await this.ormOnFireContext.linkReference(topic.author, author)
        } else {
            /**
             * For anonymous users
             * TODO: Do we should write guest author id to the entity?
             */
            topic.authorId = await this.currentUser.id
        }

        topic.dockId = model.id
        await this.ormOnFireContext.linkReference(topic.dock, model)

        topic.isInitialized = true

        return topic
    }
}
