<div class="text-switch-toggle-box" [style.height]="height">
    <!--<div class="text-switch-toggle left-toggle"
         (click)="emitValue('left')"
         [class.value-selected]="value === leftValue"
    >
        <span>Remote</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10 3L9.92684 3C8.10338 2.99997 6.63362 2.99995 5.47767 3.15537C4.27752 3.31672 3.26702 3.66191 2.46447 4.46447C1.66191 5.26702 1.31672 6.27752 1.15536 7.47767C0.999949 8.63362 0.999972 10.1034 0.999999 11.9268L1 12L0.999999 12.0732C0.999972 13.8966 0.999949 15.3664 1.15536 16.5223C1.31672 17.7225 1.66191 18.733 2.46447 19.5355C3.26702 20.3381 4.27752 20.6833 5.47767 20.8446C6.63363 21.0001 8.1034 21 9.92686 21L10 21L10.0731 21C11.8966 21 13.3664 21.0001 14.5223 20.8446C15.7225 20.6833 16.733 20.3381 17.5355 19.5355C18.3381 18.733 18.6833 17.7225 18.8446 16.5223C18.8795 16.2628 18.9066 15.9874 18.9276 15.6957C20.2393 16.6788 20.9115 17.1623 21.4472 16.8944C22 16.618 22 15.7454 22 14L22 9.99999C22 8.25461 22 7.38191 21.4472 7.10552C20.9115 6.83768 20.2393 7.32112 18.9276 8.30432C18.9066 8.01265 18.8795 7.73725 18.8446 7.47767C18.6833 6.27752 18.3381 5.26702 17.5355 4.46447C16.733 3.66191 15.7225 3.31672 14.5223 3.15537C13.3664 2.99995 11.8966 2.99997 10.0732 3L10 3ZM3.87868 5.87868C4.2477 5.50967 4.76579 5.26907 5.74416 5.13753C6.75129 5.00213 8.08611 5 10 5C11.9139 5 13.2487 5.00213 14.2558 5.13753C15.2342 5.26907 15.7523 5.50967 16.1213 5.87868C16.4903 6.2477 16.7309 6.7658 16.8625 7.74416C16.9979 8.7513 17 10.0861 17 12C17 13.9139 16.9979 15.2487 16.8625 16.2558C16.7309 17.2342 16.4903 17.7523 16.1213 18.1213C15.7523 18.4903 15.2342 18.7309 14.2558 18.8625C13.2487 18.9979 11.9139 19 10 19C8.08611 19 6.75129 18.9979 5.74416 18.8625C4.76579 18.7309 4.2477 18.4903 3.87868 18.1213C3.50966 17.7523 3.26907 17.2342 3.13753 16.2558C3.00212 15.2487 3 13.9139 3 12C3 10.0861 3.00212 8.7513 3.13753 7.74416C3.26907 6.7658 3.50966 6.2477 3.87868 5.87868Z"
                  fill="#A59FB9"/>
        </svg>
    </div>
    <div class="text-switch-toggle right-toggle"
         (click)="emitValue('right')"
         [class.value-selected]="value === rightValue"
    >
        <span>In Person</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5 10.1482C5 7.69712 5.89314 5.93541 7.17115 4.77835C8.46619 3.60588 10.2181 3 12 3C13.7819 3 15.5338 3.6059 16.8288 4.77837C18.1069 5.93543 19 7.69715 19 10.1482C19 13.3235 17.0905 16.1249 15.0896 18.1701C14.3554 18.9205 13.9107 19.3671 13.4632 19.6539C13.0761 19.902 12.6698 20.038 12.0002 20.038C11.3306 20.038 10.9243 19.9021 10.5372 19.654C10.0897 19.3672 9.64499 18.9206 8.91076 18.1702C6.90964 16.125 5 13.3236 5 10.1482ZM21 10.1482C21 7.16712 19.8931 4.85474 18.1712 3.29574C16.4662 1.75215 14.2181 1.00001 12 1C9.78191 0.999994 7.53381 1.75213 5.82885 3.29572C4.10686 4.85472 3 7.1671 3 10.1482C3 14.1122 5.356 17.3969 7.48122 19.5689L7.53987 19.6289L7.53989 19.6289C8.19707 20.3007 8.79727 20.9144 9.45805 21.3378C10.1988 21.8126 10.9874 22.038 12.0002 22.038C13.0131 22.038 13.8017 21.8125 14.5424 21.3378C15.2032 20.9143 15.8034 20.3006 16.4605 19.6287L16.5192 19.5688C18.6442 17.3967 21 14.1121 21 10.1482ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34318 13.6569 7.00003 12 7.00003C10.3431 7.00003 9 8.34318 9 10C9 11.6569 10.3431 13 12 13Z"
                  fill="#A59FB9"/>
        </svg>
    </div>-->

        <mat-select value="left" udInput class="customArrow ">
            <!--<mat-select-trigger>
                <ng-container [ngSwitch]="value">
                    <ng-container *ngSwitchCase="leftValue">
                        <app-icon-remote></app-icon-remote>
                    </ng-container>

                    <ng-container *ngSwitchCase="rightValue">
                        <app-icon-in-person></app-icon-in-person>
                    </ng-container>
                </ng-container>
            </mat-select-trigger>-->

            <mat-option value="left" (click)="emitValue('left')">
                <!--<mat-icon>
                    <app-icon-remote></app-icon-remote>
                </mat-icon>-->
                Remote
            </mat-option>

            <mat-option value="right" (click)="emitValue('right')">
                <!--<mat-icon>
                    <app-icon-in-person></app-icon-in-person>
                </mat-icon>-->
                In person
            </mat-option>
        </mat-select>

</div>


