<ng-container [ngSwitch]="isInitializedStream | stream">
    <ng-container *ngSwitchCase="true">
        <header class="fake-header">
            <app-logo-undock></app-logo-undock>
        </header>

        <div class="container email-is-not-verified-container">
            <app-auth-empty-state-icon></app-auth-empty-state-icon>

            <div class="title">Login ID Not Confirmed</div>

            <p>Please check your inbox for {{targetEmailStream | stream}} to confirm your login.</p>

            <ng-container *ngIf="{
                nextEmailAllowedIn: nextConfirmationEmailAllowedIn | stream
            } as values">
                <a class="clickable"
                   [class.disabled]="values.nextEmailAllowedIn > 0"
                   (click)="sendVerificationEmail()"
                >
                    Resend verification
                </a>

                <span *ngIf="values.nextEmailAllowedIn > 0">
                    Next verification email is allowed in {{values.nextEmailAllowedIn}} seconds.
                </span>
            </ng-container>

            <a class="logout clickable" (click)="logout()">
                Log out
            </a>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <app-loading-overlay></app-loading-overlay>
    </ng-container>
</ng-container>
