import {
    Input,
    Output,
    Component,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core'

import { MomentRange } from '@undock/time/availability'
import { default as m, Moment } from 'moment'


@Component({
    selector: 'app-date-range-picker',
    templateUrl: 'date-range-picker.component.html',
    styleUrls: ['date-range-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent {

    @Output() onRangeChanged = new EventEmitter<MomentRange>()

    @Input() rangeStartFormat: string = 'MMMM D'
    @Input() rangeEndFormat: string = this.rangeStartFormat

    protected _rangeEnd: Moment
    protected _rangeStart: Moment

    @Input() set rangeEnd(value: Moment | Date | string) {
        this._rangeEnd = this.ensureMoment(value)
    }

    @Input() set rangeStart(value: Moment | Date | string) {
        this._rangeStart = this.ensureMoment(value)
    }

    public get rangeEnd(): Moment {
        return this._rangeEnd
    }

    public get rangeStart(): Moment {
        return this._rangeStart
    }

    public async onEndDateChanged(value: Moment | Date | string) {
        /**
         * Convert value to the correct type and day period
         */
        value = this.ensureMoment(value).endOf('day')

        /**
         * If start equal or after then end
         */
        if (this._rangeStart.isBefore(value, 'day')) {
            this._rangeEnd = value.clone()
        } else {
            const diffInDays = this._rangeEnd.diff(this._rangeStart, 'days')

            this._rangeEnd = value.clone()
            this._rangeStart = value.clone().add(-diffInDays, 'days')

            /**
             * Additional check to prevent selecting past date
             */
            const todayMoment = m().startOf('day')
            if (this._rangeStart.isBefore(m(), 'day')) {
                this._rangeStart = todayMoment
            }
        }

        this.onRangeChanged.next({ start: this._rangeStart, end: this._rangeEnd })
    }

    public async onStartDateChanged(value: Moment | Date | string) {
        /**
         * Convert value to the correct type and day period
         */
        value = this.ensureMoment(value).startOf('day')

        /**
         * If start equal or after then end
         */
        if (this._rangeEnd.isAfter(value, 'day')) {
            this._rangeStart = value.clone()
        } else {
            const diffInDays = this._rangeEnd.diff(this._rangeStart, 'days')

            this._rangeStart = value.clone()
            this._rangeEnd = value.clone().add(diffInDays, 'days')
        }

        this.onRangeChanged.next({ start: this._rangeStart, end: this._rangeEnd })
    }

    protected ensureMoment(value: Moment | Date | string): Moment {
        return m.isMoment(value) ? value : m(value)
    }
}
