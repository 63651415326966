import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Injectable,
    Input,
    Output,
    ViewChild,
} from '@angular/core'
import { isArray } from 'lodash-es'

import {
    combineLatest,
    from,
    fromEvent,
    Observable,
    tap,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    compareDeeply,
    Memoize,
    Validations,
} from '@undock/core'
import { Api } from '@undock/api'
import {
    FirestoreUser,
} from '@undock/user'
import { CurrentUser } from '@undock/session'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import {
    IChannel,
    IChannelAggregate,
} from '@undock/api/scopes/organizations/contracts'
import { OrganizationsStorage } from '@undock/organizations/services/organizations.storage'


export abstract class UserSearchAdapter {

    protected abstract api: Api
    protected abstract organizationsStorage: OrganizationsStorage

    public abstract getUIds(criteria: string): Promise<string[]>

    public async getChannels(criteria: string): Promise<IChannelAggregate[]> {
        const organizations = await this.organizationsStorage.own$
        const channels = await this.api.organizations.channels.search(criteria, 0, 10)
        return channels.map(c => {
            return {
                ...c,
                organization: organizations.find(o => o._id === c.organizationId),
            } as IChannelAggregate
        })
    }
}

@Injectable()
export class ContactsSearchAdapter extends UserSearchAdapter {
    public constructor(
        protected api: Api,
        protected organizationsStorage: OrganizationsStorage,
    ) {
        super()
    }

    public getUIds(criteria: string): Promise<string[]> {
        return this.api.contacts.search.getIdsForAutocomplete(criteria)
    }
}

interface AutocompleteOption<T = any> {
    label: string
    imageUrl: string
    disabled?: boolean
    description?: string
    payload: T
}

@Component({
    selector: 'app-user-contacts-search',
    templateUrl: 'contacts-search.component.html',
    styleUrls: ['contacts-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsSearchComponent {

    @Input() autoFocus: boolean = false
    @Input() showBorder: boolean = true
    @Input() showSearchIcon: boolean = true
    @Input() showResultsPopup: boolean = true
    @Input() placeholder: string = 'Name or email'
    @Input() searchChannels: boolean = false

    @Input() disabledEmails: string[] = []

    // Current value of the contact search input field
    @Output() inputValue = new EventEmitter<string>()

    // When an updated list of search results is generated
    @Output() onSearchResults = new EventEmitter<FirestoreUser[]>()

    // When contact is selected or enter key pressed
    @Output() onSelected = new EventEmitter<Array<FirestoreUser | string>>()

    @CompleteOnDestroy()
    public searchCriteriaStream = new StatefulSubject<string>()

    @CompleteOnDestroy()
    public targetedContactSubject = new ValueSubject<FirestoreUser | string>(null)

    @CompleteOnDestroy()
    public isSearchResultsLoadingStream = new ValueSubject<boolean>(false)

    @ViewChild('usersSearchInput')
    private readonly usersSearchInput: ElementRef<HTMLInputElement>

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    private readonly contactsSearchDebounceTime = 500
    private readonly maxChannelsToDisplay = 10
    private readonly maxParticipantsCountToDisplay = 50

    public constructor(
        protected currentUser: CurrentUser,
        protected searchAdapter: UserSearchAdapter,
        protected snackbarManager: SnackbarManager,
        protected profilesProvider: ProfilesProvider,
    ) {}

    @Memoize()
    public get autocompleteOptions$(): Observable<AutocompleteOption[]> {
        return combineLatest([
            this.matchedChannels$,
            this.usersUIDsSearchStream,
        ]).pipe(
            switchMap(async ([ channels, userUIds ]) => {
                const options: AutocompleteOption[] = []
                for (let channel of channels) {
                    options.push({
                        label: `#${channel.name}`,
                        imageUrl: channel.organization.logoUrl,
                        disabled: false,
                        payload: channel,
                    })
                }

                const users = await this.profilesProvider.getProfilesByUids(userUIds)
                for (let user of users) {
                    options.push({
                        label: user.displayName,
                        description: user.email,
                        imageUrl: user.imageUrl,
                        disabled: this.disabledEmails.includes(user.email),
                        payload: user,
                    })
                }

                return options
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get matchedChannels$(): Observable<IChannelAggregate[]> {
        // TODO: Implement better way to disable channels search
        if (!this.searchChannels) {
            return from(Promise.resolve([]))
        }

        return combineLatest([
            this.searchCriteriaStream,
            this.currentUser.isRegularUserStream,
        ]).pipe(
            distinctUntilChanged(compareDeeply),
            switchMap(async ([criteria, isRegularUser]) => {
                criteria = criteria.replace('#', '')
                if (isRegularUser && criteria && criteria.length > 0) {
                    let channels = await this.searchAdapter.getChannels(criteria)
                    return channels.slice(0, this.maxChannelsToDisplay)
                }
                return []
            }),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get usersUIDsSearchStream(): Observable<string[]> {
        return combineLatest([
            this.searchCriteriaStream,
            this.currentUser.isRegularUserStream,
        ]).pipe(
            distinctUntilChanged(compareDeeply),
            tap(() => this.isSearchResultsLoadingStream.next(true)),
            switchMap(async sources => {
                const [criteria, isRegularUser] = sources
                if (isRegularUser && criteria && criteria.length > 0) {
                    let userUIDs = await this.searchAdapter.getUIds(criteria)
                    return userUIDs.slice(0, this.maxParticipantsCountToDisplay)
                }
                return []
            }),
            tap(() => this.isSearchResultsLoadingStream.next(false)),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    public async ngAfterViewInit() {
        if (this.autoFocus) {
            this.focusSearchInput()
        }

        if (this.usersSearchInput?.nativeElement) {
            const searchInputStream = fromEvent<KeyboardEvent>(
                this.usersSearchInput.nativeElement, 'keyup',
            ).pipe(
                takeUntil(this.destroyEvent),
            )

            searchInputStream.subscribe(event => {
                if (event.key === 'Enter') {
                    this.selectContactByText(
                        `${this.usersSearchInput.nativeElement.value}`
                    )
                }
            })

            searchInputStream.pipe(
                filter(event => event.key !== 'Enter'),
                debounceTime(this.contactsSearchDebounceTime),
                map(() => this.usersSearchInput.nativeElement.value),
            ).subscribe(criteria => {
                this.searchCriteriaStream.next(criteria)
            })
        }
    }

    public async selectContactByText(value: string) {
        let success: boolean
        if (this.searchChannels && value.includes('#')) {
            success = await this.tryToSelectChannelByName(value)
        } else {
            success = await this.trySelectContactOrEmailAddress(value)
        }

        this.focusSearchInput()
        if (success) {
            this.clearSearchInput()
        }
        return false
    }

    public async onOptionSelected(option: AutocompleteOption) {
        // Channel flow
        if (isArray(option.payload['members'])) {
            await this.selectChannel(option.payload as IChannel)
        } else {
            this.onSelected.emit([option.payload as FirestoreUser | string])
        }
        this.clearSearchInput()
    }

    protected async tryToSelectChannelByName(name: string): Promise<boolean> {
        name = name.replace('#', '')
        const channels = await this.searchAdapter.getChannels(name)
        const fullMatchChannels = channels.filter(channel => channel.name === name)

        // Do not add channel automatically when there are more than 1 items
        if (fullMatchChannels.length > 1) {
            return false
        }

        if (fullMatchChannels.length > 0) {
            await this.selectChannel(fullMatchChannels[0])
            return true
        }

        this.snackbarManager.error(`Channel #${name} not found`)
        return false
    }

    protected async trySelectContactOrEmailAddress(email: string): Promise<boolean> {
        if (!email) {
            return false
        }

        // Get clear email address from user input
        email = email.trim()
                     .toLowerCase()
                     .replace(/(%.+@)/, '@') // Removing escaped domain host
                     .replace(/(\+.+@)/, '@') // Removing additional characters
                     .replace(/(\(.+\))/, '') // Removing comments

        if (Validations.isValidEmail(email)) {
            this.onSelected.next([email])
            return true
        }

        this.snackbarManager.error(
            `Please enter valid email address`,
            SnackbarPosition.BottomLeft,
        )
        return false
    }

    protected async selectChannel(channel: IChannel) {
        const users = await Promise.all(
            channel.members.filter(m => m.userUId).map(
                m => this.profilesProvider.getProfileByUid(m.userUId),
            ),
        )
        this.onSelected.emit(users.filter(Boolean))
    }

    protected focusSearchInput() {
        return this.usersSearchInput?.nativeElement?.focus()
    }

    protected clearSearchInput() {
        this.searchCriteriaStream.next(null)
        this.inputValue.emit(null)
        if (this.usersSearchInput) {
            this.usersSearchInput.nativeElement.value = ''
        }
    }
}
