import {
    Inject,
    ModuleWithProviders,
    NgModule,
} from '@angular/core'
import { AuthModule } from '@undock/auth/auth.module'
import { CoreModule } from '@undock/core/core.module'
import { StatusesStateModel } from '@undock/data-store/states/statuses.state'
import { AbstractEventListener } from '@undock/core'
import { RefreshStatusesListener } from '@undock/data-store/listeners/refresh-statuses.listener'

@NgModule({
    imports: [
        CoreModule,
        AuthModule,
    ],
})
export class DataStoreProvider {
    static forRoot(): ModuleWithProviders<DataStoreProvider> {
        return {
            ngModule: DataStoreProvider,
            providers: [
                StatusesStateModel,

                { provide: AbstractEventListener, useClass: RefreshStatusesListener, multi: true },
            ],
        }
    }

    public constructor(
        /**
         * Event listeners should be injected in the next way because
         * Angular DI doesn't instantiate the service until it been injected
         */
        @Inject(AbstractEventListener)
        protected listeners: AbstractEventListener[],
    ) {}
}
