<button class="dropdown-button"
        mat-button #dropdownButton
        (click)="onClicked.emit($event)"
>
    <div class="left-icon-container">
        <ng-content select=".left-icon"></ng-content>
    </div>

    <span class="label" #labelRef>
        <ng-content select=".label"></ng-content>
    </span>

    <!-- This label is used if no custom provided -->
    <ng-container *ngIf="!labelRef.innerHTML.trim()">
        <span class="label">{{ label }}</span>
    </ng-container>

    <div class="right-icon-container">
        <ng-content select=".right-icon"></ng-content>
    </div>
</button>

<div class="expand-button" (click)="openOverlay()">
    <span class="material-icons">expand_more</span>
</div>


<ng-template #overlayContentTmpl>
    <div class="popup-menu-container" [style.width]="overlayWidth">
        <ng-content select=".dropdown-content"></ng-content>
    </div>
</ng-template>

