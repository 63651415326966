
export class RandomStringGenerator {

    public static generateRandomStringOfLetters(length: number = 12): string {
        const replaceChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K']

        return [...new Array(length)].map(
            () => Math.floor(Math.random() * 36)
        ).map(
            num => (num).toString(36)
        ).map(
            /*
             * Replacing integers with uppercase letters
             */
            char => (parseInt(char, 10) >= 0) ? replaceChars[char] : char
        ).join('')
    }

    public static generateRandomStringOfNumbers(length: number = 10): string {
        return [...new Array(length)].map(
            () => Math.floor(Math.random() * 10)
        ).join('')
    }
}
