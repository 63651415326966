import { Injectable } from '@angular/core'

export interface ScreenCoordinates {
    x: number,
    y: number
}

@Injectable({
    providedIn: 'root',
})
export class CursorTracker {

    constructor() { }

    public getCurrentCursorPosition(element: HTMLElement) {
        let selection = window.getSelection(),
            charCount = -1,
            node

        if (selection.focusNode) {
            if (this.isChildOf(selection.focusNode, element)) {
                node = selection.focusNode
                charCount = selection.focusOffset

                while (node) {
                    if (node.id === element.id) {
                        break
                    }

                    if (node.previousSibling) {
                        node = node.previousSibling
                        charCount += node.textContent.length
                    } else {
                        node = node.parentNode
                        if (node === null) {
                            break
                        }
                    }
                }
            }
        }

        return charCount
    };

    public setCurrentCursorPosition(element: HTMLElement, chars) {
        if (chars >= 0) {
            var selection = window.getSelection()

            let range = this.createRange(document.getElementById(element.id), { count: chars })

            if (range) {
                range.collapse(false)
                selection.removeAllRanges()
                selection.addRange(range)
            }
        }
    };

    private isChildOf(node, element) {
        while (node !== null) {
            if (node.id === element.id) {
                return true
            }
            node = node.parentNode
        }

        return false
    };

    private createRange(node, chars, range?) {
        if (!range) {
           try {
               range = document.createRange()
               range.selectNode(node)
               range.setStart(node, 0)
           } catch (error) {
               return false
           }
        }

        if (chars.count === 0) {
            range.setEnd(node, chars.count)
        } else if (node && chars.count > 0) {
            if (node.nodeType === Node.TEXT_NODE) {
                if (node.textContent.length < chars.count) {
                    chars.count -= node.textContent.length
                } else {
                    range.setEnd(node, chars.count)
                    chars.count = 0
                }
            } else {
                for (var lp = 0; lp < node.childNodes.length; lp++) {
                    range = this.createRange(node.childNodes[lp], chars, range)

                    if (chars.count === 0) {
                        break
                    }
                }
            }
        }

        return range
    };

}


