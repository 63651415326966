export interface DomainSpecificExtensionOptions {
    enableExtension?: boolean
    enableDynamicButtons?: boolean
    enableCalendarPrompt?: boolean
    enablePredictions?: boolean
    enableCopyPaste?: boolean
    showWarningIfDisabled?: boolean
}

export interface DomainSpecificExtensionOptionsMap {
    [domain: string]: DomainSpecificExtensionOptions
}

export interface ExtensionOptions {
    showInlineAlerts?: boolean
    showNotifications?: boolean
    notificationsInActiveTabOnly?: boolean
    allowUnknownSites?: boolean
}

export const DEFAULT_DOMAIN_SPECIFIC_EXTENSION_OPTIONS: DomainSpecificExtensionOptions = {
    enableExtension: false,
    enableDynamicButtons: false,
    enableCalendarPrompt: true,
    enablePredictions: false,
    enableCopyPaste: false,
    showWarningIfDisabled: true
}
