import { ApiRoute } from '@undock/api'


export class ProvidersRoute extends ApiRoute {
    public init() {
        this.route = 'auth/providers'
    }

    /**
     * Sends maximum 1 email per minute
     *
     * @throws BadRequestException
     */
    public sendConfirmationEmail() {
        return this.post<void>()
                   .endpoint(`send-confirmation-email`)
    }

    public verifyEmail(userUId: string, code: string) {
        return this.post<void>()
            .withData({
                uid: userUId, code: code,
            })
            .endpoint(`verify-email`)
    }

    /**
     * This method proceeds the same flow as
     * firebase.auth.currentUser.linkWithCredential()
     *
     * @warning:
     * Backend flow revokes refresh tokens and requires authentication
     */
    public linkEmailPasswordProvider(
        email: string, password: string, replaceEmail: boolean = true,
    ) {
        return this.post<void>()
                   .withData({
                       email, password, replaceEmail,
                   })
                   .endpoint(`link-email-password`)
    }
}
