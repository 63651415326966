import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Input,
} from '@angular/core'


@Directive({
    selector: 'img[default]',
    host: {
        '[attr.src]': 'src',
        '[class.default]': 'isDefaultApplied',
    },
})
export class ImageDefaultDirective {

    private _src: string

    @Input()
    public default: string

    public isDefaultApplied = false

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {}


    public get src(): string {
        return this.isDefaultApplied ? this.default : this._src
    }

    @Input()
    public set src(value: string) {
        value = value || ''
        if (this._src !== value) {
            this._src = value
            // Reset default status when src is updated
            this.isDefaultApplied = false
        }
    }

    @HostListener('error')
    public onImageError() {
        this.isDefaultApplied = true
        this.changeDetectorRef.markForCheck()
    }
}

