import { ApiRoute } from '@undock/api'
import {
    UserFeaturePlan,
} from '@undock/api/scopes/subscriptions/contracts/feature-plan.interface'

export class FeaturePlansRoute extends ApiRoute {

    public init() {
        this.route = 'terminal/feature-plans'
    }

    public getUserFeaturePlan(userId: string) {
        return this.get<UserFeaturePlan>()
                   .endpoint(`user-plan/${userId}`)
    }

    public setUserFeaturePlan(userId: string, data: UserFeaturePlan) {
        return this.post<void>()
                   .withData(data)
                   .endpoint(`user-plan/${userId}`)
    }
}
