import { Injectable } from '@angular/core'

import {
    default as m,
    Moment,
    tz,
} from 'moment'

import { Api } from '@undock/api'
import { clone } from '@undock/core'
import { Schedule } from '@undock/dock/meet'
import { SyncedCalendar } from '@undock/integrations'
import { MeetingModeLegacy } from '@undock/dock/meet/contracts'
import {
    AvailabilitySet,
    AvailabilitySlot,
    AvailabilityRange,
    AvailabilityResponse,
} from '@undock/api/scopes/profile/contracts/availability'
import { Plan } from '@undock/time/plans/contracts/plan.interface'
import {
    AvailabilityV2Request,
    PlansAvailabilityV2Request,
} from '@undock/api/scopes/profile/contracts/availability-v2.request'
import { AvailabilityFiltersAggregator } from '@undock/time/availability/services/filters/availability-filters.aggregator'
import { SuggestedRangesOptions } from '@undock/api/scopes/profile/requests/group-availability.request'
import {
    AvailabilityV2Response,
    DynamicSlot,
} from '@undock/api/scopes/profile/contracts/availability-v2.response'
import { TimezoneData } from '@undock/time/availability'

export declare type MeetingDuration = number


@Injectable({
    providedIn: 'root',
})
export class AvailabilityService {

    public constructor(
        protected api: Api,
        public readonly filters: AvailabilityFiltersAggregator,
    ) {}

    public async getAvailabilityV2(
        options: AvailabilityV2Request,
    ) {
        return this.prepareAvailabilityV2(
            await this.api.profile.availability.v2(options), options.timeZone,
        )
    }

    public async getPlansAvailabilityV2(
        options: PlansAvailabilityV2Request,
    ) {
        return this.prepareAvailabilityV2(
            await this.api.profile.availability.plansV2(options), options.timeZone,
        )
    }

    /**
     * @deprecated TODO: Use V2 availability instead
     */
    public async getGroupAvailability(
        start: number, end: number,
        participantEmails: string[],
        meetingDuration: MeetingDuration,
        meetingMode: MeetingModeLegacy,
        timeZone?: string,
        guestCalendars?: SyncedCalendar[],
        meetingType?: Schedule,
        includeSuggestedRanges?: boolean,
        suggestedRangesOptions?: SuggestedRangesOptions,
    ): Promise<AvailabilityResponse> {
        let response = await this.api.profile.availability.getGroupAvailability({
            agent: 'web',
            end: end,
            start: start,
            mode: meetingMode,
            participants: participantEmails,
            interval: meetingDuration,
            typeNeededFor: meetingMode,
            timeZoneNeededFor: timeZone,
            guestCalendars: guestCalendars,
            meetingType: meetingType,
            includeSuggestedRanges: includeSuggestedRanges,
            suggestedRangesOptions: suggestedRangesOptions,
        })

        if (response && response.availability) {
            response.availability = this.prepareAvailabilitySets(response.availability)
        }

        return response
    }

    /**
     * @deprecated TODO: Use V2 availability instead
     */
    public async getRescheduleAvailability(
        /**
         * Will restore availability booked by this meeting
         */
        meetingId: string,
        start: number, end: number,
        participantEmails: string[],
        meetingDuration: MeetingDuration,
        meetingMode: MeetingModeLegacy,
        timeZone?: string,
        guestCalendars?: SyncedCalendar[],
        meetingType?: Schedule,
        includeSuggestedRanges?: boolean,
        suggestedRangesOptions?: SuggestedRangesOptions,
    ): Promise<AvailabilityResponse> {
        let response = await this.api.profile.availability.getRescheduleAvailability(meetingId, {
            agent: 'web',
            end: end,
            start: start,
            mode: meetingMode,
            participants: participantEmails,
            interval: meetingDuration,
            typeNeededFor: meetingMode,
            timeZoneNeededFor: timeZone,
            guestCalendars: guestCalendars,
            meetingType: meetingType,
            includeSuggestedRanges: includeSuggestedRanges,
            suggestedRangesOptions: suggestedRangesOptions,
        })

        if (response && response.availability) {
            response.availability = this.prepareAvailabilitySets(response.availability)
        }

        return response
    }

    /**
     * @deprecated
     * TODO: Update to use V2 availability
     */
    public async getPlanAvailability(
        plan: Plan,
        start: number, end: number,
        participantEmails: string[],
        meetingDuration: MeetingDuration,
        timeZone?: string,
    ): Promise<AvailabilityResponse> {
        let response = await this.api.profile.availability.getPlanAvailability({
            agent: 'web',
            end: end,
            start: start,
            participants: participantEmails,
            interval: meetingDuration,
            timeZoneNeededFor: timeZone,
            plan: plan
        })

        if (response && response.availability) {
            response.availability = this.prepareAvailabilitySets(response.availability)
        }

        return response
    }

    /**
     * TODO: Update to use V2 availability
     */
    public async getPlanAllDayAvailability(
        plan: Plan,
        start: number, end: number,
        participantEmails: string[],
        meetingDuration: MeetingDuration,
        timeZone?: string,
    ): Promise<AvailabilityResponse> {
        let response = await this.api.profile.availability.getPlanAllDayAvailability({
            agent: 'web',
            end: end,
            start: start,
            participants: participantEmails,
            interval: meetingDuration,
            timeZoneNeededFor: timeZone,
            plan: plan
        })

        if (response && response.availability) {
            response.availability = this.prepareAvailabilitySets(response.availability)
        }

        return response
    }

    public findSlotByTimestamp(
        availability: AvailabilitySet<any>[], timeStamp: Date | Moment | string,
    ): AvailabilitySlot {
        for (let set of availability) {
            for (let slot of set.slots) {
                if (m(slot.timeStamp).isSame(timeStamp)) {
                    return slot
                }
            }
        }
    }

    public calculatePreferredMeetingGap(
        meetingLength: MeetingDuration, truncateMeetings: boolean = false,
    ): number {
        const meetingLengthGapsMap = {
            '15': 0, '30': 5, '60': 10, '120': 15,
        }
        return truncateMeetings ? meetingLengthGapsMap[`${meetingLength}`] ?? 0 : 0
    }

    /**
     * Formats V2 availability response to UI format
     */
    protected prepareAvailabilityV2(
        response: AvailabilityV2Response, timeZone: string,
    ) {
        return {
            bestSlot: {
                bestTime: response.bestSlotStartIso,
                bestTimeDayIndex: response.bestSlotDayIndex,
            },
            availability: response.days.map(day => {
                let bestSlot = day.slots.find(slot => slot.startIso === day.bestSlotStartIso)
                return {
                    day: m(day.startIso),
                    slots: day.slots.map(data => this.dynamicSlotToAvailabilitySlot(data, timeZone)),
                    bestSlot: bestSlot
                        ? this.dynamicSlotToAvailabilitySlot(bestSlot, timeZone)
                        : null,
                    suggestedRanges: day.suggestedRanges.map(range => {
                        return {
                            start: new Date(range.startIso),
                            end: new Date(range.endIso),
                            slots: range.slots.map(data => {
                                const start = m(data.startIso)
                                return {
                                    type: data.type,
                                    free: data.free,
                                    score: data.score,
                                    hour: start.get('hour'),
                                    minute: start.get('minute'),
                                    label: start.format('h:mm A'),
                                    duration: data.duration,
                                    timeStamp: data.startIso,
                                    isSoftBooked: false,
                                } as any // TODO: Assign correct slot type
                            })
                        }
                    })
                }
            }),
        } as AvailabilityResponse
    }

    protected dynamicSlotToAvailabilitySlot(slot: DynamicSlot, timeZone: string): AvailabilitySlot {
        const start = tz(slot.startIso, timeZone)
        const dynamicSlot = {
            type: slot.type,
            free: slot.free,
            hour: start.get('hour'),
            minute: start.get('minute'),
            duration: slot.duration,
            timeStamp: slot.startIso,
        } as AvailabilitySlot

        switch (slot.type) {
            case 'slot':
                dynamicSlot.score = slot.score
                dynamicSlot.label = start.format('h:mm A')
                dynamicSlot.statuses = slot.statuses
                // Returns true if at least one is soft booked
                dynamicSlot.isSoftBooked = Object.values(slot.statuses)
                                                 .filter(status => status.isFree)
                                                 .reduce((acc, item) => {
                    return acc || item.softBooked
                }, false)
                break
            case 'event':
                dynamicSlot.label = `${slot.title}`
                break
        }

        return dynamicSlot
    }

    /**
     * @deprecated TODO: Use V2 availability instead
     */
    protected prepareAvailabilitySets(availabilitySets: AvailabilitySet<any>[]): AvailabilitySet<any>[] {
        return availabilitySets.map(
            set => ({
                ...set,
                day: m(set.day),
                suggestedRanges: Array.isArray(set.suggestedRanges)
                    ? this.prepareSuggestedRanges(set.suggestedRanges) : [],
            }),
        )
    }

    /**
     * @deprecated TODO: Use V2 availability instead
     */
    protected prepareSuggestedRanges(suggestedRanges: AvailabilityRange[]): AvailabilityRange[] {
        return suggestedRanges.map(data => ({
            ...data,

            end: new Date(data.end),
            start: new Date(data.start),
        }))
    }
}
