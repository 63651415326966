import { ApiRoute } from '@undock/api'
import {
    FeaturePlan,
    UserFeaturePlan,
} from '@undock/api/scopes/subscriptions/contracts/feature-plan.interface'
import { AppFeature } from '@undock/api/scopes/subscriptions/contracts/app-feature.interface'

export class FeaturePlansRoute extends ApiRoute {

    public init() {
        this.route = 'feature-plan'
    }

    public getPersonalPlan() {
        return this.get<UserFeaturePlan>()
                   .endpoint(`personal-plan`)
    }

    public updatePersonalPlan(updates: Partial<UserFeaturePlan>): Promise<UserFeaturePlan> {
        return this.patch<UserFeaturePlan>()
                   .withData(updates)
                   .endpoint(`personal-plan`)
    }

    public updatePlanByUserId(userId: string, updates: Partial<UserFeaturePlan>): Promise<UserFeaturePlan> {
        return this.patch<UserFeaturePlan>()
                   .withData(updates)
                   .endpoint(`user-plan/${userId}`)
    }

    public listFeatures() {
        return this.get<AppFeature[]>()
                   .endpoint(`list-features`)
    }

    public listFeaturePlans(includePrices: boolean = true): Promise<FeaturePlan[]> {
        return this.post<FeaturePlan[]>()
                   .withData({
                       includePrices
                   })
                   .endpoint(`list-feature-plans`)
    }

}
