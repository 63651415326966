import { ApiRoute } from '@undock/api/services/api.route'

import {
    UserAvailableMeetingLength,
    UserConferenceLinkType,
    UserInterface,
    UserNotificationsSettings,
    UserPreferredTimeWindow,
    UserTimeZoneMode,
} from '../contracts/user.interface'
import { UpdateAvailabilityRequest } from '../contracts/settings'


export class SettingsRoute extends ApiRoute {

    public init() {
        this.route = 'user/settings'
    }

    public updateName(
        displayName: string, firstName: string, lastName?: string,
    ) {
        return this.post<void>()
                   .withData({ displayName, firstName, lastName })
                   .endpoint(`name`)
    }

    public updateProfileUrl(profileUrl: string) {
        return this.post<void>()
                   .withData({ profileUrl })
                   .endpoint(`profile-url`)
    }

    public getValidProfileUrl(profileUrl: string) {
        return this.post<string>()
                   .withData({ profileUrl })
                   .endpoint(`get-valid-profile-url`)
    }

    public updateProfileBio(bio: string) {
        return this.post<void>()
                   .withData({ bio })
                   .endpoint(`profile-bio`)
    }

    public updateProfileWebsite(website: string) {
        return this.post<void>()
                   .withData({ website })
                   .endpoint(`profile-website`)
    }

    public updateProfileImageUrl(imageUrl: string) {
        return this.post<void>()
                   .withData({ imageUrl })
                   .endpoint(`image-url`)
    }

    public updateMinScheduleNotice(minScheduleNotice: number) {
        return this.post<void>()
                   .withData({ minScheduleNotice })
                   .endpoint(`min-schedule-notice`)
    }

    public updateMaxMeetingHoursPerDay(numHoursPerDay: number) {
        return this.post<void>()
                   .withData({ numHoursPerDay })
                   .endpoint(`max-meeting-hours-per-day`)
    }

    public updateTruncateMeetings(truncateMeetings: boolean) {
        return this.post<void>()
                   .withData({ truncateMeetings })
                   .endpoint(`truncate-meetings`)
    }

    public updateFlexible(flexible: boolean) {
        return this.post<void>()
                   .withData({ flexible })
                   .endpoint(`flexible`)
    }

    public updateTimeBetweenEvents(timeBetweenEvents: number) {
        return this.post<void>()
                   .withData({ timeBetweenEvents })
                   .endpoint(`time-between-events`)
    }

    public updateDefaultDuration(defaultDuration: number) {
        return this.post<void>()
                   .withData({ defaultDuration })
                   .endpoint(`default-duration`)
    }

    public updateAvailableMeetingsLength(
        length: UserAvailableMeetingLength, isAvailable: boolean,
    ) {
        return this.post<void>()
                   .withData({ length, isAvailable })
                   .endpoint(`available-meeting-lengths`)
    }

    public updatePreferredTimeWindows(
        timeWindow: UserPreferredTimeWindow, isPreferred: boolean,
    ) {
        return this.post<void>()
                   .withData({ timeWindow, isPreferred })
                   .endpoint(`preferred-time-windows`)
    }

    public updateAvailability(
        updateAvailabilityRequestData: UpdateAvailabilityRequest,
    ) {
        return this.post<void>()
                   .withData({
                       ...updateAvailabilityRequestData,
                   })
                   .endpoint(`availability`)
    }

    public updatePhoneNumber(phoneNumber: string) {
        return this.post<void>()
                   .withData({ phoneNumber })
                   .endpoint(`phone-number`)
    }

    public updateConferenceLinkType(
        conferenceLinkType: UserConferenceLinkType,
    ) {
        return this.post<void>()
                   .withData({ conferenceLinkType })
                   .endpoint(`conference-link-type`)
    }

    public updateCustomMeetingLink(meetingLink: string) {
        return this.post<void>()
                   .withData({ meetingLink })
                   .endpoint(`custom-meeting-link`)
    }

    public updatePrivacyConfiguration(isEnabled: boolean) {
        return this.post<void>()
                   .withData({ isEnabled })
                   .endpoint(`privacy`)
    }

    public updateAvailabilityLimit(isLimitEnabled: boolean, slotLimit: number) {
        return this.post<void>()
                   .withData({ isLimitEnabled, slotLimit })
                   .endpoint(`limit-availability`)
    }

    public updateTimeZone(timeZone: string, timeZoneCity: string) {
        return this.post<void>()
                   .withData({ timeZone, timeZoneCity })
                   .endpoint(`timezone`)
    }

    public updateTimeZoneCity(timeZoneCity: string) {
        return this.post<void>()
                   .withData({ timeZoneCity })
                   .endpoint(`timezone-city`)
    }

    public updateTimeZoneMode(timeZoneMode: UserTimeZoneMode) {
        return this.post<void>()
                   .withData({ timeZoneMode })
                   .endpoint(`timezone-mode`)
    }

    public updateNotificationsSettings(settings: UserNotificationsSettings) {
        return this.post<void>()
                   .withData(settings)
                   .endpoint(`notifications`)
    }

    public updateOnboardingComplete(onboardingComplete: boolean) {
        return this.post<void>()
                   .withData({ onboardingComplete })
                   .endpoint(`onboarding-complete`)
    }

    public updateOnboardingCompletedStep(onboardingCompletedStep: string) {
        return this.post<void>()
                   .withData({ onboardingCompletedStep })
                   .endpoint(`onboarding-completed-step`)
    }

    /**
     * @TODO: Remove all usages of this method
     *
     *  ``` PLEASE DO NOT USE THIS METHOD IF YOU CAN CHANGE THE USER SETTING IN A DIFFERENT WAY ```
     *
     * @deprecated
     */
    public massUpdateUserProperties(properties: Partial<UserInterface> | any) {
        return this.put<void>()
                   .withData(properties)
                   .endpoint(`mass-update-properties`)
    }
}
