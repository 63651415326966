import { HasAttachments } from '@undock/core'
import { ReactiveStream } from '@typeheim/fire-rx'
import { InjectionToken } from '@angular/core'

export interface NotesAdapterInterface {
    /**
     * Used for automatically saving changes
     */
    notesTextStream: ReactiveStream<string>

    /**
     *
     */
    isEditModeStream: ReactiveStream<boolean>

    /**
     * Used to save the attachments from the editor
     */
    attachmentSourceStream: ReactiveStream<HasAttachments>

    editorChangesDebounceTime?: number

    /**
     * Should update notes
     */
    setNotes(notes: string): Promise<void> | void
}

export const NOTES_ADAPTER = new InjectionToken('NOTES_ADAPTER')
