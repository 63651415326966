import { ApiRoute } from '@undock/api/services/api.route'
import { HasEntityName, Model } from '@undock/core'

export interface UrlHandle {
    ownerId: string
    ownerType: string
    handle: string
    createdAt: string
    updatedAt: string
}

export class UrlHandleRegistryRoute extends ApiRoute {
    public init() {
        this.route = 'url-handle/registry'
    }

    public getUrlHandle(handle: string) {
        return this.get<UrlHandle>()
                   .endpoint(`${handle}`)
    }

    public isHandleAvailable(handle: string, owner: Model & HasEntityName) {
        return this.post<boolean>()
                   .withData({ handle, owner })
                   .endpoint('is-available')
    }
}
