import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

@Component({
    selector: 'app-icon-gmail',
    templateUrl: 'gmail-icon.component.html',
    styleUrls: ['gmail-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmailIconComponent {}
