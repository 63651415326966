 import { Injectable } from '@angular/core'
import {
    State,
    StateModel,
    StreamStore,
} from '@undock/core/states'
import { Api } from '@undock/api'
import {
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Plan } from '@undock/time/plans/contracts/plan.interface'
import {
    isEmptyString,
} from '@undock/core'
import {
    ConnectionsFacade,
    UiConnectionData,
} from '@undock/people/services/facades/connections.facade'
import {
    PlansSearchStateModel,
    SearchInputFocusType,
} from '@undock/time/plans/services/states/plans-search.state-model'


@Injectable()
export class TimeSearchStateModel extends StateModel<TimeSearchStore> {

    protected store = new TimeSearchStore()

    constructor(
        private api: Api,
        private plansSearchState: PlansSearchStateModel,
        private connectionsFacade: ConnectionsFacade
    ) {
        super()
    }

    public setCriteria(term: string) {
        this.store.searchCriteriaStream.next(term)
    }

    public async searchAll(term: string) {
        this.store.isLoadingStream.next(true)
        await Promise.all([
            this.searchCommands(term),
            this.searchConnections(term)
        ])
        this.store.isLoadingStream.next(false)
    }

    public async searchCommands(term: string) {
        await this.plansSearchState.searchPlans(term)
        this.store.plansSearchResultsStream.next(
            await this.plansSearchState.state.plansSearchResultsStream
        )
    }

    public async searchConnections(term: string) {
        if (!isEmptyString(term)) {
            let connections = await this.connectionsFacade.uiConnections$
            this.store.connectionsSearchResultsStream.next(
                connections.filter((connection) => {
                    return [
                        connection.profile.email,
                        connection.profile.username,
                        connection.profile.displayName,
                    ].join('')
                     .toUpperCase()
                     .includes(term.trim().toUpperCase())
                })
            )
        }
        else {
            this.store.connectionsSearchResultsStream.next([])
        }
    }

    public clearSearch() {
        return this.setCriteria(null)
    }

    public setSearchInputFocusType(state: SearchInputFocusType) {
        setTimeout(() => {
            this.store.searchInputFocusStateStream.next(state)
        }, 250) // Hotfix for popup mode UI error
    }
}

export class TimeSearchStore extends StreamStore {
    public isLoadingStream = new ValueSubject<boolean>(false)

    public searchCriteriaStream: ValueSubject<string> = new ValueSubject<string>(null)

    public searchInputFocusStateStream = new StatefulSubject<SearchInputFocusType>()

    public plansSearchResultsStream: ValueSubject<Plan[]> = new ValueSubject<Plan[]>([])

    public connectionsSearchResultsStream: ValueSubject<UiConnectionData[]> = new ValueSubject<UiConnectionData[]>([])
}

export type TimeSearchState = State<TimeSearchStore>
