<div class="selector"
     ngx-colors-trigger
     [ngModel]="value"
     [palette]="palette"
     [colorPickerControls]="'no-alpha'"
     (ngModelChange)="changeColor($event)"
>
    <div class="selected-value" [style.background]="value"></div>
    <mat-select-trigger class="customArrow"></mat-select-trigger>
</div>
