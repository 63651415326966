import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-calendar-view',
    templateUrl: 'calendar-view.component.html',
    styleUrls: ['calendar-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarViewIconComponent extends IconTemplateComponent {}
