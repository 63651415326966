import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import {
    TimeCommandActions,
    TimeCommandBlueprintHold,
    } from '@undock/api/scopes/nlp/routes/commands.route'
import * as m from 'moment'


@Component({
    selector: 'app-time-command-blueprint-hold',
    templateUrl: './time-command-blueprint-hold.component.html',
    styleUrls: ['./time-command-blueprint-hold.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCommandBlueprintHoldComponent {

    public readonly iconColor = '#2B2733'

    public readonly TimeCommandActions = TimeCommandActions

    @Input() hold: TimeCommandBlueprintHold

    @Input('action') actionType: TimeCommandActions

    @Output() onToggled = new EventEmitter<void>()

    constructor(
        public el: ElementRef
    ) {}

    public get endTime(): string {
        return Boolean(this.hold)
            ? m(this.hold.timeStamp).add(this.hold.duration, 'minutes').toISOString()
            : ''
    }

}
