import {
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Observable } from 'rxjs'

import firebase from 'firebase/app'

import { StorageFile } from '../storage-file.model'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'


export enum UploadingFileStatus {
    Error = 'Error',
    Paused = 'Paused',
    Running = 'Running',
    Finished = 'Finished'
}

export interface UploadingFileOptions {
    tags?: string[],
    fileName?: string,
}

export class UploadingFile {

    private uploadedFileSubject = new StatefulSubject<StorageFile>()

    private percentLoadedSubject = new ValueSubject<number>(0)

    private uploadingStatusSubject = new StatefulSubject<UploadingFileStatus>()

    public constructor(
        private target: Blob | Uint8Array | ArrayBuffer,
        private uploadTask: firebase.storage.UploadTask,
        private options: UploadingFileOptions = {},
        protected readonly ormOnFireContext: OrmOnFireContext,
    ) {
        this.subscribeForTaskStateChanges(uploadTask)
    }


    public start(): void {
        this.uploadTask.resume()
    }

    public pause(): void {
        this.uploadTask.pause()
    }

    public cancel(): void {
        this.uploadTask.cancel()
    }


    // @Memoize()
    public get percentLoadedStream(): Observable<number> {
        return this.percentLoadedSubject.asObservable()
    }

    // @Memoize()
    public get uploadedFileStream(): Observable<StorageFile> {
        return this.uploadedFileSubject.asObservable()
    }

    // @Memoize()
    public get uploadingStatusStream(): Observable<UploadingFileStatus> {
        return this.uploadingStatusSubject.asObservable()
    }

    public get uploadedFile(): Promise<StorageFile> {
        return (async () => this.uploadedFileSubject)()
    }

    public get fileName(): string {
        return (this.target instanceof File) ?
            this.target.name : this.options.fileName ?? ''
    }

    public get tags(): string[] {
        return this?.options?.tags ?? []
    }

    protected subscribeForTaskStateChanges(task: firebase.storage.UploadTask) {
        task.on('state_changed',
            snapshot => this.onTaskStateChanged(snapshot),
            error => this.onTaskError(error),
            () => this.onTaskFinishedSuccessfully(task),
        )
    }

    protected async onTaskStateChanged(snapshot: firebase.storage.UploadTaskSnapshot) {
        switch (snapshot.state) {
            case 'running':
                this.uploadingStatusSubject.next(UploadingFileStatus.Running)
                break

            case 'paused':
                this.uploadingStatusSubject.next(UploadingFileStatus.Paused)
                break
        }

        if (snapshot.totalBytes > 0) {
            this.percentLoadedSubject.next(
                Math.floor(snapshot.bytesTransferred / snapshot.totalBytes * 100),
            )
        } else {
            this.percentLoadedSubject.next(100)
        }
    }

    protected async onTaskFinishedSuccessfully(task: firebase.storage.UploadTask) {
        this.uploadingStatusSubject.next(UploadingFileStatus.Finished)

        const snapshot = await task
        const storageFile = new StorageFile()

        storageFile.name = snapshot.metadata.name
        storageFile.size = snapshot.metadata.size
        storageFile.path = snapshot.metadata.fullPath
        storageFile.bucket = snapshot.metadata.bucket
        storageFile.contentType = snapshot.metadata.contentType

        storageFile.tags = storageFile.tags ?? []
        if (this.options.tags) {
            storageFile.tags = storageFile.tags.concat(this.options.tags)
        }

        await this.ormOnFireContext.saveModel(storageFile)

        this.uploadedFileSubject.next(storageFile)
    }

    protected async onTaskError(error: Error) {
        console.warn(`Cannot upload file`, error)
        this.uploadedFileSubject.next(null)
        this.uploadingStatusSubject.next(UploadingFileStatus.Error)
    }

    // @todo - add fire-rx decorators
    public onDestroy(): void {
        this.uploadedFileSubject.complete()
        this.percentLoadedSubject.complete()
        this.uploadingStatusSubject.complete()

        this.uploadedFileSubject.unsubscribe()
        this.percentLoadedSubject.unsubscribe()
        this.uploadingStatusSubject.unsubscribe()
    }
}
