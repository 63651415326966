


import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-at-sign-icon',
    template: `
        <svg [attr.width]="24" [attr.height]="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 12.9555 20.124 13.79 19.7892 14.3659C19.4957 14.8705 19.0029 15.25 18 15.25C17.5942 15.25 17.3045 15.0943 17.1043 14.8573C16.8936 14.6077 16.75 14.2259 16.75 13.7503L16.75 12V8C16.75 7.58579 16.4142 7.25 16 7.25C15.5858 7.25 15.25 7.58579 15.25 8V8.53588C14.4003 7.73843 13.2572 7.25 12 7.25C9.37665 7.25 7.25 9.37665 7.25 12C7.25 14.6234 9.37665 16.75 12 16.75C13.4081 16.75 14.6731 16.1373 15.543 15.1639C15.6509 15.4021 15.7892 15.6248 15.9582 15.825C16.4456 16.4021 17.1558 16.75 18 16.75C19.4971 16.75 20.5043 16.12 21.0858 15.1199C21.626 14.1911 21.75 13.0257 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C13.5552 21.75 15.0274 21.3853 16.3338 20.7361C16.7047 20.5518 16.856 20.1016 16.6716 19.7307C16.4873 19.3598 16.0372 19.2085 15.6662 19.3928C14.5627 19.9413 13.3185 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class AtSignIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = '#807A8E'
}
