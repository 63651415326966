import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-bell-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10V15.9512C18 16.1992 18.0804 16.4406 18.2293 16.639L20 19H4L5.77073 16.639L4.97073 16.039L5.77074 16.639C5.91955 16.4406 6 16.1993 6 15.9512V10Z" stroke="#807A8E" stroke-width="2"/>
            <path d="M14.4146 21.5C14.2087 22.0826 13.6531 22.5 13 22.5H11C10.3469 22.5 9.79127 22.0826 9.58535 21.5H14.4146Z" fill="#807A8E" stroke="#807A8E"/>
            <rect x="10.5" y="2.5" width="3" height="1" rx="0.5" fill="#807A8E" stroke="#807A8E"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class BellIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = '#807A8E'
}
