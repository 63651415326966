import {
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-meeting-preview-skeleton',
    templateUrl: 'item-view-skeleton.component.html',
    styleUrls: ['item-view-skeleton.component.scss'],
})
// TODO: Move away from layout module
export class DockPreviewTileItemViewSkeletonComponent {
    @Input() scaleSize: number
    @Input() tabs : boolean = true
    @Input() single : boolean = false
}

