import {
    ErrorHandler,
    Inject,
    Injectable,
} from '@angular/core'
import { Router } from '@angular/router'

import { Config } from '@undock/core/models/config'
import { HandleErrorStrategy } from '@undock/core/contracts/handle-error.strategy'

import LogRocket from 'logrocket'


@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    public constructor(
        private config: Config,
        private router: Router,
        @Inject(HandleErrorStrategy)
        private readonly strategies: HandleErrorStrategy[],
    ) {
        super()

        this.strategies = this.strategies.sort(
            (a, b) => a.position - b.position,
        )
    }

    /**
     * This method gets every uncaught error from the app
     */
    public handleError(error: Error): void {
        error = this.getOriginalException(error)

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            return window.location.reload()
        }

        for (let strategy of this.strategies) {
            if (error instanceof strategy.errorType) {
                return strategy.handle(error)
            }
        }

        if (this.config.enableErrorTracking) {
            //LogRocket.captureException(error)
        }

        console.error(error)

        // this.router.navigate(['/', 'critical-error'], {
        //     skipLocationChange: true
        // })
    }

    protected getOriginalException(error: Error & { rejection?: Error }): Error {
        return error.rejection ?? error
    }
}
