import {
    CreatedDateField,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'


export enum AccountType {
    Regular = 1,
    Anonymous = 0,
}

export const ACCOUNT_COLLECTION_NAME = 'account'

@Entity({
    collection: ACCOUNT_COLLECTION_NAME,
})
export class Account {
    // Same as uid property
    @ID() readonly id: string

    // FirebaseAuth account ID
    // Same for Account, Profile and PublicProfile models
    @Field() readonly uid: string

    // Id of the user entity from the legacy MongoDB storage
    @Field() readonly undockId?: string

    @Field() type: AccountType = AccountType.Regular

    @Field() onboardingNextStep: string
    @Field() onboardingComplete: boolean

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    public get isRegularUser(): boolean {
        return this.type === AccountType.Regular
    }
}
