import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations'

import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts/availability'

import { default as moment } from 'moment'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    clone,
    compareDeeply,
    Memoize,
} from '@undock/core'
import { combineLatest } from 'rxjs'
import {
    distinctUntilChanged,
    map,
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import {
    Router,
} from '@angular/router'
import { CurrentUser } from '@undock/session'
import { TimelineEvent } from '@undock/api/scopes/time/contracts/timeline-event'


@Component({
    selector: 'app-time-availability-slot-column-view',
    templateUrl: 'availability-slot-column-view.component.html',
    styleUrls: ['availability-slot-column-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('insertSlotTrigger', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(10px)' }),
                animate('300ms', style({ opacity: 1, transform: 'translateX(0)' })),
            ]),
        ]),
    ],
})
export class AvailabilitySlotColumnViewComponent {

    constructor(
        private router: Router,
        private currentUser: CurrentUser,
    ) {}

    public get isLoggedIn() {
        return this.currentUser.isLoggedInStream
    }

    public onCompareCalendarClick (){
        return this.router.navigate(['oauth-signin'], {
            queryParams: {
                redirectPath: encodeURIComponent(`${location.pathname}${location.search}`),
            },
        })
    }

    @Output() readonly onAvailableSlotSelected = new EventEmitter<AvailabilitySlot>()

    @CompleteOnDestroy()
    private currentDayAvailabilitySetSubject = new ValueSubject<AvailabilitySet>(null)

    @CompleteOnDestroy()
    private meetingToRescheduleSubject = new ValueSubject<TimelineEvent>(null)

    @Input()
    set currentDayAvailabilitySet(value: AvailabilitySet) {
        this.currentDayAvailabilitySetSubject.next(value)
    }

    @Input()
    set meetingToReschedule(value: TimelineEvent) {
        this.meetingToRescheduleSubject.next(value)
    }

    @Input() selectedAvailableSlot: AvailabilitySlot
    @Input() targetedAvailableSlot: AvailabilitySlot
    @Input() suggestedAvailableSlot: AvailabilitySlot
    @Input() bestScoreAvailableSlot: AvailabilitySlot
    @Input() selectedTimezone: string

    @Input('editable') isEditorMode: boolean = false
    @Input('priorityMode') isPriorityMode: boolean = false

    @Input() embedMode = false
    @Input() textColor: string
    @Input() backgroundColor: string

    @Input() themeColor: string = '#2B2733'
    @Input() accentColor: string = '#DFC051'
    @Input() accentTextColor: string = null

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    @Memoize()
    public get displayedSlotsForCurrentDayStream(): ReactiveStream<AvailabilitySlot[]> {
        return new ReactiveStream<AvailabilitySlot[]>(
            combineLatest([
                this.currentDayAvailabilitySetSubject,
                this.meetingToRescheduleSubject
            ]).pipe(
                distinctUntilChanged(
                    (prev, next) =>  compareDeeply(prev, next)
                ),
                map(([availabilitySet, meetingToReschedule]) => {
                    if (!!meetingToReschedule && availabilitySet?.day.isSame(meetingToReschedule.start, 'day') && availabilitySet?.slots?.length) {

                        let slots = clone(availabilitySet.slots)
                        let meetingToRescheduleStart = moment(meetingToReschedule.start)

                        let slotToRescheduleIndex = slots.findIndex(slot => moment(slot.timeStamp).isSame(meetingToRescheduleStart))
                        if (slotToRescheduleIndex !== -1) {
                            /**
                             * If slot that is to be rescheduled was found in the existing list of slots, mark it as such
                             */
                            let slotToReschedule = slots[slotToRescheduleIndex] as AvailabilitySlot
                            slotToReschedule.type = 'event'
                            slotToReschedule.isPendingReschedule = true
                            slotToReschedule.label = meetingToReschedule.title

                            return slots
                        }
                        else {
                            /**
                             * If we know the slot to reschedule is on this day and it isn't already in the list, then add it to the displayed slot list
                             */
                            let slotToReschedule = {
                                type: 'event',
                                free: false,
                                label: meetingToReschedule.title,
                                hour: meetingToRescheduleStart.hour(),
                                minute: meetingToRescheduleStart.minute(),
                                timeStamp: meetingToRescheduleStart.toISOString(),
                                isPendingReschedule: true,
                            } as AvailabilitySlot

                            slots.push(slotToReschedule)
                            slots.sort(
                                (a, b) => (moment(a.timeStamp).valueOf() as number) - (moment(b.timeStamp).valueOf() as number)
                            )

                            return slots
                        }
                    }
                    return availabilitySet?.slots
                }),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true })
            )
        )
    }

    /**
     * -----------------------------------------------------------
     *                        Actions
     * -----------------------------------------------------------
     */

    public selectSlot(slot: AvailabilitySlot): void {
        if (slot.type === 'slot') {
            if (this.isAvailabilitySlotBest(slot)) {
                slot.best = true
            }
            this.onAvailableSlotSelected.next(slot as AvailabilitySlot)
        }
    }

    public isAvailabilitySlotSelected(slot: AvailabilitySlot): boolean {
        if (this.selectedAvailableSlot) {
            return moment(slot.timeStamp).isSame(this.selectedAvailableSlot.timeStamp)
        }

        return false
    }

    public isAvailabilitySlotTargeted(slot: AvailabilitySlot): boolean {
        if (this.targetedAvailableSlot) {
            return moment(slot.timeStamp).isSame(this.targetedAvailableSlot.timeStamp)
        }

        return false
    }

    public isAvailabilitySlotSuggested(slot: AvailabilitySlot): boolean {
        if (this.suggestedAvailableSlot) {
            return moment(slot.timeStamp).isSame(this.suggestedAvailableSlot.timeStamp)
        }

        return false
    }

    public isAvailabilitySlotBest(slot: AvailabilitySlot): boolean {
        if (this.bestScoreAvailableSlot && !this.isAvailabilitySlotSuggested(slot)) {
            return moment(slot.timeStamp).isSame(this.bestScoreAvailableSlot.timeStamp)
        }

        return false
    }

    public isAnySlotAvailableForBooking(slots: AvailabilitySlot[]): boolean {
        return slots?.filter(slot => slot.isSoftBooked !== true || this.isPriorityMode).length > 0
    }
}
