import {
    inject,
    Inject,
    Injectable,
} from '@angular/core'

import 'firebase/storage'
import firebase from 'firebase/app'

import { Memoize } from '@undock/core/decorators'
import { StorageFile } from '@undock/core/models/storage-file.model'
import { FirebaseApp } from '@undock/session/contracts/firebase-app.token'
import { UploadingFile } from '@undock/core/models/file-storage/uploading-file'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'

export interface UploadFileOptions {
    tags?: string[],
    cacheControl?: string
}


@Injectable()
export class FileStorage {

    protected readonly ormOnFireContext = inject(OrmOnFireContext)

    public constructor(
        @Inject(FirebaseApp)
        private firebaseApp: firebase.app.App,
    ) {}

    @Memoize()
    public get storage(): firebase.storage.Storage {
        return this.firebaseApp.storage()
    }

    public async upload(
        target: Blob | Uint8Array | ArrayBuffer,
        fileName: string,
        path: string = '/',
        options: UploadFileOptions = {},
    ): Promise<UploadingFile> {
        /**
         * If file with the same name already exists - `(N)` will be appended
         */
        const fullPath = await this.generateUniqueNameForFile(fileName, path)

        return new UploadingFile(
            target,
            this.storage.ref(fullPath).put(target),
            { ...options, fileName },
            this.ormOnFireContext,
        )
    }

    public async removeFile(file: StorageFile): Promise<boolean> {
        let fileRef = this.storage.ref(file.path)

        try {
            await fileRef.delete()
            await this.ormOnFireContext.removeModel(file)
        } catch (e) {
            console.log(`Error deleting file`, e)
            return false
        }

        return true
    }

    public async removeFilesByPath(path: string): Promise<void> {
        const file = new StorageFile()
        file.path = path

        const fileRef = this.storage.ref(file.path)

        const files = await fileRef.listAll()

        await Promise.all(files.items.map(file => file.delete()))
    }

    public async getFileUrl(file: StorageFile): Promise<string> {
        return file ? this.storage.ref(file.path).getDownloadURL() : ''
    }

    public async getFullPathFileURL(filePath: string): Promise<string> {
        return filePath ? this.storage.ref(filePath).getDownloadURL() : ''
    }

    /**
     * Generates unique file name for given path
     */
    private async generateUniqueNameForFile(fullName, pathPrefix = ''): Promise<string> {

        let fileNameParts = fullName.split('.')
        let fileName, fileExtension

        if (fileNameParts.length > 1) {
            fileExtension = fileNameParts[fileNameParts.length - 1]
            fileName = fileNameParts.slice(0, fileNameParts.length - 1).join('')
        }

        while (await this.isFileExists(`${pathPrefix}/${fileName}.${fileExtension}`)) {
            if (fileName.search(/\(\d+\)/) >= 0) {
                /**
                 * If file already includes (N) part in the name - increase a number
                 */
                fileName = fileName.replace(/\(\d+\)/, (numberInBraces: string) => {
                    return numberInBraces.replace(
                        /\d+/, value => String(parseInt(value) + 1),
                    )
                })
            } else {
                /**
                 * Adding (1) string to the name to prevent files overwriting
                 */
                fileName += '(1)'
            }
        }

        return `${pathPrefix}/${fileName}.${fileExtension}`
    }

    public async isFileExists(filePath: string): Promise<boolean> {
        try {
            await this.storage.ref(filePath).getDownloadURL()
            return true
        } catch {
            return false
        }
    }
}
