<ng-container *ngIf="availableMeetingModeOptionsStream | stream as options">
    <mat-select class="customArrow" udInput
                [value]="selectedMeetingModeStream | stream"
                [disabled]="!(isAllowedToChangeModeStream | stream)"
                (selectionChange)="onMeetingModeChanged($event.value)"
    >
        <mat-option
                *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled"
        >{{ option.label }}</mat-option>
    </mat-select>
</ng-container>