import {
    Observable,
    timer,
} from 'rxjs'
import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import {
    delay,
    map,
} from 'rxjs/operators'

import { Moment } from '@undock/core/utils'
import { Memoize } from '@undock/core/decorators'
import { VideoRecorderService } from '@undock/core/services/video-recorder.service'

import moment from 'moment'
import { AppEventsDispatcher } from '@undock/core/services/events-dispatcher.service'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { SimpleModalService } from 'ngx-simple-modal'
import { ParentDialogModalComponent } from '@undock/common/ui-kit/ui/components'


@Component({
    selector: 'app-video-recorder',
    templateUrl: 'video-recorder.component.html',
    styleUrls: ['video-recorder.component.scss'],
    providers: [VideoRecorderService],
})
export class VideoRecorderComponent {

    protected recordedStartedTime: moment.Moment

    @Input() bottomLabelText: string

    @Output() onRecordingReady = new EventEmitter<Blob>()

    @ViewChild('contentTemplate') contentTemplate: TemplateRef<any>

    public constructor(
        private recorder: VideoRecorderService,
        private eventsManager: AppEventsDispatcher,
        private snackbarManager: SnackbarManager,
        private simpleModalService: SimpleModalService,
    ) {
        this.isRecordingRunningStream.subscribe(isRecording => {
            if (isRecording) {
                this.simpleModalService.addModal(ParentDialogModalComponent, { contentTemplate: this.contentTemplate })
            } else {
                this.simpleModalService.removeAll().then().catch()
            }
        })
    }

    @Memoize()
    public get mediaStreamStream(): Observable<MediaStream> {
        return this.recorder.mediaStreamStream.asObservable()
    }

    @Memoize()
    public get recordingTimerStream(): Observable<string> {
        return timer(0, 1000).pipe(
            map(() => {
                const duration = Moment.duration(
                    Moment().unix() - this.recordedStartedTime.unix(), 'seconds',
                )

                const minutes = `${duration.minutes()}`.padStart(2, '0')
                const seconds = `${duration.seconds()}`.padStart(2, '0')

                return `${minutes}:${seconds}`
            }),
        )
    }

    @Memoize()
    public get isRecordingRunningStream(): Observable<boolean> {
        return this.recorder.isRecordingRunningStream.asObservable()
    }

    @Memoize()
    public get isRecordingRunningStreamDelayed(): Observable<boolean> {
        return this.isRecordingRunningStream.pipe(delay(100))
    }

    public async startRecording(): Promise<void> {
        try {
            this.recordedStartedTime = Moment()
            await this.recorder.startRecording()
            this.eventsManager.dispatch(new TrackUserAnalyticsEvent(UserAnalyticsAction.AsyncVideoRecorded))
        } catch (error) {
            console.warn(`Cannot start recording`, error)
            this.snackbarManager.error(`Cannot start recording`)
        }
    }

    public async stopRecording(): Promise<Blob | null> {
        const blob = await this.recorder.stopRecording()

        if (blob instanceof Blob) {
            this.onRecordingReady.next(blob)
        }

        return blob
    }

    public async cancelRecording(): Promise<void> {
        return this.recorder.cancelRecording()
    }
}
