import {
    ChangeDetectionStrategy,
    Component,
    TrackByFunction,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    DockParticipant,
    DockParticipantRole,
    DockParticipantStatus,
} from '@undock/dock/meet/models/dock/dock-participant.model'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'


@Component({
    selector: 'app-meet-dock-participants-list',
    templateUrl: 'participant-list.component.html',
    styleUrls: ['participant-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantListComponent {

    public readonly participantTrackByFn: TrackByFunction<DockParticipant> = (i, p) => p.id

    public readonly TooltipPosition = TooltipPosition
    public readonly DockParticipantRole = DockParticipantRole
    public readonly DockParticipantStatus = DockParticipantStatus

    public readonly isEditModeStream: ReactiveStream<boolean>
    public readonly participantsStream: ReactiveStream<DockParticipant[]>

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        public profilesProvider: ProfilesProvider,
        protected dock: DockFacade,
        protected participantsManager: DockParticipantsManager,
    ) {
        this.isEditModeStream = this.dock.isEditModeStream
        this.participantsStream = this.dock.participantsStream
    }
}
