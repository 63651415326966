import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'


@Component({
    selector: 'app-calendar-propose-icon',
    templateUrl: 'calendar-propose-icon.component.html',
    styleUrls: ['calendar-propose-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarProposeIconComponent extends IconTemplateComponent {
    @Input() color: string = '#FFFFFF'
    @Input() width: number = 16
    @Input() height: number = 16
}
