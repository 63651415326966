import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    inject,
    Input,
} from '@angular/core'

import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { MeetingMode } from '@undock/dock/meet/contracts'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { TopicsManager } from '@undock/dock/meet/services/topics.manager'
import { PrivateNotesManager } from '@undock/core/services/private-notes.manager'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { MeetingDockPageStateModel } from '@undock/dock/meet/ui/pages/meeting-dock/meeting-dock-page.state'
import { NOTES_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/notes.adapter'
import { TOPICS_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'
import { PARTICIPANTS_PROVIDER } from '@undock/dock/meet/contracts/ui-providers/participants.provider'
import { DockFacadeNotesAdapter } from '@undock/dock/meet/services/adapters/dock-facade-notes.adapter'
import { DockFacadeTopicsAdapter } from '@undock/dock/meet/services/adapters/dock-facade-topics.adapter'
import { DockFacadeParticipantsProvider } from '@undock/dock/meet/services/data-providers/dock-facade-participants.provider'
import { DashboardCalendarDetailsManager } from '@undock/dashboard/services/calendar/dashboard-calendar-details.manager'
import { EventDetailsViewModel } from '@undock/dashboard/view-models/event-details.view-model'
import { CalendarEventsFacade } from '@undock/calendar/services/calendar-events.facade'
import {
    ConfirmAction,
    ConfirmActionService,
} from '@undock/common/ui-kit/services/confirm-action.service'


@Component({
    selector: 'app-dashboard-calendar-event-details-preview',
    templateUrl: 'dashboard-calendar-event-details-preview.component.html',
    styleUrls: ['dashboard-calendar-event-details-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DockFacade,
        TopicsManager,
        PrivateNotesManager,
        DockParticipantsManager,
        MeetingDockPageStateModel,
        { provide: NOTES_ADAPTER, useClass: DockFacadeNotesAdapter },
        { provide: TOPICS_ADAPTER, useClass: DockFacadeTopicsAdapter },
        { provide: PARTICIPANTS_PROVIDER, useClass: DockFacadeParticipantsProvider },
    ]
})
export class DashboardCalendarEventDetailsPreviewComponent {

    public readonly RsvpStatus = RsvpStatus
    public readonly MeetingMode = MeetingMode

    public readonly state = this.eventDetailsViewModel.state

    @Input()
    @HostBinding('class.full-size-mode')
    public isFullSizeMode: boolean = false

    @HostBinding('class.attendees-list-expanded')
    public isAttendeesListExpanded: boolean = false

    @CompleteOnDestroy()
    public isLoadingStream = new ValueSubject<boolean>(true)

    protected readonly confirmActionService = inject(ConfirmActionService)

    public constructor(
        private dockFacade: DockFacade,
        private calendarEventFacade: CalendarEventsFacade,
        private eventDetailsViewModel: EventDetailsViewModel,
        private dashboardCalendarPopupManager: DashboardCalendarDetailsManager,
    ) {}

    public async ngOnInit() {
        const event = await this.eventDetailsViewModel.state.calendarEvent
        if (event.meta?.payload?.dockId) {
            await this.dockFacade.initializeWithDockId(event.meta.payload.dockId)
            this.isLoadingStream.next(false)
        }
    }

    public async openAgenda() {
        const event = await this.eventDetailsViewModel.state.calendarEvent
        return this.calendarEventFacade.navigateToAgenda(event.meta?.payload)
    }

    public async openConference() {
        const event = await this.eventDetailsViewModel.state.calendarEvent
        return this.calendarEventFacade.navigateToTheConference(event.meta?.payload)
    }

    public async changeRsvpStatus(status: RsvpStatus) {
        const isConfirmed = await this.confirmActionService.askFor(ConfirmAction.RsvpToEvent)
        if (isConfirmed) {
            /**
             * Do we need to close the popup in this case?
             */
            if (status === RsvpStatus.Declined) {
                this.dashboardCalendarPopupManager.forceClose()
            }
            const event = await this.eventDetailsViewModel.state.calendarEvent
            return this.calendarEventFacade.setRsvpToEvent(event.meta.payload, status)
        }
    }

    public expandAttendeesList() {
        this.isAttendeesListExpanded = true
    }
}
