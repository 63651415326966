import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Injectable } from '@angular/core'

import { AuthManager } from '@undock/auth'
import { CurrentUser } from '@undock/session'


@Injectable()
export class OnboardingCompleteGuard implements CanActivate {

    private readonly onboardingPagePrefixes = ['join', 'install']

    public constructor(
        private router: Router,
        private authManager: AuthManager,
        private currentUser: CurrentUser,
    ) {}

    public async canActivate(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
        if (await this.authManager.isLoggedInStream) {
            const [user, isAnonymous] = await Promise.all([
                this.currentUser.dataStream,
                this.currentUser.isAnonymousUserStream,
            ])

            if (isAnonymous || user.onboardingComplete) {
                /**
                 * Anonymous users should not proceed through onboarding
                 */
                return true
            }

            if (this.isNavigatedToOnboardingPage(state.url)) {
                /**
                 * Should not redirect if one of the onboarding pages is requested
                 */
                return true
            }

            /**
             * Navigating the user to the onboarding page
             */
            return this.router.createUrlTree(['install'])
        }

        /**
         * Not authorized users shouldn't proceed with onboarding
         */
        return true
    }

    protected isNavigatedToOnboardingPage(url: string): boolean {
        for (let prefix of this.onboardingPagePrefixes) {
            if (url.includes(prefix)) {
                return true
            }
        }

        return false
    }
}
