import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'
import { CommonModule } from '@angular/common'

import {
    Config,
    FileStorage,
    MemoryStorage,
    BrowserLocalStorage,
    BrowserSessionStorage,
    DefaultPathResolveStrategy,
} from '@undock/core/models'
import { Logger } from '@undock/core/services/logger.service'

import {
    TextTrimPipe,
    FileSizePipe,
    ImgThumbnailPipe,
    RemoveLinkProtocolPipe,
} from '@undock/core/pipes'

import {
    CheckDomainGuard,
    BlockIframeGuard,
} from '@undock/core/guards'

import { LocalStorage } from '@undock/core/contracts/storage/local-storage.interface'
import { SessionStorage } from '@undock/core/contracts/storage/session-storage.interface'
import { PathResolveStrategy } from '@undock/core/contracts/path-resolve.strategy'
import { STORAGE_PATH_PREFIX } from '@undock/core/contracts/tokens/storage-path-prefix.token'

import { ExtConnector } from '@undock/core/services/ext.connector'
import { CursorTracker } from '@undock/core/services/cursor-tracker.service'
import { AttachmentsManager } from '@undock/core/services/attachments.manager'
import { VideoRecorderComponent } from '@undock/core/components/video-recorder/video-recorder.component'
import { MatAutocompleteTabDirective } from '@undock/core/directives/mat-autocomplete-tab.directive'
import { DebounceKeyupDirective } from '@undock/core/directives/debounce-keyup.directive'
import { RxJSDebugger } from '@undock/core/services/rxjs.debugger'
import { AvatarImagePipe } from '@undock/core/pipes/avatar.pipe'
import { BrowserConnector } from '@undock/core/services/browser.connector'
import {
    ProductionModeDirective,
    StageModeDirective,
} from '@undock/core/directives/stage-mode.directive'
import { MeetingDurationPipe } from '@undock/core/pipes/meeting-duration.pipe'
import { StreamPipe } from '@undock/core/pipes/stream.pipe'

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        FileSizePipe,
        ImgThumbnailPipe,
        TextTrimPipe,
        AvatarImagePipe,
        VideoRecorderComponent,
        RemoveLinkProtocolPipe,
        MatAutocompleteTabDirective,
        DebounceKeyupDirective,
        StageModeDirective,
        ProductionModeDirective,
        MeetingDurationPipe,
        StreamPipe,
    ],
    declarations: [
        FileSizePipe,
        ImgThumbnailPipe,
        TextTrimPipe,
        AvatarImagePipe,
        VideoRecorderComponent,
        RemoveLinkProtocolPipe,
        MatAutocompleteTabDirective,
        DebounceKeyupDirective,
        StageModeDirective,
        ProductionModeDirective,
        MeetingDurationPipe,
        StreamPipe,
    ],
})
export class CoreModule {

    constructor(
        protected rxjsDebug: RxJSDebugger
    ) {}

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                Config,
                Logger,
                RxJSDebugger,

                BrowserConnector,
                ExtConnector,
                CursorTracker,

                FileSizePipe,
                ImgThumbnailPipe,
                TextTrimPipe,
                AvatarImagePipe,
                MeetingDurationPipe,

                FileStorage,
                AttachmentsManager,
                { provide: STORAGE_PATH_PREFIX, useValue: 'default' },
                { provide: PathResolveStrategy, useClass: DefaultPathResolveStrategy },

                MemoryStorage,
                { provide: LocalStorage, useClass: BrowserLocalStorage },
                { provide: SessionStorage, useClass: BrowserSessionStorage },

                CheckDomainGuard,
                BlockIframeGuard,
            ],
        }
    }
}
