import {
    inject,
    Injectable,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    tap,
    map,
    switchMap,
    takeUntil,
    shareReplay,
    distinctUntilChanged,
    take,
} from 'rxjs/operators'

import {
    Config,
    Memoize,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    Schedule,
    MeetingTypeScope,
    ScheduleType,
} from '@undock/dock/meet/models/schedule.model'
import { MeetingTypeFactory } from '@undock/dock/meet/models/factories/meeting-type.factory'
import { MeetingDurationOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-duration-options.provider'
import { UserLimitsProvider } from '@undock/feature-plans/services/user-limits.provider'
import {
    combineLatest,
    from,
} from 'rxjs'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { UserAnalyticsAttributesManager } from '@undock/integrations/services/analytics/user-analytics-attributes.manager'
import { SchedulesManager } from '@undock/dock/meet/contracts/schedules-manager'
import { Collection } from '@typeheim/orm-on-fire'
import { ProfileLinksManager } from '@undock/profile/shared/services/profile-links.manager'
import { injectCollection } from '@undock/session/utils/inject-collection'
import { Account } from '@undock/user'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'


@Injectable({ providedIn: 'root' })
export class UserSchedulesManager extends SchedulesManager {

    protected readonly ormOnFireContext = inject(OrmOnFireContext)
    protected readonly AccountCollection = injectCollection(Account)

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected config: Config,
        protected currentUser: CurrentUser,
        protected snackbarManager: SnackbarManager,
        protected meetingTypeFactory: MeetingTypeFactory,
        protected userLimitsProvider: UserLimitsProvider,
        protected profileLinksManager: ProfileLinksManager,
        protected userAnalyticsManager: UserAnalyticsAttributesManager,
        protected meetingDurationOptionsProvider: MeetingDurationOptionsProvider,
    ) {
        super()

        // Triggers code to generate build-in schedules (fail-safe option)
        this.currentUser.isRegularUser.then(isRegularUser => {
            if (isRegularUser) {
                this.schedules$.pipe(
                    take(1),
                    takeUntil(this.destroyEvent),
                ).subscribe(schedules => {
                    const isStandardScheduleExist = schedules.some(s => s.type === ScheduleType.Standard)
                        , isPersonalScheduleExist = schedules.some(s => s.type === ScheduleType.Personal)
                    if (!isStandardScheduleExist || !isPersonalScheduleExist) {
                        this.api.schedules.personal.ensureBuildInSchedulesCreated()
                            .catch(error => {
                                console.warn(`Cannot generate build-in schedules`, error)
                            })
                    }
                })
            }
        })
    }

    @Memoize()
    public get schedules$(): ReactiveStream<Schedule[]> {
        return new ReactiveStream<Schedule[]>(
            this.schedulesCollection$.pipe(
                switchMap(ScheduleCollection => {
                    return ScheduleCollection
                        .all()
                        .filter(MeetingTypeScope.notRemoved)
                        .stream()
                        .emitUntil(this.destroyEvent)
                }),

                // Ensure data structure is correct
                map(schedules => {
                    return this.prepareSchedules(schedules)
                }),

                /**
                 * Sends analytics event
                 */
                tap(types =>
                    this.userAnalyticsManager.identify({
                        'Schedules Created Count': types?.length > 2 ? types.length : 0,
                        'Schedule Types': types ? types.length : 0,
                    })
                ),

                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get customSchedules$(): ReactiveStream<Schedule[]> {
        return new ReactiveStream(
            this.schedules$.pipe(
                map(schedules => {
                    return schedules.filter(
                        schedule => [
                            ScheduleType.CustomProfile,
                            ScheduleType.EmbedProfile,
                        ].includes(schedule.type)
                    ).sort((a, b) => {
                        /**
                         * To display enabled schedules on top
                         */
                        return Number(a.isDisabled) - Number(b.isDisabled)
                    }).sort((a, b) => {
                        /**
                         * To display all embed schedules at the end of the list
                         */
                        return Number(a.type === ScheduleType.EmbedProfile)
                             - Number(b.type === ScheduleType.EmbedProfile)
                    })
                }),
                takeUntil(this.destroyEvent),
            )
        )
    }

    @Memoize()
    public get buildInSchedules$(): ReactiveStream<Schedule[]> {
        return new ReactiveStream(
            this.schedules$.pipe(
                map(schedules => {
                    return schedules.filter(
                        schedule => [
                            ScheduleType.Standard,
                            ScheduleType.Personal,
                        ].includes(schedule.type)
                    ).sort((a, b) => {
                        /**
                         * To display Standard schedule at the first place
                         */
                        return Number(b.type === ScheduleType.Standard)
                             - Number(a.type === ScheduleType.Standard)
                    })
                }),
                takeUntil(this.destroyEvent),
            )
        )
    }

    @Memoize()
    public get isAnyScheduleAdded$(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.schedules$.pipe(
                map(types => types.length > 0),
                takeUntil(this.destroyEvent),
            ),
        )
    }

    @Memoize()
    public get isSchedulesLimitReached$(): ReactiveStream<boolean> {
        return new ReactiveStream(
            combineLatest([
                this.schedules$,
                from(this.userLimitsProvider.getSchedulesLimit()),
            ]).pipe(
                map(([schedules, limit]) => {
                    return schedules.filter(schedule => {
                        return !schedule.removed
                            && !schedule.isDisabled
                            && schedule.type === ScheduleType.CustomProfile
                    }).length >= limit
                }),
            )
        )
    }

    @Memoize()
    protected get schedulesCollection$(): ReactiveStream<Collection<Schedule>> {
        return new ReactiveStream<Collection<Schedule>>(
            this.currentUser.uidStream.pipe(
                distinctUntilChanged(),
                map(uid => {
                    return this.ormOnFireContext.createNestedCollection(
                        Schedule,
                        this.AccountCollection.one(uid),
                    )
                }),
                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    public async getAllMeetingTypesByUserUId(uid: string): Promise<Schedule[]> {
        const collection = this.ormOnFireContext.createNestedCollection(
            Schedule,
            this.AccountCollection.one(uid),
        )
        return this.prepareSchedules(await collection.all().get())
    }

    public async getMeetingTypeById(id: string, userUId: string): Promise<Schedule> {
        const collection = this.ormOnFireContext.createNestedCollection(
            Schedule,
            this.AccountCollection.one(userUId),
        )
        return this.prepareSchedule(await collection.one(id).get())
    }

    public async getByIntegrationClientId(
        userUId: string, integrationClientId: string,
    ): Promise<Schedule> {
        const collection = this.ormOnFireContext.createNestedCollection(
            Schedule,
            this.AccountCollection.one(userUId),
        )
        return this.prepareSchedule(
            (await collection
                   .filter(filter => {
                       filter.type.equal(ScheduleType.EmbedProfile)
                       filter.integrationClientId.equal(integrationClientId)
                       return filter
                   })
                   .filter(MeetingTypeScope.notRemoved)
                   .get()
            )[0]
        )
    }

    public async getSchedulesPageUrl() {
        return this.profileLinksManager
                   .getPublicUrlForCurrentUserMeetingTypes()
    }

    public async generateScheduleUrl(schedule:Schedule) {
        // Standard schedule has no URL
        if (schedule.type === ScheduleType.Standard) {
            return this.profileLinksManager.getPublicUrlForCurrentUserProfile()
        }

        // Default URL for schedule
        return `${
            await this.profileLinksManager.getPublicUrlForCurrentUserMeetingTypes()
        }/${schedule.url}`
    }

    protected async getRelatedEntityData() {
        const user = await this.currentUser.data
        return { relatedEntityId: user._id, relatedEntityType: 'User' }
    }
}
