<ng-container *ngIf="!(isInviteSuggestionsLoadingStream | stream); else isLoading">
    <div *ngIf="(inviteSuggestionsStream | stream)?.length; else noInvitesFound"
         class="invite-list-container">
        <ng-container *ngIf="inviteSuggestionsFromDomainStream | stream as domainInvites">
            <ng-container *ngIf="domainInvites?.length">
                <div class="invite-list-container__domain-users domain-users">
                    <div class="domain-users__column -label">
                        <span>
                            Your Team
                        </span>
                    </div>
                    <div class="domain-users__column -meet-count">
                        <span class="domain-users__meet-count"
                              *ngIf="domainInviteCountStream | stream as domainMeetCount">
                            <span>Met {{domainMeetCount}} time</span><span *ngIf="domainMeetCount > 1">s</span>
                            <span class="domain-users__schedule-time"
                                  *ngIf="{ hours: hoursSpentSchedulingDomainStream | stream } as hoursStream">
                                <span *ngIf="hoursStream.hours < 1; else greater">&nbsp;(&lt;1 hour scheduling)</span>
                                <ng-template #greater>
                                    <span>&nbsp;({{hoursStream.hours}}+ hour<span *ngIf="hoursStream.hours > 1">s</span> scheduling)</span>
                                </ng-template>
                            </span>
                        </span>
                    </div>
                    <div class="domain-users__column -invite">
                        <div class="invite-button"
                             (click)="sendInvites(domainInvites)">
                            Invite {{domainInvites.length}} Teammates
                        </div>
                    </div>
                </div>
                <div class="domain-invites">
                    <ng-container *ngFor="let invite of domainInvites">
                        <div class="wrapper">
                            <!-- <img class="user-avatar" [src]="addedParticipant.imageUrl || ''"
                                    [default]="'/assets/img/placeholders/user.png'" alt/> -->

                            <span class="participant-name">{{ invite.displayName || invite.email }}</span>

                            <ng-container>
                                <span class="delete-participant-btn">
                                    <i class="material-icons"
                                       (click)="dismissInvite(invite)">close</i>
                                </span>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="inviteSuggestionsFromNonDomainStream | stream as nonDomainInvites">
            <app-contact-invite-list-item *ngFor="let invite of nonDomainInvites; let i=index"
                                          [invite]="invite"
                                          (sendInviteClicked)="sendInvite($event)"
                                          [class.__bottom-border]="i !== nonDomainInvites?.length-1">
            </app-contact-invite-list-item>
            <section class="button-block" *ngIf="nonDomainInvites?.length > 0">
                <button class="btn-primary" mat-raised-button (click)="sendInvites(nonDomainInvites)">Invite All
                </button>
            </section>
        </ng-container>
    </div>

    <ng-template #noInvitesFound>
        <div class="invite-list-container -no-suggestions">
            <span>No invite suggestions found</span>
        </div>
    </ng-template>
</ng-container>

<ng-template #isLoading>
    <div class="overlay-loading">
        <app-loading-overlay></app-loading-overlay>
    </div>
</ng-template>



