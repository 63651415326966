import { ApiRoute } from '@undock/api/services/api.route'

export class AnalyticsRoute extends ApiRoute {

    public init() {
        this.route = 'user/analytics'
    }

    public updateSentInvitesCountForUserProfile(
        profileUrl: string, count: number = 1
    ) {
        return this.post<number>()
                   .withData({ profileUrl, count })
                   .endpoint(`invite-count`)
    }

}
