<div class="contacts-search-container"
     matAutocompleteOrigin #autocompleteOrigin="matAutocompleteOrigin"
>
    <input #usersSearchInput class="contact-search-input" type="text"
           tabindex="0"
           udInput [udInputType]="'search'"
           [placeholder]="placeholder"
           [class.remove-border]="!showBorder"
           [value]="searchCriteriaStream | stream"

           [matAutocomplete]="autocomplete"
           [matAutocompleteConnectedTo]="autocompleteOrigin"

           (click)="$event.stopPropagation()"
    />

    <mat-autocomplete [class.__hidden]="!showResultsPopup"
        #autocomplete="matAutocomplete"
        (optionSelected)="onOptionSelected($event.option.value)"
    >
        <ng-container ngProjectAs="mat-option" *ngFor="let option of autocompleteOptions$ | stream">
            <mat-option [value]="option">
                <div class="contacts-search__user-view">
                    <img [src]="option.imageUrl | imgThumbnail">
                    <div class="user-information">
                        <span class="name">{{ option.label }}</span>
                        <span class="email">{{ option.description }}</span>
                    </div>
                </div>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</div>
