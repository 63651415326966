import {
    CreatedDateField,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import { SharedCollectionEntity } from '../contracts'


export const SECRET_COLLECTION = 'secret'


@Entity({
    collection: SECRET_COLLECTION,
})
export class Secret implements SharedCollectionEntity {
    @ID() id: string

    @Field() ownerId: string
    @Field() ownerType: string

    @Field() key: string

    @Field() value: string

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date
}
