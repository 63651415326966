<div class="switch-container clickable"
     [ngClass]="{
         lg: size === 'lg',
         md: size === 'md',
         xl: size === 'xl'
    }"
>
    <ng-container *ngFor="let option of options">
        <div class="switch-option" [class.__target]="option[0] === value" (click)="setValue(option[0])">
            <div class="label">
                <ng-container *ngIf="option[2]">
                    <ng-container *ngTemplateOutlet="option[2]"></ng-container>
                </ng-container>
                <div class="text">
                    {{option[1][0]}}
                </div>
            </div>
            <div class="description" *ngIf="option[1][1]">
                {{option[1][1]}}
            </div>
        </div>
    </ng-container>
</div>
