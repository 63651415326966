<div class="meeting-title-container">
    <ng-container [ngSwitch]="!disabled">
        <ng-container *ngSwitchCase="true">
            <textarea #titleInput
                      autofocus
                      data-private wrap="hard"
                      appTextareaAutoresize type="text"
                      [ngModel]="value" [placeholder]="placeholder"
                      (blur)="onTitleChanged.emit(titleInput.value)"
            ></textarea>
        </ng-container>

        <ng-container *ngSwitchCase="false">
            <span class="guest-title" data-private>{{ value || 'Untitled' }}</span>
        </ng-container>
    </ng-container>
</div>

<span class="meeting-title-underline"></span>

