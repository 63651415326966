<ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="CreditCardType.Amex">
        <svg [attr.width]="width" [attr.height]="width*.666" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="40" rx="4.44444" fill="#016FD0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M59.4223 29.667V34.0136H53.9023L51.8623 31.627L49.7423 34.0136H34.0623V21.307H28.8889L35.3556 6.66699H41.6489L43.1689 9.98698V6.66699H50.9823L52.2889 10.147L53.5689 6.66699H59.4223V8.73364H54.9023L52.8489 14.147L52.3023 15.6136L49.6756 8.73364H45.1556V19.4003L40.4623 8.73364H36.8223L32.1023 19.4003H35.1957L36.0223 17.347H41.1956L42.0223 19.4003H45.1556H47.8756V12.4537L47.8623 11.0536L48.3956 12.4537L50.9956 19.4003H53.5823L56.1956 12.4537L56.7023 11.067V19.4003H59.4223V23.5736L56.4089 26.6003L59.4223 29.667ZM36.2489 31.947V21.3203H45.1556V23.627H38.9689V25.4936H45.0356V27.787H38.9689V29.6403H45.1556V31.947H36.2489ZM58.5956 31.947H55.0623L51.8356 28.4803L48.5956 31.947H45.1556L50.1289 26.6536L45.1556 21.3203H48.7023L51.9023 24.7736L55.1156 21.3203H58.5956L53.6089 26.6136L58.5956 31.947Z" fill="white"/>
            <path d="M38.6089 11.0136L38.0757 12.347L36.9689 15.0403H40.2489L39.1423 12.347L38.6089 11.0136Z" fill="white"/>
            <rect x="1.11111" y="1.11111" width="57.7778" height="37.7778" rx="3.33333" stroke="black" stroke-opacity="0.2" stroke-width="2.22222"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="CreditCardType.Visa">
        <svg [attr.width]="width" [attr.height]="width*.666" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="40" rx="4.44444" fill="#1434CB"/>
            <path d="M37.0624 12.1914C33.7489 12.1914 30.7878 13.9089 30.7878 17.082C30.7878 20.721 36.0395 20.9723 36.0395 22.8005C36.0395 23.5702 35.1573 24.2592 33.6507 24.2592C31.5125 24.2592 29.9144 23.2964 29.9144 23.2964L29.2306 26.4985C29.2306 26.4985 31.0716 27.3118 33.5158 27.3118C37.1385 27.3118 39.9891 25.51 39.9891 22.2826C39.9891 18.4373 34.7156 18.1935 34.7156 16.4967C34.7156 15.8937 35.4398 15.233 36.9421 15.233C38.6372 15.233 40.0201 15.9332 40.0201 15.9332L40.6894 12.8406C40.6894 12.8406 39.1846 12.1914 37.0624 12.1914ZM6.74698 12.4248L6.66675 12.8916C6.66675 12.8916 8.06075 13.1467 9.31627 13.6557C10.9329 14.2392 11.048 14.579 11.3203 15.6341L14.2871 27.0711H18.2641L24.391 12.4248H20.4231L16.4862 22.3829L14.8797 13.942C14.7324 12.9759 13.9861 12.4248 13.0727 12.4248H6.74698ZM25.9866 12.4248L22.8739 27.0711H26.6576L29.7594 12.4248H25.9866ZM47.0898 12.4248C46.1774 12.4248 45.694 12.9133 45.3392 13.7669L39.7959 27.0711H43.7638L44.5314 24.8537H49.3655L49.8323 27.0711H53.3334L50.2791 12.4248H47.0898ZM47.6058 16.3818L48.782 21.8778H45.631L47.6058 16.3818Z" fill="white"/>
            <rect x="1.11111" y="1.11111" width="57.7778" height="37.7778" rx="3.33333" stroke="black" stroke-opacity="0.2" stroke-width="2.22222"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="CreditCardType.MasterCard">
        <svg [attr.width]="width" [attr.height]="width*.666" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="40" rx="4.44444" fill="#252525"/>
            <path d="M24.8926 10.7061H35.1072V29.0599H24.8926V10.7061Z" fill="#FF5F00"/>
            <path d="M25.5412 19.8818C25.5412 16.1527 27.2923 12.8451 29.9837 10.7049C28.0057 9.1484 25.5088 8.20801 22.7849 8.20801C16.3319 8.20801 11.1111 13.4288 11.1111 19.8818C11.1111 26.3349 16.3319 31.5556 22.7849 31.5556C25.5088 31.5556 28.0057 30.6153 29.9837 29.0588C27.2923 26.951 25.5412 23.611 25.5412 19.8818Z" fill="#EB001B"/>
            <path d="M48.889 19.8818C48.889 26.3349 43.6682 31.5556 37.2152 31.5556C34.4913 31.5556 31.9944 30.6153 30.0164 29.0588C32.7402 26.9186 34.4589 23.611 34.4589 19.8818C34.4589 16.1527 32.7078 12.8451 30.0164 10.7049C31.9944 9.1484 34.4913 8.20801 37.2152 8.20801C43.6682 8.20801 48.889 13.4612 48.889 19.8818Z" fill="#F79E1B"/>
            <rect x="1.11111" y="1.11111" width="57.7778" height="37.7778" rx="3.33333" stroke="white" stroke-opacity="0.2" stroke-width="2.22222"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="CreditCardType.Discover">
        <svg [attr.width]="width" [attr.height]="width*.666" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="40" rx="4.44444" fill="#231F20"/>
            <path d="M59.9999 26.667C59.9999 26.667 46.6567 35.8724 22.2222 40.0003H59.9999V26.667Z" fill="#F48120"/>
            <path d="M6.78519 15.4453H4.44434V23.6458H6.78519C8.02272 23.6458 8.91731 23.3476 9.70754 22.7064C10.6469 21.9311 11.1985 20.7682 11.1985 19.5605C11.1836 17.1301 9.37952 15.4453 6.78519 15.4453ZM8.66385 21.618C8.15691 22.0653 7.51578 22.2741 6.47209 22.2741H6.0397V16.8468H6.47209C7.50087 16.8468 8.12709 17.0258 8.66385 17.5029C9.21551 17.9949 9.54353 18.7553 9.54353 19.5455C9.54353 20.3358 9.21551 21.126 8.66385 21.618Z" fill="white"/>
            <path d="M13.5246 15.4453H11.9292V23.6458H13.5246V15.4453Z" fill="white"/>
            <path d="M17.4309 18.5905C16.4766 18.2326 16.1933 17.9941 16.1933 17.5617C16.1933 17.0398 16.7003 16.6522 17.3861 16.6522C17.8633 16.6522 18.2658 16.846 18.6684 17.3231L19.5033 16.2347C18.8175 15.6383 17.9974 15.3252 17.0879 15.3252C15.6417 15.3252 14.5234 16.3391 14.5234 17.681C14.5234 18.8141 15.0453 19.3956 16.5363 19.9324C17.1625 20.156 17.4756 20.3051 17.6396 20.3946C17.9527 20.6033 18.1167 20.9015 18.1167 21.2444C18.1167 21.9154 17.5949 22.4074 16.8792 22.4074C16.1188 22.4074 15.5075 22.0198 15.1347 21.319L14.106 22.3179C14.8365 23.4064 15.7311 23.8835 16.9388 23.8835C18.5938 23.8835 19.7717 22.7802 19.7717 21.1848C19.8015 19.8578 19.2499 19.2614 17.4309 18.5905Z" fill="white"/>
            <path d="M20.2939 19.5587C20.2939 21.9741 22.1875 23.8379 24.6178 23.8379C25.3037 23.8379 25.9001 23.7037 26.6158 23.3607V21.4821C25.9746 22.1232 25.408 22.3767 24.6924 22.3767C23.0821 22.3767 21.934 21.2137 21.934 19.5438C21.934 17.9633 23.1119 16.7258 24.6178 16.7258C25.3782 16.7258 25.9597 16.9942 26.6158 17.6502V15.7716C25.915 15.4137 25.3335 15.2646 24.6476 15.2646C22.2471 15.2646 20.2939 17.1731 20.2939 19.5587Z" fill="white"/>
            <path d="M39.3188 20.962L37.127 15.4453H35.3826L38.8566 23.8545H39.7214L43.255 15.4453H41.5255L39.3188 20.962Z" fill="white"/>
            <path d="M43.9854 23.6458H48.518V22.2591H45.5807V20.0525H48.4136V18.6659H45.5807V16.8468H48.518V15.4453H43.9854V23.6458Z" fill="white"/>
            <path d="M54.855 17.8754C54.855 16.3396 53.7964 15.46 51.9476 15.46H49.5769V23.6604H51.1723V20.3653H51.381L53.5877 23.6604H55.5558L52.9764 20.2013C54.1841 19.9478 54.855 19.1278 54.855 17.8754ZM51.6345 19.2322H51.1723V16.7422H51.6643C52.6633 16.7422 53.2 17.1597 53.2 17.9648C53.2 18.7849 52.6633 19.2322 51.6345 19.2322Z" fill="white"/>
            <path d="M31.521 23.9433C33.9337 23.9433 35.8896 21.9874 35.8896 19.5747C35.8896 17.1619 33.9337 15.2061 31.521 15.2061C29.1082 15.2061 27.1523 17.1619 27.1523 19.5747C27.1523 21.9874 29.1082 23.9433 31.521 23.9433Z" fill="#F48120"/>
            <rect x="1.11111" y="1.11111" width="57.7778" height="37.7778" rx="3.33333" stroke="white" stroke-opacity="0.2" stroke-width="2.22222"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <svg [attr.width]="width" [attr.height]="width*.666" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.3301 2.55911V25.758C43.3301 27.1488 42.1665 28.3171 40.7813 28.3171H2.54883C1.1636 28.3171 0 27.2045 0 25.758V2.55911C0 1.16829 1.1636 0 2.54883 0H40.7813C42.1665 0 43.3301 1.16829 43.3301 2.55911Z" fill="#CECADA"/>
            <path d="M56.1214 39.5007H20.604C18.7211 39.5007 17.2253 38.0018 17.2253 36.1064V15.5779C17.2253 13.6825 18.7211 12.1836 20.604 12.1836H56.1214C58.0043 12.1836 59.5001 13.6825 59.5001 15.5779V36.1064C59.5001 38.0018 58.0043 39.5007 56.1214 39.5007Z" fill="url(#paint0_linear_6043_68615)" stroke="#EDEAF2"/>
            <path d="M27.6965 33.6035H22.0448C21.5461 33.6035 21.1582 33.2141 21.1582 32.7134C21.1582 32.2127 21.5461 31.8232 22.0448 31.8232H27.6965C28.1952 31.8232 28.5831 32.2127 28.5831 32.7134C28.5831 33.2141 28.1952 33.6035 27.6965 33.6035Z" fill="#CECADA"/>
            <path d="M36.8391 33.6035H31.1873C30.6886 33.6035 30.3008 33.2141 30.3008 32.7134C30.3008 32.2127 30.6886 31.8232 31.1873 31.8232H36.8391C37.3378 31.8232 37.7256 32.2127 37.7256 32.7134C37.7256 33.2141 37.3378 33.6035 36.8391 33.6035Z" fill="#CECADA"/>
            <path d="M45.9814 33.6035H40.3297C39.831 33.6035 39.4431 33.2141 39.4431 32.7134C39.4431 32.2127 39.831 31.8232 40.3297 31.8232H45.9814C46.4801 31.8232 46.868 32.2127 46.868 32.7134C46.868 33.2141 46.4801 33.6035 45.9814 33.6035Z" fill="#CECADA"/>
            <path d="M55.1238 33.6035H49.472C48.9733 33.6035 48.5854 33.2141 48.5854 32.7134C48.5854 32.2127 48.9733 31.8232 49.472 31.8232H55.1238C55.6224 31.8232 56.0103 32.2127 56.0103 32.7134C56.0103 33.2141 55.6224 33.6035 55.1238 33.6035Z" fill="#CECADA"/>
            <path d="M47.098 22.9211C48.9341 22.9211 50.4226 21.4266 50.4226 19.5831C50.4226 17.7396 48.9341 16.2451 47.098 16.2451C45.2619 16.2451 43.7734 17.7396 43.7734 19.5831C43.7734 21.4266 45.2619 22.9211 47.098 22.9211Z" fill="#9A95AC"/>
            <path d="M42.7206 22.9211C44.5567 22.9211 46.0451 21.4266 46.0451 19.5831C46.0451 17.7396 44.5567 16.2451 42.7206 16.2451C40.8845 16.2451 39.396 17.7396 39.396 19.5831C39.396 21.4266 40.8845 22.9211 42.7206 22.9211Z" fill="#CECADA"/>
            <path d="M37.7206 22.9211C39.5567 22.9211 41.0451 21.4266 41.0451 19.5831C41.0451 17.7396 39.5567 16.2451 37.7206 16.2451C35.8845 16.2451 34.396 17.7396 34.396 19.5831C34.396 21.4266 35.8845 22.9211 37.7206 22.9211Z" fill="#EDEAF2"/>
            <path d="M43.3301 4.72852H0.0554199V10.1805H43.3301V4.72852Z" fill="#9A95AC"/>
            <defs>
                <linearGradient id="paint0_linear_6043_68615" x1="25.8599" y1="14.0879" x2="32.3678" y2="42.0832" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="0.9964" stop-color="white"/>
                </linearGradient>
            </defs>
        </svg>
    </ng-container>

</ng-container>