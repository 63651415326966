import {
    Pipe,
    PipeTransform,
} from '@angular/core'

@Pipe({ name: 'objectKeys', pure: true })
export class ObjectKeysPipe implements PipeTransform {

    /**
     * Repeats the element 'count' times in the HTML template
     */
    public transform(input: Object): Array<string> {
        return input instanceof Object ? Object.keys(input) : []
    }
}
