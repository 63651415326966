import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'


@Component({
    selector: 'app-drag-n-drop-icon',
    templateUrl: 'drag-n-drop-icon.component.html',
    styleUrls: ['drag-n-drop-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragNDropIconComponent extends IconTemplateComponent {
    @Input() width: number = 24
    @Input() height: number = 24
    @Input() color: string = '#807A8E'
}
