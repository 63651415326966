import { ApiRoute } from '@undock/api/services/api.route'


export class PrivacyRoute extends ApiRoute {
    public init() {
        this.route = 'user/privacy'
    }

    public async getPrivateProfileAccessCode(): Promise<string> {
        return this.get<string>()
                   .endpoint(`code`)
    }

    public async destroyPrivacyUnlockCode(code: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(`code/${code}`)
    }

    public async isProfileAccessCodeValid(code: string, email: string): Promise<boolean> {
        return this.post<boolean>()
                   .withData({ code, email })
                   .endpoint(`validate-code`)
    }
}
