import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchIconComponent extends IconTemplateComponent {
    @Input() color: string = '#CECADA'
    @Input() borderColor: string = ''
    @Input() weight: string = '2'
}
