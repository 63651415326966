<ng-container *ngIf="{
    isLoading: isLoading$ | stream,
    profileSearchResults: profileSearchResultsStream | stream,
    targetedProfile: targetedProfileStream | stream,
    groupSearchResults: groupSearchResultsStream | stream,
    targetedGroup: targetedGroupStream | stream
} as view">

    <app-mentions #profileMentions
                  [textInputElement]="textInputElement"
                  [menuTemplate]="contactMenuTemplate"
                  [triggerCharacter]="'@'"
                  [openDirection]="openDirection"
                  [getChoiceLabel]="getProfileLabel"
                  [closeMenuOnBlur]="closeMenuOnBlur"
                  [searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
                  (search)="loadContactChoices($event)"
                  (choiceSelected)="profileSelected.emit($event)"
                  (choiceRemoved)="profileRemoved.emit($event)"
                  (selectedChoicesChange)="onSelectedProfilesChange($event)"
                  (menuShow)="onProfileMenuShow()"
                  (menuHide)="onProfileMenuHide()"></app-mentions>

    <app-mentions #groupMentions
                  [textInputElement]="textInputElement"
                  [menuTemplate]="orgMenuTemplate"
                  [triggerCharacter]="'#'"
                  [openDirection]="openDirection"
                  [getChoiceLabel]="getChannelLabel"
                  [searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
                  (search)="loadGroupChoices($event)"
                  (choiceSelected)="groupSelected.emit($event)"
                  (choiceRemoved)="groupRemoved.emit($event)"
                  (selectedChoicesChange)="onSelectedChannelsChange($event)"
                  (menuShow)="onGroupMenuShow()"
                  (menuHide)="onGroupMenuHide()"></app-mentions>

    <ng-template #contactMenuTemplate
                 let-selectChoice="selectChoice">
        <ul class="vertical-items-list __contacts" @slideIn #profileResultsList
            [class.loader-only]="!view.profileSearchResults.length && view.isLoading">

            <li *ngFor="let contact of view.profileSearchResults"
                class="flx-selectable-list-item list-item"
                [class.__targeted]="contact.id === view.targetedProfile?.id"
                [attr.data-profileid]="contact.id"
                (click)="selectChoice(contact)">

                <img [src]="contact.imageUrl | imgThumbnail"
                     [alt]="contact.displayName"
                     default="/assets/img/placeholders/user.png"/>

                <div class="content-box">
                    <div class="label" title="{{contact.email}}">{{contact.displayName ?? contact.email}}</div>
                    <ng-container *ngIf="contact.displayName">
                        <div class="description">{{contact.email}}</div>
                    </ng-container>
                </div>
            </li>
        </ul>

    </ng-template>

    <ng-template #orgMenuTemplate
                 let-selectChoice="selectChoice">
        <ul class="vertical-items-list __channels" @slideIn #groupResultsList
            [class.loader-only]="!view.groupSearchResults.length && view.isLoading">

            <li *ngFor="let group of view.groupSearchResults" matRipple
                class="flx-selectable-list-item list-item"
                [class.__targeted]="group.id === view.targetedGroup?.id"
                [attr.data-groupid]="group.id"
                (click)="selectChoice(group)">

                <img [src]="group.logoUrl | imgThumbnail"
                     [alt]="group.name"
                     default="/assets/img/placeholders/user.png"/>

                <div class="content-box">
                    <div class="label" title="{{group.name}}"><span *ngIf="group.channel">#</span>{{group.name}}</div>
<!--                    <ng-container *ngIf="group.description">-->
<!--                        <div class="description">{{group.description}}</div>-->
<!--                    </ng-container>-->
<!--                    <ng-container *ngIf="group.organization">-->
<!--                        <div class="description">everyone</div>-->
<!--                    </ng-container>-->
                </div>
            </li>
        </ul>

    </ng-template>

</ng-container>

