import { Injectable } from '@angular/core'
import {
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { map } from 'rxjs/operators'

enum SidebarViewMode {
    Collapsed = 'collapsed',
    Expanded = 'expanded',
    Hidden = 'hidden'
}

@Injectable({
    providedIn: 'root',
})
export class SidebarState {
    public readonly locationChangesStream = new ValueSubject<string>('')
    public readonly viewModeStateStream = new ValueSubject<SidebarViewMode>(SidebarViewMode.Collapsed)
    public readonly isExpandedStream: ReactiveStream<boolean>
    public readonly isHiddenStream: ReactiveStream<boolean>

    constructor() {
        this.isExpandedStream = new ReactiveStream(this.viewModeStateStream.pipe(
            map(mode => mode === SidebarViewMode.Expanded),
        ))

        this.isHiddenStream = new ReactiveStream(this.viewModeStateStream.pipe(
            map(mode => mode === SidebarViewMode.Hidden),
        ))
    }

    public toggleViewMode() {
        if (this.viewModeStateStream.value === SidebarViewMode.Collapsed) {
            this.viewModeStateStream.next(SidebarViewMode.Expanded)
        } else {
            this.viewModeStateStream.next(SidebarViewMode.Collapsed)
        }
    }

    public expand() {
        this.viewModeStateStream.next(SidebarViewMode.Expanded)
    }

    public collapse() {
        this.viewModeStateStream.next(SidebarViewMode.Collapsed)
    }

    public hideSidebar() {
        this.viewModeStateStream.next(SidebarViewMode.Hidden)
    }

    public showSidebar() {
        this.collapse()
    }

    public registerNavigationChange(location: string) {
        this.locationChangesStream.next(location)
    }
}
