import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

@Component({
    selector: 'app-conf-empty-state-icon',
    templateUrl: 'conf-empty-state-icon.component.html',
    styleUrls: ['conf-empty-state-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfEmptyStateIconComponent {}
