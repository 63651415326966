import { ApiContext } from '@undock/api/contracts/api.context'
import { CommandsRoute } from '@undock/api/scopes/nlp/routes/commands.route'
import { TrainingRoute } from '@undock/api/scopes/nlp/routes/training.route'
import { BriefsRoute } from './routes/briefs.route'
import { AgendaRoute } from './routes/agenda.route'
import { SpeechToTextRoute } from '@undock/api/scopes/nlp/routes/speech-to-text.route'


export class NLPScope {
    public readonly commands: CommandsRoute
    public readonly training: TrainingRoute
    public readonly briefs: BriefsRoute
    public readonly agenda: AgendaRoute
    public readonly speechToText: SpeechToTextRoute

    public constructor(
        protected context: ApiContext,
    ) {
        this.commands = new CommandsRoute(context)
        this.training = new TrainingRoute(context)
        this.briefs = new BriefsRoute(context)
        this.agenda = new AgendaRoute(context)
        this.speechToText = new SpeechToTextRoute(context)
    }
}

