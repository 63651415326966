import { InjectionToken } from '@angular/core'
import { ReactiveStream } from '@typeheim/fire-rx'
import { UserData } from '@undock/user'


export interface ParticipantsProviderInterface {
    participantsStream: ReactiveStream<UserData[]>
}

export const PARTICIPANTS_PROVIDER = new InjectionToken('PARTICIPANTS_PROVIDER')
