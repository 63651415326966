import { ApiRoute } from '@undock/api'

import { PartnerIntegration } from '../contracts/partner-integration.interface'


export class IntegrationsRoute extends ApiRoute {

    public init() {
        this.route = 'integrations/partners'
    }

    public getPersonalIntegrationsList() {
        return this.get<PartnerIntegration[]>()
                   .endpoint(`personal/list`)
    }

    public disconnectPartnerIntegration(integrationId: string) {
        return this.delete<void>()
                   .endpoint(`disconnect/${integrationId}`)
    }
}