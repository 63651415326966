<div class="availability-slot-score-icon-container"
     [style.width]="width"
     [style.height]="height"
     [style.background]="backgroundColor">

    <svg xmlns="http://www.w3.org/2000/svg"
         [attr.viewBox]="viewBox"
         xml:space="preserve"
         [style.fill]="'transparent'"
         [style.width]="width"
         [style.height]="barHeight"
    >
        <rect class="__bar"
              [class.__solid]="!disabled"
              width="3"
              [attr.height]="barHeight"
              rx="1.5"
              fill="#807A8E"/>
        <rect class="__bar"
              [class.__solid]="score >= 40"
              x="5"
              width="3"
              [attr.height]="barHeight"
              rx="1.5"/>
        <rect class="__bar"
              [class.__solid]="score >= 80"
              x="10"
              width="3"
              [attr.height]="barHeight"
              rx="1.5"/>
    </svg>
</div>
