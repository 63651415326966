import { Dock } from '@undock/dock/meet'
import { DateRange } from '@undock/time/availability'
import { ApiRoute } from '@undock/api/services/api.route'
import { LegacyDock } from '@undock/dock/meet/contracts/legacy-dock'
import { CreateDockData } from '@undock/api/scopes/meet/contracts/create-dock.data'
import { CreateInstantDockData } from '@undock/api/scopes/meet/contracts/create-instant-dock.data'
import { CreateDockFromDraftData } from '@undock/api/scopes/meet/contracts/create-dock-from-draft.data'


export class DockRoute extends ApiRoute {

    public init() {
        this.route = 'meetings/dock'
    }

    /**
     * Generates a meeting, time block, calendar event and other
     *
     * @Warning this method uses new API and could cause errors
     */
    public create(data: CreateDockData): Promise<LegacyDock> {
        return this.post<LegacyDock>()
                   .withData(data)
                   .endpoint(`create`)
    }

    /**
     * Generates new Draft meeting. Returns ID of the Draft
     *
     * @param data
     */
    public createDraft(data: Record<string, any> = {}): Promise<string> {
        return this.post<string>()
                   .withData(data)
                   .endpoint(`create-draft`)
    }

    /**
     * Generates only meeting without additional entities.
     *
     * @Warning this method uses new API and could cause errors
     */
    public createInstant(data: CreateInstantDockData): Promise<LegacyDock> {
        return this.post<LegacyDock>()
                   .withData(data)
                   .endpoint(`create-instant`)
    }


    public createFromDraft(data: CreateDockFromDraftData): Promise<LegacyDock> {
        return this.post<LegacyDock>()
                   .withData(data)
                   .endpoint(`create-from-draft`)
    }

    public deleteById(dockId: string) {
        return this.delete<void>()
                   .endpoint(`id/${dockId}`)
    }

    public getByMeetingCode(code: string): Promise<LegacyDock> {
        return this.get<LegacyDock>()
                   .endpoint(`meeting-code/${code}`)
    }

    public getBySharedAccessSecret(value: string): Promise<Partial<Dock>> {
        return this.get<Dock>()
                   .endpoint(`shared-access-secret/${value}`)
    }

    public getSharedAccessSecretByDockId(dockId: string): Promise<string> {
        return this.get<string>()
                   .endpoint(`get-shared-access-secret/${dockId}`)
    }


    /**
     * Dock actions
     */

    public duplicate(dockId: string): Promise<string> {
        return this.post<string>()
                   .endpoint(`duplicate/${dockId}`)
    }

    public reschedule(dockId: string, dates: DateRange) {
        return this.post<void>()
                   .withData({ ...dates })
                   .endpoint(`reschedule/${dockId}`)
    }


    /**
     * Dock settings
     */

    public setIsAudioOnlyMeeting(dockId: string, isAudioOnly: boolean) {
        return this.post<void>()
                   .withData({ dockId, isAudioOnly })
                   .endpoint(`set-is-audio-only`)
    }
}
