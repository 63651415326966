import { Component } from '@angular/core'
import { NewMeetingButtonComponent } from '../new-meeting-button/new-meeting-button.component'

@Component({
    selector: 'app-header-new-meeting-button',
    templateUrl: 'header-new-meeting-button.component.html',
    styleUrls: ['header-new-meeting-button.component.scss'],
})
export class HeaderNewMeetingButtonComponent extends NewMeetingButtonComponent {

    public async navigateToTheNewMeetingPage() {
        /**
         * Early adopters are allowed to use new edit-event page
         */
        return this.router.navigate([
            '/', 'meet', (await this.isBetaUser$) ? 'new-event' : 'new'
        ])
    }
}
