import { ApiRoute } from '@undock/api'

export class CheckDomainRoute extends ApiRoute {
    public init() {
        this.route = 'integrations/embed/profile'
    }

    public checkDomain(id: string, profileUrl: string, domain: string): Promise<{ domain: string, status: boolean }> {
        return this.post<{ domain: string, status: boolean }>().withData({
            url: domain,
        }).endpoint(`check-domain/${profileUrl}/${id}`)
    }
}
