import { Component } from '@angular/core'

import { ChatMessage } from '@undock/chat'
import { fadeAnimation } from '../../shared-animations/fade.animation'
import { AbstractNotificationComponent } from '../abstract-notification.component'


@Component({
    selector: 'dock-notifications-chat-message',
    templateUrl: 'chat-message-notification.component.html',
    styleUrls: [
        '../../shared-styles/notification.scss',
        'chat-message-notification.component.scss',
    ],
    animations: [fadeAnimation],
})
export class ChatMessageNotificationComponent extends AbstractNotificationComponent<ChatMessage> {}
