import {
    inject,
    Injectable,
} from '@angular/core'

import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'
import { combineLatest } from 'rxjs'

import {
    Config,
    Memoize,
} from '@undock/core'
import {
    AclManager,
    DockAccessPolicy,
} from '@undock/acl'
import { Api } from '@undock/api'
import {
    Dock,
    getDockSecret,
} from '@undock/dock/meet/models/dock.model'
import {
    CurrentUser,
    injectCollection,
} from '@undock/session'
import { DockParticipant, DockParticipantRole } from '@undock/dock/meet/models/dock/dock-participant.model'
import { DockIsNotFoundException } from '@undock/dock/meet/exceptions/dock-is-not-found.exception'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'


@Injectable()
export class DockFacade {

    public readonly isEditModeStream: ReactiveStream<boolean>
    public readonly isOwnerModeStream: ReactiveStream<boolean>
    public readonly currentDockStream: ReactiveStream<Dock>
    public readonly participantsStream: ReactiveStream<DockParticipant[]>

    protected readonly DockCollection = injectCollection(Dock)
    protected readonly ormOnFireContext = inject(OrmOnFireContext)
    
    @CompleteOnDestroy()
    protected currentDockSubject = new StatefulSubject<Dock>()

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()


    public constructor(
        protected api: Api,
        protected config: Config,
        protected aclManager: AclManager,
        protected currentUser: CurrentUser,
        protected dockAccessPolicy: DockAccessPolicy,
        protected participantsManager: DockParticipantsManager,
    ) {
        this.currentDockStream = this.currentDockSubject.asStream()
        this.participantsStream = this.participantsManager.activeParticipantsStream

        this.isEditModeStream = new ReactiveStream<boolean>(
            this.currentDockStream.pipe(
                distinctUntilChanged(
                    (prev, next) => prev?.id === next?.id,
                ),
                switchMap(
                    dock => this.dockAccessPolicy.canEdit(dock.id),
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )

        this.isOwnerModeStream = new ReactiveStream<boolean>(
            combineLatest([
                this.currentDockStream,
                this.participantsStream,
                this.currentUser.dataStream,
            ]).pipe(
                distinctUntilChanged(
                    (prev, next) => prev[2]._id === next[2]._id,
                ),
                map(sources => {
                    const [ dock, participants, user ] = sources

                    return dock.authorId === user._id ||  participants.reduce((carry, item) => {
                        return carry || item.userId === user._id && item.role === DockParticipantRole.Owner
                    }, false)
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    public get currentDock(): Promise<Dock> {
        return (async () => this.currentDockSubject)()
    }

    @Memoize()
    public get dockHandleStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.currentDockStream.pipe(
                takeUntil(this.destroyedEvent),
                /**
                 * Refreshing stream only if Dock id been changed
                 */
                distinctUntilChanged(
                    (prev, next) => prev.id === next.id,
                ),
                /**
                 * Switching to the secret stream for given Dock
                 */
                switchMap(dock => {
                    return getDockSecret(Dock.SHARED_ACCESS_SECRET, dock, this.ormOnFireContext)
                }),
            ),
        )
    }

    @Memoize()
    public get sharedAccessUrlStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.dockHandleStream.pipe(
                takeUntil(this.destroyedEvent),
                /**
                 * Returns short link `https://dock.link/:secret`
                 */
                map(secret => `${this.config.dockLink}${secret}`),
                /**
                 * Sharing the latest value between all Subscribers
                 */
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get conferenceJoinPinCodeStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.currentDockStream.pipe(
                takeUntil(this.destroyedEvent),
                /**
                 * Refreshing stream only if Dock id been changed
                 */
                distinctUntilChanged(
                    (prev, next) => prev.id === next.id,
                ),
                /**
                 * Switching to the secret stream for given Dock
                 */
                switchMap(dock => {
                    return getDockSecret(Dock.CONFERENCE_JOIN_PIN_CODE, dock, this.ormOnFireContext)
                }),
                /**
                 * Sharing the latest value between all Subscribers
                 */
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }


    /**
     * @throws {DockIsNotFoundException}
     */
    public async initializeWithDockId(dockId: string, options?: Partial<Dock>): Promise<Dock> {
        let dock = await this.DockCollection.one(dockId).get()

        if (!dock) {
            throw new DockIsNotFoundException(dockId)
        }

        this.currentDockSubject.next(
            this.prepareDockDataStructure(dock),
        )

        this.participantsManager.initializeWithDock(dock)

        this.DockCollection.one(dockId)
                      .stream()
                      .emitUntil(this.destroyedEvent)
                      .pipe(
                          /**
                           * Stream will emit the latest value every 100 ms.
                           * Debounce time is used to prevent too many updates at one time.
                           */
                          debounceTime(100),
                          takeUntil(this.destroyedEvent),
                      ).subscribe(
            dock => this.currentDockSubject.next(this.prepareDockDataStructure(dock)),
        )

        return dock
    }

    public async setTitle(title: string): Promise<void> {
        const dock = await this.currentDock

        dock.title = title

        return this.save(dock)
    }

    public async setNotes(note: string): Promise<void> {
        const dock = await this.currentDock

        dock.note = note

        return this.save(dock)
    }

    public async save(dock: Dock): Promise<void> {
        return this.DockCollection.save(dock)
    }

    public async delete(dock: Dock): Promise<void> {
        return this.api.meet.dock.deleteById(dock.id)
    }

    /** @deprecated */
    protected prepareDockDataStructure(dock: Dock): Dock {
        if (dock?.dates) {
            /**
             * Converting proprietary Firestore Timestamps
             */
            try {
                if (dock.dates.end && 'toDate' in dock.dates.end) {
                    dock.dates.end = (dock.dates.end as any).toDate()
                }

                if (dock.dates.start && 'toDate' in dock.dates.start) {
                    dock.dates.start = (dock.dates.start as any).toDate()
                }
            } catch (error) {
                console.error(error)
                console.log(dock)
            }
        }

        return dock
    }
}
