import { NgModule } from '@angular/core'


@NgModule({
    imports: [

    ],
    declarations: [

    ],
    providers: [

    ],
})
export class TimePromptServiceProvider {}
