import { ApiContext } from '@undock/api/contracts/api.context'
import { ChargesRoute } from '@undock/api/scopes/charges/routes/charges.route'

export class ChargesScope {
    public readonly charges: ChargesRoute


    constructor(protected context: ApiContext) {
        this.charges = new ChargesRoute(context)
    }
}
