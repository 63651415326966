import { Injectable } from '@angular/core'
import { AppMode } from '@undock/core/contracts/types/app-mode.type'
import { environment } from '@web/environments/environment'
import { RxJSDebugLevel } from '@undock/core/contracts/rxjs-debug-level'

@Injectable({
    providedIn: 'root',
})
export class Config {

    get apiUrl(): string {
        return this.getValueFromEnvironment('apiURL')
    }

    get dockLink(): string {
        return this.getValueFromEnvironment('dockLink')
    }

    get apiPlatformURL(): string {
        return this.getValueFromEnvironment('apiPlatformURL')
    }

    get appMode(): AppMode {
        return this.getValueFromEnvironment('appMode')
    }

    get isProduction(): boolean {
        return this.appMode === 'prod'
    }

    get appVersion(): string {
        return this.getValueFromEnvironment('appVersion')
    }

    get actualCalendarVersion(): number {
        return this.getValueFromEnvironment('actualCalendarVersion')
    }

    get debugMode(): boolean {
        return this.getValueFromEnvironment('debugMode', false)
    }

    get clientDomain(): string {
        return this.getValueFromEnvironment('clientDomain')
    }

    get requestFeatureURL(): string {
        return this.getValueFromEnvironment('requestFeatureURL')
    }

    get privacyPolicyUrl(): string {
        return this.getValueFromEnvironment('privacyPolicyUrl')
    }

    get termsOfServiceUrl(): string {
        return this.getValueFromEnvironment('termsOfServiceUrl')
    }

    get latestUpdatesUrl(): string {
        return this.getValueFromEnvironment('latestUpdatesUrl')
    }

    get errorUrl(): string {
        return this.getValueFromEnvironment('errorUrl')
    }

    get zoomCallbackPath(): string {
        return this.getValueFromEnvironment('zoomCallbackPath')
    }

    get enableTracking(): boolean {
        return this.getValueFromEnvironment('enableTracking')
    }

    get firebaseConfig() {
        return this.getValueFromEnvironment('firebase')
    }

    get enableErrorTracking() {
        return this.getValueFromEnvironment('enableErrorTracking')
    }

    get rxjsDebugLevel(): RxJSDebugLevel {
        return this.getValueFromEnvironment('rxjsDebugLevel', RxJSDebugLevel.OFF)
    }

    get chromeExtURL() {
        return this.getValueFromEnvironment('chromeExtURL')
    }

    get chromeExtensionId() {
        return this.getValueFromEnvironment('chromeExtensionId')
    }

    get schedulingWindowAllowedDomains() {
        return this.getValueFromEnvironment('schedulingWindowAllowedDomains')
    }

    get calendarPromptAllowedDomains() {
        return this.getValueFromEnvironment('calendarPromptAllowedDomains')
    }

    get enableAnalytics(): boolean {
        return this.getValueFromEnvironment('enableAnalytics')
    }

    get enableHeapAnalytics(): boolean {
        return this.getValueFromEnvironment('enableHeapAnalytics')
    }

    get enableSegmentAnalytics(): boolean {
        return this.getValueFromEnvironment('enableSegmentAnalytics')
    }

    get enableGoogleAnalytics(): boolean {
        return this.getValueFromEnvironment('enableGoogleAnalytics')
    }

    get enableGoogleTagManager(): boolean {
        return this.getValueFromEnvironment('enableGoogleTagManager')
    }

    get enableCannySDK(): boolean {
        return this.getValueFromEnvironment('enableCannySDK')
    }

    get heapAnalyticsTrackingId() {
        return this.getValueFromEnvironment('heapAnalyticsTrackingId')
    }

    get segmentAnalyticsTrackingId() {
        return this.getValueFromEnvironment('segmentAnalyticsTrackingId')
    }

    get googleAnalyticsTrackingId(): string {
        return this.getValueFromEnvironment('googleAnalyticsTrackingId')
    }

    get googleTagManagerTrackingId(): string {
        return this.getValueFromEnvironment('googleTagManagerTrackingId')
    }

    get cannyAppId(): string {
        return this.getValueFromEnvironment('cannyAppId')
    }

    // get gistTourId(): string {
    //     return this.getValueFromEnvironment('gistTourId')
    // }
    //
    // get gistStepId(): string {
    //     return this.getValueFromEnvironment('gistStepId')
    // }

    get stripeApiKey(): string {
        return this.getValueFromEnvironment('stripeApiKey')
    }

    get disableNewCalendaring(): boolean {
        // stub to quickly disable new calendaring if needed
        return false
    }

    protected getValueFromEnvironment(key: string, defaultValue?: any): any {
        if (environment.hasOwnProperty(key)) {
            return environment[key]
        }

        if (defaultValue) {
            return defaultValue
        }

        if (environment.appMode !== 'prod') {
            console.error(`[ENV] Trying to get not defined env variable: ${key}`)
        }

        return null
    }
}


