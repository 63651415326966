import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-meet-topic-actions',
  templateUrl: 'topic-actions.component.html',
  styleUrls: ['topic-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicActionsComponent {
  @Input() isEditingAllowed: boolean = true;
  @Output() event = new EventEmitter<string>();
}
