<ng-container *ngIf="(invites$ | stream) as invites">
    <ng-container *ngIf="invites.length > 0">
        <a mat-button class="controls-button"
           [matTooltip]="'New Organization Invite'"
           [matTooltipPosition]="TooltipPosition.BOTTOM"
           (click)="openInvitesPopup()"
        >
            <app-people-icon [width]="24" [height]="24" [color]="'#807A8E'"></app-people-icon>
            <ng-container>
                <span class="ud-counter" >{{invites.length}}</span>
            </ng-container>
        </a>

        <div class="ud-card" [ngClass]="{'isOpened': isOpened === true}">
            <div class="ud-form-label">
                Organization Invites
            </div>
            <ng-container *ngFor="let invite of invites; let i = index">
                <div class="invite" [ngClass]="{'dark': i % 2 === 0}">
                    <div class="ud-row-item sender">
                        <div class="avatar">
                            <img [src]="invite.invitedBy.imageUrl" alt="avatar">
                        </div>
                        <div class="company">
                            <ng-container *ngIf="invite.organization.logoUrl">
                                <img [src]="invite.organization.logoUrl" alt="company">
                            </ng-container>
                        </div>
                        <div class="sender-data">
                            <header>
                                <div class="name">
                                    {{invite.invitedBy.firstName}} {{invite.invitedBy.lastName}}
                                </div>
                                <div class="invite-message">
                                    has invited you to join {{invite.organization.name}}.
                                </div>
                            </header>
                            <div class="status">
                                {{invite.dateFromNow}} ago
                            </div>
                        </div>
                    </div>
                    <div class="ud-row-item organization">
                        <div class="organization-name">
                            {{invite.organization.name}}
                        </div>
                        <div class="organization-handle">
                            @{{invite.organization.handle}}
                        </div>
                    </div>
                    <div class="ud-row-item actions">
                        <button mat-button class="ud-btn ud-btn-primary btn-small"
                                (click)="joinOrganization(invite)"
                        >Join Organization</button>
                        <button mat-button class="ud-btn ud-btn-secondary btn-small"
                                (click)="navigateToInviteViewPage(invite)"
                        >Details</button>
                    </div>
                </div>
                <hr class="ud-separator">
            </ng-container>
        </div>

        <div class="close-wrapper" [ngClass]="{'isOpened': isOpened === true}" (click)="close()"></div>
    </ng-container>
</ng-container>


