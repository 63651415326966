import { ApiRoute } from '@undock/api/services/api.route'
import { FirestoreUser } from '@undock/user'

export class SearchRoute extends ApiRoute {
    public init() {
        this.route = 'terminal/search'
    }

    public async users(term: string): Promise<FirestoreUser[]> {
        return this.post<FirestoreUser[]>()
                   .withData({ term }).endpoint('users')
    }
}
