import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core'

@Component({
    selector: 'app-availability-day-slot-skeleton-loader',
    templateUrl: 'availability-day-slot-skeleton-loader.component.html',
    styleUrls: ['availability-day-slot-skeleton-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityDaySlotSkeletonLoaderComponent implements OnInit {

    public structureArray = []
    public columnsArray = [0, 1, 2]
    @Input() count: number = 10

    @HostBinding('class.singleColumn') oneColumn = false;

    @Input() set columns(count: number) {
        this.columnsArray = new Array(count)
        switch (count) {
            case 1: this.oneColumn = true; break;
        }
    }

    ngOnInit() {
        if (this.count) {
            this.structureArray = new Array(this.count)
        }
        if (this.columns) {
            this.columnsArray = new Array(this.columns)
        }
    }

}
