import {
    Component,
    ChangeDetectionStrategy,
    Input,
} from '@angular/core'


@Component({
    selector: 'app-remove-icon',
    templateUrl: 'remove-icon.component.html',
    styleUrls: ['remove-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveIconComponent {
    @Input() height: number|string = '16'
    @Input() width: number|string = '16'
    @Input() color: string = '#2B2733'
}
