import {
    Injectable,
    TemplateRef,
} from '@angular/core'
import { StateModel } from '@undock/core/states'

import { DashboardView } from '@undock/dashboard/contracts/dashboard-view'
import { DashboardStore } from '@undock/dashboard/view-models/states/dashboard.state'


// TODO: Remove unnecessary usage places
@Injectable()
export class DashboardViewModel extends StateModel<DashboardStore> {

    protected readonly store = new DashboardStore()

    // Hook to handle view change
    protected onBeforeViewChanged: () => Promise<boolean>

    public async setView(view: DashboardView) {
        if (typeof this.onBeforeViewChanged === 'function') {
            if (await this.onBeforeViewChanged()) {
                this.store.dashboardView.next(view)
            }
        } else  {
            this.store.dashboardView.next(view)
        }
    }

    public setHeaderControlsTmplRef(ref: TemplateRef<any>) {
        this.store.headerControlsTmpl.next(ref)
    }

    public setOnBeforeViewChangedHook(callable: () => Promise<boolean>) {
        this.onBeforeViewChanged = callable
    }
}
