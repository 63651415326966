import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import {
    Memoize,
    LocalStorage,
    ReactiveStorage,
} from '@undock/core'


@Injectable()
export class ConfSettingsStorage extends ReactiveStorage<LocalStorage> {

    protected defaults = {
        isLocalAudioMuted: false,
        isLocalVideoStopped: false,
        canUnmuteLocalAudio: true,
        isVideoStreamingAvailable: true,
    }

    protected readonly STORAGE_KEY = '@undock[ConfSetting]'

    public constructor(
        protected storage: LocalStorage,
    ) {
        super(storage)
        this.loadSettings()
    }

    @Memoize()
    public get isLocalAudioMutedStream(): Observable<boolean> {
        return this.getSettingValueStream(`isLocalAudioMuted`)
    }

    public get isLocalAudioMuted() {
        return this.getSettingValue(`isLocalAudioMuted`)
    }

    public set isLocalAudioMuted(value: boolean) {
        this.setSettingValue(`isLocalAudioMuted`, value)
    }


    @Memoize()
    public get isLocalVideoStoppedStream(): Observable<boolean> {
        return this.getSettingValueStream(`isLocalVideoStopped`)
    }

    public get isLocalVideoStopped() {
        return this.getSettingValue(`isLocalVideoStopped`)
    }

    public set isLocalVideoStopped(value: boolean) {
        this.setSettingValue(`isLocalVideoStopped`, value)
    }


    @Memoize()
    public get canUnmuteLocalAudioStream(): Observable<boolean> {
        return this.getSettingValueStream(`canUnmuteLocalAudio`)
    }

    public get canUnmuteLocalAudio() {
        return this.getSettingValue(`canUnmuteLocalAudio`)
    }

    public set canUnmuteLocalAudio(value: boolean) {
        this.setSettingValue(`canUnmuteLocalAudio`, value)
    }


    @Memoize()
    public get isVideoStreamingAvailableStream(): Observable<boolean> {
        return this.getSettingValueStream(`isVideoStreamingAvailable`)
    }

    public get isVideoStreamingAvailable() {
        return this.getSettingValue(`isVideoStreamingAvailable`)
    }

    public set isVideoStreamingAvailable(value: boolean) {
        this.setSettingValue(`isVideoStreamingAvailable`, value)
    }


    @Memoize()
    public get chosenAudioInputDeviceIdStream(): Observable<string> {
        return this.getSettingValueStream('chosenAudioInputDeviceId')
    }

    public get chosenAudioInputDeviceId(): string {
        return this.getSettingValue('chosenAudioInputDeviceId')
    }

    public set chosenAudioInputDeviceId(value: string) {
        this.setSettingValue(`chosenAudioInputDeviceId`, value)
    }


    @Memoize()
    public get chosenVideoInputDeviceIdStream(): Observable<string> {
        return this.getSettingValueStream('chosenVideoInputDeviceId')
    }

    public get chosenVideoInputDeviceId(): string {
        return this.getSettingValue('chosenVideoInputDeviceId')
    }

    public set chosenVideoInputDeviceId(value: string) {
        this.setSettingValue(`chosenVideoInputDeviceId`, value)
    }


    @Memoize()
    public get chosenAudioOutputDeviceIdStream(): Observable<string> {
        return this.getSettingValueStream('chosenAudioOutputDeviceId')
    }

    public get chosenAudioOutputDeviceId(): string {
        return this.getSettingValue('chosenAudioOutputDeviceId')
    }

    public set chosenAudioOutputDeviceId(value: string) {
        this.setSettingValue(`chosenAudioOutputDeviceId`, value)
    }
}
