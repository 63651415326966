import { ApiContext } from '../../contracts/api.context'
import { LocationsRoute } from '@undock/api/scopes/locations/locations.route'

export class LocationsScope {
    public readonly locations: LocationsRoute

    public constructor(context: ApiContext) {
        this.locations = new LocationsRoute(context)
    }
}
