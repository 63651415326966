<div class="participants-selector-container"
     [ngClass]="{'editing-allowed': isEditMode}"
     [matTooltip]="title" (click)="openParticipantSelector()"
>
    <div class="selected-participant">
        <ng-container *ngIf="selectedParticipantStream | stream as participant else participantIsNotSelectedTmpl">
            <ng-container
                    *ngIf="profilesProvider.getProfileById(participant.id) | stream as user else participantUserIsNotSetTmpl"
            >
                <img class="user-image"
                     [src]="user.imageUrl | imgThumbnail" [alt]="user.displayName"
                     default="/assets/img/placeholders/user.png"
                />
            </ng-container>

            <ng-template #participantUserIsNotSetTmpl>
                <img class="user-image" src="/assets/img/placeholders/user.png" [alt]="participant.email"/>
            </ng-template>
        </ng-container>
    </div>

    <ng-template #participantIsNotSelectedTmpl>
        <ng-container [ngSwitch]="isConfMode">
<!--       TODO need to refactor to icon-component and remove switch case     -->
            <ng-container *ngSwitchCase="true">
                <div class="user-image -conf-view">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z" fill="white"/>
                    </svg>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="false">
                <div class="user-image">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z" fill="#2B2733"/>
                    </svg>
                </div>
            </ng-container>
        </ng-container>
    </ng-template>
</div>
