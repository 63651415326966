import {
    Injectable,
    Type,
} from '@angular/core'
import {
    AbstractEventListener,
    AppEventsDispatcher,
} from '@undock/core'
import { AnalyticsManager } from '../services'
import { TrackUserAnalyticsEvent } from '../events'


@Injectable()
export class TrackAnalyticsListener extends AbstractEventListener {

    public constructor(
        eventsManager: AppEventsDispatcher,
        private analyticsManager: AnalyticsManager,
    ) {
        super(eventsManager, TrackAnalyticsListener)
    }

    public getEventClass(): Type<TrackUserAnalyticsEvent> {
        return TrackUserAnalyticsEvent
    }

    public async handle(event: TrackUserAnalyticsEvent): Promise<void> {
        /**
         * Pushing event data into the AnalyticsManager
         */
        await this.analyticsManager.track(event.action, event.data)
    }
}
