import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-phone',
    templateUrl: 'phone.component.html',
    styleUrls: ['phone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneIconComponent extends IconTemplateComponent {}
