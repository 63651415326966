import { ApiRoute } from '@undock/api/services/api.route'


export class AgendaRoute extends ApiRoute {

    public init() {
        this.route = 'nlp/meet/agenda'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public async generateAgenda(agendaQuery: GenerateAgendaRequest) {
        return this.post<GenerateAgendaResponse>().withData(agendaQuery).endpoint(`generate`)
    }
}

export interface GenerateAgendaRequest {
    /**
     * The current meeting agenda, or empty string if there is none
     */
    agenda: string

    /**
     * Current meeting topics if there are any, empty array if none
     */
    topics: string[]

    /**
     * User's latest prompt for GPT
     */
    latestPrompt: string

    /**
     * User's previous prompt which will aid subsequent GPT queries
     */
    previousPrompt?: string

}

export interface GenerateAgendaResponse {
    agenda: string
    topics: string[]
}
