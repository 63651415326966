import { ApiRoute } from '@undock/api'


export class SchedulesRoute extends ApiRoute {

    public init() {
        this.route = 'organizations/schedules'
    }

    public ensureBuildInSchedulesCreated(organizationId: string) {
        return this.post()
                   .withData({ organizationId })
                   .endpoint('ensure-build-in-schedules-created')
    }
}
