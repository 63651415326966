import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core'
import {
    map,
    tap,
} from 'rxjs/operators'
import { Memoize } from '@undock/core'
import { ReactiveStream } from '@typeheim/fire-rx'

import { InvitesFacade } from '@undock/invites/facades/invites.facade'
import { InviteSuggestion } from '@undock/invites/contracts/invite-suggestion.interface'


@Component({
    selector: 'app-contact-invite-list',
    templateUrl: 'contact-invite-list.component.html',
    styleUrls: ['contact-invite-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ContactInviteListComponent implements OnInit {

    @Input('limit') nonDomainlimit: number = 4
    @Input('domainLimit') domainlimit: number = 10

    private nonDomainlimitAdjusted: number = this.nonDomainlimit

    public constructor(
        private invitesFacade: InvitesFacade,
    ) {}

    public async ngOnInit() { }

    @Memoize()
    public get inviteSuggestionsStream(): ReactiveStream<InviteSuggestion[]> {
        return this.invitesFacade.inviteSuggestionsStream
    }

    @Memoize()
    public get inviteSuggestionsFromDomainStream(): ReactiveStream<InviteSuggestion[]> {
        return new ReactiveStream<InviteSuggestion[]>(
            this.invitesFacade.domainInvites.pipe(
                map(invites => invites.slice(0, this.domainlimit)),
                tap(invites => {
                    if (!(invites?.length)) {
                        this.nonDomainlimitAdjusted = this.nonDomainlimit + 1
                    } else {
                        this.nonDomainlimitAdjusted = this.nonDomainlimit
                    }
                }),
            ),
        )
    }

    @Memoize()
    public get inviteSuggestionsFromNonDomainStream(): ReactiveStream<InviteSuggestion[]> {
        return new ReactiveStream<InviteSuggestion[]>(
            this.invitesFacade.nonDomainInvites.pipe(
                map(invites => invites.slice(0, this.nonDomainlimitAdjusted)),
            ),
        )
    }

    @Memoize()
    public get domainInviteCountStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(
            this.invitesFacade.domainInvites.pipe(
                map(invites => invites.reduce((total, invite) => {
                    return total + invite.interactions[0].numMeetings.total
                }, 0)),
            ),
        )
    }

    @Memoize()
    public get hoursSpentSchedulingDomainStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(
            this.domainInviteCountStream.pipe(
                map(count => Math.floor((count * 6) / 60)),
            ),
        )
    }

    @Memoize()
    public get isInviteSuggestionsLoadingStream(): ReactiveStream<boolean> {
        return this.invitesFacade.isInviteSuggestionsLoadingStream
    }

    public sendInvite(invite: InviteSuggestion): Promise<void> {
        return this.invitesFacade.sendInviteToSuggestion(invite)
    }

    public sendInvites(invites: InviteSuggestion[]): Promise<void> {
        return this.invitesFacade.sendInvitesToSuggestions(invites)
    }

    public dismissInvite(invite: InviteSuggestion) {
        this.invitesFacade.dismissInviteSuggestion(invite)
    }
}

