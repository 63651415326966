import { ApiRoute } from '@undock/api/services/api.route'
import {
    Plan,
} from '@undock/time/plans/contracts/plan.interface'


export class SharedPlansRoute extends ApiRoute {

    public init() {
        this.route = 'plans/shared'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public async listSharedByUUId(userUIds: string[]): Promise<Plan[]> {
        const data = await this.post<Plan[]>()
                               .withData({
                                   userUIds
                               })
                               .endpoint(`list`)

        return data ?? []
    }
}
