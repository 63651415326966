import {
    Input,
    Output,
    Component,
    EventEmitter,
} from '@angular/core'
import { TagOption } from '@undock/common/layout/components/form-elements'


@Component({
    selector: 'app-tags-selector-configurable',
    templateUrl: 'tags-configurable.component.html',
    styleUrls: [
        '../tags-selector/tags.component.scss',
        'tags-configurable.component.scss',
    ]
})
// TODO: Move to the ui-kit module
export class TagsSelectorConfigurableComponent<T = any> {

    @Output() readonly onTagRemoved = new EventEmitter<number>()
    @Output() readonly onAddTagClicked = new EventEmitter<void>()
    @Output() readonly selectedChange = new EventEmitter<T | T[]>()
    @Output() readonly onChange = new EventEmitter<T | T[]>()
    @Output() readonly tagClick = new EventEmitter<[T,boolean]>()

    @Input() iconSize = 18
    @Input() multiple = true
    @Input() required = false
    @Input() disabled = false

    @Input() addTagText: string
    @Input() showAddTagBtn: boolean = false
    @Input() showDeleteIcon: boolean = false

    @Input() size: 'md' | 'lg' = 'lg'

    public tagOptions: Array<TagOption & {
        removable?: boolean,
    }> = []

    private _options: Array<{
        value: T,
        label: string,
        removable?: boolean,
    }> = []

    private _selectedOptions: T[] = []

    @Input() set options(options: Array<{
        value: T,
        label: string,
        removable?: boolean,
    }>) {
        this._options = options
        this.tagOptions = options.map((item, index) => {
            return {
                index,
                label: item.label,
                value: item.value,
                checked: this._selectedOptions.includes(item.value),
                removable: item.removable,
            }
        })
    }

    @Input() set selected(value: T | T[]) {
        this._selectedOptions = Array.isArray(value) ? value : [value]
        this.updateChecked()
    }

    ngOnInit() {
        this.updateChecked()
    }

    private updateChecked() {
        this.tagOptions.forEach((option, index) => {
            option.checked = this._selectedOptions.includes(option.value)
        })
    }

    public toggleTag(option: TagOption) {
        /**
         * Skip any actions when disabled
         */
        if (this.disabled) {
            return null
        }

        const checked = !option.checked

        if (this.required && !checked && 1 === this._selectedOptions.length ) {
            return false
        }

        if (!this.multiple && option.checked) {
            this.tagOptions.forEach(option => option.checked = false)
        }

        option.checked = checked

        this.tagClick.emit([this._options[option.index] as any, checked]);

        if (this.multiple) {
            const selectedOptions = []
            const selectedValues = []
            this.tagOptions.forEach((option, index) => {
                if (option.checked) {
                    selectedValues.push(option.value)
                    selectedOptions.push(this._options[index])
                }
            })
            this._selectedOptions = selectedValues
            this.selectedChange.emit(selectedValues)
            this.onChange.emit(selectedOptions)
        } else {
            this._selectedOptions = option.checked ? [option.value] : []
            this.selectedChange.emit(this._selectedOptions[0])
            this.onChange.emit(this._options[option.index] as any)
        }
    }
}
