import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-icon-remote',
    templateUrl: 'remote.component.html',
    styleUrls: ['remote.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoteIconComponent extends IconTemplateComponent {}
