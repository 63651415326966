enum OnboardingSteps {
  ClaimName = 'claim-name',
  ConnectCalendar = 'connect-calendar',
  Complete = 'complete',
  InstallExtension = 'install-extension',
  InstallExtensionComplete = 'install-extension-complete',
  TrainPlans = 'train-plans',
  TrainMeet = 'train-meet',
  TrainWorkday = 'train-workday',
}

export default OnboardingSteps
