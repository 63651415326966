import {
    Inject,
    Injectable,
    Optional,
} from '@angular/core'

import { IMAGE_VIEWER_KEY } from '@undock/common/ui-kit/contracts/tokens/image-viewer-key'
import { ImageViewerHostComponent } from '@undock/common/ui-kit/ui/components/image-viewer/image-viewer-host.component'


@Injectable()
export class ImageViewerService {

    private host: ImageViewerHostComponent

    public constructor(
        @Optional() @Inject(IMAGE_VIEWER_KEY) private key = 'default',
    ) {}

    public registerHost(instance: ImageViewerHostComponent, key: string) {
        this.host = instance
    }

    public async displayImage(imageUrl: string) {
        this.host.displayImage(imageUrl)
    }
}
