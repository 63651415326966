import { Injectable } from '@angular/core'

import {
    distinctUntilChanged,
    map,
    takeUntil,
    tap,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import {
    Memoize,
    HasAttachments,
} from '@undock/core'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { NotesAdapterInterface } from '@undock/dock/meet/contracts/ui-adapters/notes.adapter'

/**
 * For real-time notes updating with DockFacade
 */
@Injectable()
export class DockFacadeNotesAdapter implements NotesAdapterInterface {

    public readonly editorChangesDebounceTime = 300 // ms

    public readonly isEditModeStream: ReactiveStream<boolean>

    /**
     * Using current dock as an attachment storage for editor
     */
    public readonly attachmentSourceStream: ReactiveStream<HasAttachments>

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private dockFacade: DockFacade,
    ) {
        this.isEditModeStream = this.dockFacade.isEditModeStream
        this.attachmentSourceStream = this.dockFacade.currentDockStream
    }

    @Memoize()
    public get notesTextStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.dockFacade.currentDockStream.pipe(
                map(dock => dock.note ?? ''),
                distinctUntilChanged((prev, next) => {
                    return prev === next
                }),
                takeUntil(this.destroyEvent),
            )
        )
    }

    public setNotes(notes: string): Promise<void> | void {
        return this.dockFacade.setNotes(notes)
    }
}
