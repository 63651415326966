import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import {
    DomainAppCommandsRegistry,
    GlobalAppCommandsRegistry,
} from '@undock/app-commands/services/app-commands.registry'
import { AppCommandsScopeComponent } from './ui/app-commands-scope/app-commands-scope.component'
import { RouterModule } from '@angular/router'
import { AppCommandsModalComponent } from './ui/app-commands-modal/app-commands-modal.component'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'
import { AppCommandsManager } from '@undock/app-commands/services'
import { AppCommandMenuPlanItemComponent } from '@undock/app-commands/ui/app-commands-modal/plan-item/app-command-menu-plan-item.component'
import { PlansIconComponent } from '@undock/app-commands/ui/app-commands-modal/plan-item/icon/plans-icon.component'
import { TimePromptModule } from '@undock/time/prompt/time-prompt.module'


@NgModule({
    declarations: [
        AppCommandsScopeComponent,
        AppCommandsModalComponent,
        AppCommandMenuPlanItemComponent,
        PlansIconComponent,
    ],
    exports: [
        PlansIconComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        UiKitModule,
        TimePromptModule,
    ],
    providers: [
        DomainAppCommandsRegistry,
        AppCommandsManager,
    ],
})
export class AppCommandsProvider {

    static forRoot(): ModuleWithProviders<AppCommandsProvider> {
        return {
            ngModule: AppCommandsProvider,
            providers: [
                GlobalAppCommandsRegistry
            ],
        }
    }
}
