import { SubCalendar } from '@undock/integrations/contracts/synced-calendar/sub-calendar'
import { SyncedCalendarInterface } from '@undock/api/scopes/integrations/contracts/synced-calendar.interface'

export enum AutomatedReschedulingType {
    AllEvents = 'AllEvents',
    UndockEventsOnly = 'UndockEventsOnly',
    Disabled = 'Disabled'
}


export class SyncedCalendarOptions {
    /**
     * If true, events synced from this calendar that were not created with Undock will still include a cancel/reschedule link in the description
     */
    automatedRescheduling: AutomatedReschedulingType

    /**
     * Id of the Schedule to use for rescheduling availability when an event is created on this calendar (outside of Undock) then rescheduled
     * If 'undefined' the standard work schedule is used
     */
    overrideScheduleId?: string | undefined
}

export class SyncedCalendar implements SyncedCalendarInterface {
    public _id?: string
    public email: string // The email account to which this calendar belongs
    public userId?: string // Undock User Id associated with this account

    public provider: string
    public audience?: string

    /**
     * Version of connection
     *
     * Versions less actual lacks permissions or scopes
     */
    public version: number

    public scopes: string[]
    public calendars: SubCalendar[]

    public options: SyncedCalendarOptions

    public isActive: boolean // isEnabled
    public isPrimary: boolean
    public isBlocking: boolean
    public isArchived: boolean
    /**
     * Means the calendar connection is corrupted
     */
    public isInactive: boolean
    public isIntegration: boolean

    public createdAt: Date
    public updatedAt: Date
}
