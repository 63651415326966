import {
    Component,
    Input,
} from '@angular/core'

import { MeetingMode } from '@undock/dock/meet'
import {
    MeetingModeExtended,
    MeetingModeOptionsProvider,
} from '@undock/dock/meet/services/data-providers/meeting-mode-options.provider'
import { EditMeetingViewModel } from '@undock/dock/meet/ui/pages/edit-meeting/view-models/edit-meeting.view-model'
import {
    combineLatest,
    Observable,
} from 'rxjs'
import { ValuesSelectorOption } from '@undock/common/ui-kit'
import { map } from 'rxjs/operators'
import { Memoize } from '@undock/core'


@Component({
    selector: 'app-meet-edit-mode-select',
    templateUrl: 'meeting-mode-select.component.html',
    styleUrls: ['meeting-mode-select.component.scss'],
})
export class MeetingModeSelectComponent {

    @Input() showAudioOnly: boolean = true
    @Input() showBroadcast: boolean = true

    private readonly meetingModeExtendedToDefaultMap = {
        [MeetingModeExtended.Video]: MeetingMode.Video,
        [MeetingModeExtended.Audio]: MeetingMode.Video,
        [MeetingModeExtended.InPerson]: MeetingMode.InPerson,
        [MeetingModeExtended.Broadcast]: MeetingMode.Broadcast,
    }

    public constructor(
        public state: EditMeetingViewModel,
        private meetingModeOptionsProvider: MeetingModeOptionsProvider,
    ) {}

    @Memoize()
    public get isAllowedToChangeModeStream(): Observable<boolean> {
        return combineLatest([
            this.state.isMeetingDraftTypeStream,
            this.state.selectedMeetingModeStream,
        ]).pipe(
            map(sources => {
                const [ isDraftType, mode ] = sources

                return isDraftType || mode === MeetingMode.Video
            })
        )
    }

    @Memoize()
    public get selectedMeetingModeStream(): Observable<MeetingModeExtended> {
        return combineLatest([
            this.state.isAudioOnlyModeStream,
            this.state.selectedMeetingModeStream,
        ]).pipe(
            map(sources => {
                const [ isAudioOnly, meetingMode ] = sources

                if (meetingMode === MeetingMode.Video) {
                    return isAudioOnly ?
                        MeetingModeExtended.Audio : MeetingModeExtended.Video
                }

                return meetingMode as any as MeetingModeExtended
            })
        )
    }

    @Memoize()
    public get availableMeetingModeOptionsStream(): Observable<ValuesSelectorOption<MeetingModeExtended>[]> {
        return combineLatest([
            this.state.isMeetingDraftTypeStream,
            this.meetingModeOptionsProvider.availableExtendedMeetingModeOptionsStream,
        ]).pipe(
            map(sources => {
                let [ isDraft, options ] = sources

                if (!this.showAudioOnly) {
                    options = options.filter(option => option.value !== MeetingModeExtended.Audio)
                }

                if (!this.showBroadcast) {
                    options = options.filter(option => option.value !== MeetingModeExtended.Broadcast)
                }

                return isDraft ? options : options.map(option => ({
                    ...option,

                    disabled: this.meetingModeExtendedToDefaultMap[option.value] === MeetingMode.InPerson
                }))
            })
        )
    }

    public async onMeetingModeChanged(value: MeetingModeExtended) {
        await this.state.selectMeetingMode(
            this.meetingModeExtendedToDefaultMap[value]
        )
        this.state.setIsAudioOnlyMode(value === MeetingModeExtended.Audio)
    }
}
