import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Observable } from 'rxjs'
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { Memoize } from '@undock/core/decorators/memoize'
import { base64ToBlob } from '@undock/core/utils/base64-to-blob'

@Component({
    selector: 'app-image-cropper',
    templateUrl: 'image-cropper.component.html',
    styleUrls: ['image-cropper.component.scss'],
})
export class ImageCropperComponent {

    @Output() onImageReady = new EventEmitter<Blob>()

    @CompleteOnDestroy()
    protected sourceImageSubject = new ValueSubject<Blob>(null)

    @CompleteOnDestroy()
    protected processedImageSubject = new ValueSubject<string>(null)

    @Input('sourceImage')
    public set sourceImage(value: Blob) {
        this.sourceImageSubject.next(value)
    }

    @Input() maintainAspectRatio: boolean = true
    @Input() disabled: boolean = false

    @Memoize()
    public get sourceImageStream(): Observable<Blob> {
        return this.sourceImageSubject.asObservable()
    }

    public async onSaveButtonClick() {
        this.onImageReady.next(
            base64ToBlob(await this.processedImageSubject),
        )

        this.sourceImageSubject.next(null)
    }

    public async onCancelButtonClick() {
        /**
         * Setting file to null to hide cropper popup
         */
        this.sourceImageSubject.next(null)
        this.processedImageSubject.next(null)
    }

    public async onImageCropped(event: ImageCroppedEvent) {
        this.processedImageSubject.next(event.base64)
    }
}
