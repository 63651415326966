import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core'
import {
    AppEventsDispatcher,
    compareDeeply,
    DeviceUtil,
    Memoize,
} from '@undock/core'
import { Router } from '@angular/router'
import {
    combineLatest,
    Observable,
    shareReplay,
    skip,
    takeUntil,
    tap,
} from 'rxjs'
import { Plan } from '@undock/time/plans/contracts/plan.interface'
import { OwnPlansStorage } from '@undock/time/plans/services/states/plans-list.state'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    distinctUntilChanged,
    map,
    startWith,
} from 'rxjs/operators'
import {
    TimeSearchState,
    TimeSearchStateModel,
} from '@undock/time/prompt/states/time-search.state-model'
import {
    ConnectionsFacade,
    UiConnectionData,
} from '@undock/people/services/facades/connections.facade'
import { PlansSearchStateModel } from '@undock/time/plans/services/states/plans-search.state-model'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'
import {
    TimeCommandState,
    TimeCommandViewModel,
} from '@undock/time/prompt/states/time-command.view-model'
import { TimePromptInputComponent } from '@undock/time/prompt/ui/components/time-prompt-input/time-prompt-input.component'
import {
    TimeCommandActions,
    TimeCommandBlueprintEvent,
    TimeCommandBlueprintHold,
} from '@undock/api/scopes/nlp/routes/commands.route'
import {
    FeaturePlansManager,
    FeaturePlansManagerState,
} from '@undock/feature-plans/services/feature-plans.manager'
import { FeaturePlans } from '@undock/api/scopes/subscriptions/contracts/feature-plan.interface'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'


export enum TimePromptView {
    Search = 'Search',
    NewEvent = 'NewEvent',
    PromptLimitReached = 'PromptLimitReached',
    EditActionBlueprint = 'EditActionBlueprint',
}

@UseKeyboardShortcuts({
    takeUntilPropertyKey: 'destroyedEvent',
    allowInputs: true
})
@Component({
    selector: 'app-time-prompt',
    templateUrl: 'time-prompt.component.html',
    styleUrls: ['time-prompt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        TimeCommandViewModel,
        TimeSearchStateModel,
        PlansSearchStateModel,
    ],
})
export class TimePromptComponent {

    public readonly FeaturePlans = FeaturePlans
    public readonly TimeSearchView = TimePromptView

    public readonly searchState: TimeSearchState = this.searchStateModel.state
    public readonly promptState: TimeCommandState = this.commandViewModel.state
    public readonly featurePlansState: FeaturePlansManagerState = this.featurePlansManager.state

    @CompleteOnDestroy()
    public readonly currentTimePromptView$ = new ValueSubject<TimePromptView>(TimePromptView.Search)

    @CompleteOnDestroy()
    protected readonly disableSuggestions$ = new ValueSubject<boolean>(false)

    @Input() planSuggestionCount: number = 1
    @Input() connectionSuggestionCount: number = 2
    @Input() showConnectionSuggestions: boolean = true
    @Input() onlyShowSuggestionsOnFocus: boolean = false

    @Input() autocompleteOpenDirection: 'up' | 'down' = 'down'

    @Output() onBlueprintEventSelected = new EventEmitter<TimeCommandBlueprintEvent>()
    @Output() onBlueprintHoldSelected = new EventEmitter<TimeCommandBlueprintHold>()
    @Output() onClose = new EventEmitter<void>()

    @ViewChild('prompt')
    protected readonly prompt: TimePromptInputComponent

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        public router: Router,
        public commandViewModel: TimeCommandViewModel,

        protected device: DeviceUtil,
        protected plansStorage: OwnPlansStorage,
        protected eventsManager: AppEventsDispatcher,
        protected connectionsFacade: ConnectionsFacade,
        protected searchStateModel: TimeSearchStateModel,
        protected featurePlansManager: FeaturePlansManager,
    ) {
        this.subscribeForPromptResponseChanges()
    }

    @Input() set disableSuggestions(value: boolean) {
        this.disableSuggestions$.next(value)
    }

    @Memoize()
    public get suggestedPlansStream(): Observable<Plan[]> {
        return this.plansStorage.state.suggestedPlansStream.pipe(
            map(commands => commands.slice(0, this.planSuggestionCount)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get suggestedConnectionsStream(): Observable<UiConnectionData[]> {
        return this.connectionsFacade.uiConnections$.pipe(
            map(connections => connections?.length
                ? [...connections].sort(
                    (a, b) => b.lastMetDate.getTime() - a.lastMetDate.getTime()
                ).slice(0,this.connectionSuggestionCount)
                : []),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get searchTermStream(): Observable<string> {
        return this.searchState.searchCriteriaStream.pipe(
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get allSearchResultsLengthStream(): Observable<number> {
        return combineLatest([
            this.searchState.plansSearchResultsStream,
            this.searchState.connectionsSearchResultsStream
        ]).pipe(
            map(([plans, connections]) => plans?.length + connections?.length),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get shouldShowSuggestionsStream(): Observable<boolean> {
        return combineLatest([
            this.allSearchResultsLengthStream,
            this.searchTermStream,
            this.searchState.searchInputFocusStateStream,
            this.disableSuggestions$,
        ]).pipe(
            map(([resultsLength, term, focusState, areSuggestionsDisabled]) =>
                resultsLength === 0 &&
                !term &&
                !areSuggestionsDisabled &&
                (this.onlyShowSuggestionsOnFocus
                    ? focusState === 'focus'
                    : true)
            ),
            startWith(!this.onlyShowSuggestionsOnFocus),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get isLoadingStream(): Observable<boolean> {
        return combineLatest([
            this.searchState.isLoadingStream,
            this.promptState.isLoading$
        ]).pipe(
            map(([isSearchLoading, isPromptLoading]) => isSearchLoading || isPromptLoading),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get hasPartialBlueprintStream(): Observable<boolean> {
        return combineLatest([
            this.promptState.isLoading$,
            this.promptState.blueprint$
        ]).pipe(
            map(([isLoading, blueprint]) => isLoading && Boolean(blueprint)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    public get controlMetaLabel(): string {
        return this.device.isAppleDevice ? 'Cmd' : 'Ctrl'
    }

    public async goToSearchView() {
        if (!(await this.promptState.isLoading$)) {
            this.currentTimePromptView$.next(TimePromptView.Search)
        }
    }

    public async goToNewEventView() {
        if (!(await this.promptState.isLoading$)) {
            this.currentTimePromptView$.next(TimePromptView.NewEvent)
        }
    }

    public async goToEditBlueprintView() {
        this.currentTimePromptView$.next(TimePromptView.EditActionBlueprint)
    }

    public async goToPromptLimitReachedView() {
        this.currentTimePromptView$.next(TimePromptView.PromptLimitReached)
    }

    public async onNewCommandEventUpdated(updatedEvent: TimeCommandBlueprintEvent) {
        if (updatedEvent) {
            if (await this.promptState.currentlyEditedCommandEventActionType$ === TimeCommandActions.Reschedule) {
                await this.commandViewModel.applyRescheduleEventUpdates(updatedEvent)
            } else {
                await this.commandViewModel.applyNewEventUpdates(updatedEvent)
            }
        }
    }

    public async submitPrompt() {
        if (this.prompt) {
            return this.prompt.submitPrompt()
        }
    }

    public viewPlan(plan: Plan) {
        if (plan) {
            if (plan.isPublic) {
                this.router.navigate(['plans', 'explore', plan._id])
            } else {
                this.router.navigate(['plans', plan._id])
            }
        }
        this.onClose.emit()
    }

    public goToPlansDashboard() {
        this.router.navigate(['plans'])
        this.onClose.emit()
    }

    public viewConnection(connection: UiConnectionData) {
        this.router.navigate(['/', 'people', connection.profile.firebaseId])
        this.onClose.emit()
    }

    protected subscribeForPromptResponseChanges() {
        this.commandViewModel.state.response$
            .pipe(
                skip(1),
                distinctUntilChanged(
                    (prev, next) => compareDeeply(prev, next),
                ),
                takeUntil(this.destroyedEvent)
            ).subscribe(response => {
                if (Boolean(response)) {
                    this.goToEditBlueprintView()
                } else {
                    this.goToSearchView()
                }
        })
    }

    @KeyboardShortcut('Esc')
    public async clearPrompt() {
        if (!(await this.promptState.response$) && await this.promptState.isLoading$) {
            await this.commandViewModel.cancelPrompt()
        }
        if (this.prompt) {
            this.prompt.clearSearchInput()
        }
        return this.commandViewModel.clearResponse()
    }

    public async navigateToTheMembershipPage() {
        this.eventsManager.dispatch(
            new TrackUserAnalyticsEvent(
                UserAnalyticsAction.UpgradeSubscriptionClickedBanner,
            ),
        )

        return this.router.navigate(
            ['/', 'settings', 'membership'],
            { queryParams: { upgrade: true } },
        )
    }
}
