import { ApiRoute } from '@undock/api/services/api.route'
import { PartnerAccount } from '@undock/partners/portal/models/portal-account'


export class PortalAccountRoute extends ApiRoute {
    public init() {
        this.route = 'partners/portal/account'
    }

    public async getAccount(): Promise<PartnerAccount> {
        return this.get<PartnerAccount>()
                   .endpoint(``)
    }

    public async changeAccountTitle(title: string): Promise<boolean> {
        return this.post<boolean>()
                   .withData({ title })
                   .endpoint(`change-title`)
    }
}
