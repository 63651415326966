import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-people-icon',
    templateUrl: 'people-icon.component.html',
    styleUrls: ['people-icon.component.scss']
})
export class PeopleIconComponent extends IconTemplateComponent {
    @Input() color: string = '#807A8E'
}
