import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import { EventAttendee } from '@undock/api/scopes/time/contracts/timeline-event/event-attendee.interface'


@Component({
    selector: 'app-dashboard-attendee-list-item',
    templateUrl: 'attendee-list-item.component.html',
    styleUrls: ['attendee-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardAttendeeListItemComponent {

    @Input() attendee: EventAttendee

    public isAttendeeDenied(attendee: EventAttendee): boolean {
        return attendee.status === RsvpStatus.Declined
    }

    public isAttendeeAccepted(attendee: EventAttendee): boolean {
        return attendee.status === RsvpStatus.Accepted
    }

    public isAttendeeNotResponded(attendee: EventAttendee): boolean {
        return attendee.status === RsvpStatus.NeedsAction
    }

    public isAttendeeTentativelyAccepted(attendee: EventAttendee): boolean {
        return attendee.status === RsvpStatus.Tentative
    }
}
