<div class="recorder-container">
    <div class="recorder-container__recorder-inline">
        <ng-container [ngSwitch]="isRecordingRunningStream | async">
            <ng-container *ngSwitchCase="true">
                <button class="recorder-container__record-topic-button -stop-recording" (click)="stopRecording()">
                    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1.82001V12.18C0 12.97 0.87 13.45 1.54 13.02L9.68 7.84001C10.3 7.45001 10.3 6.55001 9.68 6.15001L1.54 0.980008C0.87 0.550008 0 1.03001 0 1.82001Z"
                              fill="white"/>
                    </svg>
                </button>
            </ng-container>

            <ng-container *ngSwitchCase="false">
                <button class="recorder-container__record-topic-button -start-recording" (click)="startRecording()"
                    matTooltip="Add Video"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.92943 2L8 2H9.58334C10.1356 2 10.5833 2.44772 10.5833 3C10.5833 3.55229 10.1356 4 9.58334 4H8C6.55752 4 5.57626 4.00213 4.84143 4.10092C4.13538 4.19585 3.80836 4.36322 3.58579 4.58579C3.36322 4.80836 3.19585 5.13538 3.10092 5.84143C3.00213 6.57625 3 7.55752 3 9V12C3 13.4425 3.00213 14.4238 3.10092 15.1586C3.19585 15.8646 3.36322 16.1916 3.58579 16.4142C3.80836 16.6368 4.13538 16.8042 4.84143 16.8991C5.57625 16.9979 6.55752 17 8 17H11C12.4425 17 13.4238 16.9979 14.1586 16.8991C14.8646 16.8042 15.1916 16.6368 15.4142 16.4142C15.6368 16.1916 15.8042 15.8646 15.8991 15.1586C15.9979 14.4237 16 13.4425 16 12V11.2292C16 10.6769 16.4477 10.2292 17 10.2292C17.5523 10.2292 18 10.6769 18 11.2292V12V12.0705C18 13.4247 18.0001 14.5413 17.8813 15.4251C17.7565 16.3529 17.4845 17.1723 16.8284 17.8284C16.1723 18.4845 15.3529 18.7565 14.4251 18.8813C13.5413 19.0001 12.4247 19 11.0705 19H11H8H7.92946C6.57533 19 5.4587 19.0001 4.57494 18.8813C3.64711 18.7565 2.82768 18.4845 2.17158 17.8284C1.51547 17.1723 1.2435 16.3529 1.11875 15.4251C0.999936 14.5413 0.999966 13.4247 1 12.0706L1 12V9L1 8.92943C0.999966 7.57531 0.999936 6.4587 1.11875 5.57494C1.2435 4.64711 1.51547 3.82768 2.17158 3.17158C2.82768 2.51547 3.64711 2.2435 4.57494 2.11875C5.4587 1.99994 6.57531 1.99997 7.92943 2Z" fill="#2B2733"/>
                        <path d="M7 11.3367V9.66333C7 8.04664 7 7.23829 7.52081 6.95395C8.04162 6.66962 8.72159 7.10674 10.0815 7.98097L11.383 8.81764C12.5615 9.57525 13.1507 9.95405 13.1507 10.5C13.1507 11.046 12.5615 11.4248 11.383 12.1824L10.0815 13.019C8.72159 13.8933 8.04162 14.3304 7.52081 14.046C7 13.7617 7 12.9534 7 11.3367Z" fill="#2B2733"/>
                        <rect x="15" width="2" height="8" rx="1" fill="#2B2733"/>
                        <rect x="12" y="5" width="2" height="8" rx="1" transform="rotate(-90 12 5)" fill="#2B2733"/>
                    </svg>
                </button>
            </ng-container>
        </ng-container>
    </div>

    <ng-template #contentTemplate>
        <ng-container *ngIf="isRecordingRunningStreamDelayed | async">
        <div class="floating-player">
            <button class="close-button" (click)="cancelRecording()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                     fill="none" class="ng-tns-c268-0">
                    <path d="M15 5L5 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="ng-tns-c268-0"></path>
                    <path d="M5 5L15 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="ng-tns-c268-0"></path>
                </svg>
            </button>
            <video autoplay playsinline [volume]="0" [srcObject]="mediaStreamStream | async">
                <source type="video/mp4" src="">
            </video>
            <div class="controls-bar">
                <p class="title short">{{ bottomLabelText }}</p>

                <button class="stop-recording-btn" (click)="stopRecording()">
                    Stop recording
                </button>

                <button class="record-settings">
                    <span class="material-icons">settings</span>
                </button>

                <div class="recording-timer">
                    <span>{{ recordingTimerStream | async }}</span>
                </div>
            </div>
        </div>
        </ng-container>
    </ng-template>

    <ng-template #oldVersionOfRecordingForm>
    <ng-container *ngIf="isRecordingRunningStream | async">
        <section class="recorder-container__attached-video recorder-floating" (click)="cancelRecording()">
            <div class="recorder-floating__wrapper" (click)="$event.preventDefault()">
                <button class="recorder-floating__button-close-player" (click)="cancelRecording()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                         class="ng-tns-c268-0">
                        <path d="M15 5L5 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="ng-tns-c268-0"></path>
                        <path d="M5 5L15 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="ng-tns-c268-0"></path>
                    </svg>
                </button>

                <video
                        autoplay playsinline [volume]="0" [srcObject]="mediaStreamStream | async"
                >
                    <source type="video/mp4" src="">
                </video>

                <div class="recorder-floating__controls-bar">

                    <p class="recorder-floating__header-title">{{ bottomLabelText }}</p>

                    <button class="recorder-floating__button-stop-recording" (click)="stopRecording()">
                        Stop recording
                    </button>

                    <button class="recorder-floating__record-settings">
                        <span class="material-icons">settings</span>
                    </button>

                    <div class="recorder-floating__recording-timer">
                        <span>{{ recordingTimerStream | async }}</span>
                    </div>
                </div>
            </div>
        </section>
    </ng-container>
    </ng-template>
</div>
