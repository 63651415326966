import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    ViewChild,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    combineLatest,
    fromEvent,
    Observable,
} from 'rxjs'
import {
    map,
    takeUntil,
} from 'rxjs/operators'

import { Memoize } from '@undock/core'
import { UserData } from '@undock/user'
import {
    PARTICIPANTS_PROVIDER,
    ParticipantsProviderInterface,
} from '@undock/dock/meet/contracts/ui-providers/participants.provider'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'


@Component({
    selector: 'app-meet-participant-selector',
    templateUrl: 'participant-selector.component.html',
    styleUrls: ['participant-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantSelectorComponent {

    /**
     * This value should be set from the outside
     */
    public title: string

    public onClosed = new EventEmitter<void>()

    /**
     * This emitter should be set from the outside
     */
    public onSelected: EventEmitter<UserData>

    /**
     * This ids stream should be set from the outside
     */
    public selectedIdsStream: ReactiveStream<string[]>


    @ViewChild('searchCriteriaInput')
    private searchCriteriaInputRef: ElementRef<HTMLInputElement>

    @CompleteOnDestroy()
    private isVisibleSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private searchCriteriaSubject = new ValueSubject<string>('')


    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()


    public constructor(
        public profilesProvider: ProfilesProvider,
        @Inject(PARTICIPANTS_PROVIDER)
        private participantsProvider: ParticipantsProviderInterface,
    ) {}

    @Memoize()
    public get isVisibleStream(): ReactiveStream<boolean> {
        return this.isVisibleSubject.asStream()
    }

    @Memoize()
    public get availableParticipantsStream(): Observable<UserData[]> {
        return combineLatest([
            this.selectedIdsStream,
            this.searchCriteriaSubject,
            this.participantsProvider.participantsStream,
        ]).pipe(
            takeUntil(this.destroyedEvent),
            map(sources => {
                let [selectedIds, criteria, participants] = sources

                if (criteria.length > 0) {
                    /**
                     * Rework with participant user data usage instead of userData object
                     */
                    participants = participants.filter(
                        participant => `${participant.email}${participant.displayName}`.match(new RegExp(criteria)),
                    )
                }

                participants.sort((a, b) => {
                    let aSelected = selectedIds.includes(a.id),
                        bSelected = selectedIds.includes(b.id)

                    return (aSelected === bSelected) ? 0 : (aSelected ? -1 : 1)
                })

                /**
                 * Filtering only Undock users.
                 */
                return participants.filter(p => p.id)
            }),
        )
    }


    public ngAfterViewInit() {
        const element = this.searchCriteriaInputRef?.nativeElement

        if (element) {
            fromEvent<KeyboardEvent>(element, 'keyup').pipe(
                takeUntil(this.destroyedEvent),
            ).subscribe(() => {
                this.searchCriteriaSubject.next(element.value ?? '')
            })
        }
    }


    public async open() {
        this.isVisibleSubject.next(true)
    }

    public async close() {
        this.onClosed.next()
        this.isVisibleSubject.next(false)
    }


    public async onParticipantSelected(participant: UserData) {
        const selectedIds = await this.selectedIdsStream
        if (selectedIds && selectedIds.includes(participant.id)) {
            /**
             * If participant already selected
             */
            this.emitParticipantRemoved(participant)
        } else {
            this.emitParticipantSelected(participant)
        }

        return this.close()
    }


    public emitParticipantSelected(participant: UserData) {
        this.onSelected?.emit(participant)
    }

    public emitParticipantRemoved(participant: UserData = null) {
        this.onSelected?.emit(null)
    }
}
