import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-discover',
    templateUrl: 'discover.component.html',
    styleUrls: ['discover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverIconComponent {
    @Input() width: number | string = '179'
    @Input() height: number | string = '122'
}
