import {
    Component,
    OnInit,
} from '@angular/core'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-not-found',
    template: `
        <div class="container-fluid not-found-container" style="display: flex">
            <div class="not-found-image">
                <img src="/assets/404.png" alt="Not Found"/>
            </div>
        </div>
    `,
    styles: [`
        .not-found-container {
            /* height: auto; */
            overflow-y: auto;
            overflow-x: hidden;
            height: 100vh;
            width: 100vw;
        }

        .not-found-image {
            width: 100%;
            height: 100%;
            background-color: transparent;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `],
})
export class NotFoundScreen implements OnInit {

    public constructor(
        private title: Title,
    ) {}

    public ngOnInit(): void {
        this.title.setTitle(`Undock | Not Found`)
    }
}
