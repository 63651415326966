<!-- @TODO: replace with mat-mini-fab -->
<ng-container [ngSwitch]="disabled">
    <ng-container *ngSwitchCase="true">
        <button class="event-more-button expand-button button" mat-button [disabled]="true">
            <i class="material-icons-outlined button-icon">more_horiz</i>
        </button>
    </ng-container>

    <ng-container *ngSwitchCase="false">
        <button class="event-more-button expand-button button" mat-button
                [matMenuTriggerFor]="meetingEdit"
                (click)="$event.stopPropagation()"
        >
            <i class="material-icons-outlined button-icon">more_horiz</i>

            <mat-menu #meetingEdit>
                <ng-container *ngIf="cantChangeRsvpStream | stream; else fullRsvpMenu" class="button">
                    <button mat-menu-item>
                        <span class="material-menu-item-box -rsvp">
                            RSVP
                            <ng-container [ngTemplateOutlet]="rsvpStatuses"></ng-container>
                        </span>
                    </button>
                </ng-container>

                <ng-template #fullRsvpMenu>
                    <button mat-menu-item [matMenuTriggerFor]="rsvpAvailability" class="button">
                        <span class="material-menu-item-box -rsvp">
                            RSVP
                            <ng-container [ngTemplateOutlet]="rsvpStatuses"></ng-container>
                        </span>

                        <mat-menu #rsvpAvailability>
                            <button mat-menu-item class="button">
                                <span class="material-menu-item-box" (click)="rsvpYes()">Yes</span>
                            </button>
                            <button *ngIf="canRsvpNoStream | stream" mat-menu-item class="button">
                                <span class="material-menu-item-box" (click)="rsvpNo()">No</span>
                            </button>
                            <button mat-menu-item class="button">
                                <span class="material-menu-item-box" (click)="rsvpMaybe()">Maybe</span>
                            </button>
                        </mat-menu>
                    </button>
                </ng-template> <!-- #fullRsvpMenu -->

                <ng-template #rsvpStatuses>
                    <ng-container [ngSwitch]="currentUserParticipantStatusStream | stream">
                        <span *ngSwitchCase="DockParticipantStatus.Denied">No</span>
                        <span *ngSwitchCase="DockParticipantStatus.Accepted">Yes</span>
                        <span *ngSwitchCase="DockParticipantStatus.Tentative">Maybe</span>
                    </ng-container>
                </ng-template> <!-- #rsvpStatuses -->

                <ng-container *ngIf="isRescheduleAllowed | stream">
                    <button mat-menu-item (click)="navigateMeetReschedule()" class="button">
                        <a class="material-menu-item-box">Reschedule</a>
                    </button>
                </ng-container>

                <ng-container *ngIf="isDuplicateAllowed | stream">
                    <button mat-menu-item (click)="navigateMeetDuplicate()" class="button">
                        <a class="material-menu-item-box">Duplicate</a>
                    </button>
                </ng-container>

                <button mat-menu-item (click)="meetDelete()" class="button">
                    <a class="material-menu-item-box -delete">Delete</a>
                </button>
            </mat-menu>
        </button>
    </ng-container>
</ng-container>






