import { HttpOptions } from './undock.client'
import {
    HttpHeaders,
    HttpParams,
} from '@angular/common/http'
import { ApiContext } from '@undock/api/contracts/api.context'

export abstract class ApiRoute {
    protected baseURL: string
    protected route: string

    public constructor(protected context: ApiContext) {
        this.baseURL = this.context.config.apiPlatformURL

        this.init()
    }

    /**
     * Override this method to initialize specific route implementation
     */
    abstract init()

    protected get<ResponseData>(): RequestBuilder<ResponseData> {
        return this.prepareRequestBuilder<ResponseData>(RequestType.Get)
    }

    protected post<ResponseData>(): RequestBuilder<ResponseData> {
        return this.prepareRequestBuilder<ResponseData>(RequestType.Post)
    }

    protected delete<ResponseData>(): RequestBuilder<ResponseData> {
        return this.prepareRequestBuilder<ResponseData>(RequestType.Delete)
    }

    protected put<ResponseData>(): RequestBuilder<ResponseData> {
        return this.prepareRequestBuilder<ResponseData>(RequestType.Put)
    }

    protected patch<ResponseData>(): RequestBuilder<ResponseData> {
        return this.prepareRequestBuilder<ResponseData>(RequestType.Patch)
    }

    protected prepareRequestBuilder<ResponseData>(requestMethod: RequestType): RequestBuilder<ResponseData> {
        let requestOptions: HttpOptions = { headers: null }
        let requestData = {}
        let requestBuilder = {
            withHeaders: (headers: HttpRequestHeaders) => {
                requestOptions.headers = headers
                return requestBuilder
            },

            withParams: (params: HttpRequestParams) => {
                requestOptions.params = params
                return requestBuilder
            },

            reportProgress: () => {
                requestOptions.reportProgress = true
                return requestBuilder
            },

            withData: (data: any) => {
                requestData = data
                return requestBuilder
            },

            withCredentials: () => {
                requestOptions.withCredentials = true
                return requestBuilder
            },

            endpoint: async (endpoint: string) => {
                const requestURL = this.buildEndpointURL(endpoint)
                if (requestMethod == RequestType.Get) {
                    return this.context.client.get<ResponseData>(requestURL, requestOptions)
                } else if (requestMethod == RequestType.Post) {
                    return this.context.client.post<ResponseData>(requestURL, requestData, requestOptions)
                } else if (requestMethod == RequestType.Put) {
                    return this.context.client.put<ResponseData>(requestURL, requestData, requestOptions)
                } else if (requestMethod == RequestType.Patch) {
                    return this.context.client.patch<ResponseData>(requestURL, requestData, requestOptions)
                } else if (requestMethod == RequestType.Delete) {
                    return this.context.client.delete<ResponseData>(requestURL, requestOptions)
                }
                return null
            },
        }

        return requestBuilder
    }

    protected buildEndpointURL(endpoint: string) {
        return `${this.baseURL}${this.route}/${endpoint}`
    }
}

type HttpRequestHeaders = HttpHeaders | {
    [header: string]: string | string[];
}

type HttpRequestParams = HttpParams | {
    [param: string]: string | string[];
}

enum RequestType {
    Get = 0,
    Post = 1,
    Put = 2,
    Patch = 3,
    Delete = 4,
}

interface RequestBuilder<ResponseData> {
    withHeaders(headers: HttpRequestHeaders): this

    withParams(params: HttpRequestParams): this

    reportProgress(): this

    withData(data: any): this

    withCredentials(): this

    endpoint(endpoint: string): Promise<ResponseData>
}
