<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 219 166" fill="none">
    <path d="M109.944 146C147.679 146 178.426 115.287 178.426 77.5637C178.299 39.7135 147.679 9 109.944 9C72.0826 9 41.4629 39.7135 41.4629 77.4363C41.4629 115.287 72.0826 146 109.944 146Z" fill="url(#paint0_linear_731_10154)"/>
    <g filter="url(#filter0_d_731_10154)">
        <path d="M183 86.2294C183 100.885 171.184 112.737 156.446 112.737C156.192 112.737 154.032 112.737 138.15 112.737C127.097 112.737 109.436 112.737 81.2308 112.737H67.7632C50.1028 113.12 36 98.9736 36 81.8964C36 64.6918 50.2299 50.4183 68.1443 51.3104C83.5177 3.13737 153.905 9.89178 159.876 59.7215C173.09 61.3783 183 72.5932 183 86.2294Z" fill="url(#paint1_linear_731_10154)"/>
    </g>
    <path d="M156.446 112.737C171.057 112.737 183 100.885 183 86.2295C183 71.5736 171.057 59.7216 156.446 59.7216C141.835 59.7216 129.892 71.5736 129.892 86.2295C129.892 100.885 141.835 112.737 156.446 112.737Z" fill="url(#paint2_linear_731_10154)"/>
    <path d="M113.121 112.992C139.167 112.992 160.385 91.8371 160.385 65.7115C160.385 39.5859 139.167 18.4305 113.121 18.4305C87.0752 18.4305 65.8574 39.5859 65.8574 65.7115C65.8574 91.8371 86.9482 112.992 113.121 112.992Z" fill="url(#paint3_linear_731_10154)"/>
    <path d="M153.327 122.45L129.727 98.7955L135.137 93.3686L158.719 117.041L153.327 122.45Z" fill="#9489BD"/>
    <path d="M161.531 130.773L143.434 112.62C141.923 111.105 141.923 108.634 143.434 107.119C144.945 105.603 147.408 105.603 148.919 107.119L167.016 125.271C168.527 126.787 168.527 129.257 167.016 130.773C165.505 132.288 163.042 132.288 161.531 130.773Z" fill="#9489BD"/>
    <path d="M160.244 131.933L136.96 108.578C136.419 108.036 136.419 107.156 136.96 106.595L142.912 100.625C143.453 100.083 144.33 100.083 144.889 100.625L168.173 123.98C168.714 124.522 168.714 125.402 168.173 125.963L162.221 131.933C161.662 132.476 160.785 132.476 160.244 131.933Z" fill="url(#paint4_linear_731_10154)"/>
    <path d="M103.011 22.5755C78.3094 22.5755 58.291 42.6552 58.291 67.432C58.291 92.19 78.3094 112.288 102.992 112.288C127.693 112.288 147.711 92.2088 147.711 67.432C147.711 42.6552 127.693 22.5755 103.011 22.5755ZM103.011 103.98C83.1787 103.98 67.1155 87.6052 67.1155 67.4133C67.1155 47.2213 83.1787 30.8656 103.011 30.8656C122.842 30.8656 138.905 47.24 138.905 67.432C138.905 87.6239 122.824 103.98 103.011 103.98Z" fill="url(#paint5_linear_731_10154)"/>
    <defs>
        <filter id="filter0_d_731_10154" x="0.890301" y="0.990744" width="217.219" height="164.419" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="17.5548"/>
            <feGaussianBlur stdDeviation="17.5548"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.852319 0 0 0 0 0.824913 0 0 0 0 0.920833 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_731_10154"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_731_10154" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_731_10154" x1="109.944" y1="9" x2="109.944" y2="146" gradientUnits="userSpaceOnUse">
            <stop stop-color="#EDE9F7"/>
            <stop offset="1" stop-color="#E2DCF1"/>
        </linearGradient>
        <linearGradient id="paint1_linear_731_10154" x1="109.5" y1="57.4724" x2="109.452" y2="113.76" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.9964" stop-color="#F0EDF8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_731_10154" x1="127.066" y1="54.0145" x2="150.684" y2="79.767" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D2C8E8"/>
            <stop offset="0.9942" stop-color="#E6E2F2" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_731_10154" x1="60.8278" y1="8.25105" x2="102.962" y2="54.0963" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D2C8E8"/>
            <stop offset="0.9942" stop-color="#E6E2F2" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_731_10154" x1="136.54" y1="116.283" x2="168.584" y2="116.283" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BBB4D4"/>
            <stop offset="1" stop-color="#AAA2CA"/>
        </linearGradient>
        <linearGradient id="paint5_linear_731_10154" x1="58.2501" y1="67.4417" x2="147.728" y2="67.4417" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BBB4D4"/>
            <stop offset="1" stop-color="#AAA2CA"/>
        </linearGradient>
    </defs>
</svg>
