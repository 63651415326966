import { Injectable } from '@angular/core'

import { CurrentUser } from '@undock/session'
import {
    AbstractFactory,
    ArrayHelpers,
} from '@undock/core'
import { IntegrationsManager } from '@undock/integrations'
import { Schedule } from '@undock/dock/meet/models/schedule.model'
import { MeetingMode } from '@undock/dock/meet/contracts/dock/meeting-mode'
import { UserAvailableMeetingLength } from '@undock/api/scopes/user/contracts/user.interface'


@Injectable({ providedIn: 'root' })
export class MeetingTypeFactory extends AbstractFactory<Schedule> {

    protected defaultProperties: Partial<Schedule> = {
        url: '',
        label: '',
        removed: false,
        isPrivate: false,
        participants: [],
        allowedModes: [
            MeetingMode.Audio,
            MeetingMode.Video,
            MeetingMode.InPerson,
        ],
        // @TODO: Remove this property
        mode: MeetingMode.Video,
    }

    public constructor(
        private currentUser: CurrentUser,
        private integrationsManager: IntegrationsManager,
    ) { super() }


    public async create(properties: Partial<Schedule> = {}): Promise<Schedule> {
        const entity = new Schedule()

        const [
            user, settings, calendar,
        ] = await Promise.all([
            this.currentUser.data,
            this.currentUser.settings,
            this.integrationsManager.primaryCalendarStream,
        ])

        this.assignDefaultData(entity, {

            userId: user._id,
            userUId: user.firebaseId,

            /**
             * At default use primary calendar as default
             */
            syncedCalendarId: calendar?._id,

            /**
             * Using setting values as defaults
             */
            availability: settings.availableSlots,

            conferenceLinkType: settings.conferenceLinkPreference,

            customConferenceLink: settings.conferenceLinkPreference === 'custom' ?
                settings?.defaultMeetingContactInfo?.meetingLink : null,

            /**
             * Some users have a `availabilityIntervals` property with Mongo `_id` property set.
             */
            availableDurationValues: this.prepareAvailableDurationValues(settings.availabilityIntervals),

            ...properties,

            participants: ArrayHelpers.filterUnique(
                [
                    {
                        id: user._id,
                        uid: user.firebaseId,
                        email: user.email,
                        firstName: user.firstname,
                        lastName: user.lastname,
                        isGuest: false,
                        isUndockUser: true,
                        isRegularUser: true,
                        isScheduleOwner: true,
                    },
                    ...(properties.participants ?? []),
                ],
                (a, b) => a.email === b.email,
            )
        })

        return entity
    }

    /**
     * Casts all keys to integer type and removes all redundant properties
     */
    protected prepareAvailableDurationValues(values: Record<UserAvailableMeetingLength, boolean>): Record<UserAvailableMeetingLength, boolean> {
        const result = {} as Record<UserAvailableMeetingLength, boolean>

        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                const durationValue = parseInt(key)

                if (durationValue && Number.isInteger(durationValue)) {
                    result[durationValue] = values[durationValue]
                }
            }
        }

        /**
         * 1h30m interval is deprecated
         */
        delete result['90']

        return result
    }
}
