import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Optional,
} from '@angular/core'
import { AppCommandsManager } from '@undock/app-commands/services/app-commands.manager'
import { AppCommand } from '@undock/app-commands/contracts/app-commands'
import { ValueSubject } from '@typeheim/fire-rx'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'

@UseKeyboardShortcuts()
@Component({
    selector: 'web-app-commands-scope',
    templateUrl: './app-commands-scope.component.html',
    styleUrls: ['./app-commands-scope.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCommandsScopeComponent implements OnInit, OnDestroy {
    public readonly showAppCommandsModalStream = new ValueSubject(false)

    constructor(
        private commandsManager: AppCommandsManager,
        @Optional() @Inject(AppCommand) private commands: AppCommand[]
    ) {
        if (!this.commands || this.commands?.length < 1) {
            this.commands = []
        }
    }

    ngOnInit(): void {
        this.commands.forEach(command => this.commandsManager.register(command))
    }

    ngOnDestroy(): void {
        this.commands.forEach(command => this.commandsManager.unregister(command))
    }

    @KeyboardShortcut('Control./, Command./')
    public toggleAppCommandsMenu() {
        this.showAppCommandsModalStream.next(!this.showAppCommandsModalStream.value)
    }

    @KeyboardShortcut('Escape')
    public closeCommandsMenuIfOpen() {
        if (this.showAppCommandsModalStream.value) {
            this.showAppCommandsModalStream.next(false)
        }
    }
}
