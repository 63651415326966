import {
    Injectable,
    OnDestroy,
} from '@angular/core'

import {
    combineLatest,
    Observable,
    Subject,
    Subscription,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    StateModel,
    StreamStore,
} from '@undock/core/states'

import {
    compareDeeply,
    Memoize,
} from '@undock/core'
import {
    MeetingMode,
    Schedule,
    toLegacyMeetingMode,
} from '@undock/dock/meet'
import {
    DateRange,
    MomentRange,
} from '@undock/time/availability'
import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'
import {
    MeetingDuration,
    AvailabilityService,
} from '@undock/time/availability/services/availability.service'
import { RandomStringGenerator } from '@undock/core/utils/random-string-generator.util'
import { SuggestedRangesOptions } from '@undock/api/scopes/profile/requests/group-availability.request'
import { SchedulesManager } from '@undock/dock/meet/contracts/schedules-manager'

export type AvailabilityDataSource<T = any> = T | Observable<T>

export interface AvailabilityProviderInitData {
    // Enables V2 availability
    v2?: AvailabilityDataSource<boolean>

    // Required properties for loading an availability
    emails: AvailabilityDataSource<string[]>
    timeZone: AvailabilityDataSource<string>
    dateRange: AvailabilityDataSource<DateRange | MomentRange>
    meetingMode: AvailabilityDataSource<MeetingMode>
    meetingDuration: AvailabilityDataSource<MeetingDuration>

    // Optional properties for loading an availability
    schedule?: AvailabilityDataSource<Schedule>,
    bookingCode?: AvailabilityDataSource<string>
    // @warning: Isn't supported by availability V1
    optionalEmails?: AvailabilityDataSource<string[]>
    // @deprecated TODO: Use v2 to reschedule meetings by booking-code
    rescheduleMeetingId?: AvailabilityDataSource<string>
    availabilityFilterFn?: AvailabilityDataSource<Function>
    includeSuggestedRanges?: AvailabilityDataSource<boolean>
    suggestedRangesOptions?: AvailabilityDataSource<SuggestedRangesOptions>
}

@Injectable()
export class AvailabilityProvider<T = AvailabilityProviderStore> extends StateModel<T> implements OnDestroy {
    //
    protected readonly store = new AvailabilityProviderStore()

    // The list of subscriptions for all data sources
    protected dataSourceSubscriptions = new Map<string, Subscription>()

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    // Mapping between data sources and destinations
    protected dataSourceMap: Record<keyof AvailabilityProviderInitData, Subject<any>> = {
        v2: this.store.v2$,
        emails: this.store.emails$,
        timeZone: this.store.timeZone$,
        dateRange: this.store.dateRange$,
        meetingMode: this.store.meetingMode$,
        meetingDuration: this.store.meetingDuration$,

        // Optional parameters
        schedule: this.store.schedule$,
        bookingCode: this.store.bookingCode$,
        optionalEmails: this.store.optionalEmails$,
        rescheduleMeetingId: this.store.rescheduleMeetingId$,
        availabilityFilterFn: this.store.availabilityFilterFn$,
        includeSuggestedRanges: this.store.includeSuggestedRanges$,
        suggestedRangesOptions: this.store.suggestedRangesOptions$,
    }

    // Default values for optional data sources
    protected optionalDataSourceDefaultsMap: Partial<Record<keyof AvailabilityProviderInitData, any>> = {
        v2: false,
        schedule: null,
        bookingCode: null,
        optionalEmails: [],
        rescheduleMeetingId: null,
        availabilityFilterFn: null,
        includeSuggestedRanges: false,
        suggestedRangesOptions: {} as SuggestedRangesOptions,
    }

    public constructor(
        protected schedulesManager: SchedulesManager,
        protected availabilityService: AvailabilityService,
    ) {
        super()
    }

    @Memoize()
    public get availabilityStream(): ReactiveStream<AvailabilitySet[]> {
        return new ReactiveStream<AvailabilitySet[]>(
            combineLatest([
                this.store.v2$,
                this.store.emails$,
                this.store.timeZone$,
                this.store.dateRange$,
                this.store.meetingMode$,
                this.store.meetingDuration$,
                this.store.schedule$,
                this.store.optionalEmails$,
                this.store.bookingCode$,
                this.store.rescheduleMeetingId$,
                this.store.includeSuggestedRanges$,
                this.store.suggestedRangesOptions$,
            ]).pipe(
                map(sources => {
                    // Audio mode isn't supported here
                    if (sources[4] === MeetingMode.Audio) {
                        sources[4] = MeetingMode.Video
                    }

                    // 30 mins is minimum duration supported by v1
                    if (!sources[0] && sources[5] < 30) {
                        sources[5] = 30
                    }

                    // After adding guest calendar stream to combineLatest,
                    // without specifying the types for each element in the sources array the typing breaks
                    return sources as [
                        boolean,
                        string[],
                        string,
                        DateRange,
                        MeetingMode,
                        MeetingDuration,
                        Schedule,
                        string[],
                        string,
                        string,
                        boolean,
                        SuggestedRangesOptions,
                    ]
                }),

                distinctUntilChanged(
                    (...values) => compareDeeply(values[0], values[1]),
                ),

                // Prevents multiple availability requests at the same time
                debounceTime(100),

                switchMap(([
                    v2,
                    emails,
                    tz,
                    datesRange,
                    mode,
                    duration,
                    schedule,
                    optionalEmails,
                    bookingCode,
                    rescheduleMeetingId,
                    includeSuggestedRanges,
                    suggestedRangesOptions,
                ]) => {
                    return this.store.forceReloadGroupAvailability$.pipe(
                        tap(
                            () => this.store.isAvailabilityLoading$.next(true),
                        ),
                        switchMap(() => {
                            if (v2) {
                                let scheduleKey: string
                                if (schedule) {
                                    scheduleKey = this.schedulesManager.getScheduleKey(schedule)
                                }
                                return this.availabilityService.getAvailabilityV2({
                                    start: datesRange.start.toISOString(),
                                    end: datesRange.end.toISOString(),
                                    duration: duration,
                                    participants: emails,
                                    optionalParticipants: optionalEmails,
                                    timeZone: tz,
                                    meetingMode: mode,
                                    bookingCode: bookingCode,
                                    scheduleKey: scheduleKey,
                                    slotScoresAccuracy: 'medium',
                                    includeSuggestedRanges,
                                    suggestedRangesOptions,
                                })
                            }

                            const modeLegacy = toLegacyMeetingMode(mode)
                            if (rescheduleMeetingId) {
                                return this.availabilityService.getRescheduleAvailability(
                                    rescheduleMeetingId,
                                    datesRange.start.valueOf(), datesRange.end.valueOf(), emails,
                                    duration, modeLegacy, tz,
                                    null,
                                    schedule,
                                    includeSuggestedRanges, suggestedRangesOptions,
                                )
                            } else {
                                return this.availabilityService.getGroupAvailability(
                                    datesRange.start.valueOf(), datesRange.end.valueOf(), emails,
                                    duration, modeLegacy, tz,
                                    null,
                                    schedule,
                                    includeSuggestedRanges, suggestedRangesOptions,
                                )
                            }
                        }),
                        map(response => {
                            if (response?.bestSlot && response.bestSlot?.bestTime) {
                                this.store.bestAvailableSlotTimeStamp$.next(response.bestSlot.bestTime)
                            }
                            return response?.availability ?? []
                        }),
                        // Apply any available slot filters/additions
                        withLatestFrom(this.store.availabilityFilterFn$),
                        map(([availability, filterFn]) => {
                            if (availability?.length) {
                                for (let day of availability) {
                                    const maxScore = day.slots.reduce((acc, slot) => Math.max(acc, slot.score), 0)
                                    const maxScoreSlot = day.slots.find(slot => slot.score === maxScore)
                                    if (maxScoreSlot) {
                                        maxScoreSlot.best = true
                                    }
                                    day.slots.forEach(slot => slot.recommended = !slot.best && slot.score >= 80)
                                }
                                if (filterFn) {
                                    availability = filterFn(availability)
                                }
                            }
                            return availability
                        }),
                        tap(() => this.store.isAvailabilityLoading$.next(false)),
                    )
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get suggestedAvailableSlotStream(): ReactiveStream<AvailabilitySlot> {
        return new ReactiveStream<AvailabilitySlot>(
            combineLatest([
                this.availabilityStream,
                this.store.isAvailabilityLoading$,
                this.store.bestAvailableSlotTimeStamp$,
            ]).pipe(
                filter(([availability, isLoading, timeStamp]) => {
                    // Skip emit suggested slot when availability is loading
                    if (isLoading) {
                        return false
                    }

                    // Should emit null if there is no suggested slot provided
                    if (!timeStamp) {
                        return true
                    }

                    for (let set of availability) {
                        if (set.day.isSame(timeStamp, 'day')) {
                            return true
                        }
                    }

                    /**
                     * This is probably the case when availability stream contains
                     * data for previously loaded range, but suggested slot is new
                     */
                    return false
                }),

                map(([availability, _, timeStamp]) => {
                    if (availability && timeStamp) {
                        return this.availabilityService
                                   .findSlotByTimestamp(availability, timeStamp)
                    }
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    // TODO: Replace usages with `state.isAvailabilityLoading$`
    @Memoize()
    public get isAvailabilityLoadingStream(): ReactiveStream<boolean> {
        return this.store.isAvailabilityLoading$.asStream()
    }

    public async initialize(data: AvailabilityProviderInitData) {
        for (let key in this.dataSourceMap) {
            if (data.hasOwnProperty(key)) {
                this.applyDataSource(data[key], this.dataSourceMap[key])
            } else {
                if (this.optionalDataSourceDefaultsMap.hasOwnProperty(key)) {
                    /**
                     * Trying to assign default value to the data source
                     */
                    this.applyDataSource(
                        this.optionalDataSourceDefaultsMap[key], this.dataSourceMap[key],
                    )
                } else {
                    throw new Error(`Required data source ${key} is missing.`)
                }
            }
        }
    }

    public forceReloadAvailability(): void {
        this.store.forceReloadGroupAvailability$.next()
    }

    // Bind source value to internal stateful subject
    protected applyDataSource<TargetType>(
        source: AvailabilityDataSource<TargetType>, destination: Subject<TargetType>,
    ) {
        // Generate unique id for destination subject
        if (!destination['__destinationId']) {
            destination['__destinationId'] = RandomStringGenerator.generateRandomStringOfLetters(4)
        }

        if (this.dataSourceSubscriptions.has(destination['__destinationId'])) {
            // Should unsubscribe from the previous subscription when value is updated
            this.dataSourceSubscriptions.get(destination['__destinationId']).unsubscribe()
        }

        if (source instanceof Observable) {
            // Save new subscription
            this.dataSourceSubscriptions.set(
                destination['__destinationId'],
                source.pipe(
                    distinctUntilChanged(
                        (prev, next) => compareDeeply(prev, next),
                    ),
                    takeUntil(this.destroyedEvent),
                ).subscribe(
                    value => destination.next(value)
                ),
            )
        } else {
            destination.next(source)
        }
    }

    public ngOnDestroy() {
        // Unsubscribe from all data source subscriptions
        this.dataSourceSubscriptions.forEach(
            subscription => subscription.unsubscribe(),
        )
    }
}

export class AvailabilityProviderStore extends StreamStore {
    // Availability params
    public readonly v2$ = new StatefulSubject<boolean>()
    public readonly emails$ = new StatefulSubject<string[]>()
    public readonly timeZone$ = new StatefulSubject<string>()
    public readonly schedule$ = new StatefulSubject<Schedule>()
    public readonly dateRange$ = new StatefulSubject<DateRange>()
    public readonly meetingMode$ = new StatefulSubject<MeetingMode>()
    public readonly meetingDuration$ = new StatefulSubject<MeetingDuration>()
    public readonly optionalEmails$ = new StatefulSubject<string[]>()
    public readonly bookingCode$ = new StatefulSubject<string>()
    public readonly availabilityFilterFn$ = new StatefulSubject<Function>()
    public readonly includeSuggestedRanges$ = new StatefulSubject<boolean>()
    public readonly suggestedRangesOptions$ = new StatefulSubject<SuggestedRangesOptions>()

    // Internal data
    public readonly isAvailabilityLoading$ = new ValueSubject<boolean>(false)
    public readonly bestAvailableSlotTimeStamp$ = new ValueSubject<string>(null)
    public readonly forceReloadGroupAvailability$ = new ValueSubject<void>(null)

    /**
     * Restores availability slot blocked by this meeting
     * @deprecated TODO: Use v2 booking-code property
     */
    public readonly rescheduleMeetingId$ = new StatefulSubject<string>()
}
