import { ApiRoute } from '@undock/api/services/api.route'


export class EventsRoute extends ApiRoute {

    public init() {
        this.route = 'calendar/events'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public deleteById(eventId: string) {
        return this.delete<void>()
                   .endpoint(`id/${eventId}`)
    }
}
