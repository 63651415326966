import { ReactiveStream } from '@typeheim/fire-rx'
import {
    HasAttachments,
    Model,
} from '@undock/core'
import { InjectionToken } from '@angular/core'


export interface TopicsAdapterInterface {
    isEditModeStream: ReactiveStream<boolean>
    ownerModelStream: ReactiveStream<Model & HasAttachments>
}

export const TOPICS_ADAPTER = new InjectionToken('TOPICS_ADAPTER')
