import { Injectable } from '@angular/core'

import {
    HandleErrorStrategy,
    LocalStorage,
} from '@undock/core'
import { AuthException } from '../../exceptions'


import * as moment from 'moment'

@Injectable()
export class AuthErrorsStrategy extends HandleErrorStrategy {

    public readonly position = 0
    public readonly errorType = AuthException

    private readonly auth0CleanupDelay: number = 30000 // 30 seconds
    private readonly lastAuth0CleanupLSKey = '@undock[lastAuth0Cleanup]'

    public constructor(
        private storage: LocalStorage,
    ) { super() }

    public handle(error: Error) {
        if (this.storage.getItem(this.lastAuth0CleanupLSKey)) {
            let lastCleanup = moment(this.storage.getItem(this.lastAuth0CleanupLSKey))

            const difference = Math.abs(
                moment().diff(lastCleanup, 'milliseconds', true),
            )

            setTimeout(async () => {
                await this.cleanAuth0LSKeysAndReloadThePage()

                /**
                 * Scheduling next LS cleanup and page reloading for 30 seconds.
                 */
            }, (difference < this.auth0CleanupDelay) ? this.auth0CleanupDelay - difference : 0)
        } else {
            this.cleanAuth0LSKeysAndReloadThePage()
        }
    }

    protected cleanAuth0LSKeysAndReloadThePage() {
        if (window.localStorage) {
            window.localStorage.clear()
        }
        this.storage.setItem(this.lastAuth0CleanupLSKey, moment().toISOString())

        let cookies = document.cookie.split('; ')
        for (let c = 0; c < cookies.length; c++) {
            let d = window.location.hostname.split('.')
            while (d.length > 0) {
                let cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='
                let p = location.pathname.split('/')
                document.cookie = cookieBase + '/'
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/')
                    p.pop()
                }

                d.shift()
            }
        }

        window.location = window.location
    }
}
