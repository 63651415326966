<mat-select
    #select
    class="customArrow"
    [value]="value"
    [compareWith]="compareFn"
    [panelClass]="panelClass"
    (selectionChange)="onSelect.emit($event.value)"
>
    <mat-option [disabled]="true" class="quick-filter-wrapper">
        <app-select-filter-input [formControl]="optionsFilterCtrl"
                                 [ignoredOption]="ignoreOption"></app-select-filter-input>
    </mat-option>

    <mat-option *ngFor="let optionItem of filteredOptions | async"
                [value]="optionItem"
                [disabled]="isHidden(optionItem)"
                [ngClass]="{hidden: isHidden(optionItem) }"
    >
        {{ labelFn(optionItem) }}
    </mat-option>

    <mat-select-trigger>
        <div #matSelectTriggerWrapper><ng-content select="mat-select-trigger"></ng-content></div>
        <ng-container *ngIf="!matSelectTriggerWrapper?.innerText?.trim()?.length">
            {{ selectedLabelFn ? selectedLabelFn( value ) : labelFn( value ) }}
        </ng-container>
    </mat-select-trigger>

</mat-select>
