import {
    Injectable,
    OnDestroy,
} from '@angular/core'

import {
    Observable,
    Subject,
    Subscription,
} from 'rxjs'
import { EmitOnDestroy } from '@typeheim/fire-rx'


/**
 * @internal
 */
interface ActionsSource {
    sourceId: string
    inputStream: Observable<string>
    outputStream: Subject<ActionItemMatchedEvent>,
    sourceSubscription: Subscription
}

export interface ActionItemMatchedEvent {
    sourceId: string
    sourceText: string
    itemsNames: string[] // Item names without list marks
    itemsMatched: string[] // Matched items as were in the text
}

@Injectable()
export class EditorActionsDetector implements OnDestroy {
    /**
     * Stores all text streams, key - dockId.
     */
    private sources: Record<string, ActionsSource> = {}

    /**
     * Stream of events from all sources.
     */
    private sourceActionsSubject = new Subject<ActionItemMatchedEvent>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new Subject<void>()


    public get sourceActionsStream(): Observable<ActionItemMatchedEvent> {
        return this.sourceActionsSubject.asObservable()
    }


    public registerTextActionsSource(
        sourceId: string, textActionsSource: Observable<string>,
    ): Observable<ActionItemMatchedEvent> {

        if (this.sources.hasOwnProperty(sourceId)) {
            /**
             * If source is already registered
             */
            this.unregisterTextActionsSource(sourceId)
        }

        let subscription = textActionsSource.subscribe((text) => {
            this.onTextActionSourceChanged(text, this.sources[sourceId])
        })

        this.sources[sourceId] = {
            sourceId: sourceId,
            inputStream: textActionsSource,
            outputStream: new Subject<any>(),
            sourceSubscription: subscription,
        }

        return this.sources[sourceId].outputStream.asObservable()
    }

    public unregisterTextActionsSource(sourceId) {
        if (this.sources.hasOwnProperty(sourceId)) {
            this.sources[sourceId].outputStream.unsubscribe()
            this.sources[sourceId].sourceSubscription.unsubscribe()
            delete this.sources[sourceId]
        }
    }

    private onTextActionSourceChanged(text: string, source: ActionsSource) {
        let listItems = this.detectLists(text)

        if (listItems.length > 0) {
            /**
             * Pushing text to the source to perform some actions.
             * Example: component may delete this list.
             */
            let event = {
                sourceId: source.sourceId,
                sourceText: text,
                itemsMatched: listItems,
                itemsNames: this.parseItemNamesFromDetectedList(listItems),
            } as ActionItemMatchedEvent

            source.outputStream.next(event)
            this.sourceActionsSubject.next(event)
        }
    }

    /**
     * Searches for any types of lists in the given text.
     * Possible patterns:
     *      1. The numbered item with dot.
     *      2  The numbered item without dot.
     *      * Non numbered item type 1
     *      - Non numbered item type 2
     *
     * @param {String} input
     * @return String[]
     */
    protected detectLists(input: string): string[] {
        const listItemRegExp = /^[1-9\-*]+\.?\s+.*$/gm
        return input.match(listItemRegExp) || []
    }

    /**
     * @param {String[]} items
     * @return String[]
     */
    protected parseItemNamesFromDetectedList(items: string[]): string[] {
        const removeListMarksRegExp = /^[0-9\-*]+\.?\s?/g

        return items.map(item => {
            return item.replace(removeListMarksRegExp, '')
        })
    }

    public ngOnDestroy(): void {
        for (let sourceId in this.sources) {
            if (this.sources.hasOwnProperty(sourceId)) {
                this.unregisterTextActionsSource(sourceId)
            }
        }

        this.sourceActionsSubject.unsubscribe()

        this.destroyedEvent.next()
        this.destroyedEvent.unsubscribe()
    }
}
