import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = 'currentColor'
    //@Input() color: string = '#2B2733' // should be same as $color-primary in theme.scss
    @Input() borderColor: string = ''
    @Input() rotated: boolean = false

    /**
     * @deprecated
     */
    get viewBox(): string {
        return `0 0 ${this.width} ${this.height}`
    }
}
