<ng-container *ngFor="let column of columnsArray; let index = index">
    <div class="separator" *ngIf="index"></div>
    <div class="column">
        <ngx-skeleton-loader
                *ngFor="let i of structureArray"
                class="timeslotItem"
                [theme]="{height: '40px', borderRadius: '8px', width: '100%', marginBottom: '4px', backgroundColor: '#FBFBFD'}"
        ></ngx-skeleton-loader>
    </div>
</ng-container>
