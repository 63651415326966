<div class="organizations-container"
     *ngIf="{
            isExpanded: state.isExpandedStream | stream
         } as view"
>
    <div class="header">
        <div class="label">
            Organizations
        </div>

        <div class="add-organization clickable" [routerLink]="['', 'organizations', 'new']">
            <app-plus-small-icon
                [height]="22" [width]="22" [color]="'#807A8E'"
                [matTooltip]="view.isExpanded ? '' :'New Organization'" [matTooltipPosition]="'right'"
            ></app-plus-small-icon>
        </div>
    </div>

    <div class="organizations-list"
         *ngIf="(organizations$ | stream) as organizations; else organizationsSkeletonTmpl"
    >
        <div class="organization-list-item"
             *ngFor="let organization of organizations"
             [routerLink]="['/', organization.handle]"
             [matTooltip]="view.isExpanded ? '' : organization.name"
             [matTooltipPosition]="TooltipPosition.RIGHT"
        >
            <div class="ud-avatar avatar-24">
                <img *ngIf="organization.logoUrl"
                     [src]="organization.logoUrl | imgThumbnail" alt
                />
            </div>

            <div class="name">
                {{ organization.name }}
            </div>
        </div>
    </div>

    <ng-template #organizationsSkeletonTmpl>
        <div class="organizations-list">
            <div class="organization-list-item" *udRepeat="cachedOrganizationsCount$ | stream">
                <div class="logo">
                    <ngx-skeleton-loader appearance="circle" [theme]="{width: '24px', height: '24px'}"></ngx-skeleton-loader>
                </div>
                <div class="name">
                    <ngx-skeleton-loader [theme]="{width: '100%', height: '20px', 'border-radius': '6px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </ng-template>
</div>
