<div class="action-item-form">
    <form autocomplete="off" class="topic-form">
        <div class="topic-status" *ngIf="showCheck">
            <button type="button" class="complete-topic-button"
                    (click)="toggleCompleteStatus()"
                    [matTooltipPosition]="TooltipPosition.BOTTOM"
                    [@isComplete]="(isCompletedStream | stream) ? 'true' : 'false'"
                    [matTooltip]="(isCompletedStream | stream) ? 'Unresolve' : 'Resolve'"
            >
                <span class="material-icons">done</span>
            </button>
        </div>

        <input name="topic-title" autocomplete="off" data-private class="action-item-form__topic-text"
               type="text" #topicTextInput placeholder="Enter new topic..."

               [ngClass]="isConfMode ? '-conference-view' : '-dock-view'"
               [(ngModel)]="topicText" (keyup)="onItemInputKeyUp($event)"
               [ngModelOptions]="{standalone: true}"
        />
    </form>
</div>
