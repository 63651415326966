import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core'

import {
    bufferWhen,
    Subject,
} from 'rxjs'
import {
    debounceTime,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'

import {
    MeetingDurationOption,
    Schedule,
} from '@undock/dock/meet'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { PartnerChargeAccountInterface } from '@undock/api/scopes/charges/contracts/charge-account.interface'


@UseKeyboardShortcuts({
    takeUntilPropertyKey: 'destroyedEvent',
})
@Component({
    selector: 'app-availability-duration-select',
    templateUrl: 'duration-select.component.html',
    styleUrls: ['duration-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityDurationSelectComponent implements OnInit {

    @Output() onChange = new EventEmitter<MeetingDuration>()

    @Input() value: MeetingDuration
    @Input() options: MeetingDurationOption[]

    @Input() schedule?: Schedule
    @Input() paymentGroup?: PartnerChargeAccountInterface

    private incrementDurationEventStream: Subject<Event> = new Subject<Event>()
    private decrementDurationEventStream: Subject<Event> = new Subject<Event>()

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public ngOnInit() {
        this.initializeEventStreams()
    }

    public onMeetingDurationValueChange(value: string) {
        return this.onChange.emit(parseInt(value))
    }

    @KeyboardShortcut('Shift.+')
    protected emitIncrementMeetingDurationEvent(event: UIEvent) {
        this.incrementDurationEventStream.next(event)
    }

    @KeyboardShortcut('-')
    protected emitDecrementMeetingDurationEvent(event: UIEvent) {
        this.decrementDurationEventStream.next(event)
    }

    private async moveToDuration(moveByCount: number) {
        let currentIndex = this.options.findIndex(d => d.value == this.value)
        if (currentIndex !== -1) {
            currentIndex += moveByCount
            if (currentIndex > this.options.length - 1) {
                currentIndex = this.options.length - 1
            }
            else if (currentIndex < 0) {
                currentIndex = 0
            }
            this.onMeetingDurationValueChange(this.options[currentIndex].value.toString())
        }
    }

    private initializeEventStreams() {
        if (this.decrementDurationEventStream) {
            this.decrementDurationEventStream.pipe(
                bufferWhen(() => this.decrementDurationEventStream.pipe(debounceTime(300))),
                takeUntil(this.destroyEvent),
            ).subscribe((events: Event[]) => {
                this.moveToDuration(-events.length)
            })
        }

        if (this.incrementDurationEventStream) {
            this.incrementDurationEventStream.pipe(
                bufferWhen(() => this.incrementDurationEventStream.pipe(debounceTime(300))),
                takeUntil(this.destroyEvent),
            ).subscribe((events: Event[]) => {
                this.moveToDuration(events.length)
            })
        }
    }
}
