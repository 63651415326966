import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
} from '@angular/core'
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations'
import { Clipboard } from '@angular/cdk/clipboard'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { User } from '@undock/user'
import {
    Config,
    ExtConnector,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import { ProfileLinksManager } from '@undock/profile/shared/services/profile-links.manager'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { AuthManager } from '@undock/auth'

@Component({
    selector: 'app-controls-panel',
    templateUrl: 'controls-panel.component.html',
    styleUrls: [`controls-panel.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('toggleToastView', [
            state('show', style({
                top: '0',
                opacity: '1',
            })),
            state('hide', style({
                bottom: '-100vh',
                opacity: '0',
                overflow: 'hidden',
            })),
            transition('hide <=> show', [
                animate('0.2s'),
            ]),
        ]),
        trigger('toggleUserToastBg', [
            state('pressed', style({
                background: '#F3F3F9',
                zIndex: '18',
            })),
            state('leave', style({
                background: '',
            })),
            transition('pressed <=> leave', [
                animate('0.3s ease-in-out'),
            ]),
        ]),
    ],
})
export class ControlsPanelComponent implements OnInit {

    public readonly TooltipPosition = TooltipPosition

    public readonly currentUserStream: ReactiveStream<User>

    @CompleteOnDestroy()
    public readonly isExtInstalled = new StatefulSubject<boolean>()

    @CompleteOnDestroy()
    public isUserToastActiveStream = new ValueSubject(false)

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private config: Config,
        private clipboard: Clipboard,
        private currentUser: CurrentUser,
        private extConnector: ExtConnector,
        private snackbarManager: SnackbarManager,
        private profileLinksManager: ProfileLinksManager,
        private authManager: AuthManager,
        public state: SidebarState,
    ) {
        this.currentUserStream = this.currentUser.dataStream
    }

    public async ngOnInit() {
        this.isExtInstalled.next(await this.extConnector.isExtInstalled())
    }

    public async copyProfileUrl() {
        this.clipboard.copy(
            /**
             * Generating URL for current user profile with privacy unlock code
             */
            await this.profileLinksManager.getPrivateUrlForCurrentUserProfile(),
        )

        this.snackbarManager.info('Link copied', SnackbarPosition.BottomLeft)

        this.isUserToastActiveStream.next(false)
    }

    public onInstallExtension() {
        window.open(this.config.chromeExtURL, '_blank')
        this.onToggleToast()
    }

    public onRequestFeature() {
        window.open(this.config.requestFeatureURL, '_blank')
        this.onToggleToast()
    }

    public async logout() {
        this.onToggleToast()
        await this.authManager.logout()
    }

    public onToggleToast() {
        this.isUserToastActiveStream.next(!this.isUserToastActiveStream.value)
    }
}
