import {
    Inject,
    NgModule,
    ModuleWithProviders,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

/**
 * ----------------------- Providers --------------------------
 */
import {
    IntegrationsManager
} from '@undock/integrations/services'

/**
 *  ----------------------- Analytics  --------------------------
 */
import {
    AnalyticsManager,
    CannyClient,
    GoogleAnalyticsService,
    GoogleTagManagerService,
    SegmentAnalyticsService,
} from '@undock/integrations/services/analytics'
import { AbstractEventListener } from '@undock/core'
import { AnalyticsTracker } from '@undock/integrations/contracts'
import { TrackAnalyticsListener } from '@undock/integrations/listeners'
import { PaymentClient } from '@undock/integrations/contracts/payment-client'
import { StripeClient } from '@undock/integrations/services/payments/stripe.client'
import { OnboardingAnalyticsRegistry } from '@undock/user/services/analytics/onboarding-analytics.registry'


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
})
export class IntegrationsModule {
    public constructor(
        // explicitly specifying services that must be initiated on module creation
        analytics: AnalyticsManager,
        userAnalytics: OnboardingAnalyticsRegistry,
        /**
         * Event listeners should be injected in the next way because
         * Angular DI doesn't instantiate the service until it been injected
         */
        @Inject(AbstractEventListener)
        protected listeners: AbstractEventListener[],
    ) {}

    static forRoot(): ModuleWithProviders<IntegrationsModule> {
        return {
            ngModule: IntegrationsModule,
            providers: [
                IntegrationsManager,
                SegmentAnalyticsService,

                { provide: PaymentClient, useClass: StripeClient },

                /**
                 * Can add multiple trackers for different tracking services (Heap, Segment, etc).
                 */
                { provide: AnalyticsTracker, useClass: CannyClient, multi: true },
                { provide: AnalyticsTracker, useClass: GoogleAnalyticsService, multi: true },
                { provide: AnalyticsTracker, useExisting: SegmentAnalyticsService, multi: true },
                { provide: AnalyticsTracker, useClass: GoogleTagManagerService, multi: true },

                /**
                 * Event Listeners declaration
                 */
                { provide: AbstractEventListener, useClass: TrackAnalyticsListener, multi: true },
            ],
        }
    }
}
