import moment, { Moment, tz, MomentInput } from 'moment'

const tzOffsetCache = new Map<string, number>()
const getTzOffsetInMinutes = (name: string): number => {
    if (!tzOffsetCache.has(name)) {
        tzOffsetCache.set(name, tz(new Date(), name).utcOffset())
    }
    return tzOffsetCache.get(name)
}

export const applyEmulatedTimeZone = (date: MomentInput, timeZoneName: string): Moment => {
    const currentTzOffsetMins = new Date().getTimezoneOffset()
    const emulatedTzOffsetMins = getTzOffsetInMinutes(timeZoneName)
    return moment(date).add(emulatedTzOffsetMins + currentTzOffsetMins, 'minutes')
}

export const revertEmulatedTimeZone = (date: MomentInput, timeZoneName: string): Moment => {
    const currentTzOffsetMins = new Date().getTimezoneOffset()
    const emulatedTzOffsetMins = getTzOffsetInMinutes(timeZoneName)
    return moment(date).add(-currentTzOffsetMins - emulatedTzOffsetMins, 'minutes')
}
