import { ApiRoute } from '@undock/api'
import {
    IOrganizationMember,
    OrganizationMemberRole,
} from '@undock/api/scopes/organizations/contracts'


export class MembersRoute extends ApiRoute {

    public init() {
        this.route = 'organizations/members'
    }

    public list(
        organizationId: string,
        page: number = 0,
        pageSize: number = 50,
        searchCriteria: string = '',
        channelIdFilter: string = '',
    ) {
        return this.get<IOrganizationMember[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                       searchCriteria: searchCriteria,
                       channelIdFilter: channelIdFilter,
                   })
                   .endpoint(`list/${organizationId}`)
    }

    public remove(
        memberId: string,
        organizationId: string,
    ) {
        return this.post()
                   .withData({
                       memberId,
                       organizationId,
                   })
                   .endpoint(`remove`)
    }

    public setRole(
        memberId: string,
        organizationId: string,
        role: OrganizationMemberRole,
    ) {
        return this.post()
                   .withData({
                       role,
                       memberId,
                       organizationId,
                   })
                   .endpoint(`set-role`)
    }
}
