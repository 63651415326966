import {
    Inject,
    Injectable,
} from '@angular/core'

import 'firebase/firestore'
import firebase from 'firebase/app'

import { Metadata } from '@typeheim/orm-on-fire/src/singletons'
import { Factory } from '@typeheim/orm-on-fire/src/Model/CollectionFactory'
import { CollectionMap } from '@typeheim/orm-on-fire/src/Model/CollectionMap'
import { FirestoreConnection } from '@typeheim/orm-on-fire/src/Persistence/FirestoreConnection'
import { EntityType } from '@typeheim/orm-on-fire/src/Contracts'
import { EntityQuery } from '@typeheim/orm-on-fire/src/Persistence/EntityQuery'
import {
    Collection,
    Reference,
} from '@typeheim/orm-on-fire'
import { Model } from '@typeheim/orm-on-fire/src/Contracts/Model'

import { FirebaseApp } from '@undock/session/contracts/firebase-app.token'
import { ReactivePromise } from '@typeheim/fire-rx'

@Injectable({
    providedIn: 'root',
})
export class OrmOnFireContext {

    public readonly connection = new FirestoreConnection()
    public readonly collectionFactory = new Factory(this.connection, Metadata)
    public readonly internalCollectionsMap = new CollectionMap(this.collectionFactory)

    public constructor(
        @Inject(FirebaseApp)
        private firebaseApp: firebase.app.App,
    ) {
        this.connection.driver = this.firebaseApp.firestore()
    }

    /**
     * Hotfix for multi firebase app mode
     */
    public saveModel(model: Model | any) {
        return this.internalCollectionsMap.of(model.constructor).save(model)
    }

    /**
     * Hotfix for multi firebase app mode
     */
    public removeModel(model: Model | any) {
        return this.internalCollectionsMap.of(model.constructor).remove(model)
    }

    public async linkReference<E = Model | any>(
        ref: SafeReference<E>,
        entity: E,
    ): Promise<boolean> {
        // @ts-ignore
        ref.docRef = entity?.__ormOnFire?.docRef

        let result: ReactivePromise<boolean>
        // @ts-ignore
        if (ref.owner?.__ormOnFire === undefined || ref.owner?.__ormOnFire?.isNew) {
            result = new ReactivePromise<boolean>()
            result.resolve()
        } else {
            // @ts-ignore
            result = this.saveModel(ref.owner)
        }
        return result
    }

    public async resolveReference<E = Model | any>(ref: SafeReference<E>): Promise<E> {
        // Fix the connection in the docRef
        ref['docRef']['connection'] = this.connection
        return (ref as Reference<E>).get()
    }

    /**
     * Hotfix for multi firebase app mode
     */
    public createNestedCollection<Entity>(
        entity: EntityType<Entity>,
        modelOrEntityQuery: Model | EntityQuery<any>,
    ): Collection<Entity> {
        // Looks like it's a model instance
        if (modelOrEntityQuery['__ormOnFire']) {
            const model = modelOrEntityQuery as Model
            modelOrEntityQuery = this.internalCollectionsMap.of(model.constructor).one(model.id)
        }
        return this.collectionFactory.createFromBasePath(
            entity,
            // @ts-ignore
            modelOrEntityQuery.docReference.path,
        )
    }
}

export type SafeReference<E = any> = Omit<Reference<E>, 'get' | 'link' | 'stream'>
