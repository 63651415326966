import { ApiRoute } from '@undock/api/services/api.route'


export class TrainingRoute extends ApiRoute {

    public init() {
        this.route = 'nlp/time/command/train'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public async getTrainingData(): Promise<any> {
        return this.get<any>()
                   .endpoint(``)
    }
}

