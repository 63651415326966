import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


@Component({
    selector: 'app-time-upcoming-event-slot-view',
    templateUrl: 'upcoming-event-slot-view.component.html',
    styleUrls: ['upcoming-event-slot-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpcomingEventSlotViewComponent {
    @Input() slot: AvailabilitySlot
    @Input() selected: boolean
    @Input() timezone: string

    @Input() editable: boolean = false
}
