import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
} from '@angular/core'

@Component({
    selector: 'app-trusted-by-footer',
    templateUrl: 'trusted-by-footer.component.html',
    styleUrls: ['trusted-by-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustedByFooterComponent {

}
