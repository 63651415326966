import { ApiRoute } from '@undock/api/services/api.route'
import { SyncedCalendarInterface } from '@undock/api/scopes/integrations/contracts/synced-calendar.interface'
import { AutomatedReschedulingType } from '@undock/integrations'

export class IntegrationsRoute extends ApiRoute {

    public init() {
        this.route = 'integrations'
    }

    public getOwnIntegrations(): Promise<SyncedCalendarInterface[]> {
        return this.get<SyncedCalendarInterface[]>()
                   .endpoint(`list-own`)
    }

    public deleteIntegration(calendarId: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(`delete/${calendarId}`)
    }

    public setIsCalendarActive(calendarId: string, isActive: boolean = true): Promise<void> {
        return this.post<void>()
                   .withData({ calendarId, isActive })
                   .endpoint(`calendar/set-is-active`)
    }

    public setIsCalendarPrimary(calendarId: string, isPrimary: boolean = true): Promise<void> {
        return this.post<void>()
                   .withData({ calendarId, isPrimary })
                   .endpoint(`calendar/set-is-primary`)
    }

    public setIsCalendarBlocking(calendarId: string, isBlocking: boolean = true): Promise<void> {
        return this.post<void>()
                   .withData({ calendarId, isBlocking })
                   .endpoint(`calendar/set-is-blocking`)
    }

    public setAutomatedReschedulingValues(calendarId: string, type: AutomatedReschedulingType, overrideScheduleId: string): Promise<void> {
        return this.post<void>()
                   .withData({ calendarId, type, overrideScheduleId })
                   .endpoint(`calendar/set-automated-rescheduling`)
    }

    public setIsSubCalendarActive(
        calendarId: string, subCalendarId: string, isActive: boolean = true,
    ): Promise<void> {
        return this.post<void>()
                   .withData({ calendarId, subCalendarId, isActive })
                   .endpoint(`calendar/set-is-sub-calendar-active`)
    }

    public setIsSubCalendarBlocking(
        calendarId: string, subCalendarId: string, isBlocking: boolean = true,
    ): Promise<void> {
        return this.post<void>()
                   .withData({ calendarId, subCalendarId, isBlocking })
                   .endpoint(`calendar/set-is-sub-calendar-blocking`)
    }

    public setIsSubCalendarDisplayed(
        calendarId: string, subCalendarId: string, isDisplayed: boolean = true,
    ): Promise<void> {
        return this.post<void>()
                   .withData({ calendarId, subCalendarId, isDisplayed })
                   .endpoint(`calendar/set-is-sub-calendar-displayed`)
    }
}
