<div class="meeting-title-container">
    <ng-container [ngSwitch]="(isEditModeStream | stream) && !disabled">
        <ng-container *ngSwitchCase="true">
            <textarea #titleInput data-private wrap="hard"
                      appTextareaAutoresize type="text"
                      [ngModel]="titleStream | stream"
                      [placeholder]="placeholderStream | stream"
            ></textarea>
        </ng-container>

        <ng-container *ngSwitchCase="false">
            <span class="guest-title" data-private>{{ titleStream | stream }}</span>
        </ng-container>
    </ng-container>
</div>
<span class="meeting-title-underline"></span>
