import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core'

import {
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'
import { map } from 'rxjs/operators'

import { Memoize } from '@undock/core'
import { InviteSuggestion } from '@undock/invites/contracts/invite-suggestion.interface'


@Component({
    selector: 'app-contact-invite-list-item',
    templateUrl: 'contact-invite-list-item.component.html',
    styleUrls: ['contact-invite-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInviteListItemComponent implements OnChanges {

    @Input() invite: InviteSuggestion

    @Output() sendInviteClicked = new EventEmitter<InviteSuggestion>()

    @CompleteOnDestroy()
    private inviteChangedSubject = new StatefulSubject<InviteSuggestion>()

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.invite) {
            this.inviteChangedSubject.next(changes.invite.currentValue)
        }
    }

    @Memoize()
    public get hoursSpentSchedulingStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(this.inviteChangedSubject
            .pipe(
                map(invite => Math.floor((invite.interactions[0].numMeetings.total * 6) / 60))
            ))
    }

    public onSendInviteClicked() {
        this.sendInviteClicked.emit(this.invite)
    }
}

