import { Component } from '@angular/core'

import { fadeAnimation } from '../../shared-animations/fade.animation'
import { ConferenceEvent } from '@undock/dock/meet/models/dock/conference-event.model'
import { AbstractNotificationComponent } from '../abstract-notification.component'


@Component({
    selector: 'dock-notifications-attendee-did-join',
    templateUrl: 'attendee-did-join-notification.component.html',
    styleUrls: [
        '../../shared-styles/notification.scss',
        'attendee-did-join-notification.component.scss',
    ],
    animations: [fadeAnimation],
})
export class AttendeeDidJoinNotificationComponent extends AbstractNotificationComponent<ConferenceEvent> {}
