import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-meeting-submitted',
    templateUrl: 'meeting-submitted.component.html',
    styleUrls: ['meeting-submitted.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingSubmittedIconComponent {
    @Input() darkTheme = false;
}
