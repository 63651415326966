import { ApiContext } from '@undock/api/contracts/api.context'
import { ResourceRoute } from '@undock/api/scopes/acl/routes/resource.route'

export class AclScope {

    public resource: ResourceRoute

    public constructor(
        protected context: ApiContext,
    ) {
        this.resource = new ResourceRoute(context)
    }
}
