import { ApiRoute } from '@undock/api/services/api.route'


export class ParticipantsRoute extends ApiRoute {

    public init() {
        this.route = 'meetings/dock/participants'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public createVisitorParticipant(dockId: string): Promise<void> {
        return this.post<void>()
                   .withData({ dockId: dockId })
                   .endpoint('create-visitor')
    }

    public addByEmail(meetingId: string, email: string): Promise<void> {
        return this.post<void>()
                   .withData({ meetingId, email })
                   .endpoint('add-by-email')
    }

    public massAddByEmails(meetingId: string, emails: string[]): Promise<void> {
        return this.post<void>()
                   .withData({ meetingId, emails })
                   .endpoint('mass-add-by-emails')
    }

    public removeByEmail(meetingId: string, email: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(`remove-by-email/${meetingId}/${email}`)
    }
}
