import {
    DocRef,
    Entity,
    Field,
    ID,
} from '@typeheim/orm-on-fire'
import { FirestoreUser } from '@undock/user'
import { SafeReference } from '@undock/session/models/orm-on-fire.context'

export enum RequestStatus {
    Approved = 'approved',
    Pending = 'pending',
    Declined = 'declined',
}

export const CONNECTION_REQUEST_COLLECTION_NAME = 'connection-request'

@Entity({ collection: CONNECTION_REQUEST_COLLECTION_NAME })
export class ConnectionRequest {
    @ID()
    id: string

    @DocRef(FirestoreUser)
    requester: SafeReference<FirestoreUser>

    @DocRef(FirestoreUser)
    receiver: SafeReference<FirestoreUser>

    @Field()
    receiverID: string // simple ID string for search purposes

    @Field()
    requesterID: string // simple ID string for search purposes

    @Field()
    status: RequestStatus = RequestStatus.Pending
}
