<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 22 22" fill="none">
    <g clip-path="url(#clip0)">
        <path d="M22.0001 11.2527C22.0001 10.5049 21.9382 9.75314 21.8063 9.01752H11.2207V13.2534H17.2826C17.031 14.6196 16.2228 15.8281 15.0393 16.5961V19.3446H18.6558C20.7795 17.4287 22.0001 14.5994 22.0001 11.2527Z" fill="#4285F4"/>
        <path d="M11.2203 22.0001C14.2471 22.0001 16.7997 21.026 18.6595 19.3446L15.043 16.5961C14.0368 17.267 12.7378 17.647 11.2244 17.647C8.29656 17.647 5.81409 15.7109 4.92337 13.1079H1.19141V15.9413C3.09656 19.6558 6.97697 22.0001 11.2203 22.0001Z" fill="#34A853"/>
        <path d="M4.9197 13.1079C4.44959 11.7417 4.44959 10.2624 4.9197 8.89624V6.06287H1.19186C-0.399891 9.17109 -0.399891 12.8331 1.19186 15.9413L4.9197 13.1079Z" fill="#FBBC04"/>
        <path d="M11.2203 4.35308C12.8203 4.32883 14.3667 4.91894 15.5254 6.00217L18.7296 2.86162C16.7007 0.994256 14.0079 -0.0323874 11.2203 -5.21772e-05C6.97697 -5.21772e-05 3.09656 2.34425 1.19141 6.0628L4.91924 8.89618C5.80584 6.28915 8.29244 4.35308 11.2203 4.35308Z" fill="#EA4335"/>
    </g>
    <defs>
        <clipPath id="clip0">
            <rect width="22" height="22" fill="white"/>
        </clipPath>
    </defs>
</svg>
