import { Injectable } from '@angular/core'

import { UiTimelineEvent } from '@undock/dashboard/contracts'
import { HOUR_DURATION_MS } from '@undock/dashboard/constants'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import { isRangeContainsSingleTimeStamp } from '@undock/core/utils/ranges-overlap'


@Injectable({
    providedIn: 'root',
})
export class CalendarEventStatusesProvider {

    /**
     * -----------------------------------------------
     *  This is a global singleton service which
     *  is intended to refresh UI statuses of events
     * -----------------------------------------------
     */

    /**
     * Should refresh statuses for every timeline event
     */
    public refreshEventStatuses(events: UiTimelineEvent[]): UpdateEventStatusesResult {
        const nowMs = Date.now()
            , updatedEventIdsSet = new Set<string>()

        /**
         * Refreshing `isPast` property
         */
        for (let event of events) {

            /**
             * Init missing state
             */
            event.state = event.state ?? {}

            if (event.endMs < nowMs && !event.state.isPast) {
                event.state.isPast = true
                updatedEventIdsSet.add(event.id)
            }

            /**
             * Updating `isPrivateBlocker` status
             */
            const isPrivateBlocker = event.isAccessRestricted && !event.isNonBlocking
            if (event.state.isPrivateBlocker !== isPrivateBlocker) {
                event.state.isPrivateBlocker = isPrivateBlocker
                updatedEventIdsSet.add(event.id)
            }
        }


        const plainNonPrivateEvents = events.filter(
            event => !event.allDay && !event.state.isPrivateBlocker
        )
        for (let event of plainNonPrivateEvents) {
            /**
             * Updating `isActive` status
             */
            const isActive = isRangeContainsSingleTimeStamp(event, nowMs)
            if (event.state.isActive !== isActive) {
                event.state.isActive = isActive
                updatedEventIdsSet.add(event.id)
            }


            /**
             * Updating `isDuplicateAllowed` status
             */
            const isDuplicateAllowed = event.editingAllowed
            if (event.state.isDuplicateAllowed !== isDuplicateAllowed) {
                event.state.isDuplicateAllowed = isDuplicateAllowed
                updatedEventIdsSet.add(event.id)
            }


            /**
             * Updating `isDuplicateAllowed` status
             */
            const isRescheduleAllowed = event.isOrganizer || event.isOwner
            if (event.state.isRescheduleAllowed !== isRescheduleAllowed) {
                event.state.isRescheduleAllowed = isRescheduleAllowed
                updatedEventIdsSet.add(event.id)
            }


            /**
             * Updating `isJoinButtonDisplayed` status
             */
            const isJoinButtonDisplayed = Boolean(event.state.isCurrent && event.location)
            if (event.state.isJoinButtonDisplayed !== isJoinButtonDisplayed) {
                event.state.isJoinButtonDisplayed = isJoinButtonDisplayed
                updatedEventIdsSet.add(event.id)
            }


            /**
             * At first, searching for attendee by calendarId
             */
            let relatedAttendee = event.attendees.find(
                attendee => attendee.email === event.calendarId
            )

            /**
             * Fallback option to find related attendee by email
             */
            if (!relatedAttendee) {
                relatedAttendee = event.attendees.find(
                    attendee => attendee.email === event.calendarEmail
                )
            }

            if (relatedAttendee) {

                /**
                 * Saving relatedAttendeeEmail to the state
                 */
                event.state.relatedAttendeeEmail = relatedAttendee.email

                /**
                 * Updating `isRequest` status
                 */
                const isRequest = relatedAttendee.status === RsvpStatus.NeedsAction
                if (event.state.isRequest !== isRequest) {
                    event.state.isRequest = isRequest
                    updatedEventIdsSet.add(event.id)
                }


                /**
                 * Updating `isDeclined` status
                 */
                const isDeclined = relatedAttendee.status === RsvpStatus.Declined
                if (event.state.isDeclined !== isDeclined) {
                    event.state.isDeclined = isDeclined
                    updatedEventIdsSet.add(event.id)
                }


                /**
                 * Updating `canUseRsvpNo` status
                 *
                 * Microsoft delete event instead od setting RSVP "NO".
                 * So, for now we'd hide it to prevent incorrect expectations from users.
                 */
                const canUseRsvpNo = event.provider !== 'microsoft'
                if (event.state.canUseRsvpNo !== canUseRsvpNo) {
                    event.state.canUseRsvpNo = canUseRsvpNo
                    updatedEventIdsSet.add(event.id)
                }


                /**
                 * Updating `canChangeRsvp` status
                 *
                 * MS organizer can only view status as RSVP Yes, but can't change it - provider specifics.
                 */
                const canChangeRsvp = !(event.isOrganizer && event.provider === 'microsoft')
                if (event.state.canChangeRsvp !== canChangeRsvp) {
                    event.state.canChangeRsvp = canChangeRsvp
                    updatedEventIdsSet.add(event.id)
                }


                /**
                 * Updating `currentRsvpStatus`
                 */
                if (event.state.currentRsvpStatus !== relatedAttendee.status) {
                    event.state.currentRsvpStatus = relatedAttendee.status
                    updatedEventIdsSet.add(event.id)
                }
            }
        }


        /**
         * Refreshing `isCurrent` status
         */
        const prevCurrentEvent = plainNonPrivateEvents.find(e => e.state.isCurrent && !e.state.isDeclined)
            , currActiveEvents = plainNonPrivateEvents.filter(e => e.state.isActive && !e.state.isDeclined)
        if (currActiveEvents.length > 0) {
            /**
             * Get the closest event from all currently active
             */
            currActiveEvents.sort((a, b) => {
                return Math.abs(nowMs - a.startMs) - Math.abs(nowMs - b.startMs)
            })

            if (prevCurrentEvent) {
                /**
                 * Replace active status in events
                 */
                if (prevCurrentEvent.id !== currActiveEvents[0].id) {
                    prevCurrentEvent.state.isCurrent = false
                    currActiveEvents[0].state.isCurrent = true

                    updatedEventIdsSet.add(prevCurrentEvent.id)
                    updatedEventIdsSet.add(currActiveEvents[0].id)
                }
            } else {
                /**
                 * Set new isCurrent status
                 */
                currActiveEvents[0].state.isCurrent = true
                updatedEventIdsSet.add(currActiveEvents[0].id)
            }

            /**
             * Unset previous active event in this case
             */
        } else if (prevCurrentEvent) {
            prevCurrentEvent.state.isCurrent = false
            updatedEventIdsSet.add(prevCurrentEvent.id)
        }


        /**
         * Refreshing `isNext` status
         *
         * Should set `isNext` only if no there are no active events
         */
        const nowPlus12h = nowMs + HOUR_DURATION_MS * 12
            , prevNextEvent = plainNonPrivateEvents.find(e => e.state.isNext)
            , currNextEvent = currActiveEvents.length === 0 ?
            plainNonPrivateEvents
                       .filter(e => !e.state.isDeclined && !e.state.isPrivateBlocker)
                       .filter(e => e.startMs > nowMs && e.startMs < nowPlus12h)[0] : null

        if (currNextEvent) {
            if (prevNextEvent) {
                if (prevNextEvent.id !== currNextEvent.id) {
                    currNextEvent.state.isNext = true
                    prevNextEvent.state.isNext = false
                    updatedEventIdsSet.add(currNextEvent.id)
                    updatedEventIdsSet.add(prevNextEvent.id)
                }
            } else {
                currNextEvent.state.isNext = true
                updatedEventIdsSet.add(currNextEvent.id)
            }
            /**
             * Unset previous `next` event in this case
             */
        } else if (prevNextEvent) {
            prevNextEvent.state.isNext = false
            updatedEventIdsSet.add(prevNextEvent.id)
        }

        /**
         * Preparing response format
         */
        return { allEvents: events, updatedEventIds: Array.from(updatedEventIdsSet.values()) }
    }
}

export interface UpdateEventStatusesResult {
    allEvents: UiTimelineEvent[]
    updatedEventIds: string[]
}
