import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import {
    PlanType,
    Plan,
} from '@undock/time/plans/contracts/plan.interface'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'

@Component({
    selector: 'app-time-search-plan-result-item',
    templateUrl: './plan-search-result-item.component.html',
    styleUrls: ['./plan-search-result-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanSearchResultItemComponent {

    public readonly iconColor = '#2B2733'

    public readonly CommandType = PlanType

    @Input() plan: Plan

    public buildMeetingDurationString(duration: MeetingDuration): string {
        if (!duration) {
            return ''
        }

        const hours = Math.floor(duration / 60),
            minutes = Math.floor(duration - hours * 60)

        let durationString = ''

        if (hours > 0) {
            durationString += hours === 1 ? `${hours} hour ` : `${hours} hours `
        }

        if (minutes > 0) {
            durationString += minutes === 1 ? `${minutes} min` : `${minutes} min`
        }

        return durationString.trim()
    }
}
