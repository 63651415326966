import { AppCommandsScopeComponent } from '@undock/app-commands/ui/app-commands-scope/app-commands-scope.component'
import {
    Route,
    Routes,
} from '@angular/router'

export class AppCommandsScope {
    public static forRoute(route: Route) {
        return {
            component: AppCommandsScopeComponent,
            path: '',
            children: [
                route,
            ],
        }
    }

    public static forRoutes(routes: Routes) {
        return {
            component: AppCommandsScopeComponent,
            path: '',
            children: routes,
        }
    }
}
