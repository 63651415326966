import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-clock',
    templateUrl: 'clock.component.html',
    styleUrls: ['clock.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClockIconComponent extends IconTemplateComponent {}
