import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Inject,
    InjectFlags,
    Injector,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    TrackByFunction,
} from '@angular/core'
import {
    CdkDragDrop,
    moveItemInArray,
} from '@angular/cdk/drag-drop'

import {
    distinctUntilChanged,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import { CurrentUser } from '@undock/session'
import {
    AttachmentsManager,
    Memoize,
} from '@undock/core'
import { Topic } from '@undock/dock/meet/models/topic.model'
import { TopicsManager } from '@undock/dock/meet/services/topics.manager'
import { PathResolveStrategy } from '@undock/core/contracts/path-resolve.strategy'
import { STORAGE_PATH_PREFIX } from '@undock/core/contracts/tokens/storage-path-prefix.token'
import { DefaultPathResolveStrategy } from '@undock/core/models/file-storage/default-path-resolve.strategy'
import { DockTopicAttachmentsManager } from '@undock/dock/meet/services/attachments/dock-topic-attachments.manager'
import {
    TOPICS_ADAPTER,
    TopicsAdapterInterface,
} from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'


@Component({
    selector: 'app-meet-topics-list',
    templateUrl: 'topics-list.component.html',
    styleUrls: ['topics-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: STORAGE_PATH_PREFIX, useValue: 'topic' },
        { provide: AttachmentsManager, useClass: DockTopicAttachmentsManager },
        { provide: PathResolveStrategy, useClass: DefaultPathResolveStrategy },
    ],
})
export class TopicsListComponent implements OnInit {
    /**
     * Used for *ngFor directive rendering optimization
     */
    public readonly topicsTrackByFn: TrackByFunction<Topic> = (i, item) => item.id

    @HostBinding('class.__empty')
    private isEmpty: boolean = true

    @Input() showTitle: boolean = true
    @Input() show: boolean = true
    @Input() showFormCheckbox: boolean = false

    public readonly topicsStream: ReactiveStream<Topic[]>
    public readonly isEditModeStream: ReactiveStream<boolean>
    public readonly isRegularUserStream = this.currentUser.isRegularUserStream

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected injector: Injector,
        protected currentUser: CurrentUser,
        @Inject(TOPICS_ADAPTER)
        protected topicsAdapter: TopicsAdapterInterface,
        /**
         * This is a temporary solution used because of known Ivy bug.
         * SkipSelf flag doesn't work properly when trying get the service using an Injector instance.
         * When the bug will be fixed it won't be needed anymore. Everything will be in the getter method.
         */
        @Optional() @SkipSelf()
        protected parentTopicsManager: TopicsManager,
    ) {
        this.topicsStream = this.topicsManager.topicsStream
        this.isEditModeStream = this.topicsAdapter.isEditModeStream

        this.topicsStream.subscribe(topics => this.isEmpty = !topics || topics.length === 0)
    }

    /**
     * Will return a TopicsManager from the parent injection scope or create a local one
     */
    @Memoize()
    protected get topicsManager(): TopicsManager {
        /**
         * @TODO: Move parent service getting logic into this method after the Angular version will be updated.
         *
         * @description `parentTopicsManager` cannot be received in this place using an injector instance.
         * It ignores InjectFlags.SkipSelf for some reason and creates a new Instance of TopicsManager every time.
         */
        return this.parentTopicsManager ?? this.injector.get(TopicsManager, null, InjectFlags.Self)
    }


    public async ngOnInit(): Promise<void> {
        this.topicsAdapter.ownerModelStream.pipe(
            takeUntil(this.destroyedEvent),
            /**
             * We should initialize TopicsFacade only if dockId was changed
             */
            distinctUntilChanged(
                (prev, next) => prev?.id === next?.id,
            ),
        ).subscribe(
            dock => this.topicsManager.initializeForModel(dock),
        )
    }

    public async createTopic(data: { text: string, status?: boolean }): Promise<Topic> {
        return await this.topicsManager.new(
            await this.topicsAdapter.ownerModelStream, {
                text: data.text, complete: data.status,
            },
        )
    }

    public async onCdkDropEvent(event: CdkDragDrop<any>) {
        const topics = await this.topicsStream

        /**
         * Method proceeds changes directly in the given items array. So we can not update
         * the itemsStream with new positions because template will be automatically updated.
         */
        moveItemInArray(topics, event.previousIndex, event.currentIndex)

        /**
         * Updating item positions, saving updates
         */
        topics.forEach((topic, index) => {
            topic.position = index + 1
            this.topicsManager.save(topic)
        })
    }
}

