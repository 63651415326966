/**
 * Abstract factory for any Firestore entity
 */
export abstract class AbstractFactory<T> {

    protected defaultProperties: Partial<T> = {}

    protected constructor() {}

    public abstract create(...args): Promise<T>

    protected assignDefaultData(model: T, props: Partial<T>) {
        const data = Object.assign({}, this.defaultProperties, props)

        Object.keys(data).forEach(key => {
            if (data.hasOwnProperty(key)) {
                model[key] = data[key]
            }
        })
    }
}
