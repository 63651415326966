import { MeetingModeLegacy } from '@undock/dock/meet/contracts/meeting-mode.legacy'


export enum MeetingMode {
    Audio = 'Audio',
    Video = 'Video',
    InPerson = 'InPerson',
    Broadcast = 'Broadcast',
}

/**
 * @description a temporary solution for converting meeting modes
 */
export function toLegacyMeetingMode(mode: MeetingMode): MeetingModeLegacy {
    switch (mode) {
        case MeetingMode.Video:
            return MeetingModeLegacy['video']

        case MeetingMode.Audio:
            return MeetingModeLegacy['voice']

        case MeetingMode.InPerson:
            return MeetingModeLegacy['in-person']

        /**
         * Return Video at default
         */
        default:
            return MeetingModeLegacy['video']
    }
}

/**
 * @description a temporary solution for converting meeting modes
 */
export function fromLegacyMeetingMode(mode: MeetingMode | MeetingModeLegacy): MeetingMode {
    if (isLegacyMeetingMode(mode)) {
        switch (mode) {
            case MeetingModeLegacy['video']:
                return MeetingMode.Video

            case MeetingModeLegacy['voice']:
                return MeetingMode.Audio

            case MeetingModeLegacy['in-person']:
                return MeetingMode.InPerson

            /**
             * Return Video at default
             */
            default: return MeetingMode.Video
        }
    }

    return mode as MeetingMode
}

function isLegacyMeetingMode(mode: MeetingMode | MeetingModeLegacy): boolean {
    return typeof mode === 'number'
}
