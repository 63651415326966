import {
    Injectable,
    OnDestroy,
} from '@angular/core'

import {
    ReactiveStream,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Memoize } from '@undock/core'


export enum DockNotificationType {
    ChatMessage = 'ChatMessage',

    AttendeeDidJoin = 'AttendeeDidJoin',
    RequestedToJoin = 'RequestedToJoin',
    RequestedToSpeak = 'RequestedToSpeak',
}

export interface DockNotification<T = any> {
    type: DockNotificationType

    groupName: string

    payload: T

    /**
     * Time while notification will be displayed (seconds)
     */
    notificationTtl?: number
}

export interface DockNotificationsGroupData {
    sortOrder: number

    groupName: string

    /**
     * Time while notification will be displayed (seconds)
     */
    notificationTtl: number

    maxCountToDisplay: number
}

export interface DockNotificationGroup extends DockNotificationsGroupData {
    notificationStream: ReactiveStream<DockNotification[]>
}


@Injectable()
export class DockNotificationsManager implements OnDestroy {

    @CompleteOnDestroy()
    protected groupsSubject = new ValueSubject<DockNotificationGroup[]>([])

    protected groupNotificationsMap = new Map<string, ValueSubject<DockNotification[]>>()


    protected readonly notificationSoundsMap = {
        [DockNotificationType.ChatMessage]: new Audio('/assets/sounds/undock-chat.mp3'),
        [DockNotificationType.AttendeeDidJoin]: new Audio('/assets/sounds/undock-attendee-join.mp3'),
        [DockNotificationType.RequestedToJoin]: new Audio('/assets/sounds/undock-participant-request.mp3'),
        [DockNotificationType.RequestedToSpeak]: new Audio('/assets/sounds/undock-participant-request.mp3'),
    }


    @Memoize()
    public get groupsStream(): ReactiveStream<DockNotificationGroup[]> {
        return this.groupsSubject.asStream()
    }


    public defineGroup(data: DockNotificationsGroupData) {
        let groups = this.groupsSubject.value
        let groupExists = groups.find(g => g.groupName === data.groupName)

        if (groupExists) {
            /**
             * Do nothing
             */
        } else {
            let publisher = new ValueSubject([])

            groups = [...groups, {
                ...data, notificationStream: publisher.asStream(),
            }]

            groups.sort((a, b) => {
                return a.sortOrder - b.sortOrder
            })

            this.groupsSubject.next(groups)
            this.groupNotificationsMap.set(data.groupName, publisher)
        }
    }

    public publishNotification(data: DockNotification) {
        let group = this.groupsSubject.value.find(g => g.groupName === data.groupName)

        if (group && this.groupNotificationsMap.has(data.groupName)) {

            this.playNotificationSound(data.type).catch(
                error => console.warn(`Unable play notification sound`, error),
            )

            let publisher = this.groupNotificationsMap.get(data.groupName)
            let notifications = publisher.value

            notifications.push({
                ...data, notificationTtl: data.notificationTtl ?? group.notificationTtl,
            })

            if (notifications.length > group.maxCountToDisplay) {
                /**
                 * Removing redundant notifications
                 */
                notifications.splice(0, notifications.length - group.maxCountToDisplay)
            }

            /**
             * Pushing an update to notifications group
             */
            publisher.next(notifications)

            /**
             * Enqueue task to remove notification
             */
            setTimeout(() => {
                this.destroyNotification(data)
            }, data.notificationTtl ?? group.notificationTtl ?? 5000)
        } else {
            console.error(`Has no notification group ${data.groupName}`)
        }
    }

    public destroyNotification(data: DockNotification) {
        let publisher = this.groupNotificationsMap.get(data.groupName)
        let notifications = publisher.value

        /**
         * Removing notification
         */
        notifications.splice(notifications.indexOf(data), 1)

        return publisher.next(notifications)
    }

    public ngOnDestroy() {
        for (let subject of this.groupNotificationsMap.values()) {
            try {
                subject.complete()
            } catch (error) {
                console.warn(error)
            }
        }
    }

    protected async playNotificationSound(notificationType: DockNotificationType) {
        if (this.notificationSoundsMap[notificationType]) {
            await this.notificationSoundsMap[notificationType].play()
        }
    }
}
