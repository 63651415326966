import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core'

@Component({
  selector: 'app-icon-settings-personalized-form-edit-form',
  templateUrl: 'edit-form.component.html',
  styleUrls: ['edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPersonalizedFormEditFormIconComponent extends IconTemplateComponent {}
