import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'

@Component({
    selector: 'app-icon-meeting-confirmed',
    templateUrl: 'meeting-confirmed.component.html',
    styleUrls: ['meeting-confirmed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingConfirmedIconComponent {}
