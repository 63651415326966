import {
    Component,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    HostBinding,
    Input,
} from '@angular/core'

import { Api } from '@undock/api'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { EventDetailsViewModel } from '@undock/dashboard/view-models/event-details.view-model'

@Component({
    selector: 'app-dashboard-calendar-event-details',
    templateUrl: 'event-details.component.html',
    styleUrls: ['event-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCalendarEventDetailsComponent {

    public readonly state = this.eventDetailsViewModel.state

    @Input()
    @HostBinding('class.full-size-mode')
    public isFullSizeMode: boolean = false

    public constructor(
        private api: Api,
        private snackbarManager: SnackbarManager,
        private changeDetectorRef: ChangeDetectorRef,
        private eventDetailsViewModel: EventDetailsViewModel,
    ) {}
}
