import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'

@Component({
    selector: 'app-icon-facebook',
    templateUrl: 'facebook-icon.component.html',
    styleUrls: ['facebook-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookIconComponent {}
