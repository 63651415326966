import { NgModule } from '@angular/core'

import { AclManager } from './services/acl.manager'

import { OnlyGuestDirective } from './directives/only-guest.directive'
import { RestrictGuestDirective } from './directives/restrict-guest.directive'
import { RestrictByPermissionDirective } from './directives/restrict-by-permission.directive'
import { DockAccessPolicy } from '@undock/acl/services/dock.access-policy'


@NgModule({
    exports: [
        OnlyGuestDirective,
        RestrictGuestDirective,
        RestrictByPermissionDirective,
    ],
    providers: [
        AclManager,
        DockAccessPolicy,
    ],
    declarations: [
        OnlyGuestDirective,
        RestrictGuestDirective,
        RestrictByPermissionDirective,
    ],
})
export class AclModule {}
