import { Injectable } from '@angular/core'
import {
    State,
    StateModel,
    StreamStore,
} from '@undock/core/states'
import { Api } from '@undock/api'
import {
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Plan } from '@undock/time/plans/contracts/plan.interface'
import { OwnPlansStorage } from '@undock/time/plans/services/states/plans-list.state'
import {
    clone,
    isEmptyString,
    Memoize,
} from '@undock/core'


export type SearchInputFocusType = 'focus' | 'blur'

@Injectable()
export class PlansSearchStateModel extends StateModel<PlansSearchStore> {

    protected store = new PlansSearchStore()

    constructor(
        private api: Api,
        /**
         * TODO: remove this once there is a proper command search api
         */
        private listStateModel: OwnPlansStorage
    ) {
        super()
    }

    @Memoize()
    public get allPlansStream(): ReactiveStream<Plan[]> {
        return new ReactiveStream<Plan[]>(
            this.listStateModel.state.allPlansStream
        )
    }

    public async searchPlans(term: string) {
        this.store.isLoadingStream.next(true)
        this.store.searchCriteriaStream.next(term)

        if (!isEmptyString(term)) {
            /**
             * TODO: Call API to load commands by title/description
             */
            let allPlans = await this.allPlansStream
            this.store.plansSearchResultsStream.next(
                this.groupAndFilterSearchResults(
                    allPlans.filter(c => c.title.match(
                        new RegExp(term, 'gi')
                    ))
                )
            )
        }
        else {
            this.store.plansSearchResultsStream.next([])
        }

        this.store.isLoadingStream.next(false)
    }

    public clearSearch() {
        this.store.plansSearchResultsStream.next([])
    }

    public setSearchInputFocusType(state: SearchInputFocusType) {
        this.store.searchInputFocusStateStream.next(state)
    }

    protected groupAndFilterSearchResults(commands: Plan[]): Plan[] {
        if (commands?.length) {
            let filteredCommands = commands.filter(c => !isEmptyString(c.title))

            let activeCommands = this.sortPlansByRecentActivity(
                filteredCommands.filter(c => c.isActive)
            )

            let publicCommands = this.sortPlansByRecentActivity(
                filteredCommands.filter(c => c.isPublic)
            )

            let personalCommands = this.sortPlansByRecentActivity(
                filteredCommands.filter(c => !c.isActive && !c.isPublic)
            )

            return [...activeCommands, ...publicCommands, ...personalCommands]
        }
        return []
    }

    protected sortPlansByRecentActivity(commands: Plan[]): Plan[] {
        return [...commands].sort(
            (a, b) =>
                (b.lastExecuted ? b.lastExecuted.getTime() : b.updatedAt.getTime()) -
                (a.lastExecuted ? a.lastExecuted.getTime() : a.updatedAt.getTime())
        )
    }
}

export class PlansSearchStore extends StreamStore {
    public isLoadingStream = new ValueSubject<boolean>(false)

    public searchCriteriaStream: ValueSubject<string> = new ValueSubject<string>(null)

    public searchInputFocusStateStream = new StatefulSubject<SearchInputFocusType>()

    public plansSearchResultsStream: ValueSubject<Plan[]> = new ValueSubject<Plan[]>([])
}

export type PlansSearchState = State<PlansSearchStore>
