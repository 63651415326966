import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-microsoft-icon',
    templateUrl: 'microsoft.component.html',
    styleUrls: ['microsoft.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MicrosoftIconComponent {
    @Input() width: number | string = '22'
    @Input() height: number | string = '22'
}
