import {
    Component,
        Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-form-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52 3.50525C3.76386 3.63577 3.9161 3.88991 3.9161 4.1665L3.9161 7.58314L4.41607 7.58312C4.83028 7.5831 5.16608 7.91888 5.1661 8.33309C5.16611 8.7473 4.83034 9.0831 4.41613 9.08312L3.16613 9.08317H3.1661L1.9161 9.08317C1.50188 9.08317 1.1661 8.74739 1.1661 8.33317C1.1661 7.91896 1.50188 7.58317 1.9161 7.58317H2.4161L2.4161 5.56785L2.3321 5.62384C1.98745 5.85359 1.5218 5.76045 1.29205 5.4158C1.06229 5.07114 1.15543 4.60549 1.50009 4.37574L2.75009 3.54246C2.98023 3.38904 3.27613 3.37474 3.52 3.50525ZM6.91629 4.24979C6.50208 4.24979 6.16629 4.58558 6.16629 4.99979C6.16629 5.41401 6.50208 5.74979 6.91629 5.74979L16.9163 5.74979C17.3305 5.74979 17.6663 5.41401 17.6663 4.99979C17.6663 4.58558 17.3305 4.24979 16.9163 4.24979L6.91629 4.24979ZM6.16629 9.99979C6.16629 9.58558 6.50208 9.24979 6.91629 9.24979L16.9163 9.24979C17.3305 9.24979 17.6663 9.58558 17.6663 9.99979C17.6663 10.414 17.3305 10.7498 16.9163 10.7498L6.91629 10.7498C6.50208 10.7498 6.16629 10.414 6.16629 9.99979ZM6.16629 14.9998C6.16629 14.5856 6.50208 14.2498 6.91629 14.2498L16.9163 14.2498C17.3305 14.2498 17.6663 14.5856 17.6663 14.9998C17.6663 15.414 17.3305 15.7498 16.9163 15.7498L6.91629 15.7498C6.50208 15.7498 6.16629 15.414 6.16629 14.9998ZM1.91644 10.9165C1.50222 10.9165 1.16644 11.2522 1.16644 11.6665C1.16644 12.0807 1.50222 12.4165 1.91644 12.4165H3.66636L3.66631 12.9998L1.91623 12.9998C1.50202 12.9998 1.16623 13.3356 1.16623 13.7498L1.16623 15.8331C1.16623 16.2473 1.50202 16.5831 1.91623 16.5831H4.41644C4.83065 16.5831 5.16644 16.2473 5.16644 15.8331C5.16644 15.4189 4.83065 15.0831 4.41644 15.0831H2.66623L2.66623 14.4998L4.41623 14.4998C4.83042 14.4998 5.16619 14.164 5.16623 13.7499L5.16644 11.6665C5.16646 11.4676 5.08745 11.2768 4.94679 11.1361C4.80614 10.9955 4.61536 10.9165 4.41644 10.9165L1.91644 10.9165Z" 
                  [attr.fill]="color"/>
        </svg>

    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class FormIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = '#1D1C21'
}
