import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Observable } from 'rxjs'

import {
    FileStorage,
    Memoize,
    UploadFileOptions,
} from '@undock/core'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { SettingsFacade } from '@undock/profile/settings/services/facade/settings.facade'


export abstract class ImageUploadAdapter {
    protected abstract storage: FileStorage
    protected abstract getPath(): Promise<string> | string
    protected abstract getFileName(): Promise<string> | string

    public async upload(data: Blob): Promise<string> {
        const [path, fileName] = await Promise.all([
            this.getPath(),
            this.getFileName(),
        ])
        const task = await this.storage.upload(
            data, fileName, path, this.getUploadOptions(),
        )
        return this.storage.getFileUrl(await task.uploadedFile)
    }

    protected getUploadOptions(): UploadFileOptions {
        return {
            cacheControl: 'public, max-age=31536000',
        }
    }
}

@Component({
    selector: 'app-image-upload',
    templateUrl: 'image-upload.component.html',
    styleUrls: ['image-upload.component.scss'],
})
export class ImageUploadComponent {

    @Output() readonly onUploaded = new EventEmitter<string>()

    @Input() buttonText: string = 'Upload picture'

    @CompleteOnDestroy()
    protected readonly imageFileSubject = new ValueSubject<File>(null)

    @CompleteOnDestroy()
    protected readonly isImageProcessingSubject = new ValueSubject(false)

    protected readonly maximumImageSize = 10 * 10 ** 6 // ~ 10mb
    protected readonly supportedImageTypes = ['image/jpg', 'image/jpeg', 'image/png']

    public constructor(
        protected settings: SettingsFacade,
        protected snackbarManager: SnackbarManager,
        protected imageUploadAdapter: ImageUploadAdapter,
    ) {}

    @Memoize()
    public get imageFileStream(): Observable<File> {
        return this.imageFileSubject.asObservable()
    }

    @Memoize()
    public get isImageProcessingStream(): Observable<boolean> {
        return this.isImageProcessingSubject.asObservable()
    }

    public async onProfileImageReady(data: Blob) {
        try {
            this.isImageProcessingSubject.next(true)

            // Upload file and emit event
            this.onUploaded.next(
                await this.imageUploadAdapter.upload(data),
            )
        } catch (error) {
            console.error(error)
            this.snackbarManager.error(`Cannot upload file. Please try later`)
        } finally {
            this.isImageProcessingSubject.next(false)
        }
    }

    public async onFileInputBeenChanged(event: Event) {
        const target = event.target as HTMLInputElement

        if (target.files && target.files.length > 0) {
            if (this.validateFile(target.files[0])) {
                this.imageFileSubject.next(target.files[0])
            } else {
                this.snackbarManager.error(
                    `Please select jpg or png image less than 10mb`,
                    SnackbarPosition.BottomLeft,
                )
            }
        }

        /**
         * Resetting file input value
         */
        target.value = null
    }

    protected validateFile(file: File) {
        return this.supportedImageTypes.includes(file.type) && file.size <= this.maximumImageSize
    }
}
