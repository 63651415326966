import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'
import {
    filter,
    take,
} from 'rxjs/operators'
import { firstValueFrom } from 'rxjs'
import { AuthManager } from '@undock/auth'

@Injectable()
export class AuthHeaders {
    public constructor(
        private authManager: AuthManager,
    ) {}

    public async getFullyAuthenticatedRequest(): Promise<HttpHeaders> {
        const [isRegularUser, isAnonymous] = await Promise.all([
            this.authManager.isRegularUserStream,
            this.authManager.isAnonymousUserStream,
        ])
        if (isRegularUser || isAnonymous) {
            const accessToken = await this.getAccessToken()
            return new HttpHeaders({ 'Authorization': `Bearer ${accessToken}` })
        }
    }

    protected getAccessToken(): Promise<string> {
        return firstValueFrom(
            this.authManager.accessTokenStream.pipe(
                filter(Boolean),
                take(1),
            ),
        )
    }
}
