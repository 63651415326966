import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import { MeetingMode } from '@undock/dock/meet'
import {
    MeetingModeExtended,
    MeetingModeOptionsProvider,
} from '@undock/dock/meet/services/data-providers/meeting-mode-options.provider'
import { EditMeetingViewModel } from '@undock/dock/meet/ui/pages/edit-meeting/view-models/edit-meeting.view-model'
import {
    combineLatest,
    Observable,
} from 'rxjs'
import { ValuesSelectorOption } from '@undock/common/ui-kit'
import { map } from 'rxjs/operators'
import { Memoize } from '@undock/core'


@Component({
    selector: 'app-meet-edit-mode-slide-button',
    templateUrl: 'meet-mode-slide-button.component.html',
    styleUrls: ['meet-mode-slide-button.component.scss'],
})
export class MeetingModeSlideButtonComponent {

    @Input() showIcons: boolean = true
    @Input() showText: boolean = false
    @Input() showAudioOnly: boolean = true
    @Input() showBroadcast: boolean = true

    @Output() onModeChanged = new EventEmitter<MeetingMode>()

    private readonly meetingModeExtendedToDefaultMap = {
        [MeetingModeExtended.Video]: MeetingMode.Video,
        [MeetingModeExtended.Audio]: MeetingMode.Video,
        [MeetingModeExtended.InPerson]: MeetingMode.InPerson,
        [MeetingModeExtended.Broadcast]: MeetingMode.Broadcast,
    }

    public readonly MeetingMode = MeetingModeExtended

    public constructor(
        public state: EditMeetingViewModel,
        private meetingModeOptionsProvider: MeetingModeOptionsProvider,
    ) {}

    @Memoize()
    public get isAllowedToChangeModeStream(): Observable<boolean> {
        return combineLatest([
            this.state.isMeetingDraftTypeStream,
            this.state.selectedMeetingModeStream,
        ]).pipe(
            map(sources => {
                const [ isDraftType, mode ] = sources

                return isDraftType || mode === MeetingMode.Video
            })
        )
    }

    @Memoize()
    public get selectedMeetingModeStream(): Observable<MeetingModeExtended> {
        return combineLatest([
            this.state.isAudioOnlyModeStream,
            this.state.selectedMeetingModeStream,
        ]).pipe(
            map(sources => {
                const [ isAudioOnly, meetingMode ] = sources

                if (meetingMode === MeetingMode.Video) {
                    return isAudioOnly ?
                        MeetingModeExtended.Audio : MeetingModeExtended.Video
                }

                return meetingMode as any as MeetingModeExtended
            })
        )
    }

    @Memoize()
    public get availableMeetingModeOptionsStream(): Observable<MeetingModeExtended[]> {
        return this.meetingModeOptionsProvider.availableExtendedMeetingModeOptionsStream.pipe(
            map(modes => modes.map(m => m.value))
        )
    }

    public async onMeetingModeChanged(value: MeetingModeExtended) {
        let mode = this.meetingModeExtendedToDefaultMap[value]
        await this.state.selectMeetingMode(mode)
        this.state.setIsAudioOnlyMode(value === MeetingModeExtended.Audio)
        this.onModeChanged.emit(mode)
    }
}
