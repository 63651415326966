import {
    Component,
    inject,
    Input,
    Output,
} from '@angular/core'

import moment from 'moment'
import { RRule } from 'rrule'
import { CalendarEvent } from 'angular-calendar'

import {
    CompleteOnDestroy,
    DestroyEvent,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    combineLatest,
    Subject,
} from 'rxjs'
import {
    debounceTime,
    filter,
    map,
    shareReplay,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators'

import { Api } from '@undock/api'
import { clone } from '@undock/core'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import {
    EventFormState,
    EventFormStateModel,
} from '@undock/dock/meet/services/state-models/event-form.state-model'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { MeetingsManager } from '@undock/dock/meet/services/meetings.manager'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { CalendarGridViewModel } from '@undock/common/calendar-grid/view-models/calendar-grid.view-model'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'
import {
    AnalyticsAction,
    AnalyticsSource,
    AnalyticsTrackedFeature,
} from '@undock/api/scopes/analytics/analytics.scope'
import { revertEmulatedTimeZone } from '@undock/dock/meet/helpers/emulate-tz'
import { getOverlapTypeForRanges } from '@undock/core/utils/ranges-overlap'
import { EventSchedule } from '@undock/api/scopes/time/contracts/timeline-event/event-schedule.interface'
import { MIN_DURATION_MS } from '@undock/dashboard/constants'
import * as m from 'moment/moment'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'


@Component({ template: '' })
export abstract class AbstractEditEventPageComponent {

    @Output('close')
    @CompleteOnDestroy()
    public readonly onClose = new Subject<void>()

    @Output('submit')
    @CompleteOnDestroy()
    public readonly onSubmit = new Subject<void>()

    @CompleteOnDestroy()
    public readonly isLoading$ = new ValueSubject(false)

    @CompleteOnDestroy()
    public readonly createDraftEvents$ = new ValueSubject(true)

    public abstract state: EventFormState

    protected abstract api: Api
    protected abstract meetingsManager: MeetingsManager
    protected abstract snackbarManager: SnackbarManager
    protected abstract eventFormStateModel: EventFormStateModel
    protected abstract confirmPopupService: ConfirmPopupService
    protected abstract availabilityProvider: AvailabilityProvider
    protected abstract availabilityViewModel: AvailabilityViewModel
    protected abstract calendaringGridViewModel: CalendarGridViewModel

    protected abstract destroyEvent: DestroyEvent

    protected readonly SELECT_TIME_AUTOMATICALLY = true
    protected readonly DRAFT_EVENT_SLOT_GRID_EL_TYPE = 'draft'
    protected readonly DRAFT_EVENT_OPENED_IN_CMP_NAME = 'event-form'
    protected readonly AVAILABILITY_SLOT_GRID_EL_TYPE = 'availabilitySlot'

    protected readonly calendarEventsStorage = inject(CalendarEventsStorage, { optional: true })

    @Input('createDraftEvents')
    public set createDraftEvents(value: boolean) {
        this.createDraftEvents$.next(value)
    }

    public async close() {
        const hasUnsavedChanges = await this.eventFormStateModel
                                            .isMeetingHasUnsavedChanges()
        if (hasUnsavedChanges) {
            if (await this.requestToClose()) {
                const meetingData = await this.eventFormStateModel.getUpdatedMeetingData()
                if (meetingData.isDraft) {
                    this.api.meet.meetings.deleteDraftMeeting(meetingData._id)
                        .catch(error => console.warn(`Cannot delete draft meeting`, error))
                } else {
                    // Restores original version of updated event
                    this.eventFormStateModel
                        .reloadPossiblyMutatedDashboardRanges()
                        .catch(error => console.warn(`Cannot reload dashboard`, error))
                }
                this.onClose.next()
            }
        } else {
            this.onClose.next()
        }
    }

    protected async requestToClose(): Promise<boolean> {
        return this.confirmPopupService.open({
            title: 'Are you sure you want to leave without saving your changes?',
            description: `This action could not be undone`,

            confirmButtonLabel: 'Discard changes',
            discardButtonLabel: 'Back to edit',
        })
    }

    protected async initAvailability() {
        // Automatically selects best slot if schedule is missing
        if (this.SELECT_TIME_AUTOMATICALLY) {
            combineLatest([
                this.eventFormStateModel.state.eventScheduleStream,
                this.availabilityProvider.suggestedAvailableSlotStream,
            ]).pipe(
                takeUntil(this.destroyEvent),
            ).subscribe(async ([ schedule, suggested ]) => {
                if (!schedule.start || !schedule.end) {
                    const start = moment(suggested.timeStamp)
                    return this.eventFormStateModel.setEventSchedule({
                        ...schedule,
                        start: start.toDate(),
                        end: start.clone().add(
                            await this.eventFormStateModel.state.durationStream, 'minutes',
                        ).toDate(),
                    })
                }
            })
        }

        // Binds availability provider to the current context
        await this.availabilityProvider.initialize({
            v2: true,
            emails: this.state.attendeesStream.pipe(
                map(attendees => attendees.map(attendee => attendee.userData.email)),
            ),
            timeZone: this.state.selectedTimeZoneNameStream,
            dateRange: this.availabilityViewModel.loadAvailabilityDatesRangeStream,
            meetingMode: this.state.meetingModeStream,
            meetingDuration: this.state.durationStream,
            // Ignores blocking events with same booking code
            bookingCode: this.state.originalEventDataStream.pipe(
                map(originalData => originalData.bookingCode),
            ),
        })

        await this.availabilityViewModel.initViewModel()
    }

    protected async initCalendarGrid() {
        // Skip initialization flow
        if (!this.calendaringGridViewModel) {
            return
        }

        const findCalendarEvent = (
            events: CalendarEvent[],
            target: EditMeetingData,
        ): CalendarEvent => {
            return events.length
                ? events.find(event => event.id === (target._id ?? target.eventId))
                : null
        }

        const draftEvents$ = combineLatest([
            this.createDraftEvents$,
            this.state.isDraftModeStream,
            this.state.eventScheduleStream,
            this.state.originalEventDataStream,
        ]).pipe(
            debounceTime(25),
            takeUntil(this.destroyEvent),
            map(([
                createDraftEvents, isDraft, schedule, eventData,
            ]) => {
                if (!isDraft || !createDraftEvents || !(
                    schedule.end instanceof Date && schedule.start instanceof Date
                )) {
                    return []
                }

                if (schedule.rRule) {
                    const rule = buildRRuleFromSchedule(schedule)
                    const recurrenceDates = rule.between(
                        moment(this.calendaringGridViewModel.viewDate$.getValue())
                            .add(-1, 'month').toDate(),
                        moment(this.calendaringGridViewModel.viewDate$.getValue())
                            .add(+1, 'month').toDate(),
                    )

                    const durationMs = Math.floor(
                        schedule.end.valueOf() - schedule.start.valueOf()
                    )
                    const durationMin = Math.floor(durationMs / 60 / 1000)
                    return recurrenceDates.map((date, i) => {
                        return {
                            id: `${eventData._id}|${i}`,
                            start: date,
                            end: new Date(date.valueOf() + durationMs),
                            meta: {
                                type: this.DRAFT_EVENT_SLOT_GRID_EL_TYPE,
                                openedIn: this.DRAFT_EVENT_OPENED_IN_CMP_NAME,
                                payload: { duration: durationMin }
                            },
                            draggable: i === 0,
                            resizable: {
                                afterEnd: i === 0,
                                beforeStart: i === 0,
                            },
                        } as CalendarEvent
                    })
                } else {
                    const durationMin = Math.floor((
                        schedule.end.valueOf() - schedule.start.valueOf()
                    ) / 60 / 1000)
                    return [{
                        id: `${eventData._id}`,
                        start: schedule.start,
                        end: schedule.end,
                        meta: {
                            type: this.DRAFT_EVENT_SLOT_GRID_EL_TYPE,
                            openedIn: this.DRAFT_EVENT_OPENED_IN_CMP_NAME,
                            payload: { duration: durationMin },
                        },
                        draggable: true,
                        resizable: {
                            afterEnd: true,
                            beforeStart: true,
                        },
                    } as CalendarEvent]
                }
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        )

        // Handle schedule updates in edit mode
        combineLatest([
            this.state.isDraftModeStream,
            this.state.titleStream,
            this.state.eventScheduleStream,
            this.state.originalEventDataStream,
        ]).pipe(
            takeUntil(this.destroyEvent),
            debounceTime(25),
        ).subscribe(([
            isDraft, title, schedule, eventData,
        ]) => {
            // Data validation
            if (isDraft || !(
                schedule.start instanceof Date
                && schedule.end instanceof Date
            )) {
                return null
            }

            const calendarEvents$ = this.calendaringGridViewModel.calendarEvents$
            const overriddenEvents$ = this.calendaringGridViewModel.overriddenEvents$

            let targetEvent: CalendarEvent
            // Target calendar grid event to be updated
            targetEvent = findCalendarEvent(overriddenEvents$.value, eventData)
            if (!targetEvent) {
                // Copy calendar event to the overridden events list
                targetEvent = findCalendarEvent(calendarEvents$.value, eventData)
                if (targetEvent) {
                    targetEvent = {
                        ...clone(targetEvent),
                        draggable: true,
                        resizable: {
                            afterEnd: true,
                            beforeStart: true,
                        },
                    }

                    // Highlight selected event
                    if (targetEvent.meta.payload?.state) {
                        targetEvent.meta.payload.state.isActive = true
                    }

                    overriddenEvents$.next(overriddenEvents$.value.concat(targetEvent))
                }
            }

            // Mutate properties
            if (targetEvent) {
                targetEvent.title = title
                targetEvent.end = schedule.end
                targetEvent.start = schedule.start
                if (targetEvent.meta.payload) {
                    targetEvent.meta.payload.end = schedule.end
                    targetEvent.meta.payload.start = schedule.start
                }
            }
        })

        // Switch dashboard calendar page to the schedule start
        this.state.eventScheduleStream.pipe(
            filter(schedule => Boolean(schedule.start)),
            debounceTime(25),
            takeUntil(this.destroyEvent),
        ).subscribe((schedule) => {
            this.calendaringGridViewModel.viewDate$.next(schedule.start)
        })

        // Copy draft events to the calendar grid
        draftEvents$.subscribe(draftEvents => {
            this.calendaringGridViewModel.temporaryEvents$.next(
                this.calendaringGridViewModel.temporaryEvents$.value
                    .filter(event => event.meta.type !== this.DRAFT_EVENT_SLOT_GRID_EL_TYPE)
                    .concat(draftEvents)
            )
        })

        // Display availability slots for displayed calendar grid date
        combineLatest([
            draftEvents$,
            this.calendaringGridViewModel.overriddenEvents$,
            this.availabilityViewModel.groupAvailabilityStream,
        ]).pipe(
            debounceTime(25),
            takeUntil(this.destroyEvent),
        ).subscribe(([
            draftEvents, overriddenEvents, availability,
        ]) => {
            // All calendar items which may conflict with availability
            const scheduleRangesMs = [
                ...draftEvents,
                ...overriddenEvents,
            ].map(event => ({
                endMs: event.end.valueOf() - 1000,
                startMs: event.start.valueOf() + 1000,
            }))

            // Remove all existing availability slots from the grid
            let temporaryEvents = this.calendaringGridViewModel.temporaryEvents$.value.filter(
                event => event.meta.type !== this.AVAILABILITY_SLOT_GRID_EL_TYPE,
            )

            // Push available slots to the calendar grid custom events
            availability.forEach(set => {
                const suitableSlots = set.slots.filter(slot => {
                    if (!slot.free) {
                        return false
                    }

                    // Remove slots conflicts with schedule
                    const startMs = new Date(slot.timeStamp).valueOf()
                    for (let rangeMs of scheduleRangesMs) {
                        const overlapType = getOverlapTypeForRanges({
                            startMs: new Date(slot.timeStamp).valueOf(),
                            endMs: startMs + (slot.duration * 60 * 1000),
                        }, rangeMs)

                        if (overlapType > 0) {
                            return false
                        }
                    }

                    return true
                })

                // Add suitable slots to the calendar grid temporary events
                temporaryEvents = temporaryEvents.concat(
                    suitableSlots.map(slot => this.availabilitySlotToCalendarGridEvent(slot)),
                )
            })

            this.calendaringGridViewModel.temporaryEvents$.next(temporaryEvents)
        })

        // Update event schedule from calendar grid
        this.calendaringGridViewModel.onEventDropped.pipe(
            takeUntil(this.destroyEvent),
            withLatestFrom(combineLatest([
                this.state.eventScheduleStream,
                this.state.originalEventDataStream,
            ])),
        ).subscribe(async ([droppedEvent, [schedule, eventData]]) => {
            const emulatedTz = this.calendaringGridViewModel.emulatedTimeZone$.value

            // Get original event id for recurrent events
            const droppedEventId = droppedEvent?.event?.id?.includes('|')
                ? droppedEvent.event.id.split('|')[0]
                : droppedEvent.event.id

            if (droppedEventId === (eventData._id ?? eventData.eventId)) {
                this.eventFormStateModel.setEventSchedule({
                    ...schedule,
                    end: emulatedTz?.zone
                        ? revertEmulatedTimeZone(droppedEvent.newEnd, emulatedTz.zone).toDate()
                        : droppedEvent.newEnd,
                    start: emulatedTz?.zone
                        ? revertEmulatedTimeZone(droppedEvent.newStart, emulatedTz.zone).toDate()
                        : droppedEvent.newStart,
                })
            }
        })

        // Show weekends if there are any slots
        combineLatest([
            this.calendaringGridViewModel.temporaryEvents$,
            this.calendaringGridViewModel.overriddenEvents$,
        ]).pipe(
            debounceTime(100),
            takeUntil(this.destroyEvent),
        ).subscribe(([tmpEvents, overriddenEvents]) => {
            const weekendEvent = [...tmpEvents, ...overriddenEvents].find(event => {
                return event.start.getUTCDay() === 0 || event.start.getUTCDay() === 6
            })
            if (weekendEvent) {
                this.calendaringGridViewModel.hideWeekends$.next(false)
            }
        })

        // Cleanup temporary data on destroy
        this.destroyEvent.subscribe(() => {
            // Remove draft events and availability slots
            this.calendaringGridViewModel.temporaryEvents$.next(
                this.calendaringGridViewModel.temporaryEvents$.value.filter(event => {
                    return event.meta.type !== this.DRAFT_EVENT_SLOT_GRID_EL_TYPE
                        && event.meta.type !== this.AVAILABILITY_SLOT_GRID_EL_TYPE
                }),
            )
            // Remove overridden events data
            this.calendaringGridViewModel.overriddenEvents$.next([])
        })
    }

    protected async initFormStateModel(data: EditMeetingData) {
        // Convert serialized dates to native Date object
        if (data.schedule) {
            if (typeof data.schedule.end === 'string') {
                data.schedule.end = new Date(data.schedule.end)
            }
            if (typeof data.schedule.start === 'string') {
                data.schedule.start = new Date(data.schedule.start)
            }
        }

        try {
            await this.eventFormStateModel.initViewModel(data)
        } catch (error) {
            console.error(`Cannot initialize EventFormStateModel`, error)
        }

        this.initOnFormSubmitSubscription()
    }

    protected async handleEventFormSubmit(data: EditMeetingData) {
        try {
            let result: EditMeetingData
            if (data.isDraft) {
                result = await this.meetingsManager.createMeetingFromDraft(data)
                await this.api.analytics.track({
                    event: AnalyticsAction.MeetingProposed,
                    source: AnalyticsSource.WebApp,
                    feature: AnalyticsTrackedFeature.NewEvent,
                    properties: { meetingMode: data.mode },
                })
            } else {
                result = await this.meetingsManager.updateMeeting(data.dockKey, data)
            }

            // Init form with updated data
            await this.eventFormStateModel.initViewModel(result)
            await this.reloadDayCalendarEventsStorage(data.schedule.start)
        } catch (error) {
            console.error(`Cannot save event`, error)
            this.snackbarManager.error(`Cannot save event. Please try later`)
        } finally {
            this.onSubmit.next()
            this.isLoading$.next(false)
        }
    }

    protected initOnFormSubmitSubscription() {
        this.eventFormStateModel.state.onSubmit.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(data => this.handleEventFormSubmit(data))
    }

    protected availabilitySlotToCalendarGridEvent(slot: AvailabilitySlot) {
        const start = new Date(slot.timeStamp)
        return {
            id: `${slot.timeStamp}`,
            title: slot.label,
            start: start,
            end: new Date(start.valueOf() + slot.duration * MIN_DURATION_MS),
            meta: {
                payload: slot,
                best: slot.best,
                recommended: slot['recommended'],
                type: this.AVAILABILITY_SLOT_GRID_EL_TYPE,
            },
            draggable: false,
            resizable: {
                afterEnd: false,
                beforeStart: false,
            },
        } as CalendarEvent
    }

    protected async reloadDayCalendarEventsStorage(day: m.MomentInput) {
        if (this.calendarEventsStorage) {
            await this.calendarEventsStorage.getEventsForDateRange({
                end: m(day).endOf('day').toDate(),
                start: m(day).startOf('day').toDate(),
            }, true)
        }
    }
}

export const buildRRuleFromSchedule = (schedule: EventSchedule): RRule => {
    const rule = RRule.fromString(schedule.rRule)
    rule.options.byhour = [schedule.start.getUTCHours()]
    rule.options.byminute = [schedule.start.getUTCMinutes()]
    rule.options.bysecond = [0]

    if (rule.options.freq === RRule.DAILY) {
        rule.options.dtstart = schedule.start
    }
    if (rule.options.freq === RRule.WEEKLY) {
        rule.options.byweekday = [schedule.start.getDay()-1]
    }
    if (rule.options.freq === RRule.MONTHLY) {
        rule.options.bymonthday = [schedule.start.getDate()]
    }
    return rule
}
