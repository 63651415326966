import { ApiContext } from '@undock/api/contracts/api.context'
import { OwnGrantorsRoute } from '@undock/api/scopes/delegate/routes/own-grantors.route'
import { OwnGranteesRoute } from '@undock/api/scopes/delegate/routes/own-grantees.route'


export class DelegateScope {

    public ownGrantors: OwnGrantorsRoute
    public ownGrantees: OwnGranteesRoute

    public constructor(context: ApiContext) {
        this.ownGrantors = new OwnGrantorsRoute(context)
        this.ownGrantees = new OwnGranteesRoute(context)
    }
}
