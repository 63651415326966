import { Type } from '@angular/core'

export abstract class HandleErrorStrategy {
    public readonly position: number
    public readonly errorType: Type<Error>

    protected constructor() {}

    public abstract handle(error: Error): void
}
