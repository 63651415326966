import { PublicProfileData } from '@undock/user'


export function generateMeetingTitle(
    meetingsUsersPublicProfiles: PublicProfileData[], meetingTitlePrefix?: string,
): string {
    const getProfileName = (profileData: PublicProfileData): string => {
        return profileData.firstName || profileData.displayName || profileData.email
    }

    const getProfileFullName = (profileData: PublicProfileData): string => {
        return profileData.displayName || profileData.email
    }

    const length = meetingsUsersPublicProfiles.length

    if (length === 1) {
        return meetingTitlePrefix ?
            `${meetingTitlePrefix}: ${getProfileFullName(meetingsUsersPublicProfiles[0])}` : `${getProfileName(meetingsUsersPublicProfiles[0])}'s Dock`
    }

    if (length === 2) {
        return (meetingTitlePrefix ? `${meetingTitlePrefix}: ` : '') + `${getProfileFullName(meetingsUsersPublicProfiles[0])} <> ${getProfileFullName(meetingsUsersPublicProfiles[1])}`
    }

    if (length >= 3) {
        return (meetingTitlePrefix ? `${meetingTitlePrefix}: ` : '') + `${getProfileFullName(meetingsUsersPublicProfiles[0])} <> ${getProfileFullName(meetingsUsersPublicProfiles[1])}`
            + ` and ${meetingsUsersPublicProfiles.length - 2}`
    }

    return ''
}
