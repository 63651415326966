import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Inject,
    Input,
} from '@angular/core'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'
import {
    AsyncStream,
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Memoize } from '@undock/core'
import { CurrentUser } from '@undock/session'
import {
    StatusesState,
    StatusesStateModel,
} from '@undock/data-store/states/statuses.state'
import { environment } from '@web/environments/environment'
import { IntegrationsManager } from '@undock/integrations'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { LayoutStateModel } from '@undock/common/layout/states/layout.state'
import { from } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'


@Component({
    selector: 'app-default-header',
    templateUrl: 'default-header.component.html',
    styleUrls: ['default-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultHeaderComponent {

    public isAssistantModeActivated: boolean = false

    public TooltipPosition = TooltipPosition
    public readonly isProduction = environment.production

    public readonly statusesState: StatusesState
    public readonly isVisitorRegularUserStream: AsyncStream<boolean>
    @HostBinding('class.guest') guest: boolean

    @Input() showLogo: boolean = true
    @Input() logoColor: string = 'currentColor'
    @Input() displayNavigationIcons: boolean = true
    @Input() displayNewEventButton: boolean = true

    @CompleteOnDestroy()
    public readonly isConnectCalendarPopup = new ValueSubject(false)

    @CompleteOnDestroy()
    public isOpenedStateStream = new ValueSubject<boolean>(false)

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,
        public readonly state: SidebarState,
        public readonly layoutStateModel: LayoutStateModel,
        public readonly integrationsManager: IntegrationsManager,
        protected currentUser: CurrentUser,
        protected proposalsStateModel: StatusesStateModel,
        protected snackbarManager: SnackbarManager,
    ) {
        this.statusesState = this.proposalsStateModel.state
        this.isVisitorRegularUserStream = this.currentUser.isRegularUserStream
    }

    @Memoize()
    public get currentlyViewedPage(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            from(Promise.resolve(location.pathname)),
        )
    }

    public ngOnInit() {
        this.isVisitorRegularUserStream.pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(v => this.guest = !Boolean(v))
    }

    public onToggleConnectPopup() {
        this.isConnectCalendarPopup.next(!this.isConnectCalendarPopup.value)
    }

    public async connectGoogleCalendar(): Promise<void> {
        try {
            await this.integrationsManager.connectGoogleCalendar()
        } catch (error) {
            this.snackbarManager.warning(`Unable to connect Google calendar. Please try later.`)
        }
    }

    public async connectMicrosoftCalendar(): Promise<void> {
        try {
            await this.integrationsManager.connectMicrosoftCalendar()
        } catch (error) {
            this.snackbarManager.warning(`Unable to connect Google calendar. Please try later.`)
        }
    }

    public async activateAssistantMode() {
        this.isAssistantModeActivated = !this.isAssistantModeActivated
    }
}
