import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { ChargeInterface } from '@undock/api/scopes/charges/contracts/charge.interface'
import { SubscriptionInterface } from '@undock/api/scopes/subscriptions/contracts/subscriptions.interface'
import { HasPaymentSecretKey } from '@undock/integrations/contracts/has-payment-secret-key'
import { InitializeGroupSubscriptionRequest } from '@undock/api/scopes/subscriptions/requests/initialize-subscription.request'
import { InitializeGroupSubscriptionResponse } from '@undock/api/scopes/subscriptions/contracts/initialize-group-subscription.response'


export abstract class PaymentClient {
    public abstract initialize(): void

    public abstract createPaymentUI(payment: HasPaymentSecretKey, parentElSelector: string): Promise<any>

    public abstract destroyPaymentUI(paymentElement: any): void

    public abstract confirmPayment(payment: HasPaymentSecretKey, savePaymentMethod?: boolean)

    public abstract initializeBookingCharge(
        userUId: string, accountId: string, scheduleId: string, duration: MeetingDuration
    ): Promise<ChargeInterface>

    public abstract initializeSubscription(
        accountUserUId: string, accountId: string, productId: string, priceId: string
    ): Promise<SubscriptionInterface>

    public abstract initializeGroupSubscription(request: InitializeGroupSubscriptionRequest): Promise<InitializeGroupSubscriptionResponse>
}
