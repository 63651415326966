import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations'

export const fadeInTimeout = 600
export const fadeOutTimeout = 320

export const fadeAnimation = trigger('fade', [
    state('true', style({ opacity: '1' })),
    state('false', style({ opacity: '0' })),

    transition(':enter', [
        style({ opacity: '0' }),
        animate(fadeInTimeout, style({ opacity: '1' })),
    ]),

    transition('true => false', [animate(fadeOutTimeout)]),
])
