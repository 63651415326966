import { ApiRoute } from '@undock/api'
import {
    InitializeGroupSubscriptionRequest,
    InitializeSubscriptionRequest,
} from '@undock/api/scopes/subscriptions/requests/initialize-subscription.request'
import { SubscriptionInterface } from '@undock/api/scopes/subscriptions/contracts/subscriptions.interface'
import { BankAccountDetails } from '@undock/api/scopes/charges/routes/charges.route'
import { CancelSubscriptionRequest } from '@undock/api/scopes/subscriptions/requests/cancel-subscription.request'
import {
    IGroupSubscriptionAccount,
    IGroupSubscriptionAggregate,
} from '@undock/api/scopes/subscriptions/contracts/group-subscription-account.interface'
import { InitializeGroupSubscriptionResponse } from '@undock/api/scopes/subscriptions/contracts/initialize-group-subscription.response'

export class GroupSubscriptionsRoute extends ApiRoute {

    public init() {
        this.route = 'integrations/subscriptions/group'
    }

    public getByOrganizationId(organizationId: string): Promise<IGroupSubscriptionAggregate> {
        return this.get<IGroupSubscriptionAggregate>()
                   .endpoint(`details/${organizationId}`)
    }

    public update(accountId: string, data: Partial<IGroupSubscriptionAccount>): Promise<IGroupSubscriptionAggregate> {
        return this.post<IGroupSubscriptionAggregate>()
                   .withData(data)
                   .endpoint(`update/${accountId}`)
    }

    public updateBilling(accountId: string, data: Pick<IGroupSubscriptionAccount, 'subscriptionId' | 'seats' | 'frequency' | 'totalPrice'>): Promise<IGroupSubscriptionAggregate> {
        return this.post<IGroupSubscriptionAggregate>()
                   .withData({
                       ...data,
                       updateBilling: true
                   })
                   .endpoint(`update/${accountId}`)
    }

    public setGroupPaymentMethodAsDefaultById(organizationId: string, paymentId: string): Promise<BankAccountDetails> {
        return this.post<BankAccountDetails>()
                   .endpoint(`payment-method/default/${organizationId}/${paymentId}`)
    }

    /**
     * TODO - ORGS
     */
    public removePaymentMethodById(id: string): Promise<BankAccountDetails> {
        return this.delete<BankAccountDetails>()
                   .endpoint(`payment-method/${id}`)
    }

    public initializeGroupSubscription(request: InitializeGroupSubscriptionRequest): Promise<InitializeGroupSubscriptionResponse> {
        return this.post<InitializeGroupSubscriptionResponse>()
                   .withData(request)
                   .endpoint(`init`)
    }

    public saveGroupSubscription(response: InitializeGroupSubscriptionResponse): Promise<IGroupSubscriptionAccount> {
        return this.post<IGroupSubscriptionAccount>()
                   .withData(response)
                   .endpoint(`save`)
    }

    /**
     * TODO - ORGS
     */
    public cancelGroupSubscription(request: CancelSubscriptionRequest): Promise<IGroupSubscriptionAccount> {
        return this.post<IGroupSubscriptionAccount>()
                   .withData(request)
                   .endpoint(`cancel`)
    }

}
