import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewChild,
} from '@angular/core'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { TimeCommandViewModel } from '@undock/time/prompt/states/time-command.view-model'
import { Memoize } from '@undock/core'
import {
    map,
    tap,
    shareReplay,
    takeUntil,
    takeWhile,
} from 'rxjs'
import {
    TimeCommandAction,
    TimeCommandBlueprintEvent,
} from '@undock/api/scopes/nlp/routes/commands.route'
import { Clipboard } from '@angular/cdk/clipboard'
import {
    SnackbarManager,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { CurrentUser } from '@undock/session'
import { EventAttendee } from '@undock/api/scopes/time/contracts/timeline-event/event-attendee.interface'
import { default as m, Moment } from 'moment'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import {
    pairwise,
    startWith,
} from 'rxjs/operators'
import { MatLegacyTooltip } from '@angular/material/legacy-tooltip'


export enum EventConflictCategory {
    None,
    PersonalCommitment,
    InternalInteraction,
    WorkFocusPeriod,
    MealBreak,
    AdjustableTimeFrame,
    ExternalAppointment,
    HighImportance,
    TeamMeeting
}

export interface TimeCommandBlueprintGroupEvent extends TimeCommandBlueprintEvent {
    rank?: number
    score?: number
    freeAttendees?: EventAttendee[]
    numConflicts?: number
    conflictCategory?: EventConflictCategory
    conflictEventOwner?: string
    description?: string
}

export interface UiGroupEventsByDayGroup {
    day: Date
    events: TimeCommandBlueprintGroupEvent[]
}

export interface UiGroupSlotByDayGroup {
    day: string
    slots: string[]
}


@Component({
    selector: 'app-time-command-group-schedule',
    templateUrl: './time-command-group-schedule.component.html',
        styleUrls: ['time-command-group-schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeCommandGroupScheduleComponent {

    public readonly expandedSlotStream: ReactiveStream<TimeCommandBlueprintGroupEvent>

    @CompleteOnDestroy()
    protected currentActionSubject = new ValueSubject<TimeCommandAction>(null)

    @CompleteOnDestroy()
    protected expandedSlotSubject = new ValueSubject<TimeCommandBlueprintGroupEvent>(null)

    @Input() set action(value: TimeCommandAction) {
        this.currentActionSubject.next(value)
    }

    public readonly TooltipPosition = TooltipPosition

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        public viewModel: TimeCommandViewModel,
        protected clipboard: Clipboard,
        protected currentUser: CurrentUser,
        protected snackbarManager: SnackbarManager,
    ) {
        this.expandedSlotStream = this.expandedSlotSubject.asStream()

        this.areDescriptionsAvailableStream.pipe(
            pairwise(),
            tap(async ([prev, current]) => {
                if (!prev && current) {
                    let events = await this.groupEventsStream
                    if (events?.length) {
                        let selected = events.find(e => e.isSelected)
                        if (selected) {
                            this.expandSlot(selected)
                        }
                    }
                }
            }),

            takeWhile(([prev, current]) => !(prev && current)),
            takeUntil(this.destroyedEvent),
        ).subscribe()
    }

    @Memoize()
    public get groupEventsStream(): ReactiveStream<TimeCommandBlueprintGroupEvent[]> {
        return new ReactiveStream<TimeCommandBlueprintGroupEvent[]>(this.currentActionSubject.pipe(
            map(action => !!action
                ? action.events.sort((a, b) => new Date(a.schedule.start).valueOf() - new Date(b.schedule.start).valueOf())
                : []),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get groupEventsByDayStream(): ReactiveStream<UiGroupEventsByDayGroup[]> {
        return new ReactiveStream<UiGroupEventsByDayGroup[]>(this.groupEventsStream.pipe(
            map(events => events?.length
                ? this.convertEventsToGroups(events)
                : []),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get quickMatchSlotsByDayStream(): ReactiveStream<UiGroupSlotByDayGroup[]> {
        return new ReactiveStream<UiGroupSlotByDayGroup[]>(this.currentActionSubject.pipe(
            map(action => action?.timeSlots?.length
                ? this.convertSlotsToGroups(action.timeSlots)
                : []),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get areDescriptionsAvailableStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.groupEventsByDayStream.pipe(
            map(groups => {
                let events = groups?.map(group => group.events)?.flat()?.sort(
                    (a, b) => a.rank - b.rank
                )
                if (events?.length) {
                    return Boolean(events[0].description)
                }
                return false
            }),

            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    @Memoize()
    public get attendeesStream(): ReactiveStream<EventAttendee[]> {
        return new ReactiveStream<EventAttendee[]>(this.currentActionSubject.pipe(
            map(action => !!action
                ? action.attendees
                : []),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    public async updateEventSelection(event: TimeCommandBlueprintGroupEvent) {
        await this.viewModel.clearEventSelections()
        return this.viewModel.toggleEventSelection(event)
    }

    public expandSlot(event: TimeCommandBlueprintGroupEvent) {
        if (this.expandedSlotSubject.getValue()?.iCalUId !== event.iCalUId) {
            this.expandedSlotSubject.next(event)
        } else {
            this.expandedSlotSubject.next(null)
        }
    }

    protected convertEventsToGroups(events: TimeCommandBlueprintGroupEvent[]): UiGroupEventsByDayGroup[] {
        if (events?.length > 0) {
            const eventGroups = [[events[0]]]
            for (let i = 1; i < events.length; i++) {
                /**
                 * Always use last range as current
                 */
                let currentEvent = events[i],
                    currentGroup = eventGroups[eventGroups.length - 1],
                    currentGroupLastEvent = currentGroup[currentGroup.length - 1]

                if (m(currentGroupLastEvent.schedule.start).isSame(currentEvent.schedule.start, 'day')) {
                    currentGroup.push(currentEvent)
                } else {
                    /**
                     * Create new group in this case
                     */
                    eventGroups.push([currentEvent])
                }
            }

            return eventGroups.map(group => {
                return {
                    day: group[0].schedule.start,
                    events: group,
                }
            })
        }

        return []
    }

    protected convertSlotsToGroups(slots: string[]): UiGroupSlotByDayGroup[] {
        if (slots?.length > 0) {
            const slotGroups = [[slots[0]]]
            for (let i = 1; i < slots.length; i++) {
                /**
                 * Always use last range as current
                 */
                let currentSlot = slots[i],
                    currentGroup = slotGroups[slotGroups.length - 1],
                    currentGroupLastSlot = currentGroup[currentGroup.length - 1]

                if (m(currentGroupLastSlot).isSame(currentSlot, 'day')) {
                    currentGroup.push(currentSlot)
                } else {
                    /**
                     * Create new group in this case
                     */
                    slotGroups.push([currentSlot])
                }
            }

            return slotGroups.map(group => {
                return {
                    day: group[0],
                    slots: group,
                }
            })
        }

        return []
    }

}
