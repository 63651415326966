import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-close',
    templateUrl: 'close.html',
    styleUrls: ['close.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseIconComponent extends IconTemplateComponent {}
