import {
    Component,
    OnInit,
} from '@angular/core'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'app-not-found',
    template: `
        <div class="container-fluid not-found-container" style="display: flex">
            <div class="not-found-image">
                <img src="/assets/404.png" alt="Not Found"/>
                <p>Seems like meeting was canceled or deleted.</p>
                <p>Please contact meeting owner.</p>
            </div>
        </div>
    `,
    styles: [`
        .not-found-container {
            /* height: auto; */
            overflow-y: auto;
            overflow-x: hidden;
            height: 100vh;
            width: 100vw;
        }

        .not-found-image {
            width: 100%;
            height: 100%;
            background-color: transparent;
            color: $color-grayscale-black;
            font-family: 'Inter', sans-serif;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    `],
})
export class MeetingCanceledPageComponent implements OnInit {

    public constructor(
        private title: Title,
    ) {}

    public ngOnInit(): void {
        this.title.setTitle(`Undock | Not Found`)
    }
}
