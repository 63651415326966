import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
} from '@angular/core'

import {
    debounceTime,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { fromEvent } from 'rxjs'


@Directive({
    selector: '[infiniteList]',
})
export class InfiniteListDirective implements OnInit {

    /**
     * Direction of infinite list
     * 'top' means that elements should be loaded from the top
     */
    @Input() defaultPosition: 'top' | 'bottom' = 'top'

    /**
     * Offset when directive thinks that threshold already achieved
     */
    @Input() scrollThresholdOffset: number = 200 // px


    @Output() topThreshold = new EventEmitter<void>()

    @Output() bottomThreshold = new EventEmitter<void>()


    /**
     * Used to determine scrolling direction
     */
    private previousScrollTopValue = 0

    private readonly INITIAL_SCROLL_RESTORATION = history.scrollRestoration

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private zone: NgZone,
        public readonly elementRef: ElementRef<HTMLElement>,
    ) {}

    public ngOnInit(): void {
        history.scrollRestoration = 'manual'

        this.zone.runOutsideAngular(() => {
            if (this.elementRef?.nativeElement) {
                fromEvent<Event>(this.elementRef.nativeElement, 'scroll').pipe(
                    takeUntil(this.destroyedEvent),
                    debounceTime(100), // ms
                ).subscribe(
                    this.hostScrollEventListener.bind(this),
                )
            }
        })

        this.destroyedEvent.subscribe(() => {
            history.scrollRestoration = this.INITIAL_SCROLL_RESTORATION
        })
    }

    public get scrollTop(): number {
        return this.elementRef.nativeElement ? this.elementRef.nativeElement.scrollTop : null
    }

    public set scrollTop(value: number) {
        this.elementRef.nativeElement && (this.elementRef.nativeElement.scrollTop = value)
    }

    public get scrollHeight(): number {
        return this.elementRef.nativeElement ? this.elementRef.nativeElement.scrollHeight : null
    }

    public get clientHeight(): number {
        return this.elementRef.nativeElement ? this.elementRef.nativeElement.clientHeight : null
    }


    public scrollToTop(): void {
        this.scrollTop = 0
    }


    public scrollToBottom(): void {
        this.scrollTop = this.scrollHeight
    }


    /**
     * @HostListener() fires changeDetection in the parent components.
     */
    private hostScrollEventListener() {
        if (this.previousScrollTopValue - this.scrollTop > 0) {
            if (this.isTopThresholdAchieved()) {
                this.topThreshold.next()
            }
        } else {
            if (this.isBottomThresholdAchieved()) {
                this.bottomThreshold.next()
            }
        }

        this.previousScrollTopValue = this.scrollTop
    }

    private isTopThresholdAchieved(): boolean {
        return this.scrollTop <= this.scrollThresholdOffset
    }

    private isBottomThresholdAchieved(): boolean {
        return (this.scrollHeight - this.clientHeight - this.scrollTop) < this.scrollThresholdOffset
    }
}
