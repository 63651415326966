/**
 * Clones given object or class
 */
export function clone<T extends any>(target: T, ignoreProps = []): T {

    ignoreProps = [
        ...ignoreProps,
        /**
         * internal ORMOnFore fields should not be cloned
         */
        '__ormOnFire', 'toJSON',
    ]

    /**
     * Copying all target object data
     */
    const data = JSON.parse(JSON.stringify(target))

    if (typeof target === 'object') {
        let res = new target['__proto__'].constructor()

        for (let key in target) {
            if (ignoreProps.includes(key)) {
                continue
            }

            if (target.hasOwnProperty(key)) {
                res[key] = data[key] ?? null
            }
        }

        return res
    }

    return data
}
