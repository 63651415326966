import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { CreditCardType } from '@undock/api/scopes/subscriptions/routes/subscriptions.route'


@Component({
    selector: 'credit-card-logo-icon',
    templateUrl: 'credit-card-logo-icon.component.html',
    styleUrls: ['credit-card-logo-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardLogoIconComponent {

    public readonly CreditCardType = CreditCardType

    @Input() type: CreditCardType | string = 'default'

    @Input() width: number = 60

}
