import { Injectable } from '@angular/core'
import { AclManager } from '@undock/acl/services/acl.manager'


@Injectable({
    providedIn: 'root',
})
export class DockAccessPolicy {

    private readonly dockResourceName = 'Dock'

    public constructor(
        private aclManager: AclManager,
    ) {}

    public async canView(dockId): Promise<boolean> {
        return this.checkDockPermission(dockId, 'view')
    }

    public async canEdit(dockId): Promise<boolean> {
        return this.checkDockPermission(dockId, 'edit')
    }

    public async canJoin(dockId): Promise<boolean> {
        return this.checkDockPermission(dockId, 'room')
    }

    public async canDelete(dockId): Promise<boolean> {
        return this.checkDockPermission(dockId, 'delete')
    }

    protected checkDockPermission(dockId: string, permission: string): Promise<boolean> {
        return this.aclManager.checkPermission(this.dockResourceName, dockId, permission)
    }
}
