import { ApiRoute } from '@undock/api'
import { IDelegate } from '@undock/api/scopes/delegate/contracts'

export class OwnGranteesRoute extends ApiRoute {

    public init() {
        this.route = 'delegate/own/grantees'
    }

    public list() {
        return this.get<IDelegate[]>()
                   .endpoint(`list`)
    }

    public create(data: {
        granteeId: string
        granteeUId: string
        permissions: Record<string, any>
    }) {
        return this.post<IDelegate>()
                   .withData(data)
                   .endpoint('')
    }

    public updateById(delegateId: string, data: Partial<IDelegate>) {
        return this.patch<IDelegate>()
                    .withData(data)
                    .endpoint(`${delegateId}`)
    }

    public deleteById(delegateId: string) {
        return this.delete().endpoint(`${delegateId}`)
    }
}
