import { Injectable } from '@angular/core'

import moment from 'moment'
import firebase from 'firebase/app'

import { AuthManager } from '@undock/auth'
import {
    USER_ANALYTICS_COLLECTION_NAME,
    UserAnalyticsObject,
} from '@undock/user/models/analytics/user-analytics-object.model'
import { UserAnalyticsProvider } from '@undock/user/services/analytics/user-analytics.provider'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs'
import { UserLimitType } from '@undock/feature-plans/services/user-limits.provider'
import { DateRange } from '@undock/time/availability'


@Injectable({
    providedIn: 'root'
})
export class UserAppUsageRegistry {

    protected readonly firestore = firebase.firestore()

    protected userAnalyticsStream: ReactiveStream<UserAnalyticsObject>

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private authManager: AuthManager,
        private userAnalyticsProvider: UserAnalyticsProvider
    ) {
        this.userAnalyticsStream = this.userAnalyticsProvider.userAnalyticsStream
    }

    public streamCurrentUsageValue(key: UserLimitType | string): ReactiveStream<number> {
        return new ReactiveStream<number>(
            this.userAnalyticsProvider.userAnalyticsStream.pipe(
                map(obj => {
                    if (obj) {
                        const usagePeriodKey = this.generateCurrentUsagePeriodToken(obj.applicationUsageStartDate)
                        return obj.applicationUsage[key]
                            && obj.applicationUsage[key]['byPeriod']
                            && obj.applicationUsage[key]['byPeriod'][usagePeriodKey]
                                ? obj.applicationUsage[key]['byPeriod'][usagePeriodKey]
                                : 0
                    }
                    return 0
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    /**
     * Returns feature usage for current month
     */
    public async getUsageValue(key: UserLimitType | string) {
        const { applicationUsage } = await this.userAnalyticsStream

        const currentMonthKey = moment().startOf('month')
                                        .format('YYYY-MM-DD')
        return applicationUsage[key] &&
        applicationUsage[key]['monthly'] &&
        applicationUsage[key]['monthly'][currentMonthKey] ?
            applicationUsage[key]['monthly'][currentMonthKey] : 0
    }

    /**
     * Returns feature usage for current usage period
     */
    public async getCurrentUsageValue(key: UserLimitType | string) {
        const { applicationUsage, applicationUsageStartDate } = await this.userAnalyticsStream

        const usagePeriodKey = this.generateCurrentUsagePeriodToken(applicationUsageStartDate)

        return applicationUsage[key] &&
        applicationUsage[key]['byPeriod'] &&
        applicationUsage[key]['byPeriod'][usagePeriodKey] ?
            applicationUsage[key]['byPeriod'][usagePeriodKey] : 0
    }

    public async getCurrentUsageEndDate() {
        const { applicationUsageStartDate } = await this.userAnalyticsStream
        let usagePeriod = this.calculateCurrentUsagePeriod(applicationUsageStartDate)
        return usagePeriod.end
    }

    /**
     * Adds specific amount of some usage value for current month
     */
    public async addUsageValue(key: UserLimitType | string, amount: number) {

        let { applicationUsage, applicationUsageStartDate } = await this.userAnalyticsStream

        const currentMonthKey = moment().startOf('month')
                                        .format('YYYY-MM-DD')
            , currentPeriodKey = this.generateCurrentUsagePeriodToken(applicationUsageStartDate)

        /**
         * Assign default value for app usages object
         */
        applicationUsage = Object.assign({
            [key]: { total: 0, monthly: { [currentMonthKey]: 0 }, byPeriod: { [currentPeriodKey]: 0 }},
        }, applicationUsage)

        const totalUsage = applicationUsage[key].total ?? 0
            , monthlyUsage = applicationUsage[key].monthly[currentMonthKey]
            , periodUsage = applicationUsage[key].byPeriod[currentPeriodKey]

        await this.firestore.collection(
            USER_ANALYTICS_COLLECTION_NAME
        ).doc(
            (await this.authManager.authUserStream).uid
        ).update({
            [`applicationUsage.${key}.total`]: totalUsage + amount,
            [`applicationUsage.${key}.monthly.${currentMonthKey}`]: monthlyUsage + amount,
            [`applicationUsage.${key}.byPeriod.${currentPeriodKey}`]: periodUsage + amount,
        })
    }

    protected calculateCurrentUsagePeriod(startDate: Date): DateRange {
        let monthsSincePlanStart = moment().diff(moment(startDate), 'months')
            , periodStart = moment(startDate).add(monthsSincePlanStart, 'months')
            , periodEnd = moment(periodStart).add(1, 'month').subtract(1, 'day')

        return {
            start: periodStart.toDate(),
            end: periodEnd.toDate()
        }
    }

    protected generateCurrentUsagePeriodToken(startDate: Date): string {
        let usagePeriod = this.calculateCurrentUsagePeriod(startDate)
        return `${moment(usagePeriod.start).format('YYYY-MM-DD')}/${moment(usagePeriod.end).format('YYYY-MM-DD')}`
    }

}
