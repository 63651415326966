<div class="invite-item-container">
    <div class="invite-item-container__column -email">
        <span class="invite-item-container__email">
            {{invite.displayName || invite.email}}
        </span>
        <div class="invite-item-container__connections" [class.__more]="invite.totalInteractions > 1">
            {{invite.totalInteractions === 0 ? 1 : invite.totalInteractions}} connection<span
                *ngIf="invite.totalInteractions > 1">s</span> on Undock
        </div>
    </div>
    <div class="invite-item-container__column -meet-count">
        <span class="invite-item-container__meet-count">
            <span>Met {{invite?.interactions[0].numMeetings.total}} time</span><span
                *ngIf="invite?.interactions[0].numMeetings.total > 1">s</span>
            <span class="invite-item-container__schedule-time"
                  *ngIf="{ hours: hoursSpentSchedulingStream | stream } as hoursStream">
                <span *ngIf="hoursStream.hours < 1; else greater">&nbsp;(&lt;1 hour scheduling)</span>
                <ng-template #greater>
                    <span>&nbsp;({{hoursStream.hours}}+ hour<span
                            *ngIf="hoursStream.hours > 1">s</span> scheduling)</span>
                </ng-template>
            </span>
        </span>
    </div>
    <div class="invite-item-container__column -invite">
        <div class="invite-button" (click)="onSendInviteClicked()">
            <span>Invite</span>
            <span>to Undock</span>
        </div>
    </div>
</div>
