import { Injectable } from '@angular/core'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    filter,
    map,
    shareReplay,
    takeUntil,
    tap,
} from 'rxjs/operators'
import {
    combineLatest,
    merge,
    share,
} from 'rxjs'
import {
    Config,
    ExtConnector,
    ExtensionMessageType,
    LocalStorage,
    Memoize,
} from '@undock/core'
import {
    DEFAULT_DOMAIN_SPECIFIC_EXTENSION_OPTIONS,
    DomainSpecificExtensionOptions,
    DomainSpecificExtensionOptionsMap,
    ExtensionOptions,
} from '@undock/chrome-extension/contracts/extension-options'


@Injectable()
export class ExtensionOptionsManager {

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected config: Config,
        protected extConnector: ExtConnector,
    ) {
        this.extensionOptionsStream.subscribe()
        this.domainSpecificExtensionOptionsMapStream.subscribe()
        this.domainSpecificExtensionOptionsStream.subscribe()
        this.isCopyPasteEnabledStream.subscribe()
    }

    public get domain(): string {
        return this.extConnector.hostDomain
    }

    public get isCurrentSiteAllowed(): Promise<boolean> {
        return (async () => (
            !!(await this.domainSpecificExtensionOptionsStream) ||
            (await this.extensionOptionsStream).allowUnknownSites
        ))()
    }

    @Memoize()
    public get isExtensionEnabledStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.domainSpecificExtensionOptionsStream.pipe(
            map(options => options?.enableExtension ?? false),
            takeUntil(this.destroyedEvent),
            shareReplay(1)
        ))
    }

    @Memoize()
    public get showWarningIfDisabledStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.domainSpecificExtensionOptionsStream.pipe(
            map(options => options?.showWarningIfDisabled ?? false),
            takeUntil(this.destroyedEvent),
            shareReplay(1)
        ))
    }

    @Memoize()
    public get arePredictionsEnabledStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.domainSpecificExtensionOptionsStream.pipe(
            map(options => options?.enablePredictions ?? false),
            takeUntil(this.destroyedEvent),
            shareReplay(1)
        ))
    }

    /**
     * TODO: Update this once copy paste is move to a global setting
     */
    @Memoize()
    public get isCopyPasteEnabledStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(this.domainSpecificExtensionOptionsMapStream.pipe(
            map(map => {
                if (map) {
                    for (let domain in map) {
                        if (domain && map[domain]) {
                            if (map[domain].enableCopyPaste === true) {
                                return true
                            }
                        }
                    }
                }
                return false
            }),
            takeUntil(this.destroyedEvent),
            shareReplay(1)
        ))
    }

    @Memoize()
    public get extensionOptionsStream(): ReactiveStream<ExtensionOptions> {
        return new ReactiveStream<ExtensionOptions>(this.extConnector.updateExtensionOptionsMessageStream.pipe(
            map(message => message.body),
            takeUntil(this.destroyedEvent),
            share()
        ))
    }

    @Memoize()
    public get domainSpecificExtensionOptionsMapStream(): ReactiveStream<DomainSpecificExtensionOptionsMap> {
        return new ReactiveStream<DomainSpecificExtensionOptionsMap>(this.extConnector.updateDomainSpecificExtensionOptionsMessageStream.pipe(
            map(message => message.body),
            takeUntil(this.destroyedEvent),
            share()
        ))
    }

    @Memoize()
    public get domainSpecificExtensionOptionsStream(): ReactiveStream<DomainSpecificExtensionOptions> {
        return new ReactiveStream<DomainSpecificExtensionOptions>(
            combineLatest([
                this.extensionOptionsStream,
                this.domainSpecificExtensionOptionsMapStream
            ]).pipe(
                filter(([options, _]) => !!options),
                map(([options, domainOptions]) =>
                    domainOptions?.hasOwnProperty(this.domain)
                        ? domainOptions[this.domain]
                        : options.allowUnknownSites
                            ? DEFAULT_DOMAIN_SPECIFIC_EXTENSION_OPTIONS
                            : null
                ),
                takeUntil(this.destroyedEvent),
                shareReplay(1)
            )
        )
    }

    public async setDomainOptions(options: Partial<DomainSpecificExtensionOptions>): Promise<void> {
        return this.extConnector.sendMessageToExt(ExtensionMessageType.UpdateDomainSpecificExtensionOptions, options)
    }

}
