import { ApiRoute } from '@undock/api'
import {
    IOrganization,
    IOrganizationInviteAggregate,
} from '@undock/api/scopes/organizations/contracts'
import { IOrganizationInvite } from '@undock/api/scopes/organizations/contracts'

export class InvitesRoute extends ApiRoute {

    public init() {
        this.route = 'organizations/invite'
    }

    public create(
        organizationId: string,
        invitedUserEmails: string[],
    ) {
        return this.post()
                   .withData({
                       organizationId,
                       invitedUserEmails,
                   })
                   .endpoint(`create`)
    }

    public respond(data: any) {
        return this.post()
                   .withData(data)
                   .endpoint(`respond`)
    }

    public getById(inviteId: string) {
        return this.get<
            IOrganizationInvite & { organization: IOrganization }
        >().endpoint(`id/${inviteId}`)
    }

    public list(
        organizationId: string,
        page: number = 0,
        pageSize: number = 100,
        statusFilter?: 'pending' | 'accepted' | 'declined',
    ) {
        return this.get<IOrganizationInvite[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                       statusFilter: statusFilter,
                   })
                   .endpoint(`list/${organizationId}`)
    }

    public listPersonal(
        page: number = 0,
        pageSize: number = 100,
        statusFilter?: 'pending' | 'accepted' | 'declined',
    ) {
        return this.get<IOrganizationInviteAggregate[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                       statusFilter: statusFilter,
                   })
                   .endpoint(`list-personal`)
    }

    public resendEmail(inviteId: string) {
        return this.post<void>()
                   .withData({ inviteId })
                   .endpoint(`resend-email`)
    }
}
