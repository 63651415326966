<div class="container"
     [ngClass]="{
         lg: size === 'lg',
         md: size === 'md'
    }"
>
    <div class="tag"
         *ngFor="let option of tagOptions; let index = index"
         [class.disabled]="disabled"
         [ngClass]="{selected: option.checked}"
         [matTooltip]="option.checked ? 'Disable' : 'Enable'" [matTooltipDisabled]="disabled"
         (click)="toggleTag(option)"
    >
        <app-icon-element-status-checked
                class="icon-checked"
                [height]="iconSize" [width]="iconSize"
        ></app-icon-element-status-checked>

        {{ option.label }}

        <ng-container *ngIf="showDeleteIcon && option.removable">
            <app-clean-icon class="clickable"
                            (click)="onTagRemoved.next(option.value); $event.stopPropagation()"
            ></app-clean-icon>
        </ng-container>
    </div>

    <ng-container *ngIf="showAddTagBtn">
        <div class="add-tag clickable" (click)="onAddTagClicked.emit()">
            <app-icon-plus></app-icon-plus> {{ addTagText ?? '' }}
        </div>
    </ng-container>
</div>

