<ng-container *ngIf="{
    browser: browserTimezoneDataSubject | stream,
    selected: selectedTimezoneDataSubject | stream
} as tz">

    <ng-template #defaultSelectTemplate>
        <app-select-filter
                (onSelect)="onTimeZoneChange($event)"
                [labelFn]="timezoneLabelFn"
                [compareFn]="timeZoneCompareWithFn"
                [options]="timeZoneHelper.timeZoneSelections"
                [value]="tz.selected"
        ></app-select-filter>
    </ng-template>

    <div class="time-zone-selector-container">
        <app-select-filter
                (onSelect)="onTimeZoneChange($event)"
                [labelFn]="timezoneLabelFn"
                [compareFn]="timeZoneCompareWithFn"
                [options]="timeZoneHelper.timeZoneSelections"
                [value]="tz.selected"
                [panelClass]="selectPanelClass"
                *ngIf="!defaultSelect; else defaultSelectTemplate"
        >
            <mat-select-trigger>
                <div class="mat-select-trigger-content">
                    <app-icon-time-zone [color]="'#121114'" class="icon"></app-icon-time-zone>
                    <span class="label">{{ timezoneLabelFn(tz.selected) }}</span>
                </div>
            </mat-select-trigger>
        </app-select-filter>
    </div>
</ng-container>
