import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { TimezoneData } from '@undock/time/availability/contracts'
import { TimeZoneHelper } from '@undock/time/availability/services/timezone.helper'

@Component({
    selector: 'app-time-time-zone-selector-2',
    templateUrl: 'time-zone-selector-2.component.html',
    styleUrls: ['time-zone-selector-2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeZoneSelector2Component {

    public readonly timeZoneCompareWithFn: (tz1: TimezoneData, tz2: TimezoneData) => boolean = (tz1, tz2) => tz1?.zone === tz2?.zone
    public readonly selectedTimeZoneLabelFn: (tz: TimezoneData) => string = (tz?: TimezoneData) => tz ? `${tz.label || tz.offsetLabel}, ${tz.suggestedCity}`: ''
    public readonly timezoneLabelFn: (tz: TimezoneData) => string = (tz?: TimezoneData) => {
        if (tz) {
            if (!!tz.generalName) {
                let nameCityIndex = tz.generalName?.indexOf(' - ') || -1
                return `(${tz.offsetLabel}) ${tz.label} ${tz.generalName.slice(0, nameCityIndex < 0 ? tz.generalName.length : nameCityIndex )}, ${tz.suggestedCity}`
            } else {
                return this.selectedTimeZoneLabelFn(tz)
            }
        }
        return ''
    }
    public readonly timezoneFilterFn: (tz: TimezoneData, search: string) => boolean =
        (tz: TimezoneData, search: string) => tz
            ? (this.timezoneLabelFn(tz).toLowerCase().indexOf(search) > -1
                || (tz.zone.toLowerCase().indexOf(search) > -1)
                || (tz.cities?.length
                    ? tz.cities.some(city => city.toLowerCase().indexOf(search) > -1)
                    : false)
            )
            : false

    @CompleteOnDestroy()
    public selectedTimezoneDataSubject: ValueSubject<TimezoneData> = new ValueSubject(null)

    @Input('selectedTimeZone') set selectedTimeZone(value: TimezoneData) {
        this.selectedTimezoneDataSubject.next(value)
    }

    @Input('timezoneLabelFn') timeZoneLabelOverrideFn: (tz: TimezoneData) => string = null

    @Input('selectedtTimezoneLabelFn') selectedTimeZoneLabelOverrideFn: (tz: TimezoneData) => string = null

    @Input() defaultSelect = false

    @Input() showCityLabel = true

    @Input() selectPanelClass: string

    @Output() onSelected = new EventEmitter<TimezoneData>()

    constructor(
        public timeZoneHelper: TimeZoneHelper,
    ) { }

    public onTimeZoneChange(timezone: TimezoneData) {
        this.onSelected.emit(timezone)
    }

    public get currentSelectedLabelFunction(): (tz: TimezoneData) => string {
        return this.selectedTimeZoneLabelOverrideFn
            ? this.selectedTimeZoneLabelOverrideFn
            : this.selectedTimeZoneLabelFn
    }

    public get currentLabelFunction(): (tz: TimezoneData) => string {
        return this.timeZoneLabelOverrideFn
            ? this.timeZoneLabelOverrideFn
            : this.timezoneLabelFn
    }

}
