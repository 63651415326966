<ng-container *ngIf="availabilityDaysStream | stream as availabilityDays">
    <div class="week-carousel -container">
        <ng-container *ngIf="availabilityDays?.length >= 1">
            <ng-template #datePickerOff>
                <div class="week-carousel__current-month">
                    <span>{{ availabilityDays[0] | momentTimeZone : selectedTimeZone : "MMMM" }}</span>
                </div>
            </ng-template>

            <div
                *ngIf="enableDatePicker; else datePickerOff"
                class="week-carousel__current-month"
                [style.cursor]="enableDatePicker ? 'pointer' : 'default'"
            >
                <app-date-picker
                    #datePicker
                    class="__date-selector"
                    [date]="selectedDayDateStream | stream"
                    [dateFormat]="'MMMM'"
                    [position]="'br'"
                    [showInvalidDateLabels]="true"
                    (onDateSelected)="onChangeDateSelected($event)"
                    (onOpenStateChange)="onOpenStateChange($event, datePicker)"
                ></app-date-picker>

                <div class="dropdown-arrow" (click)="toggleDatePicker(datePicker); $event.stopPropagation()">
                    <app-short-arrow-button [ngClass]="{opened: isCalendarOverlayVisible$ | stream}"></app-short-arrow-button>
                </div>
            </div>
        </ng-container>

        <div class="week-carousel__days-container"
             [class.__multi-day-mode]="isMultiDayMode$ | stream">

            <ng-container [ngSwitch]="isAvailabilityLoading$ | stream">
                <ng-container *ngSwitchCase="true">
                    <section class="week-carousel__day-item"
                             *ngFor="let day of availabilityDays; let idx = index"
                             [class.-day-selected]="(selectedAvailabilityDayIndex$ | stream) === idx"
                             [class.--single-liner]="availabilityDaysCountToDisplay === 1"
                    >
                        <div class="day-browser-day-label">
                            {{ day | momentTimeZone : selectedTimeZone : "ddd"}}
                        </div>

                        <div class="day-browser-date-label">
                            {{ day | momentTimeZone : selectedTimeZone : "D"}}
                        </div>
                    </section>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <section class="week-carousel__day-item"
                             *ngFor="let availabilityItem of availabilitySetStream | stream; let idx = index"
                             (click)="selectDayByIndex(idx)"
                             [class.-day-slots-enabled]="availabilityItem.slots.length > 0"
                             [class.--day-today]="availabilityItem.slots.length === 0"
                             [class.--single-liner]="availabilityDaysCountToDisplay === 1"
                    >
                        <ng-container [ngSwitch]="availabilityDaysCountToDisplay">
                            <ng-container *ngSwitchCase="1">
                                <ng-template #singleLineDayLabel>
                                    <div class="day-browser-day-label"
                                         [class.--day-disabled]="availabilityItem.slots.length === 0"
                                         [class.--day-selected]="(selectedAvailabilityDayIndex$ | stream) === idx"
                                         (click)="toggleDatePicker(datePicker); $event.stopPropagation()"
                                    >
                                        <span>{{ availabilityItem.day | momentTimeZone : selectedTimeZone : "ddd, MMMM"}}</span>
                                    </div>

                                    <div class="day-browser-date-label"
                                         [class.--day-disabled]="availabilityItem.slots.length === 0"
                                         [class.--day-selected]="(selectedAvailabilityDayIndex$ | stream) === idx"
                                         [class.--day-selectable]="(selectedAvailabilityDayIndex$ | stream) !== idx"
                                         [ngStyle]="{ background: themeColor }"
                                         (click)="toggleDatePicker(datePicker); $event.stopPropagation()"
                                    >
                                        <span>{{ availabilityItem.day | momentTimeZone : selectedTimeZone : "D"}}</span>
                                    </div>
                                </ng-template>

                                <ng-container [ngTemplateOutlet]="singleLineDayLabel"></ng-container>

                                <div class="week-carousel__single-line-day-selector">
                                    <app-date-picker
                                        #datePicker
                                        class="__date-selector"
                                        [date]="selectedDayDateStream | stream"
                                        [dateFormat]="''"
                                        [position]="'br'"
                                        [showInvalidDateLabels]="true"

                                        (onDateSelected)="onChangeDateSelected($event)"
                                        (onOpenStateChange)="onOpenStateChange($event, datePicker)"
                                    ></app-date-picker>

                                    <div class="dropdown-arrow" (click)="toggleDatePicker(datePicker); $event.stopPropagation()">
                                        <app-short-arrow-button [ngClass]="{opened: isCalendarOverlayVisible$ | stream}"></app-short-arrow-button>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                <div class="day-browser-day-label"
                                     [class.--day-disabled]="availabilityItem.slots.length === 0"
                                     [class.--day-selected]="(selectedAvailabilityDayIndex$ | stream) === idx"
                                >
                                    <span>{{ availabilityItem.day | momentTimeZone : selectedTimeZone : "ddd"}}</span>
                                </div>

                                <div class="day-browser-date-label"
                                     [class.--day-disabled]="availabilityItem.slots.length === 0"
                                     [class.--day-selected]="(selectedAvailabilityDayIndex$ | stream) === idx"
                                     [class.--day-selectable]="(selectedAvailabilityDayIndex$ | stream) !== idx"
                                     [ngStyle]="{background: themeColor}"
                                >
                                    <span>{{ availabilityItem.day | momentTimeZone : selectedTimeZone : "D"}}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </section>
                </ng-container>
            </ng-container>

            <app-arrow-button
                    matRipple
                    class="arrow-btn left"
                    [direction]="'left'"
                    (click)="goToPreviousWeek()"
            ></app-arrow-button>

            <app-arrow-button
                    matRipple
                    class="arrow-btn right"
                    [direction]="'right'"
                    (click)="goToNextWeek()"
            ></app-arrow-button>
        </div>
    </div>
</ng-container>
