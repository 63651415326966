import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Injectable } from '@angular/core'

import {
    map,
    takeUntil,
} from 'rxjs/operators'
import { Observable, combineLatest } from 'rxjs'

import { AuthManager } from '@undock/auth/services/auth.manager'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'


@Injectable()
export class RegularUserGuard implements CanActivate {

    protected readonly IS_NEW_USER_THRESHOLD = 24 * 60 * 60 * 1000 // 24 hours

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private router: Router,
        private authManager: AuthManager,
    ) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return combineLatest([
            this.authManager.idTokenStream,
            this.authManager.authUserStream,
            this.authManager.isRegularUserStream,
        ]).pipe(
            map(sources => {
                const [ token, authUser, isRegular ] = sources

                // Navigate to the login page
                if (!isRegular) {
                    return this.router.createUrlTree(['/login'], {
                        queryParams: { redirectPath: state.url },
                    })
                }

                /**
                 * Email confirmation
                 * User should be authenticated via email-password
                 * User should have active email-password auth provider
                 */
                if (
                    !authUser.emailVerified &&
                    token.signInProvider === 'password' &&
                    authUser.providerData.some(p => p.providerId === 'password')
                ) {
                    // Period of time to skip the email-verified check
                    const isNewUser = (
                        Date.now() - new Date(authUser?.metadata.creationTime).valueOf()
                    ) < this.IS_NEW_USER_THRESHOLD
                    if (!isNewUser) {
                        return this.router.createUrlTree([
                            'email-is-not-verified',
                        ], {
                            queryParams: { redirectPath: state.url },
                        })
                    }
                }

                return true
            }),

            takeUntil(this.destroyEvent),
        )
    }
}
