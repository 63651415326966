import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-event-label-location-icon',
    templateUrl: 'location-icon.component.html',
    styleUrls: ['location-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventLabelLocationIconComponent extends IconTemplateComponent {

    @Input() width: number = 16
    @Input() height: number = 16

    @Input() fill: string = '#F3F3F9'
    @Input() color: string = '#9A95AC'
}
