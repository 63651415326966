import { UserRoute } from './routes/user.route'
import { GuestRoute } from './routes/guest.route'
import { SettingsRoute } from './routes/settings.route'
import { ApiContext } from '@undock/api/contracts/api.context'
import { AnalyticsRoute } from '@undock/api/scopes/user/routes/analytics.route'
import { OnboardingRoute } from '@undock/api/scopes/user/routes/onboarding.route'

export class UserScope {
    public readonly guest: GuestRoute
    public readonly profile: UserRoute
    public readonly settings: SettingsRoute
    public readonly analytics: AnalyticsRoute
    public readonly onboarding: OnboardingRoute

    public constructor(context: ApiContext) {
        this.guest = new GuestRoute(context)
        this.profile = new UserRoute(context)
        this.settings = new SettingsRoute(context)
        this.analytics = new AnalyticsRoute(context)
        this.onboarding = new OnboardingRoute(context)
    }
}
