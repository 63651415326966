import {
    CreatedDateField,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import { SharedCollectionEntity } from '@undock/core'


export const PRIVATE_NOTES_COLLECTION_NAME = 'privateNote'

@Entity({
    collection: PRIVATE_NOTES_COLLECTION_NAME,
})
export class PrivateNote implements SharedCollectionEntity {

    /**
     * Should be generated with pattern `${ownerType}|${ownerId}|${userId}`
     */
    @ID() id: string

    @Field() userId: string
    @Field() userUId: string

    @Field() ownerId: string
    @Field() ownerType: string

    @Field() text: string

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date
}
