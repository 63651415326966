import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { Subject } from 'rxjs'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { takeUntil } from 'rxjs/operators'


@Component({
    selector: 'app-image-upload-preview',
    templateUrl: 'image-upload-preview.component.html',
    styleUrls: ['image-upload-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploadPreviewComponent),
            multi: true,
        }
    ],
})
export class ImageUploadPreviewComponent implements OnInit,
                                                    OnChanges,
                                                    ControlValueAccessor {
    @Output()
    @CompleteOnDestroy()
    readonly onImageUploaded = new Subject<string>()

    @Input() uploadedImageUrl: string
    @Input() placeholderImageUrl: string

    @Input() disabled: boolean
    @Input() description: string
    @Input() showDeleteButton: boolean = true

    protected onChange?: CallableFunction
    protected onTouched?: CallableFunction

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit() {
        this.onImageUploaded.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(imageUrl => {
            this.onChange?.(imageUrl)
            this.onTouched?.(true)
            this.uploadedImageUrl = imageUrl
        })
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.changeDetectorRef.detectChanges()
    }

    public registerOnChange(fn: any) {
        this.onChange = fn
    }

    public registerOnTouched(fn: any) {
        this.onTouched = fn
    }

    public writeValue(value: any) {
        this.uploadedImageUrl = value
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled
    }
}
