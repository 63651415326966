<ng-container *ngIf="isPromoBannedDisplayed">
    <section class="promo-banner" (click)="navigateToTestFlight()">
        <span>Get the <span class="highlighed">Undock Keyboard</span> on iOS Test Flight today!</span>
    </section>
</ng-container>

<ng-template #layout>
    <main class="main-layout-container" [class.with-promo-banner]="isPromoBannedDisplayed">
        <ng-container *ngIf="stateModel.state.layoutEnabledStream | stream">
            <section class="main-layout-container__nav-panel-wrapper"
                     *ngIf="authManager.isRegularUserStream | stream"
                     [class.opened]="(sidebarState.isExpandedStream | stream)"
                     [class.closed]="!(sidebarState.isExpandedStream | stream)"
                     [class.hidden]="(sidebarState.isHiddenStream | stream)"
            >
                <div class="main-layout-container__sidebar-wrapper">
                    <div class="main-layout-container__nav-wrapper">
                        <div class="main-layout-container__nav-panel-box">
                            <app-navigation-panel></app-navigation-panel>
                            <hr class="ud-separator">
                        </div>

                        <!--<div class="main-layout-container__changelog-box">
                            <app-changelog-widget></app-changelog-widget>
                        </div>-->

                        <div class="scroll-wrapper">
                            <div class="scroll-container">
                                <app-sidebar-organizations
                                    [collapsed]="!(sidebarState.isExpandedStream | stream)"
                                ></app-sidebar-organizations>
                            </div>
                        </div>
                    </div>

                    <div class="main-layout-container__user-meet-box">
                        <app-controls-panel></app-controls-panel>
                    </div>
                </div>

                <!-- To close sidebar in mobile view -->
                <div class="main-layout-container__sidebar-overlay" (click)="sidebarState.toggleViewMode()"></div>
            </section>
        </ng-container>

        <section class="main-layout-container__route-box">
            <router-outlet></router-outlet>
        </section>
    </main>
</ng-template>

<ng-container *ngIf="isGuest; else layout">
    <div class="main-layout-content" [class.with-promo-banner]="isPromoBannedDisplayed">
        <ng-container [ngTemplateOutlet]="layout"></ng-container>
        <footer *ngIf="isGuest">
            <div class="spacer">
                Calendar by <app-logo-undock class="logo" [width]="84" [height]="26" [color]="'#A59FB9'"></app-logo-undock>
            </div>
        </footer>
    </div>
</ng-container>
