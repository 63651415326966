<div udInput class="range-picker-container">
    <app-date-picker [date]="rangeStart"
                     [dateFormat]="rangeStartFormat"
                     (onDateSelected)="onStartDateChanged($event)"
    ></app-date-picker>

    <!-- dash symbol is mid-length. neither hyphen, nor dash/mdash   -->
    <span class="separator">–</span>

    <app-date-picker [date]="rangeEnd" [dateFormat]="rangeEndFormat"
                     (onDateSelected)="onEndDateChanged($event)"
    ></app-date-picker>

    <div class="calendar-icon">
        <app-icon-calendar height="24" width="24"></app-icon-calendar>
    </div>
</div>
