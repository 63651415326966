import { Injectable } from '@angular/core'

import { storageAvailable } from '@undock/core/utils/storage.util'
import { MemoryStorage } from '@undock/core/models/storage/memory.storage'
import { LocalStorage } from '@undock/core/contracts/storage/local-storage.interface'
import { AbstractStorage } from '@undock/core/contracts/storage/abstract-storage.interface'


@Injectable()
export class BrowserLocalStorage implements LocalStorage {
    /**
     * Contains available storage implementation
     *
     * @param {AbstractStorage}
     */
    private readonly storage: AbstractStorage

    constructor() {
        if (storageAvailable('localStorage')) {
            this.storage = window.localStorage
        } else {
            this.storage = new MemoryStorage()
        }
    }

    get length(): number {
        return this.storage.length
    }

    clear(): void {
        this.storage.clear()
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key)
    }

    key(index: number): string | null {
        return this.storage.key(index)
    }

    removeItem(key: string): void {
        this.storage.removeItem(key)
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value)
    }
}
