import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core'

import {
    ValueSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'

import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import { isTopicCompleteAnimation } from '@undock/dock/meet/ui/components/topics-list/view/topic-view.component'


@Component({
    selector: 'app-meet-topic-form',
    templateUrl: 'topic-form.component.html',
    styleUrls: ['topic-form.component.scss'],
    animations: [ isTopicCompleteAnimation ],
})
export class TopicFormComponent {

    public readonly TooltipPosition = TooltipPosition

    @Input() showCheck: boolean = false
    @Input() isConfMode: boolean = false
    @Input() placeholder: string = 'Add Topics'
    @Output() onTopicAdded = new EventEmitter<{ text: string, status?: boolean }>()

    @ViewChild('topicTextInput')
    protected topicTextInput: ElementRef<HTMLInputElement>

    @CompleteOnDestroy()
    public readonly isCompletedStream = new ValueSubject(false)

    /**
     * Used for ngModel binding
     */
    public topicText: string = ''

    public onItemInputKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault()

            if (this.topicText.length > 0) {
                this.onTopicAdded.emit({
                    text: this.topicText.trim(),
                    status: this.isCompletedStream.getValue()
                })
            }

            this.topicText = ''
            this.isCompletedStream.next(false)
        }
    }

    public toggleCompleteStatus() {
        this.isCompletedStream.next(!this.isCompletedStream.getValue())
    }
}
