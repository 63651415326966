<app-time-prompt-limit-counter></app-time-prompt-limit-counter>

<div class="time-search">

    <app-wand-icon></app-wand-icon>

    <ng-container *ngIf="{
        criteria: searchState.searchCriteriaStream | stream,
        isVoiceRecordingInProgress: isPromptRecordingInProgressStream | stream
    } as search">
        <input #searchInput class="search-field"
               [class.field-filled]="searchInput.value?.length > 0"
               [class.__disabled]="viewModel.state.isLoading$ | stream"
               type="text"
               name="searchText"
               placeholder="{{placeholder}}"
               autocomplete="off"
               appInputAutoresize
               [value]="search.criteria"
               (click)="searchStateModel.setSearchInputFocusType('focus'); $event.stopPropagation();"
               (focus)="searchStateModel.setSearchInputFocusType('focus')"
               (blur)="searchStateModel.setSearchInputFocusType('blur')"
               (keydown.enter)="submitPrompt(); $event.preventDefault()"
        />

        <app-contacts-mentions #mentions
            [textInputElement]="searchInput"
            [openDirection]="autocompleteOpenDirection"
            [closeMenuOnBlur]="false"
            (selectedProfilesChange)="viewModel.setParticipantProfiles($event)"
            (selectedGroupsChange)="viewModel.setParticipantGroups($event)"
        ></app-contacts-mentions>

        <button class="arrow-button" mat-button (click)="submitPrompt()"
                [class.field-filled]="searchInput?.value.length > 0">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95776 8.81537C8.17647 8.5865 8.51533 8.51959 8.80461 8.64816L23.8046 15.3148C24.0755 15.4352 24.25 15.7038 24.25 16.0002C24.25 16.2966 24.0755 16.5652 23.8046 16.6855L8.80461 23.3522C8.51533 23.4808 8.17647 23.4139 7.95776 23.185C7.73906 22.9561 7.68761 22.6146 7.82918 22.3314L10.9948 16.0002L7.82918 9.66893C7.68761 9.38579 7.73906 9.04424 7.95776 8.81537ZM12.2969 16.7502L10.1057 21.1325L21.6533 16.0002L10.1057 10.8679L12.2969 15.2502H16C16.4142 15.2502 16.75 15.586 16.75 16.0002C16.75 16.4144 16.4142 16.7502 16 16.7502H12.2969Z" fill="#121114"/>
            </svg>
        </button>

        <ng-container *ngIf="allowVoiceActivation">
            <ng-container *ngIf="!search.isVoiceRecordingInProgress; else recordingAnimationTmpl">
                <svg (click)="recordVoicePrompt()" class="__record-icon" [class.field-filled]="searchInput?.value.length > 0"
                     width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C9.79086 1 8 2.79086 8 5V12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12V5C16 2.79086 14.2091 1 12 1ZM10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5V12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12V5ZM6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 14.7997 5.13445 16.8339 6.74176 18.149C8.0169 19.1922 9.55774 19.759 11 19.9374V22C11 22.5523 11.4477 23 12 23C12.5523 23 13 22.5523 13 22V19.9374C14.4423 19.759 15.9831 19.1922 17.2582 18.149C18.8656 16.8339 20 14.7997 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 14.2003 17.1344 15.6661 15.9918 16.601C14.8179 17.5615 13.2999 18 12 18C10.7001 18 9.18213 17.5615 8.00824 16.601C6.86555 15.6661 6 14.2003 6 12Z" fill="#807A8E"/>
                </svg>
            </ng-container>

            <ng-template #recordingAnimationTmpl>
                <app-recording-audio
                    [width]="'30px'" [height]="'30px'"
                ></app-recording-audio>
            </ng-template>
        </ng-container>
    </ng-container>
</div>


