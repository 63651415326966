import {
    CreatedDateField,
    Entity,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import { FireStorageFile } from '../contracts'


export const FILE_COLLECTION_NAME = 'file'

@Entity({
    collection: FILE_COLLECTION_NAME,
})
export class StorageFile implements FireStorageFile {
    @ID() id: string

    @Field() name: string
    @Field() size: number

    @Field() path: string
    @Field() bucket: string
    @Field() contentType: string

    @Field() tags: string[] = []

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date
}
