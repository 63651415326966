import { Injectable } from '@angular/core'

import {
    AclManager,
    DockAccessPolicy,
} from '@undock/acl'
import { Config } from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import { Dock } from '@undock/dock/meet/models/dock.model'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'


@Injectable()
export class DraftDockFacade extends DockFacade {

    public constructor(
        api: Api,
        config: Config,
        aclManager: AclManager,
        currentUser: CurrentUser,
        dockAccessPolicy: DockAccessPolicy,
        participantsManager: DockParticipantsManager,
    ) {
        super(
            api,
            config,
            aclManager,
            currentUser,
            dockAccessPolicy,
            participantsManager,
        )
    }

    /**
     * Will create new Draft if `draftId` is not provided
     */
    public async initializeWithDockId(draftId?: string, options?: Partial<Dock>): Promise<Dock> {
        if (!draftId) {
            try {
                draftId = await this.api.meet.dock.createDraft(options)

            } catch (error) {
                /**
                 * @TODO: Set page state to ERROR
                 */
                console.error(`Cannot create new Draft meeting`, error)
            }
        }

        return super.initializeWithDockId(draftId)
    }

    public async createDraftMeeting(data = {}): Promise<string> {
        return this.api.meet.dock.createDraft(data)
    }
}
