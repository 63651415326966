import {
    SwUpdate,
    SwPush,
} from '@angular/service-worker'
import { Router } from '@angular/router'
import { Component } from '@angular/core'


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    public constructor(
        private readonly router: Router,
        private readonly swPush: SwPush,
        private readonly swUpdate: SwUpdate,
    ) {}

    public async ngOnInit() {
        if (this.swUpdate.isEnabled) {
            /**
             * Checking for an update to reload the app
             */
            this.swUpdate.versionUpdates.subscribe((event) => {
                switch (event.type) {
                    case 'VERSION_DETECTED':
                        console.log(`Downloading new app version: ${event.version.hash}`)
                        break
                    case 'VERSION_READY':
                        console.log(`Current app version: ${event.currentVersion.hash}`)
                        console.log(`New app version ready for use: ${event.latestVersion.hash}`)

                        const url = this.router.url
                        if (!url.match(/meet\/.*\/room/i)) {
                            window.location.reload()
                        }

                        break
                    case 'VERSION_INSTALLATION_FAILED':
                        console.error(`Failed to install app version '${event.version.hash}': ${event.error}`)
                        break
                }
            })
        }
    }
}
