<ng-container *ngIf="!(isDataLoadingStream | stream) else eventDataLoadingTmpl">
    <div class="scrollable">
        <app-meet-event-form></app-meet-event-form>
    </div>

    <div class="processing-overlay" *ngIf="isSaveProcessingStream | stream">
        <ng-container [ngTemplateOutlet]="eventDataLoadingTmpl"></ng-container>
    </div>
</ng-container>

<ng-template #eventDataLoadingTmpl>
    <app-loading-overlay></app-loading-overlay>
</ng-template>



