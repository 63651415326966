import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'

@Component({
    selector: 'app-icon-command-created',
    templateUrl: 'command-created.component.html',
    styleUrls: ['command-created.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandCreatedIconComponent {}
