import { Injectable } from '@angular/core';
import { EmitOnDestroy, DestroyEvent } from '@typeheim/fire-rx';

import {
    TimezoneData,
} from '@undock/time/availability'

import { timeZoneToAbbreviationMap, supportedTimeZones } from '@undock/core/utils/timezone-data-model';

import moment, { default as m } from 'moment'
import { default as timezone } from 'moment-timezone';


@Injectable({
    providedIn: 'root',
})
export class TimeZoneHelper {

    private readonly _timeZoneSelections: TimezoneData[] = []

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public get timeZoneSelections(): TimezoneData[] { 
        return this._timeZoneSelections
    }

    constructor() {
        /**
         * Initialize the readonly time zone maps
         */
        let allTimeZoneNames = timezone.tz.names()
        let timeZonesForOffsetsMap = new Map<number, Set<TimezoneData>>()

        for (let name of allTimeZoneNames) {

            let offset = moment.tz.zone(name).utcOffset(Date.now());

            if (!timeZonesForOffsetsMap.has(offset)) {
                timeZonesForOffsetsMap.set(offset, new Set<TimezoneData>())
            }

            if (supportedTimeZones.has(name)) {
                let data = supportedTimeZones.get(name)
                timeZonesForOffsetsMap.get(offset).add({
                    zone: name,
                    label: this.getTimeZoneAwareTimeLabel(moment(), name, 'z'),
                    suggestedCity: data.cities[0] || '',
                    cities: data.cities,
                    offset: -(offset/60),
                    offsetLabel: this.generateOffsetLabel(-(offset/60)),
                    generalName: data.generalName
                })
            }
        }

        /**
         * Initialize the readonly time zone selections array
         */
        let selections: TimezoneData[] = []
        let offsetArray = Array.from(timeZonesForOffsetsMap.entries())
        for (let array of offsetArray) {
            let tzDataArray = Array.from(array[1])
            tzDataArray.forEach(data => {
                selections.push(data)
            })
        }
        selections.sort((a, b) => {
            return a.offset-b.offset ||
                ((a.generalName > b.generalName ? 1 : ((b.generalName > a.generalName) ? -1 : 0)))
        });

        this._timeZoneSelections = selections
    }

    public getDataForTimezone(zone: string): TimezoneData {
        return this.timeZoneSelections.find(data => data.zone === zone)
    }

    public getTimeZoneAwareTimeLabel(date: moment.MomentInput, targetTimeZoneName: string, format: string = 'h:mm A'): string {
        if (date) {
            let convertedDate = timezone(date).tz(targetTimeZoneName, true);
            if (convertedDate) {
                if (format === 'z') {
                    let label = convertedDate.format(format);
                    if (!isNaN(parseInt(label))) {
                        return timeZoneToAbbreviationMap[targetTimeZoneName] || ``;
                    }
                    else {
                        return label;
                    }
                }
                else {
                    return convertedDate.format(format);
                }
            }
            return null; 
        }
        return null;
    }

    protected generateOffsetLabel(offset: number): string {
        let absOffset = Math.abs(offset)
        return `UTC${offset < 0 ? '-' : '+'}${absOffset < 10 ? '0' : ''}${Math.floor(absOffset)}:${absOffset % 1 !== 0 ? (absOffset % 1)*60 : '00'}`
    }

    public static toUTCDate(date: moment.MomentInput): Date {
        let d = moment(date)
        return new Date(Date.UTC(d.year(), d.month(), d.date(), d.hour(), d.minute(), 0, 0))
    }

    public static fromUTCDate(date: Date, timeZone?: string): Date {
        if (!timeZone) {
            timeZone = timezone.tz.guess(true)
        }
        return timezone.utc(date).tz(timeZone, true).toDate()
    }

}
