import {
    AfterViewInit,
    Directive,
    DoCheck,
    ElementRef,
    HostListener,
    Input,
} from '@angular/core'


@Directive({
    selector: '[elementHeightClass]',
})
export class ElementHeightClassDirective implements AfterViewInit {

    @Input()
    public cutoff: number

    @Input('underHeightClass')
    public underTheCutoffClass: string

    public constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
    ) {}

    public ngAfterViewInit() {
        if (this.elementRef.nativeElement.clientHeight && typeof this.elementRef.nativeElement.classList?.add === 'function') {
            if (this.cutoff && this.underTheCutoffClass) {
                if (this.elementRef.nativeElement.clientHeight < this.cutoff) {
                    setTimeout(() => this.elementRef.nativeElement.classList.add(this.underTheCutoffClass))
                }
            } else {
                setTimeout(() => this.elementRef.nativeElement.classList.add(`ud-height-${this.elementRef.nativeElement.clientHeight}`))
            }
        }
    }
}
