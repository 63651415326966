import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-checked-hollow-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 8C2.75 5.10051 5.10051 2.75 8 2.75C10.8995 2.75 13.25 5.10051 13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.10051 13.25 2.75 10.8995 2.75 8ZM8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25ZM11.197 6.53033C11.4899 6.23744 11.4899 5.76256 11.197 5.46967C10.9041 5.17678 10.4292 5.17678 10.1363 5.46967L7.33333 8.27267L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L6.803 9.86366C7.0959 10.1566 7.57077 10.1566 7.86366 9.86366L11.197 6.53033Z" 
                  [attr.fill]="color"/>
        </svg>

    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class CheckedHollowIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 16
    @Input() height: string | number  = 16
    @Input() color: string = '#121114'
}
