import { Injectable } from '@angular/core'
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar'

export enum SnackbarState {
    Primary = 'ud-snackbar--primary',
    Secondary = 'ud-snackbar--secondary',
    Info = 'ud-snackbar--info',
    Success = 'ud-snackbar--success',
    Error = 'ud-snackbar--danger',
    Warning = 'ud-snackbar--warning',
}

export enum SnackbarPosition {
    BottomLeft = 1,
    BottomCenter = 2,
    BottomRight = 3,
    TopLeft = 4,
    TopCenter = 5,
    TopRight = 6,
}

@Injectable()
export class SnackbarManager {
    constructor(private snackbar: MatSnackBar) {}

    public showText(text: string, state = SnackbarState.Primary, position?: SnackbarPosition, action?: string): MatSnackBarRef<any> {
        const config = { duration: 3000, panelClass: [state] }

        if (position) {
            switch (position) {
                case SnackbarPosition.BottomLeft:
                    config['horizontalPosition'] = 'start'
                    config['verticalPosition'] = 'bottom'
                    break
                case SnackbarPosition.BottomCenter:
                    config['horizontalPosition'] = 'center'
                    config['verticalPosition'] = 'bottom'
                    break
                case SnackbarPosition.BottomRight:
                    config['horizontalPosition'] = 'end'
                    config['verticalPosition'] = 'bottom'
                    break
                case SnackbarPosition.TopLeft:
                    config['horizontalPosition'] = 'start'
                    config['verticalPosition'] = 'top'
                    break
                case SnackbarPosition.TopCenter:
                    config['horizontalPosition'] = 'center'
                    config['verticalPosition'] = 'top'
                    break
                case SnackbarPosition.TopRight:
                    config['horizontalPosition'] = 'end'
                    config['verticalPosition'] = 'top'
                    break
                default:
                    break
            }
        }

        return this.snackbar.open(text, action ?? null, config)
    }

    public info(text, position?: SnackbarPosition, action?: string): MatSnackBarRef<any> {
        return this.showText(text, SnackbarState.Info, position, action)
    }

    public success(text, position?: SnackbarPosition, action?: string): MatSnackBarRef<any> {
        return this.showText(text, SnackbarState.Success, position, action)
    }

    public error(text, position?: SnackbarPosition, action?: string): MatSnackBarRef<any> {
        return this.showText(text, SnackbarState.Error, position, action)
    }

    public warning(text, position?: SnackbarPosition, action?: string): MatSnackBarRef<any> {
        return this.showText(text, SnackbarState.Error, position, action)
    }
}
