<ng-template #profile let-participant="participant">
    <ng-container *ngIf="toParticipantRecord(participant) as participant">
        <ng-container *ngIf="participant.emailAddress || participant.email">
            <ng-container
                    *ngIf="participant.isUndockUser; else oldProfileApproachBlock"
            >
                <img class="participants-list__participant-icon"
                     default="/assets/img/placeholders/user.png"
                     [src]="participant.userData.imageUrl | imgThumbnail"
                     [alt]="participant.userData.email"
                     [matTooltip]="participant.userData.displayName || participant.userData.email"
                     [matTooltipPosition]="TooltipPosition.BOTTOM"
                />
            </ng-container>

            <ng-template #oldProfileApproachBlock>
                <ng-container
                        *ngIf="profilesProvider.getProfileByEmail(participant.email || participant.emailAddress) | stream as profile; else profileDoesNotExistsTmpl"
                >
                    <img class="participants-list__participant-icon"
                         default="/assets/img/placeholders/user.png"
                         [src]="profile.imageUrl | imgThumbnail"
                         [alt]="profile.email"
                         [matTooltip]="profile.displayName || participant?.fullname || participant?.firstname || participant.emailAddress"
                         [matTooltipPosition]="TooltipPosition.BOTTOM"
                    />
                </ng-container>
            </ng-template>

            <ng-template #profileDoesNotExistsTmpl>
                <img class="participants-list__participant-icon guest"
                     src="/assets/img/placeholders/user.png"
                     [alt]="participant.emailAddress"
                     [matTooltip]="participant?.fullname || participant?.firstname || participant.emailAddress || participant.email"
                     [matTooltipPosition]="TooltipPosition.BOTTOM"
                />
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #visibleParticipants>
    <ng-container *ngFor="let participant of participants; let i = index">
        <ng-container *ngIf="i < visibleCount" >
        <div class="out-item">
            <div class="item participant-view">
                <ng-container [ngTemplateOutlet]="profile"
                              [ngTemplateOutletContext]="{participant: participant}"></ng-container>
            </div>
        </div>
        </ng-container>
    </ng-container>

    <div class="out-item" *ngIf="visibleCount < participants?.length">
        <div class="item participant-counter">
            <span class="hidden-participants-count">+{{ (participants?.length - visibleCount) }}</span>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="participants">

    <ng-container *ngIf="popupOnHover">
        <div class="wrapper">
            <div class="inner">
                <div class="spacer" [style.max-width]="maxSpacerWidth"></div>
                <div
                        class="icons-block"
                        [style.max-width.px]="maxWidth"
                        #ParticipantsTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="ParticipantsView"
                        (mouseover)="ParticipantsTrigger.openMenu()"
                >
                    <ng-container [ngTemplateOutlet]="visibleParticipants"></ng-container>
                    <mat-menu
                            class="popup-box"
                            #ParticipantsView="matMenu"
                            [overlapTrigger]="false"
                    >
                        <app-participants-popup
                                [participants]="participants"
                                (onMouseLeave)="ParticipantsTrigger.closeMenu()"
                        ></app-participants-popup>
                    </mat-menu>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!popupOnHover">
        <div class="wrapper">
            <div class="inner">
                <div class="spacer" [style.max-width]="maxSpacerWidth"></div>
                <div class="icons-block" [style.max-width.px]="maxWidth">
                    <ng-container [ngTemplateOutlet]="visibleParticipants"></ng-container>
                </div>
            </div>
        </div>
    </ng-container>

</ng-container>
