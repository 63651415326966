import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'

export const findBestSlotOnDay = (set: AvailabilitySet): AvailabilitySlot => {

    if (set?.slots?.length) {
        let allSlots = [...set.slots]
        if (allSlots?.length) {
            sortSlotsByScore(allSlots as AvailabilitySlot[])
            return allSlots[0] as AvailabilitySlot
        }
    }
    return null
}

export const sortSlotsByScore = (slots: AvailabilitySlot[]) => {
    slots.sort((e1: AvailabilitySlot, e2: AvailabilitySlot) => {
        return e2.score < e1.score ? -1 : e2.score > e1.score ? 1 : 0
    })
}
