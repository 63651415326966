import { ApiRoute } from '@undock/api/services/api.route'


export class SearchRoute extends ApiRoute {
    public init() {
        this.route = 'contacts/search'
    }

    /**
     * Return an array of firebaseIds of users
     */
    public getIdsForAutocomplete(term: string): Promise<string[]> {
        return this.post<string[]>()
                   .withData({ term })
                   .endpoint(`autocomplete`)
    }
}
