import { Injectable } from '@angular/core'

import { create, CyclePlugin } from "rxjs-spy"
import { tag } from 'rxjs-spy/cjs/operators'
import { Spy } from 'rxjs-spy/cjs/spy-interface'


import { Observable } from 'rxjs'

import { Config } from '@undock/core'
import { RxJSDebugLevel } from '@undock/core/contracts/rxjs-debug-level'
import { environment } from '@web/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class RxJSDebugger {

    private spy: Spy

    constructor(
        private config: Config
    ) {
        if (this.config.rxjsDebugLevel > RxJSDebugLevel.OFF) {
            this.spy = create()

            /**
             * https://github.com/cartant/rxjs-spy/issues/41
             */
            this.spy.unplug(this.spy.find(CyclePlugin))

            this.spy.log()
        }
    }
}

export const debug = (level: RxJSDebugLevel, tagName: string, category?: string) => (source: Observable<any>) => {
    if (environment.rxjsDebugLevel >= level) {
        return source.pipe(
            tag(`[${RxJSDebugLevel[level]}]::` + (category ? `[${category}]::` : '') + tagName)
        )
    }
    return source
}
