import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-event-preferences-icon',
    templateUrl: 'event-preferences-icon.component.html',
    styleUrls: ['event-preferences-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPreferencesIconComponent extends IconTemplateComponent {}
