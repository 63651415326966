<div id="ud-loading-small" style="height: 100%; width: 100%;">
    <div class="voice-recognition-container"
        [style.background-color]="backgroundColor"
    >
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
    </div>
</div>
