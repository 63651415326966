import { ApiRoute } from '@undock/api/services/api.route'


export class SpeechToTextRoute extends ApiRoute {

    public init() {
        this.route = 'nlp/speech-to-text'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public async transcribeAudio(data: any): Promise<any> {
        return this.post<any>()
                   .withHeaders({
                       'Content-Type': 'multipart/form-data'
                   })
                   .withData(data)
                   .endpoint(`transcribe`)
    }
}

