import { inject } from '@angular/core'
import { EntityType } from '@typeheim/orm-on-fire/src/Contracts'
import { Collection } from '@typeheim/orm-on-fire/src/Model/Collection'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'

export const injectCollection = <E>(entity: EntityType<E>): Collection<E> => {
    const ormOnFireContext = inject(OrmOnFireContext)
    return ormOnFireContext.internalCollectionsMap.of(entity)
}

export const injectCollectionGroup = <E>(entity: EntityType<E>): Collection<E> => {
    const ormOnFireContext = inject(OrmOnFireContext)
    return ormOnFireContext.internalCollectionsMap.groupOf(entity)
}
