import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { InputDirective } from '@undock/common/undock-theme/directives/input.directive'
import { ButtonDirective } from '@undock/common/undock-theme/directives/button.directive'
import { FlatButtonDirective } from '@undock/common/undock-theme/directives/flat-button.directive'
import { SelectDirective } from '@undock/common/undock-theme/directives/select.directive'
import { InputBlockDirective } from '@undock/common/undock-theme/directives/input-block.directive'


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        InputDirective,
        InputBlockDirective,
        ButtonDirective,
        FlatButtonDirective,
        SelectDirective,
    ],
    exports: [
        InputDirective,
        InputBlockDirective,
        ButtonDirective,
        FlatButtonDirective,
        SelectDirective,
    ],
    providers: [],
})
export class UndockThemeModule {}
