import { Injectable } from '@angular/core'
import {
    State,
    StateModel,
    StreamStore,
} from '@undock/core/states'
import { Api } from '@undock/api'
import {
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Plan } from '@undock/time/plans/contracts/plan.interface'
import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    map,
} from 'rxjs/operators'
import {
    isEmptyString,
} from '@undock/core'
import { cast } from '@undock/core/utils/cast'
import { CurrentUser } from '@undock/session'

const SUGGESTED_ACTIVE_COMMANDS_COUNT = 2
const SUGGESTED_PUBLIC_COMMANDS_COUNT = 4
const SUGGESTED_COMMANDS_COUNT = 25


@Injectable({
    providedIn: 'root',
})
export class OwnPlansStorage extends StateModel<PlansListStore> {

    protected store = new PlansListStore()

    constructor(
        private api: Api,
        private currentUser: CurrentUser
    ) {
        super()
        this.currentUser.isLoggedInStream.then(isLoggedIn => {
            if (isLoggedIn) {
                this.refreshState().catch(error => {
                    console.error(`Cannot refresh plans list`, error)
                })
            } else {
                this.refreshPublicState().catch(error => {
                    console.error(`Cannot refresh public plans list`, error)
                })
            }
        })
    }

    public async refreshState() {
        this.store.isLoadingStream.next(true)
        let [ownPlans, publicPlans] = await Promise.all([
            this.api.plans.own.listAll(),
            this.api.plans.public.listAll(),
        ])

        /**
         * Rehydrate the date objects if necessary
         */
        ownPlans.forEach(c => {
            if (typeof c.lastExecuted === 'string') {
                c.lastExecuted = new Date(c.lastExecuted)
            }
            if (typeof c.updatedAt === 'string') {
                c.updatedAt = new Date(c.updatedAt)
            }
        })
        publicPlans.forEach(c => {
            if (typeof c.lastExecuted === 'string') {
                c.lastExecuted = new Date(c.lastExecuted)
            }
            if (typeof c.updatedAt === 'string') {
                c.updatedAt = new Date(c.updatedAt)
            }
        })

        this.store.personalPlansStream.next(ownPlans.map(c => cast(c, Plan)))
        this.store.publicPlansStream.next(publicPlans.map(c => cast(c, Plan)))

        this.store.isLoadingStream.next(false)
    }

    public async refreshPublicState() {
        this.store.isLoadingStream.next(true)
        let publicPlans = await this.api.plans.public.listAll()

        /**
         * Rehydrate the date objects if necessary
         */
        publicPlans.forEach(c => {
            if (typeof c.lastExecuted === 'string') {
                c.lastExecuted = new Date(c.lastExecuted)
            }
            if (typeof c.updatedAt === 'string') {
                c.updatedAt = new Date(c.updatedAt)
            }
        })

        this.store.publicPlansStream.next(publicPlans.map(c => cast(c, Plan)))

        this.store.isLoadingStream.next(false)
    }
}

export class PlansListStore extends StreamStore {
    public isLoadingStream = new ValueSubject<boolean>(true)

    /**
     * Personal commands properties
     */
    public personalPlansStream: ValueSubject<Plan[]> = new ValueSubject<Plan[]>([])

    /**
     * Public commands properties
     */
    public publicPlansStream: ValueSubject<Plan[]> = new ValueSubject<Plan[]>([])

    public visiblePublicPlansStream: Observable<Plan[]> = this.publicPlansStream.pipe(
        map(publicCommands => publicCommands.filter(
            c => !c.isDisabled
        )),
    )

    /**
     * All commands properties
     */
    public allPlansStream: Observable<Plan[]> = combineLatest([
        this.personalPlansStream,
        this.visiblePublicPlansStream
    ]).pipe(
        map(([personalCommands, publicCommands]) => [...personalCommands, ...publicCommands])
    )

    public suggestedPlansStream: Observable<Plan[]> = combineLatest([
        this.personalPlansStream,
        this.visiblePublicPlansStream
    ]).pipe(
        map(([personalCommands, visiblePublicCommands]) => {

            let commands = [...personalCommands, ...visiblePublicCommands]

            /**
             * TODO: replace this with a smart algorithm in the future
             */
            return this.groupAndFilterSuggestedPlans(commands)
        })
    )

    public activePlansStream: Observable<Plan[]> = this.personalPlansStream.pipe(
        map(personalCommands => personalCommands?.filter(c => c.isActive) ?? [])
    )

    protected groupAndFilterSuggestedPlans(commands: Plan[]): Plan[] {
        if (commands?.length) {
            let filteredCommands = commands.filter(c => !isEmptyString(c.title))

            let activeCommands = this.sortPlansByRecentActivity(
                filteredCommands.filter(c => c.isActive)
            ).slice(0,SUGGESTED_ACTIVE_COMMANDS_COUNT)

            let publicCommands = this.sortPlansByRecentActivity(
                filteredCommands.filter(c => c.isPublic)
            ).slice(0,SUGGESTED_PUBLIC_COMMANDS_COUNT)

            let personalCommands = this.sortPlansByRecentActivity(
                filteredCommands.filter(c => !c.isActive && !c.isPublic && !c.isDisabled)
            ).slice(0,SUGGESTED_COMMANDS_COUNT)

            return [...activeCommands, ...publicCommands, ...personalCommands]
        }
        return []
    }

    protected sortPlansByRecentActivity(commands: Plan[]): Plan[] {
        return [...commands].sort(
            (a, b) =>
                (b.lastExecuted ? b.lastExecuted.getTime() : b.updatedAt.getTime()) -
                (a.lastExecuted ? a.lastExecuted.getTime() : a.updatedAt.getTime())
        )
    }
}

export type PlansListState = State<PlansListStore>
