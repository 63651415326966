import { ApiRoute } from '@undock/api/services/api.route'

export class ResourceRoute extends ApiRoute {

    public init() {
        this.route = 'acl'
    }

    public async validateSharedAccessSecret(
        resourceName: string, secretValue: string,
    ): Promise<boolean> {
        return this.get<boolean>()
                   .endpoint(`${resourceName}/validate-secret/${secretValue}`)
    }

    public async requestAccessPermissionGrants(
        resourceName: string, secretValue: string, permissions: string[] = ['view'],
    ): Promise<boolean> {
        return this.post<boolean>()
                   .withData({
                       secret: secretValue,
                       /**
                        * Permissions are not used yet
                        */
                       permissions: permissions,
                   })
                   .endpoint(`${resourceName}/request-permission-grant`)
    }
}
