<ng-container *ngIf="{
    options: allDurationOptionsStream | stream,
    selected: state.selectedMeetingDurationStream | stream
} as values">
    <mat-select class="customArrow" udInput
                [value]="values.selected"
                (selectionChange)="onMeetingDurationChanged($event.value)"
    >
        <mat-option
                *ngFor="let option of values.options" [value]="option.value - option.gap"
        >{{ option.value - option.gap | meetingDuration }}</mat-option>
    </mat-select>
</ng-container>




