import {
    Injectable,
} from '@angular/core'
import {
    Router,
    UrlTree,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router'

import {
    AuthManager,
    AuthSession,
} from '@undock/auth'


@Injectable()
export class InstallIntegrationGuard implements CanActivate {

    public constructor(
        private router: Router,
        private authManager: AuthManager,
        private authSession: AuthSession,
    ) {}

    public async canActivate(
        next: ActivatedRouteSnapshot, state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
        if (await this.authManager.isRegularUserStream) {
            let integrationInstallPath = await this.getIntegrationInstallPath(next)
            if (integrationInstallPath) {
                if (await this.authSession.isNewUserStream) {
                    return this.router.createUrlTree([integrationInstallPath], {
                        queryParams: {
                            new_user: true
                        }
                    })
                } else {
                    return this.router.createUrlTree([integrationInstallPath])
                }
            }
        }
        return true
    }

    protected async getIntegrationInstallPath(routeSnapshot: ActivatedRouteSnapshot): Promise<string> {
        const integrationName = routeSnapshot.queryParamMap.get('install_integration')
        if (!integrationName) {
            return null
        }
        switch(integrationName) {
            case 'zoom':
                return 'integrations/zoom'
        }
    }
}