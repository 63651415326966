import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-items-list-icon',
    template: `
        <svg style="margin-right: 8px;" [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 4.16667C3.25 3.66041 3.66041 3.25 4.16667 3.25C4.67293 3.25 5.08333 3.66041 5.08333 4.16667C5.08333 4.67293 4.67293 5.08333 4.16667 5.08333C3.66041 5.08333 3.25 4.67293 3.25 4.16667ZM4.16667 1.75C2.83198 1.75 1.75 2.83198 1.75 4.16667C1.75 5.50136 2.83198 6.58333 4.16667 6.58333C5.50136 6.58333 6.58333 5.50135 6.58333 4.16667C6.58333 2.83198 5.50135 1.75 4.16667 1.75ZM8.41667 4.16667C8.41667 3.75245 8.75245 3.41667 9.16667 3.41667L17.5 3.41666C17.9142 3.41666 18.25 3.75245 18.25 4.16666C18.25 4.58088 17.9142 4.91666 17.5 4.91666L9.16667 4.91667C8.75245 4.91667 8.41667 4.58088 8.41667 4.16667ZM9.16667 9.25C8.75245 9.25 8.41667 9.58579 8.41667 10C8.41667 10.4142 8.75245 10.75 9.16667 10.75L17.5 10.75C17.9142 10.75 18.25 10.4142 18.25 10C18.25 9.58578 17.9142 9.25 17.5 9.25L9.16667 9.25ZM9.16667 15.0833C8.75246 15.0833 8.41667 15.4191 8.41667 15.8333C8.41667 16.2475 8.75246 16.5833 9.16667 16.5833L17.5 16.5833C17.9142 16.5833 18.25 16.2475 18.25 15.8333C18.25 15.4191 17.9142 15.0833 17.5 15.0833L9.16667 15.0833ZM4.16667 9.08333C3.66041 9.08333 3.25 9.49374 3.25 10C3.25 10.5063 3.66041 10.9167 4.16667 10.9167C4.67293 10.9167 5.08333 10.5063 5.08333 10C5.08333 9.49374 4.67293 9.08333 4.16667 9.08333ZM1.75 10C1.75 8.66531 2.83198 7.58333 4.16667 7.58333C5.50136 7.58333 6.58333 8.66531 6.58333 10C6.58333 11.3347 5.50136 12.4167 4.16667 12.4167C2.83198 12.4167 1.75 11.3347 1.75 10ZM3.25 15.8333C3.25 15.3271 3.66041 14.9167 4.16667 14.9167C4.67293 14.9167 5.08334 15.3271 5.08334 15.8333C5.08334 16.3396 4.67293 16.75 4.16667 16.75C3.66041 16.75 3.25 16.3396 3.25 15.8333ZM4.16667 13.4167C2.83198 13.4167 1.75 14.4986 1.75 15.8333C1.75 17.168 2.83198 18.25 4.16667 18.25C5.50136 18.25 6.58334 17.168 6.58333 15.8333C6.58333 14.4986 5.50136 13.4167 4.16667 13.4167Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
    :host {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    `]
})
export class ItemsListComponent extends IconTemplateComponent {
    @Input() width: string | number = 20
    @Input() height: string | number  = 20
    @Input() color: string = '#2B2733'
}
