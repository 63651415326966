import { ApiRoute } from '@undock/api/services/api.route'

export enum Sort {
    ask = 1,
    desk = -1
}

export class SearchRoute extends ApiRoute {
    public init() {
        this.route = 'search'
    }

    public async search(query: string, sort = Sort.ask): Promise<any> {
        return this.post()
                   .withData({ query, sort }).endpoint('global')
    }
}
