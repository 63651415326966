import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-large-calendar-no-time',
    templateUrl: 'large-calendar-no-time.component.html',
    styleUrls: ['large-calendar-no-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarNoTimeIconComponent extends IconTemplateComponent {}
