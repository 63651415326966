import { Injectable } from '@angular/core'

import moment, { Moment } from 'moment'
import { default as timezone } from 'moment-timezone'
import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'

const BASE_SLOT = {
    type: 'slot' as any,
    free: true,
    isSoftBooked: false,
    overbookingAllowed: false,
    participants: [],
    preferred: false,
    score: 0,
    isOutOfHours: true,
} as AvailabilitySlot

@Injectable({
    providedIn: 'root',
})
export class GeneratePriorityModeSlotsFilter {
    public apply(
        availabilityDay: AvailabilitySet,
        unavailableSlots: AvailabilitySlot[],
        timeZone: string
    ): AvailabilitySlot[] {
        if (!availabilityDay || !availabilityDay.slots || !availabilityDay.day) {
            return availabilityDay.slots
        }

        let originalSlots = availabilityDay.slots
        let outOfHoursSlots: AvailabilitySlot[] = []

        let now = moment()
        let baseTimestamp = availabilityDay.day.toISOString()

        /**
         * For every out of hours and non-available slot (according to settings), generate a default timeslot
         */
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 30) {
                let datetime = timezone.tz(baseTimestamp, timeZone).hour(i).minute(j).second(0).millisecond(0)
                if (this.isValidOutOfHoursDateTime(datetime, now, originalSlots)) {
                    outOfHoursSlots.push(this.generateOutOfHourSlot(datetime, i, j))
                }
            }
        }
        let allSlots = [...originalSlots, ...outOfHoursSlots]
        this.sortAvailabilitySlotsByDate(allSlots)
        return allSlots as AvailabilitySlot[]
    }

    protected isValidOutOfHoursDateTime(datetime: Moment, now: Moment, originalSlots: AvailabilitySlot[]): boolean {
        return datetime.isSameOrAfter(now, 'minute') &&
            !originalSlots.some(s => s.hour === datetime.hour() && s.minute === datetime.minute())

    }

    protected generateOutOfHourSlot(datetime: Moment, hour: number, minute: number): AvailabilitySlot {
        return {
            ...BASE_SLOT,
            label: datetime.format('h:mm A'),
            hour: hour,
            minute: minute,
            timeStamp: datetime.toISOString()
        }
    }

    protected sortAvailabilitySlotsByDate (slots: AvailabilitySlot[]) {
        slots.sort((e1: AvailabilitySlot, e2: AvailabilitySlot) => {
            return new Date(e1.timeStamp as any).getTime() - new Date(e2.timeStamp as any).getTime();
        });
    }

}
