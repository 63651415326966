import {
    Component,
    ChangeDetectionStrategy,
    Input,
} from '@angular/core'


@Component({
    selector: 'app-time-available-time-slot-warning-icon',
    templateUrl: 'available-time-slot-warning-icon.component.html',
    styleUrls: ['available-time-slot-warning-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableTimeSlotWarningIconComponent {

    protected readonly defaultWidth = 14
    protected readonly defaultHeight = 14

    @Input() width: number = this.defaultWidth
    @Input() height: number = this.defaultHeight

    @Input() color: string = '#F13693'
    @Input() backgroundColor: string = 'transparent'

    public get viewBox(): string {
        return `0 0 ${this.width} ${this.height}`
    }
}
