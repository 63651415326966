<ng-container [ngSwitch]="state.isDockInitialized | stream | boolean">
    <ng-container *ngSwitchCase="true" [ngSwitch]="state.isEditMode | stream | boolean">
        <ng-container *ngSwitchCase="true">
            <app-dashboard-calendar-event-details-edit
                [isFullSizeMode]="isFullSizeMode"
            ></app-dashboard-calendar-event-details-edit>
        </ng-container>

        <ng-container *ngSwitchCase="false">
            <app-dashboard-calendar-event-details-preview
                [isFullSizeMode]="isFullSizeMode"
            ></app-dashboard-calendar-event-details-preview>
        </ng-container>
    </ng-container>

    <!-- Loader for dock initialization -->
    <ng-container *ngSwitchCase="false">
        <app-loading-overlay></app-loading-overlay>
    </ng-container>
</ng-container>

