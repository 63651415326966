import 'reflect-metadata'


import { Observable } from 'rxjs'
import {
    KeyboardEventHandlerBindings,
    KeyboardEventListenerOptions,
    KeyboardEventUnlistenFunction,
    KeyboardShortcutsOptions,
} from '@undock/hotkeys/contracts/keyboard'
import { InvalidKeyboardShortcutBindingException } from '@undock/hotkeys/exceptions/invalid-keyboard-shortcut-binding.exception'
import { HotkeysProvider } from '@undock/hotkeys/hotkeys.provider'
import { KeyboardEventsListener } from '@undock/hotkeys/services/keyboard-events.listener'

/**
 * Symbols
 */
const ON_INIT_SYMBOL = Symbol()
const ON_DESTROY_SYMBOL = Symbol()
const keyboardShortcutBindingsKey = Symbol('keyboardShortcutBindings')

/**
 * Metadata for cleaning up listeners
 */
const KeyboardShortcutsMetadataMap = new WeakMap<any, KeyboardShortcutsMetadata>()


const defaultOptions: KeyboardEventListenerOptions = {
    priority: 'next',
    terminal: 'match',
    allowInputs: false,
    takeUntil: null,
}

/**
 * Internal interfaces
 */
interface KeyboardShortcutsMetadata {
    unlisten: KeyboardEventUnlistenFunction
}

interface KeyboardShortcutBindingsMetadata {
    functionName: string,
    keyCodes: string[]
}

/**
 * Allows the provided keyCodes to trigger the decorated function.
 * Requires the class to use the @UseKeyboardShortcuts class decorator.
 * @param keyCodes
 * @constructor
 */
export function KeyboardShortcut(keyCodes: string | string[]) {

    if (!Array.isArray(keyCodes)) {
        keyCodes = [keyCodes]
    }

    return (target: Object, key: string | symbol) => {
        let bindings: KeyboardShortcutBindingsMetadata[] = getKeyboardShortcutBindingsMetadata(target.constructor)

        if (!bindings) {
            bindings = [{
                functionName: key as string,
                keyCodes: keyCodes as string[],
            }]
            Reflect.defineMetadata(keyboardShortcutBindingsKey, bindings, target.constructor)
        } else {
            if (!bindings.find(binding => binding.functionName === key)) {
                bindings.push({
                    functionName: key as string,
                    keyCodes: keyCodes as string[],
                })
            } else {
                throw new InvalidKeyboardShortcutBindingException('Cannot include the @KeyboardShortcut decorator twice on the same function. To add multiple keyCodes to the same function, pass them as an array')
            }
        }
    }
}

/**
 * Automatically registers and unregisters all of the keyCodes to the fucntions in this class defined with the @KeyboardShortcut decorator.
 * @param options
 * @constructor
 */
export function UseKeyboardShortcuts(options: KeyboardShortcutsOptions = defaultOptions) {

    return function <T extends { new(...args: any[]): {} }>(constructor: T) {

        /**
         * Set defaults for any missing options
         */
        options = {
            ...defaultOptions,
            ...options,
        }

        /**
         * Extend ngOnInit to register all decorated functions in the class to their keyCodes
         */
        constructor.prototype[ON_INIT_SYMBOL] = constructor.prototype.ngOnInit || (() => {})
        constructor.prototype.ngOnInit = async function() {
            this[ON_INIT_SYMBOL]()
            if (await registerKeyboardShortcuts(this, options)) {
                //console.log(`[${constructor.name}] :: Keyboard shortcuts registered`)
            }
        }

        /**
         * Extend ngOnDestroy to unregister all listeners for this class
         */
        constructor.prototype[ON_DESTROY_SYMBOL] = constructor.prototype.ngOnDestroy || (() => {})

        const newDestructorDescriptor = {
            value: function(...args: any[]) {
                this[ON_DESTROY_SYMBOL] ? this[ON_DESTROY_SYMBOL].apply(this, args) : null

                if (KeyboardShortcutsMetadataMap.has(constructor)) {
                    let metadata = KeyboardShortcutsMetadataMap.get(constructor)
                    if (typeof metadata.unlisten === 'function') {
                        KeyboardShortcutsMetadataMap.delete(constructor)
                        metadata.unlisten()
                        //console.log(`[${constructor.name}] :: Keyboard shortcuts unregistered`)
                    }
                }

                return typeof this[ON_DESTROY_SYMBOL] === 'function' ? this[ON_DESTROY_SYMBOL].apply(this, args) : null
            },
            configurable: true,
            writeable: true,
        }

        // Deleting old destructor and injecting extended one
        delete constructor.prototype['ngOnDestroy']
        Object.defineProperty(constructor.prototype, 'ngOnDestroy', newDestructorDescriptor)
    }
}

function getKeyboardShortcutBindingsMetadata<T>(constructor: T): KeyboardShortcutBindingsMetadata[] {
    return Reflect.getMetadata(keyboardShortcutBindingsKey, constructor)
}

async function registerKeyboardShortcuts<T>(instance: T, options: KeyboardShortcutsOptions): Promise<boolean> {

    let bindingsMetadata: KeyboardShortcutBindingsMetadata[] = getKeyboardShortcutBindingsMetadata(instance.constructor)

    if (bindingsMetadata?.length) {

        let bindings: KeyboardEventHandlerBindings = {}

        /**
         * Build handler bindings object for keyboard listener aggregator
         */
        for (let meta of bindingsMetadata) {
            let functionName = meta.functionName
            if (functionName && typeof instance[functionName] === 'function') {
                let keyCodes = meta.keyCodes
                if (keyCodes?.length) {
                    for (let keyCode of keyCodes) {
                        bindings[keyCode] = instance[functionName].bind(instance)
                    }
                }
            }
        }

        /**
         * If a takeUntil observable property name was passed in options, attach the actual observable instance to the listener options object
         */
        if (options && options.takeUntilPropertyKey && instance.hasOwnProperty(options.takeUntilPropertyKey) && instance[options.takeUntilPropertyKey] instanceof Observable) {
            options.takeUntil = instance[options.takeUntilPropertyKey]
        }

        const injector = await HotkeysProvider.injector

        /**
         * Perform the registration and store the unlisten function to call from onDestroy
         */
        try {
            const keyboardListenerAggregator = injector.get(KeyboardEventsListener)

            if (keyboardListenerAggregator) {
                if (!KeyboardShortcutsMetadataMap.has(instance.constructor)) {
                    KeyboardShortcutsMetadataMap.set(instance.constructor, {
                        unlisten: keyboardListenerAggregator.subscribe(bindings, options),
                    })
                    return true
                } else {
                    /**
                     * TODO: throw KeyboardShorcutRegisterFailedException for decorating the class twice
                     */
                }
            } else {
                /**
                 * TODO: throw KeyboardShorcutRegisterFailedException for missing injector instance
                 */
            }
        } catch (err) {
            /**
             * TODO: Handle/throw KeyboardShorcutRegisterFailedException
             */
            console.log('ERROR registering key listeners:', err)
        }
        return false
    }
}
