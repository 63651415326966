import { Injectable } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivate,
} from '@angular/router'
import { Api } from '../../api'
import {
    from,
    Observable,
} from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class CheckDomainGuard implements CanActivate {
    constructor(private api: Api) {}

    private static getParentUrl(): string | null {
        const isInIframe = (parent !== window)

        let parentUrl = null

        if (isInIframe) {
            parentUrl = document.referrer
        }

        return parentUrl
    }

    canActivate(
        route: ActivatedRouteSnapshot): Observable<boolean> {
        const profileUrl = route.queryParamMap.get('profileUrl')
        const integrationId = route.queryParamMap.get('integrationId')

        return from(this.api.integrations.checkDomainRoute.checkDomain(integrationId, profileUrl, CheckDomainGuard.getParentUrl())).pipe(map(data => {
            return data.status
        }))
    }

}
