import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-menu-horizontal-icon',
    templateUrl: 'menu-horizontal.component.html',
    styleUrls: ['menu-horizontal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHorizontalIconComponent extends IconTemplateComponent {
    @Input() width: string|number = '22'
    @Input() height: string|number = '22'
    @Input() color: string = '#2B2733'
 }
