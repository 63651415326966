import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
} from '@angular/core'

import { Subject } from 'rxjs'

import { MeetingMode } from '@undock/dock/meet'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'


@UseKeyboardShortcuts({
    takeUntilPropertyKey: 'destroyEvent',
})
@Component({
    selector: 'app-availability-meeting-mode-switch',
    templateUrl: 'meeting-mode-switch.component.html',
    styleUrls: ['meeting-mode-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityMeetingModeSwitchComponent {

    public readonly MeetingMode = MeetingMode

    @Output() onChange = new Subject<MeetingMode>()

    @Input() value: MeetingMode

    /**
     * Allows switching between old and new style
     */
    @Input() toggleMode: boolean = false


    /**
     * The list of meeting modes to be displayed in toggle mode
     *
     * @warning This option doesn't work in old (non-toggle) mode
     */
    protected _allowedModes: Array<MeetingMode> = [
        MeetingMode.Video, MeetingMode.Audio, MeetingMode.InPerson,
    ]


    public get allowedModes(): Array<MeetingMode> {
        return this._allowedModes
    }

    @Input() set allowedModes(value: Array<MeetingMode>) {
        /**
         * Shouldn't override default with wrong value
         */
        if (Array.isArray(value) && value.length > 0) {
            this._allowedModes = value
        }
    }

    public async updateCurrentMeetingMode(mode: MeetingMode) {
        /**
         * Check is meeting mode allowed
         */
        if (!this.allowedModes.includes(mode)) {
            mode = this.allowedModes[0]
        }

        if (this.value !== mode) {
            this.value = mode
            this.onChange.next(mode)
        }
    }

    @KeyboardShortcut('Control.K, Command.K')
    public async toggleCurrentMeetingMode() {
        /**
         * Switch to next allowed meeting mode
         */
        await this.updateCurrentMeetingMode(this.allowedModes[
            (this.allowedModes.indexOf(this.value) + 1) % this.allowedModes.length
        ])
    }
}
