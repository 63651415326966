import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: `app-person-icon`,
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99935 2.41675C5.81119 2.41675 2.41602 5.81192 2.41602 10.0001C2.41602 14.1882 5.81119 17.5834 9.99935 17.5834C14.1875 17.5834 17.5827 14.1882 17.5827 10.0001C17.5827 5.81192 14.1875 2.41675 9.99935 2.41675ZM0.916016 10.0001C0.916016 4.9835 4.98276 0.916748 9.99935 0.916748C15.0159 0.916748 19.0827 4.9835 19.0827 10.0001C19.0827 15.0167 15.0159 19.0834 9.99935 19.0834C4.98276 19.0834 0.916016 15.0167 0.916016 10.0001ZM12.4771 13.4167C12.16 12.3617 11.1656 11.5834 9.99935 11.5834C8.83308 11.5834 7.83865 12.3617 7.52158 13.4167H12.4771ZM5.91602 14.136C5.91602 11.8888 7.77021 10.0834 9.99935 10.0834C12.2285 10.0834 14.0827 11.8888 14.0827 14.136C14.0827 14.5672 13.7331 14.9167 13.302 14.9167H6.69674C6.26556 14.9167 5.91602 14.5672 5.91602 14.136ZM9.08268 6.66675C9.08268 6.16049 9.49309 5.75008 9.99935 5.75008C10.5056 5.75008 10.916 6.16049 10.916 6.66675C10.916 7.17301 10.5056 7.58341 9.99935 7.58341C9.49309 7.58341 9.08268 7.17301 9.08268 6.66675ZM9.99935 4.25008C8.66466 4.25008 7.58268 5.33206 7.58268 6.66675C7.58268 8.00144 8.66466 9.08341 9.99935 9.08341C11.334 9.08341 12.416 8.00144 12.416 6.66675C12.416 5.33206 11.334 4.25008 9.99935 4.25008Z" 
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class PersonIconComponent extends IconTemplateComponent{
    @Input() width: string | number = 20
    @Input() height: string | number  = 20
    @Input() color: string = '#807A8E'
}
