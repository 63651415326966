import {
    Directive,
    EventEmitter,
    HostListener,
    Output,
} from '@angular/core'


@Directive({
    selector: '[dropzone]',
})
export class DropzoneDirective {

    @Output() dropzoneHovered = new EventEmitter<boolean>()
    @Output() dropzoneDropped = new EventEmitter<FileList>()

    @HostListener('drop', ['$event'])
    public onDrop($event) {
        $event.preventDefault()
        this.dropzoneDropped.emit($event.dataTransfer.files)
        this.dropzoneHovered.emit(false)
    }

    @HostListener('dragover', ['$event'])
    public onDragOver($event) {
        $event.preventDefault()
        this.dropzoneHovered.emit(true)
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave($event) {
        $event.preventDefault()
        this.dropzoneHovered.emit(false)
    }
}
