/**
 * Abstract class AbstractStorage
 *
 * The Storage interface of the Web Storage API provides access to a particular domain's session or local storage.
 * It allows, for example, the addition, modification, or deletion of stored data items.
 */
export abstract class AbstractStorage implements Storage {
    get length(): number {return 1}

    abstract clear(): void;

    abstract getItem(key: string): string | null;

    abstract key(index: number): string | null;

    abstract removeItem(key: string): void;

    abstract setItem(key: string, value: string): void;
}
