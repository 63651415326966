import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-billing-icon',
    template: `
        <svg [attr.width]="24" [attr.height]="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0613 4.06431C15.5988 4.00213 14.9711 4 14 4H4C3.44772 4 3 4.44772 3 5.00001C3 5.55229 3.44772 6 4 6H11.9993H12H16L16.0706 6L16.2124 6H16.9955C16.989 5.55932 16.9733 5.21873 16.9357 4.93871C16.8774 4.50497 16.7832 4.36902 16.7071 4.2929C16.631 4.21677 16.495 4.12263 16.0613 4.06431ZM4 8C3.64937 8 3.31278 7.93985 3 7.8293V15C3 16.4425 3.00213 17.4238 3.10092 18.1586C3.19585 18.8646 3.36322 19.1916 3.58579 19.4142C3.80836 19.6368 4.13538 19.8042 4.84143 19.8991C5.57625 19.9979 6.55752 20 8 20H16C17.4425 20 18.4238 19.9979 19.1586 19.8991C19.8646 19.8042 20.1916 19.6368 20.4142 19.4142C20.6368 19.1916 20.8042 18.8646 20.8991 18.1586C20.9979 17.4238 21 16.4425 21 15V13C21 11.5575 20.9979 10.5763 20.8991 9.84144C20.8042 9.13538 20.6368 8.80836 20.4142 8.58579C20.1916 8.36322 19.8646 8.19585 19.1586 8.10092C18.4298 8.00294 17.4585 8.00005 16.0353 8L16 8H12L4 8ZM18.9179 4.67221C18.9721 5.07538 18.9905 5.54465 18.9968 6.07158C19.1452 6.08474 19.2879 6.10032 19.4251 6.11876C20.3529 6.2435 21.1723 6.51547 21.8284 7.17158C22.4845 7.82769 22.7565 8.64711 22.8813 9.57494C23.0001 10.4587 23 11.5753 23 12.9294V12.9294V12.9295V13V15V15.0705V15.0706V15.0706C23 16.4247 23.0001 17.5413 22.8813 18.4251C22.7565 19.3529 22.4845 20.1723 21.8284 20.8284C21.1723 21.4845 20.3529 21.7565 19.4251 21.8813C18.5413 22.0001 17.4247 22 16.0706 22H16.0706H16.0705H16H8H7.92946H7.92944H7.92941C6.5753 22 5.45869 22.0001 4.57494 21.8813C3.64711 21.7565 2.82768 21.4845 2.17158 20.8284C1.51547 20.1723 1.2435 19.3529 1.11875 18.4251C0.999936 17.5413 0.999966 16.4247 1 15.0706L1 15V5.00001C1 3.34315 2.34315 2 4 2H14L14.0658 2C14.9523 1.99995 15.7161 1.99991 16.3278 2.08215C16.9833 2.17028 17.6117 2.36902 18.1213 2.87868C18.631 3.38835 18.8297 4.0167 18.9179 4.67221ZM17.5 15C18.3284 15 19 14.3284 19 13.5C19 12.6716 18.3284 12 17.5 12C16.6716 12 16 12.6716 16 13.5C16 14.3284 16.6716 15 17.5 15Z" 
                  [attr.fill]="color"/>
        </svg>

    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class BillingIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = '#807A8E'
}
