import {
    Inject,
    Injectable,
} from '@angular/core'
import { BreakpointObserver } from '@angular/cdk/layout'

import {
    CalendarEvent,
    CalendarView,
} from 'angular-calendar'
import moment from 'moment'

import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    map,
    filter,
    takeUntil,
} from 'rxjs/operators'
import { combineLatest } from 'rxjs'

import {
    ArrayHelpers,
    clone,
} from '@undock/core'
import { RangeMs } from '@undock/core/utils/ranges-overlap'
import { UiTimelineEvent } from '@undock/dashboard/contracts'
import { GridDataSource } from '@undock/common/calendar-grid/contracts/grid-data-source'
import { CalendarGridEvent } from '@undock/common/calendar-grid/contracts/calendar-grid-event'
import { CalendarGridViewModel } from '@undock/common/calendar-grid/view-models/calendar-grid.view-model'
import { CalendarSettingsStateModel } from '@undock/common/calendar-grid/states/calendar.settings.state'
import { DashboardCalendarDetailsManager } from '@undock/dashboard/services/calendar/dashboard-calendar-details.manager'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'


@Injectable()
export class DashboardCalendarViewModel extends CalendarGridViewModel {

    public DEFAULT_EVENT_TYPE = 'draft'

    /**
     * Contains event opened in the edit/preview popup
     */
    @CompleteOnDestroy()
    public readonly openedCalendarEventStream = new ValueSubject<
        CalendarEvent<{ payload: UiTimelineEvent }>
    >(null)

    protected readonly DRAFT_EVENT_OPENED_IN_CMP_NAME = 'event-details-view'

    public constructor(
        @Inject(GridDataSource)
        protected gridDataSource: CalendarGridDataSource,
        protected breakpointObserver: BreakpointObserver,
        protected calendarEventsStorage: CalendarEventsStorage,
        protected calendarSettingsStateModel: CalendarSettingsStateModel,
        protected dashboardCalendarDetailsManager: DashboardCalendarDetailsManager,
    ) {
        super(
            gridDataSource,
            breakpointObserver,
            calendarSettingsStateModel,
        )
    }

    public initViewModel() {
        super.initViewModel()

        this.subscribeOnEventCreated()
        this.subscribeOnEventClicked()
        this.subscribeOnEventDropped()

        this.dashboardCalendarDetailsManager.onDetailsClosed.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(() => {
            this.openedCalendarEventStream.next(null)
        })
    }

    /**
     * Opens preview in create mode
     */
    protected subscribeOnEventCreated() {
        this.temporaryEvents$.pipe(
            map(events => {
                return events.filter(event => event.meta.type === 'draft')
            }),
            filter(events => {
                return events.length > 0
            }),
            takeUntil(this.destroyEvent),
        ).subscribe(v => {
            // A single temp event is allowed at the same time
            const tempEventData = v[0]

            if (!tempEventData.meta.payload) {
                tempEventData.meta.payload = {
                    id: v[0].id,
                    state: {},
                    title: '',
                    agenda: '',
                    isDraft: true,
                    end: tempEventData.end.toISOString(),
                    endMs: tempEventData.end.valueOf(),
                    start: tempEventData.start.toISOString(),
                    startMs: tempEventData.start.valueOf(),
                    allDay: tempEventData.allDay,
                } as UiTimelineEvent
            }

            // Update the event in the details view
            return this.openEventDetailsView(tempEventData)
        })
    }

    /**
     * For opening events in edit/preview popup
     */
    protected subscribeOnEventClicked() {
        this.onEventClicked.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(event => {
            if (!event.allDay) {
                return this.openEventDetailsView(event)
            }
        })
    }

    protected subscribeOnEventDropped() {
        this.onEventDropped.pipe(
            takeUntil(this.destroyEvent)
        ).subscribe(event => {
            // Push changes to the details view
            return this.openEventDetailsView(event.event, {
                schedule: {
                    end: new Date(event.newEnd),
                    start: new Date(event.newStart),
                    isAllDay: false,
                }
            }, {
                schedule: {
                    end: new Date(event.prevEnd),
                    start: new Date(event.prevStart),
                    isAllDay: false,
                }
            })
        })
    }

    public async openEventDetailsView(
        event: CalendarEvent<{
            type: string
            openedIn: string
            payload: UiTimelineEvent
        }>,
        updates?: Partial<EditMeetingData>,
        original?: Partial<EditMeetingData>,
    ) {
        if (!event) {
            this.openedCalendarEventStream.next(null)
            this.dashboardCalendarDetailsManager.forceClose()
        }

        // Skip event if it's opened in a different place
        if (event.meta.openedIn &&
            event.meta.openedIn !== this.DRAFT_EVENT_OPENED_IN_CMP_NAME
        ) {
            return
        }

        // Set the event is opened in the details view
        event.meta.openedIn = this.DRAFT_EVENT_OPENED_IN_CMP_NAME

        const currentlyOpenedEvent = this.openedCalendarEventStream.value
        if (currentlyOpenedEvent) {
            if (currentlyOpenedEvent.id !== event.id) {
                // Need to close current popup to open the new
                if (await this.dashboardCalendarDetailsManager.requestClose()) {
                    this.dashboardCalendarDetailsManager.forceClose()
                    await this.dashboardCalendarDetailsManager.open(
                        event, updates, this.view$.value === CalendarView.Day ? 'inline' : 'popup',
                    )
                    this.openedCalendarEventStream.next(event)
                } else {
                    // Collect all dates to be reloaded to revert the changes
                    const datesToReload = [
                        event?.end,
                        event?.start,
                        updates?.schedule?.end,
                        updates?.schedule?.start,
                        original?.schedule?.end,
                        original?.schedule?.start,
                    ].filter(Boolean).map(value => {
                        return moment(value).startOf('day').toISOString()
                    })

                    // Reload all affected ranges of events to be sure changes are applied
                    if (datesToReload.length > 0) {
                        await Promise.all(
                            ArrayHelpers.filterUnique(datesToReload).map(async day => {
                                return this.calendarEventsStorage.getEventsForDateRange({
                                    start: new Date(day),
                                    end: moment(day).endOf('day').toDate(),
                                }, true)
                            })
                        )
                    }
                }
            } else {
                // Update event data
                if (updates) {
                    return this.dashboardCalendarDetailsManager.updateEditPopupData(updates)
                }
            }
        } else {
            this.openedCalendarEventStream.next(event)
            await this.dashboardCalendarDetailsManager.open(
                event, updates, this.view$.value === CalendarView.Day ? 'inline' : 'popup',
            )
        }
    }
}

/**
 * TODO: Move this class away from DashboardModule
 */
@Injectable()
export class CalendarGridDataSource extends GridDataSource {

    @CompleteOnDestroy()
    public readonly hideEvents$ = new ValueSubject(false)

    @CompleteOnDestroy()
    public readonly isEditingAllowed$ = new ValueSubject(true)

    // The range which is currently displayed on calendar
    protected currentEventsRange: RangeMs

    protected readonly eventsStorageState = this.calendarEventsStorage.state

    @CompleteOnDestroy()
    protected readonly loadedEvents$ = new ValueSubject([])

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected readonly calendarEventsStorage: CalendarEventsStorage,
    ) {
        super()
        this.subscribeToEventsStorageUpdates()

        combineLatest([
            this.loadedEvents$,
            this.hideEvents$,
        ]).pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(([events, hideEvents]) => {
            this.displayedEvents$.next(hideEvents ? [] : events)
        })
    }

    public async fetch(start: Date, end: Date) {
        const response = await this.calendarEventsStorage.getEventsForDateRange({
            start: start, end: end
        })
        this.currentEventsRange = response.rangeLoaded
        this.setCalendarEvents(response.loadedEvents)
    }

    protected setCalendarEvents(events: UiTimelineEvent[]) {
        this.loadedEvents$.next(events.map(
            event => this.timelineEventToCalendarGridEvent(
                event,
                this.isEditingAllowed$.getValue(),
            )
        ))
    }

    protected subscribeToEventsStorageUpdates() {
        combineLatest([
            this.isEditingAllowed$,
            this.eventsStorageState.events,
        ]).pipe(
            takeUntil(this.destroyEvent),
            // Skipping any updates if range isn't loaded yet
            filter(() => Boolean(this.currentEventsRange)),
        ).subscribe(([, events]) => this.setCalendarEvents(events))
    }

    public timelineEventToCalendarGridEvent(
        event: UiTimelineEvent,
        isEditingAllowed: boolean = true,
    ): CalendarGridEvent {
        return {
            id: event.id,
            title: event.title,
            allDay: event.allDay,

            end: new Date(event.end),
            start: new Date(event.start),

            meta: {
                type: 'event',
                payload: event,
            },

            draggable: isEditingAllowed && event.isOrganizer,
            resizable: {
                afterEnd: isEditingAllowed && event.isOrganizer,
                beforeStart: isEditingAllowed && event.isOrganizer,
            },
        } as CalendarGridEvent
    }
}

/**
 * TODO: Move this class away from DashboardModule
 *
 * This data source makes all events non-interactive on the calendar
 */
@Injectable()
export class PassiveCalendarGridDataSource extends CalendarGridDataSource {

    public constructor(
        calendarEventsStorage: CalendarEventsStorage,
    ) {
        super(calendarEventsStorage)
    }

    public timelineEventToCalendarGridEvent(event: UiTimelineEvent): CalendarGridEvent {
        // Shouldn't mutate original event
        event = clone(event)

        event.state.isActive = false
        event.state.isRequest = false
        event.state.isCurrent = false
        event.state.isProcessing = false

        // Using original functionality to build the event
        const calendarGridEvent = super.timelineEventToCalendarGridEvent(event)

        // Preventing events from resizing
        calendarGridEvent.draggable = false
        calendarGridEvent.resizable.afterEnd = false
        calendarGridEvent.resizable.beforeStart = false

        return calendarGridEvent
    }
}
