import {
    Inject,
    NgModule,
} from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ClipboardModule } from '@angular/cdk/clipboard'

/**
 * ------------------------------------------------------------
 *                      Undock modules
 * ------------------------------------------------------------
 */
import { AclModule } from '@undock/acl/acl.module'
import { CoreModule } from '@undock/core/core.module'
import { AvailabilityUIProvider } from '@undock/time/availability/availability-ui.provider'
import { UserProvider } from '@undock/user/user.provider'
import { PeopleProvider } from '@undock/people/people.provider'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'

/**
 * ------------------------------------------------------------
 *                   Meeting providers
 * ------------------------------------------------------------
 */
import { AbstractEventListener } from '@undock/core'
import { MeetingsManager } from '@undock/dock/meet/services/meetings.manager'
import { TopicFactory } from '@undock/dock/meet/models/factories/topic.factory'
import { UserSchedulesManager } from '@undock/dock/meet/services/user-schedules.manager'
import { ActionItemFactory } from '@undock/dock/meet/models/factories/action-item.factory'
import { ConfSettingsStorage } from '@undock/dock/meet/models/chime/conf-settings.storage'
import { MeetingTypeFactory } from '@undock/dock/meet/models/factories/meeting-type.factory'
import { MeetDockCreatedListener } from '@undock/dock/meet/listeners/meet-dock-created.listener'
import { EditorActionsDetector } from '@undock/dock/meet/services/editor/editor-actions-detector'
import { MeetingAttendeeFactory } from '@undock/dock/meet/models/factories/chime/meeting-attendee.factory'
import { ChimePhoneNumbersProvider } from '@undock/dock/meet/services/conference/chime-phone-numbers.provider'
import { MeetingModeOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-mode-options.provider'
import { MeetingDurationOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-duration-options.provider'

/**
 * ------------------------------------------------------------
 *                      Shared Components
 * ------------------------------------------------------------
 */
import {
    EditTitleComponent,
    EditParticipantsComponent,
    MeetDurationSelectComponent,
    MeetingModeSelectComponent,
    MeetingModeSelectV2Component,
    MeetDurationSelectV2Component,
    TimeSlotSelectorComponent,
    EditScheduleComponent,
    EditMeetingDateComponent,
} from '@undock/dock/meet/ui/pages/edit-meeting'
import { DockTitleComponent } from '@undock/dock/meet/ui/components/dock-title/dock-title.component'
import { NotesComponent } from '@undock/dock/meet/ui/pages/meeting-dock/components/notes/notes.component'
import { TopicFormComponent } from '@undock/dock/meet/ui/components/topics-list/form/topic-form.component'
import { TopicViewComponent } from '@undock/dock/meet/ui/components/topics-list/view/topic-view.component'
import { TopicActionsComponent } from '@undock/dock/meet/ui/components/topics-list/view/topic-actions.component'
import { UploadViewComponent } from '@undock/dock/meet/ui/components/attachments-list/upload-view/upload-view.component'
import { TopicsListComponent } from '@undock/dock/meet/ui/components/topics-list/topics-list.component'
import { PrivateNotesComponent } from '@undock/dock/meet/ui/components/private-notes/private-notes.component'
import { PrivateNotesEditorComponent } from '@undock/dock/meet/ui/components/private-notes/private-notes-editor.component'
import { ParticipantSelectorComponent } from '@undock/dock/meet/ui/components/participant-selector/participant-selector.component'
import { ParticipantSelectorHostDirective } from '@undock/dock/meet/ui/components/participant-selector/directives/participant-selector-host.directive'
import { ParticipantSelectorTriggerComponent } from '@undock/dock/meet/ui/components/participant-selector/participant-selector-trigger.component'

/**
 * ------------------------------------------------------------
 *                      Shared Pipes
 * ------------------------------------------------------------
 */
import { BookingRequestManager } from '@undock/dock/meet/services/booking-request.manager'
import { MeetDockParticipantsPreviewComponent } from '@undock/dock/meet/ui/components/meet-dock-participants-preview/meet-dock-participants-preview.component'
import { EditAttendeesComponent } from '@undock/dock/meet/ui/pages/edit-meeting/components/edit-attendees'
import { MeetingModeSlideButtonComponent } from '@undock/dock/meet/ui/pages/edit-meeting/components/meet-mode-slide-button/meet-mode-slide-button.component'
import { LayoutModule } from '@undock/common/layout/layout.module'
import {
    EventFormComponent,
    EventFormDurationComponent,
    EventFormLocationComponent,
    EventFormPlanComponent,
    EventFormScheduleComponent,
    EventFormScheduleLgComponent,
} from '@undock/dock/meet/ui/components'
import { LocationsProvider } from '@undock/locations/locations.provider'
import { EventFormAvailableSlotSelectorComponent } from '@undock/dock/meet/ui/components/event-form/components/event-form-available-slot-selector.component'
import { AgendaGeneratorComponent } from './ui/components/agenda-generator/agenda-generator.component'
import { EditorModule } from '@undock/common/editor/editor.module'


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        ClipboardModule,

        AclModule,
        CoreModule,
        UiKitModule,
        EditorModule,
        AvailabilityUIProvider,
        UserProvider,
        LayoutModule,
        PeopleProvider,
        LocationsProvider,
    ],
    exports: [
        NotesComponent,
        EditTitleComponent,
        DockTitleComponent,
        TopicFormComponent,
        TopicViewComponent,
        TopicActionsComponent,
        UploadViewComponent,
        TopicsListComponent,
        PrivateNotesComponent,
        EditScheduleComponent,
        EditAttendeesComponent,
        EditMeetingDateComponent,
        TimeSlotSelectorComponent,
        EditParticipantsComponent,
        PrivateNotesEditorComponent,
        MeetingModeSelectComponent,
        MeetDurationSelectComponent,
        ParticipantSelectorComponent,
        MeetingModeSelectV2Component,
        MeetDurationSelectV2Component,
        MeetingModeSlideButtonComponent,
        ParticipantSelectorHostDirective,
        ParticipantSelectorTriggerComponent,
        MeetDockParticipantsPreviewComponent,
        AgendaGeneratorComponent,

        /**
         * Event Form
         */
        EventFormComponent,
        EventFormPlanComponent,
        EventFormDurationComponent,
        EventFormLocationComponent,
        EventFormScheduleComponent,
        EventFormScheduleLgComponent,
        EventFormAvailableSlotSelectorComponent,
    ],
    declarations: [
        NotesComponent,
        DockTitleComponent,
        EditTitleComponent,
        TopicFormComponent,
        TopicViewComponent,
        TopicActionsComponent,
        UploadViewComponent,
        TopicsListComponent,
        EditScheduleComponent,
        PrivateNotesComponent,
        EditAttendeesComponent,
        EditMeetingDateComponent,
        TimeSlotSelectorComponent,
        EditParticipantsComponent,
        PrivateNotesEditorComponent,
        MeetingModeSelectComponent,
        MeetDurationSelectComponent,
        ParticipantSelectorComponent,
        MeetingModeSelectV2Component,
        MeetDurationSelectV2Component,
        MeetingModeSlideButtonComponent,
        ParticipantSelectorHostDirective,
        ParticipantSelectorTriggerComponent,
        MeetDockParticipantsPreviewComponent,
        AgendaGeneratorComponent,

        /**
         * Event Form
         */
        EventFormComponent,
        EventFormPlanComponent,
        EventFormDurationComponent,
        EventFormLocationComponent,
        EventFormScheduleComponent,
        EventFormScheduleLgComponent,
        EventFormAvailableSlotSelectorComponent,
    ],
    providers: [
        TopicFactory,
        ActionItemFactory,
        MeetingTypeFactory,
        MeetingAttendeeFactory,

        MeetingsManager,
        UserSchedulesManager,
        BookingRequestManager,
        ConfSettingsStorage,
        EditorActionsDetector,
        ChimePhoneNumbersProvider,
        MeetingModeOptionsProvider,
        MeetingDurationOptionsProvider,

        { provide: AbstractEventListener, useClass: MeetDockCreatedListener, multi: true },
    ],
})
export class MeetProvider {
    public constructor(
        /**
         * Event listeners should be injected in the next way because
         * Angular DI doesn't instantiate the service until it been injected
         */
        @Inject(AbstractEventListener)
        protected listeners: AbstractEventListener[],
    ) {}
}
