import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-agenda-icon',
    templateUrl: 'agenda-icon.component.html',
    styleUrls: ['agenda-icon.component.scss'],
})
export class AgendaIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 22
    @Input() height: string | number  = 22
    @Input() color: string = 'white'
}
