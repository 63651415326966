import {
    Component,
    OnInit,
} from '@angular/core'

import {
    combineLatest,
    fromEvent,
    Observable,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    StatefulSubject,
    SubscriptionsHub,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    BlurEvent,
    CKEditor5,
} from '@ckeditor/ckeditor5-angular'
import MarkdownEditor from '@ckeditor/ckeditor5-custom-build/build/ckeditor'

import {
    clone,
    Config,
    patchObject,
} from '@undock/core'
import {
    ckEditorConfig,
    Markdown2HtmlConverter,
} from '@undock/common/editor'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { PrivateNotesManager } from '@undock/core/services/private-notes.manager'
import { MeetingDockPageStateModel } from '@undock/dock/meet/ui/pages/meeting-dock/meeting-dock-page.state'


@Component({
    selector: 'app-meet-private-notes',
    templateUrl: 'private-notes.component.html',
    styleUrls: ['private-notes.component.scss'],
})
export class PrivateNotesComponent implements OnInit {

    /**
     * CKEditor class
     */
    public readonly Editor = MarkdownEditor

    public readonly editorConfig: Record<string, any>

    public readonly privateNoteStream: Observable<string>


    @CompleteOnDestroy()
    private isEditorFocusedSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private privateNoteChangesSubject = new StatefulSubject<string>()

    @CompleteOnDestroy()
    private editorInstanceSubject = new StatefulSubject<CKEditor5.Editor>()


    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    private readonly privateNotesChangesDebounceTime = 500 // ms

    public constructor(
        public state: MeetingDockPageStateModel,
        private config: Config,
        private dockFacade: DockFacade,
        private markdown2Html: Markdown2HtmlConverter,
        private privateNotesManager: PrivateNotesManager,
    ) {
        /**
         * Enabled HTML mode
         */
        this.Editor.markdownModeEnabled = false

        /**
         * Enables floating toolbar option.
         */
        this.Editor.balloonToolbarEnabled = true

        this.editorConfig = patchObject(clone(ckEditorConfig), {
            placeholder: 'Enter notes...',
            toolbar: {
                items: {
                    $unset: ['maximize', 'mediaEmbed', 'imageUpload', 'insertTable'],
                },
            },
            $unset: ['image', 'table'],
        })

        this.privateNoteStream = this.privateNotesManager.privateNotesStream
    }

    public ngOnInit() {
        this.dockFacade.currentDockStream.pipe(
            distinctUntilChanged(
                (prev, next) => prev.id === next.id,
            ),
            takeUntil(this.destroyEvent),
        ).subscribe(
            dock => this.privateNotesManager.setPrivateNotesSource(dock),
        )

        combineLatest([
            this.privateNoteStream,
            this.editorInstanceSubject,
            this.isEditorFocusedSubject,
        ]).pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(sources => {
            let [data, editor, isEditorFocused] = sources
            /**
             * Updating editor content only if editor is not focused
             *
             * After the focus been blurred all changes appear
             */
            if (!isEditorFocused && data !== editor.getData()) {
                if (!this.Editor.markdownModeEnabled) {
                    /**
                     * Checking what kind of data we got
                     */
                    if (!this.markdown2Html.isHTML(data)) {
                        /**
                         * Converting Markdown data into HTML
                         */
                        data = this.markdown2Html.toHtml(data)

                        /**
                         * Pushing changes to update data source
                         */
                        this.privateNoteChangesSubject.next(data)
                    }
                }

                editor.setData(data)
            }
        })

        const editorSubscriptionsHub = new SubscriptionsHub(this.destroyEvent)
        this.editorInstanceSubject.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(editor => {
            /**
             * Removing all subscriptions if editor instance been changed
             */
            editorSubscriptionsHub.unsubscribe()

            if (editor.sourceElement) {
                editorSubscriptionsHub.add.apply(editorSubscriptionsHub, [
                    fromEvent<FocusEvent>(editor.sourceElement, 'focus').pipe(
                        debounceTime(1000),
                    ).subscribe(() => {
                        this.isEditorFocusedSubject.next(true)
                    }),
                    fromEvent<BlurEvent>(editor.sourceElement, 'blur').pipe(
                        debounceTime(1000),
                    ).subscribe(() => {
                        this.isEditorFocusedSubject.next(false)
                    }),
                ])
            }
        })

        this.initializePrivateNotesAutoSaving()
    }

    public onEditorContentChanged(privateNote: string) {
        this.privateNoteChangesSubject.next(privateNote)
    }

    public onEditorReady(editor: MarkdownEditor) {
        this.editorInstanceSubject.next(editor)
    }

    public async closeIfOpen() {
        if (await this.state.state.isPrivateNotesDisplayedStream) {
            this.state.togglePrivateNotes()
        }
    }

    protected initializePrivateNotesAutoSaving() {
        /**
         * Private notes auto saving
         */
        this.privateNoteChangesSubject.pipe(
            takeUntil(this.destroyEvent),
            debounceTime(this.privateNotesChangesDebounceTime),
        ).subscribe(
            text => this.privateNotesManager.updatePrivateNoteText(text),
        )
    }
}
