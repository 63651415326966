<section class="navigation-container"
         *ngIf="{
            isExpanded: state.isExpandedStream | stream
         } as view"
>
    <div class="navigation-container__item -top"
         (click)="toggleSidebar()"
         [ngSwitch]="view.isExpanded"
    >
        <div class="navigation-container__item-row" *ngSwitchCase="false">
            <button class="navigation-container__item-icon">
                <app-icon-sidebar-open [width]="24" [height]="24" [color]="'var(--color-grayscale-dark-1)'"></app-icon-sidebar-open>
            </button>
        </div>
        <div class="navigation-container__item-row" *ngSwitchCase="true">
            <app-logo-undock [color]="'var(--color-primary-main)'"
                             [@sidebarView]="(view.isExpanded) ? 'show' : 'hide' ">
            </app-logo-undock>
            <button class="navigation-container__item-icon">
                <app-icon-sidebar-close [width]="24" [height]="24"></app-icon-sidebar-close>
            </button>
        </div>
    </div>

    <ng-container *ngIf="isBetaUser$ | stream">
        <div class="navigation-container__item"
             [matTooltip]="view.isExpanded ? '' : 'Time'" [matTooltipPosition]="'right'"
        >
            <button class="sidebar-navigation__navigation-buttons-box sidebar-navigation__button -light-bg"
                    sidebarRouterLink
                    [routerLink]="['/time']"
                    routerLinkActive="-router-link-active"
                    [class.-expanded]="view.isExpanded"
                    (click)="registerNavigationChange('time')"
            >
                <p class="button-content">
                    <app-infinity-icon></app-infinity-icon>
                    <span class="link-name" [class.-expanded-bar]="view.isExpanded">Time</span>
                </p>
            </button>
        </div>
    </ng-container>


    <div class="navigation-container__item sidebar-navigation sidebar-navigation-container"
         [matTooltip]="view.isExpanded ? '' : 'Calendar'" [matTooltipPosition]="'right'"
    >
        <button class="sidebar-navigation__navigation-buttons-box sidebar-navigation__button -light-bg sidebar-navigation__calendar-button"
                sidebarRouterLink
                [routerLink]="['/dashboard']" routerLinkActive="-router-link-active"
                [class.-expanded]="view.isExpanded"
                (click)="registerNavigationChange('timeline')"
        >
            <p class="button-content">
                <app-calendar-basic-icon></app-calendar-basic-icon>
                <span class="link-name" [class.-expanded-bar]="view.isExpanded">Calendar</span>
            </p>
        </button>
    </div>

    <div class="navigation-container__item"
         [matTooltip]="view.isExpanded ? '' : 'Plans'" [matTooltipPosition]="'right'"
    >
        <button class="sidebar-navigation__navigation-buttons-box sidebar-navigation__button -light-bg"
                sidebarRouterLink
                [routerLink]="['/plans']"
                routerLinkActive="-router-link-active"
                [class.-expanded]="view.isExpanded"
                (click)="registerNavigationChange('plans')"
        >
            <p class="button-content">
                <app-plans-icon></app-plans-icon>
                <span class="link-name" [class.-expanded-bar]="view.isExpanded">Plans</span>
            </p>
        </button>
    </div>

    <div class="navigation-container__item"
         [matTooltip]="view.isExpanded ? '' : 'People'" [matTooltipPosition]="'right'"
    >
        <button class="sidebar-navigation__navigation-buttons-box sidebar-navigation__button -light-bg"
                sidebarRouterLink
                [routerLink]="['/people']"
                routerLinkActive="-router-link-active"
                [class.-expanded]="view.isExpanded"
                (click)="registerNavigationChange('people')"
        >
            <p class="button-content">
                <app-people-icon></app-people-icon>
                <span class="link-name" [class.-expanded-bar]="view.isExpanded">People</span>
            </p>
        </button>
    </div>
</section>
