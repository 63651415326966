import {
    Directive,
    ElementRef,
} from '@angular/core'

@Directive({
    selector: '[udInputBlock]',
})
export class InputBlockDirective {

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.classList.add('ud-input-block')
    }
}
