import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators'

import { Memoize } from '@undock/core'
import { AvailabilitySet } from '@undock/api/scopes/profile/contracts/availability'

import {
    default as m,
    Moment,
} from 'moment'
import { DatePickerComponent } from '@undock/common/ui-kit/ui/components/date-picker'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'

@UseKeyboardShortcuts({
    takeUntilPropertyKey: 'destroyedEvent',
})
@Component({
    selector: 'app-time-availability-day-browser',
    templateUrl: 'availability-day-browser.component.html',
    styleUrls: ['availability-day-browser.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityDayBrowserComponent {
    @Output() readonly onNextDaysClicked = new EventEmitter<void>()
    @Output() readonly onPrevDaysClicked = new EventEmitter<void>()
    @Output() readonly onDaySelected = new EventEmitter<Moment>()
    @Output() readonly onDayIndexSelected = new EventEmitter<number>()

    @CompleteOnDestroy()
    public readonly isMultiDayMode$ = new StatefulSubject<boolean>()

    @CompleteOnDestroy()
    public readonly isCalendarOverlayVisible$ = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly availability$ = new StatefulSubject<AvailabilitySet[]>()

    @CompleteOnDestroy()
    public readonly isAvailabilityLoading$ = new StatefulSubject<boolean>()

    @CompleteOnDestroy()
    public readonly availabilityRangeStart$ = new StatefulSubject<Moment>()

    @CompleteOnDestroy()
    public readonly selectedAvailabilityDayIndex$ = new StatefulSubject<number>()

    @CompleteOnDestroy()
    public readonly availabilityDaysCountToDisplay$ = new StatefulSubject<number>()

    /**
     * -----------------------------------------------------------
     *                        Public API
     * -----------------------------------------------------------
     */

    @Input() set availability(value: AvailabilitySet<any>[]) {
        this.availability$.next(value ?? [])
    }

    @Input() set isAvailabilityLoading(value: boolean) {
        this.isAvailabilityLoading$.next(value ?? false)
    }

    @Input() set availabilityRangeStart(value: Moment) {
        this.availabilityRangeStart$.next(value ?? m())
    }

    @Input() set selectedAvailabilityDayIndex(value: number) {
        this.selectedAvailabilityDayIndex$.next(value ?? 0)
    }

    private _availabilityDaysCountToDisplay: number
    @Input() set availabilityDaysCountToDisplay(value: number) {
        this._availabilityDaysCountToDisplay = value
        this.availabilityDaysCountToDisplay$.next(value ?? 7)
    }

    get availabilityDaysCountToDisplay(): number {
        return this._availabilityDaysCountToDisplay;
    }

    @Input('multiMode') set isMultiDayMode(value: boolean) {
        this.isMultiDayMode$.next(value ?? true)
    }

    @Input() selectedTimeZone: string
    @Input() enableDatePicker: boolean = false

    @Input() themeColor = 'transparent'

    /**
     * -----------------------------------------------------------
     *                   Internal UI Streams
     * -----------------------------------------------------------
     */

    @Memoize()
    public get selectedDayDateStream(): ReactiveStream<Moment> {
        return new ReactiveStream<Moment>(
            combineLatest([
                this.availabilityDaysStream,
                this.selectedAvailabilityDayIndex$,
            ]).pipe(
                filter(sources => sources[1] > -1),
                distinctUntilChanged(
                    (prev, next) => prev[0][prev[1]]?.isSame(next[0][next[1]], 'day'),
                ),
                map(sources => {
                    const [days, selectedDayIndex] = sources

                    return days[selectedDayIndex]
                }),
            ),
        )
    }

    @Memoize()
    public get availabilityDaysStream(): Observable<Moment[]> {
        return combineLatest([
            this.availabilityRangeStart$,
            this.availabilityDaysCountToDisplay$,
        ]).pipe(
            map(([fromDate, displayDaysCount]) => {
                return [...new Array(displayDaysCount)].map(
                    (_, dayIndex) => fromDate.clone().add(dayIndex, 'days'),
                )
            }),
        )
    }

    @Memoize()
    public get availabilitySetStream(): Observable<AvailabilitySet[]> {
        return combineLatest([
            this.availability$,
            this.availabilityDaysStream,
        ]).pipe(
            map(([availabilitySets, daysToDisplay]) => {
                /**
                 * If api returned empty availability set we should return placeholders
                 */
                return availabilitySets.length > 0 ? availabilitySets : daysToDisplay.map(
                    day => ({ day: day, slots: [] }),
                )
            }),
        )
    }

    /**
     * -----------------------------------------------------------
     *                        Actions
     * -----------------------------------------------------------
     */

    @KeyboardShortcut('Right')
    public async goToNextWeek() {
        this.onNextDaysClicked.next()
    }

    @KeyboardShortcut('Left')
    public async goToPreviousWeek() {
        this.onPrevDaysClicked.next()
    }

    public async selectDayByIndex(index: number) {
        const days = await this.availability$

        if (days[index] && days[index].slots.length > 0) {
            this.onDayIndexSelected.next(index)
        }
    }

    public async onChangeDateSelected(value: Date) {
        this.onDaySelected.next(m(value))
    }

    public onOpenStateChange(isOpened, refComponent?: DatePickerComponent) {
        if (refComponent) {
            this.toggleDatePicker(refComponent, isOpened)
        }
    }

    toggleDatePicker(refComponent?: DatePickerComponent, nextState = null) {
        const currentState = this.isCalendarOverlayVisible$.getValue()
        if (!refComponent) {
            return false
        }
        if (null === nextState) {
            nextState = !currentState
        } else if (nextState === currentState) {
            return false
        }
        this.isCalendarOverlayVisible$.next(nextState)
        if (nextState) {
            refComponent.show()
        } else {
            refComponent.hide()
        }
    }
}
