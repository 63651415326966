import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-time',
    templateUrl: 'time.component.html',
    styleUrls: ['time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeIconComponent {
    @Input() height: number|string = '22'
    @Input() width: number|string = '20'
    @Input() color: string = '#807A8E'
    @Input() borderColor: string = ''
}
