import { Injectable } from '@angular/core'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    compareDeeplyBy,
    Memoize,
} from '@undock/core'
import {
    combineLatestWith,
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators'
import { IOrganizationInviteAggregate } from '@undock/api/scopes/organizations/contracts'


@Injectable({
    providedIn: 'root',
})
export class OrganizationInvitesManager {

    @CompleteOnDestroy()
    public readonly areInvitesLoading$ = new ValueSubject(false)

    @CompleteOnDestroy()
    public readonly triggerInvitesRefresh$ = new ValueSubject<void>(null)

    @EmitOnDestroy()
    private destroyEvent = new DestroyEvent()

    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
    ) {}

    @Memoize()
    public get invites$(): ReactiveStream<IOrganizationInviteAggregate[]> {
        return new ReactiveStream(
            this.currentUser.dataStream.pipe(
                takeUntil(this.destroyEvent),
                distinctUntilChanged(compareDeeplyBy('_id')),
                // Reload
                combineLatestWith(this.triggerInvitesRefresh$),
                tap(() => this.areInvitesLoading$.next(true)),
                switchMap(() => {
                    return this.api.organizations.invites.listPersonal(0, 100, 'pending')
                }),
                tap(organizations => {
                    this.areInvitesLoading$.next(false)
                }),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }
}
