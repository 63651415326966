import {
    Component,
    ChangeDetectionStrategy,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-shield-check-icon',
    templateUrl: 'shield-check-icon.component.html',
    styleUrls: ['shield-check-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShieldCheckIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = '#22282F';
}
