import { MemoryStorage } from '@undock/core/models/storage/memory.storage'

/**
 * Contains a MemoryStorage per each class instance
 *
 * WeakMap should be used to avoid memory leaks
 */
const globalStoragesWeakMap = new WeakMap<Object, MemoryStorage>()

const memoizeDestroyHookInitializedKey = '__memoizeDestroyHookInitialized'

/**
 * @warning Avoid of usage with non "clear" functions
 *
 * @description Memoize decorator allows to prevent multiple
 *       method calls by returning the first value each time
 */
export function Memoize(): MethodDecorator {
    /**
     * Returns a unique string for given arguments
     */
    function serializeArguments(args: any[]): string {
        return args.map((item: any): string => {
            switch (typeof item) {
                case 'object':
                    return JSON.stringify(item)
                case 'function':
                    return item.toString()
                default:
                    return item
            }
        }, []).join('')
    }

    function pluginOnDestroyHook(target: Object) {
        const originalDestructor = target['ngOnDestroy']
        const newDestructorDescriptor = {
            value: function(...args: any[]) {
                originalDestructor ? originalDestructor.apply(this, args) : null

                if (globalStoragesWeakMap.has(this)) {
                    /**
                     * Processing cache cleanup before the original object being destroyed
                     */
                    globalStoragesWeakMap.delete(this)
                }

                return typeof originalDestructor === 'function' ? originalDestructor.apply(this, args) : null
            },
            configurable: true,
            writeable: true,
        }

        // Deleting old destructor and injecting wrapped one
        delete target.constructor.prototype['ngOnDestroy']
        Object.defineProperty(target.constructor.prototype, 'ngOnDestroy', newDestructorDescriptor)
    }

    /**
     * Returns a property decorator
     */
    return <T extends Object>(target: T, key, descriptor: TypedPropertyDescriptor<any>) => {
        /**
         * Defines what kind of descriptor is used
         */
        const isGetterMode = typeof (descriptor.get) === 'function'
        const originalMethod = descriptor[isGetterMode ? 'get' : 'value'] as Function

        /**
         * It seems that TypeScript defines descriptor.value property in the wrong way.
         * It should match () => T type, but currently used T
         */
        // @ts-ignore
        descriptor[isGetterMode ? 'get' : 'value'] = function(...args) {

            if (!globalStoragesWeakMap.has(this)) {
                globalStoragesWeakMap.set(this, new MemoryStorage())
            }

            let storage = globalStoragesWeakMap.get(this)
            let storageKey = `${key}_${serializeArguments(args)}`

            if (storage.length === 0 || !storage.getItem(storageKey)) {
                storage.setItem(storageKey, originalMethod.apply(this, args))
            }

            return storage.getItem(storageKey)
        }

        if (!target[memoizeDestroyHookInitializedKey]) {
            pluginOnDestroyHook(target)
        }

        target[memoizeDestroyHookInitializedKey] = true
    }
}
