import { Injectable } from '@angular/core'

import {
    filter,
    map,
    shareReplay,
    take,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    ExtConnector,
    Memoize,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    AutomatedReschedulingType,
    SyncedCalendar,
} from '@undock/integrations/contracts'
import { PartnerIntegration } from '@undock/api/scopes/partners/contracts/partner-integration.interface'
import { combineLatest } from 'rxjs'


@Injectable()
export class IntegrationsManager {

    public readonly integrationsStream: ReactiveStream<SyncedCalendar[]>
    public readonly guestCalendarStream: ReactiveStream<SyncedCalendar>
    public readonly syncedCalendarsChangedStream: ReactiveStream<boolean>
    public readonly partnerIntegrationsStream: ReactiveStream<PartnerIntegration[]>

    @CompleteOnDestroy()
    private syncedCalendarsChangedSubject = new StatefulSubject<boolean>()

    @CompleteOnDestroy()
    protected integrationsSubject = new StatefulSubject<SyncedCalendar[]>()

    @CompleteOnDestroy()
    protected currentGuestCalendarSubject = new ValueSubject<SyncedCalendar>(null)

    @CompleteOnDestroy()
    protected partnerIntegrationsSubject = new StatefulSubject<PartnerIntegration[]>()


    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()


    public constructor(
        protected api: Api,
        protected user: CurrentUser,
        /**
         * @TODO: Trigger UserUpdated action
         */
        protected extConnector: ExtConnector,
    ) {
        this.integrationsStream = this.integrationsSubject.asStream()
        this.guestCalendarStream = this.currentGuestCalendarSubject.asStream()
        this.partnerIntegrationsStream = this.partnerIntegrationsSubject.asStream()
        this.syncedCalendarsChangedStream = this.syncedCalendarsChangedSubject.asStream()

        this.initialize().catch(
            error => console.warn(`IntegrationsManager::initialize`, error),
        )
    }

    @Memoize()
    public get calendarsStream(): ReactiveStream<SyncedCalendar[]> {
        return new ReactiveStream<SyncedCalendar[]>(
            this.integrationsSubject.pipe(
                map(integrations => {
                    return integrations.filter(i => !i.isIntegration).sort((a, b) => {
                        return Number(b.isActive) - Number(a.isActive)
                    })
                }),
                takeUntil(this.destroyedEvent),
            ),
        )
    }

    @Memoize()
    public get zoomConnectionStream(): ReactiveStream<SyncedCalendar> {
        return new ReactiveStream<SyncedCalendar>(
            this.integrationsSubject.pipe(
                map(
                    connections => connections ? connections.find(
                        connection => connection.provider === 'zoom',
                    ) : null,
                ),
            ),
        )
    }

    @Memoize()
    public get primaryCalendarStream(): ReactiveStream<SyncedCalendar> {
        return new ReactiveStream<SyncedCalendar>(
            this.calendarsStream.pipe(
                map(
                    calendars => calendars.find(item => item.isActive && item.isPrimary),
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get noCalendarConnectedStream(): ReactiveStream<boolean> {
        return new ReactiveStream(
            this.calendarsStream.pipe(
                map(calendars => calendars.length < 1),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isAnyCalendarConnectedStream(): ReactiveStream<boolean> {
        return new ReactiveStream(
            this.calendarsStream.pipe(
                map(calendars => calendars.length > 0),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isGuestCalendarConnectedStream(): ReactiveStream<boolean> {
        return new ReactiveStream(
            this.guestCalendarStream.pipe(
                map(c => Boolean(c)),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isCurrentUserHasZoomConnectionStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.integrationsSubject.pipe(
                map(
                    integrations => integrations.some(c => c.provider === 'zoom' && c.isActive),
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get paymentIntegrationsStream(): ReactiveStream<SyncedCalendar[]> {
        return new ReactiveStream<SyncedCalendar[]>(
            this.integrationsSubject.pipe(
                map(
                    integrations => integrations.filter(i => i.provider === 'stripe'),
                ),
                takeUntil(this.destroyedEvent),
            ),
        )
    }


    public async disconnectIntegration(integration: SyncedCalendar) {
        await this.api.integrations.calendar
                  .deleteIntegration(integration._id)

        await this.refreshCurrentUserIntegrations()
    }

    /**
     * Guest calendars aren't still used
     *
     * @deprecated
     */
    public async syncGuestCalendarState() {
        let calendar = await this.api.integrations.auth.getGuestState()
        if (calendar) {
            this.currentGuestCalendarSubject.next(calendar)
        } else {
            this.currentGuestCalendarSubject.next(null)
        }
    }

    public async connectGoogleCalendar(redirectUrl?, openInNewTab?) {
        try {
            const url = await this.api.integrations.auth.getCalendarConnectionUrl(
                redirectUrl ?? this.getRedirectUrlForOAuthProviders(), 'google',
            )

            if (openInNewTab) {
                window.open(url, '_blank').focus()
            } else {
                window.location.href = url
            }
        } catch (err) {
            throw new Error('ERROR: ' + err)
        }
    }

    public async connectGuestGoogleCalendar(redirectUrl?) {
        try {
            window.location.href = await this.api.integrations.auth.getCalendarConnectionUrl(
                redirectUrl ?? this.getRedirectUrlForOAuthProviders(), 'google',
            )
        } catch (err) {
            this.currentGuestCalendarSubject.next(null)
            throw new Error('ERROR: ' + err)
        }
    }

    public async connectGmailInbox(redirectUrl?, openInNewTab?) {
        try {
            const url = await this.api.integrations.auth.getIntegrationConnectionUrl(
                redirectUrl ?? this.getRedirectUrlForOAuthProviders(), 'gmail',
            )

            if (openInNewTab) {
                window.open(url, '_blank').focus()
            } else {
                window.location.href = url
            }
        } catch (err) {
            throw new Error('ERROR: ' + err)
        }
    }

    public async connectMicrosoftCalendar(redirectUrl?, openInNewTab?) {
        const url = await this.api.integrations.auth.getCalendarConnectionUrl(
            redirectUrl ?? this.getRedirectUrlForOAuthProviders(), 'microsoft',
        )

        if (openInNewTab) {
            window.open(url, '_blank').focus()
        } else {
            window.location.href = url
        }
    }

    public async connectGuestMicrosoftCalendar(redirectUrl?) {
        window.location.href = await this.api.integrations.auth.getCalendarConnectionUrl(
            redirectUrl ?? this.getRedirectUrlForOAuthProviders(), 'microsoft',
        )
    }

    public async connectZoomIntegration(redirectUrl?) {
        try {
            window.location.href = await this.api.integrations.auth.getCalendarConnectionUrl(
                redirectUrl ?? this.getRedirectUrlForOAuthProviders(), 'zoom',
            )
        } catch (err) {
            throw new Error('ERROR: ' + err)
        }
    }

    public async connectStripeIntegration(openInNewTab = false, redirectUrl?) {
        try {
            const url = await this.api.integrations.auth.getIntegrationConnectionUrl(
                redirectUrl ?? this.getRedirectUrlForOAuthProviders(), 'stripe',
            )
            if (openInNewTab) {
                window.open(url, '_blank').focus()
            } else {
                window.location.href = url
            }

        } catch (err) {
            throw new Error('ERROR: ' + err)
        }
    }

    public async disconnectCalendar(calendar: SyncedCalendar) {
        /**
         * Will remove integration before it actually removed on the backend
         */
        this.integrationsSubject.next(
            (await this.integrationsSubject).filter(item => item._id !== calendar._id),
        )

        /**
         * Deleting calendar from separate calendars collection
         */
        await this.api.integrations.calendar.deleteIntegration(calendar._id)

        await this.refreshCurrentUserIntegrations()
        this.syncedCalendarsChangedSubject.next(true)
    }

    public async setIsCalendarActive(calendar: SyncedCalendar, isActive: boolean) {
        await this.api.integrations.calendar.setIsCalendarActive(calendar._id, isActive)
        await this.refreshCurrentUserIntegrations()
        this.syncedCalendarsChangedSubject.next(true)
    }

    public async setCalendarAsPrimary(calendar: SyncedCalendar) {
        await this.api.integrations.calendar.setIsCalendarPrimary(calendar._id)
        await this.refreshCurrentUserIntegrations()
        this.syncedCalendarsChangedSubject.next(true)
    }

    public async setIsCalendarBlocking(calendar: SyncedCalendar, isBlocking: boolean) {
        await this.api.integrations.calendar.setIsCalendarBlocking(calendar._id, isBlocking)
        await this.refreshCurrentUserIntegrations()
        this.syncedCalendarsChangedSubject.next(true)
    }

    public async setAutomatedReschedulingValues(calendar: SyncedCalendar, type: AutomatedReschedulingType, scheduleId: string = undefined) {
        await this.api.integrations.calendar.setAutomatedReschedulingValues(calendar._id, type, scheduleId)
        await this.refreshCurrentUserIntegrations()
        this.syncedCalendarsChangedSubject.next(true)
    }

    public async setIsSubCalendarActive(
        calendar: SyncedCalendar, subCalendarId: string, isActive: boolean,
    ) {
        await this.api.integrations.calendar.setIsSubCalendarActive(
            calendar._id, subCalendarId, isActive,
        )
        this.syncedCalendarsChangedSubject.next(true)
    }

    public async setIsSubCalendarBlocking(
        calendar: SyncedCalendar, subCalendarId: string, isBlocking: boolean,
    ) {
        await this.api.integrations.calendar.setIsSubCalendarBlocking(
            calendar._id, subCalendarId, isBlocking,
        )
        this.syncedCalendarsChangedSubject.next(true)
    }

    public async setIsSubCalendarDisplayed(
        calendar: SyncedCalendar, subCalendarId: string, isDisplayed: boolean,
    ) {
        await this.api.integrations.calendar.setIsSubCalendarDisplayed(
            calendar._id, subCalendarId, isDisplayed,
        )
        this.syncedCalendarsChangedSubject.next(true)
    }

    protected async initialize() {
        combineLatest([
            /**
             * To ensure current user is initialized
             */
            this.user.dataStream,
            this.user.isRegularUserStream,
        ]).pipe(
            filter(([_, isRegular]) => {
                return isRegular
            }),
            take(1),
            takeUntil(this.destroyedEvent),
        ).subscribe(() => {
            return Promise.all([
                this.refreshCurrentUserIntegrations(),
                this.refreshCurrentUserPartnerIntegrations(),
            ])
        })
    }

    public async refreshCurrentUserIntegrations() {
        try {
            this.integrationsSubject.next(
                await this.api.integrations.calendar.getOwnIntegrations(),
            )
        } catch (error) {
            console.warn(`Unable load current user integrations`, error)
        }
    }


    public async refreshCurrentUserPartnerIntegrations() {
        let integrations: PartnerIntegration[] = []

        try {
            integrations = await this.api.partners.integrations.getPersonalIntegrationsList()
        } catch (error) {
            console.error(`Cannot load partner integrations`, error)
        }

        this.partnerIntegrationsSubject.next(integrations)
    }

    protected getRedirectUrlForOAuthProviders() {
        return `${location.protocol}//${window.location.host}${window.location.pathname}`
    }
}
