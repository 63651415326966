import {
    Component,
    OnInit,
} from '@angular/core'
import {
    ActivatedRoute,
    Router,
} from '@angular/router'
import { Title } from '@angular/platform-browser'
import { AuthManager } from '@undock/auth/services/auth.manager'


@Component({
    selector: 'app-logout-page',
    templateUrl: 'logout.page.html',
    styleUrls: ['logout.page.scss'],
})
export class LogoutPage implements OnInit {

    public constructor(
        private title: Title,
        private router: Router,
        private authManager: AuthManager,
        private activatedRoute: ActivatedRoute,
    ) {
        title.setTitle(`Log Out | Undock`)
    }

    public async ngOnInit(): Promise<void> {
        try {
            await this.authManager.signOut()
            this.redirectAfterSuccessSignOut()
        } catch (error) {
            console.error(error)
            await this.router.navigate(['/login'])
        }
    }

    protected redirectAfterSuccessSignOut() {
        const redirectPath = this.activatedRoute.snapshot.queryParamMap.get('redirectPath')

        window.location.href = window.location.origin + '/login' + (redirectPath ? `?redirectPath=${redirectPath}`: '')
    }
}
