import {
    Directive,
    ElementRef,
    Input,
    OnInit,
} from '@angular/core'

export enum InputType {
    Text = 'text',
    Search = 'search',
}

@Directive({
    selector: '[udInput]',
})
export class InputDirective implements OnInit {

    @Input('udInputDark')
    public readonly dark: boolean = false

    @Input('udInputType')
    public readonly inputType: 'text' | 'search' = InputType.Text

    public constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.classList.add('ud-input')
    }

    public ngOnInit() {
        if (this.dark) {
            this.elementRef.nativeElement.classList.add('dark')
        }

        if (this.inputType === InputType.Search) {
            this.elementRef.nativeElement.classList.add('ud-input--search')
        }
    }
}
