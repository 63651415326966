<ng-container *ngIf="sourceImageStream | async as image">
    <div class="image-cropper-overlay">

        <div class="cropper-body">
            <div class="header">
                <h2 class="header-title">Change profile photo</h2>
                <button mat-button class="close-button" (click)="onCancelButtonClick()">
                    <i class="material-icons">close</i>
                </button>
            </div>

            <div class="image-bg">
                <image-cropper format="png"
                               [aspectRatio]="1"
                               [onlyScaleDown]="true"
                               [resizeToWidth]="1280"
                               [imageFile]="$any(image)"
                               [maintainAspectRatio]="maintainAspectRatio"
                               [disabled]="disabled"

                               (imageCropped)="onImageCropped($event)"
                ></image-cropper>
            </div>

            <div class="footer">
                <button mat-button class="save-button" (click)="onSaveButtonClick()">Save</button>
            </div>
        </div>
    </div>
</ng-container>
