import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { CoreModule } from '@undock/core/core.module'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'

import { InvitesFacade } from '@undock/invites/facades/invites.facade'

import { StandaloneInvitePageComponent } from '@undock/invites/components/pages/standalone-invite/standalone-invite.page'
import { ContactInviteListComponent } from '@undock/invites/components/contact-invite-list/contact-invite-list.component'
import { ContactInviteListItemComponent } from '@undock/invites/components/contact-invite-list/contact-invite-list-item/contact-invite-list-item.component'
import { RouterModule } from '@angular/router'
import { LayoutModule } from '@undock/common/layout/layout.module'


@NgModule({
    imports: [
        RouterModule,
        CommonModule,

        CoreModule,
        UiKitModule,
        LayoutModule,
    ],
    exports: [
        ContactInviteListComponent,
    ],
    declarations: [
        ContactInviteListComponent,
        StandaloneInvitePageComponent,
        ContactInviteListItemComponent,
    ],
    providers: [InvitesFacade],
})
export class InvitesProvider {}
