import { ApiRoute } from '@undock/api/services/api.route'
import {
    ChargeAccountDetailsInterface,
    PartnerChargeAccountInterface,
} from '@undock/api/scopes/charges/contracts/charge-account.interface'
import { InitializeBookingChargeRequest } from '@undock/api/scopes/charges/requests/initialize-booking-charge.request'
import { ChargeInterface } from '@undock/api/scopes/charges/contracts/charge.interface'
import { ConfirmChargeRequest } from '@undock/api/scopes/charges/requests/confirm-charge.request'
import { CreditCardType } from '@undock/api/scopes/subscriptions/routes/subscriptions.route'

export class ChargesRoute extends ApiRoute {

    public init() {
        this.route = 'integrations/charge'
    }

    public getChargeAccountDetails(accountId: string): Promise<ChargeAccountDetailsInterface> {
        return this.get<ChargeAccountDetailsInterface>()
                   .endpoint(`details/${accountId}`)
    }

    public getDashboardLink(accountId: string): Promise<string> {
        return this.get<string>()
                   .endpoint(`dashboard/${accountId}`)
    }

    public initializeBookingCharge(request: InitializeBookingChargeRequest): Promise<ChargeInterface> {
        return this.post<ChargeInterface>()
                   .withData(request)
                   .endpoint(`init/booking`)
    }

    public confirmCharge(request: ConfirmChargeRequest): Promise<ChargeInterface> {
        return this.post<ChargeInterface>()
                   .withData(request)
                   .endpoint(`confirm`)
    }

    public getChargeAccountGroupByIntegrationClientId(clientId: string): Promise<PartnerChargeAccountInterface> {
        return this.get<PartnerChargeAccountInterface>()
                   .endpoint(`groups/${clientId}`)
    }

    public getActivityByIntegrationClientId(clientId: string): Promise<ChargeInterface[]> {
        return this.get<ChargeInterface[]>()
                   .endpoint(`groups/activity/${clientId}`)
    }
}

export interface BankAccountDetails {
    id?: string
    last4: string
    expMonth?: number
    expYear?: number
    brand?: CreditCardType | string
    isDefault?: boolean
}


