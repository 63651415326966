import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
} from '@angular/core'

import { ProfilesProvider } from '@undock/user'
import { DockParticipantStatus } from '@undock/dock/meet/models/dock/dock-participant.model'
import { TimelineEventParticipant } from '@undock/api/scopes/time/contracts/timeline-event'
import { ParticipantsPreviewComponent } from '../participants-preview.component'


@Component({
    selector: 'app-participants-popup',
    templateUrl: 'participants-popup.component.html',
    styleUrls: ['participants-popup.component.scss'],
})
// TODO: Move away from layout module
export class ParticipantsPopupComponent extends ParticipantsPreviewComponent {
    @Output() onMouseLeave = new EventEmitter<boolean>()

    public constructor(
        public profilesProvider: ProfilesProvider,
        private ref: ElementRef,
    ) {
        super(profilesProvider)
    }

    @HostListener('mouseleave', ['$event'])
    onMouseleave() {
        // hack to trigger mose leave event "for good"
        this.onMouseLeave.emit(true)
    }

    @HostListener('document:mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        let boundaries = this.ref.nativeElement.getBoundingClientRect()

        if (boundaries.width == 0 && boundaries.height == 0) {
            // hidden menu don't have size so we can skip next steps
            return
        }

        boundaries.y -= 60 // start boundaries 60px higher as most of thr time menu pops up lower
        boundaries.height = boundaries.height > 380 ? 380 : boundaries.height // set original height to max height of menu if internal items expand rect height
        boundaries.height += 120 // height hack to cover area of +60px on top and bottom of menu when you just hover over trigger

        if (this.mouseLeftBoundaries(event.x, event.y, boundaries)) {
            this.onMouseLeave.emit(true)
        }
    }

    private mouseLeftBoundaries(x, y, rect) {
        return !(x > rect.x && x < rect.x + rect.width && y > rect.y && y < rect.y + rect.height)
    }

    public isParticipantDenied(participant: TimelineEventParticipant): boolean {
        return participant.status ? (
            [DockParticipantStatus.Denied, DockParticipantStatus.Deleted].includes(participant.status)
        ) : participant.denied
    }

    public isParticipantAccepted(participant: TimelineEventParticipant): boolean {
        return participant.status ? (
            participant.status === DockParticipantStatus.Accepted
        ) : participant.accepted
    }

    public isParticipantDidntRespond(participant: TimelineEventParticipant): boolean {
        return participant.status ? (
            participant.status === DockParticipantStatus.Pending
        ) : participant.pending
    }

    public isParticipantTentativelyAccepted(participant: TimelineEventParticipant): boolean {
        return participant.status ? (
            participant.status === DockParticipantStatus.Tentative
        ) : participant.tentative
    }
}
