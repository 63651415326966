import { Injectable } from '@angular/core'
import firebase from 'firebase/app'
import { CurrentUser } from '@undock/session'


@Injectable()
export class AclManager {

    public constructor(
        protected currentUser: CurrentUser,
    ) {}

    public async checkPermission(
        resourceName: string, resourceId: string, permission: string,
    ) {
        const resource = await firebase.firestore()
                                       .doc(this.getAclResourceRef(resourceName, resourceId))
                                       .get()

        if (!resource.exists) {
            console.warn(`Resource ${resourceName}[${resourceId}] does not exist`)

            return false
        }

        const user = await this.currentUser.data

        /**
         * Collecting permissions for both id and firebase id to prevent errors
         */
        const permissions = [
            ...(resource.data()[`uid_${user._id}`] ?? []),
            ...(resource.data()[`uid_${user.firebaseId}`] ?? []),
        ]

        return permissions.includes(permission)
    }

    protected getAclResourceRef(resourceName: string, resourceId: string): string {
        return `acl/${resourceName}/resource/${resourceId}`
    }
}
