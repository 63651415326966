import {
    Component,
    ChangeDetectionStrategy,
    Optional,
    Inject,
    Input,
} from '@angular/core'
import { Router } from '@angular/router'

import moment from 'moment'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'

import { CalendarEvent } from 'angular-calendar'
import { Api } from '@undock/api'
import {
    DashboardCalendarViewModel,
} from '@undock/dashboard/view-models'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'
import { MeetingsManager } from '@undock/dock/meet/services/meetings.manager'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { CalendarGridViewModel } from '@undock/common/calendar-grid/view-models/calendar-grid.view-model'
import { AbstractEditEventPageComponent } from '@undock/dock/meet/ui/components/event-form/abstract-edit-event-page.component'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'


@UseKeyboardShortcuts()
@Component({
    selector: 'app-time-search-new-event',
    templateUrl: 'time-search-new-event.component.html',
    styleUrls: ['time-search-new-event.component.scss'],
    providers: [
        EventFormStateModel,
        AvailabilityProvider,
        AvailabilityViewModel,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSearchNewEventComponent extends AbstractEditEventPageComponent {

    public readonly state = this.eventFormStateModel.state

    @Input() initialTitle$: ValueSubject<string> | ReactiveStream<string>

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected router: Router,
        protected meetingsManager: MeetingsManager,
        protected snackbarManager: SnackbarManager,
        protected eventFormStateModel: EventFormStateModel,
        protected confirmPopupService: ConfirmPopupService,
        protected availabilityProvider: AvailabilityProvider,
        protected availabilityViewModel: AvailabilityViewModel,
        /**
         * Optional dashboard timeline / calendar page providers
         */
        @Optional()
        @Inject(CalendarGridViewModel)
        protected calendaringGridViewModel: DashboardCalendarViewModel,
    ) {
        super()
    }

    public async ngOnInit() {
        // To close form automatically
        this.onSubmit.subscribe(() => this.onClose.next())

        this.isLoading$.next(true)
        await Promise.all([
            this.initAvailability(),
            this.initCalendarGrid(),
            this.initFormStateModel(),
        ])
        this.isLoading$.next(false)
    }

    @KeyboardShortcut('Esc')
    public async close() {
        return super.close()
    }

    public async openStandaloneEditPage() {
        const originalEventData = await this.state.originalEventDataStream
        if (await this.state.isDraftModeStream) {
            /**
             * Save all changes for draft meeting
             */
            const data = await this.eventFormStateModel.getUpdatedMeetingData()
            await this.api.meet.meetings.updateDraftMeeting(originalEventData._id, data)
        } else {
            /**
             * TODO: Ask to reset all changes before leaving the page
             */
        }
        return this.router.navigate(['meet', 'edit-event', originalEventData.dockKey])
    }

    protected async initAvailability() {
        if (this.availabilityProvider && this.availabilityViewModel) {
            await super.initAvailability()
        }
    }

    protected async initCalendarGrid() {
        if (this.calendaringGridViewModel) {
            await super.initCalendarGrid()
        }
    }

    protected async initFormStateModel(data?: EditMeetingData) {
        let meetingData = data ?? await this.api.meet.meetings.createDraftMeeting({
            createDraftDock: false,
            initialProperties: {
                title: this.initialTitle$ ? await this.initialTitle$ : '',
            },
        })
        await super.initFormStateModel(meetingData)
    }

    protected availabilitySlotToCalendarGridEvent(slot: AvailabilitySlot) {
        const start = moment(slot.timeStamp)
        const gridEvent = super.availabilitySlotToCalendarGridEvent(slot)
        gridEvent.title = start.format('h:mmA')
        // Custom click handler called in the calendar grid component
        gridEvent.meta['click'] = async () => {
            const [schedule, duration] = await Promise.all([
                this.state.eventScheduleStream,
                this.state.durationStream,
            ])
            this.eventFormStateModel.setEventSchedule({
                ...schedule,
                start: new Date(slot.timeStamp),
                end: moment(slot.timeStamp).add(duration, 'minutes').toDate(),
            })
        }
        return gridEvent
    }
}

