import {
    Injectable,
    Type,
} from '@angular/core'

import { AbstractEvent } from '@undock/core/contracts/event/abstract-event'
import { AbstractEventListener } from '@undock/core/contracts/event/abstract-event-listener'


@Injectable({
    providedIn: 'root',
})
export class AppEventsDispatcher {

    protected listeners: Record<string, AbstractEventListener[]> = {}

    public register(event: Type<AbstractEvent>, listener: AbstractEventListener) {
        if (!this.listeners[event.name]) {
            this.listeners[event.name] = []
        }

        const alreadyRegistered = this.listeners[event.name].find(
            item => listener.constructor['LISTENER_TYPE_ID'] === item.constructor['LISTENER_TYPE_ID'],
        )

        if (!alreadyRegistered) {
            this.listeners[event.name].push(listener)

            this.listeners[event.name].sort(
                (a, b) => a.sortOrder - b.sortOrder,
            )
        }
    }

    public async dispatch(event: AbstractEvent): Promise<void> {
        if (Array.isArray(this.listeners[event.constructor.name])) {
            for (let listener of this.listeners[event.constructor.name]) {
                try {
                    await listener.handle(event)
                } catch (error) {
                    console.warn(`[EventsManager::ERROR] ${event.constructor.name}`, error)
                }
            }
        }
    }
}
