import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'

import { AbstractInput } from '../abstract-input/abstract-input'


@Component({
    selector: 'app-url-input',
    template: `
        <input class="url-input" #urlInput placeholder="{{placeholder}}"
               [(ngModel)]="url" (blur)="tryEmitValue(urlInput.value)" (keyup.enter)="urlInput.blur()"
               [style.max-width]="maxWidth" [style.width]="width" [style.color]="color" />
    `,
    styleUrls: [`url-input.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlInputComponent implements AbstractInput, OnInit, OnChanges {

    private initialUrl: string

    @Input() placeholder: string = 'Enter url...'
    @Input() maxWidth: string = '600px'
    @Input() width: string = '100vw'
    @Input() color: string = '#121114'

    @Input() url: string
    @Output() urlChange = new EventEmitter<string>()
    @Output() invalidUrl = new EventEmitter<string>()

    public ngOnInit() {
        this.initialUrl = this.url
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes.url) {
                if (changes.url.currentValue && this.verifyValue(changes.url.currentValue)) {
                    this.url = this.formatInput(changes.url.currentValue)
                } else {
                    this.invalidUrl.emit(changes.url.currentValue)
                    this.url = ''
                }
            }
        }
    }

    public tryEmitValue(value: string) {

        if (this.verifyValue(value)) {
            let v = this.formatOutput(value)
            this.urlChange.emit(v)
            this.url = v
            this.initialUrl = v
        } else {
            this.invalidUrl.emit(value)
            this.url = this.initialUrl
        }
    }

    public formatInput(input: string): string {
        return input
    }

    public formatOutput(value: string): string {
        if (value === '' || value.startsWith('http://') || value.startsWith('https://')) {
            return value
        } else {
            return `https://${value}`
        }
    }

    public verifyValue(value: string): boolean {
        return true
    }
}
