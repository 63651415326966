import { Pipe } from '@angular/core'

@Pipe({
    name: 'avatarImage', pure: true
})
export class AvatarImagePipe {

    public transform(imageUrl: string) {
        /**
         * Should display new placeholder for default profile image
         */
        if ( !imageUrl
            || imageUrl.includes('profile-default.png')
            || imageUrl.includes('placeholders/user.png')
        ) {
            return '/assets/img/placeholders/profile.png'
        }

        return imageUrl
    }
}
