import {
    Directive,
} from '@angular/core'
import { Router } from '@angular/router'

import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'

import { AuthManager } from '@undock/auth'
import {
    pairwise,
    takeUntil,
} from 'rxjs/operators'


@Directive({
    selector: '[appRefreshOnAuthChange]',
})
export class RefreshOnAuthChangeDirective {

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        private router: Router,
        private authManager: AuthManager,
    ) {}

    public ngOnInit () {
        this.authManager.authUserStream.pipe(
            pairwise(),
            takeUntil(this.destroyedEvent),
        ).subscribe(([prev, next]) => {
            if (!prev || !next) {
                /**
                 * If state changed from logged-in to logged out or reverse
                 */
                if (prev !== next) {
                    return this.doRefresh()
                }
            } else {
                /**
                 * If state logged-in state dont changed, but changed user
                 */
                if (prev.uid !== next.uid) {
                    return this.doRefresh()
                }
            }
        })
    }

    protected doRefresh() {
        window.location.href = `${window.location.href}`
    }
}
