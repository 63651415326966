import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Optional,
    Output,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    combineLatest,
    Observable,
} from 'rxjs'
import {
    map,
    takeUntil,
} from 'rxjs/operators'

import { Memoize } from '@undock/core'
import { UserData } from '@undock/user'
import {
    PARTICIPANTS_PROVIDER,
    ParticipantsProviderInterface,
} from '@undock/dock/meet/contracts/ui-providers/participants.provider'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'
import { ParticipantSelectorService } from '@undock/dock/meet/ui/components/participant-selector/providers/participant-selector.service'


@Component({
    selector: 'app-meet-participant-selector-trigger',
    templateUrl: 'participant-selector-trigger.component.html',
    styleUrls: ['participant-selector-trigger.component.scss'],
})
export class ParticipantSelectorTriggerComponent {

    @Input() title: string = 'Assign'
    @Input() isEditMode: boolean = true
    @Input() isConfMode: boolean = false

    @Output() onSelected = new EventEmitter<UserData>()

    @CompleteOnDestroy()
    private selectedIdsSubject = new ValueSubject<string[]>([])

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        public profilesProvider: ProfilesProvider,

        @Inject(PARTICIPANTS_PROVIDER)
        private participantsProvider: ParticipantsProviderInterface,

        @Optional()
        private participantSelectorService: ParticipantSelectorService,
    ) {
        if (!this.participantSelectorService) {
            console.warn(`ParticipantSelectorService is required for ParticipantSelectorTrigger component`)
            console.warn(`Please add 'participant-selector-host' directive for parent component container element to provide it.`)
            /**
             * Throw an error if a ParticipantSelectorComponent is used without a required provider
             */
            throw new Error(`ParticipantSelectorService is missing in the component context`)
        }
    }

    @Input()
    public set selectedIds(value: string[]) {
        this.selectedIdsSubject.next(value)
    }

    @Memoize()
    public get selectedParticipantStream(): Observable<UserData> {
        return combineLatest([
            this.selectedIdsSubject,
            this.participantsProvider.participantsStream,
        ]).pipe(
            takeUntil(this.destroyedEvent),
            map(sources => {
                let [selectedIds, participants] = sources

                return selectedIds.length > 0 ? participants.find(
                    userData => selectedIds.includes(userData.id)
                ) : null
            }),
        )
    }

    public async openParticipantSelector() {
        if (this.isEditMode) {
            await this.participantSelectorService
                      .openParticipantsSelector({
                          title: this.title,
                          onSelectedEmitter: this.onSelected,
                          selectedIdsStream: this.selectedIdsSubject.asStream(),
                      })
        }
    }
}
