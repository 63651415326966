import { SyncedCalendar } from '@undock/integrations'

import {
    UserAvailableSlotDayMap,
    UserInterface,
    UserProfileData,
    UserSettingsInterface,
} from '@undock/api/scopes/user/contracts/user.interface'

/**
 * Exports from API contract for domains
 */
export {
    UserInterface,
    UserProfileData,
    UserTimeZoneMode,

    /**
     * User Settings exports
     */
    UserConferenceLinkType,
    UserAvailabilityBuffer,
    UserPreferredTimeWindow,
    UserAvailableMeetingLength,
    UserMeetingLimitPreferenceFrequency,
    UserSettingsInterface as UserSettings,

    /**
     * Availability exports
     */
    UserAvailableSlotDay,
    UserAvailableSlotDayMap,
    UserAvailableSlotPreferences,
} from '@undock/api/scopes/user/contracts/user.interface'


/**
 * User class corresponds to actual MongoDB schema
 */
export class User implements UserInterface {

    public _id: string

    /** @deprecated */
    public key?: string
    /** @deprecated */
    public shortId?: number

    public firebaseId: string

    /**
     * Guest is means that the user is partially accessed
     * @deprecated
     */
    public isGuest?: boolean

    public createdDate: Date


    public email: string
    public username: string
    public password: string

    public phoneNumberFull: string

    public firstname: string
    public lastname: string
    public displayName: string
    public imageUrl: string

    public profileUrl: string

    public chargeAccountId: string
    public subscriptionAccountId: string

    public profile: UserProfileData

    public neverLoggedIn: boolean

    public lastTimeZone: string
    public lastTimeZoneCity: string

    /**
     * PLEASE DO NOT USE THIS PROPERTY
     *
     * @deprecated
     */
    public calendarInitialized?: boolean
    /**
     * PLEASE DO NOT USE THIS PROPERTY
     *
     * @deprecated
     */
    public syncedCalendars?: SyncedCalendar[]

    public settings: UserSettingsInterface

    /**
     * These are the broad types of onboarding that the User goes through
     */
    onboardingComplete: boolean
    onboardingCompletedStep?: string
}


export const defaultSlots: UserAvailableSlotDayMap = {
    '9': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
    '10': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
    '11': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
    '12': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
    '13': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
    '14': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
    '15': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
    '16': [{ available: true, allowInPerson: true }, { available: true, allowInPerson: true }],
}

/** @deprecated */
export const defaultUserSettings: UserSettingsInterface = {

    meetingIntervalPreference: {
        morning: false,
        lateMorning: true,
        afternoon: false,
        lateAfternoon: false,
    },

    availabilityIntervals: {
        15: true,
        30: true,
        60: true,
        90: true,
        120: true,
    },

    availabilityMaxSlots: 1,
    availabilityBuffer: 0,
    availabilityStart: 9,
    availabilityStartMins: 0,
    availabilityEnd: 17,
    availabilityEndMins: 0,
    businessHoursStart: 9,
    businessHoursStartMins: 0,
    businessHoursEnd: 17,
    businessHoursEndMins: 0,
    minSlotHour: 9,
    maxSlotHour: 17,

    availableSlots: {
        sunday: {
            enabled: false,
            count: 0,
            map: {},
        },
        monday: {
            enabled: true,
            count: 16,
            map: defaultSlots,
        },
        tuesday: {
            enabled: true,
            count: 16,
            map: defaultSlots,
        },
        wednesday: {
            enabled: true,
            count: 16,
            map: defaultSlots,
        },
        thursday: {
            enabled: true,
            count: 16,
            map: defaultSlots,
        },
        friday: {
            enabled: true,
            count: 16,
            map: defaultSlots,
        },
        saturday: {
            enabled: false,
            count: 0,
            map: {},
        },
    },

    timeZoneMode: 'auto',
    minScheduleNotice: 1,
    defaultDuration: null,
    flexible: false,
    timeBetweenEvents: 0,
    truncateMeetings: false,
    privateProfile: false,
    limitAvailability: false,
    availabilitySlotLimit: 4,

    meetingLimit: {
        interval: 24,
        frequency: 'none',
    },

    // Defaults for meetings
    defaultMeetingContactInfo: {
        phoneNumberFull: '',
        meetingLink: '',
        useGoogleLink: false,
    },

    conferenceLinkPreference: 'undock',

    notifications: {
        calendarEventCreated: false,
        eventBookedOnProfile: true,
        ownProposalConfirmed: true,
        calendarEventRescheduled: true,
        calendarEventReminder: false,
        proposalExpiredReminder: true,
    }
}
