import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { TimezoneData } from '@undock/time/availability/contracts'
import { TimeZoneHelper } from '@undock/time/availability/services/timezone.helper'

/**
 * @deprecated Use time zone selector v2
 */
@Component({
    selector: 'app-time-time-zone-selector',
    templateUrl: 'time-zone-selector.component.html',
    styleUrls: ['time-zone-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeZoneSelectorComponent {

    public readonly timeZoneCompareWithFn: (tz1: TimezoneData, tz2: TimezoneData) => boolean = (tz1, tz2) => tz1?.zone === tz2?.zone
    public readonly timezoneLabelFn: (tz: TimezoneData) => string = (tz?: TimezoneData) => tz ? `${tz.label}` + (this.showCityLabel ? `, ${tz.suggestedCity}` : '') : ''

    @CompleteOnDestroy()
    public selectedTimezoneDataSubject = new ValueSubject<TimezoneData>(null)

    @CompleteOnDestroy()
    public browserTimezoneDataSubject = new ValueSubject<TimezoneData>(null)

    @Input('selectedTimeZone') set selectedTimeZone(value: TimezoneData) {
        this.selectedTimezoneDataSubject.next(value)
    }

    @Input('browserTimeZone') set browserTimeZone(value: TimezoneData) {
        this.browserTimezoneDataSubject.next(value)
    }

    @Input() defaultSelect = false

    @Input() showCityLabel = true

    @Input() selectPanelClass: string

    @Output() onSelected = new EventEmitter<TimezoneData>()

    constructor(
        public timeZoneHelper: TimeZoneHelper,
    ) { }

    public onTimeZoneChange(timezone: TimezoneData) {
        this.onSelected.emit(timezone)
    }

}
