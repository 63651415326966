import { ApiRoute } from '@undock/api'
import { ApiContext } from '@undock/api/contracts/api.context'


export class AnalyticsScope extends ApiRoute {

    public constructor(
        context: ApiContext,
    ) {
        super(context)
    }

    public init() {
        this.route = 'analytics'
    }

    public track(data: TrackAnalyticsData) {
        return this.post()
                   .withData(data)
                   .endpoint(`track`)
    }
}



export interface TrackAnalyticsData {
    event: AnalyticsAction
    source: AnalyticsSource
    feature?: AnalyticsTrackedFeature
    component?: AnalyticsTrackedComponent
    properties?: any
}

/**
 * This corresponds to the list of "Kingdoms" in the Data Glossary
 */
export enum AnalyticsAction {
    MeetingProposed = 'Meeting Proposed',
}

/**
 * This corresponds to the list of "Phyla" in the Data Glossary
 */
export enum AnalyticsSource {
    WebApp  = 'Web App',
    ChromeExtension = 'Chrome Extension',
    Mobile = 'Mobile',
    Email = 'Email',
    Calendar = 'Calendar',
    Direct = 'Direct'
}

/**
 * This corresponds to the list of "Classes" in the Data Glossary
 */
export enum AnalyticsTrackedFeature {
    NewEvent = 'New Event',
    InstantRoom = 'Instant Room',
    Proposal = 'Proposal',
    Schedule = 'Schedule',
    NLP = 'NLP',
    CalendarScan = 'Calendar Scan',
    InsertTimes = 'Insert Times',
    BookingLink = 'Booking Link',
    DuplicateEvent = 'Duplicate Event',
    Reschedule = 'Reschedule',
    Request = 'Request', // Event Request on the profile
    OfficeHours = 'Office Hours', // Individual Broadcast
}

/**
 * This corresponds to the list of "Families" in the Data Glossary
 */
export enum AnalyticsTrackedComponent {
    ChromeExtensionSidebar = 'Sidebar',
    ChromeExtensionSchedulingWindow = 'Scheduling Window',
    ChromeExtensionEmailBody = 'Email',
    MobileKeyboard = 'Keyboard',
    MobileApp = 'App',
    Email = 'Email',

    // ????????????????????
    Instant = 'Instant',
    Scheduled = 'Scheduled',
}
