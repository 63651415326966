import { Injectable } from '@angular/core'
import { AudioRecorderService } from '@undock/core/services/audio-recorder.service'
import { Api } from '@undock/api'
import { Memoize } from '@undock/core'
import { ReactiveStream } from '@typeheim/fire-rx'


@Injectable()
export class VoicePromptService {

    constructor(
        private api: Api,
        private audioRecorder: AudioRecorderService
    ) {}

    @Memoize()
    public get isPromptRecordingInProgressStream(): ReactiveStream<boolean> {
        return this.audioRecorder.isRecordingRunningStream
    }

    public async startRecording() {
        if (!(await this.audioRecorder.isRecordingRunningStream)) {
            return this.audioRecorder.startRecording()
        }
    }

    public async transcribeRecording(): Promise<string> {
        if (await this.audioRecorder.isRecordingRunningStream) {
            let blob = await this.audioRecorder.stopRecording()
            if (blob) {
                const fileName = encodeURIComponent('audio_' + new Date().getTime() + '.mp3')
                const audioFile = new File([blob], fileName, { type: "audio/mpeg" })

                // TODO: Move this
                const data = new FormData()
                data.append('file', audioFile)

                return this.api.nlp.speechToText.transcribeAudio(data)
            }
        }
    }
}
