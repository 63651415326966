import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-command-mark-available',
    templateUrl: 'command-mark-available.component.html',
    styleUrls: ['command-mark-available.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandMarkAvailableIconComponent {
    @Input() height: number|string = '56'
    @Input() width: number|string = '56'
    @Input() color: string = '#2B2733'
    @Input() borderColor: string = '#2B2733'
    @Input() bold: boolean = false
}
