import {
    inject,
    Injectable,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import {
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import { Account } from '@undock/user'
import { Memoize } from '@undock/core'
import { CurrentUser, injectCollection } from '@undock/session'
import { SavedLocation } from '@undock/locations/models/saved-location.model'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'

@Injectable()
export class LocationsManager {

    protected readonly AccountCollection = injectCollection(Account)
    protected readonly ormOnFireContext = inject(OrmOnFireContext)

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private readonly currentUser: CurrentUser,
    ) {}

    @Memoize()
    public get savedLocationsStream(): ReactiveStream<SavedLocation[]> {
        return new ReactiveStream(
            this.currentUser.uidStream.pipe(
                distinctUntilChanged(),
                switchMap(uid => {
                    const collection = this.ormOnFireContext.createNestedCollection(
                        SavedLocation,
                        this.AccountCollection.one(uid),
                    )
                    return collection.all()
                                     .stream()
                                     .emitUntil(this.destroyedEvent)
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }


    public async createLocation(name: string, address: string, url: string): Promise<SavedLocation> {
        const account = await this.currentUser.accountStream
        const entity = new SavedLocation(name, address, url)
        const collection = this.ormOnFireContext.createNestedCollection(
            SavedLocation,
            this.AccountCollection.one(account.id),
        )
        await collection.save(entity)

        return entity
    }

    public async deleteLocation(entity: SavedLocation): Promise<void> {
        const account = await this.currentUser.accountStream
        const collection = this.ormOnFireContext.createNestedCollection(
            SavedLocation,
            this.AccountCollection.one(account.id),
        )
        await collection.remove(entity)
    }

    public async updateLocation(entity: SavedLocation): Promise<void> {
        const account = await this.currentUser.accountStream
        const collection = this.ormOnFireContext.createNestedCollection(
            SavedLocation,
            this.AccountCollection.one(account.id),
        )
        await collection.save(entity)
    }
}
