<section class="success-overlay" *ngIf=" {
            profile: targetProfileStream | stream
        } as view">
    <div class="background-wrapper">
        <app-default-header></app-default-header>

        <main class="success-confirmation-info">
            <ng-container [ngTemplateOutlet]="guestSection"></ng-container>
        </main>
    </div>

    <ng-template #guestSection>
        <!-- BOTTOM -->
        <div class="_bottom-wrapper">
            <div class="profile-confirm-image">
                <div class="desktop" *ngIf="animation$ | stream as animation">
                    <ng-lottie width="438px" height="374px" [options]="animation"></ng-lottie>
                </div>

                <img class="mobile" src="/projects/web/assets/img/extension-preview-mobile.png" alt="ext">
            </div>

            <div class="success-confirmation-info__bottom-side">
                <h3>
                    <div class="avatar">
                        <img src="{{ view.profile?.imageUrl }}" alt=""/>
                    </div>

                    <span>
                        Undock helps <b>{{ view.profile?.firstname }}</b> find time to meet with anyone.
                    </span>
                </h3>

                <p class="subtitle">
                    See suggestions for when you and your network prefer to meet — wherever you’re typing.
                </p>

                <a class="profile-confirm-join-undock-button primary" (click)="navigateToSignup(true)">
                    Join – It’s Free
                </a>
            </div>
        </div>
    </ng-template><!-- #guestSection -->
</section>
