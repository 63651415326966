import {
    Pipe,
    PipeTransform,
} from '@angular/core'

import { Config } from '@undock/core'


@Pipe({
    name: 'imgThumbnail', pure: true,
})
export class ImgThumbnailPipe implements PipeTransform {

    /**
     * Cloud function listens for storage changes and will automatically
     * generate thumbnail images with `${name}${postfix}.${ext}` pattern
     */
    private readonly thumbnailPostfix = '_124x124'

    public constructor(
        private config: Config,
    ) {}

    public transform(url: string): string {

        if (!url) {
            return null
        }

        if (this.isThumbnailImageAvailable(url)) {
            /**
             * Thumbnails are generated only for images from project storage
             */
            return this.isAlreadyThumbnail(url) ? url : this.getThumbnailUrl(url)
        }

        return url
    }

    protected getThumbnailUrl(url: string): string {
        let segments = url.split('.')
        let leftSide = segments.slice(0, segments.length - 1).join('.')
        let extension = segments[segments.length - 1]

        return `${leftSide}${this.thumbnailPostfix}.${extension}`
    }

    protected isAlreadyThumbnail(url: string): boolean {
        return Boolean(url.match(this.thumbnailPostfix))
    }

    protected isThumbnailImageAvailable(url: string): boolean {
        return Boolean(url.match(this.config.firebaseConfig.storageBucket))
    }
}