import {
    Collection,
    CreatedDateField,
    DocRef,
    Entity,
    EntityFilter,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import { StorageFile } from '@undock/core/models/storage-file.model'
import { ShouldInitialize } from '@undock/core/contracts/model/should-initialize.interface'
import { HasAttachments } from '@undock/core'
import {
    OrmOnFireContext,
    SafeReference,
} from '@undock/session/models/orm-on-fire.context'

export class AttachmentScope {
    public static initialized(filter: EntityFilter<Attachment>) {
        return filter.isInitialized.equal(true)
    }
}

export const ATTACHMENT_COLLECTION_NAME = 'attachment'

@Entity({
    collection: ATTACHMENT_COLLECTION_NAME,
})
export class Attachment implements ShouldInitialize {
    @ID() id: string

    /**
     * Attachment owner entity id
     */
    @Field() ownerId: string

    /**
     * Attachment owner Type
     */
    @Field() ownerType: string

    @Field() name: string
    @Field() size: number
    @Field() type: string

    @Field() tags: string[] = []

    @Field() isInitialized: boolean

    @Field() isCopy: boolean
    @Field() originalAttachmentId: string
    @DocRef(Attachment) originalAttachment: SafeReference<Attachment>

    @Field() fileId: string
    @DocRef(StorageFile) file: SafeReference<StorageFile>

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date
}

export const getAttachments = (collection: Collection<Attachment>) => {
    return collection.all()
                     .filter(AttachmentScope.initialized)
                     .get()
}

export const streamAttachments = (collection: Collection<Attachment>) => {
    return collection.all()
                     .filter(AttachmentScope.initialized)
                     .stream()
}

export const createAttachmentsCollection = (
    entity: HasAttachments,
    ormOnFireContext: OrmOnFireContext,
) => {
    return ormOnFireContext.createNestedCollection(Attachment, entity)
}
