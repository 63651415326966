import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core'

@Component({
  selector: 'app-icon-global',
  templateUrl: 'global-icon.component.html',
  styleUrls: ['global-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalIconComponent {
  @Input() width: number|string = '20'
  @Input() height: number|string = '20'
  @Input() color: number|string = '#2B2733'
}
