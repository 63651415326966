import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import { AuthModule } from '@undock/auth/auth.module'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'

import {
    LoginPage,
    LogoutPage,
    ResetPasswordPage,
    SignupPage,
    SignInPage,
    EmailIsNotVerifiedPageComponent,
    OAuthSignInPage,
    VerifyEmailPageComponent,
} from '@undock/auth-ui/components/pages'
import { LayoutModule } from '@undock/common/layout/layout.module'
import { ExtensionSignInPage } from '@undock/auth-ui/components/pages/extension-signin/extension-sign-in-page.component'
import { InviteSignUpPage } from '@undock/auth-ui/components/pages/invite-sign-up/invite-sign-up.page'


@NgModule({
    imports: [
        AuthModule,
        UiKitModule,
        FormsModule,
        RouterModule,
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        LayoutModule
    ],
    exports: [
        LoginPage,
        SignupPage,
        SignInPage,
        ExtensionSignInPage,
        LogoutPage,
        OAuthSignInPage,
        InviteSignUpPage,
        VerifyEmailPageComponent,
        EmailIsNotVerifiedPageComponent,
        ResetPasswordPage,
    ],
    declarations: [
        LoginPage,
        SignupPage,
        SignInPage,
        ExtensionSignInPage,
        LogoutPage,
        OAuthSignInPage,
        InviteSignUpPage,
        VerifyEmailPageComponent,
        EmailIsNotVerifiedPageComponent,
        ResetPasswordPage,
    ],
})
export class AuthUiModule {}
