import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core'


@Directive({
    selector: '[udRepeat]',

})
export class RepeatDirective {

    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    @Input()
    public set udRepeat(nTimes: number) {
        this.viewContainer.clear()
        for (let i = 0; i < nTimes; i++) {
            this.viewContainer.createEmbeddedView(this.templateRef)
        }
    }
}
