import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'

import { CoreModule } from '@undock/core/core.module'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'

import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'

import { DataStoreProvider } from '@undock/data-store/data.store.provider'

import {
    ChangelogWidgetComponent,
    ControlsPanelComponent,
    DefaultHeaderComponent,
    DockPreviewTileItemViewComponent,
    DockPreviewTileItemViewSkeletonComponent,
    HeaderNewMeetingButtonComponent,
    MainLayoutComponent,
    MeetEditButtonComponent,
    NavigationPanelComponent,
    NewMeetingButtonComponent,
    ParticipantsPopupComponent,
    ParticipantsPreviewComponent,
    SidebarOrganizationsComponent,
    TagsSelectorComponent,
    TagsSelectorConfigurableComponent,
    TrustedByFooterComponent,
} from '@undock/common/layout/components'
import { SidebarRouterLinkDirective } from '@undock/common/layout/directives/sidebar-router-link.directive'
import {
    LayoutOrganizationInvitesComponent,
} from '@undock/common/layout/components/organizations/organization-invites.component'


@NgModule({
    imports: [
        CoreModule,
        UiKitModule,

        CommonModule,
        RouterModule,

        MatMenuModule,
        DataStoreProvider,
    ],
    declarations: [
        MainLayoutComponent,
        ControlsPanelComponent,
        NavigationPanelComponent,
        ChangelogWidgetComponent,
        NewMeetingButtonComponent,
        TrustedByFooterComponent,
        HeaderNewMeetingButtonComponent,
        TagsSelectorComponent,
        TagsSelectorConfigurableComponent,
        SidebarRouterLinkDirective,
        DockPreviewTileItemViewComponent,
        DockPreviewTileItemViewSkeletonComponent,
        ParticipantsPreviewComponent,
        ParticipantsPopupComponent,
        MeetEditButtonComponent,
        DefaultHeaderComponent,
        SidebarOrganizationsComponent,
        LayoutOrganizationInvitesComponent,
    ],
    exports: [
        MainLayoutComponent,
        ControlsPanelComponent,
        NavigationPanelComponent,
        NewMeetingButtonComponent,
        TrustedByFooterComponent,
        HeaderNewMeetingButtonComponent,
        TagsSelectorComponent,
        TagsSelectorConfigurableComponent,
        DockPreviewTileItemViewComponent,
        DockPreviewTileItemViewSkeletonComponent,
        ParticipantsPreviewComponent,
        ParticipantsPopupComponent,
        MeetEditButtonComponent,
        DefaultHeaderComponent,
    ],
})
export class LayoutModule {}
