import {
    Injectable,
    InjectionToken,
    Injector,
    Type,
} from '@angular/core'
import {
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay'
import {
    ComponentPortal,
} from '@angular/cdk/portal'

import { Subscription } from 'rxjs'

import {
    AbstractConfirmPopup,
    ConfirmPopupComponent,
    ConfirmPopupConfig,
} from '../ui/components/confirm-popup/confirm-popup.component'


export const CONFIRM_POPUP_CONTEXT = new InjectionToken<any>('CONFIRM_POPUP_CONTEXT')

@Injectable()
export class ConfirmPopupService {

    protected ckdOverlayRef: OverlayRef
    protected closeSubscription: Subscription
    protected popupComponentClass: Type<AbstractConfirmPopup> = ConfirmPopupComponent

    public constructor(
        protected readonly overlay: Overlay,
        protected readonly injector: Injector,
    ) {}

    /**
     * Returns `null` if popup been closes
     * Returns `boolean` if any of popup actions been selected
     */
    public async open(
        config: Partial<ConfirmPopupConfig>,
        popupComponentClass?: Type<AbstractConfirmPopup>,
        popupContext?: any,
    ): Promise<boolean | null> {
        const positionStrategy = this.overlay.position()
                                             .global()
                                             .centerVertically()
                                             .centerHorizontally()

        this.ckdOverlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            hasBackdrop: true,
        })

        const component = this.ckdOverlayRef.attach(
            popupComponentClass
                ? new ComponentPortal(
                    popupComponentClass,
                    null,
                    this.createContextInjector(popupContext, this.injector),
                )
                : new ComponentPortal(this.popupComponentClass)
        )

        component.instance.applyConfig(config)

        this.ckdOverlayRef.backdropClick().subscribe(
            () => this.close()
        )

        return new Promise(resolve => {
            const subscriptions = []
            const unsubscribeAll = (ss: Subscription[]) => {
                return ss.map(s => s.unsubscribe())
            }

            subscriptions.push(
                component.instance.onClosed.subscribe(result => {
                    resolve(result)
                    unsubscribeAll(subscriptions)
                    this.close()
                }),
            )
        })
    }

    public async close() {
        if (this.ckdOverlayRef) {
            this.ckdOverlayRef.dispose()
            this.ckdOverlayRef = null
        }

        if (this.closeSubscription) {
            this.closeSubscription.unsubscribe()
        }
    }

    protected createContextInjector(context: any, parentInjector: Injector): Injector {
        return Injector.create({
            providers: [
                { provide: CONFIRM_POPUP_CONTEXT, useValue: context },
            ],
            parent: parentInjector,
        })
    }
}
