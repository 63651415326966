<app-text-input-autocomplete #autocompleteMenu
                             [textInputElement]="textInputElement"
                             [menuTemplate]="menuTemplate"
                             [triggerCharacter]="triggerCharacter"
                             [openDirection]="openDirection"
                             [searchRegexp]="searchRegexp"
                             [closeMenuOnBlur]="closeMenuOnBlur"
                             [getChoiceLabel]="getChoiceLabel"
                             [selectedChoices]="selectedChoices"
                             (search)="search.emit($event)"
                             (choiceSelected)="choiceSelected.emit($event)"
                             (choiceRemoved)="choiceRemoved.emit($event)"
                             (selectedChoicesChange)="onSelectedChoicesChange($event)"
                             (menuShow)="menuShow.emit()"
                             (menuHide)="menuHide.emit()"></app-text-input-autocomplete>

<app-text-input-highlight #highlights
                          [textInputElement]="textInputElement"
                          [tags]="selectedCwis"
                          [tagCssClass]="tagCssClass"
                          (tagClick)="tagClick.emit($event)"
                          (tagMouseEnter)="tagMouseEnter.emit($event)"
                          (tagMouseLeave)="tagMouseLeave.emit($event)"></app-text-input-highlight>
