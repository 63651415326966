import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    Optional,
    Output,
} from '@angular/core'
import { Router } from '@angular/router'

import moment from 'moment'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import { Api } from '@undock/api'
import {
    DashboardCalendarViewModel,
} from '@undock/dashboard/view-models'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import { UseKeyboardShortcuts } from '@undock/hotkeys/services/keyboard-shortcuts.decorator'
import { MeetingsManager } from '@undock/dock/meet/services/meetings.manager'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { CalendarGridViewModel } from '@undock/common/calendar-grid/view-models/calendar-grid.view-model'
import { TimeSearchNewEventComponent } from '@undock/time/prompt/ui/components/new-event/time-search-new-event.component'
import {
    TimeCommandActions,
    TimeCommandBlueprintEvent,
} from '@undock/api/scopes/nlp/routes/commands.route'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import {
    clone,
    Memoize,
} from '@undock/core'
import { CurrentUser } from '@undock/session'
import { TimeCommandViewModel } from '@undock/time/prompt/states/time-command.view-model'
import {
    map,
    shareReplay,
    takeUntil,
} from 'rxjs'
import { MeetingMode } from '@undock/dock/meet'


@UseKeyboardShortcuts()
@Component({
    selector: 'app-time-command-edit-new-event',
    templateUrl: 'edit-time-command-new-event.component.html',
    styleUrls: ['edit-time-command-new-event.component.scss'],
    providers: [
        EventFormStateModel,
        AvailabilityProvider,
        AvailabilityViewModel,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTimeCommandNewEventComponent extends TimeSearchNewEventComponent {

    public readonly state = this.eventFormStateModel.state

    @Input('event') initialEvent: TimeCommandBlueprintEvent

    @Output() onEventUpdated = new EventEmitter<TimeCommandBlueprintEvent>()

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected router: Router,
        protected meetingsManager: MeetingsManager,
        protected snackbarManager: SnackbarManager,
        protected eventFormStateModel: EventFormStateModel,
        protected confirmPopupService: ConfirmPopupService,
        /**
         * Optional availability functionality
         */
        @Optional()
        protected availabilityProvider: AvailabilityProvider,
        @Optional()
        protected availabilityViewModel: AvailabilityViewModel,
        /**
         * Optional dashboard timeline / calendar page providers
         */
        @Optional()
        @Inject(CalendarGridViewModel)
        protected calendaringGridViewModel: DashboardCalendarViewModel,

        protected currentUser: CurrentUser,
        protected commandViewModel: TimeCommandViewModel
    ) {
        super(
            api, router, meetingsManager, snackbarManager, eventFormStateModel, confirmPopupService, availabilityProvider,
            availabilityViewModel, calendaringGridViewModel
        )
    }

    public async ngOnInit() {
        /**
         * To close form automatically
         */
        this.onSubmit.subscribe(() => this.onClose.next())

        this.isLoading$.next(true)
        await Promise.all([
            this.initAvailability(),
            this.initCalendarGrid(),
            this.initFormStateModel(),
        ])
        this.isLoading$.next(false)
    }

    @Memoize()
    public get relevantActionTypeStream(): ReactiveStream<TimeCommandActions> {
        return new ReactiveStream<TimeCommandActions>(this.commandViewModel.state.blueprint$.pipe(
            map(blueprint => blueprint.actions.find(
                ac => ac.events.some(e => e.iCalUId === this.initialEvent.iCalUId)
            )?.action ?? TimeCommandActions.Schedule),
            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        ))
    }

    /**
     * @override
     */
    public async close() {
        const meetingData = await this.eventFormStateModel.getUpdatedMeetingData()
        await this.handleEventFormSubmit(meetingData)

        setTimeout(() => this.onClose.next())
    }

    /**
     * @override
     */
    protected async handleEventFormSubmit(data: EditMeetingData) {
        if (await this.state.isDraftModeStream) {
            /**
             * Save all changes for draft meeting
             */
            await this.api.meet.meetings.updateDraftMeeting(data._id, data)
        }
        let initialEvent = this.initialEvent ? clone(this.initialEvent) : null
          , actionType = this.initialEvent ? await this.relevantActionTypeStream : null
        this.onEventUpdated.emit(
            initialEvent
                ? actionType === TimeCommandActions.Reschedule
                    ? {
                        ...initialEvent,
                        title: data.title,
                        reschedule: data.schedule,
                        attendees: data.attendees,
                        isEdited: true,
                        meetingData: data
                    }
                    : actionType === TimeCommandActions.Modify
                        ? {
                            ...initialEvent,
                            schedule: data.schedule,
                            attendees: data.attendees,
                            isEdited: true,
                            meetingData: data
                        }
                        : {
                            ...initialEvent,
                            title: data.title,
                            schedule: data.schedule,
                            attendees: data.attendees,
                            isEdited: true,
                            meetingData: data
                        }

                : null
        )

        this.onSubmit.next()
        this.isLoading$.next(false)
    }

    /**
     * @override
     */
    public async openStandaloneEditPage() {
       return false
    }

    protected async initFormStateModel() {
        let user = await this.currentUser.dataStream
        let initialEvent = this.initialEvent ?? null

        /**
         * If the initial event data already contains draft data, load that draft and continue editing
         */
        let meetingData = initialEvent?.meetingData?.dockKey
            ? await this.api.meet.meetings.getEditMeetingData(initialEvent.meetingData.dockKey)
            : await this.api.meet.meetings.createDraftMeeting({
                createDraftDock: false,
                initialProperties: {
                    title: initialEvent ? initialEvent.title : '',
                    schedule: initialEvent?.schedule?.start ? initialEvent.schedule : {
                        start: moment().add(1, 'hour').startOf('hour').toDate(),
                        end: moment().add(1, 'hour').endOf('hour').toDate(),
                        isAllDay: false
                    },
                    attendees: initialEvent ? initialEvent.attendees.filter(a => a.userData.email !== user.email) : undefined,
                    mode: initialEvent.location ?? undefined,
                    conferenceLinkType: initialEvent.location === MeetingMode.Video
                        ? user.settings.conferenceLinkPreference
                        : null
                },
            })

        /**
         * Action specific changes
         */
        if (initialEvent) {
            let actionType = await this.relevantActionTypeStream
            if (
                actionType === TimeCommandActions.Reschedule
                && initialEvent.reschedule.start.valueOf() !== meetingData.schedule.start.valueOf()
            ) {
                meetingData.schedule = initialEvent.reschedule
            } else if (
                actionType === TimeCommandActions.Modify
                && initialEvent.isEdited
                && !!initialEvent.meetingData
            ) {
                meetingData = {
                    ...meetingData,
                    ...initialEvent.meetingData
                }
            }
        }

        await super.initFormStateModel(meetingData)
    }

}

