import { ApiRoute } from '@undock/api/services/api.route'

export class BriefsRoute extends ApiRoute {

    public init() {
        this.route = 'nlp/time/briefs'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public async getBriefsSummary(currentDay: string) {
        return this.get<string[]>().endpoint(`day/${currentDay}`)
    }
}
