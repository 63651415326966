import { Injectable } from '@angular/core'

import { User } from '@undock/user'
import { Config } from '@undock/core'
import {
    AnalyticsTracker,
    UserAnalyticsAttributes,
} from '@undock/integrations/contracts'
import { UserAnalyticsAction } from '@undock/integrations/events'


@Injectable()
export class SegmentAnalyticsService implements AnalyticsTracker {

    protected isInitialized: boolean

    public constructor(
        private config: Config,
    ) {}

    public initialize(): void {
        if (!this.isInitialized && this.config.enableSegmentAnalytics) {
            if (this.segment) {
                console.log('[ Segment initialized ]')
                this.segment.load(this.config.segmentAnalyticsTrackingId)
                this.segment.page()
                this.isInitialized = true
            }
        }
    }

    public identify(userData: User, customUserAttributes: UserAnalyticsAttributes = {}) {
        if (this.segment && userData?.email) {

            let userAttributes: UserAnalyticsAttributes = {
                name: userData.displayName || `${userData.firstname} ${userData.lastname}`,
                email: userData.email,
                ...customUserAttributes,
            }

            this.segment.identify(userData.email, userAttributes)
        }
    }

    public track(action: UserAnalyticsAction, userData?: User, data?: any) {
        if (action) {
            if (this.segment && (this.config.segmentAnalyticsTrackingId)) {
                if (userData?.email) {
                    try {
                        let customAttributes = this.createCustomAttributes(userData)
                        this.setUserAttributeForAction(action, customAttributes)
                        this.identify(userData, customAttributes)
                    } catch (err) {
                        console.warn(`An error occured while trying to create attributes for [${userData?.displayName}].`, err)
                    }
                }

                this.segment.track(action, {
                    'User': userData?.email || 'Anonymous',
                    'Release': 'Web App',

                    ...data,
                })
            }
        }
    }

    protected createCustomAttributes(userData: User): UserAnalyticsAttributes {
        let attributes: UserAnalyticsAttributes = {
            handle: userData.profileUrl,
            'Public Profile': !userData.settings.privateProfile,
            'Onboarding Completed': userData.onboardingComplete
        }

        return attributes
    }

    protected setUserAttributeForAction(action: UserAnalyticsAction, attributes: UserAnalyticsAttributes): void {
        switch (action) {
            case UserAnalyticsAction.OnboardingCompleted:
                attributes['Onboarding Completed'] = true
                break
            case UserAnalyticsAction.MeetingJoinedFromTimeline:
                attributes['Join Meeting from Timeline'] = true
                break
            case UserAnalyticsAction.ConferenceJoined:
                attributes['Tried Undock Conference'] = true
                break
            case UserAnalyticsAction.BroadcastScheduled:
                attributes['Scheduled a Broadcast'] = true
                break
            case UserAnalyticsAction.AsyncVideoRecorded:
                attributes['Recorded Asynch Video'] = true
                break
            case UserAnalyticsAction.PrivateNoteEntered:
                attributes['Private Notes'] = true
                break
            default:
                break
        }
    }

    /**
     * @TODO: Check why TS compiler doesn't resolve SegmentAnalytics type
     */
    protected get segment(): any { // SegmentAnalytics.AnalyticsJS {
        return window['analytics'] = window['analytics'] || null
    }
}
