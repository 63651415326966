import {
    Pipe,
    PipeTransform,
} from '@angular/core'

@Pipe({ name: 'boolean', pure: true })
export class BooleanPipe implements PipeTransform {

    /**
     * Casts any value to the boolean
     */
    public transform(value: any): boolean {


        return Boolean(value)
    }
}
