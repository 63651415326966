<ng-container *ngIf="{
    searchTerm: searchTermStream | stream,
    suggestedPlans: suggestedPlansStream | stream,
    plansSearchResults: searchState.plansSearchResultsStream | stream,
    suggestedConnections: suggestedConnectionsStream | stream,
    connectionsSearchResults: searchState.connectionsSearchResultsStream | stream,
    allResultsLength: allSearchResultsLengthStream | stream,
    promptFocusState: searchState.searchInputFocusStateStream | stream,
    isLoading: isLoadingStream | stream,
    hasPartialBlueprint: hasPartialBlueprintStream | stream,
    currentView: currentTimePromptView$ | stream,
    newEvent: promptState.currentlyEditedCommandEvent$ | stream
} as view">
    <div class="time-prompt-container"
         [ngClass]="{
            '__is-focus': view.promptFocusState === 'focus',
            '__edit-view': view.currentView === TimeSearchView.EditActionBlueprint
         }"
    >
        <ng-container
            *ngIf="view.currentView !== TimeSearchView.NewEvent" [ngTemplateOutlet]="searchBarTmpl"
        ></ng-container>

        <ng-container [ngSwitch]="view.isLoading && !view.hasPartialBlueprint">
            <ng-container *ngSwitchCase="false">
                <ng-container [ngSwitch]="view.currentView">
                    <ng-container *ngSwitchCase="TimeSearchView.Search" [ngTemplateOutlet]="searchViewTmpl"></ng-container>
                    <ng-container *ngSwitchCase="TimeSearchView.NewEvent" [ngTemplateOutlet]="newEventFormTmpl"></ng-container>
                    <ng-container *ngSwitchCase="TimeSearchView.EditActionBlueprint" [ngTemplateOutlet]="editPromptActionsTmpl"></ng-container>
                    <ng-container *ngSwitchCase="TimeSearchView.PromptLimitReached" [ngTemplateOutlet]="upgradeTmpl"></ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="loadingTmpl"></ng-container>
        </ng-container>
    </div>

    <ng-template #searchBarTmpl>
        <div class="__search-bar" [class.__is-focus]="view.promptFocusState === 'focus'">
            <app-time-prompt-input #prompt [class.__is-focus]="view.promptFocusState === 'focus'"
                                   [autocompleteOpenDirection]="autocompleteOpenDirection"
                                   (onSubmitPromptPrevented)="goToPromptLimitReachedView()"
                                   (keydown.tab)="goToNewEventView()"
            ></app-time-prompt-input>

            <div class="__shortcut-bar"
                 [ngClass]="{ '__open': view.promptFocusState === 'focus' }"
            >
                <ng-container *ngIf="view.currentView !== TimeSearchView.EditActionBlueprint; else editBlueprintLabelsTmpl">
                    <div class="__shortcut __left clickable"
                         (click)="goToNewEventView(); $event.stopPropagation()"
                    >
                        <span class="__label">New event</span>
                        <div class="__key-label">Tab</div>
                    </div>

                    <div class="__shortcut __right clickable"
                         (click)="submitPrompt(); $event.stopPropagation()"
                    >
                        <span class="__label">Run</span>
                        <div class="__key-label">Enter</div>
                    </div>
                </ng-container>

                <ng-template #editBlueprintLabelsTmpl>
                    <div class="__shortcut __right">
                        <span class="__label">Clear</span>
                        <div class="__key-label" (click)="clearPrompt(); $event.stopPropagation()">Esc</div>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-template> <!-- searchBarTmpl -->

    <ng-template #loadingTmpl>
        <!-- TODO: Move to a component -->
        <div class="__loading-section">
            <ng-container *ngIf="promptState.isLoading$ | stream; else regularLoadingTmpl">
                <app-ai-loading [height]="'150px'"></app-ai-loading>
                <div class="prompt-loader">
                    <span class="__label">{{promptState.loadingStatusMessage$ | stream}}</span>
                    <span class="__sub-label">Give your calendar a few seconds to think</span>
                    <div class="__cancel-button">
                        <span class="__labels">Press</span>
                        <app-key-label [label]="'esc'"></app-key-label>
                        <span class="__labels">to cancel</span>
                    </div>
                </div>
            </ng-container>
            <ng-template #regularLoadingTmpl>
                <app-loading [height]="'150px'"></app-loading>
            </ng-template>
        </div>
    </ng-template> <!-- loadingTmpl -->

    <ng-template #searchViewTmpl>
        <div class="__scroll-section __search-results">
            <ng-container *ngIf="shouldShowSuggestionsStream | stream; else searchResultsTmpl">
                <div class="__suggestions-list __list-section __plans">
                    <span class="__heading">Suggestions</span>

                    <ng-container *ngIf="view.suggestedPlans?.length; else noSuggestionsTmpl">
                        <div class="__list __plans">
                            <ng-container *ngFor="let suggestion of view.suggestedPlans">
                                <app-time-search-plan-result-item
                                    (click)="viewPlan(suggestion)"
                                    [plan]="suggestion"
                                ></app-time-search-plan-result-item>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-template #noSuggestionsTmpl>
                        <span class="__no-suggestions">No recent plans</span>
                    </ng-template>
                </div>

                <ng-container *ngIf="showConnectionSuggestions">
                    <div class="__suggestions-list __list-section __people">
                        <span class="__heading">Recently Met</span>

                        <ng-container *ngIf="view.suggestedConnections?.length; else noRecentlyMetTmpl">
                            <div class="vertical-items-list __list __people">
                                <ng-container *ngFor="let connection of view.suggestedConnections">
                                    <app-time-search-people-result-item
                                        (click)="viewConnection(connection)"
                                        [connection]="connection"
                                    ></app-time-search-people-result-item>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-template #noRecentlyMetTmpl>
                            <span class="__no-suggestions">No recently met</span>
                        </ng-template>
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #searchResultsTmpl>
                <ng-container *ngIf="view.plansSearchResults?.length">
                    <div class="__search-results-list __list-section __plans">
                        <span class="__heading">Plans</span>

                        <div class="__list __plans">
                            <ng-container *ngFor="let result of view.plansSearchResults">
                                <app-time-search-plan-result-item
                                    (click)="viewPlan(result)"
                                    [plan]="result"
                                ></app-time-search-plan-result-item>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="view.connectionsSearchResults?.length">
                    <div class="__search-results-list __list-section __people">
                        <span class="__heading">People</span>

                        <div class="vertical-items-list __list __people">
                            <ng-container *ngFor="let result of view.connectionsSearchResults">
                                <app-time-search-people-result-item
                                    (click)="viewConnection(result)"
                                    [connection]="result"
                                ></app-time-search-people-result-item>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>
    </ng-template> <!-- searchViewTmpl -->

    <ng-template #newEventFormTmpl>
        <div class="__scroll-section __new-event">
            <app-time-search-new-event
                [initialTitle$]="searchState.searchCriteriaStream"
                (close)="goToSearchView()"
            ></app-time-search-new-event>
        </div>
    </ng-template> <!-- newEventFormTmpl -->

    <ng-template #editPromptActionsTmpl>
        <div class="__scroll-section __blueprint"
             [class.__is-focus]="view.promptFocusState === 'focus'"
             [class.__is-new-event]="view.newEvent"
        >
            <app-time-command-blueprint
                (onEventSelected)="onBlueprintEventSelected.emit($event)"
                (onHoldSelected)="onBlueprintHoldSelected.emit($event)"
                (onBlueprintClosed)="prompt?.clearSearchInput()"
            ></app-time-command-blueprint>

            <ng-container *ngIf="view.newEvent">
                <div class="__edit-new-event-popover">
                    <app-time-command-edit-new-event
                        [event]="$any(view.newEvent)"
                        [createDraftEvents]="false"
                        (onEventUpdated)="onNewCommandEventUpdated($event)"
                        (close)="commandViewModel.editNewCommandEvent(null)"
                    ></app-time-command-edit-new-event>
                </div>
            </ng-container>
        </div>
    </ng-template> <!-- editPromptActionsTmpl -->

    <!-- TODO: Move this to a component -->
    <ng-template #upgradeTmpl>
        <div *ngIf="featurePlansState.nextFeaturePlanToUpgrade$ | stream as nextPlan"
             class="__scroll-section __limit" [class.__is-focus]="view.promptFocusState === 'focus'"
        >
            <div class="upgrade-banner">
                <svg [attr.width]="150" [attr.height]="150" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.4999 66.2667C52.4678 66.2667 66.2666 52.4896 66.2666 35.5332C66.2006 18.5105 52.4678 4.7334 35.4999 4.7334C18.466 4.7334 4.73328 18.5105 4.73328 35.4669C4.73328 52.4896 18.466 66.2667 35.4999 66.2667Z" fill="#252329"/>
                    <path d="M35.5 56.8002C47.2633 56.8002 56.7999 47.2823 56.7999 35.5002C56.7999 23.7181 47.2213 14.2002 35.5 14.2002C23.7366 14.2002 14.2 23.7181 14.2 35.5002C14.2 47.2823 23.7366 56.8002 35.5 56.8002Z" fill="#1A191D"/>
                    <path d="M26.98 32.0293V23.5093C26.98 18.8038 30.7945 14.9893 35.5 14.9893V14.9893V14.9893C40.2054 14.9893 44.02 18.8038 44.02 23.5093V32.0293" stroke="#3E3C42" stroke-width="4.26"/>
                    <path d="M49.72 55.8135H21.28C19.558 55.8135 18.105 54.3215 18.105 52.4925V32.8644C18.105 31.0354 19.558 29.5435 21.28 29.5435H49.72C51.442 29.5435 52.895 31.0354 52.895 32.8644V52.4925C52.895 54.3215 51.442 55.8135 49.72 55.8135Z" fill="#5A5860" stroke="#5A5860" stroke-width="0.71"/>
                    <circle cx="35.5" cy="40.549" r="3.55" fill="#3E3C42"/>
                    <rect x="34.08" y="41.9688" width="2.84" height="4.97" rx="1.42" fill="#3E3C42"/>
                </svg>

                <div class="info-box">
                    <div class="text-box">
                        <h1>
                            Upgrade to {{ nextPlan.name }}
                        </h1>

                        <ng-container *ngIf="featurePlansState.currentFeaturePlan$ | stream as currentPlan; else noPlanFoundTmpl">
                            <h5>You are currently on the {{currentPlan?.name === 'Base' ? 'Free' : currentPlan.name}} plan. To run more actions, upgrade your plan.</h5>
                        </ng-container>
                        <ng-template #noPlanFoundTmpl>
                            <h5>To run more actions, upgrade your plan.</h5>
                        </ng-template>
                    </div>

                    <ng-container [ngSwitch]="nextPlan.type">

                        <ng-container *ngSwitchCase="FeaturePlans.Premium">
                            <div class="feature-box">
                                <div class="feature-item">
                                    <app-checked-circle-icon></app-checked-circle-icon>
                                    <span class="description">200 Actions per month</span>
                                </div>
                                <div class="feature-item">
                                    <app-checked-circle-icon></app-checked-circle-icon>
                                    <span class="description">Unlimited Schedules & Plans</span>
                                </div>
                                <div class="feature-item">
                                    <app-checked-circle-icon></app-checked-circle-icon>
                                    <span class="description">Unlimited Calendars</span>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="FeaturePlans.Unlimited">
                            <div class="feature-box">
                                <div class="feature-item">
                                    <app-checked-circle-icon></app-checked-circle-icon>
                                    <span class="description">Unlimited Actions</span>
                                </div>
                                <div class="feature-item">
                                    <app-checked-circle-icon></app-checked-circle-icon>
                                    <span class="description">Unlimited Plans</span>
                                </div>
                                <div class="feature-item">
                                    <app-checked-circle-icon></app-checked-circle-icon>
                                    <span class="description">Unlimited Payments</span>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>

                    <button class="button clickable" (click)="navigateToTheMembershipPage()">
                        Upgrade
                    </button>
                </div>
            </div>
        </div>
    </ng-template> <!-- upgradeTmpl -->

    <!-- Hidden upgrade button for onboarding -->
    <div id="ud-ob-upgrade-button" (click)="navigateToTheMembershipPage()"></div>
</ng-container>
