<section class="controls-container" [class.-expanded-bar]="state.isExpandedStream | stream"
         [@toggleUserToastBg]="(isUserToastActiveStream | stream) ? 'pressed' : 'leave'"
>
    <span class="chevron-right">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.7066 7.29436C11.0971 7.68488 11.0971 8.31805 10.7066 8.70857L7.70662 11.7086C7.31609 12.0991 6.68293 12.0991 6.2924 11.7086C5.90188 11.318 5.90188 10.6849 6.2924 10.2944L8.5853 8.00146L6.2924 5.70857C5.90188 5.31805 5.90188 4.68488 6.2924 4.29436C6.68293 3.90383 7.31609 3.90383 7.70662 4.29436L10.7066 7.29436Z"
                  fill="#807A8E"/>
        </svg>
    </span>

    <ng-container *ngIf="currentUserStream | stream as user">
        <div class="profile-image-container"
             (click)="onToggleToast()"
        >
            <img default="/assets/img/placeholders/user.png" [class.-expanded-bar]="state.isExpandedStream | stream"
                 [src]="user.imageUrl | avatarImage | imgThumbnail" [alt]="user.displayName || user.email"
            />

            <div class="profile-image-container__user-nickname" [class.-expanded-bar]="state.isExpandedStream | stream">
                <span class="name">{{user.displayName}}</span>
                <span class="profile-url">@{{user.profileUrl}}</span>
            </div>
        </div>

        <div class="user-toast-overlay"
             (click)="onToggleToast()"
             [@toggleToastView]="(isUserToastActiveStream | stream) ? 'show' : 'hide'"
        >
            <div class="user-toast" (click)="$event.preventDefault(); $event.stopPropagation()">
                <div class="user-toast__user-box">
                    <div class="user-toast__user-icon">
                        <img default="/assets/img/placeholders/user.png"
                             [src]="user.imageUrl | imgThumbnail"
                             [alt]="user.displayName || user.email"
                        />
                    </div>

                    <div class="user-toast__name-info">
                        <div class="user-toast__user-name-wrapper">
                            <p class="user-toast__full-name">
                                {{ user.displayName }}
                            </p>
                            <p class="user-toast__profile-link">@{{ user.profileUrl }}</p>
                        </div>

                        <button class="user-toast__copy-button ud-btn btn-secondary btn-medium" (click)="copyProfileUrl()">
                            <app-icon-copy-link [width]="20" [height]="20" [color]="'var(--color-primary-main)'"></app-icon-copy-link>
                            Copy link
                        </button>
                    </div>
                </div>

                <div class="user-toast__link-item-wrapper"
                     sidebarRouterLink [routerLink]="['/me']" (click)="onToggleToast()"
                >
                    <div class="user-toast__request-icon-box">
                        <app-icon-user-profile [width]="24" [height]="24" [color]="'var(--color-grayscale-dark-1)'"></app-icon-user-profile>
                    </div>

                    <a class="user-toast__request-title">My profile</a>
                </div>

                <div class="user-toast__link-item-wrapper"
                     sidebarRouterLink [routerLink]="['/settings']" (click)="onToggleToast()"
                >
                    <div class="user-toast__request-icon-box">
                        <app-icon-settings [width]="24" [height]="24" [color]="'var(--color-grayscale-dark-1)'"></app-icon-settings>
                    </div>

                    <a class="user-toast__request-title">Settings</a>
                </div>

                <div class="user-toast__bottom-links-wrapper">
                    <div class="user-toast__link-item-wrapper"
                         *ngIf="!(isExtInstalled | stream)" (click)="onInstallExtension()"
                    >
                        <div class="user-toast__request-icon-box">
                            <app-icon-chrome-ext [color]="'var(--color-grayscale-dark-1)'"></app-icon-chrome-ext>
                        </div>

                        <a class="user-toast__request-title">Install Chrome Extension</a>
                    </div>

                    <div class="user-toast__link-item-wrapper" (click)="onRequestFeature()">
                        <div class="user-toast__request-icon-box">
                            <app-icon-feature [color]="'var(--color-grayscale-dark-1)'"></app-icon-feature>
                        </div>

                        <a class="user-toast__request-title" >Request a feature</a>
                    </div>

                    <div class="user-toast__link-item-wrapper" (click)="logout()">
                        <div class="user-toast__exit-icon-box">
                            <app-icon-conf-exit [color]="'var(--color-grayscale-dark-3)'"></app-icon-conf-exit>
                        </div>

                        <a class="user-toast__request-title -logout">Log out</a>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</section>

