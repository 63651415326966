import { JoinData } from '../contracts'
import { ApiRoute } from '@undock/api/services/api.route'
import { DeviceInformation } from '@undock/core'
import { ChimeAttendee } from '@undock/dock/meet/contracts/conference/chime-attendee'


export class ConferenceRoomRoute extends ApiRoute {

    public init() {
        this.route = 'meetings/conference-room'
    }

    public requestAccess(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({
                       roomId, participantId,
                   })
                   .endpoint(`request-access`)
    }

    public revokeAccessRequest(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({
                       roomId, participantId,
                   })
                   .endpoint(`revoke-access-request`)
    }

    public approveAccess(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({
                       roomId, participantId,
                   })
                   .endpoint(`approve-access`)
    }

    public declineAccess(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({
                       roomId, participantId,
                   })
                   .endpoint(`decline-access`)
    }

    public allowMicUsage(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({
                       roomId, participantId,
                   })
                   .endpoint(`allow-microphone`)
    }

    public restrictMicUsage(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({
                       roomId, participantId,
                   })
                   .endpoint(`restrict-microphone`)
    }

    public requestMicrophoneUsage(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({
                       roomId, participantId,
                   })
                   .endpoint(`request-microphone`)
    }

    public setQueueMessage(roomId: string, participantId: string, message: string) {
        return this.post<void>()
                   .withData({ roomId, message, participantId })
                   .endpoint(`set-queue-message`)
    }

    public updateActivityStatus(roomId: string, participantId: string) {
        return this.post<void>()
                   .withData({ roomId, participantId })
                   .endpoint(`update-activity-status`)
    }

    public async join(
        meetingId: string,
        deviceToken?: string,
        deviceInformation?: DeviceInformation,
        additionalOptions: Record<string, string> = {},
    ): Promise<JoinData> {
        return this.post<JoinData>()
                   .withData({ deviceToken, deviceInformation, ...additionalOptions })
                   .endpoint(`join/${meetingId}`)
    }

    public async leave(
        meetingId: string, deviceToken?: string,
    ): Promise<void> {
        return this.post<void>()
                   .withData({ deviceToken })
                   .endpoint(`leave/${meetingId}`)
    }

    public async getAttendeeById(attendeeId: string): Promise<ChimeAttendee> {
        return this.get<ChimeAttendee>()
                   .endpoint(`attendee/${attendeeId}`)
    }

    public async startConferenceRoomRecording(meetingId: string): Promise<void> {
        return this.post<void>()
                   .withData({})
                   .endpoint(`${meetingId}/start-recording`)
    }

    public async stopConferenceRoomRecording(meetingId: string, recordingId: string): Promise<void> {
        return this.post<void>()
                   .withData({})
                   .endpoint(`${meetingId}/stop-recording/${recordingId}`)
    }
}
