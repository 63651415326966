import {
    Pipe,
    PipeTransform,
} from '@angular/core'


@Pipe(({
    name: 'fileSize', pure: true,
}))
export class FileSizePipe implements PipeTransform {

    public transform(size: number): string {

        if (size > 0 && size < 10**6) {
            /**
             * Bytes & Kilobytes
             */
            return `${(size / 10**3).toFixed(2)} kb`
        }

        if (size >= 10**6 && size < 10**9) {
            /**
             * Megabytes
             */
            return `${(size / 10**6).toFixed(2)} mb`
        }

        if (size >= 10**9 && size < 10**12) {
            /**
             * Gigabytes
             */
            return `${(size / 10**9).toFixed(2)} Gb`
        }

        if (size >= 10**12 && size < 10**15) {
            /**
             * Terabytes
             */
            return `${(size / 10**12).toFixed(2)} Tb`
        }

        if (size >= 15 && size < 10**18) {
            /**
             * Petabytes
             */
            return `${(size / 10**15).toFixed(2)} Pb`
        }

        if (size >= 18 && size < 10**21) {
            /**
             * Exabytes
             */
            return `${(size / 10**18).toFixed(2)} Eb`
        }

        if (size >= 21 && size < 10**24) {
            /**
             * Zetabytes
             */
            return `${(size / 10**21).toFixed(2)} Eb`
        }

        if (size >= 24) {
            /**
             * Yotabytes
             */
            return `${(size / 10**24).toFixed(2)} Yb`
        }

        return `${size} bytes`
    }
}
