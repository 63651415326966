import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    PlanType,
    Plan,
} from '@undock/time/plans/contracts/plan.interface'

@Component({
    selector: 'plans-icon',
    templateUrl: 'plans-icon.component.html',
    styleUrls: ['plans-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlansIconComponent {

    public readonly CommandType = PlanType

    @Input() command: Plan

    @Input() color: string = '#2B2733'

    @Input() borderColor: string = '#2B2733'

    @Input() size: number = 42

}
