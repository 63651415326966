import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-connect-calendar-icon',
    templateUrl: 'connect-calendar.component.html',
    styleUrls: ['connect-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectCalendarIconComponent extends IconTemplateComponent {}
