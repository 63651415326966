import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-plus-small-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 6C10.4477 6 10 6.44772 10 7V10H7C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H10V15C10 15.5523 10.4477 16 11 16C11.5523 16 12 15.5523 12 15V12H15C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10H12V7C12 6.44772 11.5523 6 11 6Z" 
              [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
    `]
})
export class PlusSmallIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 22
    @Input() height: string | number  = 22
    @Input() color: string = '#807A8E'
}
