import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

enum ArrowDirectionOptions {
    LEFT = 'left',
    RIGHT = 'right',
    UP = 'up',
    DOWN = 'down'
}

type ArrowDirection = ArrowDirectionOptions | 'right' | 'left' | 'up' | 'down'

@Component({
    selector: 'app-arrow-button',
    templateUrl: 'arrow-button.component.html',
    styleUrls: ['arrow-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowButtonComponent extends IconTemplateComponent {

    @Input()
    public get direction (): ArrowDirection {
        return this._direction || ArrowDirectionOptions.DOWN;
    }
    public set direction (val: ArrowDirection) {
        this._direction = val;
        this.isLeft = val == ArrowDirectionOptions.LEFT
        this.isRight = val == ArrowDirectionOptions.RIGHT
        this.isUp = val == ArrowDirectionOptions.UP
        this.isDown = val == ArrowDirectionOptions.DOWN
    }

    @Input() color: string = '#2B2733'
    @Input() borderColor: string = '#E4DFF2'
    @Input() backgroundColor: string = 'none' //'#FBFBFD'

    /*
    @Input() border: string = '2px solid'
    @Input() fontSize: string = '3rem'
    @Input() fontWeight: string = 'bold'
    @Input() borderRadius: string = '8px'
    @Input() borderWidth: string = ''
    @Input() listFontSize: string = '14px'
    */

    @Output() onClick = new EventEmitter<any>()
    @HostListener('click') _onClick() {
        this.onClick.emit()
    }

    @HostBinding('class.left') private isLeft: boolean = false
    @HostBinding('class.right') private isRight: boolean = false
    @HostBinding('class.up') private isUp: boolean = false
    @HostBinding('class.down') private isDown: boolean = false

    @Input()
    @HostBinding('class.disabled') private disabled: boolean = false

    private _direction: ArrowDirection = ArrowDirectionOptions.DOWN

}
