import { PaymentClient } from '@undock/integrations/contracts/payment-client'
import {
    loadStripe,
    PaymentIntentResult,
    Stripe,
    StripeElements,
    StripeElementsOptions,
    StripePaymentElement,
} from '@stripe/stripe-js'
import { Config } from '@undock/core'
import { Injectable } from '@angular/core'
import { Api } from '@undock/api'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { ChargeInterface } from '@undock/api/scopes/charges/contracts/charge.interface'
import { SubscriptionInterface } from '@undock/api/scopes/subscriptions/contracts/subscriptions.interface'
import { HasPaymentSecretKey } from '@undock/integrations/contracts/has-payment-secret-key'
import { InitializeGroupSubscriptionRequest } from '@undock/api/scopes/subscriptions/requests/initialize-subscription.request'
import { InitializeGroupSubscriptionResponse } from '@undock/api/scopes/subscriptions/contracts/initialize-group-subscription.response'

@Injectable()
export class StripeClient extends PaymentClient {

    private client: Stripe

    @CompleteOnDestroy()
    private readonly stripeElementsSubject = new ValueSubject<StripeElements>(null)

    constructor(
        protected api: Api,
        protected config: Config
    ) {
        super()
        this.initialize()
    }

    public async initialize(): Promise<void> {
        if (!this.client) {
            this.client = await loadStripe(this.config.stripeApiKey)
            if (this.client) {
                console.log('[ Stripe initialized ]')
            }
        }
    }

    public async createPaymentUI(charge: HasPaymentSecretKey, parentElSelector: string): Promise<StripePaymentElement> {
        if (this.client && charge.secretKey) {
            const elements: StripeElements = this.client.elements({
                clientSecret: charge.secretKey,
                appearance: {
                    variables: {
                        fontFamily: 'Inter, sans-serif',
                        fontSizeBase: '11px',
                        fontLineHeight: '14px',
                        colorText: '#807A8E',
                        colorDanger: '#ED315E',
                        colorDangerText: '#ED315E',
                        fontWeightNormal: '600'
                    },
                    rules: {
                        '.Label': {
                            textTransform: 'uppercase',
                            letterSpacing: '1px'
                        },
                        '.Input': {
                            fontSize: '15px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#121114',
                        },
                        '.Input::placeholder': {
                            color: '#807A8E'
                        }
                    }
                },
            } as StripeElementsOptions)
            if (elements) {
                this.stripeElementsSubject.next(elements)

                const paymentElement: StripePaymentElement = elements.create('payment', {
                    fields: {
                        billingDetails: {
                            address: {
                                country: 'never'
                            }
                        }
                    }
                })
                if (paymentElement) {
                    paymentElement.mount(parentElSelector)
                    return paymentElement
                }
            }
        }
        return null
    }

    public destroyPaymentUI(paymentElement: any) {
        if (paymentElement) {
            (paymentElement as StripePaymentElement).unmount()
        }
    }

    public async confirmPayment(payment: HasPaymentSecretKey, savePaymentMethod: boolean = false): Promise<HasPaymentSecretKey> {
        let elements = await this.stripeElementsSubject
        if (elements) {
            let result: PaymentIntentResult = await this.client.confirmPayment({
                elements,
                redirect: 'if_required',
                confirmParams: {
                    return_url: this.getRedirectUrl(),
                    payment_method_data: {
                        billing_details: {
                            address: {
                                country: 'US'
                            }
                        }
                    },
                    save_payment_method: savePaymentMethod
                }
            })
            if (result.error) {
                console.error("STRIPE ERROR:", result.error.message)
            }
            else {
                if (result.paymentIntent.status === 'succeeded') {
                    console.log("Payment Success", payment, result)
                    return payment
                } else {
                    console.log("Payment Unsuccessful", payment, result)
                }
            }
        }
        return null
    }

    public async initializeBookingCharge(
        userUId: string,
        accountId: string,
        scheduleId: string,
        duration: MeetingDuration
    ): Promise<ChargeInterface> {
        return this.api.charge.charges.initializeBookingCharge({
            accountUserUId: userUId, accountId, scheduleId, duration
        })
    }

    public async initializeSubscription(
        accountUserUId: string,
        accountId: string,
        productId: string,
        priceId: string
    ): Promise<SubscriptionInterface> {
        console.log("Initializing subscription", accountUserUId, accountId, productId, priceId)
        return this.api.subscription.subscriptions.initializeSubscription({
            accountUserUId, accountId, productId, priceId, referralId: this.getReferralId()
        })
    }

    public async initializeGroupSubscription(request: InitializeGroupSubscriptionRequest): Promise<InitializeGroupSubscriptionResponse> {
        console.log("Initializing group subscription", request)
        return this.api.subscription.groupSubscriptions.initializeGroupSubscription(request)
    }

    protected getRedirectUrl() {
        return `${location.protocol}//${window.location.host}${window.location.pathname}`
    }

    protected getReferralId() {
        return window ? window['tolt_referral'] : null
    }

}
