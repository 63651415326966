import {
    Injectable,
    OnDestroy,
} from '@angular/core'
import { Clipboard } from '@angular/cdk/clipboard'

import {
    combineLatest,
    merge,
    Subscription,
} from 'rxjs'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    ValueSubject,
} from '@typeheim/fire-rx'

import {
    AppEventsDispatcher,
    Memoize,
    Validations,
} from '@undock/core'
import {
    ConferenceMode,
    DockSharedAccessMode,
    MeetingDurationOption,
    MeetingMode,
    MeetingSlot,
} from '@undock/dock/meet/contracts'
import { Api } from '@undock/api'
import {
    DateRange,
    MomentRange,
    TimezoneData,
} from '@undock/time/availability'
import { CurrentUser } from '@undock/session'
import {
    AvailabilityService,
    MeetingDuration,
} from '@undock/time/availability/services/availability.service'
import { Dock } from '@undock/dock/meet/models/dock.model'

import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { DockVisibility } from '@undock/dock/meet/contracts/dock/dock-visibility'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'
import { generateMeetingTitle } from '@undock/dock/meet/utils/meeting-titles-generator'
import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts/availability'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { MeetingModeOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-mode-options.provider'
import { MeetingDurationOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-duration-options.provider'

import 'moment-timezone'
import {
    default as m,
    Moment,
} from 'moment'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { BrowserTime } from '@undock/time/availability/services/browser-time.model'
import { RRule } from 'rrule'
import { Weekday } from 'rrule/dist/esm/src/weekday'
import {
    Frequency,
    Options,
} from 'rrule/dist/esm/src/types'
import { ScheduleMode } from '@undock/dock/meet/contracts/schedule-mode'
import {
    AnalyticsAction,
    AnalyticsSource,
    AnalyticsTrackedFeature,
} from '@undock/api/scopes/analytics/analytics.scope'



@Injectable()
export class EditMeetingViewModel implements OnDestroy {

    public readonly browserTimeZoneNameStream = this.browserTime.timeZoneNameStream
    public readonly browserTimeZoneDataStream = this.browserTime.timeZoneDataStream

    public readonly meetingTitleStream: ReactiveStream<string>
    public readonly meetingLocationStream: ReactiveStream<string>
    public readonly meetingInPersonLocationStream: ReactiveStream<string>
    public readonly meetingInPersonLocationUrlStream: ReactiveStream<string>
    public readonly isAudioOnlyModeStream: ReactiveStream<boolean>
    public readonly meetingHasUnsavedChangesStream: ReactiveStream<boolean>
    public readonly selectedMeetingModeStream: ReactiveStream<MeetingMode>
    public readonly selectedScheduleModeStream: ReactiveStream<ScheduleMode>
    public readonly selectedVisibilityModeStream: ReactiveStream<DockVisibility>
    public readonly selectedConferenceModeStream: ReactiveStream<ConferenceMode>
    public readonly isCustomAvailableSlotUsedStream: ReactiveStream<boolean>
    public readonly selectedAvailableSlotStream: ReactiveStream<AvailabilitySlot>
    public readonly isCustomMeetingDurationUsedStream: ReactiveStream<boolean>
    public readonly selectedMeetingDurationStream: ReactiveStream<MeetingDuration>
    public readonly availableMeetingDurationOptionsStream: ReactiveStream<MeetingDurationOption[]>


    /**
     * Availability streams
     */
    public readonly availabilityStream: ReactiveStream<AvailabilitySet[]>
    public readonly isAvailabilityLoadingStream: ReactiveStream<boolean>
    public readonly selectedAvailabilityDayStream: ReactiveStream<Moment>
    public readonly displayAvailabilityRangeStartStream: ReactiveStream<Moment>
    public readonly availabilityDaysCountToDisplayStream: ReactiveStream<number>


    @CompleteOnDestroy()
    public isRecurrentEventStream = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public recurringFrequencyStream = new ValueSubject<Frequency>(RRule.DAILY)

    @CompleteOnDestroy()
    public limitRecurrentEventSeriesStream = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public numberOfEventOccurrencesStream = new ValueSubject<number>(20)

    @CompleteOnDestroy()
    private meetingTitleSubject = new StatefulSubject<string>()

    @CompleteOnDestroy()
    private meetingLocationSubject = new StatefulSubject<string>()

    @CompleteOnDestroy()
    private meetingInPersonLocationSubject = new StatefulSubject<string>()

    @CompleteOnDestroy()
    private meetingInPersonLocationUrlSubject = new StatefulSubject<string>()

    @CompleteOnDestroy()
    private isAudioOnlyModeSubject = new StatefulSubject<boolean>()

    @CompleteOnDestroy()
    private selectedAvailabilityDaySubject = new StatefulSubject<Moment>()

    @CompleteOnDestroy()
    protected loadAvailabilityRangeStartSubject = new StatefulSubject<Moment>()

    @CompleteOnDestroy()
    private displayAvailabilityRangeStartSubject = new StatefulSubject<Moment>()

    @CompleteOnDestroy()
    private selectedMeetingModeSubject = new StatefulSubject<MeetingMode>()

    @CompleteOnDestroy()
    private selectedScheduleModeSubject = new StatefulSubject<ScheduleMode>()

    @CompleteOnDestroy()
    private availabilityDaysCountToDisplaySubject = new StatefulSubject<number>()

    @CompleteOnDestroy()
    private selectedVisibilityModeSubject = new StatefulSubject<DockVisibility>()

    @CompleteOnDestroy()
    private selectedConferenceModeSubject = new StatefulSubject<ConferenceMode>()

    @CompleteOnDestroy()
    private selectedMeetingDurationSubject = new StatefulSubject<MeetingDuration>()

    @CompleteOnDestroy()
    private isCustomMeetingDurationUsedSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private meetingHasUnsavedChangesSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private selectedAvailableSlotSubject = new ValueSubject<AvailabilitySlot>(null)

    @CompleteOnDestroy()
    private isCustomAvailableSlotUsedSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private selectedTimeZoneDataSubject = new StatefulSubject<TimezoneData>()

    /**
     * Default duration of availability slot
     */
    private readonly availabilitySlotStep = 30

    protected readonly loadAvailabilityDaysCount = 30

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected dock: DockFacade,
        protected user: CurrentUser,
        protected clipboard: Clipboard,
        protected browserTime: BrowserTime,
        protected eventsManager: AppEventsDispatcher,
        protected snackbarManager: SnackbarManager,
        protected availabilityService: AvailabilityService,
        protected availabilityProvider: AvailabilityProvider,
        protected dockParticipantsManager: DockParticipantsManager,
        protected meetingModeOptionsProvider: MeetingModeOptionsProvider,
        protected meetingDurationOptionsProvider: MeetingDurationOptionsProvider,
    ) {
        this.meetingTitleStream = this.meetingTitleSubject.asStream()
        this.isAudioOnlyModeStream = this.isAudioOnlyModeSubject.asStream()
        this.meetingLocationStream = this.meetingLocationSubject.asStream()
        this.meetingInPersonLocationStream = this.meetingInPersonLocationSubject.asStream()
        this.meetingInPersonLocationUrlStream = this.meetingInPersonLocationUrlSubject.asStream()
        this.selectedAvailabilityDayStream = this.selectedAvailabilityDaySubject.asStream()
        this.meetingHasUnsavedChangesStream = this.meetingHasUnsavedChangesSubject.asStream()
        this.displayAvailabilityRangeStartStream = this.displayAvailabilityRangeStartSubject.asStream()
        this.selectedMeetingModeStream = this.selectedMeetingModeSubject.asStream()
        this.selectedScheduleModeStream = this.selectedScheduleModeSubject.asStream()
        this.selectedVisibilityModeStream = this.selectedVisibilityModeSubject.asStream()
        this.selectedConferenceModeStream = this.selectedConferenceModeSubject.asStream()
        this.isCustomAvailableSlotUsedStream = this.isCustomAvailableSlotUsedSubject.asStream()
        this.availabilityDaysCountToDisplayStream = this.availabilityDaysCountToDisplaySubject.asStream()
        this.selectedAvailableSlotStream = this.selectedAvailableSlotSubject.asStream()
        this.selectedMeetingDurationStream = this.selectedMeetingDurationSubject.asStream()
        this.isCustomMeetingDurationUsedStream = this.isCustomMeetingDurationUsedSubject.asStream()
        this.availableMeetingDurationOptionsStream = this.meetingDurationOptionsProvider.currentUserAvailableMeetingDurationOptionsStream

        this.availabilityStream = this.availabilityProvider.availabilityStream
        this.isAvailabilityLoadingStream = this.availabilityProvider.isAvailabilityLoadingStream
    }

    @Memoize()
    public get currentNonDraftMeetingIdStream(): ReactiveStream<string> {
        /**
         * Should return NULL for Draft meetings
         */
        return new ReactiveStream<string>(
            this.dock.currentDockStream.pipe(
                map(
                    dock => dock?.isDraftType ? null : dock?.id,
                ),

                distinctUntilChanged(),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isMeetingDraftTypeStream(): ReactiveStream<boolean> {
        /**
         * Temporary availability selector is available only for Draft meetings
         */
        return new ReactiveStream<boolean>(
            this.dock.currentDockStream.pipe(
                map(dock => dock?.isDraftType),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get defaultMeetingTitleStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            combineLatest([
                this.selectedMeetingModeStream,
                this.dockParticipantsManager.activeParticipantsStream,
            ]).pipe(
                map(([mode, participants]) => {
                    let titlePrefix: string
                    if (mode === MeetingMode.Broadcast) {
                        titlePrefix = 'Broadcasting'
                    }

                    return generateMeetingTitle(participants.map(p => p.userData), titlePrefix)
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isMeetingReadyToSaveStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.selectedMeetingSlotStream,
            ]).pipe(
                map(([slot]) => Boolean(slot)),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isMeetingShouldBeRescheduledStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.dock.currentDockStream,
                this.selectedMeetingSlotStream,
            ]).pipe(
                map(([dock, slot]) => {
                    /**
                     * Drafts aren't scheduled at this point
                     */
                    if (dock.isDraftType) {
                        return false
                    }

                    return slot && slot.start && slot.end &&
                        dock.dates.start && dock.dates.end &&
                        /**
                         * Meeting should be rescheduled if any of dates been changed
                         */
                        !(slot.end.isSame(dock.dates.end) && slot.start.isSame(dock.dates.start))
                }),

                distinctUntilChanged(),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get displayAvailabilityStream(): ReactiveStream<AvailabilitySet[]> {
        return new ReactiveStream<AvailabilitySet[]>(
            combineLatest([
                this.availabilityStream,
                this.displayAvailabilityRangeStartStream,
                this.availabilityDaysCountToDisplayStream,
            ]).pipe(
                map(sources => {
                    const [availabilitySets, rangeStart, daysCount] = sources

                    if (availabilitySets.length > 0) {

                        let setsRangeStartIndex = availabilitySets.findIndex(set => {
                            return set.day.isSame(rangeStart, 'day')
                        })

                        return availabilitySets.slice(
                            setsRangeStartIndex, setsRangeStartIndex + daysCount,
                        )
                    }

                    return []
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get selectedMeetingSlotStream(): ReactiveStream<MeetingSlot> {
        return new ReactiveStream<MeetingSlot>(
            combineLatest([
                this.selectedAvailableSlotStream,
                this.selectedMeetingDurationStream,
                this.selectedTimeZoneNameStream,
            ]).pipe(
                debounceTime(10),
                map(sources => {
                    let [slot, duration, timezone] = sources
                    return slot ? {
                        duration,
                        start: m(slot.timeStamp).tz(timezone),
                        end: m(slot.timeStamp).tz(timezone).add(duration, 'minutes'),
                        best: slot.best || (90 < slot.score && slot.preferred) || 100 === slot.score,
                    } : null
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isMeetingHasUnsavedChangesStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.meetingHasUnsavedChangesSubject,
                this.isMeetingShouldBeRescheduledStream,
                this.dockParticipantsManager.pendingParticipantsStream,
            ]).pipe(
                map(sources => {

                    const [
                        hasUnsavedChanges,
                        shouldBeRescheduled,
                        pendingParticipants,
                    ] = sources

                    return hasUnsavedChanges || shouldBeRescheduled || pendingParticipants.length > 0
                }),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get meetingDurationForAvailabilityStream(): ReactiveStream<MeetingDuration> {
        /**
         * This stream emits closest VALID meeting duration for selected custom value
         *
         * Current implementation of Availability API doesn't support custom duration
         */
        return new ReactiveStream<MeetingDuration>(
            combineLatest([
                this.selectedMeetingDurationStream,
                this.availableMeetingDurationOptionsStream,
            ]).pipe(
                map(([duration, options]) => {

                    /**
                     * Calculating differences between requested duration and available options
                     */
                    const optionDiffs = options.map(
                        option => Math.abs(duration - (option.value - option.gap)),
                    )

                    /**
                     * Searching for closest duration option from available ones
                     */
                    const closestOption = options[optionDiffs.indexOf(Math.min.apply(this, optionDiffs))]

                    if (closestOption) {
                        return closestOption.value
                    }

                    return options[0] ? options[0].value : this.availabilitySlotStep
                }),

                distinctUntilChanged(),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isCustomRangeTimeConflictsWithAvailability(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.availabilityStream,
                this.selectedMeetingSlotStream,
                this.isAvailabilityLoadingStream,
                this.isCustomAvailableSlotUsedStream,
            ]).pipe(
                debounceTime(10),
                switchMap(async sources => {
                    const [
                        availabilitySet,
                        selectedMeetingSlot,
                        isAvailabilityLoading,
                        isCustomAvailabilitySlotUsed,
                    ] = sources

                    if (isAvailabilityLoading || !isCustomAvailabilitySlotUsed) {
                        /**
                         * We shouldn't check
                         */
                        return false
                    }

                    const availabilityDay = availabilitySet.find(availability => {
                        return availability.day.isSame(selectedMeetingSlot.start, 'day')
                    })

                    return availabilityDay ? this.isMeetingSlotConflictsWithAvailability(
                        selectedMeetingSlot, availabilityDay,
                    ) : true
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get selectedTimeZoneNameStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.selectedTimeZoneDataStream.pipe(
                map(data => data.zone),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get selectedTimeZoneDataStream(): ReactiveStream<TimezoneData> {
        return new ReactiveStream<{ zone: string, label: string }>(
            merge(
                this.browserTimeZoneDataStream,
                this.selectedTimeZoneDataSubject,
            ).pipe(
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get loadAvailabilityDatesRangeStream(): ReactiveStream<MomentRange> {
        return new ReactiveStream(
            this.loadAvailabilityRangeStartSubject.pipe(
                map((start) => ({
                    end: start.clone()
                              .endOf('day')
                              .add(
                                  this.loadAvailabilityDaysCount - 1, 'days',
                              ),

                    start: start.clone()
                                .startOf('day'),
                })),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get conferenceLocationStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            combineLatest([
                this.user.settingsStream,
                this.dock.currentDockStream,
                this.dock.sharedAccessUrlStream,
                this.isMeetingDraftTypeStream,
                this.selectedMeetingModeStream,
            ]).pipe(
                map(([
                         settings, dock, sharedAccessUrl, isDraft, mode,
                     ]) => {

                    if (isDraft) {
                        switch (mode) {
                            case MeetingMode.Video:
                                switch (settings.conferenceLinkPreference) {
                                    case 'undock':
                                        return sharedAccessUrl

                                    case 'custom':
                                        return settings.defaultMeetingContactInfo.meetingLink

                                    default:
                                        return ''
                                }
                            case MeetingMode.InPerson:
                                return ''
                            case MeetingMode.Broadcast:
                                return sharedAccessUrl
                        }
                    }

                    return dock.location ?? ''
                }),
            ),
        )
    }


    public async initViewModel() {
        // Initial UI options.
        this.selectAvailabilityDaysCountToDisplay(3)
        this.selectRangeStartForAvailabilityLoading(new Date())
        this.selectRangeStartForAvailabilityDisplaying(new Date())

        // Apply defaults on meeting mode change
        this.subscribeForSelectedMeetingMode()
        this.subscribeToRefreshSelectedAvailableSlot()

        const dock = await this.dock.currentDock
        if (dock) {
            // Initial Meeting options. These options shouldn't be updated in real-time.
            this.setMeetingTitle(dock.title)
            this.setIsAudioOnlyMode(this.getIsAudioOnlyMode(dock))
            this.setMeetingLocation(this.getMeetingLocation(dock))

            const tasks: Promise<any>[] = []
            tasks.push(this.selectScheduleMode())
            tasks.push(this.selectMeetingMode(this.getMeetingMode(dock)),)
            tasks.push(this.selectVisibilityMode(this.getVisibilityMode(dock)),)
            tasks.push(this.selectConferenceMode(this.getConferenceMode(dock)),)
            tasks.push(this.selectMeetingDuration(this.getMeetingDuration(dock)),)
            tasks.push((async () => this.selectAvailableSlot(await this.getAvailableSlotForMeeting(dock)))())

            await Promise.all(tasks)
            this.loadRecurrenceRule(dock)
        }
    }


    public async onGoToNextDaysClicked() {
        return this.selectRangeStartForAvailabilityDisplaying(
            (await this.displayAvailabilityRangeStartStream).clone().add(
                await this.availabilityDaysCountToDisplayStream, 'days',
            ),
        )
    }

    public async onGoToPrevDaysClicked() {
        return this.selectRangeStartForAvailabilityDisplaying(
            (await this.displayAvailabilityRangeStartStream).clone().subtract(
                await this.availabilityDaysCountToDisplayStream, 'days',
            ),
        )
    }

    public async copyMeetingLinkToTheClipboard() {
        let location: string
        if (this.isMeetingDraftTypeStream) {
            location = await this.conferenceLocationStream
        } else {
            location = (await this.dock.currentDockStream).location
        }

        this.clipboard.copy(location)

        this.snackbarManager.success(`The meeting link has been copied to your clipboard`, SnackbarPosition.BottomLeft)
    }


    public setMeetingTitle(value: string) {
        this.meetingTitleSubject.next(value)
    }

    public setMeetingLocation(value: string) {
        this.meetingLocationSubject.next(value)
    }

    public setMeetingInPersonLocation(value: string) {
        this.meetingInPersonLocationSubject.next(value)
    }

    public setMeetingInPersonLocationUrl(value: string) {
        this.meetingInPersonLocationUrlSubject.next(value)
    }

    public setIsAudioOnlyMode(value: boolean) {
        this.isAudioOnlyModeSubject.next(value)
    }

    public async selectAvailabilityDay(value: Moment, centerSelectedDay = false) {
        if (value) {
            const [
                displayAvailabilityRangeStart, availabilityDaysCountToDisplay,
            ] = await Promise.all([
                this.displayAvailabilityRangeStartStream,
                this.availabilityDaysCountToDisplayStream,
            ])

            let displayAvailabilityRangeEnd = displayAvailabilityRangeStart.clone()
                                                                           .add(availabilityDaysCountToDisplay - 1, 'days')

            let isSelectedSlotDisplayed = value.isBetween(
                displayAvailabilityRangeStart,
                displayAvailabilityRangeEnd,
                'days', '[]',
            )

            if (centerSelectedDay || !isSelectedSlotDisplayed) {
                let newDisplayRangeStart = value.clone()
                                                .subtract(
                                                    Math.floor(availabilityDaysCountToDisplay / 2), 'days',
                                                )

                await this.selectRangeStartForAvailabilityDisplaying(newDisplayRangeStart)
            }
        }

        this.selectedAvailabilityDaySubject.next(value)
    }

    public selectAvailabilityDaysCountToDisplay(count: number) {
        this.availabilityDaysCountToDisplaySubject.next(count)
    }

    private defaultStartDate

    public async selectRangeStartForAvailabilityDisplaying(value: Date | Moment, forceDate = false) {
        const targetMoment = m.isMoment(value) ? value : m(value)

        if (forceDate) {
            this.defaultStartDate = targetMoment
            this.displayAvailabilityRangeStartSubject.next(targetMoment)
            this.loadAvailabilityRangeStartSubject.next(targetMoment)
            await this.selectAvailabilityDay(targetMoment, true)
            return
        }

        const [
            loadAvailabilityRange, availabilityDaysCountToDisplay,
        ] = await Promise.all([
            this.loadAvailabilityDatesRangeStream,
            this.availabilityDaysCountToDisplayStream,
        ])

        let displayRangeStartValid = targetMoment.clone()
                                                 .isBetween(
                                                     loadAvailabilityRange.start, loadAvailabilityRange.end, 'days', '[]',
                                                 )

        let displayRangeEndValid = targetMoment.clone()
                                               .add(availabilityDaysCountToDisplay - 1, 'days')
                                               .isBetween(
                                                   loadAvailabilityRange.start, loadAvailabilityRange.end, 'days', '[]',
                                               )

        if (!displayRangeStartValid || !displayRangeEndValid) {
            let newLoadAvailabilityStart = targetMoment.clone()
                                                       .subtract(
                                                           Math.floor(this.loadAvailabilityDaysCount / 2), 'days',
                                                       )
            /**
             * Will load new availability set for adjusted range
             */
            this.selectRangeStartForAvailabilityLoading(newLoadAvailabilityStart)
        }

        this.displayAvailabilityRangeStartSubject.next(targetMoment)
    }


    public async selectMeetingMode(mode?: MeetingMode) {
        if (!mode) {
            // Select default meeting mode if no one provided
            mode = this.getMeetingMode(await this.dock.currentDock)
        }
        this.selectedMeetingModeSubject.next(mode)
    }

    public async selectScheduleMode(mode?: ScheduleMode) {
        if (!mode) {
            const availableModes = this.getAvailableScheduleModes(
                await this.selectedMeetingModeStream,
            )
            /**
             * Select first available schedule mode
             */
            mode = availableModes[0]
        }

        this.selectedScheduleModeSubject.next(mode)
    }

    public async selectConferenceMode(mode?: ConferenceMode) {
        if (!mode) {
            /**
             * Select default conference mode if no one provided
             */
            const availableModes = this.getAvailableConferenceModes(
                await this.selectedMeetingModeStream,
            )

            mode = availableModes[0]
        }

        this.selectedConferenceModeSubject.next(mode)
    }

    public async selectVisibilityMode(mode?: DockVisibility) {
        if (!mode) {
            /**
             * Select default conference mode if no one provided
             */
            const availableModes = await this.getAvailableVisibilityModes(
                await this.selectedMeetingModeStream,
            )

            mode = availableModes[0]
        }

        this.selectedVisibilityModeSubject.next(mode)
    }

    public async selectMeetingDuration(requestedDuration?: MeetingDuration) {
        const [dock, options] = await Promise.all([
            this.dock.currentDockStream,
            this.availableMeetingDurationOptionsStream,
        ])

        if (!requestedDuration) {
            /**
             * Select duration from dock entity or default meeting duration if no one provided
             */
            requestedDuration = this.getMeetingDuration(dock) ?? await this.meetingDurationOptionsProvider
                                                                           .getCurrentUserDefaultMeetingDuration()
        }

        let duration: MeetingDuration = requestedDuration

        /**
         * Calculating differences between requested duration and available options
         */
        const optionDiffs = options.map(
            option => Math.abs(requestedDuration - (option.value - option.gap)),
        )

        /**
         * Searching for closest duration option from available ones
         */
        const closestOption = options[optionDiffs.indexOf(Math.min.apply(this, optionDiffs))]

        if (dock.isDraftType) {
            /**
             * For the Draft meeting we should check is the current duration available based on the current user settings.
             *     If the current user settings being changed we should select the closest duration for the requested one.
             */
            if (closestOption) {
                duration = closestOption.value - closestOption.gap
            }

            this.selectedMeetingDurationSubject.next(duration)
            this.isCustomMeetingDurationUsedSubject.next(false)
        } else {
            /**
             * For Non-Draft meeting we should check is custom meeting duration been used
             */

            /**
             * Checking is closest option match to the meeting duration provided
             */
            const isCustomDurationUsed = closestOption ?
                Math.abs(closestOption.value - duration) !== closestOption.gap : false

            this.selectedMeetingDurationSubject.next(duration)
            this.isCustomMeetingDurationUsedSubject.next(isCustomDurationUsed)
        }
    }

    public async selectAvailableSlot(slot?: AvailabilitySlot, custom = false) {
        if (!slot) {
            /**
             * Select default availability slot if no one provided
             */
            slot = await this.getAvailableSlotForMeeting(await this.dock.currentDock)
        }

        this.selectedAvailableSlotSubject.next(slot)
        this.isCustomAvailableSlotUsedSubject.next(custom)
        await this.selectAvailabilityDay(slot ? m(slot.timeStamp) : null)

        /**
         * If custom duration is used we probably will receive wrong availability
         *  response because now availability doesn't support custom duration and
         *     the closest duration option value is used for availability request
         *
         * In this case we should select the duration value used for availability request
         */
        if (!custom && await this.isCustomMeetingDurationUsedStream) {
            const [meetingSlot, availability] = await Promise.all([
                this.selectedMeetingSlotStream,
                this.availabilityProvider.availabilityStream,
            ])

            const set = availability.find(set => set.day.isSame(meetingSlot.start, 'day'))

            if (
                await this.isMeetingSlotConflictsWithAvailability(meetingSlot, set)
            ) {
                /**
                 * Should select a VALID duration if custom is not available for selected slot
                 */
                return this.selectMeetingDuration(await this.meetingDurationForAvailabilityStream)
            }
        }
    }

    public async selectCustomDuration(duration: MeetingDuration) {
        this.selectedMeetingDurationSubject.next(duration)
        this.isCustomAvailableSlotUsedSubject.next(true)
        this.isCustomMeetingDurationUsedSubject.next(true)
    }

    public selectTimeZone(timeZone: TimezoneData) {
        this.selectedTimeZoneDataSubject.next(timeZone)
    }

    public async restoreInitialMeetingSlot() {
        const dock = await this.dock.currentDockStream

        const [duration, slot] = await Promise.all([
            this.getMeetingDuration(dock),
            this.getAvailableSlotForMeeting(dock),
        ])

        /**
         * Should select all initial values
         */
        return Promise.all([
            this.selectAvailableSlot(slot), this.selectMeetingDuration(duration),
        ])
    }


    /**
     * @TODO: Implement more options to update
     */
    public async saveChangesToTheMeeting(dock: Dock) {
        if (dock.isDraftType) {
            throw new Error(`Called for Draft meeting`)
        }

        const isAudioOnly = await this.isAudioOnlyModeStream
        if (dock.isAudioOnly !== isAudioOnly) {
            await this.api.meet.dock
                      .setIsAudioOnlyMeeting(dock.id, isAudioOnly)
        }

        if (await this.isMeetingShouldBeRescheduledStream) {
            const slot = await this.selectedMeetingSlotStream
            await this.api.meet.dock.reschedule(dock.id, {
                start: slot.start.toDate(), end: slot.end.toDate(),
            })

            /**
             * Reschedule event analytics
             */
            await this.api.analytics.track({
                event: AnalyticsAction.MeetingProposed,
                source: AnalyticsSource.WebApp,
                feature: AnalyticsTrackedFeature.Reschedule,
            })
        }
    }

    public async saveChangesToTheDraftMeeting(dock: Dock) {
        if (!dock.isDraftType) {
            throw new Error(`Called for non Draft meeting`)
        }

        const [
            meetingTitle,
            isAudioOnlyMode,
            meetingMode, meetingSlot, scheduleMode,
            visibilityMode, conferenceMode, duration,
        ] = await Promise.all([
            this.meetingTitleSubject,
            this.isAudioOnlyModeStream,
            this.selectedMeetingModeStream,
            this.selectedMeetingSlotStream,
            this.selectedScheduleModeStream,
            this.selectedVisibilityModeStream,
            this.selectedConferenceModeStream,
            this.selectedMeetingDurationStream,
        ])

        dock.mode = meetingMode
        dock.title = meetingTitle
        dock.duration = meetingSlot?.duration ?? duration
        dock.isAudioOnly = isAudioOnlyMode ?? false
        dock.visibilityMode = visibilityMode
        dock.conferenceMode = conferenceMode

        /**
         * Resetting selected dock dates
         */
        dock.dates = {} as DateRange
        if (scheduleMode === ScheduleMode.Schedule) {
            dock.dates.end = meetingSlot.end.toDate()
            dock.dates.start = meetingSlot.start.toDate()
        } else {
            dock.dates.start = m().toDate()
            dock.dates.end = m().add(dock.duration, 'minutes').toDate()
        }

        let rRule = null
        if (this.isRecurrentEventStream.value) {
            let options: Partial<Options> = {
                freq: this.recurringFrequencyStream.value,
            }

            if (this.recurringFrequencyStream.value == RRule.WEEKLY) {
                options.byweekday = new Weekday(meetingSlot.start.day() - 1)
            }

            if (this.limitRecurrentEventSeriesStream.value) {
                options.count = this.numberOfEventOccurrencesStream.value
            }

            rRule = (new RRule(options)).toString()
        }

        dock.eventSchedule = {
            start: dock.dates.start,
            end: dock.dates.end,
            isAllDay: false,
            rRule,
        }

        if (!Validations.isNotEmptyString(dock.title)) {
            /**
             * Assign default title to the meeting if no is set up
             */
            dock.title = await this.defaultMeetingTitleStream
        }

        if (dock.mode === MeetingMode.InPerson) {
            /**
             * For `InPerson` mode location should be saved
             */
            dock.location = await this.meetingLocationStream
            dock.inPersonLocation = await this.meetingInPersonLocationStream
            dock.inPersonLocationUrl = await this.meetingInPersonLocationUrlStream
        } else {
            /**
             * For `Remote` modes meeting link will be generated later
             */
            dock.location = ''
        }

        if (dock.mode === MeetingMode.Broadcast) {
            /**
             * Hard-coded temporarily until shared-access mode is not implemented
             *
             * @TODO: Add shared-access-mode selector to the form.
             */
            dock.sharedAccessMode = dock.visibilityMode === DockVisibility.Connections ?
                DockSharedAccessMode.Connections : DockSharedAccessMode.Link

            /**
             * Send broadcast mode scheduling analytics tracking
             */
            if (scheduleMode === ScheduleMode.Schedule) {
                this.eventsManager.dispatch(new TrackUserAnalyticsEvent(UserAnalyticsAction.BroadcastScheduled))
            }
        }

        await this.dock.save(dock)

        return dock
    }

    protected loadRecurrenceRule(dock: Dock) {
        if (dock?.eventSchedule?.rRule && dock?.eventSchedule?.rRule?.length > 0) {
            const rRule = RRule.fromString(dock.eventSchedule.rRule)

            this.recurringFrequencyStream.next(rRule.options.freq)

            if (rRule.options.count) {
                this.numberOfEventOccurrencesStream.next(rRule.options.count)
                this.limitRecurrentEventSeriesStream.next(true)
            }

            this.isRecurrentEventStream.next(true)
        }
    }

    protected getMeetingLocation(dock: Dock): string {
        return dock.location ?? ''
    }

    protected getIsAudioOnlyMode(dock: Dock): boolean {
        return dock.isAudioOnly ?? false
    }

    protected getMeetingMode(dock: Dock): MeetingMode {
        return dock?.mode ?? MeetingMode.Video
    }

    protected getVisibilityMode(dock: Dock): DockVisibility {
        return dock?.visibilityMode
    }

    protected getConferenceMode(dock?: Dock): ConferenceMode {
        return dock?.conferenceMode
    }

    protected getMeetingDuration(dock: Dock): MeetingDuration {
        if (dock.dates && dock.dates.start && dock.dates.end) {
            /**
             * Calculate duration if dock has start and end dates
             */
            return Math.abs(
                m(dock.dates.end).diff(dock.dates.start, 'minutes'),
            )
        }

        return this.availabilitySlotStep
    }

    protected getAvailableScheduleModes(meetingMode: MeetingMode): ScheduleMode[] {
        if (meetingMode === MeetingMode.Broadcast) {
            return [
                ScheduleMode.Instant, ScheduleMode.Schedule,
            ]
        }

        return [ScheduleMode.Schedule]
    }

    protected getAvailableVisibilityModes(meetingMode: MeetingMode): DockVisibility[] {
        if (meetingMode === MeetingMode.Broadcast) {
            return [
                DockVisibility.Connections, DockVisibility.Participants,
            ]
        }

        return [DockVisibility.Participants]
    }

    protected getAvailableConferenceModes(meetingMode: MeetingMode): ConferenceMode[] {
        if (meetingMode === MeetingMode.Broadcast) {
            return [
                ConferenceMode.Individual, ConferenceMode.Forum,
            ]
        }

        return [ConferenceMode.Room]
    }

    protected async getAvailableSlotForMeeting(dock: Dock): Promise<AvailabilitySlot> {
        if (dock.isDraftType) {
            const availability = await this.availabilityStream
            if (this.defaultStartDate) {
                const defaultStartSlotMoment = (m.isMoment(this.defaultStartDate) ? this.defaultStartDate : m(this.defaultStartDate)) as m.Moment

                // round time to 30 minutes for slot-search
                const remainder = defaultStartSlotMoment.minute() % 30
                defaultStartSlotMoment.add(15 <= remainder ? 30 - remainder : -1 * remainder, 'minutes')

                await this.selectAvailabilityDay(defaultStartSlotMoment, true)
                for (const availabilityDay of (availability || [])) {
                    const { day, slots } = availabilityDay
                    if (m(day).isSame(defaultStartSlotMoment, 'date')) {
                        const hour = defaultStartSlotMoment.hour()
                        const minute = defaultStartSlotMoment.minute()
                        const maxScore = Math.max.apply(Math, slots.map(slot => slot.score))
                        const bestSlot = slots.find(slot => slot.score === maxScore)
                        const requestedSlot = slots.find(slot => slot.hour === hour && slot.minute === minute)

                        if (requestedSlot?.free) {
                            return this.createMockAvailabilitySlot(m(requestedSlot.timeStamp))
                        } else if (bestSlot) {
                            return this.createMockAvailabilitySlot(m(bestSlot.timeStamp))
                        } else {
                            const hasFreeSlots = Boolean(slots.find(slot => slot.free))
                            if (hasFreeSlots) {
                                // convert to custom time
                            } else {
                                // no slot found
                            }
                        }
                    }
                }
            }

            /**
             * Should try select the same slot or suggested
             */
            if (dock.dates && dock.dates.start) {
                if (!availability || availability.length === 0) {
                    /**
                     * @TODO: Rework this
                     */
                    setTimeout(() => {
                        this.isCustomAvailableSlotUsedSubject.next(true)
                    }, 10)

                    return this.createMockAvailabilitySlot(m(dock.dates.start))
                }

                /**
                 * For Draft we should search for an empty availability slot
                 *             because this meeting isn't yet been scheduled
                 */
                let targetSlot = this.availabilityService
                                     .findSlotByTimestamp(availability, dock.dates.start)

                if (targetSlot && targetSlot.type === 'slot') {
                    return targetSlot as AvailabilitySlot
                }

                /**
                 * Try select best availability slot for the meeting
                 */
                const suggested = await this.availabilityProvider.suggestedAvailableSlotStream

                if (suggested) {
                    return suggested
                }

                /**
                 * @TODO: Rework this
                 */
                setTimeout(() => {
                    this.isCustomAvailableSlotUsedSubject.next(true)
                }, 10)

                return this.createMockAvailabilitySlot()
            } else {
                /**
                 * Try select best availability slot for the meeting
                 */
                const suggested = await this.availabilityProvider.suggestedAvailableSlotStream

                if (suggested) {
                    return suggested
                }

                /**
                 * @TODO: Rework this
                 */
                setTimeout(() => {
                    this.isCustomAvailableSlotUsedSubject.next(true)
                }, 10)

                return this.createMockAvailabilitySlot()
            }
        } else {
            /**
             * If Dock has been scheduled we'll not get availability slot for it
             */

            /**
             * Returning mock availability slot for already scheduled meeting
             */
            return this.createMockAvailabilitySlot(
                dock?.dates?.start ? m(dock.dates.start) : null,
            )
        }
    }

    protected async refreshSelectedAvailableSlot(availability: AvailabilitySet[]) {
        if (await this.isCustomAvailableSlotUsedStream) {
            /**
             * Should not refresh selected availability slot
             */
            return null
        }

        let selectedSlot = await this.selectedAvailableSlotStream
        if (selectedSlot) {
            /**
             * Check is selected slot is still available
             */
            let isSlotValid = this.availabilityService
                                  .findSlotByTimestamp(availability, selectedSlot.timeStamp)

            if (isSlotValid) {
                /**
                 * Do nothing if selected slot is still available
                 */
                return null
            }
        }

        /**
         * Searching for availability slot if selected slot isn't yet available
         */
        return this.selectAvailableSlot(
            await this.getAvailableSlotForMeeting(await this.dock.currentDockStream),
        )
    }

    protected selectRangeStartForAvailabilityLoading(value: Date | Moment) {
        this.loadAvailabilityRangeStartSubject.next(m.isMoment(value) ? value : m(value))
    }

    protected subscribeForSelectedMeetingMode(): Subscription {
        return this.selectedMeetingModeStream.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyedEvent),
        ).subscribe(async meetingMode => {
            const [
                selectedScheduleMode, selectedConferenceMode, selectedVisibilityMode,
                scheduleModesAvailable, conferenceModesAvailable, visibilityModesAvailable,
            ] = await Promise.all([
                this.selectedScheduleModeStream,
                this.selectedConferenceModeStream,
                this.selectedVisibilityModeStream,
                this.getAvailableScheduleModes(meetingMode),
                this.getAvailableConferenceModes(meetingMode),
                this.getAvailableVisibilityModes(meetingMode),
            ])

            if (!scheduleModesAvailable.includes(selectedScheduleMode)) {
                /**
                 * Force select default schedule mode if current is wrong
                 */
                await this.selectScheduleMode()
            }

            if (!conferenceModesAvailable.includes(selectedConferenceMode)) {
                /**
                 * Force select default conference mode if current is wrong
                 */
                await this.selectConferenceMode()
            }

            if (!visibilityModesAvailable.includes(selectedVisibilityMode)) {
                /**
                 * Force select default visibility mode if current is wrong
                 */
                await this.selectVisibilityMode()
            }

            if (meetingMode === MeetingMode.Broadcast) {
                /**
                 * Broadcasts should be instant at default
                 */
                await this.selectScheduleMode(ScheduleMode.Instant)

                /**
                 * Should select 60 as default duration for Broadcasts
                 */
                await this.selectMeetingDuration(60)

                /**
                 * Broadcasts should be visible for connections at default
                 */
                await this.selectVisibilityMode(DockVisibility.Connections)
            }
        })
    }

    protected createMockAvailabilitySlot(startDate?: Moment): AvailabilitySlot {
        if (!startDate) {
            startDate = m()
            startDate.add(1, 'days')
            startDate.set('hours', 12)
            startDate.set('minutes', 0)
        }

        return {
            hour: startDate.get('hour'),
            minute: startDate.get('minute'),
            label: startDate.format('h:mm A'),
            timeStamp: startDate.toISOString(),
        } as AvailabilitySlot
    }


    protected async isMeetingSlotConflictsWithAvailability(
        slot: MeetingSlot, availability: AvailabilitySet<any>,
    ): Promise<boolean> {
        /**
         * This is the meeting duration used for availability request
         */
        const availabilityDuration = await this.meetingDurationForAvailabilityStream
        const requiredSlotTimestamps = this.getAvailabilitySlotTimestampsForDatesRange(slot.start, slot.end)

        /**
         * If meeting duration is more than availability slot duration API
         * can remove some slots and we should remove it before validation
         */
        if (availabilityDuration > this.availabilitySlotStep) {
            /**
             * Checking how many slots from the end we can ignore
             */
            const redundantSlotsCount = Math.floor(
                availabilityDuration / this.availabilitySlotStep,
            ) - 1

            requiredSlotTimestamps.splice(
                requiredSlotTimestamps.length - redundantSlotsCount, redundantSlotsCount,
            )
        }

        return requiredSlotTimestamps.reduce((isConflict, timeStamp) => {
            /**
             * All required slots should exist in the availability set
             */
            return isConflict || (!availability.slots.find(slot => slot.free && slot.timeStamp === timeStamp))
        }, false)
    }


    protected getAvailabilitySlotTimestampsForDatesRange(
        start: Date | m.Moment | string, end: Date | m.Moment | string,
    ): Array<string> {
        if (!m.isMoment(end)) {
            end = m(end)
        }
        if (!m.isMoment(start)) {
            start = m(start)
        }

        /**
         * Each value should be cloned to prevent mutations
         */
        end = end.clone()
        start = start.clone()

        let startHour = start.get('hours')
        let startMinute = (start.get('minutes') < this.availabilitySlotStep) ? 0 : this.availabilitySlotStep
        let endHour = end.get('hours')
        let endMinute = end.get('minutes')

        if (endMinute === 0) {
            endHour--
            endMinute = this.availabilitySlotStep
        } else if (endMinute > 0 && endMinute <= this.availabilitySlotStep) {
            endMinute = 0
        }

        const timeStamps: Array<string> = []
        /**
         * Searching for availability slots for selected meeting date range
         */
        for (let h = startHour; h <= endHour; h++) {
            let currentHourStartMinute = (h === startHour) ? startMinute : 0,
                currentHourEndMinute = (h === endHour) ? endMinute : this.availabilitySlotStep

            for (let m = currentHourStartMinute; m <= currentHourEndMinute; m += this.availabilitySlotStep) {
                /**
                 * Generating timeStamp for target availability slot
                 */
                timeStamps.push(start.set('hours', h).set('minutes', m).toISOString())
            }
        }

        return timeStamps
    }

    protected subscribeToRefreshSelectedAvailableSlot() {
        this.availabilityProvider.availabilityStream.subscribe(
            availability => this.refreshSelectedAvailableSlot(availability),
        )
    }

    public toggleRecurrenceMode() {
        this.isRecurrentEventStream.next(!this.isRecurrentEventStream.value)
    }

    public toggleRecurrenceLimit() {
        let nextValue = true
        if (this.limitRecurrentEventSeriesStream.value) {
            nextValue = false
            this.numberOfEventOccurrencesStream.next(20)
        }

        this.limitRecurrentEventSeriesStream.next(nextValue)
    }

    public changeRecurringFrequency(frequency: Frequency) {
        this.recurringFrequencyStream.next(frequency)
    }

    public changeNumberOfEventOccurrences(occurrences: number) {
        this.numberOfEventOccurrencesStream.next(occurrences)
    }

    public ngOnDestroy() {}
}
