import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import {
    FirestoreUser,
    ProfilesProvider,
    PublicProfileData,
} from '@undock/user'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import { EventAttendee } from '@undock/api/scopes/time/contracts/timeline-event/event-attendee.interface'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'


export type AttendeeWithMeta = EventAttendee & {
    canDelete?: boolean
}

@Component({
    selector: 'app-meet-edit-attendees',
    templateUrl: 'edit-attendees.component.html',
    styleUrls: ['edit-attendees.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAttendeesComponent {

    public readonly RsvpStatus = RsvpStatus
    public readonly TooltipPosition = TooltipPosition

    @Input() readonly: boolean
    @Input() placeholder: string = 'Email or name...'
    @Output() onAttendeesUpdated = new EventEmitter<EventAttendee[]>()

    protected _attendees: AttendeeWithMeta[]
    protected _allAttendeeEmails: string[]


    public constructor(
        private profilesProvider: ProfilesProvider,
    ) {}


    public get attendees(): AttendeeWithMeta[] {
        return this._attendees ?? []
    }

    @Input() set attendees(value: AttendeeWithMeta[]) {
        this._attendees = value
        delete this._allAttendeeEmails
    }

    /**
     *
     */
    public get allAttendeeEmails(): string[] {
        if (this._allAttendeeEmails === undefined) {
            this._allAttendeeEmails = this.attendees.reduce(
                (carry, attendee) => {

                    /**
                     * Related regular user email
                     */
                    if (attendee.userData.email) {
                        carry.push(attendee.userData.email)
                    }

                    return carry
                }, [],
            )
        }
        return this._allAttendeeEmails
    }

    public removeAttendee(target) {
        this.attendees = this.attendees.filter(
            attendee => attendee !== target
        )
        this.onAttendeesUpdated.emit(this.attendees)
    }

    public async onContactSelected(contacts: Array<PublicProfileData | string>) {
        // TODO: Better support group contact events
        for (let contact of contacts) {
            let profile: FirestoreUser
            if (typeof contact === 'string') {
                /**
                 * Trying to get Undock user by provided email
                 */
                profile = await this.profilesProvider.getProfileByEmail(contact)
                if (!profile) {
                    /**
                     * Contact isn't an Undock user
                     */
                    this.attendees = this.attendees.concat({
                        email: null,
                        status: RsvpStatus.NeedsAction,
                        userData: {
                            email: contact,
                            isGuest: true,
                            isUndockUser: false,
                            isRegularUser: false,
                        },
                        isOrganizer: false,
                    } as EventAttendee)

                    continue
                }
            }

            profile = profile ?? contact as FirestoreUser

            this.attendees = this.attendees.concat({
                email: null,
                status: RsvpStatus.NeedsAction,
                userData: profile.toPublicProfileData(),
                isOrganizer: false,
            } as EventAttendee)
        }

        this.onAttendeesUpdated.emit(this.attendees)
    }
}
