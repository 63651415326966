import { ApiContext } from '@undock/api/contracts/api.context'
import { CheckDomainRoute } from '@undock/api/scopes/integrations/routes/embed/check-domain.route'
import { EmbedProfileRoute } from '@undock/api/scopes/integrations/routes/embed/embed-profile.route'
import { IntegrationsRoute } from './routes/integrations.route'
import { AuthRoute } from '@undock/api/scopes/integrations/routes/auth.route'

export class IntegrationsScope {
    public readonly auth: AuthRoute
    public readonly calendar: IntegrationsRoute
    public readonly embedProfile: EmbedProfileRoute
    public readonly checkDomainRoute: CheckDomainRoute

    constructor(protected context: ApiContext) {
        this.auth = new AuthRoute(this.context)
        this.calendar = new IntegrationsRoute(this.context)
        this.embedProfile = new EmbedProfileRoute(this.context)
        this.checkDomainRoute = new CheckDomainRoute(this.context)
    }
}
