import { Injectable } from '@angular/core'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'

@Injectable({
    providedIn: 'root',
})
export class RemoveDuplicateSlotsFilter {
    public apply(
        originalSlots: AvailabilitySlot[]
    ): AvailabilitySlot[] {
        if (!originalSlots || originalSlots.length === 0) {
            return originalSlots;
        }
        return originalSlots.filter((slot, i) => originalSlots.findIndex(s => s.timeStamp === slot.timeStamp) === i);
    }
}
