export enum UserAnalyticsAction {
    UserSignIn = 'User Sign In',
    AsyncVideoRecorded = 'Agenda View - Async Video Recorded',
    BroadcastScheduled = 'New Event - Broadcast Scheduled',
    CalendarConnected = 'Calendar Connected',
    ConferenceJoined = 'Conference Joined',
    ConnectCalendarFailed = 'Connect Calendar Failed',
    IosAppEligible = 'iOS App Eligible',
    IosAppIneligible = 'iOS App Ineligible',
    MeetingBooked = 'Booking Page - Meeting Created',
    MeetingJoinedFromTimeline = 'Timeline - Meeting Joined',
    MobileAppSkipped = 'Mobile App Skipped',
    PrivateNoteEntered = 'Dock View - Private Note Entered',
    TestFlightClicked = 'Test Flight Clicked',
    TestFlightEmailFormCompleted = 'Test Flight Email Form Completed',

    /**
     * Onboarding
     */
    SetWorkingHoursCompleted = 'Set Working Hours Completed',
    SetMeetingIntervalPreferenceCompleted = 'Set Meeting Interval Preference Completed',
    TrainCalendarCompleted = 'Train Calendar Completed',
    ClaimNameCompleted = 'Claim Name Completed',
    OnboardingCompleted = 'Onboarding Completed',

    ChromeExtensionEligible = 'Chrome Extension Eligible',
    ChromeExtensionIneligible = 'Chrome Extension Ineligible',

    ProductTourStarted = 'Product Tour Started',
    ProductTourCompleted = 'Product Tour Completed',
    ProductTourProfileCompleted = 'Product Tour Profile Completed',
    ProductTourDemoStarted = 'Product Tour Demo Started',
    ProductTourDemoCompleted = 'Product Tour Demo Completed',
    ProductTourSlashKeyPressed = 'Product Tour Slash Key Pressed',
    ProductTourArrowKeyPressed = 'Product Tour Arrow Key Pressed',
    ProductTourTabKeyPressed = 'Product Tour Tab Key Pressed',
    ProductTourEnterKeyPressed = 'Product Tour Enter Key Pressed',
    InstallChromeExtensionClicked = 'Install Chrome Extension Clicked',
    ProductTourInstallButtonClicked = 'Product Tour Install Button Cllicked',

    /**
     * Subscriptions
     */
    UpgradeSubscriptionClickedBanner = 'Upgrade Subscription Clicked - Banner',
    UpgradeSubscriptionClickedSettings = 'Upgrade Subscription Clicked - Settings',
    UpgradeSubscriptionAbandoned = 'Upgrade Subscription Abandoned', //  Close upgrade modal
    ActivateBaseSubscriptionClicked = 'Activate Base Subscription Clicked',
    ActivatePremiumSubscriptionClicked = 'Activate Premium Subscription Clicked',
    ActivatePremiumSubscriptionAbandoned = 'Activate Premium Subscription Abandoned', // back button
    ActivateUnlimitedSubscriptionClicked = 'Activate Unlimited Subscription Clicked',
    ActivateUnlimitedSubscriptionAbandoned = 'Activate Unlimited Subscription Abandoned', // back button
    ToggleMonthlySubscription = 'Toggle Monthly Subscription',
    ToggleYearlySubscription = 'Toggle Yearly Subscription',
    ConfirmPremiumSubscriptionSuccess = 'Confirm Premium Subscription Success',
    ConfirmPremiumSubscriptionFail = 'Confirm Premium Subscription Fail', // User confirmed, but error occured
    ConfirmUnlimitedSubscriptionSuccess = 'Confirm Unlimited Subscription Success',
    ConfirmUnlimitedSubscriptionFail = 'Confirm Unlimited Subscription Fail', // User confirmed, but error occured
}
