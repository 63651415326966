import { Config } from '@undock/core'
import { Injectable } from '@angular/core'


@Injectable()
export class ChimePhoneNumbersProvider {

    public constructor(
        private config: Config,
    ) {}

    /**
     * Returns one of
     */
    public async getNumber(): Promise<string> {
        return this.numbers[
            Math.floor(Math.random() * this.numbers.length)
            ]
    }

    public getDefaultNumber(): string {
        return this.numbers[0]
    }

    protected get numbers(): string[] {
        return this.config.isProduction ?
            ChimePhoneNumbersProvider.productionPhoneNumbers : ChimePhoneNumbersProvider.developmentPhoneNumbers
    }

    private static readonly developmentPhoneNumbers: string[] = [
        '+1 315-284-0981', '+1 518-407-5181', '+1 315-284-0888',
        '+1 315-284-0099', '+1 518-414-3278',
    ]

    protected static readonly productionPhoneNumbers: string[] = [
        '+1 607-363-8954', '+1 585-496-8949', '+1 585-496-8946',
        '+1 585-628-4380', '+1 585-496-8948', '+1 585-496-8943',
        '+1 585-496-8951', '+1 585-496-8947', '+1 585-496-8942',
        '+1 585-496-8941',
    ]
}
