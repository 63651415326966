import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-web-globe',
    templateUrl: 'web-globe.component.html',
    styleUrls: ['web-globe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebGlobeIconComponent extends IconTemplateComponent {}
