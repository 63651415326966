import { Injectable } from '@angular/core'

import { parse } from 'marked'


@Injectable()
export class Markdown2HtmlConverter {

    public toHtml(markdown: string): string {
        // @ts-ignore
        return parse(markdown, {
            gfm: true,
            breaks: true,
            tables: true,
            xhtml: true,
            headerIds: false,
        })
    }

    public isHTML(input: string) {
        let htmlTagsToMatch = [
            'a', 'p', 'ul', 'li',
        ]

        for (let tag of htmlTagsToMatch) {
            if (this.matchHtmlTag(input, tag)) {
                return true
            }
        }

        return false
    }

    protected matchHtmlTag(target: string, tagName: string): RegExpMatchArray {
        return target.match(
            new RegExp(`<\\s*${tagName}[^>]*>(.*?)<\\s*/\\s*a>`, 'g'),
        )
    }
}
