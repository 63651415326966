
export const unregisterServiceWorkers = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for(let registration of registrations) {
                registration.unregister().catch(
                    error => console.warn('unregisterServiceWorkers failed: ', error)
                )
            }
        })
    }
}
