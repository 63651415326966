<div class="container-fluid not-found-container" style="display: flex">
    <div class="not-found-image">
        <app-icon-not-found [width]="140" [height]="140"></app-icon-not-found>

        <h2 class="no-found-header">Page not found</h2>

        <span class="no-found-sub-title">The page you are looking for has moved or no longer exists</span>

<!--        <img class="guest-image" src="/assets/img/hero.gif" alt="hero">-->

        <a mat-button class="navigation-link" *ngIf="(isVisitorRegularUserStream | stream)" [routerLink]="['/', 'timeline']">Go Back</a>
        <a mat-button class="navigation-link" *ngIf="!(isVisitorRegularUserStream | stream)" [routerLink]="['/', 'login']">Join Undock — it’s free</a>

        <span class="no-found-sub-title -last" *ngIf="!(isVisitorRegularUserStream | stream)">Try the most powerful calendar in the galaxy</span>
    </div>
</div>
