export class ArrayHelpers {

    /**
     * Returns an array of unique elements using provided compare function
     *
     * @Warning Avoid using on large arrays - it will take a long time to proceed.
     *          Better use `filterUniqueWithCache` for large arrays (more 500 elements)
     */
    public static filterUnique<T extends any>(input: T[], compareFn?: (a: T, b: T) => boolean): T[] {

        if (!Array.isArray(input)) {
            /**
             * If input validation failed return empty array
             */
            return []
        }

        if (!compareFn) {
            /**
             * Direct comparison function
             */
            compareFn = (a, b) => a === b
        }

        if (input.length > 500) {
            console.warn(`Please use filterUniqueWithCache for large arrays`)
        }

        return input.filter(
            (itemA, index) => input.findIndex(
                (itemB) => compareFn(itemA, itemB),
            ) === index,
        )
    }


    /**
     * Returns an array of unique elements using cache searching
     *
     * @Warning This function possibly will take less time on large (more 500 elements) arrays than filterUnique
     */
    public static filterUniqueWithCache<T extends any>(input: T[], compareByFn?: (a: T) => any): T[] {
        let cache = {}
        return input.filter(item => {
            let compareByValue = compareByFn(item)
            return cache.hasOwnProperty(compareByValue) ? false : (cache[compareByValue] = true)
        })
    }


    /**
     * Returns given arrays difference.
     *
     * @Warning `compareByFn` is required if array of Objects is provided.
     */
    public static findArraysDifference<T extends any>(a: T[], b: T[], compareByFn?: (t: T) => any): T[] {
        if (!compareByFn) {
            compareByFn = (t) => t
        }

        /**
         * Both `a` and `b` should be arrays
         */
        if (!(Array.isArray(a) && Array.isArray(b))) {
            return []
        }

        const compareByFnForUnique = (a, b) => {
            return compareByFn(a) === compareByFn(b)
        }

        /**
         * Arrays should have unique items for compare
         */
        if (a.length < 500) {
            a = ArrayHelpers.filterUnique(a, compareByFnForUnique)
        } else {
            a = ArrayHelpers.filterUniqueWithCache(a, compareByFn)
        }

        /**
         * Arrays should have unique items for compare
         */
        if (b.length < 500) {
            b = ArrayHelpers.filterUnique(b, compareByFnForUnique)
        } else {
            b = ArrayHelpers.filterUniqueWithCache(b, compareByFn)
        }

        const cache = new Set(), diff = [],
            aLength = a.length, bLength = b.length

        /**
         * Pushing all `a` entries to the cache
         */
        for (let i = 0; i < aLength; i++) {
            cache.add(compareByFn(a[i]))
        }

        for (let i = 0; i < bLength; i++) {
            const key = compareByFn(b[i])
            cache.has(key) ? cache.delete(key) : cache.add(key)
        }

        const mergedArrays = [...a, ...b],
            mergedArrayKeys = mergedArrays.map(compareByFn)

        for (let key of cache.values()) {
            const index = mergedArrayKeys.indexOf(key)
            if (index >= 0) {
                diff.push(mergedArrays[index])
            }
        }

        return diff
    }

    /**
     * Returns given arrays intersection.
     *
     * @Warning `compareByFn` is required if array of Objects is provided.
     */
    public static findArraysIntersection<T extends any>(a: T[], b: T[], compareByFn?: (t: T) => any): T[] {

        if (!compareByFn) {
            compareByFn = (t) => t
        }

        return a.filter(aItem => {
            return b.some(bItem => compareByFn(aItem) === compareByFn(bItem))
        })
    }


    /**
     * Returns an array of arrays with specified length
     *
     * @Warning `chunkSize` should be more or equal to 1
     */
    public static splitArrayToChunks<T extends any>(input: T[], chunkSize: number = 100): Array<T[]> {
        if (chunkSize <= 0) {
            throw new Error('Invalid chunk size')
        }

        let i, len, chunks: Array<T[]> = []
        for (i = 0, len = input.length; i < len; i += chunkSize) {
            chunks.push(input.slice(i, i + chunkSize))
        }

        return chunks
    }
}
