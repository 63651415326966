import { Type } from '@angular/core'

import { generate } from 'shortid'

import { AppEventsDispatcher } from '@undock/core/services/events-dispatcher.service'
import { AbstractEvent } from '@undock/core/contracts/event/abstract-event'


export abstract class AbstractEventListener {

    public readonly sortOrder: number = 0

    protected static readonly LISTENER_TYPE_ID: string

    protected constructor(
        eventsManager: AppEventsDispatcher,
        inheritedListenerClass: Type<AbstractEventListener>,
    ) {
        eventsManager.register(this.getEventClass(), this)

        /**
         * If some domain being created multiple times we can get
         * an issue when multiple instances of same listener are registered.
         *
         * The LISTENER_TYPE_ID property is used to prevent this issue
         */
        inheritedListenerClass['LISTENER_TYPE_ID'] = generate()
    }

    public abstract getEventClass(): Type<AbstractEvent>

    public abstract handle(event: AbstractEvent): Promise<void> | void
}
