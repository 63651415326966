import { Injectable } from '@angular/core'
import RecordRTC from 'recordrtc'
import { Recorder } from '@undock/core/contracts/recorder.interface'


@Injectable()
export class AudioRecorderService extends Recorder {

    public async startRecording(): Promise<void> {
        const stream = await this.getUserMediaStream()

        this.mediaStreamSubject.next(stream)
        this.isRecordingRunningSubject.next(true)

        this.recordRTC = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm',
            numberOfAudioChannels: 1,
            disableLogs: true,
        })

        await this.recordRTC.startRecording()
    }

    public async stopRecording(): Promise<Blob> {
        if (this.recordRTC) {
            const stream = await this.mediaStreamSubject

            this.mediaStreamSubject.next(null)
            this.isRecordingRunningSubject.next(false)

            const blob = await new Promise(resolve => {
                this.recordRTC.stopRecording(() => {
                    resolve(this.recordRTC.getBlob())
                })
            })

            /**
             * Revoking all media stream tracks when the recording is stopped
             */
            stream.getTracks().forEach(track => track.stop())

            return blob as Blob
        }

        return null
    }

    public async cancelRecording(): Promise<void> {
        if (this.recordRTC) {
            const stream = await this.mediaStreamSubject

            this.recordRTC.stopRecording()
            this.isRecordingRunningSubject.next(false)

            /**
             * Revoking all media stream tracks when the recording is stopped
             */
            stream.getTracks().forEach(track => track.stop())
        }
    }


    protected getUserMediaStream(): Promise<MediaStream> {
        return navigator?.mediaDevices?.getUserMedia({
            audio: true
        })
    }
}
