import { Injectable } from '@angular/core'

import {
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import {
    Memoize,
    HasAttachments,
} from '@undock/core'
import { NotesAdapterInterface } from '@undock/dock/meet/contracts/ui-adapters/notes.adapter'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'

/**
 * For real-time notes updating with DockFacade
 */
@Injectable()
export class EditEventNotesAdapter implements NotesAdapterInterface {

    public readonly editorChangesDebounceTime = 300 // ms

    public readonly isEditModeStream: ReactiveStream<boolean>

    /**
     * Using current dock as an attachment storage for editor
     */
    public readonly attachmentSourceStream: ReactiveStream<HasAttachments>

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private eventFormStateModel: EventFormStateModel,
    ) {
        this.isEditModeStream = this.eventFormStateModel.state.isEditModeStream
        this.attachmentSourceStream = this.eventFormStateModel.state.relatedDockStream
    }

    @Memoize()
    public get notesTextStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.eventFormStateModel.state.notesStream.pipe(
                takeUntil(this.destroyEvent),
            )
        )
    }

    public setNotes(notes: string): Promise<void> | void {
        return this.eventFormStateModel.setNotes(notes)
    }
}
