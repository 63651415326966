import {
    AbstractEvent,
    AbstractEventListener,
    AppEventsDispatcher,
} from '@undock/core'
import {
    Injectable,
    Type,
} from '@angular/core'
import { StatusesStateModel } from '@undock/data-store/states/statuses.state'
import { RefreshStatusesEvent } from '@undock/data-store/events/refresh-statuses.event'


@Injectable()
export class RefreshStatusesListener extends AbstractEventListener {

    public readonly sortOrder = 1

    public constructor(
        private eventsManager: AppEventsDispatcher,
        private statusesStateModel: StatusesStateModel,
    ) {
        super(eventsManager, RefreshStatusesListener)
    }

    public getEventClass(): Type<AbstractEvent> {
        return RefreshStatusesEvent
    }

    public async handle(event: RefreshStatusesEvent): Promise<void> {
        /**
         * Refreshing the statuses list
         */
        await this.statusesStateModel.refreshStatePartial(event.itemType)
    }
}
