import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
} from '@angular/router'

/**
 * Used for specific param searching using all parent routes
 */
export function getRouteParam(
    route: ActivatedRoute | ActivatedRouteSnapshot, name: string, useParents: boolean = true,
): string {
    const target: ActivatedRouteSnapshot = route instanceof ActivatedRoute ? route.snapshot : route

    if (!target.params[name]) {
        if (useParents && route.parent) {
            return getRouteParam(route.parent, name, useParents)
        }

        return null
    }

    return target.params[name]
}

/**
 * Used for specific param searching using all parent routes
 */
export function getQueryParam<T = string>(
    route: ActivatedRoute | ActivatedRouteSnapshot, name: string, useParents: boolean = true,
): T {
    const target: ActivatedRouteSnapshot = route instanceof ActivatedRoute ? route.snapshot : route

    if (!target.queryParams[name]) {
        if (useParents && route.parent) {
            return getQueryParam(route.parent, name, useParents)
        }

        return null
    }

    return target.queryParams[name]
}
