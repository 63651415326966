import { Injectable } from '@angular/core'
import { Config } from '../models'
import {
    ExtensionMessage,
    ExtensionMessageType,
} from '../contracts/chrome-extension'
import { User } from '@undock/user'
import { ActivatedRoute } from '@angular/router'
import {
    getQueryParam,
    LocalStorage,
    Memoize,
} from '@undock/core'
import { CHROME_EXTENSION_URL_PARAM } from '@undock/chrome-extension/contracts/chrome-extension-url-param'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    filter,
    map,
    takeUntil,
    tap,
} from 'rxjs/operators'
import {
    fromEvent,
    merge,
    share,
} from 'rxjs'
import { OptionalExtensionPermissions } from '@undock/chrome-extension/contracts/optional-extension-permissions'

declare const chrome: any

@Injectable({
    providedIn: 'root',
})
export class ExtConnector {

    private readonly EXT_MESSAGE_APP_ID = 'ud-availability'

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        private config: Config,
        private route: ActivatedRoute
    ) {
        this.contentScriptMessageStream.subscribe()
    }

    public get hostDomain(): string {
        return document.location.ancestorOrigins[0]
    }

    @Memoize()
    public get contentScriptMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            fromEvent(window, 'message').pipe(
                filter((event: MessageEvent) =>
                    event.source == window.top &&
                    event.origin === this.hostDomain &&
                    event.data?.appId === this.EXT_MESSAGE_APP_ID
                ),
                map((event: MessageEvent) => event.data as ExtensionMessage),
                takeUntil(this.destroyedEvent),
            ).pipe(
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get openSidebarMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.OpenSidebar),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get closeSidebarMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.CloseSidebar),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get showTimelineMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.ShowTimeline),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get updateProposalsCountMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.UpdateProposalsCount),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get updateExtensionOptionsMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.UpdateExtensionOptions),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get updateDomainSpecificExtensionOptionsMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.UpdateDomainSpecificExtensionOptions),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get publicProfilesRequestMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.RequestPublicProposals),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    @Memoize()
    public get signInWithCustomTokenMessageStream(): ReactiveStream<ExtensionMessage> {
        return new ReactiveStream<ExtensionMessage>(
            this.contentScriptMessageStream.pipe(
                filter(message => message.type === ExtensionMessageType.SignInWithCustomToken),
                takeUntil(this.destroyedEvent),
                share(),
            ),
        )
    }

    public async isExtInstalled(): Promise<boolean> {
        return !!(await this.sendMessage(ExtensionMessageType.CheckForChromeExtension, {}))
    }

    public async isExtRoute(route?: ActivatedRoute): Promise<boolean> {
        if (!route) {
            route = this.route
        }
        if (route) {
            return Boolean(
                getQueryParam(route, CHROME_EXTENSION_URL_PARAM)
            )
        }
        return false
    }

    public isExt(): boolean {
        return this.isChromeBrowser() && window.top && window != window.top
    }

    public sendMessageToExt(type: ExtensionMessageType, body?: any): void {
        if (this.isExt()) {
            window.top.postMessage({
                type: type,
                body: body,
                appId: this.EXT_MESSAGE_APP_ID
            } as ExtensionMessage, this.hostDomain)
        }
    }

    public async updateUserInExt(user: User): Promise<boolean> {
        return !!(await this.sendMessage(ExtensionMessageType.UpdateUser, user))
    }

    public async sendIdTokenToExt(idToken: string): Promise<boolean> {
        return !!(await this.sendMessage(ExtensionMessageType.SignInWithCustomToken, idToken))
    }

    public async requestPermissionInExt(permission: OptionalExtensionPermissions): Promise<boolean> {
        return !!(await this.sendMessage(ExtensionMessageType.RequestPermission, {
            permission: permission,
            domain: this.hostDomain?.replace(/^https?:\/\//, '')
        }))
    }

    public async removePermissionInExt(permission: OptionalExtensionPermissions): Promise<boolean> {
        return !!(await this.sendMessage(ExtensionMessageType.RemovePermission, {
            permission: permission,
            domain: this.hostDomain?.replace(/^https?:\/\//, '')
        }))
    }

    public async refreshUserInExt(email: string): Promise<boolean> {
        return !!(await this.sendMessage(ExtensionMessageType.RefreshUser, email))
    }

    public async signOutExt(): Promise<boolean> {
        return !!(await this.sendMessage(ExtensionMessageType.SignOut, {}))
    }

    public isChromeBrowser(): boolean {
        // @ts-ignore
        return !!window?.chrome && !!window?.chrome.runtime
    }

    protected sendMessage(type: ExtensionMessageType, body: any): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            if (this.isChromeBrowser()) {
                chrome.runtime.sendMessage(this.config.chromeExtensionId, { type: type, body: body, appId: this.EXT_MESSAGE_APP_ID } as ExtensionMessage, function(res) {

                    if (chrome.runtime.lastError) {
                        console.log('ERROR:', chrome.runtime.lastError.message)
                        resolve(false)
                    }

                    resolve(res)
                })
            } else {
                resolve(false)
            }
        })
    }
}
