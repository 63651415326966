import {
    Directive,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core'

@Directive({
    selector: '[onInit],[onDestroy]',
})
export class LifecycleDirective implements OnInit, OnDestroy {

    /**
     * -----------------------------------------
     *  LifecycleDirective used for subscribing
     *  to  the simple HTML  elements lifecycle
     * -----------------------------------------
     */

    public constructor(
        private elementRef: ElementRef,
    ) {}

    @Output() onInit: EventEmitter<ElementRef> = new EventEmitter<ElementRef>()
    @Output() onDestroy: EventEmitter<ElementRef> = new EventEmitter<ElementRef>()

    public ngOnInit(): void {
        this.onInit.emit(this.elementRef)
    }

    public ngOnDestroy(): void {
        this.onDestroy.emit(this.elementRef)
    }
}
