import { ApiRoute } from '@undock/api'
import { EditMeetingData } from '@undock/dock/meet/contracts/edit-meeting-data.interface'
import { CreateDockFromDraftData } from '@undock/api/scopes/meet/contracts/create-dock-from-draft.data'

export class MeetingsRoute extends ApiRoute {

    public init() {
        this.route = 'meetings/dock'
    }

    public updateMeeting(
        dockKey: string, data: Partial<EditMeetingData>,
    ): Promise<EditMeetingData> {
        return this.post<EditMeetingData>()
                   .withData({
                       ...data, dockKey,
                   })
                   .endpoint(`update-meeting/${dockKey}`)
    }

    public getEditMeetingData(dockKey: string): Promise<EditMeetingData> {
        return this.get<EditMeetingData>()
                   .endpoint(`edit-meeting-data/${dockKey}`)
    }

    public createMeetingFromDraft(data: CreateDockFromDraftData) {
        return this.post<EditMeetingData>()
                   .withData({ ...data })
                   .endpoint(`create-from-draft-meeting`)
    }

    public createDraftMeeting(data: CrateDraftMeetingRequest = {}) {
        return this.post<EditMeetingData>()
                   .withData({ ...data })
                   .endpoint('create-draft-meeting')
    }

    public updateDraftMeeting(id: string, data: Partial<EditMeetingData>) {
        return this.post<EditMeetingData>()
                   .withData({ ...data, _id: id })
                   .endpoint('update-draft-meeting')
    }

    public deleteDraftMeeting(id: string): Promise<void> {
        return this.post<void>()
                   .endpoint(`delete-draft-meeting/${id}`)
    }
}

export interface CrateDraftMeetingRequest {
    createDraftDock?: boolean
    initialProperties?: Partial<EditMeetingData>
}