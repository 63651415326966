import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-credit-card-plus-icon',
    templateUrl: 'credit-card-plus-icon.component.html',
    styleUrls: ['credit-card-plus-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardPlusIconComponent extends IconTemplateComponent {}
