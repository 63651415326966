import {
    Observable,
    Subject,
} from 'rxjs'
import { inject } from '@angular/core'

export type Readonly$<T> =
    T extends Subject<infer U1>
        ? Omit<T, 'next'>
        : T extends Observable<infer U2>
            ? T
            : never

export type State<T> = {
    [key in keyof T]: Readonly$<T[key]>
}

export type StateOf<T extends StateModel<any>> =
    T extends StateModel<infer U> ? State<U> : never

export function injectState<S extends StreamStore>(
    stateModelCtr: new (...args) => StateModel<S>,
): State<S> {
    return inject(stateModelCtr)?.state
}

export class StreamStore {
    getState(): State<this> {
        return storeToState(this)
    }

    // Complete all subject in store
    public complete() {
        completeStore(this)
    }
}

// Converts store to readonly state
export abstract class StateModel<T> {
    protected abstract store: StreamStore
    protected _state: any

    get state(): State<T> {
        if (!this._state) {
            this._state = this.store.getState()
        }
        return this._state
    }

    destroy() {
        this.store.complete()
    }
}

function storeToState<T extends StreamStore>(store: T): State<T> {
    const state = {} as State<T>
    for (const [key, value] of Object.entries(store)) {
        if (value instanceof Observable) {
            state[key] = value
        }
    }

    // Going through all prototypes to collect getters
    let prototype = store
    while (prototype) {
        const descriptors = Object.getOwnPropertyDescriptors(prototype)
        for (let [key, descriptor] of Object.entries(descriptors)) {
            if (typeof descriptor['get'] === 'function') {
                Object.defineProperty(state, key, {
                    get: () => store[key],
                    enumerable: false,
                    configurable: true,
                })
            }
        }
        prototype = Object.getPrototypeOf(prototype)
    }

    return state
}

// TODO: Ensure lazy streams are completed
function completeStore(store: StreamStore) {
    for (const [key, value] of Object.entries(store)) {
        //@ts-ignore
        if (value?.complete && typeof value.complete == 'function') {
            try {
                //@ts-ignore
                value.complete()
            } catch (error) {
            }
            //@ts-ignore
        } else if (value?.unsubscribe && typeof value.unsubscribe == 'function') {
            try {
                //@ts-ignore
                value.unsubscribe()
            } catch (error) {
            }
        }
    }
}
