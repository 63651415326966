import {
    Input,
    Component,
} from '@angular/core'
import {
    PlansSearchState,
    PlansSearchStateModel,
} from '@undock/time/plans/services/states/plans-search.state-model'
import { Observable, combineLatest } from 'rxjs'
import { Memoize } from '@undock/core'
import {
    EventFormState,
    EventFormStateModel,
} from '@undock/dock/meet/services/state-models/event-form.state-model'
import { Plan } from '@undock/time/plans/contracts/plan.interface'
import {
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'


@Component({
    selector: 'app-event-form-plan',
    templateUrl: 'event-form-plan.component.html',
    styleUrls: ['event-form-plan.component.scss'],
    providers: [PlansSearchStateModel],
})
export class EventFormPlanComponent {

    public readonly eventFormState: EventFormState
    public readonly searchCommandsState: PlansSearchState

    /**
     * Optional host for dropdown elem
     */
    @Input() dropdownElHost?: HTMLElement

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private eventFormStateModel: EventFormStateModel,
        private commandSearchStateModel: PlansSearchStateModel,
    ) {
        this.eventFormState = this.eventFormStateModel.state
        this.searchCommandsState = this.commandSearchStateModel.state
    }

    @Memoize()
    public get allPlansStream(): Observable<Plan[]> {
        return this.commandSearchStateModel.allPlansStream.pipe(
            map(plans => {
                return plans.filter(plan => Boolean(plan.title))
            })
        )
    }

    @Memoize()
    public get selectedPlanStream(): Observable<Plan> {
        return combineLatest([
            this.eventFormState.planIdStream,
            this.commandSearchStateModel.allPlansStream,
        ]).pipe(
            map(([planId, userPlans]) => {

                console.log({
                    planId, userPlans
                })

                return planId ? userPlans.find(
                    plan => plan._id === planId || plan.publicId === planId
                ) : null
            }),
            takeUntil(this.destroyEvent),
        )
    }

    public selectPlan(plan: Plan) {
        /**
         * TODO
         *      1. Select plan
         *      2. Copy plan dock
         *      3. Save dockId, planId
         */
        console.log(`selectPlan`, plan)
        this.eventFormStateModel.applyUserCommand(plan)
    }

    public async resetSelectedPlan() {
        /**
         * TODO:
         *      1. Delete draft dock
         *      2. Remove dockId, planId from event
         */
        console.log(`resetSelectedPlan`)
    }

    public setSearchCriteria(criteria: string) {
        return this.commandSearchStateModel.searchPlans(criteria)
    }
}
