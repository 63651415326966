import { ReactiveStream } from '@typeheim/fire-rx'
import { Collection } from '@typeheim/orm-on-fire'
import { Schedule } from '@undock/dock/meet'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { MeetingTypeFactory } from '@undock/dock/meet/models/factories/meeting-type.factory'
import { MeetingDurationOptionsProvider } from '@undock/dock/meet/services/data-providers/meeting-duration-options.provider'


export abstract class SchedulesManager {
    public abstract schedules$: ReactiveStream<Schedule[]>
    public abstract customSchedules$: ReactiveStream<Schedule[]>
    public abstract buildInSchedules$: ReactiveStream<Schedule[]>
    public abstract isAnyScheduleAdded$: ReactiveStream<boolean>
    public abstract isSchedulesLimitReached$: ReactiveStream<boolean>

    protected abstract schedulesCollection$: ReactiveStream<Collection<Schedule>>

    protected abstract snackbarManager: SnackbarManager
    protected abstract meetingTypeFactory: MeetingTypeFactory
    protected abstract meetingDurationOptionsProvider: MeetingDurationOptionsProvider

    public async createMeetingType(properties?: Partial<Schedule>, save = false): Promise<Schedule> {
        const entity = await this.meetingTypeFactory.create({
            ...properties,
            ...(await this.getRelatedEntityData()),
        })
        if (save) {
            (await this.schedulesCollection$).save(entity)
        }
        return entity
    }

    public async setScheduleEnabled(entity: Schedule): Promise<void> {
        if (!await this.isSchedulesLimitReached$) {
            entity.isDisabled = false
            return this.saveMeetingType(entity)
        } else {
            this.snackbarManager.error(`Cannot enable schedule`)
        }
    }

    public async setScheduleDisabled(entity: Schedule): Promise<void> {
        entity.isDisabled = true
        return this.saveMeetingType(entity)
    }

    public async saveMeetingType(entity: Schedule): Promise<void> {
        return (await this.schedulesCollection$).save(entity)
    }

    public async deleteMeetingType(entity: Schedule): Promise<void> {
        entity.removed = true
        return (await this.schedulesCollection$).save(entity)
    }

    public getScheduleKey(entity: Schedule): string {
        // Support deprecated data structure
        if (!entity.relatedEntityType) {
            return `${entity.userUId}/${entity.id}`
        }

        return [
            entity.relatedEntityType,
            entity.relatedEntityId,
            entity.id,
        ].join('/')
    }

    protected prepareSchedule(schedule: Schedule): Schedule {
        if (!schedule) {
            return schedule
        }

        /**
         * Setup default value for `allowedModes`
         */
        schedule.allowedModes =  schedule.allowedModes ?? []

        /**
         * Using legacy mode as the one is allowed
         */
        if (schedule.allowedModes.length === 0) {
            schedule.allowedModes.push(schedule.mode)
        }

        const defaultDurations = this.meetingDurationOptionsProvider.defaultMeetingDurationValues
        /**
         * Restore all default durations
         */
        defaultDurations.forEach(duration => {
            if (!schedule.availableDurationValues.hasOwnProperty(`${duration}`)) {
                schedule.availableDurationValues[duration] = false
            }
        })

        return schedule
    }

    protected prepareSchedules(schedules: Schedule[]): Schedule[] {
        return schedules.map(schedule => this.prepareSchedule(schedule))
    }


    // TODO: Move these methods to a separate service
    public abstract getSchedulesPageUrl(): Promise<string>

    // TODO: Move these methods to a separate service
    public abstract generateScheduleUrl(schedule: Schedule): Promise<string>

    protected abstract getRelatedEntityData(): Promise<{
        relatedEntityId: string
        relatedEntityType: string
    }>
}
