import { FeaturePlans } from '@undock/api/scopes/subscriptions/contracts/feature-plan.interface'

export enum AppFeatures {
    LimitedPlans = 'LimitedPlans',
    LimitedEvents = 'LimitedEvents',
    LimitedPrompts = 'LimitedPrompts',
    LimitedPayments = 'LimitedPayments',
    LimitedCalendars = 'LimitedCalendars',
    LimitedSchedules = 'LimitedSchedules',
    LimitedConferencing = 'LimitedConferencing',

    ExtendedPlans = 'ExtendedPlans',
    ExtendedEvents = 'ExtendedEvents',
    ExtendedPrompts = 'ExtendedPrompts',
    ExtendedPayments = 'ExtendedPayments',
    ExtendedConferencing = 'ExtendedConferencing',

    UnlimitedPlans = 'UnlimitedPlans',
    UnlimitedEvents = 'UnlimitedEvents',
    UnlimitedPrompts = 'UnlimitedPrompts',
    UnlimitedPayments = 'UnlimitedPayments',
    UnlimitedCalendars = 'UnlimitedCalendars',
    UnlimitedSchedules = 'UnlimitedSchedules',
    UnlimitedConferencing = 'UnlimitedConferencing',

    PeopleAndTimeAnalytics = 'PeopleAndTimeAnalytics',
}

export interface AppFeature {
    name: string
    type: AppFeatures
    planType: FeaturePlans
    description: string
}
