<ng-container *ngIf="currentTopicStream | stream as topic">
    <div class="topic-view-container topic-list" [ngClass]="{'-bg-completed' : topic.complete}">
        <div class="topic-list__header">
            <ng-container *ngIf="displayCdkDragToggle">
                <span class="topic-list__drag-handle-area"></span>
                <span class="topic-list__range"></span>
            </ng-container>

            <div class="topic-list__topic-status">
                <button class="topic-list__complete-topic-button" (click)="toggleCompleteStatus()"
                        [matTooltipPosition]="TooltipPosition.BOTTOM"
                        [disabled]="!(isEditModeStream | stream)"
                        [@isComplete]="topic.complete ? 'true' : 'false'"
                        [matTooltip]="topic.complete ? 'Unresolve' : 'Resolve'"
                >
                    <span class="material-icons">done</span>
                </button>
            </div>

            <div class="topic-list__titles">
                <div class="topic-list__topic-input-title">
                    <form autocomplete="off">
                        <input name="topic-title" autocomplete="off" data-private type="text" #topicTextInput
                               [(ngModel)]="topic.text"
                               [ngModelOptions]="{standalone: true}"
                               [disabled]="!(isEditModeStream | stream)"
                               [ngClass]="topic.complete ? '-strike' : ''"
                        />
                    </form>
                </div>
            </div>
        </div>

        <!--header-right-side-->
        <div class="topic-list__topic-navigation">
            <div class="action-icon topic-list__participant-wrapper">
                <app-meet-participant-selector-trigger class="topic-list__participant-selector"
                                                       [title]="'Assign topic'"
                                                       [isEditMode]="isEditModeStream | stream"
                                                       [selectedIds]="assignedUsersIdsStream | async"
                                                       (onSelected)="onParticipantSelectedEvent($event)"
                ></app-meet-participant-selector-trigger>
            </div>

            <ng-container [ngSwitch]="isVideoProcessingStream | stream">
                <ng-container *ngSwitchCase="true">
                    <div class="action-icon topic-list__record-item">
                        <spinner [size]="30" [tickness]="5" [color]="'#2B2733'"></spinner>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                    <ng-container *ngIf="isEditModeStream | stream">
                        <div class="action-icon topic-list__record-item" [ngClass]="{'hidden': hasVideoAttachmentStream | stream}">
                            <app-video-recorder [bottomLabelText]="(currentTopicStream | stream)?.text"
                                          (onRecordingReady)="onRecordingReady($event)"
                            ></app-video-recorder>
                        </div>
                    </ng-container>

                    <ng-template #contentTemplate>
                        <div class="floating-player">
                            <button class="close-button" (click)="toggleVideoPlayer()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                     fill="none" class="ng-tns-c268-0">
                                    <path d="M15 5L5 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round" class="ng-tns-c268-0"></path>
                                    <path d="M5 5L15 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round" class="ng-tns-c268-0"></path>
                                </svg>
                            </button>
                            <video controls autoplay playsinline [src]="attachmentUrl | stream"></video>
                            <div class="controls-bar" *ngIf="isEditModeStream | stream">
                                <p class="title">{{ (currentTopicStream | stream)?.text }}</p>
                                <button class="video-delete-btn" (click)="removeAttachment()">Delete Video</button>
                            </div>
                        </div>
                    </ng-template>

                    <div *ngIf="hasVideoAttachmentStream | stream" class="action-icon topic-list__record-item">
                        <button class="video-attachment-button" (click)="toggleVideoPlayer()">
                            <app-icon-play [width]="20" [height]="20" [color]="'#FFFFFF'"></app-icon-play>
                        </button>
                    </div>
                </ng-container>
            </ng-container>

            <app-meet-topic-actions
                    class="action-icon"
                    [isEditingAllowed]="isEditModeStream | stream"
                    (event)="processMeetingAction($event)"
            ></app-meet-topic-actions>
        </div>
    </div>
</ng-container>




