import {
    inject,
    Injectable,
    Type,
} from '@angular/core'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import {
    AbstractConfirmPopup,
    ConfirmPopupConfig,
} from '@undock/common/ui-kit/ui/components'

export enum ConfirmAction {
    CreateEvent = 'Create Event',
    UpdateEvent = 'Update Event',
    DeleteEvent = 'Delete Event',
    RsvpToEvent = 'Respond to Event',

    ConfirmTimelineEvent = 'Confirm timeline Event',
    DeclineTimelineEvent = 'Decline timeline Event',

    CreateProposal = 'Create Request',
    UpdateProposal = 'Update Request',
    DeleteProposal = 'Delete Request',
    ConfirmProposal = 'Confirm Request',
    RespondToProposal = 'Respond to Request',
    RespondWithEmptyProposal = 'Respond with empty Request',
}

@Injectable()
export class ConfirmActionService {

    // Default confirmation logic
    protected actionEnabled = {
        [ConfirmAction.DeleteEvent]: true,
        [ConfirmAction.DeleteProposal]: true,
        [ConfirmAction.RespondWithEmptyProposal]: true,
    }

    protected readonly confirmPopupService = inject(ConfirmPopupService)

    public async askFor(actionType: ConfirmAction) {
        if (!this.actionEnabled[actionType]) {
            return true
        }
        return this.confirmPopupService.open(
            await this.getProperties(actionType),
            this.getComponentClass(),
        )
    }

    protected getComponentClass(): Type<AbstractConfirmPopup> {
        return null // Use default component class
    }

    protected async getProperties(actionType: ConfirmAction): Promise<ConfirmPopupConfig> {
        switch (actionType) {
            case ConfirmAction.DeleteEvent:
                return {
                    title: 'Are you sure you want to delete this event?',
                    description: `This action could not be undone`,
                    confirmButtonLabel: 'Yes, Delete',
                    discardButtonLabel: 'Cancel',
                }
            case ConfirmAction.DeleteProposal:
                return {
                    title: 'Are you sure you want to delete this request?',
                    description: `This action could not be undone`,
                    confirmButtonLabel: 'Yes, Delete',
                    discardButtonLabel: 'Cancel',
                }
            case ConfirmAction.RespondWithEmptyProposal:
                return {
                    title: 'Are you sure you want to submit an empty response?',
                    description: `You haven't selected any time slots`,
                    confirmButtonLabel: 'Yes, I\'m sure',
                    discardButtonLabel: 'Cancel',
                }
        }
    }
}
