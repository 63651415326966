import {
    State,
    StreamStore,
} from '@undock/core/states'
import {
    ValueSubject,
    StatefulSubject,
} from '@typeheim/fire-rx'

import { TimelineDayData } from '@undock/dashboard/contracts'


export class DashboardTimelineStore extends StreamStore {

    public readonly daysStream = new ValueSubject<TimelineDayData[]>([])

    /**
     * Emitter to push the updates directly into components
     */
    public readonly onDayUpdated = new StatefulSubject<TimelineDayData>()

    /**
     * An id of currently focused events (tabs are opened)
     */
    public readonly focusedEventIdStream = new ValueSubject<string>(null)

    /**
     * Triggers when initial visible range is ready
     */
    public readonly isTimelineReadyStream = new ValueSubject<boolean>(false)

    /**
     * Timeline page statuses
     */
    public readonly isFutureLoadingStream = new ValueSubject<boolean>(false)
    public readonly isHistoryLoadingStream = new ValueSubject<boolean>(false)
    public readonly canLoadFutureEventsStream = new ValueSubject<boolean>(true)
    public readonly canLoadHistoryEventsStream = new ValueSubject<boolean>(true)
}

export declare type DashboardTimelineState = State<DashboardTimelineStore>
