import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-sidebar-close',
    templateUrl: 'sidebar-close.component.html',
    styleUrls: ['sidebar-close.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarCloseIconComponent extends IconTemplateComponent {}
