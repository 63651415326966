import { Injectable } from '@angular/core'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
    combineLatestWith,
} from 'rxjs/operators'
import {
    compareDeeplyBy,
    LocalStorage,
    Memoize,
} from '@undock/core'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import { IOrganizationAggregate }from '@undock/api/scopes/organizations/contracts'


@Injectable({
    providedIn: 'root',
})
export class OrganizationsStorage {

    @CompleteOnDestroy()
    public readonly areOrganizationsLoading$ = new ValueSubject(false)

    @CompleteOnDestroy()
    public readonly triggerOrganizationsRefresh$ = new ValueSubject<void>(null)

    @CompleteOnDestroy()
    public readonly cachedUserOrganizationsCount$ = new ValueSubject(null)

    private readonly ORGANIZATIONS_COUNT_CACHE_KEY = '@undock[CachedOrganizationsCount]'

    @EmitOnDestroy()
    private destroyEvent = new DestroyEvent()

    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
        private localStorage: LocalStorage,
    ) {
        this.ngOnInit()
    }

    @Memoize()
    public get own$(): ReactiveStream<IOrganizationAggregate[]> {
        return new ReactiveStream(
            this.currentUser.dataStream.pipe(
                takeUntil(this.destroyEvent),
                distinctUntilChanged(compareDeeplyBy('_id')),
                // Reload
                combineLatestWith(this.triggerOrganizationsRefresh$),
                tap(() => this.areOrganizationsLoading$.next(true)),
                switchMap(() => {
                    return this.api.organizations.own.list()
                }),
                tap(organizations => {
                    this.areOrganizationsLoading$.next(false)
                    this.setCachedOrganizationsCount(organizations.length)
                }),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    public ngOnInit() {
        // Restore cached organizations count for skeleton loaders
        this.cachedUserOrganizationsCount$.next(this.getCachedOrganizationsCount())
    }

    protected getCachedOrganizationsCount(): number {
        return parseInt(
            this.localStorage.getItem(this.ORGANIZATIONS_COUNT_CACHE_KEY),
        ) || 0
    }

    protected setCachedOrganizationsCount(count: number) {
        this.localStorage.setItem(this.ORGANIZATIONS_COUNT_CACHE_KEY, `${count || 0}`)
    }
}
