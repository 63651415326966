import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    AppEventsDispatcher,
    Memoize,
} from '@undock/core'
import {
    UserLimitsProvider,
    UserLimitType,
} from '@undock/feature-plans/services/user-limits.provider'
import { UserAppUsageRegistry } from '@undock/user/services/analytics/app-usage.registry'
import {
    from,
    combineLatest,
    map,
    takeUntil,
    Observable,
    switchMap,
} from 'rxjs'
import moment from 'moment'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import {
    TrackUserAnalyticsEvent,
    UserAnalyticsAction,
} from '@undock/integrations'
import { Router } from '@angular/router'


@Component({
    selector: 'app-time-prompt-limit-counter',
    templateUrl: './prompt-limit-counter.component.html',
    styleUrls: ['./prompt-limit-counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptLimitCounterComponent {

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public readonly TooltipPosition = TooltipPosition

    constructor(
        protected router: Router,
        protected eventsManager: AppEventsDispatcher,
        protected appUsageRegistry: UserAppUsageRegistry,
        protected userLimitsProvider: UserLimitsProvider,
    ) {}

    @Memoize()
    public get promptsCountStream(): ReactiveStream<number> {
        return this.appUsageRegistry.streamCurrentUsageValue(UserLimitType.PromptsCount)
    }

    @Memoize()
    public get promptsRemainingStream(): Observable<number> {
        return combineLatest([
            this.promptsCountStream,
            this.promptsLimitStream
        ]).pipe(
            map(([count, limit]) => Math.max(limit-count, 0)),
            takeUntil(this.destroyedEvent)
        )
    }

    @Memoize()
    public get isPromptLimitReachedStream(): Observable<boolean> {
        return this.promptsRemainingStream.pipe(
            map(remaining => remaining <= 0),
            takeUntil(this.destroyedEvent)
        )
    }

    @Memoize()
    public get promptsLimitStream(): Observable<number> {
        return from(
            this.userLimitsProvider.getPromptsLimit()
        ).pipe(
            takeUntil(this.destroyedEvent)
        )
    }

    @Memoize()
    public get isUnlimitedPromptsStream(): Observable<boolean> {
        return this.promptsLimitStream.pipe(
            map(limit => limit === Infinity)
        )
    }

    @Memoize()
    public get tooltipStream(): Observable<string> {
        return combineLatest([
            this.promptsRemainingStream,
            this.promptsLimitStream,
            this.isPromptLimitReachedStream
        ]).pipe(
            switchMap(async ([remaining, limit, isLimitReached]) => {
                if (!isLimitReached) {
                    let usageEndDate = await this.appUsageRegistry.getCurrentUsageEndDate()
                    let daysRemaining = moment(usageEndDate).diff(moment(), 'days')
                    return `${remaining}/${limit} requests remaining. Refreshes in ${daysRemaining} days.`
                } else {
                    return `You've reached the limit. Upgrade your plan.`
                }
            })
        )
    }

    public async navigateToTheMembershipPage() {
        this.eventsManager.dispatch(
            new TrackUserAnalyticsEvent(
                UserAnalyticsAction.UpgradeSubscriptionClickedBanner,
            ),
        )

        return this.router.navigate(
            ['/', 'settings', 'membership'],
            { queryParams: { upgrade: true } },
        )
    }
}
