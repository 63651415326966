import {
    RouterModule,
    Routes,
} from '@angular/router'
import { NgModule } from '@angular/core'

import {
    InstallIntegrationGuard,
    LoggedInGuard,
    NotAuthorizedUserGuard,
    RegularUserGuard,
} from '@undock/auth'

import { RoutingGuard } from '@web/app/guards/routing.guard'
import { BlockIframeGuard } from '@undock/core/guards/block-iframe.guard'
import { CheckDomainGuard } from '@undock/core/guards/check-domain.guard'
import { ForceAnonymousGuard } from '@undock/auth/guards/force-anonymous.guard'
import { ForceOnboardingGuard } from '@undock/auth/guards/force-onboarding.guard'
import { AccountInitializedGuard } from '@undock/user/routing/guards/account-initialized.guard'

import { NavigationController } from '@web/app/services/navigation-controller.service'

import {
    EmailIsNotVerifiedPageComponent,
    LoginPage,
    LogoutPage,
    ResetPasswordPage,
    SignInPage,
    SignupPage,
} from '@undock/auth-ui'
import { CriticalErrorPage } from '@web/app/components/critical-error.page'
import { MainLayoutComponent } from '@undock/common/layout/components/main-layout/main-layout.component'
import { MeetingCanceledPageComponent } from '@web/app/components/meeting-canceled.page.component'
import { environment } from '@web/environments/environment'
import {
    OAuthSignInPage,
    VerifyEmailPageComponent,
} from '@undock/auth-ui/components/pages'
import { EmbedWrapperGuard } from '@undock/auth/guards/embed-wrapper-guard.service'
import { OnboardingCompleteGuard } from '@undock/onboarding/install/routing/guards/onboarding-complete.guard'
import { IframeOnlyGuard } from '@undock/core/guards/iframe-only.guard'
import { ExtensionRegularUserGuard } from '@undock/auth/guards/extension-regular-user.guard'
import { ExtensionSignInPage } from '@undock/auth-ui/components/pages/extension-signin/extension-sign-in-page.component'
import { InviteSignUpPage } from '@undock/auth-ui/components/pages/invite-sign-up/invite-sign-up.page'
import { PageNotFoundComponent } from '@web/app/components/page-not-found/page-not-found.component'
import { DevDesignSystemComponent } from '@web/app/components/dev-design-system/dev-design-system.component'


/**
 * LazyLoad callbacks declaration
 */
const meetLazyLoad = () => import('@undock/dock/meet/meet.module').then(module => module.MeetModule)
const profileLazyLoad = () => import('@undock/profile/public/profile.module').then(module => module.ProfileModule)
const settingsLazyLoad = () => import('@undock/profile/settings/settings.module').then(module => module.SettingsModule)
const profileEmbedLazyLoad = () => import('@undock/profile/embed/profile-embed.module').then(module => module.ProfileEmbedModule)
const invitesLazyLoad = () => import('@undock/invites/invites.module').then(module => module.InvitesModule)
const partnerOAuthLazyLoad = () => import('@undock/partners/oauth/partners-o-auth.module').then(module => module.PartnersOAuthModule)
const partnerLazyLoad = () => import('@undock/partners/widgets/partners-widgets.module').then(module => module.PartnersWidgetsModule)
const peopleLazyLoad = () => import('@undock/people/people.module').then(module => module.PeopleModule)
const plansLazyLoad = () => import('@undock/time/plans/plans.module').then(module => module.PlansModule)
const timeLazyLoad = () => import('@undock/time/dashboard/time.module').then(module => module.TimeModule)
const terminalLazyLoad = () => import('@undock/terminal/terminal.module').then(module => module.TerminalModule)
const timeFitnessLazyLoad = () => import('@undock/time-fitness/time-fitness.module').then(module => module.TimeFitnessModule)
const onboardingInstallLazyLoad = () => import('@undock/onboarding/install/onboarding-install.module').then(module => module.OnboardingInstallModule)
const onboardingTourLazyLoad = () => import('@undock/onboarding/tour/onboarding-tour.module').then(module => module.OnboardingTourModule)
const userIntegrationsLazyLoad = () => import('@undock/user-integrations/user-integrations.module').then(module => module.UserIntegrationsModule)
const proposalsLazyLoad = () => import('@undock/proposals/proposals.module').then(module => module.ProposalsModule)
const dashboardLazyLoad = () => import('@undock/dashboard/dashboard.module').then(module => module.DashboardModule)
const portalLazyLoad = () => import('@undock/partners/portal/portal.module').then(module => module.PortalModule)
const organizationsLazyLoad = () => import('@undock/organizations/organizations.module').then(module => module.OrganizationsModule)
const formsLazyLoad = () => import('@undock/common/form-creator/form.module').then(module => module.FormModule)
const delegateLazyLoad = () => import('@undock/delegate/delegate.module').then(module => module.DelegateModule)
const chromeSidebarLazyLoad = () => import('@undock/chrome-extension/modules/sidebar/sidebar.module').then(module => module.SidebarModule)
//const chromeCalendarLazyLoad = () => import('@undock/chrome-extension/modules/calendar/calendar.module').then(module => module.CalendarModule)

const routes: Routes = [

    /**
     * ----------------- MAIN LAYOUT -----------------
     */
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [
            EmbedWrapperGuard,
            OnboardingCompleteGuard,
        ],
        children: [
            /**
             * REDIRECTS
             */
            { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
            { path: 'timeline', pathMatch: 'full', redirectTo: 'dashboard/timeline' },

            /**
             * CONTACTS
             */
            { path: 'people', loadChildren: peopleLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard] },

            /**
             * NEW DASHBOARD
             */
            { path: 'dashboard', loadChildren: dashboardLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard] },

            /**
             * PLANS
             */
            { path: 'plans', loadChildren: plansLazyLoad, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },

            /**
             * TIME
             */
            { path: 'time', loadChildren: timeLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard] },

            /**
             * PROPOSALS
             */
            { path: 'proposal', loadChildren: proposalsLazyLoad, canActivate: [BlockIframeGuard] },

            /**
             * USER PROFILE CONTEXT
             */
            { path: 'me', loadChildren: profileLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard] },
            { path: 'u', loadChildren: profileLazyLoad, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },
            { path: 'profile', loadChildren: profileLazyLoad, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },
            { path: 'settings', loadChildren: settingsLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard]},
            { path: 'terminal', loadChildren: terminalLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard] },
            { path: 'time-fitness', loadChildren: timeFitnessLazyLoad, canActivate: [BlockIframeGuard] },
        ],
    },

    /**
     * ----------------- SIDEBAR FREE LAYOUT -----------------
     */

    { path: 'delegate', loadChildren: delegateLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard] },

    /**
     * SYSTEM PAGES
     */
    { path: '404', component: PageNotFoundComponent },
    { path: 'critical-error', component: CriticalErrorPage },
    { path: 'meeting-not-found', component: MeetingCanceledPageComponent },

    { path: 'organizations', loadChildren: organizationsLazyLoad, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },

    /**
     * USER INTEGRATIONS
     */
    // @todo - this has to be moved to a settings module
    { path: 'integrations', loadChildren: userIntegrationsLazyLoad, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },

    /**
     * EMBED USER PROFILE CONTEXT
     */
    { path: 'partner/profile', loadChildren: profileEmbedLazyLoad, canActivate: [ForceAnonymousGuard] },

    { path: 'partner/portal', loadChildren: portalLazyLoad, canActivate: [ForceAnonymousGuard] },

    /**
     * EMBED USER PROFILE CONTEXT
     */
    { path: 'embed/profile', loadChildren: profileEmbedLazyLoad, canActivate: [ForceAnonymousGuard, CheckDomainGuard] },

    /**
     * MEETINGS CONTEXT
     */
    {
        path: 'meet',
        canActivate: [EmbedWrapperGuard, ForceAnonymousGuard, BlockIframeGuard ],
        loadChildren: meetLazyLoad,
    },

    /**
     * ONBOARDING
     */
    { path: 'join', loadChildren: onboardingInstallLazyLoad, canActivate: [NotAuthorizedUserGuard, BlockIframeGuard] },
    {
        path: 'install',
        loadChildren: onboardingInstallLazyLoad,
        canActivate: [LoggedInGuard, AccountInitializedGuard, BlockIframeGuard],
        data: { [LoggedInGuard.DATA_KEY]: { mode: LoggedInGuard.MODE_SIGNUP } },
    },
    { path: 'tour', loadChildren: onboardingTourLazyLoad, canActivate: [IframeOnlyGuard] },

    /**
     * FORM RESPONSE PAGE
     */
    { path: 'f', loadChildren: formsLazyLoad },

    /**
     * SIGN IN / SIGN UP
     */
    { path: 'logout', component: LogoutPage, canActivate: [LoggedInGuard, BlockIframeGuard] },
    { path: 'login', redirectTo: 'signin' },
    { path: 'signup', component: LoginPage, canActivate: [InstallIntegrationGuard, ForceOnboardingGuard, BlockIframeGuard] },
    { path: 'signup/email', component: SignupPage, canActivate: [InstallIntegrationGuard, ForceOnboardingGuard, BlockIframeGuard] },
    { path: 'signin', component: SignInPage, canActivate: [InstallIntegrationGuard, ForceOnboardingGuard, BlockIframeGuard] },
    { path: 'oauth-signin', component: OAuthSignInPage, canActivate: [] },
    { path: 'reset-password', component: ResetPasswordPage, canActivate: [BlockIframeGuard] },

    { path: 'invite', component: InviteSignUpPage, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },

    { path: 'verify-email', component: VerifyEmailPageComponent, canActivate: [BlockIframeGuard] },
    { path: 'email-is-not-verified', component: EmailIsNotVerifiedPageComponent, canActivate: [BlockIframeGuard] },

    { path: 'oauth', loadChildren: partnerOAuthLazyLoad, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },
    { path: 'partner', loadChildren: partnerLazyLoad, canActivate: [EmbedWrapperGuard] },

    /**
     * CHROME EXTENSION
     */
    { path: 'chrome/signin', component: ExtensionSignInPage, canActivate: [IframeOnlyGuard] },
    { path: 'chrome/sidebar', loadChildren: chromeSidebarLazyLoad, canActivate: [ExtensionRegularUserGuard, ForceAnonymousGuard, IframeOnlyGuard] },
    //{ path: 'chrome/calendar', loadChildren: chromeCalendarLazyLoad, canActivate: [ExtensionRegularUserGuard, ForceAnonymousGuard, IframeOnlyGuard] },
    //{ path: 'chrome/schedule', loadChildren: chromeSchedulingWindowLazyLoad, canActivate: [ExtensionRegularUserGuard, ForceAnonymousGuard, IframeOnlyGuard] },

    /**
     * STANDALONE INVITES PAGE
     *
     * @deprecated
     */
    { path: 'connections', loadChildren: invitesLazyLoad, canActivate: [RegularUserGuard, BlockIframeGuard] },

    { path: '**', loadChildren: profileLazyLoad, canActivate: [RoutingGuard, BlockIframeGuard] },
]

if (!environment.production) {
    const searchLazyLoad = () => import('@undock/search/search.module').then(module => module.SearchModule)
    routes.unshift(
        { path: 'search', loadChildren: searchLazyLoad, canActivate: [ForceAnonymousGuard, BlockIframeGuard] },
        { path: 'dev-design-system', component: DevDesignSystemComponent }
    )
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {}),
    ],
    providers: [
        RoutingGuard,
        NavigationController,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
