import { ApiContext } from '../../contracts/api.context'

import { OAuthRoute } from './routes/oauth.route'
import { ClientsRoute } from './routes/clients.route'
import { IntegrationsRoute } from './routes/integrations.route'
import { PortalAccountRoute } from '@undock/api/scopes/partners/routes/portal/portal-account.route'


export class PartnersScope {

    public readonly oauth: OAuthRoute
    public readonly clients: ClientsRoute
    public readonly integrations: IntegrationsRoute
    public readonly portalAccount: PortalAccountRoute

    public constructor(context: ApiContext) {
        this.oauth = new OAuthRoute(context)
        this.clients = new ClientsRoute(context)
        this.integrations = new IntegrationsRoute(context)
        this.portalAccount = new PortalAccountRoute(context)
    }
}
