import {
    Component,
} from '@angular/core'
import {
    IOrganizationInviteAggregate,
} from '@undock/api/scopes/organizations/contracts'
import {
    ValueSubject,
} from '@typeheim/fire-rx'
import { Api } from '@undock/api'
import moment from 'moment'
import { OrganizationsStorage } from '@undock/organizations/services/organizations.storage'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { Router } from '@angular/router'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import { OrganizationInvitesManager } from '@undock/organizations/services/organization-invites.manager'
import { map } from 'rxjs/operators'

type UIOrganizationInvite = IOrganizationInviteAggregate & { dateFromNow: string }

@Component({
    selector: 'app-layout-organization-invites',
    templateUrl: 'organization-invites.component.html',
    styleUrls: [`organization-invites.component.scss`]
})
export class LayoutOrganizationInvitesComponent {

    public TooltipPosition = TooltipPosition
    public isOpened: boolean = false

    public invites$: ValueSubject<UIOrganizationInvite[]> = new ValueSubject([])

    public constructor(
        private readonly api: Api,
        private readonly organizationsProvider: OrganizationsStorage,
        private readonly snackbarManager: SnackbarManager,
        private readonly router: Router,
        private organizationInvitesManager?: OrganizationInvitesManager,
    ) {}

    public ngOnInit() {
        this.initInvitesLoading()
    }

    protected initInvitesLoading() {
        this.organizationInvitesManager.invites$.pipe(
            map(invites => {
                return this.prepareInviteData(invites)
            }),
        ).subscribe(value => {
            this.invites$.next(value)
        })
    }

    protected prepareInviteData(invites: IOrganizationInviteAggregate[]): UIOrganizationInvite[] {
        return invites.map(invite => {
            return {
                ...invite,
                dateFromNow:  moment(invite.createdAt)
                    .startOf('hour')
                    .fromNow(true)
            }
        })
    }

    public openInvitesPopup() {
        this.isOpened = !this.isOpened
    }

    public close() {
        this.isOpened = false
    }

    public async joinOrganization(invite: UIOrganizationInvite) {
        this.invites$.next(
            this.invites$.value.filter(
                item => item._id !== invite._id
            ),
        )

        try {
            await this.api.organizations.invites.respond({
                inviteId: invite._id,
                status: true,
            })
        } catch (error) {
            this.snackbarManager.error("Cannot join organization")
        }

        this.organizationsProvider.triggerOrganizationsRefresh$.next()
    }

    public navigateToInviteViewPage(invite: UIOrganizationInvite) {
        this.router.navigate([`organizations`, `invite`, `${invite._id}`])
    }
}
