<div class="selected-meeting-date-box">
   <div class="date-text date">
       <app-date-picker
               [showAsModal]="true"
               [date]="schedule.start"
               [dateFormat]="dateFormat"
               [minDate]="'1900-01-01T00:00:00Z'"
               (onDateSelected)="handleDateSelected($event)"
       ></app-date-picker>
   </div>

   <div class="date-text time">
       <app-meet-time-slot-selector
               [step]="timeInputStep"
               [labelFormat]="timeFormat"
               [selectedTime]="schedule.start"
               (onSlotSelected)="handleStartTimeChange($event)"
       ></app-meet-time-slot-selector>

       <span class="time-separator">-</span>

       <app-meet-time-slot-selector
               [step]="timeInputStep"
               [labelFormat]="timeFormat"
               [selectedTime]="schedule.end"
               (onSlotSelected)="handleEndTimeChange($event)"
       ></app-meet-time-slot-selector>
   </div>
</div>

<app-time-time-zone-selector-2
        class="timezone-selector-new"
        [defaultSelect]="true"
        [selectedTimeZone]="timeZone"
        (onSelected)="handleSelectedTimeZoneChange($event)"
></app-time-time-zone-selector-2>
