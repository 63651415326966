import { Injectable } from '@angular/core'

import { map } from 'rxjs/operators'
import { ReactiveStream } from '@typeheim/fire-rx'

import { Memoize } from '@undock/core'
import { UserData } from '@undock/user'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { ParticipantsProviderInterface } from '@undock/dock/meet/contracts/ui-providers/participants.provider'


@Injectable()
export class EditEventParticipantsProvider implements ParticipantsProviderInterface {

    public constructor(
        private eventFormStateModel: EventFormStateModel,
    ) {}

    @Memoize()
    public get participantsStream(): ReactiveStream<UserData[]> {
        return new ReactiveStream<UserData[]>(
            this.eventFormStateModel.state.attendeesStream.pipe(
                map(attendees => {
                    return attendees.map(
                        attendee => attendee.userData
                    ).filter(
                        userData => userData.isRegularUser
                    )
                })
            )
        )
    }
}
