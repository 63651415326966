import {
    Component,
    inject,
    Input,
} from '@angular/core'
import {
    ValueSubject,
} from '@typeheim/fire-rx'
import { IDelegate } from '@undock/api/scopes/delegate/contracts'
import { Api } from '@undock/api'
import { ProfilesProvider } from '@undock/user'
import { Router } from '@angular/router'
import { DelegateStorage } from '@undock/delegate/services/storage/delegate.storage'

@Component({
    selector: 'app-assistant-mode-component',
    templateUrl: 'assistant-mode-popup.component.html',
    styleUrls: [`assistant-mode-popup.component.scss`]
}) export class AssistantModePopupComponent {

    @Input() popupTriggerRef

    public  selectedCalendarToManage$ = new ValueSubject<IDelegate>(null)

    protected readonly api = inject(Api)
    protected readonly router = inject(Router)
    public profilesProvider = inject(ProfilesProvider)

    //TODO Fix problem with infinite Memoized grantors values reloading

    public delegateProvider = inject(DelegateStorage)

    public async ngOnInit() {
        const grantors = await this.delegateProvider.ownGrantorsData$
        this.selectedCalendarToManage$.next(grantors[0])
    }

    public closePopupTriggerDirective() {
        this.popupTriggerRef.close()
    }

    public selectGrantor(grantor: IDelegate) {
        this.selectedCalendarToManage$.next(grantor)
    }

    public toggleGrantorPopup(ref) {
        ref.toggle()
    }

    public openDelegateMode() {
        return this.router.navigate(['/', 'delegate', this.selectedCalendarToManage$.value._id, 'dashboard'])
    }
}
