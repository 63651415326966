import { Injectable } from '@angular/core'

import { Config } from '@undock/core'
import { AnalyticsTracker } from '@undock/integrations'
import { User } from '@undock/user'
import { UserAnalyticsAction } from '@undock/integrations/events'


@Injectable()
export class CannyClient implements AnalyticsTracker {
    private _canny: any

    public constructor(
        private config: Config,
    ) {}

    public initialize(): void {
        if (this.config.enableCannySDK) {
            console.log('[ Canny initialized ]')
        }
    }

    public identify(userData: User) {
        if (!userData) {
            return
        }

        if (userData.email) {
            this.canny('identify', {
                appID: this.config.cannyAppId,
                user: {
                    email: userData.email,
                    name: userData.displayName,
                    id: userData.firebaseId,
                    avatarURL: userData.imageUrl,
                    created: userData.createdDate ? new Date(userData.createdDate).toISOString() : null,
                },
            })
        }
    }

    public track(action: UserAnalyticsAction, userData?: User, data?: any) {
        if (!this.config.enableCannySDK || !action || !userData) {
            return
        }

        this.identify(userData)
    }

    protected get canny(): any {
        if (!this._canny) {
            this._canny = window['Canny'] ?? this.cannyStub
        }
        return this._canny
    }

    private cannyStub = (action, data) => {}
}
