import {
    Directive,
    HostListener,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core'
import {
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay'
import { Subscription } from 'rxjs'
import { TemplatePortal } from '@angular/cdk/portal'

@Directive({
    selector: '[popupTrigger]',
    exportAs: 'popupTriggerRef',
})
export class PopupTriggerDirective {

    @Input('popupTrigger')
    public templateRef: TemplateRef<HTMLElement>

    private cdkOverlayRef: OverlayRef
    private closeSubscription: Subscription

    public constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
    ) {}

    @HostListener('click')
    public open() {
        if (!this.templateRef) {
            throw new Error(`Template ref is missing`)
        }

        if (!this.cdkOverlayRef) {
            this.cdkOverlayRef = this.overlay.create({
                hasBackdrop: true,
                scrollStrategy: this.overlay.scrollStrategies.noop(),
                positionStrategy: this.overlay.position().global().centerHorizontally()
                                                                  .centerVertically(),
            })

            this.cdkOverlayRef.attach(
                new TemplatePortal(this.templateRef, this.viewContainerRef),
            )

            this.closeSubscription = this.cdkOverlayRef.backdropClick()
                                         .subscribe(() => this.close())
        }
    }

    public close() {
        this.cdkOverlayRef.dispose()
        this.closeSubscription.unsubscribe()
        delete this.cdkOverlayRef
        delete this.closeSubscription
    }
}
