import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { TagMouseEvent } from '@undock/common/mentions/contracts/tag-mouse-event'
import { ChoiceWithIndices } from '@undock/common/mentions/contracts/choice-with-indices'
import { TextInputAutocompleteComponent } from '@undock/common/mentions/components/text-input-autocomplete/text-input-autocomplete.component'
import { TextInputHighlightComponent } from '@undock/common/mentions/components/text-input-highlight/text-input-highlight.component'


@Component({
    selector: 'app-mentions',
    templateUrl: './mentions.component.html',
    styleUrls: ['./mentions.component.scss'],
})
export class MentionsComponent implements OnInit {

    @ViewChild('autocompleteMenu') autocomplete: TextInputAutocompleteComponent

    @ViewChild('highlights') highlights: TextInputHighlightComponent

    /**
     * Reference to the text input element
     */
    @Input() textInputElement: HTMLTextAreaElement | HTMLInputElement

    /**
     * Reference to the menu template
     */
    @Input() menuTemplate: TemplateRef<any>

    /**
     * The character that will trigger the menu to appear
     */
    @Input() triggerCharacter = '@'

    @Input() openDirection: 'up' | 'down' = 'down'

    /**
     * The regular expression that will match the search text after the trigger character
     */
    @Input() searchRegexp = /^\w*$/

    /**
     * Whether to close the menu when the host textInputElement loses focus
     */
    @Input() closeMenuOnBlur = false

    /**
     * Selected choices (required in editing mode in order to keep track of choices)
     */
    @Input() selectedChoices: any[] = []

    /**
     * A function that formats the selected choice once selected.
     * The result (label) is also used as a choice identifier (e.g. when editing choices)
     */
    @Input() getChoiceLabel: (choice: any) => string

    /**
     * Called when the options menu is shown
     */
    @Output() menuShow = new EventEmitter()

    /**
     * Called when the options menu is hidden
     */
    @Output() menuHide = new EventEmitter()

    /**
     * Called when a choice is selected
     */
    @Output() choiceSelected = new EventEmitter<ChoiceWithIndices>()

    /**
     * Called when a choice is removed
     */
    @Output() choiceRemoved = new EventEmitter<ChoiceWithIndices>()

    /**
     * Called when a choice is selected, removed, or if any of the choices' indices change
     */
    @Output() selectedChoicesChange = new EventEmitter<ChoiceWithIndices[]>()

    /**
     * Called on user input after entering trigger character. Emits search term to search by
     */
    @Output() search = new EventEmitter<string>()

    // --- text-input-highlight.component inputs/outputs ---
    /**
     * The CSS class to add to highlighted tags
     */
    @Input() tagCssClass: string = ''

    /**
     * Called when the area over a tag is clicked
     */
    @Output() tagClick = new EventEmitter<TagMouseEvent>()

    /**
     * Called when the area over a tag is moused over
     */
    @Output() tagMouseEnter = new EventEmitter<TagMouseEvent>()

    /**
     * Called when the area over the tag has the mouse is removed from it
     */
    @Output() tagMouseLeave = new EventEmitter<TagMouseEvent>()

    selectedCwis: ChoiceWithIndices[] = []

    constructor() {}

    ngOnInit(): void {}

    public selectChoice(choice: any) {
        if (this.autocomplete) {
            this.autocomplete.selectChoice(choice)
        }
    }

    onSelectedChoicesChange(cwis: ChoiceWithIndices[]): void {
        this.selectedCwis = cwis
        this.selectedChoicesChange.emit(cwis)
    }

    clearHighlights() {
        if (this.highlights) {
            this.highlights.clear()
        }

        this.onSelectedChoicesChange([])
    }
}
