import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    Optional,
} from '@angular/core'
import {
    from,
    Observable,
} from 'rxjs'
import { IOrganization } from '@undock/api/scopes/organizations/contracts'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import { OrganizationsStorage } from '@undock/organizations/services/organizations.storage'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'


@Component({
    selector: 'app-sidebar-organizations',
    templateUrl: 'sidebar-organizations.component.html',
    styleUrls: ['sidebar-organizations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarOrganizationsComponent {

    public readonly TooltipPosition = TooltipPosition
    public readonly organizations$: Observable<IOrganization[]>
    public readonly cachedOrganizationsCount$: Observable<number>

    @Input()
    @HostBinding('class.collapsed')
    public collapsed: boolean

    public constructor(
        public readonly state: SidebarState,
        @Optional()
        private organizationsProvider?: OrganizationsStorage,
    ) {
        if (this.organizationsProvider) {
            this.organizations$ = this.organizationsProvider.own$
            this.cachedOrganizationsCount$ = this.organizationsProvider.cachedUserOrganizationsCount$
        } else {
            this.organizations$ = from(Promise.resolve([]))
            this.cachedOrganizationsCount$ = from(Promise.resolve(0))
        }
    }
}
