import { ApiRoute } from '@undock/api'
import {
    TimelineEvent,
    TimelineDirection,
} from '@undock/api/scopes/time/contracts/timeline-event'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'


export class TimelineRoute extends ApiRoute {

    init() {
        this.route = 'timeline'
        this.baseURL = this.context.config.apiPlatformURL
    }

    public getById(eventId: string) {
        return this.get<TimelineEvent>()
                   .endpoint(`event/${eventId}`)
    }

    public getByICalUId(eventICalUId: string) {
        return this.get<TimelineEvent>()
                   .endpoint(`event/uid/${eventICalUId}`)
    }

    public getByDockKey(dockKey: string): Promise<TimelineEvent> {
        return this.get<TimelineEvent>().endpoint(`event/dock-key/${dockKey}`)
    }

    public async getTimelineEvents(options: GetTimelineEventsRangeOptions): Promise<TimelineEvent[]> {
        return this.post<TimelineEvent[]>()
                   .withData(options)
                   .endpoint(`events/list`)
    }

    public async getTimelineEventsPage(options: GetTimelineEventsPaginationOptions): Promise<TimelineEvent[]> {
        return this.post<TimelineEvent[]>()
                   .withData(options)
                   .endpoint(`events/list`)
    }
}


export interface BaseTimelineRequest {
    filterByStatus?: RsvpStatus
    filterByAttendeeEmail?: string
}

export interface GetTimelineEventsRangeOptions extends BaseTimelineRequest {
    start: string
    end: string
}

export interface GetTimelineEventsPaginationOptions extends BaseTimelineRequest {
    start: string
    page: number
    pageSize: number
    order: TimelineDirection
}
