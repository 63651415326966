<section class="availability-slot-container"
         [ngClass]="{
            'slot-selected': selected || targeted,
            'priority-mode-only': slot.isSoftBooked || slot.isOutOfHours
         }"
         [style.background-color]="(selected || targeted) ? themeColor : null"
>
    <span class="availability-slot-date-label" [style.color]="(selected || targeted) ? accentTextColor : null">
        {{ slot.label }}
    </span>

    <app-icon-lightning *ngIf="iconColor" [color]="iconColor" class="availability-slot-best-icon"></app-icon-lightning>

    <app-icon-slot-held *ngIf="!best && !suggested && slot.isSoftBooked" class="availability-slot-held-icon"
        [color]="'#8473B5'"
    ></app-icon-slot-held>

<!--    <app-time-available-time-slot-score-icon-->
<!--            [score]="slot.score"-->
<!--            [disabled]="slot.isSoftBooked || slot.isOutOfHours"-->
<!--            [onColor]="selected || targeted ? 'white' : '#2B2733'"-->
<!--            [offColor]="selected || targeted ? '#807A8E' : '#F3F3F9'"-->
<!--            [backgroundColor]="selected || targeted ? 'transparent' : '#FFFFFF'"-->
<!--    ></app-time-available-time-slot-score-icon>-->

    <ng-container *ngIf="editable">
        <ng-container [ngSwitch]="getEditButtonType()">
            <ng-container *ngSwitchCase="SlotEditButtonType.Release">
                <svg class="release-button" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44772 11.4477 6 12 6Z" fill="#402F6F"/>
                </svg>
                <span class="edit-button-label" (click)="makeSlotAvailable()">Make available</span>
            </ng-container>
            <ng-container *ngSwitchCase="SlotEditButtonType.Block">
                <svg class="block-button" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 8.5L8.5 15.5" stroke="#CCC5E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.5 8.5L15.5 15.5" stroke="#CCC5E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="edit-button-label" (click)="makeSlotUnavailable()">Block</span>
            </ng-container>
        </ng-container>
    </ng-container>

</section>
