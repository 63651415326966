import { User } from '@undock/user'
import { UserAnalyticsAttributes } from '@undock/integrations/contracts/user-analytics-attributes';
import { UserAnalyticsAction } from '@undock/integrations/events';

export abstract class AnalyticsTracker {
    public abstract initialize(): void

    public abstract identify(userData: User, customUserAttributes?: UserAnalyticsAttributes): void

    public abstract track(action: UserAnalyticsAction, userData?: User, data?: any): void
}
