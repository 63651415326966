import {
    inject,
    Injectable,
} from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators'
import {
    combineLatest,
    Observable,
} from 'rxjs'

import {
    FirestoreUser,
    PublicProfileData,
} from '@undock/user'
import { Api } from '@undock/api'
import { Memoize } from '@undock/core'
import { CurrentUser } from '@undock/session'
import { Dock } from '@undock/dock/meet/models/dock.model'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import {
    ConferenceAccessStatus,
    DockParticipant,
    DockParticipantScope,
} from '@undock/dock/meet/models/dock/dock-participant.model'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'


@Injectable()
export class DockParticipantsManager {

    public readonly pendingParticipantsStream: ReactiveStream<PublicProfileData[]>
    public readonly pendingDeleteParticipantsEmailsStream: ReactiveStream<string[]>

    @CompleteOnDestroy()
    protected currentDockSubject = new StatefulSubject<Dock>()

    @CompleteOnDestroy()
    protected pendingParticipantsSubject = new ValueSubject<PublicProfileData[]>([])

    @CompleteOnDestroy()
    protected pendingDeleteParticipantsEmailsSubject = new ValueSubject<string[]>([])

    protected readonly ormOnFireContext = inject(OrmOnFireContext)

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected user: CurrentUser,
        protected snackbarManager: SnackbarManager,
    ) {
        this.pendingParticipantsStream = this.pendingParticipantsSubject.asStream()
        this.pendingDeleteParticipantsEmailsStream = this.pendingDeleteParticipantsEmailsSubject.asStream()
    }


    @Memoize()
    public get isDraftModeStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.currentDockSubject.pipe(
                map(dock => dock?.isDraftType),

                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get participantsEmailStream(): ReactiveStream<string[]> {
        return new ReactiveStream<string[]>(
            combineLatest([
                this.activeParticipantsStream,
                this.pendingParticipantsStream,
                this.pendingDeleteParticipantsEmailsSubject,
            ]).pipe(
                /**
                 * Debounce time is required because emails could change multiple times
                 */
                debounceTime(100),
                map(([participants, pendingParticipants, pendingDeleteEmails]) => {
                    return [
                        ...participants.map(
                            participant => participant?.userData?.email,
                        ),
                        ...pendingParticipants.map(
                            pendingParticipantData => pendingParticipantData.email,
                        ),
                    ].filter(
                        /*
                         * Filtering participants with no emails
                         */
                        email => email && email?.length > 0,
                    ).filter(
                        /*
                         * Filtering participants which should be removed
                         */
                        email => !pendingDeleteEmails.includes(email),
                    ).filter(
                        /*
                         * Filtering duplicate emails from array
                         */
                        (email, idx, arr) => arr.indexOf(email) === idx,
                    )
                }),
            ),
        )
    }

    @Memoize()
    public get participantsStream(): ReactiveStream<DockParticipant[]> {
        return new ReactiveStream<DockParticipant[]>(
            this.currentDockSubject.pipe(
                distinctUntilChanged(
                    (prev, next) => prev.id === next.id,
                ),
                switchMap(dock => {
                    return this.ormOnFireContext
                               .createNestedCollection(DockParticipant, dock)
                               .filter(DockParticipantScope.initialized)
                               .stream()
                               .emitUntil(this.destroyEvent)
                }),
                map((participants) => {
                    participants.sort((a, b) => {
                        let isUndockUsers = (a.isUndockUser ? 0 : 1) + (b.isUndockUser ? 0 : 1)

                        switch (isUndockUsers) {
                            /**
                             * If both participants are Undock users or non-Undock users
                             */
                            case 0:
                            case 2:
                                return a.createdAt?.valueOf() - b.createdAt?.valueOf()

                            /**
                             * If one of participants is Undock user
                             */
                            case 1:
                                return (a.isUndockUser ? 0 : 1) - (b.isUndockUser ? 0 : 1)

                            default:
                                return 0
                        }
                    })

                    return participants
                }),

                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get activeParticipantsStream(): ReactiveStream<DockParticipant[]> {
        return new ReactiveStream<DockParticipant[]>(
            combineLatest([
                this.participantsStream,
                this.pendingParticipantsStream,
                this.pendingDeleteParticipantsEmailsStream,
            ]).pipe(
                map(sources => {
                    const [participants, pendingParticipants, removedEmails] = sources

                    const pendingEmails = pendingParticipants.map(p => p.email)

                    /**
                     * Removing participants which are pending to be removed or already removed
                     */
                    return participants.filter(
                        participant => !pendingEmails.includes(participant.userData.email),
                    ).filter(
                        participant => !removedEmails.includes(participant.userData.email) && !participant.removed,
                    )
                }),

                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get currentUserParticipantStream(): ReactiveStream<DockParticipant> {
        return new ReactiveStream<DockParticipant>(
            combineLatest([
                this.user.dataStream,
                this.activeParticipantsStream,
            ]).pipe(
                map(([user, participants]) => {
                    return participants.find(p => p.userId === user._id)
                }),

                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get pendingDeleteParticipantsStream(): ReactiveStream<DockParticipant[]> {
        return new ReactiveStream<DockParticipant[]>(
            combineLatest([
                this.participantsStream,
                this.pendingDeleteParticipantsEmailsStream,
            ]).pipe(
                map(([participants, removedEmails]) => {
                    /**
                     * Removing participants which are pending to be removed or already removed
                     */
                    return participants.filter(
                        participant => removedEmails.includes(participant.email),
                    )
                }),

                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get inQueueParticipantsStream(): Observable<DockParticipant[]> {
        return this.participantsStream.pipe(
            map(
                participants => participants.filter(
                    p => p?.conferenceMeta?.accessStatus === ConferenceAccessStatus.Pending,
                ),
            ),

            takeUntil(this.destroyEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }


    public initializeWithDock(dock: Dock) {
        this.currentDockSubject.next(dock)
    }

    public async addParticipant(user: FirestoreUser) {
        if (!await this.isParticipantAlreadyAdded(user.email)) {
            /**
             * Adding a pending participant to display it on UI
             */
            this.addPendingParticipant(user.toPublicProfileData())

            if (await this.isDraftModeStream) {
                const dock = await this.currentDockSubject

                /**
                 * For Draft meeting we can add participant right away
                 */
                await this.api.meet.participants.addByEmail(dock.id, user.email)

                /**
                 * Removes already added participant from the pending list
                 */
                await this.removePendingParticipant(user.email)
            }

            /**
             * In  Non-Draft mode `sendInvitesAndAddPendingParticipants` method should be called
             */
        }
    }

    public async addParticipantByEmail(email: string) {
        if (!await this.isParticipantExist(email)) {
            /**
             * Adding a pending participant to display it on UI
             */
            await this.addPendingParticipant({
                isGuest: true,
                isRegularUser: false,

                email: email,
                imageUrl: '',
                lastName: '',
                firstName: email,
                displayName: email,
            })

            if (await this.isDraftModeStream) {
                const dock = await this.currentDockSubject

                /**
                 * For Draft meeting we can add participant right away
                 */
                await this.api.meet.participants.addByEmail(dock.id, email)

                /**
                 * Removes already added participant from the pending list
                 */
                await this.removePendingParticipant(email)
            }

            /**
             * In Non-Draft mode `sendInvitesAndAddPendingParticipants` method should be called
             */
        }
    }

    public async removeParticipantByEmail(email: string) {
        const pendingRemoved = await this.removePendingParticipant(email)

        if (!pendingRemoved) {
            if (await this.isDraftModeStream) {
                /**
                 * For Draft mode we should delete participant instantly
                 */
                const dock = await this.currentDockSubject
                await this.api.meet.participants.removeByEmail(dock.id, email)
            } else {
                /**
                 * If this participant isn't a pending one - add email to delete list
                 */
                this.pendingDeleteParticipantsEmailsSubject.next(
                    [...this.pendingDeleteParticipantsEmailsSubject.value, email],
                )
            }
        }
    }

    public async removeParticipant(participant: DockParticipant) {
        return this.removeParticipantByEmail(participant.email ?? participant.userData?.email)
    }

    public removeParticipantFromDeleteList(participant: DockParticipant) {
        this.pendingDeleteParticipantsEmailsSubject.next(
            this.pendingDeleteParticipantsEmailsSubject.value.filter(
                email => email !== participant.userData.email,
            ),
        )
    }

    /**
     * Deletes all participants from PendingDelete list
     */
    public async completeMarkedParticipantsDeletion() {
        const emailsToDelete = await this.pendingDeleteParticipantsEmailsSubject

        for (let email of emailsToDelete) {
            try {
                const dock = await this.currentDockSubject

                await this.api.meet.participants.removeByEmail(dock.id, email)
            } catch (error) {
                console.error(error)
            }
        }

        this.pendingDeleteParticipantsEmailsSubject.next([])
    }

    public async sendInvitesAndAddPendingParticipants() {
        const dock = await this.currentDockSubject
        const pendingParticipants = await this.pendingParticipantsSubject

        try {
            /**
             * Will add all pending participants
             */
            await this.api.meet.participants.massAddByEmails(
                dock.id, pendingParticipants.map(p => p.email),
            )

            /**
             * Removes added participants from the pending list
             */
            await Promise.all(
                pendingParticipants.map(
                    participant => this.removePendingParticipant(participant.email)
                )
            )
        } catch (error) {
            console.warn(`Cannot add dock participants`, error)
            this.snackbarManager.error(
                `Cannot add participant${pendingParticipants.length > 1 ? 's' : ''}. Please try later.`
            )
        }
    }

    protected async removePendingParticipant(email: string) {
        const pendingParticipants = await this.pendingParticipantsSubject

        if (pendingParticipants.find(p => p.email === email)) {
            this.pendingParticipantsSubject.next(
                pendingParticipants.filter(participant => participant.email !== email),
            )

            return true
        }

        return false
    }

    protected async addPendingParticipant(user: PublicProfileData) {
        this.pendingParticipantsSubject.next([
            ...this.pendingParticipantsSubject.value, user,
        ])
    }

    protected async isParticipantExist(email: string): Promise<boolean> {
        const emails = await this.participantsEmailStream

        return emails.includes(email)
    }

    protected async isParticipantAlreadyAdded(email: string) {
        const [participants, pendingParticipants] = await Promise.all([
            this.participantsStream,
            this.pendingParticipantsStream,
        ])

        return [
            ...participants.filter(p => !p.removed).map(p => p.email),
            ...pendingParticipants.filter(p => !p.isRemoved).map(p => p.email),
        ].includes(email)
    }
}
