import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-minus-circle-hollow-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 8C2.75 5.10051 5.10051 2.75 8 2.75C10.8995 2.75 13.25 5.10051 13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.10051 13.25 2.75 10.8995 2.75 8ZM8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25ZM5.33332 7.25C4.9191 7.25 4.58332 7.58579 4.58332 8C4.58332 8.41421 4.9191 8.75 5.33332 8.75H10.6666C11.0809 8.75 11.4166 8.41421 11.4166 8C11.4166 7.58579 11.0809 7.25 10.6666 7.25H5.33332Z" 
                  [attr.fill]="color"/>
        </svg>


    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class MinusCircleHollowComponent extends IconTemplateComponent {
    @Input() width: string | number = 16
    @Input() height: string | number  = 16
    @Input() color: string = '#CECADA'
}
