import { Injectable } from '@angular/core'
import { AppCommand } from '@undock/app-commands/contracts/app-commands'
import { KeyboardEventsListener } from '@undock/hotkeys/services/keyboard-events.listener'

@Injectable()
export abstract class AppCommandsRegistry {
    private readonly storage = new Set<AppCommand>()
    private hotkeysUnsubscribersMap = new WeakMap()

    constructor(private keyboardListener: KeyboardEventsListener) {}

    public register(command: AppCommand): void {
        if (this.storage.has(command)) {
            return
        }
        this.storage.add(command)
        const hotkey = command.getHotkey()
        if (hotkey) {
            let unsubscribe = this.keyboardListener.subscribeToKey(hotkey.shortcut, (keyboardEvent) => { command.handle(keyboardEvent) }, {})
            this.hotkeysUnsubscribersMap.set(command, unsubscribe)
        }
    }

    public unregister(command: AppCommand): void {
        if (!this.storage.has(command)) {
            return
        }

        if (this.hotkeysUnsubscribersMap.has(command)) {
            this.hotkeysUnsubscribersMap.get(command)()
            this.hotkeysUnsubscribersMap.delete(command)
        }
        this.storage.delete(command)
    }

    public listCommands(route = '*') {
        // return copy of internal array to prevent it's modifications
        return [...this.storage].filter(command => command.isAvailableOnRoute(route))
    }
}

@Injectable({
    providedIn: 'root',
})
export class GlobalAppCommandsRegistry extends AppCommandsRegistry {
    // fully inherited
}

@Injectable()
export class DomainAppCommandsRegistry extends AppCommandsRegistry {
    // fully inherited
}
