<ng-container *ngIf="availableMeetingModeOptions as options">
    <mat-select class="customArrow" udInput
                [value]="meetingModeSelected"
                [disabled]="!isAllowedToChangeMode"
                (selectionChange)="onMeetingModeChanged.emit($event.value)"
    >
        <mat-option
                *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled"
        >{{ option.label }}</mat-option>
    </mat-select>
</ng-container>
