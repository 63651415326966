import {
    Component,
    Input,
} from '@angular/core'

import { Observable } from 'rxjs'
import {
    StatefulSubject,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'

import {
    Memoize,
    UploadingFile,
    UploadingFileStatus,
} from '@undock/core'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'


@Component({
    selector: 'app-meet-attachment-upload-view',
    templateUrl: 'upload-view.component.html',
    styleUrls: ['upload-view.component.scss'],
})
export class UploadViewComponent {
    public readonly TooltipPosition = TooltipPosition
    public readonly UploadingFileStatus = UploadingFileStatus

    @CompleteOnDestroy()
    private readonly uploadSubject = new StatefulSubject<UploadingFile>()

    @Input()
    public set upload(value: UploadingFile) {
        this.uploadSubject.next(value)
    }

    @Memoize()
    public get uploadStream(): Observable<UploadingFile> {
        return this.uploadSubject.asObservable()
    }
}
