<ng-container *ngIf="toggleMode; else defaultDropdown">
    <mat-button-toggle-group
            #group="matButtonToggleGroup"
            class="mat-button-toggle-group"
            [value]="value"
            (change)="updateCurrentMeetingMode($event.value)"
    >
        <ng-container *ngIf="allowedModes.includes(MeetingMode.Video)">
            <mat-button-toggle [value]="MeetingMode.Video" aria-label="Video" matTooltip="Video">
                <app-icon-remote [width]="20" [height]="20" [color]="group.value === MeetingMode.Video ? '#2B2733' : '#A59FB9'"></app-icon-remote>
            </mat-button-toggle>
        </ng-container>

        <ng-container *ngIf="allowedModes.includes(MeetingMode.Audio)">
            <mat-button-toggle [value]="MeetingMode.Audio" aria-label="Audio" matTooltip="Audio">
                <app-icon-audio-on [width]="20" [height]="20" [color]="group.value === MeetingMode.Audio ? '#2B2733' : '#A59FB9'"></app-icon-audio-on>
            </mat-button-toggle>
        </ng-container>

        <ng-container *ngIf="allowedModes.includes(MeetingMode.InPerson)">
            <mat-button-toggle [value]="MeetingMode.InPerson" aria-label="In Person" matTooltip="In Person">
                <app-icon-in-person [width]="20" [height]="20" [color]="group.value === MeetingMode.InPerson ? '#2B2733' : '#A59FB9'"></app-icon-in-person>
            </mat-button-toggle>
        </ng-container>
    </mat-button-toggle-group>
</ng-container>

<ng-template #defaultDropdown>
    <app-text-switch-toggle [value]="value"
                            (valueSelected)="updateCurrentMeetingMode($event)" [height]="'40px'"
                            [leftValue]="MeetingMode.Video" [rightValue]="MeetingMode.InPerson"
                            leftIcon="videocam" rightIcon="location_on" iconSize="22px"
    ></app-text-switch-toggle>
</ng-template>
