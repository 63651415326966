import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class Logger {
    info(message?: any, ...optionalParams: any[]) {
        this.log('INFO', message, ...optionalParams)
    }

    debug(message?: any, ...optionalParams: any[]) {
        this.log('DEBUG', message, ...optionalParams)
    }

    error(message?: any, ...optionalParams: any[]) {
        this.log('ERROR', message, ...optionalParams)
    }

    private log(type: string, message?: any, ...optionalParams: any[]) {
        if (typeof message == 'string') {
            console.log(`[APP - ${type}] ${message}`, ...optionalParams)
        } else {
            console.log(`[APP - ${type}]`, message, ...optionalParams)
        }
    }
}
