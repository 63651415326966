<ng-container *ngIf="{
    remaining: promptsRemainingStream | stream,
    limit: promptsLimitStream | stream,
    limitReached: isPromptLimitReachedStream | stream,
    isUnlimited: isUnlimitedPromptsStream | stream,
    tooltip: tooltipStream | stream
} as prompts">
    <div class="prompt-limit-container"
         (click)="navigateToTheMembershipPage()"
         [class.__limit-reached]="prompts.limitReached"
         [matTooltip]="!prompts.isUnlimited ? prompts.tooltip : 'You have unimited requests.'"
         [matTooltipPosition]="TooltipPosition.BOTTOM"
    >
        <app-command-prompt
            [round]="true" [width]="18" [height]="18"
        ></app-command-prompt>

        <div class="__counters">
            <ng-container *ngIf="!prompts.isUnlimited; else unlimitedPromptsTmpl">
                <span class="__counter"
                      [class.__limit-reached]="prompts.limitReached"
                >{{prompts.remaining}}</span>
<!--                <span class="__counter">/</span>-->
<!--                <span class="__counter">{{prompts.limit}}</span>-->
            </ng-container>

            <ng-template #unlimitedPromptsTmpl>
                <span class="__counter __limit-reached">∞</span>
<!--                <span class="__counter">/</span>-->
<!--                <span class="__counter __limit-reached">∞</span>-->
            </ng-template>
        </div>

    </div>
</ng-container>
