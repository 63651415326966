import {
    EventEmitter,
    Injectable,
    NgModuleRef,
    ViewContainerRef,
} from '@angular/core'

import { ReactiveStream } from '@typeheim/fire-rx'

import { DockParticipant } from '@undock/dock/meet/models/dock/dock-participant.model'
import { ParticipantSelectorComponent } from '../participant-selector.component'
import { UserData } from '@undock/user'


interface OpenSelectorOptions {
    title: string,
    onSelectedEmitter: EventEmitter<UserData>,
    selectedIdsStream: ReactiveStream<string[]>
}

@Injectable()
export class ParticipantSelectorService {

    protected host: ViewContainerRef
    protected ngModuleRef: NgModuleRef<any>

    public registerHost(
        host: ViewContainerRef,
        ngModuleRef: NgModuleRef<any>
    ) {
        this.host = host
        this.ngModuleRef = ngModuleRef
    }

    public get isHostAlreadyRegistered(): boolean {
        return Boolean(this.host)
    }

    public async openParticipantsSelector(options: OpenSelectorOptions) {
        if (this.host) {
            const componentRef = this.host.createComponent(ParticipantSelectorComponent, {
                ngModuleRef: this.ngModuleRef,
            })
            componentRef.instance.title = options.title
            componentRef.instance.onSelected = options.onSelectedEmitter
            componentRef.instance.selectedIdsStream = options.selectedIdsStream

            componentRef.instance.onClosed.subscribe(() => {
                componentRef.destroy()
            })

            await componentRef.instance.open()
        } else {
            console.error(`Host isn't registered yet`)
        }
    }
}
