import { Injectable } from '@angular/core'

import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import {
    BookingRequest,
    BookingRequestCreateResult,
    BookingRequestDetailsResponse,
    BookingRequestSlot,
} from '@undock/api/scopes/meet/routes/booking.route'
import { Api } from '@undock/api'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { MeetingMode } from '@undock/dock/meet'
import { BookingRequestExpiredException } from '@undock/dock/meet/exceptions/booking-request-expired.exception'
import { BookingRequestSlotsNotAvailableException } from '@undock/dock/meet/exceptions/booking-request-slots-not-available.exception'
import { BookingRequestCodeNotAvailableException } from '@undock/dock/meet/exceptions/booking-request-code-not-available.exception'

enum BookingRequestErrorCode {
    RequestMissingOrExpired = 404,
    SlotsNotAvailable = 409,
    BookingCodeNotAvailable = 409
}

@Injectable()
export class BookingRequestManager {

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        private api: Api,
    ) { }

    /**
     * Create a new booking request using provided booking request slot
     * returns BookingRequestCreateResult if successful, null if slot is unavailable
     */
    public async createBookingRequest(
        profileUrl: string, // TODO: Use userId
        slot: BookingRequestSlot,
        participantEmails: string[],
        timeZone?: string,
        scheduleId?: string, // TODO: Use scheduleKey
    ): Promise<BookingRequestCreateResult | null> {
        try {
            return await this.api.meet.booking.createIncoming({
                profileUrl: profileUrl,
                slots: [slot],
                participantEmails: participantEmails,
                scheduleId: scheduleId,
                timeZone: timeZone,
            })
        } catch (err) {
            this.handleBookingRequestError(err)
        }
    }

    /**
     * Try and create a new booking request using provided booking code, slots and participants
     * @returns {BookingRequestCreateResult} if successful, null if slot is unavailable
     */
    public async createBookingRequestFromLegacyUrl(
        profileUrl: string,
        bookingCode: string,
        slots: BookingRequestSlot[],
        participantEmails: string[],
        timeZone?: string,
    ): Promise<BookingRequestCreateResult | null> {
        try {
            return await this.api.meet.booking.createOutgoingWithCode({
                bookingCode: bookingCode,
                profileUrl: profileUrl,
                slots: slots,
                participantEmails: participantEmails,
                timeZone: timeZone,
            })
        } catch (err) {
            let status = err?.error?.status ?? err?.error?.statusCode
            if (status === BookingRequestErrorCode.BookingCodeNotAvailable) {
                throw new BookingRequestCodeNotAvailableException(bookingCode)
            }
        }
    }

    public async getBookingRequest(bookingCode: string): Promise<BookingRequest> {
        return this.api.meet.booking.getRequestByCode(bookingCode)
    }

    public async getBookingRequestDetails(bookingCode: string): Promise<BookingRequestDetailsResponse> {
        try {
            return await this.api.meet.booking.getRequestDetailsByCode(bookingCode)
        } catch (err) {
            return null
        }
    }

    /**
     * Add a new booking request slot to an existing booking request.
     * @param bookingCode
     * @param timeStamp
     * @param mode
     * @param duration
     * @returns {BookingRequest} containing the available added slots if successful
     * @throws {BookingRequestExpiredException} if the original request is not found or has expired
     * @throws {BookingRequestSlotsNotAvailableException} if any of the requested slots are no longer available
     */
    public async addSlotToBookingRequest(bookingCode: string, timeStamp: string, mode: MeetingMode, duration: MeetingDuration): Promise<BookingRequest | null> {
        try {
            let slot: BookingRequestSlot = {
                timeStamp: timeStamp,
                meetingMode: mode,
                duration: duration,
            }
            return await this.api.meet.booking.addSlot(bookingCode, slot)
        } catch (err) {
            this.handleBookingRequestError(err)
        }
    }

    public async addParticipantsToBookingRequest(bookingCode: string, emails: string[]): Promise<BookingRequest> {
        return this.api.meet.booking.addParticipants(bookingCode, emails)
    }

    public async removeParticipantsFromBookingRequest(bookingCode: string, emails: string[]): Promise<BookingRequest> {
        return this.api.meet.booking.removeParticipants(bookingCode, emails)
    }

    public async confirmIncomingBookingRequest(
        bookingCode: string,
        meetingDate: string,
        requesterEmail?: string,
        requesterFullname?: string,
        requesterTimeZone?: string,
    ): Promise<BookingRequest> {
        try {
            return await this.api.meet.booking.confirmIncoming(bookingCode, {
                meetingDate: meetingDate,
                requester: {
                    email: requesterEmail ?? undefined,
                    displayName: requesterFullname ?? undefined,
                    timeZone: requesterTimeZone ?? undefined,
                },
            })
        } catch (err) {
            this.handleBookingRequestError(err)
        }
    }

    public async confirmOutgoingBookingRequest(
        bookingCode: string,
        meetingDate: string,
        confirmedByEmail?: string,
        confirmedByFullname?: string,
        confirmedByTimeZone?: string,
    ): Promise<BookingRequest> {
        try {
            return await this.api.meet.booking.confirmOutgoing(bookingCode, {
                meetingDate: meetingDate,
                confirmedBy: {
                    email: confirmedByEmail ?? undefined,
                    displayName: confirmedByFullname ?? undefined,
                    timeZone: confirmedByTimeZone ?? undefined,
                },
            })
        } catch (err) {
            this.handleBookingRequestError(err)
        }
    }

    public async rescheduleBookingRequest(
        bookingCode: string,
        rescheduleSlot: BookingRequestSlot,
        requesterEmail?: string,
        requesterFullname?: string,
        requesterTimeZone?: string,
        message?: string,
    ): Promise<BookingRequest> {
        try {
            return await this.api.meet.booking.reschedule(bookingCode, {
                requester: {
                    email: requesterEmail ?? undefined,
                    displayName: requesterFullname ?? undefined,
                    timeZone: requesterTimeZone ?? undefined,
                },
                slot: rescheduleSlot,
                message: message,
            })
        } catch (err) {
            this.handleBookingRequestError(err)
        }
    }

    public async confirmRescheduleBookingRequest(bookingCode: string): Promise<BookingRequest> {
        try {
            return await this.api.meet.booking.confirmReschedule(bookingCode)
        } catch (err) {
            this.handleBookingRequestError(err)
        }
    }

    public async cancelBookingRequest(
        bookingCode: string,
        requesterEmail?: string,
        requesterFullName?: string,
        requesterTimeZone?: string,
        message?: string,
    ): Promise<BookingRequest> {
        try {
            return await this.api.meet.booking.cancel(bookingCode, {
                requester: {
                    email: requesterEmail ?? undefined,
                    displayName: requesterFullName ?? undefined,
                    timeZone: requesterTimeZone ?? undefined,
                },
                message: message,
            })
        } catch (err) {
            this.handleBookingRequestError(err)
        }
    }

    public async deleteBookingRequest(bookingCode: string): Promise<boolean> {
        try {
            await this.api.meet.booking.deleteRequest(bookingCode)
            return true
        } catch (err) {
            console.warn(`Booking request ${bookingCode} could not be deleted`, err)
        }
    }

    protected handleBookingRequestError(err) {
        let status = err?.error?.status ?? err?.error?.statusCode
        switch (status) {
            case BookingRequestErrorCode.RequestMissingOrExpired:
                throw new BookingRequestExpiredException(err)
            case BookingRequestErrorCode.SlotsNotAvailable:
                throw new BookingRequestSlotsNotAvailableException()
        }
    }
}
