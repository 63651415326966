<div class="list-item clickable">
    <div class="image-box">
        <img [src]="connection.profile.imageUrl | imgThumbnail"
             [alt]="connection.profile.displayName"
             default="/assets/img/placeholders/user.png"/>
    </div>

    <div class="content-box">
        <div class="label">{{ connection.profile.displayName ?? connection.profile.email }}</div>
        <div class="description">Last met {{ connection.lastMetDate | moment :'MMM Do' }}</div>
    </div>
</div>