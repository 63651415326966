import {
    Injectable,
} from '@angular/core'

import {
    Memoize,
} from '@undock/core'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'
import {
    distinctUntilChanged,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs'
import {
    UserAnalyticsObject,
} from '@undock/user/models/analytics/user-analytics-object.model'
import { AuthManager } from '@undock/auth'
import { filter } from 'rxjs/operators'
import { injectCollection } from '@undock/session'

@Injectable({
    providedIn: 'root',
})
export class UserAnalyticsProvider {

    protected readonly UserAnalyticsObjectCollection = injectCollection(UserAnalyticsObject)

    @EmitOnDestroy()
    private readonly destroyEvent = new DestroyEvent()

    public constructor(
        private authManager: AuthManager
    ) { }

    @Memoize()
    public get userAnalyticsStream(): ReactiveStream<UserAnalyticsObject> {
        return new ReactiveStream<UserAnalyticsObject>(
            this.authManager.authUserStream.pipe(
                filter(authUser => Boolean(authUser)),
                distinctUntilChanged(),
                switchMap(async (authUser) => {
                    let object = await this.UserAnalyticsObjectCollection.one(authUser?.uid).get()

                    /**
                     * If the user does not have the analytics object yet, create a new one
                     */
                    if (!object) {
                        if (authUser) {
                            let newObject = await this.UserAnalyticsObjectCollection.new(authUser.uid)
                            newObject.invitesCount = 0
                            newObject.applicationUsage = {}
                            newObject.applicationUsageStartDate = new Date()

                            await this.UserAnalyticsObjectCollection.save(newObject)
                        }
                    }
                    return authUser
                }),
                switchMap(authUser => {
                    return this.UserAnalyticsObjectCollection.one(authUser?.uid)
                                                        .stream()
                                                        .emitUntil(this.destroyEvent)
                }),

                takeUntil(this.destroyEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

}
