import {
    DomainAppCommandsRegistry,
    GlobalAppCommandsRegistry,
} from '@undock/app-commands/services/app-commands.registry'
import {
    AppCommand,
    DomainAppCommand,
    GlobalAppCommand,
} from '@undock/app-commands/contracts/app-commands'
import { Injectable } from '@angular/core'

@Injectable()
export class AppCommandsManager {
    constructor(private globalRegistry: GlobalAppCommandsRegistry, private domainRegistry: DomainAppCommandsRegistry) {}

    public register(command: AppCommand) {
        if (command instanceof GlobalAppCommand) {
            this.globalRegistry.register(command)
        } else if (command instanceof DomainAppCommand) {
            this.domainRegistry.register(command)
        }
    }

    public unregister(command: AppCommand) {
        if (command instanceof GlobalAppCommand) {
            this.globalRegistry.register(command)
        } else if (command instanceof DomainAppCommand) {
            this.domainRegistry.register(command)
        }
    }

    public listCommands(route = '*') {
        return [...this.globalRegistry.listCommands(route), ...this.globalRegistry.listCommands(route)]
    }


}
