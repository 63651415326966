<svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1670_10857)">
        <path d="M100 177.143C142.604 177.143 177.143 142.672 177.143 100C177.143 57.3285 142.452 22.8572 100 22.8572C57.3967 22.8572 22.8574 57.3285 22.8574 100C22.8574 142.672 57.3967 177.143 100 177.143Z" fill="#333137"/>
        <g filter="url(#filter0_d_1670_10857)">
            <path d="M89.7543 49.2918C91.1647 50.2271 92.1838 51.6463 92.6194 53.2817L111.366 123.67C111.868 126.124 110.465 128.763 108.012 129.265L36.8471 147.305C34.3933 147.807 31.7542 146.404 31.2521 143.95L7.51082 49.9209C6.5759 47.3511 8.41161 44.8279 10.8654 44.3258L59.6312 32.0332C61.5224 31.5565 63.5269 31.8991 65.1522 32.977L89.7543 49.2918Z" fill="#5A5860"/>
        </g>
        <path d="M57.4707 48.6969L23.1444 57.3574C22.3792 57.6163 21.3553 57.11 21.3708 56.1864C21.112 55.4213 21.6183 54.3973 22.3254 54.3549L56.5938 45.9107C57.3589 45.6519 58.3829 46.1582 58.4253 46.8653C58.6842 47.6305 58.1779 48.6544 57.4707 48.6969Z" fill="#333137"/>
        <rect x="50.2162" y="81.3853" width="22.5108" height="24.2424" fill="#A7A7A8"/>
        <path d="M38.2761 62.3398L25.126 65.5421C24.3609 65.801 23.5533 65.3527 23.5108 64.6455C23.252 63.8804 23.7583 62.8564 24.4655 62.814L37.3992 59.5537C38.1643 59.2949 39.1883 59.8012 39.2307 60.5083C39.7639 61.115 39.2577 62.139 38.2761 62.3398Z" fill="#333137"/>
        <path d="M62.6003 31.2852L67.8578 51.4799C68.7347 54.266 71.7486 56.0013 74.4768 55.3408L92.5925 50.9178" fill="#66646C"/>
        <path d="M85.3016 90.2464C84.3978 85.8296 83.0187 80.5896 80.6426 76.474C79.3753 74.2791 77.5013 72.6173 74.9046 71.9215C72.308 71.2257 69.6957 71.4535 66.7512 72.0561C61.6852 72.786 57.0519 73.6318 52.2447 75.1269C47.9863 76.3051 43.0207 77.5257 39.2799 80.2342C34.2677 84.2255 34.8817 89.7243 36.0868 95.6134C36.9906 100.03 38.3697 105.27 40.7458 109.386C42.6467 112.678 45.877 114.472 49.6292 114.317C54.4633 114.453 64.1781 111.954 68.7109 110.617C72.7529 109.381 77.3862 108.535 80.8526 105.985C87.1631 102.342 86.8235 96.6843 85.3016 90.2464ZM53.5575 87.5388C53.2562 86.0665 53.9789 85.1005 55.652 85.7807C60.1382 87.2147 64.466 88.3744 68.7359 89.7504C70.467 90.2142 70.6678 91.1958 69.6128 92.5365C66.4632 95.6353 63.2556 98.9505 60.0481 102.266C58.8346 103.332 57.7527 103.042 57.3934 101.786C56.9182 100.963 53.9168 88.7947 53.5575 87.5388Z" fill="#333137"/>
        <g filter="url(#filter1_d_1670_10857)">
            <path d="M189.845 86.9944C190.485 88.5882 190.51 90.3629 189.915 91.974L163.795 162.703C162.883 165.241 159.981 166.551 157.5 165.423L86.3898 138.251C83.8511 137.339 82.5414 134.437 83.4535 131.898L119.209 38.2718C120.121 35.7331 123.024 34.4234 125.504 35.552L174.235 54.0886C176.042 54.7761 177.48 56.1879 178.2 57.9824L189.845 86.9944Z" fill="#5A5860"/>
        </g>
        <path d="M177.229 55.4403L169.557 75.4175C168.587 78.1726 169.997 81.5654 172.968 82.5936L191.056 89.5275" fill="#66646C"/>
        <path d="M161.887 140.39L103.745 117.621C102.288 116.999 101.496 115.627 102.118 114.17L116.925 77.0871C117.49 75.8467 119.136 74.8963 120.376 75.4605L178.577 98.0131C180.033 98.6354 180.825 100.007 180.203 101.464L165.396 138.547C164.774 140.004 163.186 140.738 161.887 140.39Z" fill="#333137"/>
        <path opacity="0.5" d="M136.012 120.21C136.523 121.614 135.133 122.963 133.745 122.411L114.054 114.581C112.67 114.031 112.58 112.108 113.905 111.43L128.107 104.171C129.034 103.697 130.166 104.143 130.522 105.121L136.012 120.21Z" fill="#8C8A90"/>
        <path d="M158.267 128.955C158.774 130.361 157.383 131.705 155.995 131.151L124.402 118.526C123.014 117.971 122.934 116.037 124.272 115.369L147.188 103.927C148.116 103.464 149.239 103.914 149.591 104.889L158.267 128.955Z" fill="#A7A7A8"/>
        <path opacity="0.5" d="M129.311 96.6404C132.341 97.4521 135.359 95.7096 136.17 92.6802C136.982 89.6507 135.24 86.6326 132.21 85.8209C129.181 85.0091 126.163 86.7516 125.351 89.7811C124.539 92.8105 126.282 95.8286 129.311 96.6404Z" fill="#8C8A90"/>
        <path d="M162.612 64.9764L127.361 51.1241C126.928 51.0082 126.611 50.4594 126.943 50.0846L127.059 49.6518C127.175 49.2191 127.724 48.9022 128.099 49.2346L163.351 63.0869C163.783 63.2029 164.1 63.7516 163.768 64.1264L163.652 64.5592C163.752 65.0499 163.204 65.3668 162.612 64.9764Z" fill="#333137"/>
        <path d="M160.54 71.8434L124.855 57.8751C124.423 57.7591 124.106 57.2104 124.438 56.8356L124.554 56.4028C124.67 55.97 125.219 55.6532 125.594 55.9856L161.278 69.9538C161.711 70.0698 162.028 70.6185 161.695 70.9933L161.58 71.4261C161.738 71.7005 160.973 71.9593 160.54 71.8434Z" fill="#333137"/>
        <path d="M142.879 142.95L98.6119 125.523C97.9627 125.349 97.7039 124.584 98.0942 123.992C98.2681 123.343 99.0333 123.084 99.8409 123.533L143.891 140.902C144.54 141.076 144.799 141.841 144.409 142.432C144.019 143.024 143.47 143.34 142.879 142.95Z" fill="#333137"/>
        <path d="M153.391 76.8853L122.351 64.6254C121.702 64.4515 121.443 63.6863 121.834 63.0951C122.008 62.446 122.773 62.1871 123.58 62.6354L154.62 74.8953C155.269 75.0693 155.528 75.8344 155.138 76.4256C154.806 76.8004 154.257 77.1172 153.391 76.8853Z" fill="#333137"/>
        <path d="M157.381 156.345L95.7913 131.725C95.1421 131.551 94.8833 130.785 95.2736 130.194C95.4476 129.545 96.2127 129.286 97.0203 129.735L158.668 154.138C159.317 154.312 159.576 155.077 159.185 155.669C158.795 156.26 158.03 156.519 157.381 156.345Z" fill="#333137"/>
    </g>
    <defs>
        <filter id="filter0_d_1670_10857" x="-36.0273" y="10.178" width="190.785" height="202.163" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="21.645"/>
            <feGaussianBlur stdDeviation="21.645"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0470588 0 0 0 0 0.0588235 0 0 0 0.2 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1670_10857"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1670_10857" result="shape"/>
        </filter>
        <filter id="filter1_d_1670_10857" x="39.8734" y="13.4988" width="193.76" height="217.267" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="21.645"/>
            <feGaussianBlur stdDeviation="21.645"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0470588 0 0 0 0 0.0588235 0 0 0 0.6 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1670_10857"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1670_10857" result="shape"/>
        </filter>
        <clipPath id="clip0_1670_10857">
            <rect width="200" height="200" fill="white"/>
        </clipPath>
    </defs>
</svg>
