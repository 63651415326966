import { ApiContext } from '@undock/api/contracts/api.context'

import { EventsRoute } from '@undock/api/scopes/time/routes/events.route'
import { ReservedSlotsRoute } from '@undock/api/scopes/time/routes/reserved-slots.route'
import { ReportsRoute } from '@undock/api/scopes/time/routes/reports.route'


export class TimeScope {
    public readonly events: EventsRoute
    public readonly reports: ReportsRoute
    public readonly reservedSlots: ReservedSlotsRoute

    public constructor(context: ApiContext) {
        this.events = new EventsRoute(context)
        this.reports = new ReportsRoute(context)
        this.reservedSlots = new ReservedSlotsRoute(context)
    }
}
