import { ApiContext } from '../../contracts/api.context'
import { SearchRoute } from '@undock/api/scopes/search/search.route'


export class SearchScope {
    public readonly global: SearchRoute

    public constructor(context: ApiContext) {
        this.global = new SearchRoute(context)
    }
}
