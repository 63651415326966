import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import {
    TimeCommandActions,
    TimeCommandBlueprintEvent,
} from '@undock/api/scopes/nlp/routes/commands.route'


@Component({
    selector: 'app-time-command-blueprint-event-item',
    templateUrl: './time-command-blueprint-event-item.component.html',
    styleUrls: ['./time-command-blueprint-event-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCommandBlueprintEventItemComponent {

    public readonly iconColor = '#2B2733'

    public readonly TimeCommandActions = TimeCommandActions

    @Input() event: TimeCommandBlueprintEvent

    @Input('action') actionType: TimeCommandActions

    @Input() showParticipants: boolean = false

    @Output() onToggled = new EventEmitter<void>()

    constructor(
        public el: ElementRef
    ) {}

    public getAttendeeString() {
        return this.event.attendees.map(a => `${a.userData?.displayName ?? ''} ${a.email}`)
    }
}
