import {
    Injectable,
    NgZone,
} from '@angular/core'
import { take } from 'rxjs/operators'
import {
    KeyboardEventHandler,
    KeyboardEventHandlerBindings,
    KeyboardEventListener,
    KeyboardEventListenerOptions,
    KeyboardEventUnlistenFunction,
    NormalizedKeys,
    Terminal,
    TerminalWhitelist,
} from '@undock/hotkeys/contracts/keyboard'

const KEY_MAP = {
    '\b': 'Backspace',
    '\t': 'Tab',
    '\x7F': 'Delete',
    '\x1B': 'Escape',
    'Del': 'Delete',
    'Esc': 'Escape',
    'Left': 'ArrowLeft',
    'Right': 'ArrowRight',
    'Up': 'ArrowUp',
    'Down': 'ArrowDown',
    'Menu': 'ContextMenu',
    'Scroll': 'ScrollLock',
    'Win': 'OS',
    ' ': 'Space',
    '.': 'Dot',
}

const KEY_ALIAS = {
    command: 'meta',
    ctrl: 'control',
    del: 'delete',
    down: 'arrowdown',
    esc: 'escape',
    left: 'arrowleft',
    right: 'arrowright',
    up: 'arrowup',
}

@Injectable({
    providedIn: 'root',
})
export class KeyboardEventsListener {

    private listeners: KeyboardEventListener[] = []
    private normalizedKeys: NormalizedKeys = {}

    constructor(
        private zone: NgZone,
    ) {
        /**
         * Aggregate keydown listener which executes all registered listeners in order of priority
         */
        this.zone.runOutsideAngular(
            (): void => {
                document.addEventListener('keydown', this.handleKeyboardEvent, true)
            },
        )
    }

    public subscribeToKey(key: string, handler: KeyboardEventHandler, options: KeyboardEventListenerOptions): KeyboardEventUnlistenFunction {
        let binding = {

        }
        binding[key] = handler

        return this.subscribe(binding, options)
    }

    public subscribe(handler: KeyboardEventHandler | KeyboardEventHandlerBindings, options: KeyboardEventListenerOptions): KeyboardEventUnlistenFunction {
        let listener = this.addListener(this.buildListener(handler, options))

        let unsubscribe = (): void => {
            this.removeListener(listener)
        }

        /**
         * Automatically remove the listener when the provided takeUntil Observable fires any value
         */
        if (options.takeUntil) {
            options.takeUntil.pipe(
                take(1),
            ).subscribe(value => (value !== null && value !== undefined) && unsubscribe())
        }

        return unsubscribe
    }

    private addListener(listener: KeyboardEventListener): KeyboardEventListener {

        this.listeners.push(listener)
        /**
         * Sort in descending priority order
         */
        this.listeners.sort((a: KeyboardEventListener, b: KeyboardEventListener) => {
            return a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0
        })

        return listener
    }

    private buildListener(handler: KeyboardEventHandler | KeyboardEventHandlerBindings, options: KeyboardEventListenerOptions): KeyboardEventListener {

        let listener: KeyboardEventListener = {
            priority: typeof options.priority === 'number' ? options.priority : this.getNextPriority(),
            terminal: this.normalizeTerminal(options.terminal),
            terminalWhitelist: this.normalizeTerminalWhitelist(options.terminalWhitelist),
            preventDefault: options.preventDefault,
            allowInputs: this.normalizeInputs(options.allowInputs),
        }

        if (typeof handler === 'function') {
            /**
             * Default for general handlers for all keys
             */
            if (listener.preventDefault === undefined) {
                listener.preventDefault = false
            }
            listener.handler = handler
        } else if (typeof handler === 'object') {
            /**
             * Default for specific key handlers
             */
            if (listener.preventDefault === undefined) {
                listener.preventDefault = true
            }
            listener.bindings = this.normalizeBindings(handler)
        }
        return listener
    }

    private getKeyFromEvent(event: KeyboardEvent): string {

        let key = event.key || event['keyIdentifier'] || 'Unidentified'
        if (key.startsWith('U+')) {
            key = String.fromCharCode(parseInt(key.slice(2), 16))
        }

        let parts = [KEY_MAP[key] || key]

        if (key.toLowerCase() !== 'alt' && event.altKey) {
            parts.unshift('Alt')
        }
        if (key.toLowerCase() !== 'control' && event.ctrlKey) {
            parts.unshift('Control')
        }
        if (key.toLowerCase() !== 'meta' && event.metaKey) {
            parts.unshift('Meta')
        }
        if (key.toLowerCase() !== 'shift' && event.shiftKey) {
            parts.unshift('Shift')
        }

        return this.normalizeKey(parts.join('.'))
    }

    private handleKeyboardEvent = async (event: KeyboardEvent): Promise<void> => {

        let key = this.getKeyFromEvent(event)

        let isInputEvent = this.isEventFromInput(event)
        let handler: KeyboardEventHandler

        for (let listener of this.listeners) {

            if (handler = listener.handler ?? listener.bindings[key]) {

                // Execute handler if this is NOT an input event that we need to ignore.
                if (!isInputEvent || listener.allowInputs) {

                    if (listener.preventDefault && !listener.terminalWhitelist[key]) {
                        event.stopPropagation()
                        event.preventDefault()
                    }

                    // Right now, we're executing outside of the NgZone. As such, we
                    // have to re-enter the NgZone so that we can hook back into change-
                    // detection. Plus, this will also catch errors and propagate them
                    // through application properly.
                    let result = await this.zone.runGuarded(
                        (): boolean | Promise<boolean> | void => {
                            return handler(event)
                        },
                    )

                    // If the handler returned an explicit False, we're going to treat
                    // this listener as Terminal, regardless of the original settings.
                    if (result === false) {
                        return
                    }
                        // If the handler returned an explicit True, we're going to treat
                    // this listener as NOT Terminal, regardless of the original settings.
                    else if (result === true) {
                        continue
                    }
                }

                // If this listener is terminal for matches, stop propagation.
                if (listener.terminal === 'match') {
                    return
                }
            }

            // If this listener is terminal for all events, stop propagation (unless the
            // event is white-listed for propagation).
            if (listener.terminal === true && !listener.terminalWhitelist[key]) {
                return
            }
        }
    }

    private isEventFromInput(event: KeyboardEvent): boolean {

        if (event.target instanceof Node) {
            let targetNodeName = (event.target as HTMLElement).shadowRoot ? (event.composedPath()[0] as HTMLElement)?.nodeName : event.target.nodeName
            switch (targetNodeName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return true
                default:
                    return false
            }
        }
        return false
    }

    private normalizeBindings(bindings: KeyboardEventHandlerBindings): KeyboardEventHandlerBindings {
        let normalized = Object.create(null)

        for (let key in bindings) {
            normalized[this.normalizeKey(key)] = bindings[key]
        }
        return normalized
    }

    private normalizeInputs(inputs: boolean | undefined): boolean {
        if (inputs === undefined) {
            return false
        }
        return inputs
    }

    private normalizeKey(key: string): string {
        if (!this.normalizedKeys[key]) {

            this.normalizedKeys[key] = key
                .toLowerCase()
                .split('.')
                .map(
                    (segment): string => {
                        return KEY_ALIAS[segment] || segment
                    },
                )
                .join('.')
        }
        return this.normalizedKeys[key]
    }

    private normalizeTerminal(terminal: Terminal | undefined): Terminal {
        if (terminal === undefined) {
            return true
        }
        return terminal
    }

    private normalizeTerminalWhitelist(keys: string[] | undefined): TerminalWhitelist {
        let normalized = Object.create(null)

        if (keys) {
            for (let key of keys) {
                normalized[this.normalizeKey(key)] = true
            }
        }
        return normalized
    }

    private removeListener(listenerToRemove: KeyboardEventListener): void {
        this.listeners = this.listeners.filter(
            (listener: KeyboardEventListener): boolean => {
                return listener !== listenerToRemove
            },
        )
    }

    private getNextPriority(): number {
        return this.listeners?.length ? this.listeners[0].priority + 1 : 100
    }

}
