
export interface RangeMs {
    startMs: number, endMs: number
}

/**
 * 0 - no overlap
 * 1 - 4 different overlap cases
 */
export function getOverlapTypeForRanges(targetRange: RangeMs, boundsRange: RangeMs): number {
    /**
     *  Target range is between bounds dates range
     * `bounds.start` --> `target.start` --> `target.end` --> `bounds.end`
     */
    if (targetRange.startMs >= boundsRange.startMs && targetRange.endMs <= boundsRange.endMs) {
        return 1
    }

    /**
     *  Bounds range is between target range
     * `target.start` --> `bounds.start` --> `bounds.end` --> `target.end`
     */
    if (targetRange.startMs <= boundsRange.startMs && targetRange.endMs >= boundsRange.endMs) {
        return 2
    }

    /**
     *  Target END is between of bounds range
     * `bounds.start` --> `target.end` --> `bounds.end`
     */
    if (targetRange.endMs >= boundsRange.startMs && targetRange.endMs <= boundsRange.endMs) {
        return 3
    }

    /**
     *  Target START is between of bounds range
     * `bounds.start --> `target.start` --> `bounds.end`
     */
    if (targetRange.startMs >= boundsRange.startMs && targetRange.startMs <= boundsRange.endMs) {
        return 4
    }

    return 0
}

/**
 * Returns the duration of `targetRange` inside the `boundsRange` in milliseconds
 */
export function calculateRangeDurationInsideBounds(targetRange: RangeMs, boundsRange: RangeMs): number {
    const overlapType = getOverlapTypeForRanges(targetRange, boundsRange)
    switch (overlapType) {
        /**
         *  Target range is between requested dates range
         * `bounds.start` --> `target.start` --> `target.end` --> `bounds.end`
         */
        case 1:
            return targetRange.endMs - targetRange.startMs

        /**
         *  Bounds range is between target range
         * `target.start` --> `bounds.start` --> `bounds.end` --> `target.end`
         */
        case 2:
            return boundsRange.endMs - boundsRange.startMs

        /**
         *  Target END is between of bounds range
         * `bounds.start` --> `target.end` --> `bounds.end`
         */
        case 3:
            return targetRange.endMs - boundsRange.startMs

        /**
         *  Target START is between of bounds range
         * `bounds.start --> `target.start` --> `bounds.end`
         */
        case 4:
            return boundsRange.endMs - targetRange.startMs

        /**
         * Ranges have no overlap
         */
        case 0: default: return 0
    }
}


export function getTotalRangeForOverlappedRanges(ranges: RangeMs[]): RangeMs {

    const timeStamps = []
    for (let range of ranges) {
        timeStamps.push(range.startMs, range.endMs)
    }

    timeStamps.sort((a, b) => {
        return a - b
    })

    /**
     * Returns total range from lower to bigger value
     */
    return { startMs: timeStamps[0], endMs: timeStamps[timeStamps.length - 1] }
}


export function isRangeContainsSingleTimeStamp(range: RangeMs, timeStamp: number): boolean {
    return range.startMs <= timeStamp && range.endMs >= timeStamp
}
