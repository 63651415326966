import {
    Component,
    Input,
} from '@angular/core'
import { ParticipantListComponent } from '@undock/dock/meet/ui/components/dock-participant-list/participant-list.component'

@Component({
    selector: 'app-meet-dock-participants-preview',
    templateUrl: 'meet-dock-participants-preview.component.html',
    styleUrls: ['meet-dock-participants-preview.component.scss'],
})
export class MeetDockParticipantsPreviewComponent extends ParticipantListComponent {
    @Input() public participantsToDisplay: number
}
