<div class="container signup-page">
    <div class="signup-page__login-wrapper"><!--    right side    -->

        <div class="signup-page__login-box"><!-- wrapper for login fields -->

            <nav class="signup-page__top-navigation">
                <a [routerLink]="'/login'" class="login-page__logo">
                    <app-logo-undock></app-logo-undock>
                </a>
            </nav>

            <div class="signup-page__title -forgot-password">
                <h1>Reset your Undock password</h1>
            </div>

            <form class="signup-page__login-form form-container" [formGroup]="sendEmailForm"
                  (ngSubmit)="sendVerificationEmail()">
                <ng-container [ngSwitch]="resetPasswordEmailBeenSent">
                    <ng-container *ngSwitchCase="false">
                        <div class="form-group email form-container__input-item">
                            <div class="form-field form-field-reset-password email">
                                <input name="email" autocomplete="email" [formControlName]="'email'" [placeholder]="'Enter work email'" type="text"/>
                            </div>

                            <div class="form-group__errors form-container__errors-box -reset-password"
                                 *ngIf="isFormControlInvalid('email')">
                                <p class="error-message" *ngIf="isFormControlHasError('email', 'email')">Email is not
                                    valid</p>
                                <p class="error-message" *ngIf="isFormControlHasError('email', 'required')">Email is
                                    required</p>
                            </div>
                        </div>

                        <div class="form-container__errors -reset-password">
                            <ng-container *ngFor="let error of authenticationErrors">
                                <p class="error-message">{{ error }}</p>
                            </ng-container>
                        </div>

                        <div class="form-group actions form-container__submit-button-box">
                            <button mat-button class="button submit-button submit-button-reset" type="submit"
                                    [disabled]="sendEmailForm.invalid">
                                Send email
                            </button>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="true">
                        <div class="email-been-sent-message">
                            <h2>Email has been sent</h2>
                            <span>Please follow the instructions from the email</span>
                        </div>
                    </ng-container>
                </ng-container>
            </form>
        </div>
    </div>
</div>
