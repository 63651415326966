import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Injectable } from '@angular/core'
import {
    ExtConnector,
    ExtensionMessageType,
    Memoize,
} from '@undock/core'
import {
    Observable,
    share,
    tap,
} from 'rxjs'
import { Router } from '@angular/router'
import {
    map,
    takeUntil,
} from 'rxjs/operators'
import { ExtensionOptionsManager } from '@undock/chrome-extension/services/extension-options.manager'

@Injectable()
export class SidebarViewModel {

    @CompleteOnDestroy()
    protected isSlidePanelOpenSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    protected isProfileMenuOpenSubject = new ValueSubject<boolean>(false)

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    constructor(
        protected router: Router,
        protected extConnector: ExtConnector,
        protected extensionOptionsManager: ExtensionOptionsManager,
    ) {
        this.subscribeForExtensionMessages()
        this.initialize()
    }

    public async initialize() {
        await this.showTimeline()
    }

    @Memoize()
    public get isSlidePanelOpenStream(): ReactiveStream<boolean> {
        return this.isSlidePanelOpenSubject.asStream()
    }

    @Memoize()
    public get isProfileMenuOpenStream(): ReactiveStream<boolean> {
        return this.isProfileMenuOpenSubject.asStream()
    }

    @Memoize()
    public get incomingProposalsCountStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(this.extConnector.updateProposalsCountMessageStream.pipe(
            map(message => message.body ?? 0),
            takeUntil(this.destroyedEvent),
            share()
        ))
    }

    public async showTimeline() {
        this.isSlidePanelOpenSubject.next(false)
        this.extConnector.sendMessageToExt(ExtensionMessageType.ShowTimeline)
        return this.router.navigate(['chrome/sidebar/tl', { outlets: { slide: null } }])
    }

    public async showNewEvent() {
        await this.router.navigate(
            ['chrome/sidebar', { outlets: { slide: ['meet', 'new'] } }], {
                queryParams: {
                    ext: true,
                },
            })
        this.extConnector.sendMessageToExt(ExtensionMessageType.ShowNewEvent)
        this.isSlidePanelOpenSubject.next(true)
    }

    public async toggleProfileMenu() {
        this.isProfileMenuOpenSubject.next(
           !(await this.isProfileMenuOpenSubject)
        )
    }

    public closeProfileMenu() {
        this.isProfileMenuOpenSubject.next(false)
    }

    protected subscribeForExtensionMessages() {
        this.extConnector.closeSidebarMessageStream.subscribe(_ => this.initialize())
        this.extConnector.showTimelineMessageStream.subscribe(_ => this.showTimeline())
    }

}
