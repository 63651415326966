import {
    ActivatedRoute,
    Router,
} from '@angular/router'
import {
    Component,
    EventEmitter,
    HostListener,
    Inject,
    OnInit,
    Output,
} from '@angular/core'
import { Title } from '@angular/platform-browser'

import { combineLatest } from 'rxjs'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import {
    map,
    takeUntil,
} from 'rxjs/operators'

import {
    Config,
    getQueryParam,
    getRouteParam,
    Memoize,
    Validations,
} from '@undock/core'
import {
    ConferenceMode,
    DockType,
    MeetingMode,
    ScheduleMode,
} from '@undock/dock/meet/contracts'
import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import { ConfirmPopupService } from '@undock/common/ui-kit'
import { Dock } from '@undock/dock/meet/models/dock.model'
import { TopicsManager } from '@undock/dock/meet/services/topics.manager'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { MeetingsManager } from '@undock/dock/meet/services/meetings.manager'
import { DockVisibility } from '@undock/dock/meet/contracts/dock/dock-visibility'
import { EditMeetingViewModel } from '@undock/dock/meet/ui/pages/edit-meeting/view-models/edit-meeting.view-model'
import { DraftDockFacade } from '@undock/dock/meet/services/facade/draft-dock.facade'
import { DockParticipantsManager } from '@undock/dock/meet/services/dock/dock-participants.manager'
import { DockIsNotFoundException } from '@undock/dock/meet/exceptions/dock-is-not-found.exception'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import moment from 'moment/moment'
import { default as m } from 'moment'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { SettingsFacade } from '@undock/profile/settings/services/facade/settings.facade'
import {
    User,
    UserSettings,
} from '@undock/user'
import { NOTES_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/notes.adapter'
import { DockFacadeNotesAdapter } from '@undock/dock/meet/services/adapters/dock-facade-notes.adapter'
import { TOPICS_ADAPTER } from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'
import { DockFacadeTopicsAdapter } from '@undock/dock/meet/services/adapters/dock-facade-topics.adapter'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { PARTICIPANTS_PROVIDER } from '@undock/dock/meet/contracts/ui-providers/participants.provider'
import { DockFacadeParticipantsProvider } from '@undock/dock/meet/services/data-providers/dock-facade-participants.provider'
import {
    AnalyticsAction,
    AnalyticsSource,
    AnalyticsTrackedComponent,
    AnalyticsTrackedFeature,
} from '@undock/api/scopes/analytics/analytics.scope'


/**
 * This class should be replaced with a new one
 *
 * @deprecated
 */
@Component({
    selector: 'app-meet-new-meeting',
    templateUrl: 'edit-meeting.page.html',
    styleUrls: ['edit-meeting.page.scss'],
    providers: [
        TopicsManager,
        EditMeetingViewModel,
        DockParticipantsManager,
        AvailabilityProvider,
        AvailabilityViewModel,
        /**
         * Allows initialization of Draft meeting
         */
        { provide: DockFacade, useClass: DraftDockFacade },
        { provide: NOTES_ADAPTER, useClass: DockFacadeNotesAdapter },
        { provide: TOPICS_ADAPTER, useClass: DockFacadeTopicsAdapter },
        { provide: PARTICIPANTS_PROVIDER, useClass: DockFacadeParticipantsProvider },
    ],
})
export class EditMeetingPage implements OnInit {

    public readonly TooltipPosition = TooltipPosition

    public readonly MeetingMode = MeetingMode
    public readonly ScheduleMode = ScheduleMode
    public readonly DockVisibility = DockVisibility
    public readonly ConferenceMode = ConferenceMode

    public readonly state: EditMeetingViewModel

    public readonly currentDockStream: ReactiveStream<Dock>
    public readonly sharedAccessUrlStream: ReactiveStream<string>
    public readonly isOwnerModeStream = this.draftDockFacade.isOwnerModeStream

    @Output() toggleChanged = new EventEmitter<boolean>()

    @CompleteOnDestroy()
    public readonly isRequestProcessingSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    public readonly isMeetingCreatedSuccessfullySubject = new ValueSubject<boolean>(false)

    @EmitOnDestroy()
    protected destroyedEvent = new DestroyEvent()

    public readonly initStartDate: Date
    public readonly initEndDate: Date
    protected readonly MEETING_ID_URL_PARAM = 'id'
    protected readonly PARTICIPANTS_URL_PARAM = 'r'
    protected readonly MEETING_MODE_URL_PARAM = 'm'
    protected readonly MEETING_START_URL_PARAM = 'start'
    protected readonly MEETING_END_URL_PARAM = 'end'

    public constructor(
        protected api: Api,
        protected title: Title,
        protected router: Router,
        protected config: Config,
        protected user: CurrentUser,
        protected route: ActivatedRoute,
        protected settings: SettingsFacade,
        protected snackbarManager: SnackbarManager,
        @Inject(DockFacade)
        protected draftDockFacade: DraftDockFacade,
        protected meetingsManager: MeetingsManager,
        protected editMeetingVM: EditMeetingViewModel,
        protected confirmService: ConfirmPopupService,
        protected participantsManager: DockParticipantsManager,
        protected availabilityProvider: AvailabilityProvider,
    ) {
        /**
         * @TODO: this is a first step of migration to state. Next step should split model and state
         */
        this.state = editMeetingVM

        this.currentDockStream = this.draftDockFacade.currentDockStream
        this.sharedAccessUrlStream = this.draftDockFacade.sharedAccessUrlStream


        const startTime = getQueryParam(this.route, this.MEETING_START_URL_PARAM)
        if (startTime) {
            this.initStartDate = moment(startTime).toDate()
        }
        const endTime = getQueryParam(this.route, this.MEETING_END_URL_PARAM)
        if (endTime) {
            this.initEndDate = moment(endTime).toDate()
        }
    }

    @HostListener('document:keyup.escape', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent) {
        return this.onLeaveTheEditPageButtonClicked()
    }

    @Memoize()
    public get completeEditingButtonTextStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            combineLatest([
                this.state.isMeetingDraftTypeStream,
                this.state.selectedMeetingModeStream,
                this.state.selectedScheduleModeStream,
            ]).pipe(
                map(sources => {
                    const [isDraft, meetingMode, scheduleMode] = sources
                    if (isDraft) {
                        if (meetingMode === MeetingMode.Broadcast) {
                            switch (scheduleMode) {
                                case ScheduleMode.Instant:
                                    return 'Open Room'
                                case ScheduleMode.Schedule:
                                    return 'Schedule Room'
                            }
                        }
                        return 'Save and send invites'
                    }
                    if (meetingMode === MeetingMode.Broadcast) {
                        return 'Save Room'
                    }
                    return 'Save Meeting'
                }),
            ),
        )
    }

    @Memoize()
    public get conferenceLocationPlaceholderStream(): ReactiveStream<string> {
        return new ReactiveStream<string>(
            this.user.settingsStream.pipe(
                map(settings => {
                    if (['zoom', 'external'].includes(settings.conferenceLinkPreference)) {
                        return 'Conference link will be generated'
                    }

                    return ''
                }),
            ),
        )
    }

    @Memoize()
    public get currentUserStream(): ReactiveStream<User> {
        return this.settings.currentUserStream
    }

    @Memoize()
    public get currentUserSettingsStream(): ReactiveStream<UserSettings> {
        return this.settings.currentUserSettingsStream
    }

    public async ngOnInit() {
        /**
         * TODO: Implement separate page for instant meetings
         */
        if (this.router.url.includes('instant')) {
            return this.createInstantMeetingAndNavigateToAgenda()
        }

        let dockId = getRouteParam(this.route, this.MEETING_ID_URL_PARAM)

        /**
         * It's probably dockKey
         *
         * Should initialize lazy-dock and replace the id here
         */
        if (dockId && dockId.length === 12) {
            const handle = await this.api.urlHandle.registry.getUrlHandle(dockId)
            if (handle) {
                dockId = await this.ensureDockExistAndGetId(dockId)
            }
        }

        /**
         * Creating draft meeting if dockId isn't provided
         */
        if (!dockId) {
            dockId = await this.draftDockFacade.createDraftMeeting({
                dates: { start: this.initStartDate, end: this.initEndDate }
            })

            return this.router.navigate(['meet', 'edit', dockId], {
                queryParams: this.router.parseUrl(this.router.url).queryParams
            })
        }

        try {
            await this.draftDockFacade.initializeWithDockId(dockId)

            if (this.initStartDate) {
                this.editMeetingVM.selectAvailabilityDaysCountToDisplay(3)
                await this.editMeetingVM.selectRangeStartForAvailabilityDisplaying(this.initStartDate, true)
                if (this.initEndDate) {
                    let newDuration = m(this.initEndDate).diff( m(this.initStartDate), 'minutes')
                    if (newDuration > 0) {
                        await this.editMeetingVM.selectCustomDuration(newDuration)
                    }
                }
            }
        } catch (error) {
            if (error instanceof DockIsNotFoundException) {
                /**
                 * Will create a new meeting if not found
                 */
                return this.router.navigate(['meet', 'new'])
            }

            this.snackbarManager.error(`Something went wrong. Please try later.`)

            return this.navigateToTheTimeline()
        }

        /**
         * Init view models
         */
        await Promise.all([
            this.editMeetingVM.initViewModel(),

            this.availabilityProvider.initialize({
                emails: this.participantsManager.participantsEmailStream,
                timeZone: this.editMeetingVM.selectedTimeZoneNameStream,
                dateRange: this.editMeetingVM.loadAvailabilityDatesRangeStream,
                meetingMode: this.editMeetingVM.selectedMeetingModeStream,
                meetingDuration: this.editMeetingVM.meetingDurationForAvailabilityStream,

                rescheduleMeetingId: this.editMeetingVM.currentNonDraftMeetingIdStream,
            }),
        ])

        await Promise.all([
            this.importMeetingModeFromUrl(),
            this.importParticipantsFromUrl(),
            this.syncMeetingTitleWithTabTitle(),
        ])

        const { defaultDuration } = await this.user.settings
        await this.state.selectMeetingDuration(defaultDuration)
    }


    public copyMeetingLinkToTheClipboard() {
        return this.editMeetingVM.copyMeetingLinkToTheClipboard()
    }


    public onTitleChanged(value: string) {
        return this.editMeetingVM.setMeetingTitle(value)
    }

    public onLocationChanged(value: string) {
        return this.editMeetingVM.setMeetingLocation(value)
    }

    public onInPersonLocationChanged(value: string) {
        return this.editMeetingVM.setMeetingInPersonLocation(value)
    }

    public onInPersonLocationUrlChanged(value: string) {
        return this.editMeetingVM.setMeetingInPersonLocationUrl(value)
    }

    public onMeetingModeChanged(value: MeetingMode) {
        return this.editMeetingVM.selectMeetingMode(value)
    }

    public onScheduleModeChanged(value: ScheduleMode) {
        return this.editMeetingVM.selectScheduleMode(value)
    }

    public onConferenceModeChanged(value: ConferenceMode) {
        return this.editMeetingVM.selectConferenceMode(value)
    }

    public onVisibilityModeChanged(value: DockVisibility) {
        return this.editMeetingVM.selectVisibilityMode(value)
    }

    public async navigateToTheMeeting() {
        const dock = await this.draftDockFacade.currentDockStream

        return this.router.navigate(['meet', dock.id])
    }

    public async navigateToTheTimeline() {
        return this.router.navigate(['/'], { state: { reloadTimeline: true } })
    }

    /**
     * Handler for 'Save' button
     */
    public async onCompleteEditingButtonClicked() {
        this.isRequestProcessingSubject.next(true)
        let dock = await this.draftDockFacade.currentDockStream

        if (dock.isDraftType) {
            /**
             * Save Draft changes
             */
            dock = await this.editMeetingVM
                             .saveChangesToTheDraftMeeting(dock)

            await this.api.meet.dock.createFromDraft({
                draftMeetingId: dock.id, requestedMeetingType: DockType.Meeting,
            })

            /**
             * Sending analytics
             */
            try {
                /**
                 * Broadcast analytics
                 */
                if (dock.mode === MeetingMode.Broadcast) {
                    const scheduleMode = await this.state.selectedScheduleModeStream
                    await this.api.analytics.track({
                        event: AnalyticsAction.MeetingProposed,
                        source: AnalyticsSource.WebApp,
                        feature: AnalyticsTrackedFeature.OfficeHours,
                        component: scheduleMode === ScheduleMode.Schedule ?
                            AnalyticsTrackedComponent.Scheduled : AnalyticsTrackedComponent.Instant,
                    })
                } else {
                    /**
                     * Meeting / event analytics
                     */
                    await this.api.analytics.track({
                        event: AnalyticsAction.MeetingProposed,
                        source: AnalyticsSource.WebApp,
                        feature: AnalyticsTrackedFeature.NewEvent,
                        properties: { meetingMode: dock.mode }
                    })
                }
            } catch (error) {
                console.log(`Cannot track analytics`, error)
            }
        } else {
            /**
             * @TODO: Handle regular meeting saving
             *      ...............................
             *      1. Reschedule the meeting
             *      ...
             *      2. Update scheduleMode (?)
             *      3. Update visibilityMode (?)
             *      4. Update conferenceMode (?)
             */
            await this.editMeetingVM.saveChangesToTheMeeting(dock)
            await this.participantsManager.completeMarkedParticipantsDeletion()
            await this.participantsManager.sendInvitesAndAddPendingParticipants()

            return this.router.navigate(['meet', dock.id])
        }

        if ((await this.state.selectedScheduleModeStream) === ScheduleMode.Instant) {
            /**
             * For instant meetings we should navigate straight to the conference room
             */
            return this.router.navigate(['meet', dock.id, 'room'], {
                queryParams: { skipLobbyPage: 'true' },
            })
        }

        this.isRequestProcessingSubject.next(false)
        this.isMeetingCreatedSuccessfullySubject.next(true)
    }


    public async onLeaveTheEditPageButtonClicked() {
        const dock = await this.draftDockFacade.currentDockStream

        if (dock.isDraftType) {
            const discardChanges = await this.confirmService.open({
                title: 'Are you sure you want to leave without saving your changes?',
                description: `This action could not be undone`,

                confirmButtonLabel: 'Discard changes',
                discardButtonLabel: 'Back to edit',
            })

            if (discardChanges) {
                /**
                 * Delete draft meeting asynchronously
                 */
                this.draftDockFacade.delete(dock).catch(
                    error => console.log(`Unable delete draft meeting`, error),
                )

                return this.router.navigate(['timeline'])
            }

            return null
        }

        /**
         * Handling changes for regular meetings
         */
        if (await this.editMeetingVM.isMeetingHasUnsavedChangesStream) {
            const saveChanges = await this.confirmService.open({
                title: 'Are you sure you want to leave without saving your changes?',
                description: `This action could not be undone`,

                confirmButtonLabel: 'Save changes',
                discardButtonLabel: 'Discard changes',
            })

            if (saveChanges === null) {
                /**
                 * It seems the popup was closed with no option selected
                 */
                return null
            }

            if (saveChanges) {
                await this.editMeetingVM.saveChangesToTheMeeting(dock)
                await this.participantsManager.completeMarkedParticipantsDeletion()
                await this.participantsManager.sendInvitesAndAddPendingParticipants()
            }
        }

        /**
         * Return the user to the meeting page
         */
        return this.router.navigate(['meet', dock.id])
    }


    protected async importMeetingModeFromUrl() {
        let mode = getQueryParam(
            this.route, this.MEETING_MODE_URL_PARAM, true,
        )

        if (mode) {
            return this.editMeetingVM.selectMeetingMode(mode as MeetingMode)
        }
    }

    protected async importParticipantsFromUrl() {
        const dock = await this.draftDockFacade.currentDockStream

        let participantEmails = getQueryParam<string[]>(
            this.route, this.PARTICIPANTS_URL_PARAM, true,
        )

        if (dock.isDraftType && participantEmails) {
            participantEmails = Array.isArray(participantEmails) ? participantEmails : [participantEmails]

            await Promise.all(
                participantEmails.map(email => this.participantsManager.addParticipantByEmail(email)),
            )
        }
    }

    protected async syncMeetingTitleWithTabTitle() {
        combineLatest([
            this.currentDockStream,
            this.editMeetingVM.defaultMeetingTitleStream,
        ]).pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(sources => {
            const [dock, defaultTitle] = sources

            let prefix = dock?.isDraftType ? 'New' : 'Edit',
                title = Validations.isNotEmptyString(dock?.title) ? dock.title : defaultTitle

            this.title.setTitle(`Undock | ${prefix} - ${title}`)
        })
    }


    /** @deprecated */
    protected async createInstantMeetingAndNavigateToAgenda() {
        const dock = await this.meetingsManager.createInstantMeeting(
            await this.user.dataStream,
        )

        await this.router.navigate([
            'meet', dock.conferenceSharedAccessSecret, 'room',
        ], {
            queryParams: { skipLobbyPage: 'true' },
        })
    }

    protected async ensureDockExistAndGetId(dockKey: string): Promise<string> {
        const dock = await this.api.meet.dock
                               .getBySharedAccessSecret(dockKey)
        return dock.id
    }
}
