import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-wand-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.25 12V16.5" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18 14.25H22.5" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.875 3.75V7.5" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 5.625H9.75" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.75 17.25V20.25" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.25 18.75H17.25" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.4775 3.52798L3.53642 17.469C3.24353 17.7619 3.24353 18.2368 3.53642 18.5297L5.47544 20.4687C5.76833 20.7616 6.2432 20.7616 6.5361 20.4687L20.4771 6.52766C20.77 6.23476 20.77 5.75989 20.4771 5.467L18.5381 3.52798C18.2452 3.23508 17.7704 3.23508 17.4775 3.52798Z" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.5 7.5L16.5 10.5" stroke="#807A8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class WandIconComponent extends IconTemplateComponent {

}
