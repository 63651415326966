import { ApiRoute } from '@undock/api/services/api.route'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'
import { DateRange } from '@undock/time/availability'


export class ReservedSlotsRoute extends ApiRoute {

    public init() {
        this.route = 'time/availability/reserved-slots'
    }

    public getPersonalByDateRange(range: DateRange): Promise<ReservedSlotAggregate[]> {
        return this.post<ReservedSlotAggregate[]>()
                   .withData(range)
                   .endpoint('list-personal')
    }

    public getReservedTimeSlotsByMeetingCode(code: string): Promise<ReservedSlot[]> {
        return this.get<ReservedSlot[]>()
                   .endpoint(`meeting-code/${code}`)
    }

    public removeReservedSlotsByMeetingCode(code: string, timestamp?: string): Promise<void> {
        return this.delete<void>()
                   .endpoint(timestamp ? `meeting-code/${code}/${timestamp}` : `reserved-slots/meeting-code/${code}`)
    }
}

export type ReservedSlotRelatedEntityType = 'Proposal' | 'BookingRequest'

export interface ReservedSlot extends AvailabilitySlot {
    _id: string
    userId: string
    userUId: string

    relatedEntityId: string
    relatedEntityType: ReservedSlotRelatedEntityType

    draftId?: string // TODO: Do we still need this property

    createdAt: Date
    updatedAt: Date
}

export interface ReservedSlotAggregate extends ReservedSlot {
    // Attributes of related Proposal or BookingRequest
    relatedEntityData: {
        title: string
    }
}
