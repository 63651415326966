import { Pipe } from '@angular/core'
import { AsyncPipe } from '@angular/common'

import { Observable } from 'rxjs'


@Pipe({ name: 'asyncDefault', pure: false })
export class AsyncDefaultPipe extends AsyncPipe {

    public transform<T>(obj: null, defaultValue?: any): null;
    public transform<T>(obj: undefined, defaultValue?: any): undefined;
    public transform<T>(obj: Promise<T> | null | undefined, defaultValue?: any): T | null;
    public transform<T>(obj: Observable<T> | null | undefined, defaultValue?: any): T | null;
    public transform(obj: Observable<any> | Promise<any> | null | undefined, defaultValue?: any): any {

        if (!this['_latestValue']) {
            /**
             * `_latestValue` is nullable when the AsyncPipe is initialized.
             * This value will be provided into @Input property of specific component.
             * This code allows to override _latestValue with defaultValue to not provide null to the @Input.
             */
            this['_latestValue'] = defaultValue
            this['_latestReturnedValue'] = defaultValue
        }

        return super.transform(obj as any)
    }
}
