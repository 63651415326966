import { Injectable } from '@angular/core'

import {
    map,
    shareReplay,
    takeUntil,
    tap,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
} from '@typeheim/fire-rx'

import {
    defaultUserSettings,
    SettingsManager,
    User,
    UserAvailableSlotPreferences,
    UserProfileData,
    UserSettings,
} from '@undock/user'
import { Api } from '@undock/api'
import {
    timeZoneCities,
    timeZoneMap,
} from '@undock/core/utils/data-model'
import {
    ExtConnector,
    Memoize,
} from '@undock/core'
import { AuthManager } from '@undock/auth'
import { CurrentUser } from '@undock/session'
import {
    UserAvailableMeetingLength,
    UserConferenceLinkType,
    UserNotificationsSettings,
    UserTimeZoneMode,
} from '@undock/api/scopes/user/contracts/user.interface'
import {
    SnackbarManager,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { TimeZoneHelper } from '@undock/time/availability/services/timezone.helper'


@Injectable({ providedIn: 'root' })
export class SettingsFacade {

    private readonly defaults: Partial<UserSettings> = {
        availabilitySlotLimit: 4,
    }

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected authManager: AuthManager,
        protected currentUser: CurrentUser,
        protected extConnector: ExtConnector,
        protected timeZoneHelper: TimeZoneHelper,
        protected snackbarManager: SnackbarManager,
        protected settingsManager: SettingsManager,
    ) {}


    @Memoize()
    public get currentUserStream(): ReactiveStream<User> {
        return this.currentUser.dataStream
    }

    public get currentUserProfileDataStream(): ReactiveStream<UserProfileData> {
        return new ReactiveStream<UserProfileData>(
            this.currentUser.dataStream.pipe(
                map(
                    data => data.profile ?? {} as UserProfileData,
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get availableTimeZoneCitiesStream(): ReactiveStream<string[]> {
        return new ReactiveStream<string[]>(
            this.currentUserStream.pipe(
                map(
                    profile => timeZoneCities[timeZoneMap[profile.lastTimeZone]] ?? [],
                ),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get currentUserSettingsStream(): ReactiveStream<UserSettings> {
        return new ReactiveStream<UserSettings>(
            this.currentUser.dataStream.pipe(
                map(user => user.settings ?? defaultUserSettings),
                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }


    public async setProfileUrl(value: string) {
        this.refreshCurrentUser({ profileUrl: value })
        await this.api.user.settings.updateProfileUrl(value)
        await this.refreshCurrentUser()
    }

    public async getValidProfileUrl(value: string) {
        return this.api.user.settings.getValidProfileUrl(value)
    }

    public async setDisplayName(value: string) {
        let lastName = '',
            firstName = value,
            displayName = value

        const regExp = /(\w+)\s+(\w+)/
        if (value.search(regExp) >= 0) {
            const match = value.match(regExp)
            lastName = match[2] ?? ''
            firstName = match[1] ?? ''
        }

        this.refreshCurrentUser({
            displayName, firstname: firstName, lastname: lastName,
        })

        return this.api.user.settings.updateName(displayName, firstName, lastName)
    }


    public async setProfileBio(value: string) {
        await this.api.user.settings.updateProfileBio(value)
        await this.refreshCurrentUser()
    }

    public async setProfileWebsite(value: string) {
        await this.api.user.settings.updateProfileWebsite(
            value.replace(/^http(s?):\/\//m, ''),
        )
        await this.refreshCurrentUser()
    }

    public async setProfileImageUrl(value: string) {
        await this.api.user.settings.updateProfileImageUrl(value)
        await this.refreshCurrentUser()
    }


    public async setPhoneNumber(value: string) {
        await this.api.user.settings.updatePhoneNumber(value)
        await this.refreshCurrentUser()
    }


    public async setCustomMeetingLink(value: string) {
        await this.api.user.settings.updateCustomMeetingLink(value)
        await this.refreshCurrentUser()
    }


    public async setConferenceLinkPreference(value: UserConferenceLinkType) {
        await this.api.user.settings.updateConferenceLinkType(value)
        await this.refreshCurrentUser()
    }


    public async setProfileIsPrivate(value: boolean) {
        await this.api.user.settings.updatePrivacyConfiguration(value)
        await this.refreshCurrentUser()
    }


    public async setLimitAvailability(value: boolean) {
        const settings = await this.currentUserSettingsStream

        settings.limitAvailability = value
        if (value && (!settings.availabilitySlotLimit && settings.availabilitySlotLimit !== 0)) {
            settings.availabilitySlotLimit = this.defaults.availabilitySlotLimit
        }

        await this.api.user.settings.updateAvailabilityLimit(
            settings.limitAvailability, settings.availabilitySlotLimit,
        )

        await this.refreshCurrentUser()
    }


    public async setAvailabilitySlotLimit(value: number) {
        await this.api.user.settings.updateAvailabilityLimit(true, value)
        await this.refreshCurrentUser()
    }


    public async setTimeZone(value: string) {
        /**
         * The first city of selected time-zone
         */
        let city = null
        let zoneData = this.timeZoneHelper.getDataForTimezone(value)
        if (zoneData) {
            city = zoneData.cities[0] || zoneData.suggestedCity || zoneData.offsetLabel || zoneData.generalName
        } else {
            city = value
        }

        await this.api.user.settings.updateTimeZone(value, city)

        return this.refreshCurrentUser({
            lastTimeZone: value, lastTimeZoneCity: city,
        })
    }


    public async setTimeZoneCity(value: string) {
        await this.api.user.settings.updateTimeZoneCity(value)
        await this.refreshCurrentUser()
    }


    public async setTimeZoneMode(value: UserTimeZoneMode) {
        this.refreshCurrentUserSettings({ timeZoneMode: value })

        return this.api.user.settings.updateTimeZoneMode(value)
    }


    public async setTruncateMeetings(value: boolean) {
        this.refreshCurrentUserSettings({ truncateMeetings: value })

        return this.api.user.settings.updateTruncateMeetings(value)
    }

    public async setFlexible(value: boolean) {
        this.refreshCurrentUserSettings({ flexible: value })

        return this.api.user.settings.updateFlexible(value)
    }

    public async setTimeBetweenEvents(timeBetweenEvents: number) {
        this.refreshCurrentUserSettings({ timeBetweenEvents })

        return this.api.user.settings.updateTimeBetweenEvents(timeBetweenEvents)
    }

    public async setDefaultDuration(value: number) {
        await this.refreshCurrentUserSettings({ defaultDuration: value })

        return this.api.user.settings.updateDefaultDuration(value)
    }


    public async setMinimumScheduleNotice(value: number) {
        await this.api.user.settings.updateMinScheduleNotice(value)
        await this.refreshCurrentUser()
    }


    public async setMaximumMeetingHoursPerDay(value: number) {
        await this.api.user.settings.updateMaxMeetingHoursPerDay(value)
        await this.refreshCurrentUser()
    }


    public async setWorkingHoursTime(value: number, bound: string) {
        await this.settingsManager.updateWorkingHours(
            await this.currentUserSettingsStream, value, bound as any,
        )
        await this.refreshCurrentUser()
    }

    public async setMeetingIntervalPreference(value: boolean, interval: string) {
        // Increases UI responsiveness
        this.currentUser.settings.then(settings => {
            settings.meetingIntervalPreference[interval] = value
            return this.refreshCurrentUserSettings({
                meetingIntervalPreference: settings.meetingIntervalPreference,
            })
        })

        await this.api.user.settings.updatePreferredTimeWindows(interval as any, value)
        await this.refreshCurrentUser()
    }

    public async setOnboardingComplete(status: boolean) {
        await this.refreshCurrentUser({ onboardingComplete: status })
        return this.api.user.settings.updateOnboardingComplete(status)
    }

    public async setOnboardingCompletedStep(completedStep: string) {
        await this.refreshCurrentUser({ onboardingCompletedStep: completedStep })
        return this.api.user.settings.updateOnboardingCompletedStep(completedStep)
    }

    public async setNotificationsSettings(settings: UserNotificationsSettings) {
        this.refreshCurrentUserSettings({ notifications: settings })
        return this.api.user.settings.updateNotificationsSettings(settings)
    }

    /**
     * This property is moved into Standard schedule
     *
     * @deprecated
     */
    public async updateTimeProfile(
        availableSlots: UserAvailableSlotPreferences,
    ) {
        const settings = await this.currentUser.settings
        settings.availableSlots = availableSlots

        for (let day in availableSlots) {
            for (let h in availableSlots[day].map) {
                const hour = parseInt(h)

                for (let slot of availableSlots[day].map[hour]) {
                    if (slot.available) {
                        if (hour < settings.minSlotHour) {
                            settings.minSlotHour = hour
                        }

                        if (hour > settings.maxSlotHour) {
                            settings.maxSlotHour = hour
                        }
                    }
                }
            }
        }

        return Promise.all([
            this.refreshCurrentUserSettings(settings),
            this.api.user.settings.massUpdateUserProperties({ settings }),
        ])
    }

    /**
     * This property is moved into Standard schedule
     *
     * @deprecated
     */
    public async updateAvailableDurationValues(
        availableDurationValues: Record<UserAvailableMeetingLength, boolean>
    ) {
        const settings = await this.currentUser.settings

        settings.availabilityIntervals = availableDurationValues

        return Promise.all([
            this.refreshCurrentUserSettings(settings),
            this.api.user.settings.massUpdateUserProperties({ settings }),
        ])
    }


    public async deleteAccount(): Promise<void> {
        try {
            await this.api.user.profile.deleteCurrentUserProfile()
        } catch (error) {
            console.warn(`Unable delete account`, error)
        } finally {
            await this.authManager.logout()
        }
    }


    public async refreshCurrentUser(updates?: Partial<User> | Record<string, any>) {
        await this.currentUser.refreshProperties(updates)
    }

    public async refreshCurrentUserSettings(updates: Partial<UserSettings> | Record<string, any>) {
        return this.refreshCurrentUser({
            settings: {
                ...await this.currentUserSettingsStream, ...updates,
            },
        })
    }
}
