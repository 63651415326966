import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-dollar-icon',
    template: `
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99992 2.08301C4.73223 2.08301 2.08325 4.73199 2.08325 7.99967C2.08325 11.2674 4.73223 13.9163 7.99992 13.9163C11.2676 13.9163 13.9166 11.2674 13.9166 7.99967C13.9166 4.73199 11.2676 2.08301 7.99992 2.08301ZM0.583252 7.99967C0.583252 3.90356 3.90381 0.583008 7.99992 0.583008C12.096 0.583008 15.4166 3.90356 15.4166 7.99967C15.4166 12.0958 12.096 15.4163 7.99992 15.4163C3.90381 15.4163 0.583252 12.0958 0.583252 7.99967ZM5.24992 6.49529C5.24992 5.43916 6.10607 4.58301 7.1622 4.58301H7.24992V3.99967C7.24992 3.58546 7.5857 3.24967 7.99992 3.24967C8.41413 3.24967 8.74992 3.58546 8.74992 3.99967V4.58464C9.86188 4.62839 10.7499 5.54366 10.7499 6.66634C10.7499 7.08055 10.4141 7.41634 9.99992 7.41634C9.5857 7.41634 9.24992 7.08055 9.24992 6.66634C9.24992 6.37247 9.03261 6.12935 8.74992 6.08891V7.4591L9.44236 7.68992C10.2232 7.9502 10.7499 8.68096 10.7499 9.50406C10.7499 10.5602 9.89376 11.4163 8.83764 11.4163H8.74992V11.9997C8.74992 12.4139 8.41413 12.7497 7.99992 12.7497C7.5857 12.7497 7.24992 12.4139 7.24992 11.9997V11.4147C6.13796 11.371 5.24992 10.4557 5.24992 9.33301C5.24992 8.91879 5.5857 8.58301 5.99992 8.58301C6.41413 8.58301 6.74992 8.91879 6.74992 9.33301C6.74992 9.62688 6.96723 9.87 7.24992 9.91043V8.54024L6.55748 8.30943C5.77662 8.04914 5.24992 7.31839 5.24992 6.49529ZM7.24992 6.9591V6.08301H7.1622C6.9345 6.08301 6.74992 6.26759 6.74992 6.49529C6.74992 6.67274 6.86347 6.83029 7.03182 6.88641L7.24992 6.9591ZM8.74992 9.04024V9.91634H8.83764C9.06534 9.91634 9.24992 9.73176 9.24992 9.50406C9.24992 9.32661 9.13637 9.16906 8.96801 9.11294L8.74992 9.04024Z" 
                  [attr.fill]="color"/>
        </svg>

    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class DollarIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 16
    @Input() height: string | number  = 16
    @Input() color: string = '#BD970F'
}
