import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactivePromise } from '@typeheim/fire-rx'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class HotkeysProvider {

  private static injectorState = new ReactivePromise<Injector>()

  constructor(private injector: Injector) {
    HotkeysProvider.injectorState.resolve(injector)
  }

  static get injector(): ReactivePromise<Injector> {
    return HotkeysProvider.injectorState
  }
}
