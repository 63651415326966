import {
    Component,
    ChangeDetectionStrategy,
    Input,
    ElementRef,
    Output,
    EventEmitter,
    Inject,
} from '@angular/core'

import moment, { Moment } from 'moment'

import {
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    CompleteOnDestroy,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    ValueSubject,
} from '@typeheim/fire-rx'
import { combineLatest } from 'rxjs'

import {
    clone,
    Memoize,
} from '@undock/core'
import {
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'
import { AvailabilityProvider } from '@undock/time/availability/services/availability.provider'
import { AvailabilityViewModel } from '@undock/profile/public/view-models/availability.vmodel'
import { EventFormStateModel } from '@undock/dock/meet/services/state-models/event-form.state-model'
import { DateRange } from '@undock/time/availability'
import {
    Moment as MomentRange
} from '@undock/core'
import {
    KeyboardShortcut,
    UseKeyboardShortcuts,
} from '@undock/hotkeys/services/keyboard-shortcuts.decorator'
import { IS_BETA_USER } from '@undock/feature-plans/tokens/is-beta-user'


@UseKeyboardShortcuts({
    takeUntilPropertyKey: 'destroyEvent'
})
@Component({
    selector: 'app-meet-event-form-available-slot-selector',
    templateUrl: 'event-form-available-slot-selector.component.html',
    styleUrls: [
        '_shared/event-form-schedule.scss',
        'event-form-available-slot-selector.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormAvailableSlotSelectorComponent {

    public readonly state = this.eventFormStateModel.state
    public readonly isAvailabilityLoadingStream: ReactiveStream<boolean>
    public readonly selectedAvailabilityDayStream: ReactiveStream<Moment>

    @CompleteOnDestroy()
    public readonly targetedSlot$ = new ValueSubject<AvailabilitySlot>(null)

    @CompleteOnDestroy()
    protected readonly selectedTimeStamps$ = new ValueSubject<string[]>([])

    @CompleteOnDestroy()
    protected readonly initiallySelectedTimeStamps$ = new ValueSubject<string[]>([])

    @CompleteOnDestroy()
    protected readonly removedRanges$ = new ValueSubject<DateRange[]>([])

    @CompleteOnDestroy()
    public readonly isSettingsOpened = new ValueSubject<boolean>(false)

    @Input('selectedTimestamps') set initiallySelectedTimestamps(value: string[]) {
        this.initiallySelectedTimeStamps$.next(value)
    }

    @Input() set removedRanges(value: DateRange[]) {
        this.removedRanges$.next(value)
    }

    @Input('multiSelect') isMultiSelectMode: boolean = false
    @Input() selectSlotOnDayChange: boolean = true

    @Output() onSlotSelected = new EventEmitter<AvailabilitySlot>()
    @Output('onConfirm') onSlotChangesConfirmed = new EventEmitter<{
        addedSlots: AvailabilitySlot[] ,
        removedSlots: AvailabilitySlot[]
    }>()

    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        public readonly elementRef: ElementRef,
        @Inject(IS_BETA_USER)
        public readonly isBetaUser$: ReactiveStream<boolean>,
        protected readonly eventFormStateModel: EventFormStateModel,
        protected readonly availabilityProvider: AvailabilityProvider,
        protected readonly availabilityViewModel: AvailabilityViewModel,
    ) {
        this.isAvailabilityLoadingStream = this.availabilityViewModel.isAvailabilityLoadingStream
        this.selectedAvailabilityDayStream = this.availabilityViewModel.selectedAvailabilityDayStream
    }

    @Memoize()
    public get availableSlotsStream(): ReactiveStream<AvailabilitySlot[]> {
        return new ReactiveStream<AvailabilitySlot[]>(
            combineLatest([
                this.availabilityViewModel.displayAvailabilityStream,
                this.availabilityViewModel.selectedAvailabilityDayStream,
                this.selectedTimeStamps$,
                this.removedRanges$,
            ]).pipe(
                takeUntil(this.destroyEvent),
                map(([availability, day, selectedTimestamps, removedRanges]) => {
                    let set = availability?.find(set => set.day.isSame(day, 'day'))

                    let clonedSlots = !!set ? clone(set.slots) : []

                    if (this.isMultiSelectMode) {
                        clonedSlots.forEach(slot => {
                            if (selectedTimestamps.includes(slot.timeStamp)) {
                                slot.isSelected = true
                            }
                        })
                    }

                    if (set && removedRanges?.length) {

                        return clonedSlots.filter(
                            slot => {
                                let slotRange = MomentRange.range(moment(slot.timeStamp), moment(slot.timeStamp).add(slot.duration, 'minutes'))
                                return !removedRanges.some(range => {
                                    let removedRange = MomentRange.range(range.start, range.end)
                                    return slotRange.overlaps(removedRange)
                                })
                            }
                        )
                    }

                    return clonedSlots
                }),
            ),
        )
    }

    @Memoize()
    public get isUnconfirmedChangesStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            combineLatest([
                this.selectedTimeStamps$,
                this.initiallySelectedTimeStamps$
            ]).pipe(
                takeUntil(this.destroyEvent),
                map(([selectedTimes, initialSelectedTimes]) => {
                    return this.isMultiSelectMode
                        && (selectedTimes.length !== initialSelectedTimes.length
                            || selectedTimes.some(time => !initialSelectedTimes.includes(time)))
                }),
            ),
        )
    }

    public async ngOnInit() {
        let initialSelectedTimetamps = await this.initiallySelectedTimeStamps$
        if (initialSelectedTimetamps?.length) {
            for (let timestamp of initialSelectedTimetamps) {
                await this.toggleAvailabilitySlot({
                    timeStamp: timestamp
                } as AvailabilitySlot)
            }
        }

        this.targetedSlot$.pipe(
            takeUntil(this.destroyEvent),
        ).subscribe(selected => {
            if (selected && this.selectSlotOnDayChange) {
                return this.selectAvailabilitySlot(selected)
            }
        })
    }

    @KeyboardShortcut('Left')
    public async displayPrevAvailabilityDays() {
        await this.availabilityViewModel.selectAvailabilityDay(
            (await this.availabilityViewModel.selectedAvailabilityDayStream)
                .clone().subtract(1, 'day'),
        )
        setTimeout(() => {
            this.targetedSlot$.next()
            this.activatePrevListItem()
        }, 250)
    }

    @KeyboardShortcut('Right')
    public async displayNextAvailabilityDays() {
        await this.availabilityViewModel.selectAvailabilityDay(
            (await this.availabilityViewModel.selectedAvailabilityDayStream)
                .clone().add(1, 'day'),
        )
        setTimeout(() => {
            this.targetedSlot$.next()
            this.activateNextListItem()
        }, 250)
    }

    public async selectAvailabilitySlot(slot: AvailabilitySlot) {
        if (this.isMultiSelectMode) {
            await this.toggleAvailabilitySlot(slot)
        } else {
            this.onSlotSelected.emit(slot)
        }
        await this.availabilityViewModel.selectAvailabilityDay(moment(slot.timeStamp))
    }

    @KeyboardShortcut('Tab')
    private async selectTargetedAvailabilitySlot() {
        let targetedSlot = await this.targetedSlot$
        if (targetedSlot) {

            if (this.isMultiSelectMode) {
                await this.toggleAvailabilitySlot(targetedSlot)
            } else {
                this.onSlotSelected.emit(targetedSlot)
            }
            await this.availabilityViewModel.selectAvailabilityDay(moment(targetedSlot.timeStamp))
        }
    }

    private async toggleAvailabilitySlot(slot: AvailabilitySlot) {
        if (!!slot && this.isMultiSelectMode) {
            let selectedTimestamps = await this.selectedTimeStamps$
            if (selectedTimestamps) {
                let index = selectedTimestamps?.findIndex(t => t === slot.timeStamp)
                if (index === -1) {
                    this.selectedTimeStamps$.next([...selectedTimestamps, slot.timeStamp])
                }
                else {
                    this.selectedTimeStamps$.next(selectedTimestamps.filter(t => t !== slot.timeStamp))
                }
            }
        }
    }

    @KeyboardShortcut('Up')
    public async activatePrevListItem() {
        const options = await this.availableSlotsStream

        if (!this.targetedSlot$.value) {
            this.targetedSlot$.next(
                options[options.findIndex(option => option.free)] ?? null,
            )
        } else {
            const currItemIndex = options.findIndex(
                option => option?.timeStamp === this.targetedSlot$.value?.timeStamp
            )

            let prevItemIndex: number
            if (currItemIndex > 0) {
                for (let index = currItemIndex - 1; index >= 0; index--) {
                    if (options[index].free) {
                        prevItemIndex = index
                        break
                    }
                }
            }

            if (!prevItemIndex) {
                prevItemIndex = options.findIndex(option => option.free)
            }

            this.targetedSlot$.next(options[prevItemIndex])
        }

        this.ensureFocusedItemVisible().catch(error => console.error(error))
    }

    @KeyboardShortcut('Down')
    public async activateNextListItem() {
        const options = await this.availableSlotsStream

        if (!this.targetedSlot$.value) {
            this.targetedSlot$.next(
                options[options.findIndex(option => option.free)] ?? null,
            )
        } else {
            const currItemIndex = options.findIndex(
                option => option?.timeStamp === this.targetedSlot$.value?.timeStamp
            ) || 0

            let nextItemIndex: number
            if (currItemIndex < options.length) {
                for (let index = currItemIndex + 1; index < options.length; index++) {
                    if (options[index].free) {
                        nextItemIndex = index
                        break
                    }
                }
            }

            if (!nextItemIndex) {
                nextItemIndex = currItemIndex
            }

            this.targetedSlot$.next(options[nextItemIndex < options.length ? nextItemIndex : options.length - 1])
        }

        this.ensureFocusedItemVisible().catch(error => console.error(error))
    }

    public async confirmChanges() {
        if (this.isMultiSelectMode) {
            let [slots, initiallySelectedTimestamps] = await Promise.all([
                this.availableSlotsStream,
                this.initiallySelectedTimeStamps$
            ])
            let added = [], removed = []

            for (let slot of slots) {
                if (slot.isSelected) {
                    if (!initiallySelectedTimestamps.includes(slot.timeStamp)) {
                        added.push(slot)
                    }
                } else {
                    if (initiallySelectedTimestamps.includes(slot.timeStamp)) {
                        removed.push(slot)
                    }
                }
            }

            this.onSlotChangesConfirmed.emit({
                addedSlots: added,
                removedSlots: removed
            })
        }
    }

    /**
     * This is a temporary solution
     * TODO: Rework with better approach using directive assigned to every list item
     */
    protected async ensureFocusedItemVisible() {
        /**
         * Searching for all list items in the target dropdown
         */
        const listItems = document.querySelectorAll<HTMLElement>(
            '.event-form-slot-select-dropdown .available-slots-list .available-slot'
        )

        Array.from(listItems).forEach(listItem => {
            if (listItem.classList.contains('targeted')) {
                listItem.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        })
    }
}
