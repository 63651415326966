import {
    Input,
    OnInit,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'

export enum SlotEditButtonType {
    Block,
    Release,
    Cancel
}

@Component({
    selector: 'app-time-available-time-slot-view',
    templateUrl: 'available-time-slot-view.component.html',
    styleUrls: ['available-time-slot-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableTimeSlotViewComponent implements OnInit{
    @Input() slot: AvailabilitySlot
    @Input() selected: boolean = false
    @Input() suggested: boolean = false
    /**
     * TODO: For when keyboard navigation is implemented
     */
    @Input() targeted: boolean = false
    @Input() best: boolean = false

    @Input() editable: boolean = false

    /**
     * TODO: For "block" command
     */
    @Input() blocked: boolean = false

    @Input() iconColor: string

    @Input() themeColor: string = '#2B2733'
    @Input() accentColor: string = '#DFC051'
    @Input() accentTextColor: string = null

    ngOnInit() {
        const {best, recommended} = this.slot || {};
        if (best){
            this.iconColor = this.accentColor; // Complementary/Gold Main
        } else if(recommended) {
            this.iconColor = '#CECADA'; // Grayscale/Light 1
        }
    }

    SlotEditButtonType = SlotEditButtonType

    public makeSlotAvailable() {

    }

    public makeSlotUnavailable() {

    }

    public getEditButtonType(): SlotEditButtonType {
        if (this.blocked || this.slot.isSoftBooked || this.slot.isOutOfHours) {
            return SlotEditButtonType.Release
        }
        else {
            return SlotEditButtonType.Block
        }
    }
}
