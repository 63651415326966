import { Injectable } from '@angular/core'
import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { CalendarGridEvent } from '@undock/common/calendar-grid/contracts/calendar-grid-event'

export interface GridDataSourceInterface {
    fetch(start: Date, end: Date): Promise<any>
}

@Injectable()
export class GridDataSource implements GridDataSourceInterface {

    @CompleteOnDestroy()
    public readonly displayedEvents$ = new ValueSubject<CalendarGridEvent[]>([])

    public async fetch(start: Date, end: Date): Promise<void> {}
}
