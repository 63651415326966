import {
    Router,
    ActivatedRoute,
} from '@angular/router'
import {
    ChangeDetectorRef,
    Component,
} from '@angular/core'

import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { AuthManager } from '@undock/auth'
import { CurrentUser } from '@undock/session'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'
import { IntegrationsManager } from '@undock/integrations'


@Component({
    selector: 'app-oauth-sign-in-page',
    templateUrl: 'oauth-sign-in-page.component.html',
    styleUrls: [
        'oauth-sign-in-page.component.scss',
        '../shared-styles/login-signup.scss',
    ],
})
export class OAuthSignInPage {

    /**
     * Default redirect to the homepage
     */
    protected redirectPath = ''

    @CompleteOnDestroy()
    public readonly isLoadingStream = new ValueSubject<boolean>(true)

    @CompleteOnDestroy()
    public readonly ssoAuthErrorStream = new ValueSubject<string>(null)

    public constructor(
        private router: Router,
        private authManager: AuthManager,
        private currentUser: CurrentUser,
        private sidebarState: SidebarState,
        private activatedRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        private integrationsManager: IntegrationsManager,
    ) {
        this.sidebarState.hideSidebar()
    }

    public async ngOnInit() {
        /**
         * Get the redirect path from the query
         */
        if (this.activatedRoute.snapshot.queryParamMap.has('redirectPath')) {
            this.redirectPath = decodeURIComponent(
                this.activatedRoute.snapshot.queryParamMap.get('redirectPath')
            )
        }

        /**
         * Handles authorization redirect
         */
        const signInProvider = await this.checkSignInProviderRedirect()
        if (signInProvider) {
            /**
             * To ensure current user is initialized
             */
            await this.currentUser.data

            /**
             * Force to connect the same provider's calendar
             */
            const isCalConnected = await this.integrationsManager.isAnyCalendarConnectedStream
            if (!isCalConnected) {
                return this.connectCalendarIntegration(signInProvider)
            } else {
                /**
                 * Turn back to the proposal if the calendar is already connected
                 */
                return this.returnBack()
            }
        }

        if (this.activatedRoute.snapshot.queryParamMap.has('guestRedirect')) {
            let guestRedirectUrl = decodeURIComponent(
                this.activatedRoute.snapshot.queryParamMap.get('guestRedirect')
            )
            if (guestRedirectUrl) {
                this.redirectPath = guestRedirectUrl
                return this.returnBack()
            }
        }

        this.isLoadingStream.next(false)
    }

    public async proceedWithGoogle() {
        this.isLoadingStream.next(true)
        this.ssoAuthErrorStream.next(null)

        if (await this.authManager.isRegularUserStream) {
            return this.connectCalendarIntegration('google.com')
        } else {
            try {
                await this.authManager.signInWithGoogle()
            } catch (error) {
                this.pushAuthError(error)
                this.changeDetector.detectChanges()
            }
        }
    }

    public async proceedWithMicrosoft() {
        this.isLoadingStream.next(true)
        this.ssoAuthErrorStream.next(null)

        if (await this.authManager.isRegularUserStream) {
            return this.connectCalendarIntegration('microsoft.com')
        } else {
            try {
                await this.authManager.signInWithMicrosoft()
            } catch (error) {
                this.pushAuthError(error)
                this.changeDetector.detectChanges()
            }
        }
    }

    public async returnBack() {
        return this.router.navigateByUrl(this.redirectPath)
    }

    protected pushAuthError(error) {
        let errorMessage = 'Something went wrong, please try again!'
        if (error?.code == 'auth/cancelled-popup-request' || error?.code == 'auth/popup-blocked') {
            errorMessage = 'Sign in popup closed by browser!'
        } else if (error?.code == 'auth/popup-closed-by-user') {
            errorMessage = 'Sign in popup closed by browser!'
        } else if (error?.code == 'auth/account-exists-with-different-credential') {
            errorMessage = 'Account already exist with the same email but different credentials. Please link accounts in settings.'
        } else if (error?.code == 'auth/web-storage-unsupported') {
            errorMessage = 'Failed to finish authorization. This browser is not supported or cookies are disabled.'
        }
        this.ssoAuthErrorStream.next(errorMessage)
    }

    protected async checkSignInProviderRedirect(): Promise<string> {
        try {
            const result = await this.authManager.getRedirectResult()
            if (result?.additionalUserInfo?.providerId) {
                return result?.additionalUserInfo?.providerId
            }
        } catch (error) {
            this.pushAuthError(error)
        }
    }

    protected async connectCalendarIntegration(provider: string) {
        const redirectUrl = this.buildRedirectUrlForCalendarOAuthProviders()
        if (provider == 'google.com') {
            return this.integrationsManager.connectGoogleCalendar(redirectUrl)
        } else {
            return this.integrationsManager.connectMicrosoftCalendar(redirectUrl)
        }
    }

    protected buildRedirectUrlForCalendarOAuthProviders(): string {
        return `${location.protocol}//${window.location.host}/${this.redirectPath}`
    }
}
