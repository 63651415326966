<ng-container *ngIf="isEditingAllowed; else editingNotAllowedTmpl">
    <button
            mat-button
            class="event-more-button"
            [matMenuTriggerFor]="topicActions"
            (click)="$event.stopPropagation()"
    >
        <app-menu-vertical-icon [width]="24" [height]="24" [color]="'#2B2733'"></app-menu-vertical-icon>

        <mat-menu #topicActions>
            <button mat-menu-item (click)="event.emit('delete'); $event.stopPropagation()">
                <a class="material-menu-item-box">Delete</a>
            </button>
        </mat-menu>
    </button>
</ng-container>

<ng-template #editingNotAllowedTmpl>
    <button mat-button class="event-more-button disabled" (click)="$event.stopPropagation()">
        <app-menu-vertical-icon [width]="24" [height]="24" [color]="'#2B2733'"></app-menu-vertical-icon>
    </button>
</ng-template>



