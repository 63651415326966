import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-no-proposal',
    templateUrl: 'no-proposal.component.html',
    styleUrls: ['no-proposal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoProposalIconComponent extends IconTemplateComponent {
    @Input() width = '140'
    @Input() height = '140'
}
