<ng-template #loadingSlotsSkeleton>
    <app-availability-day-slot-skeleton-loader [columns]="availabilityDaysCountToDisplay" [count]="5"></app-availability-day-slot-skeleton-loader>
</ng-template>

<div class="available-time -container">
    <ng-container *ngIf="!isAvailabilityLoading; else loadingSlotsSkeleton">
        <ng-container *ngIf="availabilitySet?.length else noItemsForSelectedDayTmpl">
            <div class="available-time__time-box"
                 *ngFor="let day of availabilitySet; let i = index"
                 [class.bordered]="i !== availabilitySet.length - 1"
                 [style.min-width]="(100 / (availabilitySet?.length || 1)) + '%'"
                 [style.max-width]="(100 / (availabilitySet?.length || 1)) + '%'"
            >
                <app-time-availability-slot-column-view
                        [currentDayAvailabilitySet]="day"
                        [selectedAvailableSlot]="selectedAvailabilitySlot"
                        [targetedAvailableSlot]="suggestedAvailableSlot"
                        [suggestedAvailableSlot]="suggestedAvailableSlot"
                        [bestScoreAvailableSlot]="findBestSlotOnDay(day)"
                        [meetingToReschedule]="meetingToReschedule"
                        [selectedTimezone]="selectedTimezone"
                        [embedMode]="embedMode"
                        [textColor]="textColor"
                        [backgroundColor]="backgroundColor"

                        [accentColor]="accentColor"
                        [accentTextColor]="accentTextColor"
                        [themeColor]="themeColor"

                        (onAvailableSlotSelected)="selectSlot($event)"
                ></app-time-availability-slot-column-view>
            </div>
        </ng-container>

        <ng-template #noItemsForSelectedDayTmpl>
            <div class="available-time__time-box -empty">
                <app-icon-large-calendar-no-time class="available-time__no-free-time" [width]="'140'" [height]="'140'"></app-icon-large-calendar-no-time>
                <span style="margin-bottom: 20px; color: #2B2733;">We could't find a match in this range.</span>
            </div>
        </ng-template>
    </ng-container>
</div>
