<ng-container participant-selector-host *ngIf="{
    response: answer$ | stream
} as view">

    <div class="command-answer-container">
        <form #messageForm="ngForm">
            <div class="__message-box" editable="true" propValueAccessor="innerText"
                 name="messageForm"
                 [ngModel]="view.response"
            ></div>
        </form>
    </div>

</ng-container>
