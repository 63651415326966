<ng-container *ngIf="{
        event: state.calendarEvent | stream
    } as view">
    <section class="agenda-container" participant-selector-host>
        <div class="title-container">
            <h3 class="title">{{ view.event.meta.payload.title }}</h3>

            <div class="labels-list">
                <ng-container *ngIf="view.event.meta.payload.type === MeetingMode.InPerson">
                    <app-event-label-location-icon
                            [matTooltip]="view.event.meta.payload.location"
                    ></app-event-label-location-icon>
                </ng-container>

                <ng-container *ngIf="view.event.meta.payload.location && view.event.meta.payload.type !== MeetingMode.InPerson">
                    <app-event-label-video-icon></app-event-label-video-icon>
                </ng-container>

                <ng-container *ngIf="view.event.meta.payload.dockId">
                    <app-event-label-notes-icon></app-event-label-notes-icon>
                    <app-event-label-agenda-icon></app-event-label-agenda-icon>
                </ng-container>

                <ng-container *ngIf="view.event.meta.payload.isAutoGenerated">
                    <app-event-label-command-icon></app-event-label-command-icon>
                </ng-container>
            </div>
        </div>

        <div class="sub-title-container schedule">
            <app-icon-clock [width]="16" [height]="16" [color]="'#807A8E'"></app-icon-clock>
            <span class="short-date">{{ view.event.meta.payload.start | moment:"ddd, MMM D, y" }}</span>
            <span class="full-date">{{ view.event.meta.payload.start | moment:"dddd, MMM D, y" }}</span>
            <span class="material-icons">circle</span>
            <span>{{ view.event.meta.payload.start | moment:"h:mm A" }}&ndash;{{ view.event.meta.payload.end | moment:"h:mm A" }}</span>
        </div>

        <div class="sub-title-container location"
             *ngIf="view.event.meta.payload.location"
             [ngSwitch]="view.event.meta.payload.type"
        >
            <app-icon-audio-on *ngSwitchCase="'Audio'" [width]="16" [height]="16"  [color]="'#807A8E'"></app-icon-audio-on>

            <app-event-label-video-icon *ngSwitchCase="'Video'" [width]="16" [height]="16"  [color]="'#807A8E'" [fill]="'none'"></app-event-label-video-icon>

            <app-icon-in-person *ngSwitchCase="'InPerson'" [width]="16" [height]="16"  [color]="'#807A8E'"></app-icon-in-person>
            <!-- TODO: Use a tag for links-->
            <span class="location">
                {{ view.event.meta.payload.location }}
            </span>
        </div>

        <div class="notes-container">
            <app-meet-notes></app-meet-notes>
        </div>

        <div class="topics-container">
            <app-meet-topics-list
                    [showTitle]="false" [showFormCheckbox]="true"
            ></app-meet-topics-list>
        </div>
    </section>

    <section class="attendees-container">
        <h3 class="section-title attendees">Participants ({{view.event.meta.payload.attendees.length}})</h3>

        <div class="attendees-list">
            <app-dashboard-attendee-list-item
                    *ngFor="let attendee of view.event.meta.payload.attendees" [attendee]="attendee"
            ></app-dashboard-attendee-list-item>

            <ng-container *ngIf="view.event.meta.payload.attendees.length > 4">
                <div class="show-all-attendees-btn"
                     (click)="expandAttendeesList()"
                >
                    Show more ({{view.event.meta.payload.attendees.length - 4}})
                </div>
            </ng-container>
        </div>
    </section>

    <section class="footer">
        <div class="rsvp-container">
            <span>RSVP</span>
            <mat-select class="customArrow" udInput
                        [value]="view.event.meta.payload.state.currentRsvpStatus"
                        (selectionChange)="changeRsvpStatus($event.value)"
            >
                <mat-option [value]="RsvpStatus.Accepted">Yes</mat-option>
                <mat-option [value]="RsvpStatus.Declined">No</mat-option>
                <mat-option [value]="RsvpStatus.NeedsAction">Maybe</mat-option>
            </mat-select>
        </div>

        <div class="actions-container">
            <div class="button agenda" (click)="openAgenda()">View Agenda</div>

            <ng-container *ngIf="view.event.meta.payload.state.isJoinButtonDisplayed">
                <div class="button conference" (click)="openConference()">Join</div>
            </ng-container>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="isLoadingStream | stream">
    <app-loading-overlay></app-loading-overlay>
</ng-container>
