import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-personalized-form',
    templateUrl: 'personalized-form.component.html',
    styleUrls: ['personalized-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalizedFormIconComponent extends IconTemplateComponent {}
