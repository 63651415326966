<svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1711_10646)">
        <path d="M2.20914 20.0001H6.66368V9.69707L0.300049 5.15161V18.1819C0.300049 19.188 1.15596 20.0001 2.20914 20.0001Z" fill="#4285F4"/>
        <path d="M21.9365 20.0001H26.3911C27.4474 20.0001 28.3002 19.1849 28.3002 18.1819V5.15161L21.9365 9.69707" fill="#34A853"/>
        <path d="M21.9365 1.81821V9.697L28.3002 5.15155V2.7273C28.3002 0.478819 25.6052 -0.802999 23.7183 0.545485" fill="#FBBC04"/>
        <path d="M6.66357 9.6969V1.81812L14.2999 7.27266L21.9363 1.81812V9.6969L14.2999 15.1514" fill="#EA4335"/>
        <path d="M0.300049 2.7273V5.15155L6.66368 9.697V1.81821L4.88187 0.545485C2.99187 -0.802999 0.300049 0.478819 0.300049 2.7273Z" fill="#C5221F"/>
    </g>
    <defs>
        <clipPath id="clip0_1711_10646">
            <rect width="28" height="20" fill="white" transform="translate(0.300049)"/>
        </clipPath>
    </defs>
</svg>
