import { ApiRoute } from '@undock/api/services/api.route'

export class SettingsRoute extends ApiRoute {
    public init() {
        this.route = 'terminal/settings'
    }

    public getUser(uid: string): Promise<any> {
        return this.post()
                   .withData({ uid }).endpoint('user')
    }

    public getAuthProviders(uid: string): Promise<any> {
        return this.get()
                   .endpoint(`auth-providers/${uid}`)
    }

    public getUserIntegrations(uid: string): Promise<any> {
        return this.post()
                   .withData({ uid }).endpoint('integrations')
    }

    public getUserPartnerIntegrations(uid: string): Promise<any> {
        return this.post()
                   .withData({ uid }).endpoint('integrations/partner')
    }

    public getUserSchedules(uid: string): Promise<any> {
        return this.post()
                   .withData({ uid }).endpoint('schedules')
    }
}
