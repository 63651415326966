import {
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    Router,
    ActivatedRoute,
} from '@angular/router'

import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { Api } from '@undock/api'
import { getQueryParam } from '@undock/core'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'


@Component({
    selector: 'app-verify-email-page',
    templateUrl: 'verify-email-page.component.html',
    styleUrls: [
        'verify-email-page.component.scss',
        '../email-is-not-verified/email-is-not-verified-page.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyEmailPageComponent {

    private readonly CODE_PARAM_NAME = 's'
    private readonly EMAIL_PARAM_NAME = 'e'
    private readonly USER_ID_PARAM_NAME = 'u'

    @CompleteOnDestroy()
    public readonly targetEmailStream = new ValueSubject('')

    @CompleteOnDestroy()
    public readonly isInitializedStream = new ValueSubject(false)

    public constructor(
        private api: Api,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackbarManager: SnackbarManager,
    ) {}

    public async ngOnInit() {
        const code = getQueryParam(this.activatedRoute, this.CODE_PARAM_NAME)
            , email = getQueryParam(this.activatedRoute, this.EMAIL_PARAM_NAME)
            , userId = getQueryParam(this.activatedRoute, this.USER_ID_PARAM_NAME)

        if (code && email && userId) {
            try {
                /**
                 * Running email verification
                 */
                await this.api.auth.providers.verifyEmail(userId, code)

                this.targetEmailStream.next(email)
                this.isInitializedStream.next(true)
            } catch (error) {
                /**
                 * Leaving the page if validation fails
                 */
                console.error(`Cannot verify email`, error)
                this.snackbarManager.error(`Cannot verify the email. Please resend the email and try again.`)
                return this.router.navigate(['/'])
            }
        } else {
            /**
             * Leaving the page if validation link is invalid
             */
            this.snackbarManager.error(`Verification link is invalid`)
            return this.router.navigate(['/'])
        }
    }
}
