/*****************************************************************************************************
 *
 * Undock Web App Angular Client Development Environment
 * Default Environment Configuration
 *
 *
 * This environment is the default environment during a build
 * e.g. $ ng build
 * or $ ng serve
 *
 */
import { AppMode } from '@undock/core'
import { RxJSDebugLevel } from '@undock/core/contracts/rxjs-debug-level'


export const environment = {
    appMode: 'dev-deploy' as AppMode,
    prodMode: false,
    production: false,

    enableErrorTracking: false,
    logRocketId: 'oozzgi/undock',

    // App Config
    dockLink: 'https://dev.undock.com/meet/join/',
    proposalLink: 'https://dev.undock.com/',
    clientDomain: 'https://dev.undock.com/',
    embedDomain: 'https://dev.undock.com/',
    apiURL: 'https://undock-development.appspot.com/',
    apiPlatformURL: 'https://api-dev.undock.com/',
    requestFeatureURL: 'https://matter.undock.com/feature-requests',
    latestUpdatesUrl: 'https://undock.canny.io/changelog',
    privacyPolicyUrl: 'https://undock.com/privacypolicy',
    termsOfServiceUrl: 'https://undock.com/m/terms-of-use/',
    errorUrl: 'https://undock.getgist.help',
    debugMode: true,
    rxjsDebugLevel: RxJSDebugLevel.OFF,
    enableTracking: false,

    useExperimentalFeatures: true,
    useExperimentalPeoplePage: true,
    appVersion: 'ud00063',
    actualCalendarVersion: 3,

    // Analytics
    enableAnalytics: false,
    enableHeapAnalytics: false,
    enableGoogleAnalytics: false,
    enableGoogleTagManager: false,
    enableSegmentAnalytics: false,
    enableCannySDK: false,
    googleAnalyticsTrackingId: 'UA-250497264',
    googleTagManagerTrackingId: 'UA-160161596-3',
    segmentAnalyticsTrackingId: 'PAVZhBktsLI3niBDTRFS7fL13VCC6FNO',
    cannyAppId: '6071a6334c231e2b2f3ac332',
    // gistTourId: 283,
    // gistStepId: '283-step-c7e54ca8-1681415317897',

    // Integrations
    googleApiUrl: 'https://apis.google.com/js/api.js',
    googleApiClientUrl: 'https://accounts.google.com/gsi/client',
    // Production client is temporary disabled for dev.undock.com
    // googleClientId: '689053353097-tpf84rmqj0a96dhhjt95dubdi8ppeapu.apps.googleusercontent.com',
    // googleApiKey: 'AIzaSyCJ3SGEv_2fYB7vu7MIm5S2499eIyI5w6o',
    googleClientId: '615419546124-ktbdml75v8hhf1tou8pio94r97s49iqm.apps.googleusercontent.com',
    googleApiKey: 'AIzaSyAT0QNL3bRMnQtMeyDvsWoYjM-ezv-lkDk',
    googleContactsScope: 'https://www.googleapis.com/auth/contacts.readonly',
    googleDirectoryScope: 'https://www.googleapis.com/auth/directory.readonly',
    microsoftClientId: '0c78673b-03cf-4162-82b4-36006964084b',
    stripeApiKey: 'pk_test_neGXqrBfS9WLJsa84fkacaKq',
    zoomCallbackPath: 'zoom-auth',

    gistAppId: null,
    usetifulToken: '5e732dfa2ae33aa10bbb1d7b66d8ee46',
    crispWebsiteId: 'eb7a6ae9-b213-4712-bf89-b025346b57df',

    firebase: {
        appId: '1:615419546124:web:8cd9c4a997129118513c29',
        apiKey: 'AIzaSyBUhcy21lUEJoLr6M35PwF2KI924vWXQZ8',
        // authDomain: 'undock-development.firebaseapp.com',
        authDomain: 'auth.dev.undock.com',
        databaseURL: 'https://undock-development.firebaseio.com',
        projectId: 'undock-development',
        storageBucket: 'undock-development.appspot.com',
        messagingSenderId: '615419546124',
        measurementId: 'G-PBSK67N7S6',
    },

    // Chrome Extension
    chromeExtURL: 'https://chrome.google.com/webstore/detail/undock-meetings/edpipfjenheecalaenoobkbnbdelhopm',
    chromeExtensionId: 'edpipfjenheecalaenoobkbnbdelhopm',
    schedulingWindowAllowedDomains: [
        'https://mail.google.com',
        'https://outlook.live.com',
        'https://outlook.office365.com',
        'https://outlook.office.com',
        'https://mail.superhuman.com',
        'http://localhost:4200/install',
        'http://localhost:4200/signup',
        'http://localhost:4201/install',
        'http://localhost:4201/signup',
        'https://dev.undock.com/install',
        'https://dev.undock.com/signup',
        'https://dev5.undock.com/install',
        'https://dev5.undock.com/signup',
        'https://next.undock.com/install',
        'https://next.undock.com/signup',
        'https://app.undock.com/install',
        'https://app.undock.com/signup',
        'https://io.undock.com/install',
        'https://io.undock.com/signup'
    ],
    calendarPromptAllowedDomains: [
        'https://calendar.google.com',
        'https://outlook.live.com/calendar',
        'https://outlook.office365.com/calendar',
        'https://outlook.office.com/calendar',
    ]
}
