import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-schedule',
    templateUrl: 'schedule-icon.component.html',
    styleUrls: ['schedule-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleIconComponent extends IconTemplateComponent {
    @Input() color: string = '#807A8E'
}
