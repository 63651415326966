import {
    Inject,
    Injectable,
    Optional,
} from '@angular/core'

import { DockChatAttachmentsManager } from '@undock/dock/meet/services/attachments/dock-chat-attachments.manager'
import {
    Attachment,
    AttachmentsManager,
    FileStorage,
    HasAttachments,
} from '@undock/core'
import { PathResolveStrategy } from '@undock/core/contracts/path-resolve.strategy'
import { STORAGE_PATH_PREFIX } from '@undock/core/contracts/tokens/storage-path-prefix.token'
import {
    TOPICS_ADAPTER,
    TopicsAdapterInterface,
} from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'
import { AttachmentSource } from '@undock/core/contracts/attachments.manager'


@Injectable()
export class DockTopicAttachmentsManager extends AttachmentsManager {
    /**
     * ---------------------------------------------------------------------
     *  This class is used to create attachment copies for the parent Model
     * ---------------------------------------------------------------------
     */

    public constructor(
        protected fileStorage: FileStorage,
        protected pathResolveStrategy: PathResolveStrategy,
        @Optional()
        @Inject(STORAGE_PATH_PREFIX)
        protected storagePathPrefix: string = '/',

        @Inject(TOPICS_ADAPTER)
        protected topicsAdapter: TopicsAdapterInterface,
    ) {
        super(fileStorage, pathResolveStrategy, storagePathPrefix)
    }

    public async addAttachments(topic: HasAttachments, sources: AttachmentSource[]) {
        const [
            topicOwner,
            attachments,
        ] = await Promise.all([
            this.topicsAdapter.ownerModelStream,
            super.addAttachments(topic, sources),
        ])

        /**
         * Generates copies of the attachments for current Dock entity
         */
        await this.copyAttachmentsIntoTopicsOwner(topicOwner, attachments)

        return attachments
    }

    private async copyAttachmentsIntoTopicsOwner(
        topicOwner: HasAttachments, attachments: Attachment[],
    ) {
        await Promise.all(attachments.map(
            async original => this.createAttachmentEntity(
                topicOwner,
                await this.ormOnFireContext.resolveReference(original.file),
                original,
            ),
        ))
    }
}
