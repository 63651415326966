import {
    Input,
    Component,
} from '@angular/core'
import { MeetDurationSelectV2Component } from '@undock/dock/meet/ui/pages/edit-meeting'
import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'


@Component({
    selector: 'app-meet-event-form-duration',
    templateUrl: 'event-form-duration.component.html',
    styleUrls: ['event-form-duration.component.scss'],
})
export class EventFormDurationComponent extends MeetDurationSelectV2Component {

    /**
     *
     */
    @Input() placeholder?: string = 'Select duration'

    /**
     * Optional host for dropdown elem
     */
    @Input() dropdownElHost?: HTMLElement


    /**
     * Temporary functionality for key arrow selection
     * TODO: Rework this with some shared directive or service
     */

    @CompleteOnDestroy()
    public readonly selectedItem$ = new ValueSubject(null)

    public applyActiveListItem() {
        if (this.selectedItem$.value) {
            const option = this.selectedItem$.value
            this.onMeetingDurationChanged(option.value - option.gap)
        }
    }

    public activatePrevListItem() {
        const options = this.availableMeetingDurationOptions
        if (!this.selectedItem$.value) {
            this.selectedItem$.next(this.availableMeetingDurationOptions[0])
        } else {
            const nextItemIndex = options.indexOf(this.selectedItem$.value) - 1
            this.selectedItem$.next(options[nextItemIndex >= 0 ? nextItemIndex : 0])
        }
    }

    public activateNextListItem() {
        const options = this.availableMeetingDurationOptions
        if (!this.selectedItem$.value) {
            this.selectedItem$.next(options[0] ?? 0)
        } else {
            const nextItemIndex = options.indexOf(this.selectedItem$.value) + 1
            this.selectedItem$.next(options[nextItemIndex < options.length ? nextItemIndex : options.length - 1])
        }
    }
}
