import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-gear',
    templateUrl: 'gear.component.html',
    styleUrls: ['gear.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GearIconComponent extends IconTemplateComponent {}
