import { Injectable } from '@angular/core'

import {
    map,
    takeUntil,
    shareReplay,
} from 'rxjs/operators'
import {
    ValueSubject,
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    CompleteOnDestroy,
} from '@typeheim/fire-rx'
import { combineLatest } from 'rxjs'

import { Memoize } from '@undock/core'
import { CurrentUser } from '@undock/session'
import {
    AvailabilityService,
    MeetingDuration,
} from '@undock/time/availability/services/availability.service'
import { UserAvailableMeetingLength } from '@undock/user'
import { MeetingDurationOption } from '@undock/dock/meet/contracts/meeting-duration-option.interface'


@Injectable({ providedIn: 'root' })
export class MeetingDurationOptionsProvider {

    public readonly defaultMeetingDuration: MeetingDuration = 30
    public readonly defaultMeetingDurationValues: MeetingDuration[] = [15, 30, 60, 120]

    @CompleteOnDestroy()
    private readonly allowTruncateMeetings = new ValueSubject(true)

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected currentUser: CurrentUser,
        protected availabilityService: AvailabilityService,
    ) {}


    @Memoize()
    public get currentUserAvailableMeetingDurationOptionsStream(): ReactiveStream<MeetingDurationOption[]> {
        return new ReactiveStream<MeetingDurationOption[]>(
            combineLatest([
                this.allowTruncateMeetings,
                this.currentUser.settingsStream,
            ]).pipe(
                map(sources => {
                    const [ allowTruncateMeetings, settings ] = sources

                    return this.getAvailableOptions(
                        settings?.availabilityIntervals,
                        allowTruncateMeetings && settings?.truncateMeetings,
                    )
                }),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            )
        )
    }

    public setAllowTruncateMeetings(value: boolean) {
        this.allowTruncateMeetings.next(value)
    }

    public async getCurrentUserDefaultMeetingDuration(): Promise<MeetingDuration> {
        const settings = await this.currentUser.settings
        const availableOptions = this.getAvailableOptions(
            settings.availabilityIntervals, settings.truncateMeetings,
        )

        /**
         * Return default meeting duration or select lowest value from available options
         */
        return availableOptions.length === 0 ? this.defaultMeetingDuration : Math.min.apply(
            this, availableOptions.map(
                option => option.value - option.gap,
            ),
        )
    }


    public getAvailableOptions(
        availabilityIntervals: Record<UserAvailableMeetingLength, boolean>, truncateMeetings: boolean = false,
    ): MeetingDurationOption[] {
        if (availabilityIntervals) {
            return Object.keys(
                availabilityIntervals,
            ).map(
                duration => parseInt(duration),
            ).filter(Boolean).map(
                duration => ({
                    value: duration,
                    enabled: availabilityIntervals[duration],
                    gap: this.availabilityService.calculatePreferredMeetingGap(duration, truncateMeetings),
                }) as MeetingDurationOption,
            )
        } else {
            /**
             * If the user object dont contains a duration config use default
             */
            return this.defaultMeetingDurationValues.map(
                duration => ({
                    value: duration,
                    enabled: true,
                    gap: this.availabilityService.calculatePreferredMeetingGap(duration, truncateMeetings),
                }) as MeetingDurationOption,
            )
        }
    }
}
