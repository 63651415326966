import {
    ID,
    Field,
    Entity,
    CreatedDateField,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'
import { UserLimitType } from '@undock/feature-plans/services/user-limits.provider'

export interface OnboardingStepTrackingState {
    started?: string,
    completed?: string,
    isComplete: boolean
}

export interface OnboardingTrackingData {
    onboarding?: {
        _state: OnboardingStepTrackingState,
        trainCalendar?: {
            _state: OnboardingStepTrackingState
            setWorkingHours: OnboardingStepTrackingState,
            setMeetingIntervals: OnboardingStepTrackingState,
            plans?: OnboardingStepTrackingState,
        },
        connectCalendar?: OnboardingStepTrackingState,
        claimName?: OnboardingStepTrackingState,
    },
    productTour?: {
        _state: OnboardingStepTrackingState,
        profile?: OnboardingStepTrackingState,
        demo?: {
            _state: OnboardingStepTrackingState,
            slashKey?: OnboardingStepTrackingState,
            arrowKeys?: OnboardingStepTrackingState,
            tabKey?: OnboardingStepTrackingState,
            enterKey?: OnboardingStepTrackingState,
            installButton?: OnboardingStepTrackingState
        },
        time?: OnboardingStepTrackingState
    }
}

export interface ApplicationUsageData {
    [key: UserLimitType | string]: {
        total: number
        monthly?: {
            [key: string]: number
        }
        byPeriod?: {
            [key: string]: number
        }
    }
}

export const USER_ANALYTICS_COLLECTION_NAME = 'user-analytics'

@Entity({
    collection: USER_ANALYTICS_COLLECTION_NAME
})
export class UserAnalyticsObject {

    /**
     * The firebase id of the user
     */
    @ID() readonly id: string

    @Field() invitesCount: number

    @Field() onboardingTracking: OnboardingTrackingData

    /**
     * Keeps usages of some specific features
     */
    @Field() applicationUsage: ApplicationUsageData = {}

    @Field() applicationUsageStartDate: Date

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date
}
