import { Injectable } from '@angular/core'

import { Config } from '@undock/core'
import { CurrentUser } from '@undock/session'
import { PrivacyManager } from '@undock/user/services/privacy.manager'
import { User } from '@undock/user'


@Injectable({
    providedIn: 'root',
})
export class ProfileLinksManager {

    public constructor(
        protected config: Config,
        protected currentUser: CurrentUser,
        protected privacyManager: PrivacyManager,
    ) {}

    /**
     * Returns a full URL for current user profile for current env
     */
    public async getPublicUrlForCurrentUserProfile(): Promise<string> {
        return `${this.config.clientDomain}${await this.currentUser.profileUrl}`
    }

    /**
     * Returns a full URL for current user profile including unlock code
     */
    public async getPrivateUrlForCurrentUserProfile(): Promise<string> {
        return `${await this.getPublicUrlForCurrentUserProfile()}${await this.generatePrivacyUnlockUQueryParams()}`
    }

    /**
     * Returns a full URL for specified user profile for current env
     */
    public async getPublicUrlForUserProfile(user: User): Promise<string> {
        return `${this.config.clientDomain}${user.profileUrl}`
    }

    /**
     * Returns URL for the current user meeting types page
     */
    public async getPublicUrlForCurrentUserMeetingTypes(): Promise<string> {
        return `${await this.getPublicUrlForCurrentUserProfile()}/t`
    }

    /**
     * Returns URL for the current user meeting types page with privacy unlock code
     */
    public async getPrivateUrlForCurrentUserMeetingTypes(): Promise<string> {
        return `${await this.getPublicUrlForCurrentUserMeetingTypes()}${await this.generatePrivacyUnlockUQueryParams()}`
    }

    /**
     * Returns URL for the specified user meeting types page
     */
    public async getPublicUrlForUserMeetingTypes(user: User): Promise<string> {
        return `${await this.getPublicUrlForUserProfile(user)}/t`
    }

    public async generatePublicAccessUrlForMeetingType(meetingTypeUrl: string): Promise<string> {
        return `${await this.getPublicUrlForCurrentUserMeetingTypes()}/${meetingTypeUrl}`
    }

    public async generatePrivateAccessUrlForMeetingType(meetingTypeUrl: string): Promise<string> {
        return `${await this.generatePublicAccessUrlForMeetingType(meetingTypeUrl)}${await this.generatePrivacyUnlockUQueryParams()}`
    }

    protected async generatePrivacyUnlockUQueryParams(): Promise<string> {
        const settings = await this.currentUser.settings

        if (settings.privateProfile) {
            let privacyUnlockCode = await this.privacyManager.createPrivacyUnlockCode()
            if (privacyUnlockCode) {
                return `?${PrivacyManager.PRIVACY_UNLOCK_URL_PARAM}=${privacyUnlockCode}`
            }
        }

        return ''
    }
}
