import { Injectable } from '@angular/core'
import { ReactiveStream } from '@typeheim/fire-rx'

import {
    Model,
    HasAttachments,
} from '@undock/core'
import { DockFacade } from '@undock/dock/meet/services/facade/dock.facade'
import { TopicsAdapterInterface } from '@undock/dock/meet/contracts/ui-adapters/topics.adapter'


@Injectable()
export class DockFacadeTopicsAdapter implements TopicsAdapterInterface {

    public readonly isEditModeStream: ReactiveStream<boolean>
    public readonly ownerModelStream: ReactiveStream<Model & HasAttachments>

    public constructor(
        private dockFacade: DockFacade,
    ) {
        this.isEditModeStream = this.dockFacade.isEditModeStream
        this.ownerModelStream = this.dockFacade.currentDockStream
    }
}
