import { AbstractEvent } from '@undock/core'

import { User } from '@undock/user'
import { LegacyDock } from '@undock/dock/meet/contracts/legacy-dock'

/**
 * Meet domain core Event
 *
 * @API
 */
export class DockCreatedEvent extends AbstractEvent {

    public constructor(
        protected _dock: LegacyDock,
        protected _recipient?: User,
        protected _requester?: User | Record<string, any>,
    ) { super() }

    public get dock(): LegacyDock {
        return this._dock
    }

    public get recipient(): User {
        return this._recipient
    }

    public get requester(): User | Record<string, any> {
        return this._requester
    }
}
