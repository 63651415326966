<div class="container signup-page">
    <div class="signup-page__login-wrapper">
        <div class="signup-page__login-box"><!-- wrapper for login fields -->
            <nav class="signup-page__top-navigation navigation-mobile">
                <a target="_blank" href="https://undock.com/l/?ref=null" class="login-page__logo logo">
                    <app-logo-undock></app-logo-undock>
                </a>

                <a [routerLink]="'/login'" class="login-page__logo arrow">
                    <app-icon-back-arrow [width]="32" [height]="32"></app-icon-back-arrow>
                </a>

                <p class="navigation-text">Sign up with Email</p>
            </nav>
            <div class="signup-page__title">
                <h1>Discover what tomorrow brings</h1>
                <p>It’s time to take control of your day.</p>
            </div>

            <form class="signup-page__login-form form-container" [formGroup]="signupForm"
                  (ngSubmit)="signUpWithEmailPassword()">

                <div class="form-container__sso-errors" *ngIf="ssoAuthErrorStream | stream as ssoErrorMessage">
                    <p class="error-message">{{ ssoErrorMessage }}</p>
                </div>

                <div class="form-container__input-wrapper">
                    <label class="form-container__input-item">
                        <span>Email</span>
                        <input name="email" autocomplete="email" [formControlName]="'email'" [placeholder]="'Enter your email address...'" type="text"/>
                    </label>

                    <div class="form-group__errors form-container__errors-box" *ngIf="isFormControlInvalid('email')">
                        <p class="form-container__error-message" *ngIf="isFormControlHasError('email', 'email')">
                            Email is not valid
                        </p>
                        <p class="form-container__error-message" *ngIf="isFormControlHasError('email', 'required')">
                            Email is required
                        </p>
                    </div>
                </div>

                <div class="form-container__input-wrapper">
                    <label class="form-container__input-item">
                        <span>Password</span>
                        <input data-private
                               [type]="isPasswordHidden ? 'password' : 'text'"
                               [formControlName]="'password'" [placeholder]="'Create a password...'"/>
                    </label>

                    <div class="form-group__errors form-container__errors-box"
                         *ngIf="isFormControlInvalid('password')">
                        <p class="form-container__error-message" *ngIf="isFormControlHasError('password', 'required')">
                            Password is required
                        </p>
                        <p class="form-container__error-message" *ngIf="isFormControlHasError('password', 'minlength')">
                            Password must be at least 8 characters long
                        </p>
                    </div>

                    <div class="form-group__errors form-container__errors-box"
                         *ngIf="passwordAuthErrorStream | stream as errorMessage">
                        <p class="form-container__error-message">{{errorMessage}}</p>
                    </div>
                </div>


                <div class="form-container__submit-button-box">
                    <button mat-button class="form-container__submit-button" type="submit" [disabled]="signupForm.invalid">
                        Continue
                    </button>
                </div>

                <p class="form-container__terms-and-privacy">By clicking “Continue” I agree with Undock’s
                    <a href="https://undock.com/l/legal/terms-of-service/">Term of Service</a> and <a
                            href="https://undock.com/privacypolicy/">Privacy Policy</a>
                </p>

                <p class="account-info link-button">
                    Already have account? <a [routerLink]="'/signin'">Log In</a>
                </p>

            </form>
        </div>
    </div>
</div>
