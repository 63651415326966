import { ApiRoute } from '@undock/api/services/api.route'
import { TerminalAvailabilitySimultationInterface } from '@undock/api/scopes/terminal/contracts/terminal-availability-simultation.interface'

export class AvailabilityRoute extends ApiRoute {
    public init() {
        this.route = 'availability/service'
    }

    public async simulateAvailabiltyByRange(request: any): Promise<TerminalAvailabilitySimultationInterface> {
        return this.post<TerminalAvailabilitySimultationInterface>()
                   .withData(request).endpoint('simulate')
    }

}
