import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-warning-icon',
    templateUrl: 'warning-icon.component.html',
    styleUrls: ['warning-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningIconComponent {
    @Input() color: string = '#F13693'
    @Input() width: number | string = '40'
    @Input() height: number | string = '40'
}
