import { GuestAuthRoute } from './routes/guest-auth.route'
import { ApiContext } from '@undock/api/contracts/api.context'
import { Auth0Route } from '@undock/api/scopes/auth/routes/auth0.route'
import { ProvidersRoute } from '@undock/api/scopes/auth/routes/providers.route'
import { SessionRoute } from '@undock/api/scopes/auth/routes/session.route'

export class AuthScope {
    public auth0: Auth0Route
    public session: SessionRoute
    public guestAuth: GuestAuthRoute
    public providers: ProvidersRoute

    public constructor(
        protected context: ApiContext,
    ) {
        this.auth0 = new Auth0Route(context)
        this.session = new SessionRoute(context)
        this.guestAuth = new GuestAuthRoute(context)
        this.providers = new ProvidersRoute(context)
    }
}
