<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 140 140" fill="none">
    <g clip-path="url(#clip0_1029_2185)">
        <path d="M70 124C99.8225 124 124 99.8701 124 70C124 40.1299 99.716 16 70 16C40.1775 16 16 40.1299 16 70C16 99.8701 40.1775 124 70 124Z" fill="#F3F3F9"/>
        <g opacity="0.5" filter="url(#filter0_f_1029_2185)">
            <ellipse cx="70" cy="95.5" rx="35" ry="24.5" fill="#B4AFC6" fill-opacity="0.7"/>
        </g>
        <rect x="19.5" y="35.5" width="97" height="67" rx="7.5" fill="url(#paint0_linear_1029_2185)"/>
        <rect x="19" y="35" width="98" height="12" fill="#EDEAF2"/>
        <path d="M25.1727 42.1643C26.0865 42.1643 26.8273 41.4197 26.8273 40.5011C26.8273 39.5825 26.0865 38.8379 25.1727 38.8379C24.259 38.8379 23.5182 39.5825 23.5182 40.5011C23.5182 41.4197 24.259 42.1643 25.1727 42.1643Z" fill="#9A95AC"/>
        <path d="M30.5819 42.1643C31.4957 42.1643 32.2365 41.4197 32.2365 40.5011C32.2365 39.5825 31.4957 38.8379 30.5819 38.8379C29.6681 38.8379 28.9274 39.5825 28.9274 40.5011C28.9274 41.4197 29.6681 42.1643 30.5819 42.1643Z" fill="#9A95AC"/>
        <path d="M35.9589 42.1643C36.8726 42.1643 37.6134 41.4197 37.6134 40.5011C37.6134 39.5825 36.8726 38.8379 35.9589 38.8379C35.0451 38.8379 34.3043 39.5825 34.3043 40.5011C34.3043 41.4197 35.0451 42.1643 35.9589 42.1643Z" fill="#9A95AC"/>
        <rect x="19.5" y="35.5" width="97" height="67" rx="7.5" stroke="#EDEAF2"/>
        <path d="M93.7347 96.9628L79.3973 82.5245L82.6842 79.2119L97.0102 93.6617L93.7347 96.9628Z" fill="#9A95AC"/>
        <path d="M98.7185 102.043L87.7247 90.9632C86.8066 90.0379 86.8066 88.5301 87.7247 87.6049C88.6427 86.6796 90.1388 86.6796 91.0568 87.6049L102.051 98.6849C102.969 99.6102 102.969 101.118 102.051 102.043C101.133 102.968 99.6365 102.968 98.7185 102.043Z" fill="#9A95AC"/>
        <path d="M97.9366 102.752L83.7919 88.496C83.4632 88.1647 83.4632 87.6279 83.7919 87.2852L87.4074 83.6413C87.7361 83.3101 88.2688 83.3101 88.6088 83.6413L102.753 97.8969C103.082 98.2281 103.082 98.765 102.753 99.1077L99.138 102.752C98.798 103.083 98.2653 103.083 97.9366 102.752Z" fill="#CECADA"/>
        <path d="M63.1673 36C48.1612 36 36 48.2566 36 63.3803C36 78.4925 48.1612 90.7605 63.156 90.7605C78.162 90.7605 90.3232 78.5039 90.3232 63.3803C90.3232 48.2566 78.162 36 63.1673 36ZM63.1673 85.6888C51.1194 85.6888 41.3609 75.694 41.3609 63.3688C41.3609 51.0437 51.1194 41.0603 63.1673 41.0603C75.2152 41.0603 84.9737 51.0551 84.9737 63.3803C84.9737 75.7054 75.2039 85.6888 63.1673 85.6888Z" fill="#CECADA"/>
    </g>
    <defs>
        <filter id="filter0_f_1029_2185" x="5" y="41" width="130" height="109" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_1029_2185"/>
        </filter>
        <linearGradient id="paint0_linear_1029_2185" x1="39.6862" y1="40.7736" x2="56.1529" y2="107.575" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.9964" stop-color="white"/>
        </linearGradient>
        <clipPath id="clip0_1029_2185">
            <rect width="140" height="140" fill="white"/>
        </clipPath>
    </defs>
</svg>
