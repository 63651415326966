import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-add-new-calendar-icon',
    template: `
        <svg [attr.width]="24" [attr.height]="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6866_37309)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00008 0C8.55237 0 9.00008 0.447715 9.00008 1V2.00004L9.29183 2L9.33342 2H15.0001V1C15.0001 0.447715 15.4478 0 16.0001 0C16.5524 0 17.0001 0.447715 17.0001 1V2.00159C17.1884 2.00395 17.345 2.00978 17.4902 2.02408C19.8641 2.25788 21.7422 4.13603 21.976 6.50991C22.0002 6.75529 22.0001 7.03352 22.0001 7.44217L22.0001 7.5V10.5C22.0001 11.0523 21.5524 11.5 21.0001 11.5C20.4478 11.5 20.0001 11.0523 20.0001 10.5V7.5C20.0001 7.01187 19.9989 6.8403 19.9856 6.70595C19.8454 5.28162 18.7185 4.15473 17.2941 4.01445C17.2216 4.0073 17.1382 4.00366 17.0001 4.00182C16.9991 4.55327 16.5518 5 16.0001 5C15.4478 5 15.0001 4.55228 15.0001 4H9.33342L9.00008 4.00008C9.00004 4.55233 8.55234 5 8.00008 5C7.46058 5 7.02087 4.57278 7.0008 4.03819C6.95232 4.04133 6.90494 4.04474 6.85857 4.04842C6.26967 4.09522 5.9305 4.1825 5.67322 4.30938C5.08092 4.60147 4.60155 5.08084 4.30946 5.67313C4.18259 5.93042 4.0953 6.26958 4.0485 6.85849C4.00082 7.45858 4.00008 8.22864 4.00008 9.33333V15.5C4.00008 16.9683 4.01094 17.4808 4.12926 17.8709C4.42011 18.8297 5.17042 19.58 6.12923 19.8708C6.51929 19.9891 7.03177 20 8.50008 20H10.0001C10.5524 20 11.0001 20.4477 11.0001 21C11.0001 21.5523 10.5524 22 10.0001 22H8.50008L8.32634 22C7.09908 22.0005 6.26106 22.0008 5.54866 21.7847C3.95065 21.3 2.70013 20.0494 2.21538 18.4514C1.99928 17.739 1.99959 16.901 2.00004 15.6737L2.00008 15.5V9.33333L2.00008 9.29175C2.00007 8.23836 2.00007 7.38871 2.05479 6.70006C2.11112 5.99117 2.23009 5.36776 2.51572 4.78856C3.00253 3.80139 3.80148 3.00245 4.78864 2.51564C5.36785 2.23 5.99125 2.11104 6.70014 2.05471C6.7969 2.04702 6.89684 2.04041 7.00008 2.03473V1C7.00008 0.447715 7.4478 0 8.00008 0Z" 
                      [attr.fill]="color"/>
                <rect x="16" y="12" width="2" height="10" rx="1" fill="#807A8E"/>
                <rect x="12" y="18" width="2" height="10" rx="1" transform="rotate(-90 12 18)" fill="#807A8E"/>
            </g>
            <defs>
                <clipPath id="clip0_6866_37309">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class AddNewCalendarIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = '#807A8E'
}
