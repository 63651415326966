import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'

@Component({
    selector: 'app-icon-live-broadcast',
    templateUrl: 'live-broadcast.component.html',
    styleUrls: ['live-broadcast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveBroadcastIconComponent {
    @Input() height: number|string = '16'
    @Input() width: number|string = '18'
    @Input() color: string = '#000'
}
