import { Component } from '@angular/core'

import {
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { Api } from '@undock/api'
import { ProfilesProvider } from '@undock/user'
import { fadeAnimation } from '../../shared-animations/fade.animation'
import { ConferenceEvent } from '@undock/dock/meet/models/dock/conference-event.model'
import { AbstractNotificationComponent } from '../abstract-notification.component'


@Component({
    selector: 'dock-notifications-requested-to-speak',
    templateUrl: 'requested-to-speak-notification.component.html',
    styleUrls: [
        '../../shared-styles/notification.scss',
        'requested-to-speak-notification.component.scss',
    ],
    animations: [fadeAnimation],
})
export class RequestedToSpeakNotificationComponent extends AbstractNotificationComponent<ConferenceEvent> {

    @CompleteOnDestroy()
    public readonly isProcessingStream = new ValueSubject(false)

    public constructor(
        public profilesProvider: ProfilesProvider,
        private api: Api,
    ) {
        super(profilesProvider)
    }

    public async approve() {
        this.isProcessingStream.next(true)

        const event = this.notification.payload
        if (event && event.payload.participantId) {
            await this.api.meet.conferenceRoom
                      .allowMicUsage(event.dockId, event.payload.participantId)

            this.destroy()
        }
    }

    public async decline() {
        this.isProcessingStream.next(true)

        this.destroy()
    }
}
