import { ApiContext } from '@undock/api/contracts/api.context'
import { UrlHandleRegistryRoute } from '@undock/api/scopes/url-handle/routes/url-handle-registry.route'

export class UrlHandleScope {

    public readonly registry: UrlHandleRegistryRoute

    public constructor(context: ApiContext) {
        this.registry = new UrlHandleRegistryRoute(context)
    }
}
