<ng-template #visibleParticipants>
    <ng-container *ngFor="let participant of participants; let i = index">
        <ng-container *ngIf="i < visibleCount">
            <div class="out-item">
                <div class="item participant-view" [class.-one-icon]="participants.length == 1">
                    <img class="participants-list__participant-icon"
                         default="/assets/img/placeholders/user.png"
                         [src]="participant.userData?.imageUrl | imgThumbnail"
                         [alt]="participant.email"
                    />
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="out-item" *ngIf="visibleCount < participants?.length">
        <div class="item participant-counter">
            <span class="hidden-participants-count">+{{ (participants?.length - visibleCount) }}</span>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="participants">
    <div class="wrapper">
        <div class="inner">
            <div class="icons-block" [style.max-width.px]="maxWidth">
                <ng-container [ngTemplateOutlet]="visibleParticipants"></ng-container>
            </div>
        </div>
    </div>
</ng-container>
