<div class="invites-logo" id="logo">
    <a [routerLink]="''">
        <img src="assets/img/undock-logo-deep-purple.png" alt="logo-purple">
    </a>
</div>

<section id="standalone-invites-container">

    <span class="__title">People you meet the most</span>
    <article class="__tips-wrapper">
        <span class="__tips">Save even more time when your connections join Undock</span>
    </article>
    <span class="__contacts">CONTACTS</span>
    <app-contact-invite-list
            [limit]="1000"
    ></app-contact-invite-list>

</section>
