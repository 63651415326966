import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    PlanType,
    Plan,
} from '@undock/time/plans/contracts/plan.interface'

@Component({
    selector: 'commands-list-icon',
    templateUrl: 'commands-list-icon.component.html',
    styleUrls: ['commands-list-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandsListIconComponent {

    public readonly CommandType = PlanType

    @Input() command?: Plan

    @Input() color: string = '#2B2733'

    @Input() backgroundColor: string = '#EDEAF2'

    @Input() border: string = 'none'

    @Input() size: number = 45

}
