import {
    CalendarEvent,
    TimeBlock,
} from '@undock/time/availability/contracts'

import * as m from 'moment'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


export interface DockDates {
    end: Date
    start: Date
    allDay: boolean
}

/** @deprecated */
export class LegacyChecklistItem {
    _id: string
    text: string
    position: number
    createdBy: string

    isComplete: boolean

    audio: { status: string, isUploaded: boolean }
    video: { status: string, isUploaded: boolean }
}

/** @deprecated */
export class LegacyDockParticipant {
    isUndockUser: boolean
    emailAddress: string
    addedBy: string
    accepted?: boolean
    denied?: boolean
    pending?: boolean
    isOwner?: boolean
    isCreator?: boolean
    isOptional?: boolean
    isOnline?: boolean
    firstname?: string
    fullname?: string
    present: boolean
    timeZone?: string

    userData: {
        _id: string
        sid: number
        emailAddress: string
    }
}

/** @deprecated */
export class LegacyDock {

    _id: string

    type: LegacyDockType

    title?: string
    note?: string

    /**
     * Contains CalendarEvent instance only when `type` === `Event`
     */
    item: CalendarEvent | any

    url: string
    meetingCode?: string

    date: DockDates

    participants?: LegacyDockParticipant[]

    conferenceSharedAccessSecret: string
}

/** @deprecated */
export enum LegacyDockType {
    Event,
    Task,
    Workspace,
    Record,
    Conversation,
    Conference,
    VideoConference,
    VoiceCall,
    VideoCall,
    FlexMeeting,
    Generic,
    Broadcast,
    GroupChat,
}

export interface MeetingSlot {
    start: m.Moment
    end: m.Moment
    duration?: number
    best?: boolean
    availabilitySlot?: AvailabilitySlot
}
