import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class IframeOnlyGuard implements CanActivate {
    public canActivate(): boolean {
        if (top.location !== self.location) {
            return true
        }

        window.document.body.innerHTML = 'access denied'
        return false
    }
}
