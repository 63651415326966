import { Injectable } from '@angular/core'
import { MeetingSession } from 'amazon-chime-sdk-js'

import { Api } from '@undock/api'
import { MeetingAttendee } from '@undock/dock/meet/models/chime/meeting-attendee'
import { CurrentUser } from '@undock/session'


export interface AttendeeInformation {
    attendeeId: string
    externalUserId: string
}

@Injectable()
export class MeetingAttendeeFactory {

    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
    ) {}

    public async create(
        attendeeInformation: AttendeeInformation, meetingSession: MeetingSession,
    ): Promise<MeetingAttendee> {
        const { attendeeId } = attendeeInformation
        const isCurrentUser = await this.isCurrentUser(attendeeInformation)
        const attendeeUserId = this.getUndockUserIdFromExternalId(attendeeInformation.externalUserId)

        return new MeetingAttendee(
            attendeeUserId, attendeeId, isCurrentUser, this.api, this.currentUser, meetingSession,
        )
    }

    private async isCurrentUser(attendeeInformation: AttendeeInformation) {
        return (await this.currentUser.id) === this.getUndockUserIdFromExternalId(attendeeInformation.externalUserId)
    }

    /**
     * `externalUserId` is generated with pattern `${undockUserId}_${uuid()}` to allow
     * the same users to connect to meeting session as different attendees from different devices
     */
    private getUndockUserIdFromExternalId(externalUserId: string): string {
        return externalUserId.includes('_') ? externalUserId.split('_')[0] : externalUserId
    }
}
