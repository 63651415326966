import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'

import { Moment } from 'moment'
import { Observable } from 'rxjs'

import {
    MeetingMode,
    MeetingDurationOption,
    Schedule,
} from '@undock/dock/meet'
import {
    AvailabilitySet,
    AvailabilitySlot,
} from '@undock/api/scopes/profile/contracts'
import { CurrentUser } from '@undock/session'
import { TimelineEvent } from '@undock/api/scopes/time/contracts/timeline-event'
import { MeetingDuration } from '@undock/time/availability/services/availability.service'
import { PartnerChargeAccountInterface } from '@undock/api/scopes/charges/contracts/charge-account.interface'


@Component({
    selector: 'app-time-availability-slot-selector',
    templateUrl: 'availability-slot-selector.component.html',
    styleUrls: ['availability-slot-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilitySlotSelectorComponent {

    public readonly isAnonymous$: Observable<boolean>

    @Output() onExpandBtnClicked = new EventEmitter<void>()
    @Output() onMeetingModeChanged = new EventEmitter<MeetingMode>()
    @Output() onMeetingDurationChanged = new EventEmitter<MeetingDuration>()
    @Output() onAvailabilityDaySelected = new EventEmitter<Date>()
    @Output() onAvailabilitySlotSelected = new EventEmitter<AvailabilitySlot>()

    @Output() onGoToNextDaysClicked = new EventEmitter<void>()
    @Output() onGoToPrevDaysClicked = new EventEmitter<void>()

    // Booking state
    @Input() schedule: Schedule
    @Input() alreadyBookedMeeting: TimelineEvent
    @Input() meetingMode: MeetingMode
    @Input() meetingModeOptions: MeetingMode[]
    @Input() meetingDuration: MeetingDuration
    @Input() meetingDurationOptions: MeetingDurationOption[]

    @Input() selectedTimeZoneName: string

    // Availability state
    @Input() isAvailabilityLoading: boolean
    @Input() displayedAvailability: AvailabilitySet[]
    @Input() selectedAvailabilityDay: Moment
    @Input() displayAvailabilityRangeStart: Moment
    @Input() selectedAvailabilityDayIndex: number
    @Input() availabilityDaysCountToDisplay: number
    @Input() suggestedAvailableSlot: AvailabilitySlot

    @Input() paymentGroup?: PartnerChargeAccountInterface

    // Style customization
    @Input() themeColor?: string
    @Input() accentColor?: string
    @Input() showExpandBtn: boolean = false

    public constructor(
        protected currentUser: CurrentUser,
    ) {
        this.isAnonymous$ = this.currentUser.isAnonymousUserStream
    }

    public onAvailabilityDayIndexSelected(index: number) {
        if (this.displayedAvailability?.[index]) {
            this.onAvailabilityDaySelected.next(
                this.displayedAvailability[index].day?.toDate(),
            )
        }
    }
}
