import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core'

import { LegacyDockParticipant } from '@undock/dock/meet'
import { DockParticipant } from '@undock/dock/meet/models/dock/dock-participant.model'
import { ProfilesProvider } from '@undock/user/services/profiles.provider'
import { TimelineEventParticipant } from '@undock/api/scopes/time/contracts/timeline-event'
import { TooltipPosition } from '@undock/common/ui-kit/contracts/tooltip.position'

type ParticipantRecord = LegacyDockParticipant | DockParticipant | TimelineEventParticipant;

@Component({
    selector: 'app-meeting-participants-preview',
    templateUrl: 'participants-preview.component.html',
    styleUrls: ['participants-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: Move away from layout module
export class ParticipantsPreviewComponent implements OnInit {

    /* Match both values with size variable in scss */
    @Input() public iconSize = 38
    @Input() public iconShift = 10
    public readonly TooltipPosition = TooltipPosition
    public visibleCount: number

    @Input() public direction: string
    @Input() public maxDisplayCount: number = 5
    @Input() public preserveWidth: boolean = false
    @Input() public dynamicWidth: boolean = false
    @Input() public popupOnHover: boolean = false
    @Input() public participants: ParticipantRecord[]

    @HostBinding('class.expand-on-hover') @Input() expandOnHover = false

    @HostBinding('class.right-to-left') get rightDirection() { return this.direction !== 'left' }

    @HostBinding('class.left-to-right') get leftDirection() { return this.direction === 'left' }

    @HostBinding('style.min-width.px') public minWidth: number = 0
    public maxWidth: number = 0
    public maxSpacerWidth: string = '100%'

    constructor(
        public profilesProvider: ProfilesProvider,
    ) {}

    ngOnInit() {
        this.updateLayout()
    }

    public toParticipantRecord = (item: ParticipantRecord) => item

    private updateLayout() {
        if( !this.participants ){
            return
        }

        const totalCount = this.participants.length
        this.visibleCount = this.maxDisplayCount
        let displayCount = this.visibleCount
        if (totalCount > this.visibleCount) {
            this.visibleCount--
        }

        if (this.dynamicWidth) {
            // @todo element width check and change displayCount
        }

        const itemsToShow = displayCount <= totalCount ? displayCount : totalCount
        const calcWidth = itemsToShow * (this.iconSize - this.iconShift) + this.iconShift
        const realSize = this.iconSize >= calcWidth ? this.iconSize : calcWidth

        if (this.preserveWidth) {
            this.minWidth = realSize
        }

        this.maxWidth = realSize
        this.maxSpacerWidth = `calc(100% - ${realSize}px)`
    }

}
