<ng-container *ngIf="{
    slotLabel: selectedLabel | stream,
    availableSlots: this.availableSlotsStream | stream,
    selectedSlotIndex: this.selectedSlotIndexStream | stream
} as values">
    <mat-select class="customArrow"
                [tabIndex]="tabIndex"
                [disabled]="disabled"
                [ngModel]="selectedSlotIndexStream | stream"
                (ngModelChange)="selectAvailabilitySlot($event)"
    >
        <mat-option disabled [value]="-2" *ngIf="-2 === values.selectedSlotIndex">{{ values.slotLabel }}</mat-option>
        <mat-option *ngFor="let slot of values.availableSlots; let i = index" [value]="i">
            {{ slot.label }}
        </mat-option>
    </mat-select>
</ng-container>



