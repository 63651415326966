import { ApiRoute } from '@undock/api/services/api.route'


export class PersonalRoute extends ApiRoute {
    public init() {
        this.route = 'schedules/personal'
    }

    public async getById(id: string): Promise<any> {
        return this.get()
                   .endpoint(id)
    }

    public async ensureBuildInSchedulesCreated(): Promise<void> {
        return this.post<void>()
                   .withData({})
                   .endpoint(`ensure-build-in-schedules-created`)
    }
}
