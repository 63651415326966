import {
  ErrorHandler,
  NgModule,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { ServiceWorkerModule } from '@angular/service-worker'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { ApiModule } from '@undock/api/api.module'
import { CoreModule } from '@undock/core/core.module'
import { AuthModule } from '@undock/auth/auth.module'
import { UserProvider } from '@undock/user/user.provider'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'
import { LayoutModule } from '@undock/common/layout/layout.module'
import { LayoutProvider } from '@undock/common/layout/layout.provider'
import { AuthUiModule } from '@undock/auth-ui/auth-ui.module'
import { SessionModule } from '@undock/session/session.module'
import { IntegrationsModule } from '@undock/integrations/integrations.module'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { NotFoundScreen } from '@web/app/components/not-found.screen'
import { CriticalErrorPage } from '@web/app/components/critical-error.page'
import { PageNotFoundComponent } from '@web/app/components/page-not-found/page-not-found.component'

import { ErrorHandlerService } from '@undock/core'
import { OnboardingCompleteGuard } from '@undock/onboarding/install/routing/guards/onboarding-complete.guard'
import { MeetingCanceledPageComponent } from '@web/app/components/meeting-canceled.page.component'
import { DataStoreProvider } from '@undock/data-store/data.store.provider'
import { AppCommandsProvider } from '@undock/app-commands/app-commands.provider'
import { HotkeysProvider } from '@undock/hotkeys/hotkeys.provider'


import { MaterialsModule } from '@undock/common/ui-kit/materials.module'
import { DevDesignSystemComponent, DynamicComponentHost } from '@web/app/components/dev-design-system/dev-design-system.component'
import { IS_BETA_USER_PROVIDER } from '@undock/feature-plans/tokens/is-beta-user'
import { SchedulesManager } from '@undock/dock/meet/contracts/schedules-manager'
import { UserSchedulesManager } from '@undock/dock/meet'


@NgModule({
    declarations: [
        AppComponent,
        NotFoundScreen,
        CriticalErrorPage,
        PageNotFoundComponent,
        MeetingCanceledPageComponent,
        DevDesignSystemComponent,
        DynamicComponentHost
    ],
    imports: [
        CommonModule,

        // App Modules
        LayoutProvider.forRoot(),
        ApiModule.forRoot(),
        AuthModule.forRoot(),
        CoreModule.forRoot(),
        UserProvider.forRoot(),
        UiKitModule.forRoot(),
        MaterialsModule.forRoot(),
        SessionModule.forRoot(),
        AppCommandsProvider.forRoot(),
        IntegrationsModule.forRoot(),
        DataStoreProvider.forRoot(),
        HotkeysProvider,
        AuthUiModule,
        LayoutModule,

        // Dependencies
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: false, //environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        IS_BETA_USER_PROVIDER,
        OnboardingCompleteGuard,

        /**
         * Global application errors handler.
         */
        { provide: ErrorHandler, useClass: ErrorHandlerService },

        /**
         * Temporary solution to provide UserSchedulesManager by default
         */
        { provide: SchedulesManager, useExisting: UserSchedulesManager },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
