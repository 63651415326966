import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'

import { CoreModule } from '@undock/core/core.module'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'

/**
 * -----------------------------------------------------------
 *                       Components
 * -----------------------------------------------------------
 */
import {
    AvailabilityDayBrowserComponent,
    AvailabilitySlotSelectorComponent,
    AvailabilityMultiDaySlotSelectorComponent,
    AvailabilityDaySlotSkeletonLoaderComponent,
    AvailabilitySlotColumnViewComponent,
    AvailableTimeSlotViewComponent,
    AvailableTimeSlotScoreIconComponent,
    UpcomingEventSlotViewComponent,
    TimeZoneSelectorComponent,
    AvailableTimeSlotWarningIconComponent,
    AvailabilityMeetingModeSwitchComponent,
    AvailabilityDurationSelectComponent,
} from '@undock/time/availability/components'

/**
 * -----------------------------------------------------------
 *                        Providers
 * -----------------------------------------------------------
 */
import { AvailabilityService } from '@undock/time/availability/services/availability.service'
import { TimeZoneHelper } from '@undock/time/availability/services/timezone.helper'
import { AvailabilityFiltersAggregator } from '@undock/time/availability/services/filters/availability-filters.aggregator'
import { RemoveUnavailableSlotsFilter } from '@undock/time/availability/services/filters/remove-unavailable-slots.filter'
import { GeneratePriorityModeSlotsFilter } from '@undock/time/availability/services/filters/generate-priority-mode-slots.filter'
import { RemoveDuplicateSlotsFilter } from '@undock/time/availability/services/filters/remove-duplicate-slots.filter'
import { EventSlotEditButtonComponent } from '@undock/time/availability/components/availability-multi-day-slot-selector/availability-slot-column-view/upcoming-event-slot-view/event-slot-edit-button/event-slot-edit-button.component'
import { AvailableTimeSlotViewEmbedComponent } from '@undock/time/availability/components/embed/available-time-slot-view-embed/available-time-slot-view-embed.component'


@NgModule({
    imports: [
        CoreModule,
        UiKitModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        AvailableTimeSlotViewComponent,
        AvailableTimeSlotViewEmbedComponent,
        UpcomingEventSlotViewComponent,
        EventSlotEditButtonComponent,
        AvailabilityDayBrowserComponent,
        AvailabilitySlotSelectorComponent,
        AvailabilitySlotColumnViewComponent,
        AvailabilityMultiDaySlotSelectorComponent,
        AvailabilityDaySlotSkeletonLoaderComponent,
        AvailableTimeSlotScoreIconComponent,
        AvailableTimeSlotWarningIconComponent,
        TimeZoneSelectorComponent,
        AvailabilityMeetingModeSwitchComponent,
        AvailabilityDurationSelectComponent,
    ],
    declarations: [
        AvailableTimeSlotViewComponent,
        AvailableTimeSlotViewEmbedComponent,
        UpcomingEventSlotViewComponent,
        EventSlotEditButtonComponent,
        AvailabilityDayBrowserComponent,
        AvailabilitySlotSelectorComponent,
        AvailabilitySlotColumnViewComponent,
        AvailabilityMultiDaySlotSelectorComponent,
        AvailabilityDaySlotSkeletonLoaderComponent,
        AvailableTimeSlotScoreIconComponent,
        AvailableTimeSlotWarningIconComponent,
        TimeZoneSelectorComponent,
        AvailabilityMeetingModeSwitchComponent,
        AvailabilityDurationSelectComponent,
    ],
    providers: [
        TimeZoneHelper,
        AvailabilityService,
        AvailabilityFiltersAggregator,
        RemoveUnavailableSlotsFilter,
        GeneratePriorityModeSlotsFilter,
        RemoveDuplicateSlotsFilter,
    ],
})
export class AvailabilityUIProvider {}
