import {
    Aggregate,
    CreatedDateField,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import {
    HasAttachments,
    HasEntityName,
    HasPrivateNotes,
    HasRecordings,
    HasSecrets,
    Model,
    Secret,
} from '@undock/core'
import {
    ConferenceMode,
    DockSharedAccessMode,
    DockType,
    MeetingMode,
} from '@undock/dock/meet/contracts'
import { DateRange } from '@undock/time/availability'
import { DockVisibility } from '@undock/dock/meet/contracts/dock/dock-visibility'
import { OrmOnFireContext } from '@undock/session/models/orm-on-fire.context'

const DOCK_COLLECTION_NAME = 'dock'

@Aggregate({
    collection: DOCK_COLLECTION_NAME,
})
export class Dock implements Model,
                             HasSecrets,
                             HasEntityName,
                             HasRecordings,
                             HasAttachments,
                             HasPrivateNotes {

    // Used for `SharedCollectionEntity` linking
    public readonly entityName = 'Dock'
    static readonly entityName = 'Dock'

    public static readonly SHARED_ACCESS_SECRET = 'conferenceSharedAccess'
    public static readonly CONFERENCE_JOIN_PIN_CODE = 'conferenceJoinPinCode'

    @ID() readonly id: string

    /**
     * Contains an undockID of author user
     */
    @Field() authorId: string

    /**
     * Contains a firebaseID of author user
     */
    @Field() authorUId: string

    @Field() url: string
    @Field() note: string
    @Field() title: string

    @Field() dates: DateRange

    @Field() eventSchedule: EventSchedule

    /**
     * Selected duration in minutes
     */
    @Field() duration: number

    /**
     * Contains text for `InPerson` or conference url for remote meetings
     */
    @Field() location: string

    @Field() inPersonLocation: string

    @Field() inPersonLocationUrl: string

    /**
     * Used for only `MeetingMode.Video` and `MeetingMode.Broadcast`
     */
    @Field() isAudioOnly: boolean = false

    /** `Draft`, `Meeting`, `Instant`, `Document` */
    @Field() type: DockType = DockType.Meeting

    /** `Video`, `InPerson`, `Broadcast` */
    @Field() mode: MeetingMode = MeetingMode.Video

    /** `Room`, `Forum` and `Private` */
    @Field() conferenceMode: ConferenceMode = ConferenceMode.Room

    /**
     * `Participants` at default.
     *
     * `Connections` option is available only for Broadcast meetings
     */
    @Field() visibilityMode: DockVisibility = DockVisibility.Participants

    /** `Link`, `Connections`, `Participants` */
    @Field() sharedAccessMode: DockSharedAccessMode = DockSharedAccessMode.Link

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    public get isDraftType(): boolean {
        return this.type === DockType.Draft
    }

    /**
     * Converting proprietary Firestore dates to Date type
     */
    protected init() {
        // TODO: Finish this method
    }

    @Field() partnerName?: string

    /**
     * Assigned phone number for the conference incoming calling
     *
     * @TODO: Move this field to the secrets sub-collection
     *
     * @deprecated
     */
    @Field() conferenceJoinPhoneNumber: string

    /**
     * IDs of connections participating in this meeting
     *
     * Used for searching meetings for specific connection
     */
    @Field() readonly connectionIds: Array<string> = []
}

export interface EventSchedule {
    isAllDay: boolean // whether event is all day event
    start?: Date // event start date
    end?: Date // event end date
    rRule?: string // iCal recurrency rule - https://datatracker.ietf.org/doc/html/rfc5545
}

export const getDockSecret = async (
    code: string,
    dock: Dock,
    ormOnFireContext: OrmOnFireContext,
): Promise<string> => {
    return ormOnFireContext.createNestedCollection(Secret, dock)
                           .filter(filter => filter.key.equal(code))
                           .map(secrets => secrets.length > 0 ? secrets[0].value : null)
                           .get()
}
