import { ApiRoute } from '@undock/api'
import { IForm } from '@undock/common/form-creator/types'

export class FormsRoute extends ApiRoute {

    public init() {
        this.route = 'organizations'
    }

    public list(
        organizationId: string,
        page: number = 0,
        pageSize: number = 50,
    ) {
        return this.get<IForm[]>()
                   .withParams({
                       page: `${page}`,
                       pageSize: `${pageSize}`,
                   })
                   .endpoint(`${organizationId}/forms`)
    }

    public create(organizationId: string, data: Record<string, any>) {
        return this.post<IForm>().withData(data).endpoint(`${organizationId}/forms`)
    }

    public updateById(organizationId: string, id: string, updates: Record<string, any>) {
        return this.patch<IForm>().withData(updates).endpoint(`${organizationId}/forms/${id}`)
    }

    public deleteById(organizationId: string, id: string) {
        return this.delete().endpoint(`${organizationId}/forms/${id}`)
    }
}
