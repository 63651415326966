/**
 * File loader class.
 *
 * It is used to control the process of reading the file and uploading it using the specified upload adapter.
 */
import {
    AttachmentsManager,
    HasAttachments,
} from '@undock/core'

export interface FileLoader {
    data: File
    file: Promise<File>

    read(): Promise<string>

    abort(): Promise<void>

    upload(): Promise<Object>
}

export interface UploadAdapter {
    abort(): Promise<void>

    upload(): Promise<UploadResult>
}

export interface UploadResult {
    /**
     * File URL or base64 string
     */
    default: string
}

export class AttachmentsUploadAdapter implements UploadAdapter {

    public constructor(
        private loader: FileLoader,
        private ownerEntity: HasAttachments,
        private attachmentsManager: AttachmentsManager,
    ) {}

    public async upload(): Promise<UploadResult> {
        const file = await this.loader.file

        if (!file) {
            throw new Error(`File is missing`)
        }

        const target = { name: file.name, target: file }

        if (this.attachmentsManager.checkIsMediaTypeAllowed(target)) {
            const attachments = await this.attachmentsManager
                                          .addAttachments(this.ownerEntity, [target])
            return {
                default: await this.attachmentsManager.generateAttachmentUrl(attachments[0]),
            }
        }
    }

    public async abort() {}
}

export function createUploadAdapterPlugin(
    entity: HasAttachments, attachmentsManager: AttachmentsManager,
) {
    return function(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new AttachmentsUploadAdapter(loader, entity, attachmentsManager)
        }
    }
}
