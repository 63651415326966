import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'


@Component({
    selector: 'app-user-check-icon',
    templateUrl: 'user-check-icon.component.html',
    styleUrls: ['user-check-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCheckIconComponent extends IconTemplateComponent {
    @Input() width: number = 21
    @Input() height: number = 20
    @Input() color: string = 'white'
}
