<!-- fix width on list filtering (real input is absolute so it will cause width change) -->
<input matInput class="search-input-field hidden"/>
<div #selectFilterInputFieldWrapper class="filter-input-wrapper">
    <app-icon-search></app-icon-search>
    <input class="search-input-field mat-input-element"
           #searchSelectInput
           matInput
           autocomplete="off"
           type="text"
           [placeholder]="placeholder"
           [formControl]="filterFormControl"
           (keydown)="onKeyDown($event)"
           (keyup)="onKeyUp($event)"
    />
    <button mat-button
            *ngIf="isCleanButtonVisible"
            mat-icon-button
            aria-label="Clear"
            (click)="resetForm()"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="no-results" #noResults >Nothing found</div>
