import {
    Input,
    Component,
    ChangeDetectionStrategy,
} from '@angular/core'
import {
    PlanCategory,
} from '@undock/time/plans/contracts/plan.interface'

@Component({
    selector: 'plan-category-icon',
    templateUrl: 'plan-category-icon.component.html',
    styleUrls: ['plan-category-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanCategoryIconComponent {

    public readonly UserCommandCategory = PlanCategory

    @Input() category: PlanCategory

    @Input() color: string = '#9A95AC'

    @Input() size: number = 24

}
