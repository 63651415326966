import {
    AfterViewInit,
    Directive,
    DoCheck,
    ElementRef,
    HostListener,
} from '@angular/core'


@Directive({
    selector: '[appInputAutoresize]',
})
export class InputAutoresizeDirective implements AfterViewInit, DoCheck {

    public constructor(
        private readonly elementRef: ElementRef,
    ) {}

    @HostListener('input')
    public onInput() {
        this.resize()
    }

    @HostListener('window:resize')
    public resize() {
        this.elementRef.nativeElement.style.width = '0'
        this.elementRef.nativeElement.style.width = this.elementRef.nativeElement.scrollWidth + 'px'
    }

    public ngAfterViewInit() {
        if (this.elementRef.nativeElement.scrollWidth) {
            setTimeout(() => this.resize())
        }
    }

    private _prevValue
    public ngDoCheck() {
        const newValue = this.elementRef.nativeElement.value;
        if( newValue && this._prevValue !== newValue ){
            this._prevValue = newValue
            this.resize()
        }
    }
}
