import { AbstractEvent } from '@undock/core'
import { UserAnalyticsAction } from '@undock/integrations/events/actions'

/**
 * Analytics tracking core Event
 *
 * @API
 */
export class TrackUserAnalyticsEvent extends AbstractEvent {

    public constructor(
        protected _action: UserAnalyticsAction,
        protected _data?: any,
    ) { super() }

    public get action(): UserAnalyticsAction {
        return this._action
    }

    public get data(): any {
        return this._data
    }
}
