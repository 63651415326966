/**
 * Used only for Broadcast feature
 */
export enum ConferenceMode {
    /**
     * Default privacy mode. No restrictions
     */
    Room = 'Room',

    /**
     * Attendees could join, but are muted at default
     */
    Forum = 'Forum',

    /**
     * Attendees should wait for approval before join
     */
    Individual = 'Individual',
}
