import { Injectable } from '@angular/core'
import { AvailabilitySlot } from '@undock/api/scopes/profile/contracts'


@Injectable({
    providedIn: 'root',
})
export class RemoveUnavailableSlotsFilter {
    public apply(
        originalSlots: AvailabilitySlot[],
        slotsToRemove: AvailabilitySlot[],
        isPriorityMode: boolean = false,
    ): AvailabilitySlot[] {
        if (!originalSlots || originalSlots.length === 0 || !slotsToRemove || slotsToRemove.length === 0) {
            return originalSlots;
        }

        return this.filterSlots(originalSlots, slotsToRemove, isPriorityMode);
    }

    protected filterSlots(slots: AvailabilitySlot[], slotsToRemove: AvailabilitySlot[], isPriorityMode: boolean): AvailabilitySlot[] {
        return slots.filter(slot => {
            if (slot.type !== 'event') {

                let index = slotsToRemove.findIndex(slotToRemove => slot.timeStamp === slotToRemove?.timeStamp);
                if (index !== -1) {
                    if (slotsToRemove[index].isSoftBooked) {
                        slot.isSoftBooked = true;
                        if (slotsToRemove[index].cancelled) {
                            (slot as AvailabilitySlot).cancelled = true;
                            return true
                        }
                        return isPriorityMode;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    if (slot.isSoftBooked) {
                        return isPriorityMode;
                    }
                    else {
                        return true;
                    }
                }
            }
            else {
                return true
            }
        });
    }
}
