<div class="notification-container" [class.conf-mode]="isConfMode" [@fade]="isVisibleSubject | stream">
    <header class="notification-header">
        <i class="material-icons-round">chat</i>
        <span class="title">Chat &#9679; Now </span>
    </header>

    <section class="notification-content">
        <ng-container
                *ngIf="profilesProvider.getProfileById(notification.payload.authorId) | stream as profile"
        >
            <app-user-avatar [profileData]="profile"></app-user-avatar>

            <div class="notification-content-data">
                <div class="title">
                    <span>{{ profile.displayName }}</span>
                </div>

                <div class="content">
                    <span>{{ notification.payload.text }}</span>
                </div>
            </div>
        </ng-container>
    </section>
</div>



