import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import {
    ConnectedPosition,
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'


@Component({
    selector: 'app-dropdown-button',
    templateUrl: 'dropdown-button.component.html',
    styleUrls: ['dropdown-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownButtonComponent {

    /**
     * TODO: Rework with DropdownDirective
     */

    @Input() label: string = ''
    @Input('overlayWidth') customOverlayWidth: string
    @Input('direction') overlayOpenDirection: 'up' | 'down' = 'down'
    @Output() onClicked = new EventEmitter<MouseEvent>()

    /**
     * CSS width of overlay popup
     */
    public overlayWidth: string

    protected overlayRef: OverlayRef
    protected resizeObserver: ResizeObserver


    @ViewChild('dropdownButton', { read: ElementRef })
    protected readonly dropdownButtonRef: ElementRef<HTMLElement>

    @ViewChild('overlayContentTmpl')
    protected readonly overlayContentRef: TemplateRef<HTMLElement>


    @EmitOnDestroy()
    protected readonly destroyEvent = new DestroyEvent()

    public constructor(
        protected readonly overlay: Overlay,
        protected readonly viewContainerRef: ViewContainerRef,
        protected readonly changeDetectorRef: ChangeDetectorRef,
    ) {}


    public ngAfterViewInit() {
        /**
         *
         */
        let position: ConnectedPosition = this.overlayOpenDirection === 'down'
            ? {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
            }
            : {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
            }

        const positionStrategy = this.overlay
                                     .position()
                                     .flexibleConnectedTo(this.dropdownButtonRef)
                                     .withPositions([position])
                                     .withPush(false)
                                     .withDefaultOffsetY(2)

        this.overlayRef = this.overlay.create({
            positionStrategy,
            hasBackdrop: true,
            backdropClass: '',
            scrollStrategy: this.overlay.scrollStrategies.noop(),
        })

        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach()
        })

        this.resizeObserver = new ResizeObserver((events) => {
            if (Array.isArray(events) && events.length > 0) {
                this.overlayWidth = this.customOverlayWidth ?? `${events[0].borderBoxSize[0].inlineSize}px`
                this.changeDetectorRef.detectChanges()
            }
        })

        /**
         *
         */
        this.resizeObserver.observe(this.viewContainerRef.element.nativeElement)
    }

    public openOverlay() {

        /**
         * Closing previous view
         */
        this.closeOverlay()

        if (this.overlayRef) {
            this.overlayRef.attach(
                new TemplatePortal(
                    this.overlayContentRef, this.viewContainerRef,
                ),
            )
        }
    }

    public closeOverlay() {
        if (this.overlayRef) {
            this.overlayRef.detach()
        }
    }
}
