export enum DockType {
    /**
     * Isn't scheduled, isn't used anywhere.
     */
    Draft = 'Draft',

    /**
     * Scheduled. Has a CalendarEvent, TimeBlock.
     */
    Meeting = 'Meeting',

    /**
     * Same as Basic Dock, but with `instant` date
     */
    Instant = 'Instant',

    /**
     * This Dock isn't scheduled. Has no conference room
     *
     * @deprecated This property isn't used yet
     */
    Document = 'Document',
}