import {
    inject,
    Injectable,
    Optional,
} from '@angular/core'

import * as m from 'moment'

import {
    State,
    StateModel,
    StreamStore,
} from '@undock/core/states'

import {
    combineLatest,
    Observable,
    timer,
} from 'rxjs'
import {
    map,
    takeUntil,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { Api } from '@undock/api'
import { CurrentUser } from '@undock/session'
import {
    ProposalAggregate,
    TimeProposalStatus,
} from '@undock/proposals/contracts/time-proposal.interface'
import { BookingRequestAggregate } from '@undock/api/scopes/meet/routes/booking.route'
import {
    TimelineDirection,
    TimelineEvent,
} from '@undock/api/scopes/time/contracts/timeline-event'
import { RsvpStatus } from '@undock/api/scopes/calendar/contracts'
import { SnackbarManager } from '@undock/common/ui-kit/services/snackbar.manager'
import { StatusesListItemType } from '@undock/data-store/contracts/statuses/statuses-list-item-type'
import { CalendarEventsStorage } from '@undock/calendar/services/calendar-events.storage'
import { ExtConnector } from '@undock/core'
import {
    ConfirmAction,
    ConfirmActionService,
} from '@undock/common/ui-kit/services/confirm-action.service'


@Injectable({
    providedIn: 'root',
})
export class StatusesStateModel extends StateModel<ProposalsListStore> {

    protected readonly store = new ProposalsListStore()

    protected readonly confirmActionService = inject(ConfirmActionService)

    @EmitOnDestroy()
    protected readonly destroyedEvent = new DestroyEvent()

    /**
     * Maximum date for displaying requests
     * TODO: Implement ability to filter requests on the BE side
     */
    protected readonly requestsBoundary = Date.now() + 1000 * 60 * 60 * 24 * 365

    public constructor(
        private api: Api,
        private currentUser: CurrentUser,
        private extConnector: ExtConnector,
        private snackbarManager: SnackbarManager,
        @Optional()
        private calendarEventsStorage: CalendarEventsStorage,
    ) {
        super()

        /**
         * Refresh the list every 60 minutes
         * TODO: Trigger refresh from BE side
         */
        timer(0, 60 * 60 * 1000).pipe(
            takeUntil(this.destroyedEvent),
        ).subscribe(() => this.refreshState())
    }

    public async refreshState() {
        /**
         * Shouldn't load any data for guest users
         */
        if (await this.currentUser.isRegularUser) {
            if (!this.extConnector.isExt()) {
                this.store.isProposalsDataLoadingStream.next(true)
                this.store.isTimelineEventsDataLoadingStream.next(true)
                this.store.isBookingRequestsDataLoadingStream.next(true)

                await Promise.all([
                    // this.fetchDraftProposals(),

                    this.fetchOutgoingProposals(),
                    this.fetchOutgoingBookingRequests(),

                    this.fetchIncomingProposals(),
                    this.fetchIncomingTimelineEvents(),
                    this.fetchIncomingBookingRequests(),
                ])
            }

            this.store.isProposalsDataLoadingStream.next(false)
            this.store.isTimelineEventsDataLoadingStream.next(false)
            this.store.isBookingRequestsDataLoadingStream.next(false)
        }
    }

    public async refreshStatePartial(type: StatusesListItemType) {
        switch (type) {
            case StatusesListItemType.Proposal:
                return Promise.all([
                    // this.fetchDraftProposals(),
                    this.fetchIncomingProposals(),
                    this.fetchOutgoingProposals(),
                ])

            case StatusesListItemType.TimelineEvent:
                return this.fetchIncomingTimelineEvents()

            case StatusesListItemType.BookingRequest:
                return Promise.all([
                    this.fetchIncomingBookingRequests(),
                    this.fetchOutgoingBookingRequests(),
                ])
        }
    }

    public async fetchDraftProposals(): Promise<void> {
        this.store.draftProposalsStream.next(await this.api.meet.proposal.listDrafts())
    }

    public async fetchOutgoingBookingRequests(): Promise<void> {
        this.store.outgoingBookingRequestsStream.next(await this.api.meet.booking.listPersonalOutgoingRequests())
    }

    public async fetchOutgoingProposals(): Promise<void> {
        this.store.outgoingProposalsStream.next(await this.api.meet.proposal.listOutgoing())
    }

    public async fetchIncomingProposals(): Promise<void> {
        this.store.incomingProposalsStream.next(await this.api.meet.proposal.listIncoming())
    }

    public async fetchIncomingTimelineEvents(): Promise<void> {
        const threshold = m().startOf('day')
        const pendingEvents = await this.api.calendar.timeline.getTimelineEventsPage({
            start: threshold.toISOString(),
            page: 0, pageSize: 50,
            order: TimelineDirection.Future,
            filterByStatus: RsvpStatus.NeedsAction,
        })

        const now = Date.now()
        this.store.incomingTimelineEventsStream.next(
            pendingEvents.filter(event => {
                const eventTS = new Date(event.end).valueOf()
                return eventTS >= now && eventTS <= this.requestsBoundary
            })
        )
    }

    public async fetchIncomingBookingRequests(): Promise<void> {
        this.store.incomingBookingRequestsStream.next(await this.api.meet.booking.listPersonalIncomingRequests())
    }

    /**
     * @TODO: Add animation for deleting items from the list
     */
    public async deleteOwnProposal(entity: ProposalAggregate): Promise<void> {

        const confirmed = await this.confirmActionService.askFor(ConfirmAction.DeleteProposal)
        if (!confirmed) {
            return null
        }

        this.store.draftProposalsStream.next(
            this.store.draftProposalsStream.value.filter(
                proposal => proposal._id !== entity._id
            )
        )

        this.store.outgoingProposalsStream.next(
            this.store.outgoingProposalsStream.value.filter(
                proposal => proposal._id !== entity._id
            )
        )

        await this.api.meet.proposal.deleteOwn(entity._id)
    }

    public async confirmTimelineEvent(entity: TimelineEvent) {
        const confirmed = await this.confirmActionService.askFor(ConfirmAction.ConfirmTimelineEvent)
        if (!confirmed) {
            return null
        }

        this.store.incomingTimelineEventsStream.next(
            this.store.incomingTimelineEventsStream.value.filter(
                event => event.id !== entity.id
            )
        )

        this.snackbarManager.success('Event confirmed')
        await this.api.meet.requests.acceptFromEvent(entity.id)

        this.reloadDayCalendarEventsStorage(entity.start).catch(
            error => console.warn(`Cannot reload calendar events storage`, error),
        )
    }

    public async declineTimelineEvent(entity: TimelineEvent) {
        const confirmed = await this.confirmActionService.askFor(ConfirmAction.DeclineTimelineEvent)
        if (!confirmed) {
            return null
        }

        this.store.incomingTimelineEventsStream.next(
            this.store.incomingTimelineEventsStream.value.filter(
                event => event.id !== entity.id
            )
        )

        this.snackbarManager.success('Event declined')
        await this.api.meet.requests.declineFromEvent(entity.id)

        this.reloadDayCalendarEventsStorage(entity.start).catch(
            error => console.warn(`Cannot reload calendar events storage`, error),
        )
    }

    /**
     * Refreshed events cache for selected day
     */
    protected async reloadDayCalendarEventsStorage(day: m.MomentInput) {
        if (this.calendarEventsStorage) {
            await this.calendarEventsStorage.getEventsForDateRange({
                end: m(day).endOf('day').toDate(),
                start: m(day).startOf('day').toDate(),
            }, true)
        }
    }
}

class ProposalsListStore extends StreamStore {

    public readonly isDataLoadingStream: Observable<boolean>
    public readonly incomingProposalsCountStream: Observable<number>

    public readonly isProposalsDataLoadingStream = new ValueSubject<boolean>(true)
    public readonly isTimelineEventsDataLoadingStream = new ValueSubject<boolean>(true)
    public readonly isBookingRequestsDataLoadingStream = new ValueSubject<boolean>(true)


    public readonly draftProposalsStream = new ValueSubject<ProposalAggregate[]>([])

    public readonly outgoingProposalsStream = new ValueSubject<ProposalAggregate[]>([])
    public readonly outgoingBookingRequestsStream = new ValueSubject<BookingRequestAggregate[]>([])

    public readonly incomingProposalsStream = new ValueSubject<ProposalAggregate[]>([])
    public readonly incomingTimelineEventsStream = new ValueSubject<TimelineEvent[]>([])
    public readonly incomingBookingRequestsStream = new ValueSubject<BookingRequestAggregate[]>([])

    public constructor() {
        super()

        /**
         * Returns true if at least one data source is loading
         */
        this.isDataLoadingStream = combineLatest([
            this.isProposalsDataLoadingStream,
            this.isTimelineEventsDataLoadingStream,
            this.isBookingRequestsDataLoadingStream,
        ]).pipe(
            map((statuses) => {
                return statuses.some(Boolean)
            })
        )

        /**
         * Calculates total count of incoming proposals and booking requests
         */
        this.incomingProposalsCountStream = combineLatest([
            this.incomingProposalsStream,
            this.incomingTimelineEventsStream,
            this.incomingBookingRequestsStream,
        ]).pipe(
            map(sources => {
                const count = sources.reduce((total, item) => total + item.length, 0)
                return count > 99 ? 99 : count
            })
        )
    }
}

export type StatusesState = State<ProposalsListStore>
