import {
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons'

@Component({
    selector: 'app-lock-rounded-icon',
    template: `
        
        <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.92671 0.916649L9.99999 0.916673L10.0733 0.916649C10.466 0.916454 10.769 0.916304 11.0365 0.969514C12.1274 1.18651 12.9802 2.03928 13.1971 3.13017C13.2504 3.39768 13.2502 3.70067 13.25 4.0934L13.25 4.16667V6.77351C15.2407 7.90575 16.5833 10.0461 16.5833 12.5C16.5833 16.1359 13.6359 19.0833 9.99999 19.0833C6.36412 19.0833 3.41666 16.1359 3.41666 12.5C3.41666 10.0461 4.75927 7.90575 6.74999 6.77351V4.16667L6.74997 4.0934C6.74977 3.70067 6.74962 3.39768 6.80283 3.13017C7.01982 2.03928 7.87259 1.18651 8.96349 0.969514C9.231 0.916304 9.53399 0.916454 9.92671 0.916649ZM11.75 4.16667V6.15181C11.1927 5.99854 10.6059 5.91667 9.99999 5.91667C9.39405 5.91667 8.80723 5.99854 8.24999 6.15181V4.16667C8.24999 3.66607 8.25362 3.5253 8.27401 3.42281C8.37264 2.92695 8.76026 2.53932 9.25613 2.44069C9.35861 2.42031 9.49939 2.41667 9.99999 2.41667C10.5006 2.41667 10.6414 2.42031 10.7439 2.44069C11.2397 2.53932 11.6273 2.92695 11.726 3.42281C11.7464 3.5253 11.75 3.66607 11.75 4.16667ZM9.99999 7.41667C7.19254 7.41667 4.91666 9.69256 4.91666 12.5C4.91666 15.3075 7.19254 17.5833 9.99999 17.5833C12.8074 17.5833 15.0833 15.3075 15.0833 12.5C15.0833 9.69256 12.8074 7.41667 9.99999 7.41667ZM10.75 11.6667C10.75 11.2525 10.4142 10.9167 9.99999 10.9167C9.58578 10.9167 9.24999 11.2525 9.24999 11.6667V13.3333C9.24999 13.7476 9.58578 14.0833 9.99999 14.0833C10.4142 14.0833 10.75 13.7476 10.75 13.3333V11.6667Z"
                  [attr.fill]="color"/>
        </svg>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class LockRoundedIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 20
    @Input() height: string | number  = 20
    @Input() color: string = '#807A8E'
}
