import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'

@Component({
    selector: 'app-standalone-invite',
    templateUrl: 'standalone-invite.page.html',
    styleUrls: ['standalone-invite.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class StandaloneInvitePageComponent {

    public constructor() {}
}

