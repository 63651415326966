import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { CoreModule } from '@undock/core/core.module'
import { PeopleProvider } from '@undock/people/people.provider'
import { ContactMentionsComponent } from '@undock/people/ui/components/contact-mentions/contact-mentions.component'
import { MentionsModule } from '@undock/common/mentions/mentions.module'
import { UiKitModule } from '@undock/common/ui-kit/ui-kit.module'


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        PeopleProvider,
        MentionsModule,
        UiKitModule,
    ],
    declarations: [
        ContactMentionsComponent
    ],
    exports: [
        ContactMentionsComponent
    ]
})
export class PeopleUiProvider {}
