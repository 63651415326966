import {
    ReactiveStream,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'
import { Memoize } from '../decorators'
import RecordRTC from 'recordrtc'


export abstract class Recorder {

    protected recordRTC: RecordRTC

    @CompleteOnDestroy()
    protected mediaStreamSubject = new ValueSubject<MediaStream>(null)

    @CompleteOnDestroy()
    protected isRecordingRunningSubject = new ValueSubject<boolean>(false)

    @Memoize()
    public get mediaStreamStream(): ReactiveStream<MediaStream> {
        return this.mediaStreamSubject.asStream()
    }

    @Memoize()
    public get isRecordingRunningStream(): ReactiveStream<boolean> {
        return this.isRecordingRunningSubject.asStream()
    }

    public abstract startRecording(): Promise<void>

    public abstract stopRecording(): Promise<Blob>

    public abstract cancelRecording(): Promise<void>

    protected abstract getUserMediaStream(): Promise<MediaStream>
}
