import {
    Directive,
    HostListener,
    Input,
} from '@angular/core'
import { Router } from '@angular/router'
import { SidebarState } from '@undock/common/layout/states/sidebar.state'

@Directive({
    selector: '[sidebarRouterLink]',
})
export class SidebarRouterLinkDirective {
    constructor(
        private state: SidebarState,
    ) { }

    @HostListener('click', ['$event']) onClick($event) {
        if (window.innerWidth <= 1280) {
            this.state.collapse()
        }
    }
}
