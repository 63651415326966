<div class="container carbon__edit-notes meeting-notes"
     [ngClass]="{'fullscreen': (isFullscreenModeActiveStream | stream)}"
>
    <ng-container *ngIf="editorDataStream | stream as data">
        <ckeditor *ngIf="data.type"
                  data-private class="ck-editor__editable ck-placeholder"
                  [disabled]="disabled || !(isEditModeStream | stream)"
                  [editor]="data.type" [config]="data.config"
                  (ready)="onEditorReady($event)"
                  (change)="onEditorContentChanged($event.editor.getData())"
        ></ckeditor>
    </ng-container>
</div>
