import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core'

import { Config } from '@undock/core'
import { CoreModule } from '@undock/core/core.module'
import { CurrentUser } from '@undock/session/models/current-user'
import { UserSession } from '@undock/session/models/user-session'
import { FirebaseApp } from '@undock/session/contracts/firebase-app.token'
import { FirebaseAppFactory } from '@undock/session/factories/firebase-app.factory'

@NgModule({
    imports: [CoreModule],
})
export class SessionModule {
    static forRoot(): ModuleWithProviders<SessionModule> {
        return {
            ngModule: SessionModule,
            providers: [
                CurrentUser,
                UserSession,
                { provide: FirebaseApp, useFactory: FirebaseAppFactory, deps: [Config] },
            ],
        }
    }
}
