import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-chevron',
    templateUrl: 'chevron.component.html',
    styleUrls: ['chevron.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChevronIconComponent extends IconTemplateComponent {
    @Input() width: string | number = 24
    @Input() height: string | number  = 24
    @Input() color: string = '#2B2733'
}
