import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import { TimeCommandBlueprintEvent } from '@undock/api/scopes/nlp/routes/commands.route'
import { TimeCommandBlueprintEventItemComponent } from '@undock/time/prompt/ui/components/time-command-blueprint-event-item/time-command-blueprint-event-item.component'

@Component({
    selector: 'app-time-command-pending-blueprint-event-item',
    templateUrl: './time-command-pending-blueprint-event-item.component.html',
    styleUrls: [
        './time-command-pending-blueprint-event-item.component.scss',
        '../time-command-blueprint-event-item.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCommandPendingBlueprintEventItemComponent extends TimeCommandBlueprintEventItemComponent {

}
