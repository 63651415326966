import { Injectable } from '@angular/core'

import {
    distinctUntilChanged,
    map,
    pairwise,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators'
import {
    DestroyEvent,
    EmitOnDestroy,
    ReactiveStream,
    StatefulSubject,
    CompleteOnDestroy,
    ValueSubject,
} from '@typeheim/fire-rx'

import { Memoize } from '@undock/core'
import { Api } from '@undock/api'
import { IntegrationsManager } from '@undock/integrations'
import { InviteSuggestion } from '@undock/invites/contracts/invite-suggestion.interface'
import {
    SnackbarManager,
    SnackbarPosition,
} from '@undock/common/ui-kit/services/snackbar.manager'
import { CurrentUser } from '@undock/session'
import { OnboardingAnalyticsRegistry } from '@undock/user/services/analytics/onboarding-analytics.registry'
import { UserAnalyticsProvider } from '@undock/user/services/analytics/user-analytics.provider'


@Injectable()
export class InvitesFacade {

    private readonly INVITE_INITIAL_LOAD_AMOUNT = 50

    @CompleteOnDestroy()
    private isInviteSuggestionsLoadingSubject = new ValueSubject<boolean>(false)

    @CompleteOnDestroy()
    private inviteSuggestionsSubject = new StatefulSubject<InviteSuggestion[]>()

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    public constructor(
        protected api: Api,
        protected user: CurrentUser,
        protected snackbarManager: SnackbarManager,
        protected calendarManager: IntegrationsManager,
        protected userAnalyticsProvider: UserAnalyticsProvider,
    ) {
        this.initialize().catch(
            error => console.warn(`Could not initialize InvitesFacade`, error),
        )
    }

    @Memoize()
    public get invitesSentCountStream(): ReactiveStream<number> {
        return new ReactiveStream<number>(
            this.userAnalyticsProvider.userAnalyticsStream.pipe(
                map(obj => obj?.invitesCount || 0),
                startWith(0),

                takeUntil(this.destroyedEvent),
                shareReplay({ bufferSize: 1, refCount: true }),
            ),
        )
    }

    @Memoize()
    public get isInviteSuggestionsLoadingStream(): ReactiveStream<boolean> {
        return this.isInviteSuggestionsLoadingSubject.asStream()
    }

    @Memoize()
    public get inviteSuggestionsStream(): ReactiveStream<InviteSuggestion[]> {
        return this.inviteSuggestionsSubject.asStream()
    }

    @Memoize()
    public get nonDomainInvites(): ReactiveStream<InviteSuggestion[]> {
        return new ReactiveStream<InviteSuggestion[]>(
            this.inviteSuggestionsStream
                .pipe(
                    map(suggestions => suggestions.filter(s => !s.interactions[0].isInDomain)),
                ),
        )
    }

    @Memoize()
    public get domainInvites(): ReactiveStream<InviteSuggestion[]> {
        return new ReactiveStream<InviteSuggestion[]>(
            this.inviteSuggestionsStream.pipe(
                map(suggestions => suggestions.filter(s => s.interactions[0].isInDomain)),
            ),
        )
    }

    @Memoize()
    public get shouldRefreshInviteSuggestionsStream(): ReactiveStream<boolean> {
        return new ReactiveStream<boolean>(
            this.inviteSuggestionsStream
                .pipe(
                    startWith([] as InviteSuggestion[]),
                    pairwise(),
                    map(
                        ([previousValue, currentValue]) => previousValue?.length > 0 && currentValue?.length === 0,
                    ),
                ),
        )
    }

    @Memoize()
    public get shouldUpdateSuggestionsStream(): ReactiveStream<boolean> {
        return this.calendarManager.syncedCalendarsChangedStream
    }


    public async getSuggestedInvites(count?: number, offset?: number): Promise<void> {
        this.isInviteSuggestionsLoadingSubject.next(true)

        let suggestions = await this.api.contacts.invites.getInviteSuggestions(count, offset) ?? []

        this.inviteSuggestionsSubject.next(
            suggestions.sort((a, b) => b.totalInteractions - a.totalInteractions),
        )

        this.isInviteSuggestionsLoadingSubject.next(false)
    }


    public async sendInviteToSuggestion(suggestion: InviteSuggestion): Promise<void> {
        let profile = await this.api.contacts.invites
                                .sendInviteToSuggestion(suggestion.uid || suggestion.email)

        if (profile) {
            const currentSuggestions = await this.inviteSuggestionsStream
            this.inviteSuggestionsSubject.next(
                currentSuggestions.map(item => {
                    if (item.email === suggestion.email) {
                        item.isInviteSent = true
                    }
                    return item
                })
            )
        } else {
            this.snackbarManager.warning('Problem sending invite, please try again', SnackbarPosition.BottomLeft)
        }
    }

    public async sendInvitesToSuggestions(suggestions: InviteSuggestion[]): Promise<void> {

        let successfulSends: InviteSuggestion[] = []
        for (let suggestion of suggestions) {
            let profile = await this.api.contacts.invites
                                    .sendInviteToSuggestion(suggestion.uid || suggestion.email)
            if (profile) {
                successfulSends.push(profile)
            }
        }

        if (successfulSends.length) {
            this.inviteSuggestionsSubject.next(
                (await this.inviteSuggestionsStream).filter(i => successfulSends.findIndex(s => s.email === i.email) < 0),
            )
        } else {
            this.snackbarManager.warning('Problem sending invites, please try again', SnackbarPosition.BottomLeft)
        }
    }

    public async dismissInviteSuggestion(suggestion: InviteSuggestion): Promise<void> {
        let profile = await this.api.contacts.invites
                                .dismissInviteSuggestion(suggestion.uid || suggestion.email)

        if (profile) {
            this.inviteSuggestionsSubject.next(
                (await this.inviteSuggestionsStream).filter(i => i.email !== suggestion.email),
            )
        } else {
            this.snackbarManager.warning('Problem updating invite', SnackbarPosition.BottomLeft)
        }
    }

    private async initialize() {
        this.getSuggestedInvites(this.INVITE_INITIAL_LOAD_AMOUNT)

        this.shouldRefreshInviteSuggestionsStream
            .pipe(
                takeUntil(this.destroyedEvent),
                tap(async (shouldRefresh) => {
                    if (shouldRefresh) {
                        this.getSuggestedInvites(this.INVITE_INITIAL_LOAD_AMOUNT)
                    }
                }),
            ).subscribe()

        this.shouldUpdateSuggestionsStream
            .pipe(
                takeUntil(this.destroyedEvent),
                tap(async (shouldRebuild) => {
                    if (shouldRebuild) {
                        this.getSuggestedInvites(this.INVITE_INITIAL_LOAD_AMOUNT)
                    }
                }),
            ).subscribe()
    }
}
