<div class="event-item-header">
    <ng-container *ngIf="isNext">
        Next event
        <ng-container *ngIf="timerText">starts {{ timerText }}</ng-container>
    </ng-container>

    <ng-container *ngIf="!isNext && isFirstActive">
        Started {{ timerText }}
    </ng-container>

    <ng-container *ngIf="meetingRequest">
        <span class="material-icons" *ngIf="isNext || isFirstActive">stop_circle</span> Event Request
    </ng-container>
</div>

<div class="event-item-body">
    <section class="description-wrapper">
        <div class="description-left">
            <div class="title-timer">
                <div class="duration">
                    <div class="title-box">
                        <h3 class="title">
                            {{ event.title }}
                        </h3>

                        <div class="labels-list">
                            <ng-container *ngIf="event.type === MeetingMode.InPerson">
                                <app-event-label-location-icon
                                        [matTooltip]="event.location"
                                ></app-event-label-location-icon>
                            </ng-container>

                            <ng-container *ngIf="event.location && event.type !== MeetingMode.InPerson">
                                <app-event-label-video-icon></app-event-label-video-icon>
                            </ng-container>

                            <ng-container *ngIf="event.dockId">
                                <app-event-label-notes-icon></app-event-label-notes-icon>
                                <app-event-label-agenda-icon></app-event-label-agenda-icon>
                            </ng-container>

                            <ng-container *ngIf="event.isAutoGenerated">
                                <app-event-label-command-icon></app-event-label-command-icon>
                            </ng-container>
                        </div>
                    </div>

                    <div class="time-subtitle">
                        <span class="short-date">{{ event.start | moment:"ddd, MMM D, y" }}</span>
                        <span class="full-date">{{ event.start | moment:"dddd, MMM D, y" }}</span>
                        <span class="material-icons">circle</span>
                        <span>{{ event.start | moment:"h:mm A" }}&ndash;{{ event.end | moment:"h:mm A" }}</span>
                    </div>

                    <app-meet-edit-button
                            class="viewport-mobile glue-top-right"
                            [event]="event"
                            [disabled]="!event.editingAllowed"
                            (preAction)="action.emit($event)"
                    ></app-meet-edit-button>
                </div>
            </div>
        </div>

        <div class="description-right">
            <div class="participants-list">
                <app-meeting-participants-preview
                        class="viewport-mobile"
                        *ngIf="event.participants?.length"
                        [direction]="'right'"
                        [expandOnHover]="false"
                        [popupOnHover]="false"
                        [participants]="event.participants"
                ></app-meeting-participants-preview>

                <app-meeting-participants-preview
                        class="viewport-web"
                        *ngIf="event.participants?.length"
                        [direction]="'left'"
                        [expandOnHover]="true"
                        [popupOnHover]="true"
                        [participants]="event.participants"
                ></app-meeting-participants-preview>
            </div>

            <div class="enter-buttons-box" (click)="$event.stopPropagation()">
                <ng-container *ngIf="meetingRequest; else actionButtons">
                    <div class="request-controls-wrapper -choice">
                        <button class="enter-button -deny" (click)="action.emit('declineMeetingRequest')">
                            Deny
                        </button>

                        <button mat-button class="enter-button -accept" (click)="action.emit('acceptMeetingRequest')">
                            Accept
                        </button>
                    </div>
                </ng-container>

                <ng-template #actionButtons>
                    <ng-container *ngIf="event.hasOwnProperty('inPersonLocationUrl')">
                        <button *ngIf="event.inPersonLocationUrl !== ''" class="btn-primary view-location-button">
                            <a target="_blank" [href]="event.inPersonLocationUrl">View location</a>
                        </button>
                    </ng-container>

                    <button *ngIf="canJoinMeeting" mat-button
                            class="enter-button -join-meeting"
                            (click)="navigateToConference()">
                        Join Room
                    </button>

                    <app-meet-edit-button
                            class="viewport-web meeting-edit-btn"
                            [event]="event"
                            [disabled]="!event.editingAllowed"
                            (preAction)="action.emit($event)"
                    ></app-meet-edit-button>
                </ng-template>
            </div>
        </div>
    </section>

    <ng-template #defaultTabs>
        <!--Agenda | Notes -->
    </ng-template>

    <div class="viewport-web">
        <ng-container *ngTemplateOutlet="tabsTemplate || defaultTabs"></ng-container>
    </div>
</div>
