import { User } from '@undock/user'
import { ApiRoute } from '@undock/api/services/api.route'

export class UserRoute extends ApiRoute {
    public init() {
        this.route = 'user'
    }

    public getCurrentUserProfile(): Promise<User> {
        return this.get<User>()
                   .endpoint(`profile`)
    }

    public getById(id: string): Promise<User> {
        return this.get<User>()
                   .endpoint(`${id}`)
    }

    public getGuestById(id: string): Promise<User> {
        return this.get<User>()
                   .endpoint(`guest/${id}`)
    }

    public getByEmail(email: string): Promise<User> {
        return this.get<User>()
                   .endpoint(`email/${email}`)
    }

    public getByProfileUrl(profileUrl: string): Promise<User> {
        return this.get<User>()
                   .endpoint(`by-profile-url/${profileUrl}`)
    }

    public async deleteCurrentUserProfile() {
        return this.post<void>()
                   .endpoint(`profile/delete-account`)
    }
}
