import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core'
import { TimeCommandBlueprintEventItemComponent } from '@undock/time/prompt/ui/components/time-command-blueprint-event-item/time-command-blueprint-event-item.component'


@Component({
    selector: 'app-time-command-reschedule-blueprint-event-item',
    templateUrl: './time-command-reschedule-blueprint-event-item.component.html',
    styleUrls: [
        './time-command-reschedule-blueprint-event-item.component.scss',
        '../time-command-blueprint-event-item.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeCommandRescheduleBlueprintEventItemComponent extends TimeCommandBlueprintEventItemComponent {

}
