import {
    CreatedDateField,
    DocRef,
    Entity,
    EntityFilter,
    Field,
    ID,
    UpdatedDateField,
} from '@typeheim/orm-on-fire'

import {
    FirestoreUser,
    HasUserData,
    PublicProfileData,
} from '@undock/user'
import { LazyLoadStream } from '@typeheim/fire-rx'
import {
    Model,
    ShouldInitialize,
    SoftDelete,
} from '@undock/core'
import { SafeReference } from '@undock/session/models/orm-on-fire.context'


export class DockParticipantScope {
    public static initialized(filter: EntityFilter<DockParticipant>) {
        return filter.isInitialized.equal(true)
    }

    public static notRemoved(filter: EntityFilter<DockParticipant>) {
        return filter.removed.equal(false)
    }
}

export enum DockParticipantRole {
    Owner = 'Owner',
    Member = 'Member',
    Visitor = 'Visitor',
}


export enum DockParticipantStatus {
    /**
     * Participant denied meeting request
     */
    Denied = 'Denied',

    /**
     * Participant cancelled the meeting
     */
    Deleted = 'Deleted',

    /**
     * Participant didn't respond to the request yet
     *
     * This is default status
     */
    Pending = 'Pending',

    /**
     * Participant accepted meeting request
     */
    Accepted = 'Accepted',

    /**
     * Equal to RSVP `Maybe`.
     */
    Tentative = 'Tentative',
}


export enum ConferenceAccessStatus {
    /**
     * Participant waiting for approval
     */
    Pending = 'Pending',

    /**
     * Participant can join conference room
     */
    Accepted = 'Accepted',

    /**
     * Participant not allowed to join the room
     */
    Rejected = 'Rejected',

    /**
     * Participant is allowed to request an access
     */
    PendingRequest = 'PendingRequest',
}


export interface ConferenceMeta {

    /**
     * Permissions
     */
    canStartVideo?: boolean
    canUnmuteAudio?: boolean
    canScreenShare?: boolean

    /**
     * Depends on ConferenceMode
     */
    accessStatus: ConferenceAccessStatus

    queueMessage?: string
    queuePosition?: number

    /** @deprecated */
    requestedAt?: Date

    lastActivityAt?: Date
    accessGrantedAt?: Date
    accessRequestedAt?: Date
    interactRequestedAt?: Date
}

const DOCK_PARTICIPANT_COLLECTION_NAME = 'participant'

@Entity({
    collection: DOCK_PARTICIPANT_COLLECTION_NAME,
})
export class DockParticipant implements Model,
                                        SoftDelete,
                                        HasUserData,
                                        ShouldInitialize {

    @ID() readonly id: string

    @Field() dockId: string

    @Field() userId?: string
    @Field() addedById: string

    @Field() userUId?: string
    @Field() addedByUId: string

    @Field() role: DockParticipantRole
    @Field() status: DockParticipantStatus

    /**
     * For soft-delete implementation
     */
    @Field() removed: boolean = false

    /**
     * Stores denormalized User data or non-undock user information.
     */
    @Field() userData: PublicProfileData

    @Field() conferenceMeta: ConferenceMeta

    @CreatedDateField() createdAt: Date
    @UpdatedDateField() updatedAt: Date

    public get isOwner(): boolean {
        return this.role === DockParticipantRole.Owner
    }

    /** @deprecated */
    @Field() email: string
    /** @deprecated */
    @Field() isUndockUser: boolean

    /** @deprecated */
    @DocRef(FirestoreUser) user: SafeReference<FirestoreUser>
    /** @deprecated */
    @DocRef(FirestoreUser) addedBy: SafeReference<FirestoreUser>

    /**
     * @description Temporary fix for getting realtime updates when
     *     the document is not completely filled with required data
     *
     * @deprecated
     */
    @Field() isInitialized: boolean

    /**
     * Converting proprietary Firestore dates to Date type
     */
    protected init() {
        if (this.conferenceMeta?.requestedAt) {
            // @ts-ignore
            this.conferenceMeta.requestedAt = this.conferenceMeta.requestedAt.toDate()
        }
        if (this.conferenceMeta?.lastActivityAt) {
            // @ts-ignore
            this.conferenceMeta.lastActivityAt = this.conferenceMeta.lastActivityAt.toDate()
        }
        if (this.conferenceMeta?.accessGrantedAt) {
            // @ts-ignore
            this.conferenceMeta.accessGrantedAt = this.conferenceMeta.accessGrantedAt.toDate()
        }
        if (this.conferenceMeta?.accessRequestedAt) {
            // @ts-ignore
            this.conferenceMeta.accessRequestedAt = this.conferenceMeta.accessRequestedAt.toDate()
        }
        if (this.conferenceMeta?.interactRequestedAt) {
            // @ts-ignore
            this.conferenceMeta.interactRequestedAt = this.conferenceMeta.interactRequestedAt.toDate()
        }
    }
}
