import { ApiRoute } from '@undock/api/services/api.route'


export class ClientsRoute extends ApiRoute {
    public init() {
        this.route = 'partners/clients'
    }

    public async getClientById(clientId: string): Promise<ClientData> {
        return this.get<ClientData>()
                   .endpoint(`${clientId}`)
    }
}

/**
 * @TODO: Move this into correct place
 */
export interface ClientData {
    title: string
    scopes: string[]
    logoUrl: string
    clientId: string
}
