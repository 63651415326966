import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core'


export interface ValuesSelectorOption<T> {
    value: T,
    label: string,
    disabled: boolean
}

@Component({
    selector: 'app-values-selector',
    templateUrl: 'values-selector.component.html',
    styleUrls: ['values-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValuesSelectorComponent<T> {

    @Input() value: T
    @Input() options: ValuesSelectorOption<T>[]

    @Input() height: string = '50px'
    @Input() width: string = '120px'
    @Input() fontSize: string = '2.3rem'
    @Input() fontWeight: string = 'bold'

    @Output() valueChanges = new EventEmitter<any>()

    public label: string = ''
    public dropDownSelectedValue: string
    public dropDownShowing: boolean = false
    public showValuesToggled: boolean = false


    public ngOnChanges(changes: SimpleChanges) {
        const selectedValue = changes?.value?.currentValue

        if (selectedValue) {
            this.options.forEach(option => {
                if (option.value === selectedValue) {
                    this.label = option.label
                }
            })
        }
    }

    public toggleValuesList(event?) {
        this.showValuesToggled = !this.showValuesToggled
        this.dropDownShowing = !this.dropDownShowing

        if (event) {
            event.stopPropagation()
        }
    }

    public selectValue(event, value: any) {
        this.value = value
        this.valueChanges.emit(value)

        if (event) {
            event.stopPropagation()
        }

        this.toggleValuesList()
    }

    public closeValuesList() {
        this.dropDownShowing = false
        this.showValuesToggled = false
    }
}
