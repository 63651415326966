import {
    Component,
    Host,
    OnInit,
} from '@angular/core'
import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations'
import {
    DeviceUtil,
    Memoize,
} from '@undock/core'
import {
    AppCommandsManager,
} from '@undock/app-commands/services'
import { Router } from '@angular/router'
import { AppCommand } from '@undock/app-commands/contracts/app-commands'
import { AppCommandsScopeComponent } from '@undock/app-commands/ui/app-commands-scope/app-commands-scope.component'
import { Observable } from 'rxjs'
import { Plan } from '@undock/time/plans/contracts/plan.interface'
import {
    shareReplay,
    takeUntil,
} from 'rxjs/operators'
import { PlansSearchStateModel } from '@undock/time/plans/services/states/plans-search.state-model'
import { OwnPlansStorage } from '@undock/time/plans/services/states/plans-list.state'
import {
    DestroyEvent,
    EmitOnDestroy,
} from '@typeheim/fire-rx'
import { map } from 'rxjs/operators'


@Component({
    selector: 'app-commands-modal',
    templateUrl: './app-commands-modal.component.html',
    styleUrls: ['./app-commands-modal.component.scss'],
    animations: [
        trigger('slideInConfirmation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('400ms ease-in-out', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('400ms ease-in-out', style({ opacity: 0 })),
            ]),
        ]),
    ],
    providers: [
        PlansSearchStateModel
    ]
})
export class AppCommandsModalComponent implements OnInit {

    public commandsForRoute: AppCommand[] = []

    @EmitOnDestroy()
    private readonly destroyedEvent = new DestroyEvent()

    constructor(
        public router: Router,
        protected device: DeviceUtil,
        protected commandsManager: AppCommandsManager,
        protected listStateModel: OwnPlansStorage,
        protected searchStateModel: PlansSearchStateModel,

        @Host() protected commandScope: AppCommandsScopeComponent
    ) {}

    ngOnInit(): void {
        this.commandsForRoute = this.commandsManager.listCommands(this.router.url)
    }

    @Memoize()
    public get suggestedPlansStream(): Observable<Plan[]> {
        return this.listStateModel.state.suggestedPlansStream.pipe(
            map(commands => commands.slice(0, 2)),
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get plansSearchResultsStream(): Observable<Plan[]> {
        return this.searchStateModel.state.plansSearchResultsStream.pipe(
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    @Memoize()
    public get searchTermStream(): Observable<string> {
        return this.searchStateModel.state.searchCriteriaStream.pipe(
            takeUntil(this.destroyedEvent),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    public get controlMetaLabel(): string {
        return this.device.isAppleDevice ? 'Cmd' : 'Ctrl'
    }

    public runPlan(plan: Plan) {
        if (plan) {
            this.router.navigate(['plans', plan._id])
        }
        this.closeCommandMenuIfOpen()
    }

    public goToPlansDashboard() {
        this.router.navigate(['plans'])
        this.closeCommandMenuIfOpen()
    }

    public closeCommandMenuIfOpen() {
        this.commandScope.closeCommandsMenuIfOpen()
    }

}
