import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core'
import { IconTemplateComponent } from '@undock/common/ui-kit/ui/icons/icon-template.component'

@Component({
    selector: 'app-icon-participants',
    templateUrl: 'participants.component.html',
    styleUrls: ['participants.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantsIconComponent extends IconTemplateComponent {}
