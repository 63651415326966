import { ApiRoute } from '@undock/api/services/api.route'
import { TimeFitnessReport } from '@undock/time-fitness/services/time-fitness-report.manager'


export class ReportsRoute extends ApiRoute {

    public init() {
        this.route = 'time/reports'
    }

    public async getTimeFitnessReport(start: string, end: string) {
       return this.post<TimeFitnessReport>()
                  .withData({ start, end })
                  .endpoint('time-fitness')
    }

    public async getDashboardHistory(type: TimeHistoryReportType): Promise<TimeDashboardHistoryReport> {
        return this.post<TimeDashboardHistoryReport>()
                   .withData({
                       type: type
                   })
                   .endpoint(`history`)
    }

    public async getDashboardForecast(): Promise<TimeDashboardForecastReport> {
        return this.post<TimeDashboardForecastReport>()
                   .withData({})
                   .endpoint(`forecast`)
    }

    public async getDashboardThisWeek(): Promise<TimeDashboardThisWeekReport> {
        return this.post<TimeDashboardThisWeekReport>()
                   .withData({})
                   .endpoint(`this-week`)
    }
}


export enum TimeHistoryReportType {
    Month = 'month',
    Week = 'week',
    Day = 'day'
}

export interface TimeBreakdown {
    date: Date
    workingTotal: number
    meetingTotal: number
    focusTimeTotal: number
    soloEventTotal: number
    freeTotal: number
}

export interface TimeDashboardHistoryReport {
    type: TimeHistoryReportType
    data: Partial<TimeBreakdown>[]
    avgMeetingTotalHours: number
    avgFocusTimeTotalHours: number
}

export interface TimeDashboardForecastReport {
    data: Partial<TimeBreakdown>[]
    meetingPercentage: number
    focusTimePercentage: number
    soloEventPercentage: number
    freePercentage: number
}

export interface TimeDashboardThisWeekReport {
    scheduledPercentage: number
    scheduledPercentageHistorical: number
    scheduledPercentageChange: number
    focusTimePercentage: number
    focusTimePercentageHistorical: number
    focusTimePercentageChange: number
}
